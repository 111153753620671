import { Inject, Injectable, Injector, NgModuleFactory, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRoute, NavigationStart, NavigationEnd, Router, Event, NavigationCancel, NavigationError } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { UrlLambdaApi } from './classroom/models/models';


//graphql
import { gql, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { LAZY_MODULES_MAP } from './shared/graphql/lazy_module_map';
import { Integer } from 'aws-sdk/clients/apigateway';
import { environment } from 'src/environments/environment';

//graphql

@Injectable()
export class AppService {
    public url_route: string = "";
    public url_Base: string = environment.domain;
    // public url_Base: string = "https://edutin.com";
    // public url_Base: string = "https://develop.edutin.com";
    public url_previous: string;
    public show_desktop_notification: boolean = false;
    public display_spinner: boolean = false;

    baseUrl = this.url_Base + '/libraries/myLibrary';
    baseLambdaUrl = environment.urlAPI;
    baseLambdaUrlA = 'https://api.edutin.com/a/p';
    baseRetry: number = 4;

    //graphql
    public ws_host = 'tcm5oi5qdveqpamqp4txivk54q.appsync-api.us-east-1.amazonaws.com';
    public token: string;
    static querySubscription: Subscription;
    static idtoken: string;
    static current_opened_conversation: string;
    //graphql

    public cognitoUserAuthenticated;
    public verifyEmail = {
        detail: null,
        type: null
    };

    public authenticated_error: string;

    public loadNavBar: boolean = false;
    public loadFooter: boolean = false;


    public indexedDB_users_list;

    public showMessaging: boolean = false;

    public assistOnline: boolean = false;
    notification_msg_subject: Subject<any> = new Subject<any>();

    public darkTheme: boolean = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private injector: Injector,
        private loader: NgModuleFactoryLoader,
        @Inject(LAZY_MODULES_MAP) private modulesMap
    ) {
        // this.route.queryParams.subscribe(params => {
        //     let url_string = window.location.href;
        //     let url = new URL(url_string);

        //     if (params.hasOwnProperty('token') || url.searchParams.get('token') != null) {
        //         this.token = params.token || url.searchParams.get('token');
        //         localStorage.setItem('token', this.token);
        //     } else if (localStorage.getItem('token') != null) {
        //         // this.token = localStorage.getItem('token');
        //     } else {
        //         // window.location.href = this.url_Base + "/users/login";
        //         // this.router.navigate(['/user/login']);
        //     }
        // });



        let routerEventsSubscriber = this.router.events.subscribe((routerEvent: Event) => {

            if (routerEvent instanceof NavigationStart) {
                this.display_spinner = true;
            }

            if (routerEvent instanceof NavigationEnd ||
                routerEvent instanceof NavigationError ||
                routerEvent instanceof NavigationCancel) {
                this.display_spinner = false;
                if (routerEventsSubscriber) routerEventsSubscriber.unsubscribe();

            }
        })
    }

    public chat_user: any;
    public chatroom_state: boolean = false;

    public comment_modal_ = {
        data: null,
        state: false
    }

    public certification: any;
    public config = {
        actions: false //si el usuario está logueado ? true : false
    }

    public display_navbar: boolean = true;
    public isDesktop: boolean = true;
    menu_is_active: boolean = false;
    public hoverTop(ev?) {

        if (this.url_route == 'classroom') {

            this.menu_is_active = ev != null ? ev : false;

            if (!this.display_navbar) {
                this.display_navbar = true;
                // setTimeout(() => {
                if (!this.menu_is_active) this.display_navbar = false;
                // }, 500);
            } else {
                // setTimeout(() => {
                if (!this.menu_is_active) this.display_navbar = false;
                // }, 500);
            }
        }
    }

    getHeaders(): any {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.token
        })

        return ({ headers: headers });
    }

    post(url: any, formData: FormData) {

        return this.http.post(url, formData, this.getHeaders());
    }

    put(url: any, formData: FormData) {
        return this.http.put(url, formData, this.getHeaders());
    }

    delete(url: any) {
        return this.http.delete(url, this.getHeaders());
    }

    public user_data: any;
    public user_session: number = -1;

    getUserData() {
        if (this.cognitoUserAuthenticated) {
            if (this.cognitoUserAuthenticated.hasOwnProperty('signInUserSession')) {
                // User with session    
                return { valid: true, print: this.user_data, session: this.user_session };
            } else {
                // User without session              
                return { valid: true, print: null, session: this.user_session };
            }
        } else {
            // Error cognitoUserAuthenticated
            return { valid: false, print: null, session: this.user_session };
        }
    }


    public display_verification_alert: boolean = false;//Cuando es verdadero muestra la notificación de verificación de correo electrónico
    public userSignUpEmail;
    setUserData(user, from?) {
        this.cognitoUserAuthenticated = user;

        // let response;
        if (this.cognitoUserAuthenticated) {

            if (this.cognitoUserAuthenticated.hasOwnProperty('signInUserSession')) {


                user['userKeyPrefix'] = user.keyPrefix + '.' + user.username;

                // const { email } = user.signInUserSession.idToken.payload;
                const { email_verified, certification_id } = user.signInUserSession.idToken.payload;
                user['email_verified'] = email_verified;

                this.cognitoUserAuthenticated = user;

                //console.log('setting token...');
                this.token = user.signInUserSession.idToken.jwtToken;
                AppService.idtoken = this.token;
                let user_data = JSON.parse(JSON.stringify(this.cognitoUserAuthenticated.signInUserSession.idToken.payload));
                // User with session
                this.user_session = 1;
                let urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

                user_data.picture = user_data.image == '' ? urlavatar + (user_data.role == 'propietario' ? 'professor/medium_imagen.jpg' : 'default/medium_imagen.jpg') : urlavatar + user_data.id + '/medium_' + user_data.image;
                this.user_data = user_data;
                if (user_data.hasOwnProperty('certification_id') && user_data.certification_id == "0") {
                    const ls_certification_id = localStorage.getItem("last-certificate-default");
                    if (ls_certification_id) {
                        this.user_data.certification_id = ls_certification_id;
                    }
                }
                // response = { valid: true, print: user_data };

            } else {
                // User without session                
                this.user_session = 0;
                this.user_data = [];
                // response = { valid: true, print: [] };
            }
        }
        else {
            this.token = null;
            //     console.warn(validate_user.msg);
            //     response = { valid: false, print: validate_user.msg };
        }

        //('%c currentAuthenticatedUser: ', 'color: blue; background: white', JSON.parse(JSON.stringify(this.cognitoUserAuthenticated)));

    }

    popUpData: any = {};
    public reloadGetsNavBar: boolean = true;
    public startGetsNavBar() {
        return new Promise(resolve => {
            // Get user data
            const userData = this.getUserData();
            if (userData.valid && this.reloadGetsNavBar) { // init app
                resolve(true); // Start the validations of the routes
                if (this.user_session == 1) {// User with session
                    // Get learning
                    this.loadingLearning = true;
                    this.learning = [];
                    this.getLearning(0).subscribe(data => {
                        if (data.valid) {

                            // Aqui cada vez que inicie la app
                            // Leo las estadisticas del ultimo curso al que el usuario ingresó
                            this.readStatistics(data.print[0].id).subscribe()

                            if (data.print && data.print.length > 0) {
                                this.startNotificationsPush();
                                data.print.forEach(async (element, index) => {
                                    this.learning.push(Object.assign(element, { active: false }));
                                    if (element.certification_state != 1) {
                                        // Call when you do not have the certificate
                                        this.learning[index].pending_payment = -1;
                                        await this.paymentsCertification(element.certification_id).then((res) => {
                                            if (this.learning != null && this.learning.length > 0) {
                                                this.learning[index] = Object.assign(this.learning[index], res);
                                            }
                                        });
                                    }
                                });
                                // Send call to change
                                this.learningSubject.next(this.learning);
                                if (this.url_route == 'classroom' && sessionStorage.getItem('config') != null) {
                                    let config = JSON.parse(sessionStorage.getItem('config'));
                                    if (config && config.hasOwnProperty('curso_id')) {
                                        let curso_id = config.curso_id;
                                        // update learning
                                        let i = this.learning.findIndex(elemt => elemt.id == curso_id);
                                        if (i != -1) {
                                            let temp = this.learning[i];
                                            this.learning = this.learning.filter(item => item.id != curso_id).map((item) => { item.active = false; return item });
                                            this.learning.unshift(Object.assign(temp, { active: true }));
                                        } else {
                                            // Course of config not found in getLearning()
                                        }
                                    }
                                }
                            }
                        }
                        this.loadingLearning = false;
                    });

                    // Get new courses
                    this.loadNewCourses = true;
                    this.getNewCourses().subscribe(data => {
                        if (data.valid) {
                            this.newCourses = data.print;
                            this.newCoursesPage += 1;
                        } else {
                            this.newCoursesPage = 0;
                        }
                        this.loadNewCourses = false;
                    });

                    // Get shopping car
                    this.resultsShoppingCar = [];
                    this.getShoppingCart().subscribe(data => {
                        if (data.valid) {
                            this.resultsShoppingCar = data.print;
                        } else {
                            this.resultsShoppingCar = [];
                        }
                    });

                    // Get notifications count
                    this.getNotificationsUnreaded().subscribe(data => {
                        if (data.valid) {
                            this.notifications.count = data.print;
                        }
                    });
                    // Get notifications
                    this.notifications_loaded = false;
                    this.getNotifications(null).subscribe(data => {
                        if (data.valid) {
                            this.notifications.all = data.print;
                            if (data.metadata.hasOwnProperty('last_evaluated_key')) {
                                this.more_notifications = true;
                            } else {
                                this.more_notifications = false;
                            }
                        } else {
                            this.notifications.all = [];
                        }
                        this.notifications_loaded = true;
                    });

                    // Get conversations
                    //this.conversations = [];
                    //this.messageCounter = 0;
                    //this.conversationId = null;
                    /*this.getConversations(null).subscribe(data => {
                        if (data.valid) {
                            this.conversations = data.print;
                            this.messageCounter = this.conversations.filter(msg => {
                                return msg.state == '1';
                            }).length;
                            this.conversationId = Number(this.route.snapshot.queryParamMap.get('conversation'));
                            if (this.conversationId) {
                                // Open chat
                                this.openChatRoom(this.conversationId);
                            }
                            
                        } else {
                            this.conversations = [];
                        }
                    });*/
                    // Get category in nav-bar

                    //graphql
                    this.loadGraphQLModule();
                    //graphql

                    if (this.categories.length == 0) {
                        this.getCategory(true).subscribe();
                    }
                } else if (this.user_session == 0) {// User without session
                    // Get category in nav-bar
                    if (this.categories.length == 0) {
                        this.getCategory(false).subscribe();
                    }
                }
                this.reloadGetsNavBar = false;
                // window.addEventListener("storage", this.changeStorage, false);
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Esta función lee las estadistica de los usuarios del ultimo curso al que ingresó el usuario logueado
     * Con el fin de generar sugerencias de conexiones.
     * @param curso_id 
     */
    readStatistics(curso_id): Observable<any> {
        return this.http.get(`${UrlLambdaApi}/certifications_statistics?curso_id=${curso_id}`, this.getHeaders()).pipe(
        map((response: any) => {
            // console.log(response);
        }),
        catchError((error: any) => {
            this.handleError('ReadStatistics');
            return of({ status: false });
          })
        );    
    }

    lazyEventStream = new Subject();
    lazyConversationsStream = new Subject();

    //graphql lazy loading
    _apolloClient
    heartbeatinterval
    loadGraphQLModule() {

        this.loader
            .load(this.modulesMap['graphQLModule'])
            .then((moduleFactory: NgModuleFactory<any>) => {
                const moduleRef = moduleFactory.create(this.injector);

                const nuwModule = moduleRef.injector.get((<any>moduleFactory.moduleType).createApolloInit);

                this._apolloClient = moduleRef.injector.get((<any>moduleFactory.moduleType).apolloClient);

                this.lazyEventStream.next(true);
                //graphql
                //connecting user to grapql
                this.connectUserToGraphQL().subscribe(data => {
                });

                this.setHeartBeat();
                let th = this;

                this.heartbeatinterval = setInterval(function () {
                    th.setHeartBeat();
                    th.sendHeartBeat();
                }, 20000);

                this.initConversationsObjects();
                this.conversationsObservable.subscribe((data: any) => {
                    if (this.chatRoomMain) {
                        return;
                    }
                    if (data.valid) {

                        if (!this.conversations || this.conversations.length === 0) {
                            data.print = JSON.parse(JSON.stringify(data.print));
                            let temp_conversations = [];
                            data.print.forEach(element => {
                                if (this.verifyAssociatedConversation(element)) {
                                    temp_conversations.push(element);
                                }
                            });
                            this.conversations = temp_conversations;
                            this.lazyConversationsStream.next(true);
                            this.conversations_loaded = true;
                            this.nextToken = data.next;

                            let th = this;
                            this.conversations.forEach(e => {
                                e.input_state = "NONE";
                                if (e.unreaded_messages > 0) {
                                    th.messageCounter += 1;
                                }
                            });

                            if (this.showMessaging) {
                                this.loadConversationsStatus(this.conversations);
                                this.loadConversationsInputStatus();
                            }

                            if (this.nextToken) {
                                this.conversation_end = false;
                            } else {
                                this.conversation_end = true;
                            }

                            /*TODO: open based in route
                            this.conversationId = Number(this.route.snapshot.queryParamMap.get('conversation'));
                            if (this.conversationId) {
                                // Open chat
                                this.openChatRoom(this.conversationId);
                            }*/
                        }
                    } else {
                        this.conversations = [];
                    }
                });

                this.initSubscription();
                //graphql


            });


    }




    /*
  * Not allow add a conversation with problems in asociated.
  */
    verifyAssociatedConversation(conversation: any): boolean {

        if (!conversation.associated.length) {
            return false;
        }

        if (conversation.associated[0].user == null || conversation.associated[0].user == undefined) {
            return false;
        }

        if (conversation.associated[0].user.user_id == null || conversation.associated[0].user.user_id == undefined) {
            return false;
        }

        return true;
    }




    conversations_loaded = false;
    loadConversationsInputStatus() {
        const ws_headers = {
            "Authorization": AppService.idtoken,
            "host": this.ws_host
        };
        this.statusQuery.subscribeToMore({
            document: AppService.INPUT_STATUS,
            variables: {
                user_id: this.user_data.id
            },

            extensions: {
                authorization: ws_headers
            },
            updateQuery: (prev, { subscriptionData }) => {
                //evaluate to proccess a subscription if no message is sended
                //replacing old object
                var th = this;
                this.conversations = this.conversations.map(function (con) {
                    if (con.conversation.id === subscriptionData.data.subscribeToInputState.conversation_id) {
                        con.input_state = subscriptionData.data.subscribeToInputState.input_state
                    }
                    return con;
                });
            }
        });
    }

    loadConversationsStatus(arr) {
        const ws_headers = {
            "Authorization": AppService.idtoken,
            "host": this.ws_host
        };
        arr.forEach(f => {
            f.associated.forEach(e => {
                e.user.status = 'offline';

                this.statusQuery = this._apolloClient.watchQuery({
                    query: AppService.GET_STATUS,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                    variables: {
                        id: e.user.user_id
                    }
                });

                this.statusQuery.valueChanges.pipe(
                    map((response: any) => {
                        e.user.status = response.data.status.status;
                        return response;
                    })
                ).subscribe((data: any) => { });

                this.statusQuery.subscribeToMore({
                    document: AppService.STATUS,
                    variables: {
                        id: e.user.user_id
                    },

                    extensions: {
                        authorization: ws_headers
                    },
                    updateQuery: (prev, { subscriptionData }) => {
                        //evaluate to proccess a subscription if no message is sended
                        //replacing old object
                        e.user.status = subscriptionData.data.onStatus.status;
                    }
                });
            });
        });
    }

    //graphql lazy loading
    isFirstTime = true;

    public defaultGetsNavBar() {
        this.loadingLearning = true;
        this.learning = [];

        this.newCourses = [];
        this.newCoursesPage = 0;
        this.loadNewCourses = false;

        this.resultsShoppingCar = [];

        this.notifications = {
            count: 0,
            all: []
        }
        this.notifications_loaded = false;
        this.more_notifications = false;

        this.conversations = [];

        this.user_data = undefined;
        this.user_session = -1;

        this.categories = [];

        this.reloadGetsNavBar = true;
    }

    changeStorage(e) {
        if (e.key == 'token') {
            let url = new URL(window.location.href);
            if (url.searchParams.get('token') != null) {
                this.token = url.searchParams.get('token');
                localStorage.setItem('token', this.token);
            } else if (localStorage.getItem('token') != null) {
                if (e.oldValue == e.newValue) {
                    this.token = localStorage.getItem('token');
                } else {
                    sessionStorage.clear();
                    window.location.href = "https://edutin.com/";
                }
            } else {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "https://edutin.com/users/login";
            }
        }
    }

    public learning: Array<any> = [];
    loadingLearning: boolean = true;
    learningSubject: Subject<any> = new Subject<any>();
    pagination: number = 0;
    loadingPagination: boolean = false;
    public learning_item_count: number = 0;

    getLearning(key): Observable<any> {
        // const fields = ["progress", "calification", "id", "estado", "tour", "clase_id", "quiz_id", "rating", "source_id"]; &fields=' + JSON.stringify(fields)

        let last_evaluated_key = key != 0 ? '&LastEvaluatedKey=' + JSON.stringify(key) : '';

        return this.http.get(this.baseLambdaUrl + '/dynamo/certifications?' + 'order_by="fecha"&user_id=' + this.user_data.id + last_evaluated_key, this.getHeaders()).pipe(
            map((response: any) => {

                const { data, metadata } = response;
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    response.data = response.data.map((item) => {
                        let certification = {
                            avance: item.Certification.progress,
                            calificacion: item.Certification.calification,
                            certification_id: item.Certification.id,
                            certification_state: item.Certification.estado,
                            certification_tour: item.Certification.tour,
                            clase_id: item.Certification.clase_id,
                            id: item.Course.id,
                            language_id: item.Course.language_id,
                            language: (item.Course.language_id == 1 ? 'Español' : 'Inglés'),
                            name: item.Course.nombre,
                            quiz_id: item.Certification.quiz_id,
                            rating: item.Certification.rating,
                            slug: item.Course.slug,
                            source_id: item.Certification.source_id,
                            state: item.Course.estado,
                            time: item.Certification.duracion,
                            tipo: item.Course.tipo,
                            visitas: item.Course.visitas,
                            category_id: item.Course.category_id,
                            fecha: item.Certification.fecha,
                            fecha_inscripcion: item.Certification.fecha_inscripcion,
                            date: '',
                            date_state: false,
                            pending_payment: false,
                        };
                        certification = Object.assign(certification, this.dateCertification(item.Certification.fecha_inscripcion));
                        return Object.assign(item.Course, certification);
                    });



                    if (metadata) {
                        if (metadata.hasOwnProperty('last_evaluated_key')) {
                            // console.log('metadata tiene last_evaluated_key:: ', metadata.last_evaluated_key);
                            this.pagination = JSON.parse(JSON.stringify(metadata.last_evaluated_key));
                        } else {
                            this.pagination = 0;
                        }
                        if (metadata.hasOwnProperty('item_count')) {

                            this.learning_item_count = metadata.item_count;
                            // if (data.length === this.learning_item_count) {
                            //('es la misma cantidad de certificados');
                            // }
                        }
                    }



                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }

            }),
            retry(this.baseRetry + 6),
            catchError(this.handleError<any>('getLearning', []))
        );


    }

    newCourses: Array<any> = [];
    loadNewCourses: boolean = false;
    newCoursesPage: number = 0;
    loadNewCoursesPage: boolean = false;
    getNewCourses(): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/cursos?order_by="actualizacion"&tipo=2&estado=[1,2]&page=' + this.newCoursesPage, this.getHeaders()).pipe(
            map((response: any) => {
                // Valid response
                let valData = this.valData(2, response);
                if (valData.is) {
                    response.data = response.data.map((item) => {
                        let course = {
                            id: item.Curso.id,
                            language_id: item.Curso.language_id,
                            language: (item.Curso.language_id == 1 ? 'Español' : 'Inglés'),
                            name: item.Curso.nombre,
                            slug: item.Curso.slug,
                            state: item.Curso.estado,
                            visitas: item.Curso.visitas,
                            category_id: item.Curso.category_id,
                        };
                        return Object.assign(item.Curso, course);
                    });
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }), catchError(this.handleError<any>('getNewCourses', []))
        );
    }

    _getLearning(page): Observable<any> {
        // const fields = ["progress", "calification", "id", "estado", "tour", "clase_id", "quiz_id", "rating", "source_id"]; &fields=' + JSON.stringify(fields)

        // let k = {
        //     curso_id: "2639",
        //     fecha: "2022-04-01T13:48:26",
        //     user_id: "209906"
        // }


        return this.http.get(this.baseLambdaUrl + '/certifications?page=' + page + '&order_by="fecha"&user_id=' + this.user_data.id, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    response.data = response.data.map((item) => {
                        let certification = {
                            avance: item.Certification.progress,
                            calificacion: item.Certification.calification,
                            certification_id: item.Certification.id,
                            certification_state: item.Certification.estado,
                            certification_tour: item.Certification.tour,
                            clase_id: item.Certification.clase_id,
                            id: item.Course.id,
                            language_id: item.Course.language_id,
                            language: (item.Course.language_id == 1 ? 'Español' : 'Inglés'),
                            name: item.Course.nombre,
                            quiz_id: item.Certification.quiz_id,
                            rating: item.Certification.rating,
                            slug: item.Course.slug,
                            source_id: item.Certification.source_id,
                            state: item.Course.estado,
                            time: item.Certification.duracion,
                            tipo: item.Course.tipo,
                            visitas: item.Course.visitas,
                            category_id: item.Course.category_id,
                            fecha: item.Certification.fecha,
                            fecha_inscripcion: item.Certification.fecha_inscripcion,
                            date: '',
                            date_state: false,
                            pending_payment: false,
                        };
                        certification = Object.assign(certification, this.dateCertification(item.Certification.fecha_inscripcion));
                        return Object.assign(item.Course, certification);
                    });
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry + 6),
            catchError(this.handleError<any>('getLearning', []))
        );
    }

    public dateCertification(fecha_inscripcion: string): any {
        let date, date_state;
        let fecha = new Date(fecha_inscripcion.replace(/\s/, 'T'));
        let limit = new Date("2019-11-01");
        let hoy = new Date();
        if (fecha < limit) {
            fecha = limit;
        }
        fecha.setDate(fecha.getDate() + 7);
        // fecha.setMonth(fecha.getMonth() + 1);
        if (fecha < hoy) {
            date_state = false;
        } else {
            date_state = true;
        }
        const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
        date = fecha.getDate() + ' ' + monthNames[fecha.getMonth()] + ' ' + fecha.getFullYear();
        return { date, date_state };
    }

    public paymentsCertification(certification_id) {
        return new Promise(resolve => {
            this.getPaymentsCertification(certification_id).subscribe(data => {
                if (data.valid) {
                    const { pending_payment, date } = data.print[0];
                    if (pending_payment && date) {
                        const response = Object.assign({ pending_payment }, this.dateCertification(date));
                        resolve(response);
                    }
                }
                resolve({ pending_payment: false });
            });
        })
    }

    public updateLearningAvance(course) {
        let i = this.learning.findIndex(elemt => elemt.id == course.id);
        if (i != -1) {
            let temp = this.learning[i];
            this.learning = this.learning.filter(item => item.id != course.id).map((item) => { item.active = false; return item });
            this.learning.unshift(Object.assign(temp, { active: true }));
        } else {
            // Course of config not found in getLearning()
        }
    }

    getCategoryInfo(category_id): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/categories/' + category_id + '?language=1', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getCategoryInfo', []))
        );
    }

    categories: Array<any> = [];
    loadingCategories: boolean = false;
    categoriesSubject: Subject<any> = new Subject<any>();
    getCategory(type: boolean = false): Observable<any> {
        this.loadingCategories = true;
        return this.http.get(this.baseLambdaUrl + '/categories' + (type ? '/custom' : ''), this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    if (response.data.length > 0) {
                        this.categories = response.data;
                        this.categoriesSubject.next(response.data);
                    }
                    this.loadingCategories = false;
                    return { valid: true, print: response.data };
                } else {
                    this.categories = [];
                    this.loadingCategories = false;
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getCategory', []))
        );
    }

    getCampaignsInfo(campaing_id): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/campaings/' + campaing_id + '?language=1', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getCampaignsInfo', []))
        );
    }

    campaigns: Array<any> = [];
    getCampaigns(category_id): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/campaings?category_id=' + category_id + '&language=1', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getCampaigns', []))
        );
    }

    courses: Array<any> = [];
    getCuorses(campaingId): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/campaings/' + campaingId + '/cursos', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }), catchError(this.handleError<any>('getCuorses', []))
        );
    }

    getCourseInfo(courseId): Observable<any> {
        const fields = ["id", "nombre", "slug", "descripcion", "tematicas", "visitas", "language_id"];
        return this.http.get(this.baseLambdaUrl + '/cursos/' + courseId + '?fields=' + JSON.stringify(fields), this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }), catchError(this.handleError<any>('getCourseInfo', []))
        );
    }

    getCertification(paramDinamic): Observable<any> {
        // const fields = ["id", "calification", "estado", "fecha_inscripcion", "tour", "clase_id", "source_id", "quiz_id", "language_id"];
        // const fields_2 = ["fecha", "fecha_inscripcion", "id", "clase_id", "source_id", "quiz_id", "language_id", "tour", "estado"];

        // let url_request = this.baseLambdaUrl + '/certifications?user_id=' + this.user_data.id + '&' + (paramDinamic.hasOwnProperty('certification_id') ? 'certification_id=' + paramDinamic.certification_id + '&fields=' + JSON.stringify(fields) : 'curso_id=' + paramDinamic + '&fields=' + JSON.stringify(fields_2));

        let url_request = this.baseLambdaUrl + '/dynamo/certifications?user_id=' + this.user_data.id + '&' + 'curso_id=' + paramDinamic;

        return this.http.get(url_request, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    if (response.data.length > 0) {
                        return { valid: true, print: response.data[0], id: paramDinamic };
                    }
                    return { valid: false, print: 'Answer without data', id: paramDinamic };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry + 6),
            catchError(this.handleError<any>('getCertification', []))
        );
    }

    enrollUser(body): Observable<any> {
        return this.post(this.baseLambdaUrl + '/certifications', body).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry + 6),
            catchError(this.handleError<any>('setRanking', []))
        );
    }

    getCourseTab(curso_id, type): Observable<any> {
        return this.http.get(this.relativeUrl + '/get_server_data.json?curso_id=' + curso_id + '&option=' + type, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(0, { x: response });
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            catchError(this.handleError<any>('getCourseTab', []))
        );
    }

    setRanking(json): Observable<any> {
        return this.post(this.relativeUrl + '/ranking_add.json', json).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(0, { x: response });
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            catchError(this.handleError<any>('setRanking', []))
        );
    }

    startNotificationsPush(): void {
        let isCompatible = (
            'Notification' in window &&
            'serviceWorker' in navigator &&
            'PushManager' in window
        );

        // Validate support for push notifications
        if (isCompatible) {
            // console.log('Service Worker and Push are supported');
            if (Notification.permission === 'default') {
                this.popUpData = {
                    show: true,// show popup
                    shadow: false,// show shadow
                    close: true,// show icon close
                    type: 'confirm',// info, confirm, warning, error
                    class: 'desktop-notification',// inner class to edit
                    position: 'top center',// full + [top, middle, bottom + left, center, right], none
                    title: 'Notificaciones del curso',
                    msg: "¿Deseas interactuar con el profesor y otros estudiantes del curso?",
                    timer: 60000,// time to close popup
                    cancel: 'Cancelar',// inner button cancel to edit
                    accept: 'Aceptar',// inner button accept to edit
                };
                if (localStorage.getItem('desk_push')) {
                    let permission = localStorage.getItem('desk_push');
                    if (permission == 'true') {
                        this.show_desktop_notification = true;
                        localStorage.setItem('desk_push', JSON.stringify(true));
                        localStorage.removeItem('date_push');
                    } else {
                        if (localStorage.getItem('date_push')) {
                            let date = localStorage.getItem("date_push");
                            if (new Date().getDate() == new Date(date).getDate()) {
                                this.show_desktop_notification = true;
                                localStorage.setItem('desk_push', JSON.stringify(true));
                                localStorage.removeItem('date_push');
                            } else {
                                this.show_desktop_notification = false;
                                localStorage.setItem('desk_push', JSON.stringify(false));
                            }
                        } else {
                            this.show_desktop_notification = false;
                            localStorage.setItem('desk_push', JSON.stringify(false));
                            // Create date of return
                            let date = new Date();
                            date.setDate(date.getDate() + 7);
                            localStorage.setItem("date_push", date.toDateString());
                        }
                    }
                } else {
                    this.show_desktop_notification = true;
                    localStorage.setItem('desk_push', JSON.stringify(true));
                }
            } else {
                this.show_desktop_notification = false;
                localStorage.removeItem('desk_push');
                localStorage.removeItem('date_push');
            }
        } else {
            // console.warn('Push messaging is not supported');
            this.show_desktop_notification = false;
            localStorage.removeItem('desk_push');
            localStorage.removeItem('date_push');
        }
    }

    openCourse(course): void {
        if (course && course.hasOwnProperty('id')) {

            let config = {
                curso_id: course.id,
                certification_id: course.certification_id,
                type: course.tipo,
                clase_id: course.clase_id,
                idu: this.user_data.id,
                source_id: course.source_id,
                quiz_id: course.quiz_id,
                language_id: course.language_id,
                state: course.certification_state,
                tour: course.certification_tour
            }

            // update learning
            this.learning = this.learning.filter(item => item.id != course.id).map((item) => { item.active = false; return item });
            this.learning.unshift(Object.assign(course, { active: true }));

            sessionStorage.setItem('config', JSON.stringify(config));
            this.router.navigateByUrl('/classroom?config=' + JSON.stringify(config));
        }
    }

    searchText: string = "";
    getResultSearch(text, start): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/cursos/search?search_string="' + text + '"&start=' + start, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(0, { x: response });
                if (valData.is) {
                    return { valid: true, print: response.data, metadata: response.metadata };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getResultSearch', []))
        );
    }

    resultsShoppingCar: Array<any> = [];
    getShoppingCart(): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/payments?estado=0&order_by="id"', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    response.data = response.data.map((item) => {
                        item = item.Format;
                        if (item.type == 0 || item.type == 2) {
                            item.image_url = 'https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg';
                        }
                        item.url = this.url_Base + item.url;
                        // https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg
                        return item;
                    })
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getShoppingCart', []))
        );
    }

    getPaymentsCertification(certification_id): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/payments?certification_id=' + certification_id, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getShoppingCart', []))
        );
    }

    deleteItemShoppingCart(id): Observable<any> {
        return this.delete(this.baseLambdaUrl + '/payments/' + id).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is || (response.hasOwnProperty('amount') && response.amount > 0)) {
                    return { valid: true, print: response.status };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getShoppingCartDelete', []))
        );
    }

    notifications: any = {
        count: 0,
        all: []
    }
    notifications_loaded: boolean = false;
    more_notifications: boolean = false;
    getNotifications(fecha): Observable<any> {

        var pagination = { "user_id": this.user_data.id, "_date": fecha };
        // var fields = ["sender.name", "state"]; fields=' + JSON.stringify(fields) + '&
        return this.http.get(this.baseLambdaUrl + '/notifications' + ((fecha) ? '?LastEvaluatedKey=' + JSON.stringify(pagination) : ''), this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    response.data = response.data.map((item) => {
                        item = {
                            descripcion: item.message.body,
                            estado: item.state,
                            fecha: item._date,
                            link: item.message.url,
                            sender: item.sender,
                            _type: item._type
                        }
                        return item;
                    })
                    return { valid: true, print: response.data, metadata: response.metadata };
                } else {
                    return { valid: false, print: valData.msg };
                }

            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getNotifications', []))
        );
    }

    getNotificationsUnreaded(): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/notifications/count?state=1', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    if (response.data[0]) response.data = response.data[0].count;
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getNotificationsUnreaded', []))
        );
    }

    setStateNotification(id, body): Observable<any> {
        return this.put(this.baseLambdaUrl + '/notifications' + ((id) ? '/' + id : ''), body).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is || (response.hasOwnProperty('status') && response.status)) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('setAllReaded', []))
        );
    }

    deleteNotification(id): Observable<any> {
        return this.delete(this.baseLambdaUrl + '/notifications/' + id).pipe(
            map((response: any) => {
                return response;
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('deleteNotification', []))
        );
    }

    updateSubscriptionOnServer(json): Observable<any> {
        return this.post(this.baseLambdaUrl + '/users/' + this.user_data.id + '/notifications/subscriptions', json).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            catchError(this.handleError<any>('updateSubscriptionOnServer', []))
        );
    }


    //graphql
    heartbeatQuery: QueryRef<any>;
    conversationsQuery: QueryRef<any>;
    statusQuery: QueryRef<any>;
    heartbeatObservable: Observable<any>;
    conversationsObservable: Observable<any>;

    conversations: Array<any> | any = null;
    current_conversation_index: Integer = -1;
    nextToken: String = null;
    conversation_end: boolean = true;
    messageCounter: number = 0;

    chatRoomMain: boolean = false;

    static GET_CONVERSATIONS = gql`
    query get_user_conversation_connection_through_user($after: String, $first: Int) {
        me {
            conversations(first: $first, after: $after) {
                nextToken
                userConversations {
                    associated {
                        user {
                            user_id
                            user_name
                            user_image
                            user_role
                            user_fuente
                        }
                        state
                        user_role
                    }
                    conversation {
                        id
                        name
                        type
                        image
                    }
                    last_message
                    last_update
                    unreaded_messages
                    state
                    user_role
                }
            }
        }
    }`;

    static GET_MORE_CONVERSATIONS = gql`
    query get_user_conversation($after: String, $first: Int) {
        allUserConversationConnection(after: $after, first: $first) {
            nextToken,
            userConversations {
                associated {
                    user {
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                    state
                    user_role
                }
                conversation {
                    id
                    name
                    type
                    image
                }
                last_message
                last_update
                unreaded_messages
                state
                user_role
            }
        }
    }`;

    static NEW_CONVERSATION = gql`
    subscription subscribeToNewUCs($user_id: ID!) {
        subscribeToNewUCs(user_id: $user_id) {
            conversation {
                id
                name
                type
                image
            }
            associated {
                user {
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                state
                user_role
            }
            user_id
            last_message
            last_update
            unreaded_messages
            state
            user_role
        }
    }`;

    static STATUS = gql`
    subscription subscribeStatus($id: ID!) {
        onStatus(id: $id) {
            id
            status
        }
    }`;

    static COURSE_STATUS = gql`
    subscription subscribeCourseStatus($course_id: ID) {
        onStatus(course_id: $course_id) {
            id
            status
        }
    }`;

    static INPUT_STATUS = gql`
    subscription subscribeInputStatus($user_id: ID!) {
        subscribeToInputState(user_id: $user_id){
            user_id
            conversation_id
            input_state
        }
    }`;


    static GET_CACHED_CONVERSATION = gql`
    fragment active_conver on Conversation {
        id
        name
        image
        type
        messages
        next_token
    }
    `;

    static WRITE_CACHED_CONVERSATION = gql`
    fragment active_conver on Conversation {
        next_token
        messages
    }
    `;

    static WRITE_PENDING_MESSAGES = gql`
    fragment active_conver on Conversation {
        pending_messages
    }
    `;

    static DELETE_CONVERSATION = gql`
    mutation deleteConversation($conversation_id: ID!, $state: String!) {
        su: updateUserConversations(conversation_id: $conversation_id, state: $state){
            conversation {
                id
                name
            }
        }
    }`;

    static CONNECT_USER = gql`
    mutation connectUser($id: ID!) {
        cu: connect(id: $id){
            id
            status
        }
    }`;

    static CONNECT_USER_BY_COURSE = gql`
    mutation connectUser($id: ID!, $course_id: ID!) {
        cu: connect(id: $id, course_id: $course_id){
            course_id
            id
            status
        }
    }`;

    static DISCONNECT_USER_COURSE = gql`
    mutation disconnectUser($id: ID!, $course_id: ID!) {
        cu: disconnect(id: $id, course_id: $course_id){
            course_id
            id
            status
        }
    }`;

    static DISCONNECT_USER = gql`
    mutation disconnectUser($id: ID!) {
        cu: disconnect(id: $id){
            id
            status
        }
    }`;

    static HEARTBEAT = gql`
    query send_heartbeat($id: ID!) {
        heartbeat(id: $id){
            id
            status
        }
    }`;

    static HEARTBEAT_BY_COURSE = gql`
    query send_heartbeat($id: ID!,$course_id: ID!) {
        heartbeat(id: $id,course_id: $course_id){
            id
            status
        }
    }`;

    static GET_STATUS = gql`
    query get_status($id: ID!) {
        status(id: $id){
            id
            status
        }
    }`;

    static GET_COURSE_CONNECTED_USERS = gql`
    query course_status($course_id: ID!) {
        course_status(course_id: $course_id){
            users_id
        }
    }`;

    //neww
    static SET_UNREADED_MESSAGES = gql`
    mutation setUnreadMessages($conversation_id: ID!, $unreaded_messages: Int!) {
        su: updateUserConversations(conversation_id: $conversation_id, unreaded_messages: $unreaded_messages){
            conversation {
                id
                name
                type
                image
            }
            associated {
                user {
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                state
                user_role
            }
            user_id
            conversation_id
            last_message
            last_update
            unreaded_messages
            state
            user_role
        }
    }`;

    setHeartBeat() {
        this.heartbeatQuery = this._apolloClient.watchQuery({
            query: AppService.HEARTBEAT,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables: {
                id: this.user_data.id
            }
        });
        this.heartbeatObservable = this.heartbeatQuery.valueChanges.pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    sendHeartBeat() {
        this.heartbeatObservable.subscribe((data: any) => {
        });
    }
    initConversationsObjects() {
        this.conversationsQuery = this._apolloClient.watchQuery({
            query: AppService.GET_CONVERSATIONS,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables: {
                after: this.nextToken
            }
        });

        this.conversationsObservable = this.conversationsQuery.valueChanges.pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valConversations(response.data.me.conversations);
                if (valData.is) {

                    return {
                        valid: true,
                        print: response.data.me.conversations.userConversations.filter(element => {
                            return element.__typename == "UserConversations"
                                && (element.hasOwnProperty('associated') && (element.associated.length > 0 && (element.associated[0].hasOwnProperty('user') && element.associated[0].user)))
                                && element.hasOwnProperty('conversation')
                                && element.hasOwnProperty('last_message')
                                && element.hasOwnProperty('last_update')
                                && element.conversation;
                        }),
                        next: response.data.me.conversations.nextToken
                    };
                } else {
                    return { valid: false, print: valData.msg };
                }
            })
        );
    }


    connectUserToGraphQL(): Observable<any> {
        let variables = {
            id: this.user_data.id
        };

        return this._apolloClient.mutate({
            mutation: AppService.CONNECT_USER,
            variables: variables
        });
    }

    disconnectUserFromGraphQL(): Observable<any> {
        let variables = {
            id: this.user_data.id
        };

        return this._apolloClient.mutate({
            mutation: AppService.DISCONNECT_USER,
            variables: variables
        });
    }

    valConversations(data: any): any {
        if (data == null || data == undefined) {
            return { is: false, msg: 'Respond with null or undefined' };
        } else if (data.__typename == 'UserConverstationsConnection') {
            return { is: true, msg: 'Ok Value' };
        } else {
            return { is: false, msg: 'Response contains invalid data from Graph' };
        }
    }

    initSubscription() {
        const ws_headers = {
            "Authorization": AppService.idtoken,
            "host": this.ws_host
        };
        var th = this;
        setTimeout(function () {
            th.conversationsQuery.subscribeToMore({
                document: AppService.NEW_CONVERSATION,
                variables: {
                    user_id: th.user_data.id//user_id from token
                },

                extensions: {
                    authorization: ws_headers
                },
                updateQuery: (prev, { subscriptionData }) => {
                    // (":::updateQuery:::",subscriptionData.data.subscribeToNewUCs);
                    //evaluate to proccess a subscription if no message is sended
                    //replacing old object
                    if (subscriptionData.data.subscribeToNewUCs.state != "DELETED") {

                        //move to 0 position new object
                        let conv = th.conversations.findIndex(c => c.conversation.id === subscriptionData.data.subscribeToNewUCs.conversation.id);

                        if (conv > -1) {
                            th.conversations = th.conversations.map(function (con) {
                                if (con.conversation.id === subscriptionData.data.subscribeToNewUCs.conversation.id) {
                                    let convv = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewUCs));

                                    if (convv.associated.length === 1) {
                                        //TODO: manage states in individual conversations
                                        convv.associated[0].state = "ACCEPTED";

                                        if (!convv.associated[0].user.status) {
                                            convv.associated[0].user.status = 'offline';

                                            th.statusQuery = th._apolloClient.watchQuery({
                                                query: AppService.GET_STATUS,
                                                errorPolicy: 'all',
                                                fetchPolicy: 'network-only',
                                                variables: {
                                                    id: convv.associated[0].user.user_id
                                                }
                                            });

                                            th.statusQuery.valueChanges.pipe(
                                                map((response: any) => {
                                                    convv.associated[0].user.status = response.data.status.status;
                                                    return response;
                                                })
                                            ).subscribe((data: any) => { });

                                            th.statusQuery.subscribeToMore({
                                                document: AppService.STATUS,
                                                variables: {
                                                    id: convv.associated[0].user.user_id
                                                },

                                                extensions: {
                                                    authorization: ws_headers
                                                },
                                                updateQuery: (prev, { subscriptionData }) => {
                                                    //evaluate to proccess a subscription if no message is sended
                                                    //replacing old object
                                                    convv.associated[0].user.status = subscriptionData.data.onStatus.status;
                                                }
                                            });
                                        } else {
                                            convv.associated = con.associated;
                                        }
                                    } else {
                                        if (convv.associated.length === 0) {
                                            convv.associated = con.associated;
                                        }
                                    }

                                    return convv;
                                } else {
                                    return con;
                                }
                            });
                            th.array_move(th.conversations, conv, 0);

                            //Se lanza el observador para que se abra el livechat del mensaje que acaba de llegar
                            if (!th.chatroom_state) {
                                th.notification_msg_subject.next(th.conversations[0]);
                            }

                            if (conv === th.current_conversation_index) {
                                th.current_conversation_index = 0;
                            } else {
                                if (th.current_conversation_index < conv) {
                                    th.current_conversation_index += 1;
                                }
                            }

                        } else {

                            subscriptionData.data.subscribeToNewUCs = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewUCs));
                            subscriptionData.data.subscribeToNewUCs.associated.forEach(e => {
                                e.user.status = 'offline';

                                th.statusQuery = th._apolloClient.watchQuery({
                                    query: AppService.GET_STATUS,
                                    errorPolicy: 'all',
                                    fetchPolicy: 'network-only',
                                    variables: {
                                        id: e.user.user_id
                                    }
                                });

                                th.statusQuery.valueChanges.pipe(
                                    map((response: any) => {

                                        e.user.status = response.data.status.status;
                                        return response;
                                    })
                                ).subscribe((data: any) => { });

                                th.statusQuery.subscribeToMore({
                                    document: AppService.STATUS,
                                    variables: {
                                        id: e.user.user_id
                                    },

                                    extensions: {
                                        authorization: ws_headers
                                    },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        //evaluate to proccess a subscription if no message is sended
                                        //replacing old object
                                        e.user.status = subscriptionData.data.onStatus.status;
                                    }
                                });
                            });
                            th.conversations.unshift(subscriptionData.data.subscribeToNewUCs);
                            //Se lanza el observador para que se abra el livechat del mensaje que acaba de llegar
                            if (!th.chatroom_state) {
                                th.notification_msg_subject.next(th.conversations[0]);
                            }
                            th.current_conversation_index += 1;
                        }

                        if (AppService.current_opened_conversation == undefined || AppService.current_opened_conversation != subscriptionData.data.subscribeToNewUCs.conversation.id) {
                            if (subscriptionData.data.subscribeToNewUCs.unreaded_messages === 1) {
                                th.messageCounter++;
                            }
                            if (this.assistOnline === false) {
                                th.playTone();
                            }
                        }
                        th.saveApolloCache(subscriptionData.data.subscribeToNewUCs.conversation.id);
                    } else {
                        let conv = th.conversations.findIndex(c => c.conversation.id === subscriptionData.data.subscribeToNewUCs.conversation.id);
                        if (conv > -1) {
                            th.conversations.splice(conv, 1);
                        }
                    }
                    
                }
            });
        }, 1000);
    }



    array_move(arr, old_index, new_index): [] {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    }



    playTone() {
        let audio = new Audio();
        audio.src = "./assets/sound/tone.wav";
        audio.load();
        audio.play();
    }

    clear_created_conversations(): void {
        this.conversations = this.conversations.filter(element => {
            return element.state != 'CREATED';
        });
    }

    saveApolloCache(id): void {
        /*const conver = this._apolloClient.getClient().readFragment({
            id: 'Conversation:' + id,
            fragment: AppService.GET_CACHED_CONVERSATION,
        });*/

        this._apolloClient.getClient().writeFragment({
            id: 'Conversation:' + id,
            fragment: AppService.WRITE_CACHED_CONVERSATION,
            data: {
                messages: [],
                next_token: null
            },
        });
    }

    getMoreConversations(): Observable<any> {
        this.conversationsQuery = this._apolloClient.watchQuery({
            query: AppService.GET_MORE_CONVERSATIONS,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables: {
                after: this.nextToken
            }
        });

        return this.conversationsQuery.valueChanges.pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valConversations(response.data.allUserConversationConnection);
                if (valData.is) {
                    return {
                        valid: true,
                        print: response.data.allUserConversationConnection.userConversations.filter(element => {
                            return element.__typename == "UserConversations"
                                && element.hasOwnProperty('associated')
                                && element.hasOwnProperty('conversation')
                                && element.hasOwnProperty('last_message')
                                && element.hasOwnProperty('last_update')
                                && element.conversation;
                        }),
                        next: response.data.allUserConversationConnection.nextToken
                    };
                } else {
                    return { valid: false, print: valData.msg };
                }
            })
        );
    }

    //graphql



    conversation_metadata: any = {};
    conversationId: String;

    openChatRoom(conversationId: any): void {
        /*if (conversationId) {
            if (conversationId == -1) {
                this.chat_user = { execute: 1, data: [] };
                this.chatroom_state = true;
            } else {
                if (this.conversations.length > 0) {
                    this.conversationId = conversationId;
                    let i = this.conversations.findIndex(elemt => elemt.conversation.id == this.conversationId);
                    if (i != -1) {
                        this.chat_user = { execute: 0, data: this.conversations[i] };
                        this.chatroom_state = true;
                    }
                }
            }
        }*/
    }

    /*setStateConversation(user_id2, body): Observable<any> {
        // Metodo utilizado en la nav-bar
        return this.put(this.baseLambdaUrl + '/conversations/' + user_id2, body).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is || (response.hasOwnProperty('row_count') && response.row_count > 0)) {
                    return { valid: true, print: response.status };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('setStateConversation', []))
        );
    }*/

    //neww1
    deleteConversation(conversation_id): Observable<any> {
        let variables = {
            "conversation_id": conversation_id,
            "state": 'DELETED'
        };

        this.delete(this.baseLambdaUrl + '/conversations/' + conversation_id).subscribe();

        return this._apolloClient.mutate({
            mutation: AppService.DELETE_CONVERSATION,
            variables: variables
        });
    }


    setStateConversation(variables): Observable<any> {
        return this._apolloClient.mutate({
            mutation: AppService.SET_UNREADED_MESSAGES,
            variables: variables
        });
    }

    //neww1
    unit: Array<any> = [];
    getUnit(courseId): Observable<any> {
        return this.http.get(this.baseLambdaUrl + '/cursos/' + courseId + '/modules', this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getUnit', []))
        );
    }

    putUser(body): Observable<any> {
        return this.put(this.baseLambdaUrl + '/users/' + this.user_data.id, body).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(2, response);
                if (valData.is || (response.hasOwnProperty('status') && response.status)) {
                    return { valid: true, print: response.data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            retry(this.baseRetry),
            catchError(this.handleError<any>('getUnit', []))
        );
    }

    isJsonString(strJson: string): boolean {
        // Validar JSON
        try {
            JSON.parse(strJson);
        } catch (e) {
            return false;
        }
        return true;
    }

    isError(resData: any, errorName: string): any {
        // Validar datos de JSON, Error...
        if (this.isJsonString(JSON.stringify(resData))) {
            if (resData == null || resData == undefined) {
                return { is: false, msg: 'Answer with error' };
            } else if (resData instanceof Array) {
                return { is: true, msg: 'Ok Array' };
            } else if (resData.hasOwnProperty('data')) {
                if (resData.data == null || resData.data == undefined) {
                    return { is: false, msg: 'Answer with error' };
                } else if (resData.data instanceof Array) {
                    return { is: true, msg: 'Ok Array' };
                } else if (resData.data.hasOwnProperty('Status')) {
                    if (resData.data.Status == false) {
                        localStorage.clear();
                        sessionStorage.clear();
                        // window.location.href = this.url_Base + "/users/login";
                        return { is: false, msg: 'Answer with error' };
                    } else {
                        return { is: true, msg: 'Ok Value' };
                    }
                } else {
                    return { is: true, msg: 'Ok Value' };
                }
            } else {
                return { is: true, msg: 'Ok Value' };
            }
        } else {
            return { is: false, msg: 'Invalid json response' };
        }
    }

    isErrorLambda(resData: any): any {
        if (this.isJsonString(JSON.stringify(resData))) {
            if (resData == null || resData == undefined) {
                return { is: false, msg: 'Respond with null or undefined' };
            } else if (resData.hasOwnProperty('status')) {
                if (resData.status) {
                    if (resData.hasOwnProperty('data')) {
                        return { is: true, msg: 'Ok Value' };
                    } else {
                        return { is: false, msg: 'Response does not contain the [data] property' };
                    }
                } else {
                    return { is: false, msg: 'Response contains false value in [status] property' };
                }
            } else {
                return { is: false, msg: 'Response does not contain the [status] property' };
            }
        } else {
            return { is: false, msg: 'Response does not contain a valid json' };
        }
    }

    valData(type: number, params: any): any {
        switch (type) {
            case 0:
                // Default
                return this.isError(params.x, 'Error');
            case 1:
                return this.isError(params.x, params.y);
            case 2:
                return this.isErrorLambda(params);
            default:
                return {
                    is: false,
                    msg: 'Invalid parameter type:(' + type + ') in valData'
                };
        }
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.log(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
    private log(message: string) {
        // console.log(`ClassroomService: ${message}`);
    }


    relativeUrl = this.url_Base + '/libraries/myLibrary';
    _getCourseToRedirect(comment_id): Observable<any> {
        // http://34.224.84.165/apis/learn/go_to_comments.json?comment_id=
        return this.http.get('https://edutin.com/apis/learn/go_to_comments.json?comment_id=' + comment_id, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(0, { x: response });
                if (valData.is) {
                    return { valid: true, print: response.data, id: comment_id };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            catchError(this.handleError<any>('_getCourseToRedirect', []))
        );
    }

    getCourseToRedirect(comment_id): Observable<any> {
        // http://34.224.84.165/apis/learn/go_to_comments.json?comment_id=
        // https://37lyp7qtzf.execute-api.us-east-1.amazonaws.com/dev/comments/168687/config
        return this.http.get(`${UrlLambdaApi}/comments/${comment_id}/config`, this.getHeaders()).pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valData(0, { x: response });

                if (valData.is) {

                    let valid = true;
                    let data: any = {};

                    if (response.status) {

                        const { Curso } = response.data[0];
                        data = { comment_id, course_name: Curso.nombre, course_id: Curso.id }

                    } else {

                        valid = false;

                    }

                    return { valid: valid, print: data };
                } else {
                    return { valid: false, print: valData.msg };
                }
            }),
            catchError(error => {
                return of(error)
            })
        );
    }


    goToApp() {
        const { certification_id } = this.user_data;

        let init = sessionStorage.getItem('init');

        if (init == null) {

            if (certification_id != null && certification_id != '') {

                this.getLastCertification(certification_id).subscribe(response => {

                    const { data, status } = response;
                    sessionStorage.setItem('init', 'true');

                    if (status) {

                        const { clase_id, curso_id, estado, id, language_id, quiz_id, source_id, tipo, tour, user_id } = data[0].Certification;

                        let config = {
                            curso_id: curso_id,
                            certification_id: id,
                            type: tipo,
                            clase_id: clase_id,
                            idu: user_id,
                            source_id: source_id,
                            quiz_id: quiz_id,
                            language_id: language_id,
                            state: estado,
                            tour: tour != null ? tour : '-1'
                        };

                        if (curso_id != null && curso_id != '' && clase_id != null && clase_id != '') {
                            return this.router.navigateByUrl('/classroom?config=' + JSON.stringify(config));
                        } else {
                            return this.router.navigate(['/category']);
                        }

                    } else {
                        return this.router.navigate(['/category']);
                    }

                })
            } else {
                // return this.router.navigate(['/category']);
            }

        }
    }


    getLastCertification(certification_id): Observable<any> {

        return this.http.get(`${UrlLambdaApi}/certifications/${certification_id}?fields=["id","clase_id","curso_id","source_id","quiz_id","estado","tour","language_id","tipo","user_id"]`, this.getHeaders())
            .pipe(map((certification: any) => {
                return certification
            }),
                catchError(this.handleError<any>('getLastCertification', []))
            );
    }


    closeLiveChatSubject: Subject<any> = new Subject<any>();
    closeLiveChatObservable = this.closeLiveChatSubject.asObservable();

    closeLiveChat() {

        this.chatroom_state = false;
        this.closeLiveChatSubject.next();

    }


    search_user(search_string): Observable<any> {
        // https://api.edutin.com/b/d
        return this.http.get(UrlLambdaApi + '/users/search?search_string="' + search_string + '"&start=0', this.getHeaders());
    }




    onlineUserNotificationSubject: Subject<any> = new Subject<any>();

    onlineUserNotification(notification) {
        this.onlineUserNotificationSubject.next(notification);
    }




    registerChatUser(data: any): Observable<any> {
        return this.http.post(UrlLambdaApi + '/chat_users_conversations_relations', { data }, this.getHeaders()).pipe(retry(3));
    }




    registerServedUser(served: any): Observable<any> {
        return this.http.post(`${this.baseLambdaUrlA}/users/stats/supports`, { served }, this.getHeaders()).pipe(retry(3));
    }
}
