<div id="main-new-courses">
  <div class="header">
    <h3>Nuevos lanzamientos</h3>
  </div>
  <div class="content">
    <div #carousel class="carousel">
      <div #rowCards_ class="row">
        <div class="cards">
          <!-- [ngClass]="{loading: (campaignId == campaignIdUrl && courseId == course.id) || courseActive==course.id}"  -->
          <div *ngFor="let course of appService.newCourses;let i = index;" [style.max-width.px]="cardWidth"
            [style.min-width.px]="cardWidth" class="card" [id]="'course_card_'+course.id+'_'+campaignId">
            <a class="content" [href]="environment.domain + '/' + course.slug" target="_blank">
              <div class="dimmable">
                <div class="content-dimmer">
                  <div class="loader-content loading-Element">
                    <!-- <div class="loader-circle"></div> -->
                  </div>
                  <!-- <div (click)="closeCourse(i)" id="close" class="button">Cerrar</div>
                      <div (click)="openCourse(course,i)" id="open" class="button">Abrir</div> -->
                </div>
                <div class="content-img">
                  <img #img [src]="'https://d3puay5pkxu9s4.cloudfront.net/curso/'+course.id+'/card_imagen.jpg'"
                    (error)="img.src='https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg'"
                    alt="{{course.name}}">
                </div>
              </div>
              <div #element class="description">
                <h4 [title]="course.name">{{course.name}}</h4>
                <div class="label_certificated">
                  <label>Titulo a certificar:</label>
                  <p [ngClass]="{'long-title': (element|cssChildHeight)>20}"
                    [attr.data-tooltip]="course.nombre_certificado">
                    {{course.nombre_certificado}}</p>
                </div>
              </div>
            </a>
          </div>
          <ul *ngIf="appService.loadNewCourses" class="loadCourses">
            <li [style.width.px]="cardWidth-10" [style.height.px]="cardWidth*70/100" class="loading-Element"></li>
            <li [style.width.px]="cardWidth-10" [style.height.px]="cardWidth*70/100" class="loading-Element"></li>
            <li [style.width.px]="cardWidth-10" [style.height.px]="cardWidth*70/100" class="loading-Element"></li>
            <li [style.width.px]="cardWidth-10" [style.height.px]="cardWidth*70/100" class="loading-Element"></li>
          </ul>
        </div>
      </div>
      <!-- Arrows -->
      <i #leftArrow_ [hidden]="appService.newCourses.length<=cardsShow || translate==0" (click)="desplaceCards(false)"
        id="leftArrow" class="displace left icon-arrow-left"></i>
      <i #rigthArrow_
        [hidden]="appService.newCourses.length<=cardsShow || (translateAbs+rowWidth)>=(appService.newCourses.length * cardWidth)"
        (click)="desplaceCards(true)" id="rigthArrow" class="displace right icon-arrow-right"></i>
      <div [hidden]="!appService.loadNewCoursesPage" class="displace right loadingLearning">
        <div class="spinner spinner--button" style="left: 0;">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
