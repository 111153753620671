import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';
import { AuthGuard } from './shared/guards/user/auth.guard';
import { CategoryGuard } from './shared/guards/category.guard';
import { ClassroomGuard } from './shared/guards/classroom.guard';
import { HomeGuard } from './shared/guards/home.guard';
import { SearchGuard } from './shared/guards/search.guard';
//import { EditGuard } from './shared/guards/user/edit.guard';
//import { AssistGuard } from './shared/guards/assist.guard';
import { DashboardGuard } from './shared/guards/dashboard.guard';
// import { MeetGuard } from './shared/guards/meet.guard';


export const APP_ROUTES: Routes = [
    { path: 'user', data: { preload: true, loadAfterSeconds: 1 }, loadChildren: () => import('./auth/user.module').then(mod => mod.AuthModule), canActivate: [AuthGuard] },
    { path: 'category', loadChildren: () => import('./category/category.module').then(mod => mod.CategoryModule), canActivate: [CategoryGuard] },
    { path: 'search', loadChildren: () => import('./search/search.module').then(mod => mod.SearchModule), canActivate: [SearchGuard] },
    { path: 'home', loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule), canActivate: [HomeGuard] },
    { path: 'classroom', data: { preload: true, loadAfterSeconds: 3 }, loadChildren: () => import('./classroom/classroom.module').then(mod => mod.ClassroomModule), canActivate: [ClassroomGuard], canDeactivate: [ClassroomGuard] },
    // { path: 'meet', loadChildren: () => import('./meet/meet.module').then(mod => mod.MeetModule), canActivate: [MeetGuard] },

    //Nuevas apps integradas
    // { path: 'assist', loadChildren: () => import('./assist/assist.module').then(mod => mod.AssistModule), canActivate: [AssistGuard] },
    { path: 'dashboard/:certification_id', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule), canActivate: [DashboardGuard] },
    { path: 'verify/:certification_id', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule), canActivate: [DashboardGuard] },
    // { path: 'designer', loadChildren: () => import('./designer/designer.module').then(mod => mod.DesignerModule) },
    // { path: 'recruitm', loadChildren: () => import('./recruitment/recruitment.module').then(mod => mod.RecruitmentModule) },
    // { path: 'teach', loadChildren: () => import('./teach/teach.module').then(mod => mod.TeachModule) },
    // { path: 'training', loadChildren: () => import('./training/training.module').then(mod => mod.TrainingModule) },
    //Nuevas apps integradas

    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: CustomPreloadingStrategyService });