import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AudioPlayerComponent } from './audio-player.component';
import { SafeStylePipe, AudioPlayeTimePipe, AudioPlayeDurationPipe, AudioPlayeSecondPipe } from '../chat-pipe.pipe';


@NgModule({
    declarations: [
        AudioPlayerComponent,
        SafeStylePipe,
        AudioPlayeTimePipe,
        AudioPlayeDurationPipe,
        AudioPlayeSecondPipe,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        AudioPlayerComponent
    ],
})
export class AudioPlayerModule { }
