<div id="popup-msg" *ngIf="config.show" [class]="config.class+' '+((config.shadow)?'full':'')+' '+config.position">
  <div id="shadow" (click)="config.show=false;" *ngIf="config.shadow"></div>
  <div id="background" [class]="((config.shadow)?'':'none')+' '+config.position">
    <span (click)="config.show=false;" *ngIf="config.close" class="icon-close"></span>
    <div *ngIf="config.type=='info'" class="info">
      <div class="content">
        <div id="title" [innerHTML]="config.title"></div>
        <div id="msg" [innerHTML]="config.msg"></div>
      </div>
    </div>
    <div *ngIf="config.type=='confirm'" class="confirm">
      <div class="content">
        <div id="title" [innerHTML]="config.title"></div>
        <div id="msg" [innerHTML]="config.msg"></div>
        <div id="btns">
          <div id="cancel" class="btn-msg" (click)="confirm(false)" [innerHTML]="config.cancel"></div>
          <div id="accept" class="btn-msg" (click)="confirm(true)" [innerHTML]="config.accept"></div>
        </div>
      </div>
    </div>
    <div *ngIf="config.type=='warning'" class="warning">
      <div class="content">
        <div id="title" [innerHTML]="config.title"></div>
        <div id="msg" [innerHTML]="config.msg"></div>
      </div>
    </div>
    <div *ngIf="config.type=='error'" class="error">
      <div class="content">
        <div id="title" [innerHTML]="config.title"></div>
        <div id="msg" [innerHTML]="config.msg"></div>
      </div>
    </div>
  </div>
</div>
