import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {

  constructor(private _appService: AppService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { valid, session, print } = this._appService.getUserData();
    // Cognito User
    if (valid) {
      // User with session
      if (session == 1) {
        const { certification_id } = print;
        // User with certification
        if (certification_id && certification_id != '0' && certification_id != '-1') {
          // Show nav bar and footer
          this._appService.loadNavBar = this._appService.loadFooter = true;
          // Init gets nav bar
          this._appService.startGetsNavBar();
          // Go
          return true;
        }
      } else {
        // Identify error in main route
        if (state.url.includes('error_description')) {
          if (state.url.includes('ERR11')) {
            this._appService.authenticated_error = 'ERR01';
          }
          // Error email facebook
          return this._router.parseUrl('/user/login');
        }
      }
      // User without session or without certification  
      return this._router.parseUrl('/category');
    }
    // Cognito User error
    return this._router.parseUrl('/user/login');
  }

}
