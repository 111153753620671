<div (clickOutsideDdown)="showShoppingCar=false" [ngClass]="{'menu-mobile-open': showMenu || showShoppingCar}"
  class="showpping-car dropdown-all-menu">
  <span (click)="toggleShoppingCar()" *ngIf="showShoppingCar" class="icon-cross"></span>
  <div (click)="toggleShoppingCar()"
    [ngClass]="{'open':showShoppingCar, 'label':_appService.resultsShoppingCar.length>0}" class="item">
    <span class="icon-shopping-cart"></span>
    <div class="label-red">{{_appService.resultsShoppingCar.length}}</div>
  </div>

  <div class="menu">

    <div class="header">
      <div class="head-title">
        Carrito
      </div>
    </div>

    <ul>

      <li *ngIf="_appService.resultsShoppingCar.length==0" class="product empty">
        <div>No tiene productos por comprar</div>
      </li>
      <li *ngFor="let itemCart of _appService.resultsShoppingCar" (click)="goToLink(itemCart.url)" class="product">
        <div class="img">
          <img [src]="itemCart.image_url">
        </div>
        <div class="content">
          <h4 class="type">{{itemCart.type | shoppingCarType}}</h4>
          <h4 class="title">{{itemCart.title}}</h4>
          <div class="price">
            <h4>{{itemCart.value}}</h4>
            <div *ngIf="itemCart.discount!='0%' && itemCart.value!=itemCart.original_value" class="before">
              <h4>{{itemCart.discount}} Dto.</h4>
              <h4>{{itemCart.original_value}}</h4>
            </div>
          </div>
          <img #remove_ (click)="removeProductSP($event,itemCart,remove_);"
            src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/designer/remove.svg" class="remove-product">
        </div>
      </li>
    </ul>
  </div>

</div>
