<div *ngIf="(_appService.notifications.all!=null)" [ngClass]="{'menu-mobile-open': showMenu || showNotifications}"
  (clickOutsideDdown)="showNotifications=false;" class="notifications dropdown-all-menu">
  <span (click)="toggleNotifications()" *ngIf="showNotifications" class="icon-cross"></span>
  <div (click)="toggleNotifications()" [ngClass]="{'open':showNotifications, 'label':_appService.notifications.count>0}"
    class="item">
    <!-- <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-notification.svg" class="hover-out"
      alt="Notificaciones">
    <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-notification-hover.svg" class="hover-in"
      alt="Notificaciones"> -->


      <span class="icon-notification-bell"></span>

      
    <div class="label-red">{{_appService.notifications.count}}</div>
  </div>
  <div class="menu">
    <div class="header">
      <div class="head-title">Notificaciones
        <a *ngIf="_appService.notifications.count>0">
          <p id="set_all_readed" *ngIf="!reading" (click)="setAllReaded()">Marcar todas como leídas</p>
          <div class="spinner spinner--button" *ngIf="reading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </a>
      </div>
      <div style="display: none;">Notificaciones...</div>

      <div class="filters" *ngIf="_appService.notifications.all.length>0">
        <a class="all" [class.active]="notification_filter_selected == 'all'"
          (click)="updateNotificationList('all');">Todas</a>
        <a class="unread" [class.active]="notification_filter_selected == 'unread'"
          (click)="updateNotificationList('unread');">No leídas</a>
      </div>

      <div class="notification-label">
        <p *ngIf="!_appService.notifications_loaded">Cargando</p>
      </div>
      <p class="empty-notifications"
        *ngIf="_appService.notifications_loaded && _appService.notifications.all.length==0">No tiene
        notificaciones.</p>

    </div>
    <ul class="content">
      <li>
        <div class="new">
          <div #_newNotification *ngFor="let nueva of notifications_list;let i = index"
            (click)="goToLink(nueva, _newNotification);readNotification(i,nueva,1)"
            class="container-items {{ ((nueva.estado==1)? 'recent':'viewed') }}">
            <div #itemMenuN
              *ngIf="nueva._type == 'AQN' || nueva._type == 'PCN' || nueva._type == 'MAN' || nueva._type == 'NIB' || nueva._type == 'NIBG'"
              class="items"
              [class.active]="display_options_item_notification == nueva.fecha || load_notification == nueva.fecha">
              <div>
                <img [src]="nueva | urlImage: 0" [alt]="nueva.sender.username"
                  [class]="nueva.sender.role | userClassName">
              </div>
              <div class="content">
                <h4 [innerHTML]="nueva.descripcion"></h4>
                <div class="legend">
                  <h4>{{nueva.fecha | notificationsDate}}</h4>
                  <div class="options" (click)="configNotiSP($event,0,nueva)">
                    <span *ngIf="load_notification != nueva.fecha">...</span>
                    <div *ngIf="load_notification == nueva.fecha" class="loader-circle e-blue"></div>
                  </div>
                </div>

                <span class="dot-notification"></span>

              </div>
            </div>
            <div #itemMenuN *ngIf="nueva._type == 'NCN'" class="items"
              [class.active]="display_options_item_notification == nueva.fecha || load_notification == nueva.fecha">
              <div>
                <img [src]="'https://d3puay5pkxu9s4.cloudfront.net/curso/'+nueva.curso_id+'/card_imagen.jpg'"
                  alt="edutin" class="student-official">
              </div>
              <div class="content">
                <h4 [innerHTML]="nueva.descripcion"></h4>
                <div class="legend">
                  <h4>{{nueva.fecha | notificationsDate}}</h4>
                  <div class="options" (click)="configNotiSP($event,0,itemMenuN)">
                    <span *ngIf="load_notification != nueva.fecha">...</span>
                    <div *ngIf="load_notification == nueva.fecha" class="loader-circle e-blue"></div>
                  </div>
                </div>

                <span class="dot-notification"></span>

              </div>
            </div>

            <div class="visible"
              [ngStyle]="{'display': display_options_item_notification == nueva.fecha ? 'block' : 'none'}">
              <a (click)="configNotiSP($event,1,nueva)">Eliminar notificación</a>
              <a *ngIf="nueva.estado==1" (click)="configNotiSP($event,2,{d:nueva,e:'0'})" class="read">Marcar como
                leído</a>
              <a (click)="configNotiSP($event,3,{d:nueva,e:'1'})" class="no-read">Marcar como no leído</a>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div id="showMoreNotifications" *ngIf="_appService.more_notifications && _appService.notifications.all.length>0">
      <a (click)="configNotiSP($event,4,'')" *ngIf="!more_notifications_loaded">Ver más</a>
      <div *ngIf="more_notifications_loaded" class="loader"></div>
    </div>
  </div>
</div>
