<a class="return" *ngIf="_communityService.full_view.state && _communityService.config['comments'] == 'full'"
  (click)="doAction({ev: 'full-view', comment: items[0]})">
  <span> {{'<<'}} </span>
  Regresar
</a>
<div class="list" #itemList
  [ngClass]="{'full-item': _communityService.config.comments == 'full' || _communityService.config.contributions == 'full'}">

  <modal-dialog *ngIf="modal_dialog_.state"></modal-dialog>

  <div class="items-container" *ngIf="items!=null && items.length == 0">
    <div class="card empty">
      <p [innerHTML]="empty_text[reference]" *ngIf="items.length == 0">
      </p>
    </div>
  </div>

  <div class="items-container" *ngFor="let item of items; let i_item = index">

    <div class="card" [id]="item_selector_id+item['Comment'].id" #card_ *ngIf="item">
      <modal-dialog class="delete_comment" *ngIf="deleted_item_id == 'comment-'+item['Comment'].id"></modal-dialog>

      <div class="card-content">

        <div class="user_avatar_container">
          <img #studentAvatar class="avatar"
            (error)="studentAvatar.src=urlavatar+(item.User.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
            [src]="item.User|avatarUrlPipe">


          <div style="max-width: 40px;">
            <div class="dropdown-menu-options quiestion"
              *ngIf="(actions && current_user_data!=null) &&             
              (current_user_data.id==item['Comment'].user_id || current_user_data.role=='moderador' || current_user_data.role=='propietario')">

              <label class="icon-ellipsis-h" (contextmenu)="onRightClick($event)">
                <input class="dropdown-input" type="checkbox" #ddown_comment>
                <ul class="dropdown-content">
                  <li (click)="doAction({ev: 'edit', comment: item, element: card_}); ddown_comment.checked = false">
                    Editar
                  </li>
                  <li
                    (click)="doAction({ev: 'delete', item: item['Comment'], index: i_item }); ddown_comment.checked = false">
                    Eliminar</li>
                </ul>
              </label>
            </div>

          </div>


        </div>

        <div class="files">

          <div class="student">
            <a *ngIf="item.User.name != null && item.User.name != '' " [innerHTML]="item.User.name | cutUserName:'2'"
              [href]="'https://app.edutin.com/user/profile/' + item.User.id" target="_blank"></a>
            <span *ngIf="item.User.role != null && item.User.role != '' ">{{item.User.role | roleName}}</span>
            <span class="dot">
              <div></div>
            </span>
            <span>hace {{item['Comment'].fecha | timeAgoPipe}}</span>

            <!-- Pregunta solucionada -->
            <a class="comment_solved" *ngIf="item['Comment'].punto == 0"><i class="icon-tick-outline"></i> Resuelta</a>
          </div>

          <!-- editar una pregunta -->
          <community-participate #comment_edit id="comment-participate"
            *ngIf="comment_participate_component?.id == item.Comment.id && comment_type_case_participate == 'edit'"
            [current]="{lesson:_classroomService.data.lesson, source: _classroomService.data.source, modules:_classroomService.data.modules}"
            [item_to_edit]="comment_to_edit" [component]="'comments'"
            (updateJson)="updateJSONFromParticipateComponent($event)"
            [comment_type]="(item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0) ? 'participation' : 'question'"
            (closeParticipateComponentComment)="closeParticipateCommentComponent(item.Comment.id)"
            [data_user]="item.User">
          </community-participate>
          <!-- editar una pregunta -->


          <div class="sources-container"
            *ngIf="(item && comment_type_case_participate != 'edit') || (comment_participate_component?.id != item.Comment.id && comment_type_case_participate == 'edit')">


            <div
              *ngFor="let file of (comments_id_full_view.includes(item.Comment.id) ? item.Comment.files_comments : (item.Comment.files_comments | slice:0:3))"
              [className]="file.type">

              <p class="text" *ngIf="file.type == 'text'" [innerHTML]="file.description | setTarget_blank">
              </p>

              <div class="file_image"
                *ngIf="(file.file_ext!=null && (file.type == 'image' || (file.type =='file' && _communityService.img_formats.includes(file.file_ext)))) && file.file_url != null">
                <img
                  [ngStyle]="{'background-image': 'url('+((file.src != null && file.src != '') ? file.src : file.file_url)+')'}"
                  #img_item>
                <span class="icon-fullscreen" (click)="showImage(img_item)"></span>
              </div>

              <div class="file-type link" *ngIf="file.type == 'link'">
                <span>{{file.link}}</span>
                <div>
                  <a class="icon-send-arrow" [attr.data-hover]="'ir al enlace'" role="link"
                    (click)="openExternalLink(file.link)" target="_blank"></a>
                </div>
              </div>

              <div class="file-type"
                *ngIf="file.file_ext!=null && ((file.type == 'file' && !_communityService.img_formats.includes(file.file_ext)) || file.type == 'video')">

                <span>{{(file.type == 'video' ? 'video.' :'archivo.')+file.file_ext}}</span>
                <div>
                  <a class="icon-view" [attr.data-hover]="'visualizar archivo'"
                    (click)="doAction({ev: 'open-file', file: file})"
                    *ngIf="file_formats_toview.includes(file.file_ext)"></a>


                  <a class="icon-" style="height: 10px; width: 19px;margin-left: 8px;"
                    [attr.data-hover]="'Descargando archivo'" *ngIf="isLoading == file.id">
                    <div class="spinner spinner--button">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </a>

                  <a class="icon-download-bottom" [attr.data-hover]="'descargar archivo'" (click)="downloadFile(file)"
                    style="font-size: 13px;margin-left: 10px;" *ngIf="isLoading!=file.id"></a>
                </div>

              </div>
              <div ace-editor *ngIf="file.type == 'code'" [(text)]="file.description"
                [mode]="file.language ? file.language : 'html'" [theme]="'tomorrow_night'" [readOnly]="true"
                [autoUpdateContent]="true"></div>
            </div>


            <div class="view-all-answer"
              *ngIf="item.Comment.files_comments.length > 3 && !_communityService.full_view.state"
              (click)="doAction({ev: 'full-view', comment: item, comment_index: i_item})">              

              {{comments_id_full_view.includes(item.Comment.id) ? 'Ver menos...' : (item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0)
              ? '...Ver participación completa' : '...Ver pregunta completa'}}
            </div>

          </div>





        </div>

      </div>

      <div class="lesson-info">
        <a class="lesson-link" (click)="openUploadFromOtherSource(item)">
          <i class="icon-reply"></i>
          <div>

            <!-- <p *ngIf="item.class_info.Module">Unidad
              {{item.class_info.Module.order | getModuleOrder}}: <span [innerHTML]="item.class_info.Module.name"></span>
            </p> -->
            <p *ngIf="item.Modules && item.Modules.name">Unidad
              {{ item.Modules.order | getModuleOrder}}: <span [innerHTML]="item.Modules.name"></span>
            </p>

            <p>Clase: <span *ngIf="item.Clases != null && item.Clases[0] && item.Clases[0].titulo"
                [innerHTML]="item.Clases[0].titulo"></span>
            </p>


          </div>
        </a>
      </div>

    </div>


    <div class="card-footer parent-footer" #parentFooter>

      <div class="card-options">

        <div>

          <!-- Opciones de comentar y RESPONDER -->
          <div class="card-options--options">


            <a (click)="doAction({ev: 'giveLike', data: item, index_parent: i_item})">

              <div class="spinner spinner--button"
                *ngIf="giveLike && sending_comment_voto == item['Comment'].id; else likeCommentBttn">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <ng-template #likeCommentBttn>
                <span class="icon-like"
                  [ngClass]="{'active': (item.Votos!=null && item.Votos.length > 0 && item.Votos[0].valor == 1)}">
                </span>
              </ng-template>

              <label *ngIf="item['Comment'].like > 0">
                {{(item['Comment'].like > 0 ? item['Comment'].like : 0) | likePipe}}
              </label>
            </a>


            <a role="button"
              (click)="doAction({ev:'answer', comment:item, i_tem: i_item, parentElement: parentFooter, itemList: itemList})">
              {{(item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0) ? 'OPINAR': 'RESPONDER'}}
            </a>

          </div>
          <!-- END commentar y RESPONDER -->

          <div>
            <a *ngIf="item['Comment'].count_children > 0" (click)="getChildren(item, i_item)">
              {{_communityService.answers_parentId == item['Comment'].id ? 'Ocultar': 'Ver'}}
              {{item['Comment'].count_children}}
              {{(item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0) ? item['Comment'].count_children > 1 ? 'opiniones' : 'opinión'  :  item['Comment'].count_children > 1 ? 'respuestas' : 'respuesta' }}
              <i
                [ngClass]="_communityService.answers_parentId == item['Comment'].id ? 'icon-arrow-up': 'icon-arrow-down'"></i>
            </a>
          </div>

        </div>


      </div>


      <div class="card-footer--container"
        *ngIf="(_communityService.answers_parentId != null && _communityService.answers_parentId == item['Comment'].id) || (children_participate_component?.id == item.Comment.id && children_type_case_participate == 'answer')">


        <!-- crear o editar una respuesta/opinion -->
        <community-participate #children_new
          *ngIf="children_participate_component?.id == item.Comment.id && children_type_case_participate == 'answer'"
          [current]="{lesson:_classroomService.data.lesson, source: _classroomService.data.source, modules:_classroomService.data.modules}"
          [item_to_edit]="children_to_edit" [component]="'comments'"
          (updateJson)="updateJSONFromParticipateComponent($event); openChildren({event: $event, parentData: {item: item, i_item:i_item}})"
          [comment_type]="(item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0) ? 'opinion' : 'answer'"
          (closeParticipateComponentChild)="closeParticipateChildrenComponent()" [data_user]="current_user_data">
        </community-participate>
        <!-- crear o editar una respuesta/opinion -->





        <!-- section comments of parent -->
        <div class="card section-comments" [class.active]="opinions_.parent"
          *ngIf="opinions_.parent != null && (opinions_.parent.id == item['Comment'].id)">

          <modal-dialog *ngIf="opinion_modal_dialog_.state"></modal-dialog>

          <comment [_comment]="opinions_.new" [actions]="actions" [id_textarea]="'c-'+item['Comment'].id"
            *ngIf="opinions_.parent" [current_user_data]="current_user_data"
            (addOpinion)="doAction({ev: 'addOpinion', opinion: $event, index_parent: i_item})"
            (deleteOpinion)="doAction({ev:'delete_opinion', opinion: $event})" [comment_list]="opinions_.data"
            (eventOpinion)="showModalDialog(9)"></comment>

          <div class="spinner dotted" *ngIf="opinions_.data.length==0 && item['Comment'].count_opinions > 0">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

        </div>
        <!--END section comments of parent -->

        <div class="children"
          *ngIf="_communityService.answers_parentId != null && _communityService.answers_parentId == item['Comment'].id">

          <comment-loading class="children-loading" [amount]="item['Comment'].count_children"
            *ngIf="_communityService.answers[this.reference] == null"></comment-loading>

          <div class="card card-children" [class.active]="_communityService.answers_parentId == item['Comment'].id"
            *ngFor="let child of (parent_id_view_answer == item.Comment.id ? _communityService.answers[this.reference] : (_communityService.answers[this.reference] | slice:0:3)); let i_child = index"
            style="position: relative;">


            <community-participate #children_edit
              *ngIf="children_participate_component?.id == child.ChildrenComment.id &&  children_type_case_participate == 'editAnswer'"
              [current]="{lesson:_classroomService.data.lesson, source: _classroomService.data.source, modules:_classroomService.data.modules}"
              [item_to_edit]="children_to_edit" [component]="'comments'"
              (updateJson)="updateJSONFromParticipateComponent($event)"
              [comment_type]="(item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0) ? 'opinion' : 'answer'"
              (closeParticipateComponentChild)="closeParticipateChildrenComponent()" [data_user]="child.User">
            </community-participate>


            <modal-dialog *ngIf="deleted_item_id == 'answer-'+child.ChildrenComment.id"></modal-dialog>

            <div class="child-content" [class.active]="child_id_from_home == child.ChildrenComment.id"
              [id]="'child-'+child.ChildrenComment.id"
              [hidden]="children_participate_component?.id == child.ChildrenComment.id">

              <div class="card-content children-card-content">
                <div class="user_avatar_container">
                  <img class="child_avatar" #childStudentAvatar
                    (error)="childStudentAvatar.src=urlavatar+(child.User.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
                    [src]="child.User|avatarUrlPipe">


                  <div class="dropdown-menu-options"
                    *ngIf="current_user_data && ((current_user_data.id==child.ChildrenComment.user_id) || current_user_data.role=='moderador' || current_user_data.role=='propietario')">
                    <label class="icon-ellipsis-h" [for]="item['Comment'].id+'-'+child.ChildrenComment.id">
                      <input class="dropdown-input" [id]="item['Comment'].id+'-'+child.ChildrenComment.id"
                        type="checkbox" name="child" #ddown_answer>
                      <ul class="dropdown-content">
                        <li (click)="doAction({ev: 'editAnswer', comment: child});ddown_answer.checked = false">Editar
                        </li>
                        <li
                          (click)="doAction({ev: 'delete_answer', url: '/children_comments_delete.json', item: child.ChildrenComment}); ddown_answer.checked = false">
                          Eliminar</li>
                      </ul>
                    </label>
                  </div>

                </div>

                <div class="files">

                  <div class="student">
                    <a [href]="'https://app.edutin.com/user/profile/' + child.User.id"
                      target="_blank">{{child.User.name | cutUserName: '2'}}</a>
                    <span>{{child.User.role | roleName}}</span>
                    <span class="dot">
                      <div></div>
                    </span>
                    <span>hace {{child.ChildrenComment.date_creation | timeAgoPipe}}</span>
                  </div>


                  <div *ngFor="let childFile of child.ChildrenComment.files_comments" [className]="childFile.type">

                    <p class="text" *ngIf="childFile.type == 'text'"
                      [innerHTML]="childFile.description | setTarget_blank">
                    </p>

                    <div class="file_image"
                      *ngIf="(childFile.file_ext!=null && (childFile.type == 'image' || (childFile.type =='file' && _communityService.img_formats.includes(childFile.file_ext)))) && childFile.file_url != null">
                      <img
                        [ngStyle]="{'background-image': 'url('+((childFile.src != null && childFile.src != '') ? childFile.src : childFile.file_url)+')'}"
                        #img_child>

                      <span class="icon-fullscreen" (click)="showImage(img_child)"></span>
                    </div>

                    <div class="file-type link" *ngIf="childFile.type == 'link'">
                      <span>{{childFile.link}}</span>
                      <div>
                        <a class="icon-send-arrow" [attr.data-hover]="'ir al enlace'" role="link"
                          (click)="openExternalLink(childFile.link)" target="_blank"></a>
                      </div>
                    </div>

                    <div class="file-type"
                      *ngIf="childFile.file_ext!=null && ((childFile.type == 'file' && !_communityService.img_formats.includes(childFile.file_ext)) || childFile.type == 'video')">

                      <span>{{(childFile.type == 'video' ? 'video.' :'archivo.')+childFile.file_ext}}</span>
                      <div>
                        <a class="icon-view" [attr.data-hover]="'visualizar archivo'"
                          (click)="doAction({ev: 'open-file', file: childFile})"
                          *ngIf="file_formats_toview.includes(childFile.file_ext)"></a>
                        <a class="icon-download-bottom" [attr.data-hover]="'descargar archivo'"
                          style="font-size: 13px;margin-left: 10px;" href="childFile.file_url" download
                          target="_blank"></a>
                      </div>

                    </div>

                    <div ace-editor *ngIf="childFile.type == 'code'" [(text)]="childFile.description"
                      [mode]="childFile.language ? childFile.language : 'html'" [theme]="'tomorrow_night'"
                      [readOnly]="true" [autoUpdateContent]="true"></div>

                  </div>



                  <div class="card-options children">
                    <div>

                      <div class="card-options--options">

                        <a>
                          <div class="spinner spinner--button"
                            *ngIf="giveChildrenLike && sending_children_voto == child.ChildrenComment.id; else likeChildrenBttn">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>

                          <ng-template #likeChildrenBttn>
                            <span class="icon-like answer"
                              [ngClass]="{'active': (child.Votos!=null && child.Votos.length > 0 && child.Votos[0].valor == 1)}"
                              (click)="doAction({ev: 'giveAnswerLike', data: child, course_id: item.Comment.curso_id})">
                            </span>
                          </ng-template>

                          <label
                            *ngIf="child.ChildrenComment.like > 0">{{(child.ChildrenComment.like > 0 ? child.ChildrenComment.like : 0) | likePipe}}</label>
                        </a>


                        <span class="child-option"
                          (click)="doAction({ev: 'getAnswerOpinions', data:  child.ChildrenComment, index_child: i_child, element:childFooter})">
                          COMENTAR
                        </span>
                      </div>
                    </div>
                    <div>
                      <i class="icon-rate" *ngIf="child.ChildrenComment.best == 1"></i>
                      <a *ngIf="child.ChildrenComment.count_opinions > 0"
                        (click)="doAction({ev: 'getAnswerOpinions', data:  child.ChildrenComment, index_child: i_child, element: childFooter})">{{child.ChildrenComment.count_opinions}}
                        {{child.ChildrenComment.count_opinions > 1 ? 'comentarios' : 'comentario'}}

                        <i
                          [ngClass]="(opinions_.OpenAnswer != null &&  opinions_.OpenAnswer.id == child.ChildrenComment.id) ? 'icon-arrow-up': 'icon-arrow-down'"></i>
                      </a>
                    </div>
                  </div>


                  <div class="card-footer" #childFooter
                    *ngIf="(opinions_.OpenAnswer != null && opinions_.OpenAnswer.id == child.ChildrenComment.id)">

                    <!-- comentarios de las respuestas de las preguntas -->
                    <div class="card section-comments" [ngStyle]="{'padding-bottom': items.length > 0 ? null : '20px'}"
                      [class.active]="(opinions_.OpenAnswer != null && opinions_.OpenAnswer.id == child.ChildrenComment.id)">
                      <comment [_comment]="opinions_.ofAnswersNew" [actions]="actions"
                        [id_textarea]="'child-'+child.ChildrenComment.id" [current_user_data]="current_user_data"
                        *ngIf="(opinions_.OpenAnswer != null && opinions_.OpenAnswer.id == child.ChildrenComment.id)"
                        [comment_list]="opinions_.ofAnswers"
                        (addOpinion)="doAction({ev: 'addAnswerComment', comment: $event, index_child: i_child})"
                        (deleteOpinion)="doAction({ev:'delete_answer_opinion', opinion:$event})"
                        (eventOpinion)="showModalDialog(9)">
                      </comment>

                      <!-- *ngIf="opinions_.ofAnswers.length==0 && child.ChildrenComment.count_opinions > 0" -->
                      <comment-loading style="margin-top: 20px; display: flex; flex-direction:  column   ;" [type]="'opinions'"
                        [amount]="child.ChildrenComment.count_opinions"
                        *ngIf="opinions_.ofAnswers.length==0 && child.ChildrenComment.count_opinions > 0">
                      </comment-loading>

                    </div>
                    <!--END comentarios de las respuestas de las preguntas -->
                  </div>

                </div>

              </div>


            </div>


          </div>
        </div>

        <!-- *ngIf="(_communityService.answers_parentId == item['Comment'].id) && _communityService.answers[this.reference] && _communityService.answers[this.reference].length > 3 && !_communityService.full_view.state" -->
        <div class="card-content view-all-answer"
          *ngIf="(_communityService.answers_parentId == item['Comment'].id) && _communityService.answers[this.reference] && _communityService.answers[this.reference].length > 3 && parent_id_view_answer != item.Comment.id"
          (click)="doAction({ev: 'all-answers', comment: item})">
          <span class="icon-arrow-to-right"></span>
          {{(item.Comment.quiz_id!=null && item.Comment.quiz_id!='' && item.Comment.quiz_id!=0) ? 'Mostrar más opiniones' : 'Mostrar más respuestas'}}

        </div>

      </div>

    </div>

  </div>



  <comment-loading [amount]="10" *ngIf="loadingComments"></comment-loading>


  <a class="btn_more" (click)="pagination()"
    *ngIf="((items && items.length > 0) && (_communityService.commentsPages[items_type] != null) && !_communityService.full_view.state) && items_type!='notificate' ">

    <span *ngIf="!loadingComments">
      Ver más
    </span>

    <div class="spinner dotted load dark" *ngIf="loadingComments">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

  </a>

  <!-- <div class="toast">No hay más items para cargar</div> -->
</div>
