import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UploadFileComponent } from './upload-file/upload-file.component';

import { AceEditorModule } from 'ng2-ace-editor';
import { SharedModule } from 'src/app/shared/shared.module';
import { AWSS3Service } from './awsS3.service';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { SafePipe, ToFixed, EvaluationStatePipe, EvaluationTooltipPipe } from './classroom-shared.pipe';
import { ActivitiesComponent } from '../sidebar/components/activities/activities.component';
import { CommentLoadingComponent } from './comment-loading/comment-loading.component';
import { SliderMenuComponent } from './slider-menu/slider-menu.component';



@NgModule({
  declarations: [
    UploadFileComponent,
    ModalDialogComponent,
    SafePipe, ToFixed, EvaluationStatePipe, EvaluationTooltipPipe,
    ActivitiesComponent,
    CommentLoadingComponent,
    SliderMenuComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AceEditorModule,
    SharedModule,
  ],
  exports: [
    UploadFileComponent,
    ModalDialogComponent,
    SharedModule,
    ActivitiesComponent,
    CommentLoadingComponent,
    SliderMenuComponent
  ],
  providers: [AWSS3Service],
  entryComponents: [UploadFileComponent]
})
export class ClassroomSharedModule { }
