import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, retry, debounceTime } from 'rxjs/operators';
import { UrlLambdaApi, UrlPhpApi } from 'src/app/classroom/models/models';
import { UserData } from './auth.models';
import * as countries_data from '../../../assets/countries.json';
import { IndexedDBService } from './indexedDB.service';
import { FormatPhoneNumber } from '../services/auth.pipe';
import { environment } from 'src/environments/environment';



// const AWS = require('aws-sdk');
// AWS.config.update({
//   "accessKeyId": "AKIA6LPQ6GUE7NUJMOMK", 
//   "secretAccessKey": "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", 
//   "region": "us-east-1" 
// });

Auth.configure({
  authenticationFlowType: 'USER_SRP_AUTH',
  // authenticationFlowType: 'USER_PASSWORD_AUTH'
});


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public passwordConfirmRegistration: string;

  public emailChangePass = {
    detail: null,
    type: null
  };

  public resetPasswordRequired: boolean = false;

  public newPasswordRequired = {
    email: '',
    user: null,
    requiredAttributes: null,
  }

  public userInRoute: any;
  public userLogged: any;
  public countries: any;
  public countries_copy: any;
  public country: any;
  public userData: UserData;
  public userCountry: any = {
    code: "1",
    id: 230,
    name: "ESTADOS UNIDOS",
    short_name: "US"
  }

  public dataLogin = {
    email: '',
    password: '',
    country: null
  }


  constructor(
    public router: Router,
    private http: HttpClient,
    private _appService: AppService,
    private _indexedDBService: IndexedDBService,
  ) {

    this._indexedDBService.openIndexedDB().subscribe(opendb => { });


  }



  getUserIndexedDB = (user_id) => new Observable((observer) => {
    this._indexedDBService.getUser(user_id).subscribe((user: any) => {
      observer.next(user);
    });
  });


  public indexedDB_user_list;
  getUserListIndexedDB = () => new Observable((observer) => {

    this._indexedDBService.getUserList().subscribe((resp: any) => {

      if (resp.status == 'ok' && resp.data.length > 0) {

        this.indexedDB_user_list = JSON.parse(JSON.stringify(resp.data));

        let countries = this.getCountries();

        this.indexedDB_user_list.forEach(user => {
          if (this.validatePhoneNumber(user.username)) {

            let country = countries.filter(country => (country.id == user.country_id));
            user.code = country[0].code;
            user.username = new FormatPhoneNumber().transform(user.username, user.code);

          }
        });

        // observer.next(resp.data);
        observer.next(this.indexedDB_user_list);

      } else {
        observer.next(resp.data);
      }
    })
  })

  deleteUserIndexDB = (user_id) => new Observable((observer) => {

    this._indexedDBService.deleteUser(user_id).subscribe((resp: any) => {

      observer.next(resp);

    })
  })



  getCountry(): Observable<any> {
    return this.http.get(`${UrlPhpApi}/users/getIdCountry`).pipe(
      map((country_id: any) => {
        return country_id
      }), retry(3)
    )
  }

  public classroom = '/classroom?config=%7B"certification_id":"499896","clase_id":"409342484","curso_id":"2631","idu":"239965","language_id":"2","quiz_id":"1606","source_id":"0","state":"-1","status":true,"tour":"4","type":"2"%7D';

  public token;


  isUserAuthenticated(): Observable<any> {
    return from(Auth.currentAuthenticatedUser()
      .then(user => {
        //('%c ===isUserAuthenticated===', 'color: green; background:white', user);
        this.userLogged = JSON.parse(JSON.stringify(user));
        this.token = this.userLogged.signInUserSession.idToken.jwtToken;
        const cognitoUser = JSON.parse(JSON.stringify(user));

        this.currentSession().subscribe((session: any) => {

          let { currentSession, status } = session;

          switch (status) {
            case 'ok':
              //Usuario está autenticado

              const { signInUserSession } = cognitoUser;

              if (signInUserSession != null && (signInUserSession.idToken.payload.fuente != 'google' && signInUserSession.idToken.payload.fuente != 'facebook')) {
                user.refreshSession(currentSession.refreshToken, (err, session) => {
                  //('session', err, session);
                  // const { idToken, refreshToken, accessToken } = session;
                  if (session && session.idToken != null && session.idToken.payload != null) {
                    let urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

                    session.idToken.payload.picture = session.idToken.payload.image == '' ? urlavatar + (session.idToken.payload.role == 'propietario' ? 'professor/medium_imagen.jpg' : 'default/medium_imagen.jpg') : urlavatar + session.idToken.payload.id + '/medium_' + session.idToken.payload.image;

                    this._appService.user_data = JSON.parse(JSON.stringify(session.idToken.payload));
                  }
                  // do whatever you want to do now :)
                  this.loginPHP().subscribe();
                });
              }

              if (signInUserSession != null && (signInUserSession.idToken.payload.fuente == 'google' || signInUserSession.idToken.payload.fuente == 'facebook')) {
                this.loginPHP().subscribe();
              }



              // this.getCountry().subscribe(resp => {
              //   //Aqui debe ser el país del usuario y no de la ubicación
              //   this._appService.user_data.country_id = resp;
              // });
              this._appService.setUserData(cognitoUser);
              this.loadUserCountry().subscribe(resp => {
                this._appService.user_data.country_id = resp;

              });


              if (localStorage.getItem('federated_signin')) {
                //Si inició con Facebook o Google lanzar login.json
                // this.loginLearn().subscribe();    

                if (sessionStorage.getItem('redirect_url')) {

                  let url = '';

                  if (sessionStorage.getItem('redirect_url').includes('dashboard')) {
                    url = sessionStorage.getItem('redirect_url').split('/').pop();
                    setTimeout(() => {
                      this.router.navigateByUrl('/dashboard/' + url);
                      sessionStorage.removeItem('redirect_url');
                    }, 1000);
                  } else {
                    setTimeout(() => {
                      sessionStorage.removeItem('redirect_url');
                      window.location.href = url;
                    }, 500);

                  }


                }

                this.loginPHP().subscribe(response => {


                  if (sessionStorage.getItem('redirect')) {
                    let get_redirect = JSON.parse(sessionStorage.getItem('redirect'));
                    sessionStorage.removeItem('redirect');

                    if (this._appService.user_data.id == get_redirect.id) {
                      setTimeout(() => {
                        window.location.href = get_redirect.url;
                      }, 500);
                    }

                  }


                });


                //Si el usuario solicita inscripción desde externa
                this.enrollUserWithPreviousInscription();

                this._indexedDBService.openIndexedDB().subscribe(opendb => {

                  this._indexedDBService.getUser(this._appService.user_data.id).subscribe((user: any) => {

                    if (user.status == 'ok') {
                    }

                    if (user.status == 'not_found') {

                      let new_user = {
                        id: this._appService.user_data.id,
                        username: this._appService.user_data.fuente == 'google' ? this._appService.user_data.email : '',
                        name: this._appService.user_data.name,
                        image: this._appService.user_data.picture,
                        fuente: this._appService.user_data.fuente,
                        country_id: this._appService.user_data.country_id
                      }


                      // if(this._appService.indexedDB_users_list != null && this._appService.indexedDB_users_list.length >0 ){

                      //   let last_account = this._appService.indexedDB_users_list[this._appService.indexedDB_users_list.length - 1];
                      // ('cuenta anterior: ', last_account)
                      // }

                      this._indexedDBService.addUser(new_user).subscribe(resp => {
                        this.getUserListIndexedDB().subscribe();
                      });

                    }

                  });
                });

                localStorage.removeItem('federated_signin');
              }




              //('%c ===isUserAuthenticated===', 'color: green; background:white', signInUserSession);

              if (signInUserSession != null && (signInUserSession.idToken.payload.fuente == 'facebook' || signInUserSession.idToken.payload.fuente == 'google')) {

                this.getUserEmailCellphone().subscribe(response => {

                  const { email, cellphone, country_id } = response[0].User;

                  if ((email == null || email == '') || (email.includes('@facebook.com') && (cellphone == null || cellphone == ''))) {
                    this.router.navigate(['/user/user_update']);
                  }


                  setTimeout(() => {

                    if (this._appService.user_data.country_id != country_id && country_id == 1) {

                      const { id } = this._appService.user_data;
                      let url_put = `/users/${id}`;
                      let data = {
                        country_id: this._appService.user_data.country_id
                      }
                      this.editItem(url_put, data).subscribe(resp => { });
                    }

                  }, 10000);


                })

              }



              break;
            case 'error':

              this._appService.cognitoUserAuthenticated = [];
              this._appService.setUserData([]);

              break;
          }

        })



      })
      .catch(error => {

        this._appService.cognitoUserAuthenticated = [];
        this._appService.setUserData([]);

        // this.router.navigate(['/user/login'])
        // return { status: 'error', error };

      })
    )
  }


  loadUserCountry = () => new Observable((observer) => {

    let countries = this.getCountries()
    this.countries = JSON.parse(JSON.stringify(countries));
    this.countries_copy = JSON.parse(JSON.stringify(countries));

    this.getCountry().subscribe(resp => {

      this.country = resp;
      let country = this.countries.filter(country => (country.id == this.country));
      this.userCountry = country[0];

      observer.next(resp);


    });


  });



  currentSession(): Observable<any> {
    return from(Auth.currentSession()
      .then(currentSession => {

        return { status: 'ok', currentSession };

      })
      .catch(error => {

        return { status: 'error', error };

      })
    )
  }



  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhoneNumber(phone) {
    // var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    var phoneno = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return phone.match(phoneno) ? true : false;
  }

  public signUp(addData): Observable<any> {
    let token = window.btoa(addData.password);
    let country = addData.country.id + '';
    let type;

    let data: any = {
      // username: this.email.split('@')[0],//esta opción para cuando se escoja iniciar con username
      password: addData.password,
      attributes: {
        name: addData.fullname,
      },
      clientMetadata: {
        token,
        country
      }
    }

    if (this.validateEmail(addData.email)) {

      data['username'] = data.attributes['email'] = addData.email;
      type = 'email';
    }

    if (this.validatePhoneNumber(addData.email)) {

      if (addData.email.includes('+') || addData.email.includes('%2B')) {
        data['username'] = data.attributes['phone_number'] = addData.email;
      } else {
        // si el número celular no contiene el indicativo, se le agrega. 
        let code = addData.country.code.replace(/-/g, '');
        data['username'] = data.attributes['phone_number'] = '+' + code + addData.email;
      }

      type = 'phone_number';

    }

    return from(Auth.signUp(
      data,
    ).then((user: any) => {
      this._appService.userSignUpEmail = addData.email;
      user.type = type;
      return { status: 'ok', user }
    }).catch((error: any) => {
      return { status: 'error', error }
    })
    );
  }

  public confirmSignUp(dataConfirm): Observable<any> {
    return from(Auth.confirmSignUp(dataConfirm.email, dataConfirm.code).then((confirm: any) => {
      return { status: 'ok', confirm }
    }).catch(error => {
      return { status: 'error', error }
    })
    );
  }



  sending: boolean = false;
  errorMessage;
  signIn(dataLogin): Observable<any> {

    //('%c ===signIn=== ', 'color: blue; background: white', JSON.parse(JSON.stringify(dataLogin)));

    let token = window.btoa(dataLogin.password);
    let username;
    let type;

    if (this.validateEmail(dataLogin.email)) {
      username = dataLogin.email;
      type = 'email';
    }

    if (this.validatePhoneNumber(dataLogin.email)) {

      if (dataLogin.hasOwnProperty('country') && !dataLogin.email.includes('+')) {
        username = '+' + dataLogin.country.code.replace(/-/g, '') + dataLogin.email;
      } else {
        username = dataLogin.email;
      }

      type = 'phone';
    }

    localStorage.removeItem('user_form');

    localStorage.clear();//Limpiamos el localstorage para que no existan token repetidos

    return from(Auth.signIn(username, dataLogin.password, { token }).then((user: any) => {
      this.userLogged = JSON.parse(JSON.stringify(user));
      this.token = this.userLogged.signInUserSession.idToken.jwtToken;

      const { payload } = this.userLogged.signInUserSession.idToken;



      // CognitoIdentityService
      let urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

      payload.picture = payload.image == '' ? urlavatar + (payload.role == 'propietario' ? 'professor/medium_imagen.jpg' : 'default/medium_imagen.jpg') : urlavatar + payload.id + '/medium_' + payload.image;


      this._indexedDBService.getUser(payload.id).subscribe((response: any) => {

        if (response.status == 'ok') {

          //Actualizar usuario en IndexedDB cada vez que inicie sesión porque no sabemos si modificó o no la contraseña

          if (dataLogin.password != null && dataLogin.password != '') {

            let passcipher = this.cipherPassword(dataLogin.password);

            response.user.password = passcipher;
            response.user.image = payload.picture;

            this.updateUserIndexedDB(response.user).subscribe(resp => {
              // this.getUserList().subscribe((accounts: any) => {
              //   if (accounts.length == 1) this.user_display = accounts[0];
              // });
            });

          }

          let current_img = response.user.image.split('/').pop();
          const { signInUserSession } = user;
          if (signInUserSession.idToken && signInUserSession.idToken.payload) {
            const { image } = signInUserSession.idToken.payload;
            if (image != current_img) {

              let user_ = response.user;
              // user_.image = `https://d3puay5pkxu9s4.cloudfront.net/Users/${user_.id}/${image}`;

              user_.image = payload.image == '' ? urlavatar + (payload.role == 'propietario' ? 'professor/medium_imagen.jpg' : 'default/medium_imagen.jpg') : urlavatar + payload.id + '/medium_' + payload.image;


              this.updateUserIndexedDB(user_).subscribe();
            }

          }




        }

        if (response.status == 'not_found') {

          let passcipher = this._indexedDBService.cipher(dataLogin.password);

          let new_user: any = {
            id: payload.id,
            username: username,
            name: payload.name,
            image: payload.picture,
            fuente: payload.fuente == null ? type : payload.fuente,
            country_id: payload.country_id,
            password: passcipher
          }

          if (type == 'phone') {

            let countries = this.getCountries();
            let country = countries.filter(country => (country.id == new_user.country_id));
            new_user.code = country[0].code;
            new_user.username = new FormatPhoneNumber().transform(new_user.username, new_user.code);

          }

          this._indexedDBService.addUser(new_user).subscribe(resp => {
            this.getUserListIndexedDB().subscribe();
          });




        }

      });


      return { status: 'ok', user }


    }).catch(error => {
      error.type = type;
      return { status: 'error', error }
    }));

  }

  updateUserIndexedDB = (new_user) => new Observable((observer) => {
    this._indexedDBService.updateUser(new_user).subscribe(resp => {
      observer.next();
    });
  });


  loginPHP(): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(`${UrlPhpApi}/auths/login.json`, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(error => {
        return of(error)
      })
    );
  }


  enrollUser = (course_id) => new Observable((observer) => {

    let body = {
      "data": {
        "user_id": this._appService.user_data.id,
        "curso_id": course_id
      }
    };

    this._appService.enrollUser(body).subscribe(data => {


      if (data.valid) {

        if (data.print.hasOwnProperty('extra_data') && data.print.extra_data.hasOwnProperty('config_redirect') && data.print.extra_data.config_redirect.hasOwnProperty('certification_id')) {
          let certification_id = data.print.extra_data.config_redirect.certification_id;
          // Updating certification_id in user_data
          if (this._appService.user_data.hasOwnProperty('certification_id') && this._appService.user_data.certification_id == "0") {
            certification_id = JSON.stringify(certification_id);
            localStorage.setItem("last-certificate-default", certification_id);
            this._appService.user_data.certification_id = certification_id;
          }
          // Go to home
          // this.router.navigate['/home'];

          let body = {
            "data": { "certification_id": "" + certification_id }
          };
          this._appService.putUser(JSON.stringify(body)).subscribe(data => {
            this.router.navigate['/home'];
            if (data.valid == false) {
              console.log("Error putUser():", data);
            }
          });


        } else {
          console.log("Error enrollUser() not contain property: extra_data && config_redirect && certification_id", data);
        }

      } else {
        console.log("Error enrollUser():", data);

      }

      observer.next(data);
      observer.complete();

    });
  });

  addPhoneNumber(number): Observable<any> {
    //Al agregar o cambiar email, el usuario recibirá un código de verificación

    return from(Auth.currentAuthenticatedUser().then(user => {
      return Auth.updateUserAttributes(user, {
        'phone_number': number,
      }).then((response: any) => {
        return { status: 'ok', user: response }
      }).catch(error => {
        return { status: 'error', error }
      })
    })
    )

  }


  addEmail(email): Observable<any> {
    //Al agregar o cambiar email, el usuario recibirá un código de verificación

    return from(Auth.currentAuthenticatedUser().then(user => {
      return Auth.updateUserAttributes(user, {
        'email': email,
      }).then((response: any) => {
        return { status: 'ok', user: response }
      }).catch(error => {
        return { status: 'error', error }
      })
    })
    )

  }


  signOut() {

    setTimeout(() => {
      this._appService.darkTheme = false;
    }, 100);
    this._appService.loadNavBar = this._appService.loadFooter = false;

    this._appService.closeLiveChat();

    Auth.signOut().then(() => {
      // window.location.href = UrlPhpApi;
      if (navigator.credentials && navigator.credentials.preventSilentAccess) {
        // Turn on the mediation mode so auto sign-in won't happen
        // until next time user intended to do so.
        navigator.credentials.preventSilentAccess();
      }

      this.logOutLearn().subscribe(resp => {

        // if (redirect != null) {
        //   setTimeout(() => {
        //     this.router.navigate(['/user/accounts'], { queryParams: { redirect } });
        //   }, 100);
        // }



        this._appService.cognitoUserAuthenticated = null;
        this._appService.token = null;

      });

      this.userLogged = null;
      sessionStorage.clear();
      localStorage.clear();

    });

    this._appService.userSignUpEmail = null;
  };


  logOutLearn(redirect?): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    });

    return this.http.get(`${UrlPhpApi}/auths/logout`, { headers }).pipe(
      map((response: any) => {
        if (redirect == null) {
          this.router.navigate(['/user/accounts']);

        }
      }),
      catchError(error => {
        if (redirect == null) this.router.navigate(['/user/accounts']);
        return of(error)
      })
    );
  }

  public federatedSignIn(customProvider) {

    // let options = {
    //   customProvider,
    //   user: {
    //     email: 'hola@email.com'
    //   }
    // }
    localStorage.setItem('federated_signin', 'true');

    return from(Auth.federatedSignIn({ customProvider }).then((response: any) => {
      // this.loginLearn().subscribe();
      return { status: 'ok', response }
    }).catch(error => {
      localStorage.removeItem('federated_signin');
      return { status: 'error', error }
    }))
  }


  public forgotPassword(username) {

    return from(Auth.forgotPassword(username).then((user: any) => {
      return { status: 'ok', user }
    }).catch(error => {
      return { status: 'error', error }
    }))
  }

  public verifyEmailBefore(attr): Observable<any> {
    return this.http.get(`${UrlLambdaApi}/users/idp/verify?${attr}`, this.getHeadersLambda())
      .pipe(
        map((verify: any) => {
          return verify
        }),
        catchError(this.handleError<any>('verifyEmailBefore', []))
      );

  }


  async getHeaders() {

    const myInit = {
      headers: {
        Authorization: `Bearer ${this._appService.cognitoUserAuthenticated.signInUserSession.idToken.jwtToken}`
      },
    };

    return myInit

  }


  public updatePassword(data): Observable<any> {

    return from(Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, data.oldPassword, data.newPassword);
      })
      .then(data => {
        return { status: 'ok', data }
      })
      .catch(error => {
        return { status: 'error', error }
      })

    )

  }


  public completeNewPassword(data) {

    return from(Auth.completeNewPassword(
      data.user,               // the Cognito User Object
      data.newPassword,
      {
        name: data.fullname
      }
      // the new password
      // OPTIONAL, the required attributes
    ).then(user => {
      // at this time the user is logged in if no MFA required
      return { status: 'ok', user }
    }).catch(error => {
      return { status: 'error', error }
    })

    )
  }

  public getCountries() {

    // let countries_url = 'https://restcountries.eu/rest/v2/all?fields=name;callingCodes;flag;alpha2Code';

    // return this.http.get(`${UrlLambdaApi}/countries?fields=["id","name","short_name","code"]`)
    //   .pipe(
    //     map((countries: any) => {

    let countries: any = (countries_data as any).default;

    countries.shift(); //Eliminar el primer (internacional)

    let countries_data_response = [];

    let array_ = [];
    countries.forEach((element, index) => {

      if (index == (countries.length - 1)) {

        countries_data_response = countries.concat(array_);
        countries.sort((a: any, b: any) => {
          let countryA = a.id;
          let countryB = b.id;
          return (countryA < countryB) ? -1 : (countryA > countryB) ? 1 : 0;
        });

      }
    });


    return countries_data_response
    // }),
    // catchError(this.handleError<any>('getTranslate', []))


  }

  getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })

    return ({ headers: headers });
  }


  public getCertifications(user_id = null): Observable<any> {

    return this.http.get(`${UrlLambdaApi}/dynamo/certifications?estado=1&user_id=${user_id ? user_id : this._appService.user_data.id}`, this.getHeadersLambda())
      .pipe(
        map((certifications: any) => {

          const { data, status } = certifications;

          let data_return = [];
          if (status) {
            data_return = JSON.parse(JSON.stringify(data));
          }

          return data_return
        }),
        retry(3),
        catchError(this.handleError<any>('getCertifications', []))
      );

  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      console.log('%c error::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead            
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  public resendVerificationCode(attr): Observable<any> {

    // return from(Auth.resendSignUp(attr)
    return from(Auth.resendSignUp(attr)
      .then(resend => {
        return { status: 'ok', resend }
      })
      .catch(error => {
        return { status: 'error', error }
      }))

  }


  //Verificación del Email del usuario, se podria usar este metodo para verificar tambien el atributo phone_number si así se requiere
  public verifyAuthenticatedUserEmail(attr): Observable<any> {

    // return from(Auth.resendSignUp(attr)
    return from(Auth.verifyCurrentUserAttribute(attr)
      .then(verify => {
        return { status: 'ok', verify }
      })
      .catch(error => {
        return { status: 'error', error }
      }))

  }

  //Se envia el código de verificación recibido en verifyCurrentUserAttribute
  public verifyEmail(dataVerifcate): Observable<any> {

    return from(Auth.verifyCurrentUserAttributeSubmit(dataVerifcate.type, dataVerifcate.code)
      .then((confirm: any) => {
        return { status: 'ok', confirm }
      }).catch(error => {
        return { status: 'error', error }
      })
    );
  }


  public forgotPasswordSubmit(dataPassword) {

    return from(Auth.forgotPasswordSubmit(dataPassword.email, dataPassword.code, dataPassword.newPassword)
      .then(data => {
        return { status: 'ok', data }
      }).catch(error => {
        return { status: 'error', error }
      })

    );
  }


  //http://localhost:4200/user/confirmForgotPassword?tokenhash=aa1ea943e1df159a54527e2b74a9b1953bb6de7665eb116b4614d7f12b4f3b0287039b6927cf92aa73876d57702a2dd59ae3ad0ccb100a12b44e8dcc4f513752#f7795b4540b09ac3b48ae4aabbdd2e1db5310237


  sendEmailVerificationPHP(email): Observable<any> {
    return this.http.get(`${environment.domain}/emails/send_email_forgetpwd.json?email=${email}`)
      .pipe(map((user: any) => {
        return user
      }),
        catchError(this.handleError<any>('sendEmailVerificationPHP', []))
      );
  }

  updatePasswordPHP(data) {

    return this.http.post(`${environment.domain}/users/reset_app.json`, data, this.getHeadersLambda())
      .pipe(map((response: any) => {
        return response
      }),
        catchError(this.handleError('recents_add'))
      );
  }



  getItem(get_url): Observable<any> {
    return this.http.get(`${UrlLambdaApi}${get_url}`, this.getHeadersLambda())
      .pipe(map((data_response: any) => {
        return data_response
      }),
        catchError(this.handleError<any>('getItem', []))
      );
  }


  getUserEmailCellphone(): Observable<any> {

    const { id } = this._appService.user_data;

    return this.http.get(`${UrlLambdaApi}/users/${id}?fields=["email","cellphone","country_id"]`, this.getHeadersLambda())
      .pipe(map((user: any) => {
        const { data, status } = user;
        let data_return = [];

        if (status) {
          data_return = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        catchError(this.handleError<any>('getUserData', []))
      );
  }



  getUserData(): Observable<any> {

    const { id } = this._appService.user_data;

    return this.http.get(`${UrlLambdaApi}/users/${id}`, this.getHeadersLambda())
      .pipe(map((user: any) => {
        const { data, status } = user;
        let data_return = [];

        if (status) {
          this.userData = JSON.parse(JSON.stringify(data))[0];
          data_return = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('getUserData', []))
      );
  }

  getFileUrl(tokenhash, qr_type): Observable<any> {
    let type = qr_type == 1 ? 'dni_file' : 'profile_file';
    // https://wcz77vfgp4.execute-api.us-east-1.amazonaws.com/dev/

    return this.http.get(`https://api.edutin.com/s/p/file_url?tokenhash="${tokenhash}"&type="${type}"`, this.getHeadersLambda())
      .pipe(map((user: any) => {
        const { data, status } = user;
        let data_return = [];

        if (status) {
          data_return = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        catchError(this.handleError<any>('getFileUrl', []))
      );
  }

  validateDNI(dni): Observable<any> {

    return this.http.get(`https://api.edutin.com/s/p/verification/dni/${dni.dni_number}?file_name=${dni.dni_file}&type=${dni.dni_type}`, this.getHeadersLambda())
      .pipe(map((validation: any) => {
        return validation
      }),
        catchError(this.handleError<any>('getFileUrl', []))
      );
  }


  editItem(url, data): Observable<any> {

    return this.http.put(`${UrlLambdaApi}${url}`, { data }, this.getHeadersLambda())
      .pipe(
        catchError(error => {
          this.handleError('editItem');
          return error;
        })
      );

  }

  addItem(url, data): Observable<any> {

    return this.http.post(`${UrlLambdaApi}${url}`, { data }, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('addItem'))
      );

  }


  deleteItem(url): Observable<any> {

    return this.http.delete(`${UrlLambdaApi}${url}`, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('deleteItem'))
      );

  }

  public generateRandomString() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 12; i++)text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  putUser(data): Observable<any> {
    const { id } = this._appService.user_data;
    return this.http.put(`${UrlLambdaApi}/users/${id}`, { data }, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('sourceRecentUpdate'))
      );
  }




  cipherPassword(password) {
    return this._indexedDBService.cipher(password);
  }
  decipherPassword(passcipher) {
    return this._indexedDBService.decipher(passcipher);
  }




  enrollUserWithPreviousInscription() {


    if (sessionStorage.getItem('previous_inscription')) {

      let goto = sessionStorage.getItem('go_to')
      let curso_id = sessionStorage.getItem('previous_inscription');

      if (curso_id != null && curso_id != "-1" && curso_id != "0") {
        this.enrollUser(curso_id).subscribe((resp: any) => {

          this.sending = false;
          sessionStorage.removeItem('previous_inscription');
          sessionStorage.removeItem('go_to');

          if (goto != null && goto != '' && goto == 'payments' && resp.valid == true) {

            window.location.href = `${environment.domain}/payments/certificate/${resp.print.id}`;
            // window.location.href = `https://edutin.com/payments/certificate/${resp.print.id}`;

          } else {

            this.router.navigate(['/home']);

          }

        });
      } else {
        this.sending = false;
        this.router.navigate(['/home']);
      }

    } else {
      this.sending = false;

      if (sessionStorage.getItem('redirect_url')) {

        //Redireccionará al dashboard en caso que venga de una url de algún correo y se encuentre sin sesión

        let url = '';

        if (sessionStorage.getItem('redirect_url').includes('dashboard')) {
          url = sessionStorage.getItem('redirect_url').split('/').pop();
          setTimeout(() => {
            this.router.navigateByUrl('/dashboard/' + url);
            sessionStorage.removeItem('redirect_url');
          }, 100);
        } else {
          setTimeout(() => {
            sessionStorage.removeItem('redirect_url');
            if (url != null && url != '') {
              window.location.href = url;
            } else {
              this.router.navigate(['/home']);

            }
          }, 500);

        }

      } else {

        this.router.navigate(['/home']);

      }


    }






  }


}
