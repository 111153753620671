<modal-dialog [hidden]="!modal_dialog_state"></modal-dialog>
<div class="community-container participate-container">
  <div class="tabs-take-part">

    <ng-content></ng-content>

    <div class="section">
      <div class="participate-container" *ngIf="this_component.state">
        <div class="comment-content">

          <div class="files" #participate_footer>
            <div class="files__group">
              <span>Agregar:</span>

              <ul class="files__group-options">

                <!-- <li (click)="createComponent('text',null,'btn')"><i class="icon-text"></i>Texto</li> -->

                <li class="option" (click)="createComponent('image',null,'btn')"><i class="icon-image"></i>Imagen</li>

                <li class="option" (click)="createComponent('video',null,'btn')"
                  *ngIf="participate_footer.offsetWidth >= 300">
                  <i class="icon-play-circle-outline"></i>
                  Video
                </li>

                <li class="option" (click)="createComponent('file',null,'btn')"
                  *ngIf="participate_footer.offsetWidth >= 513">
                  <i class="icon-attached"></i>
                  Archivo
                </li>

                <li class="option" (click)="createComponent('code',null,'btn')"
                  *ngIf="participate_footer.offsetWidth >= 591">
                  <i class="icon-code"></i>
                  Código
                </li>


                <!-- <ul *ngIf="participate_footer.offsetWidth < 591" class="icon-ellipsis-v"
                  (click)="display_more_items=!display_more_items" [class.active]="display_more_items">
                  <div>



                    <li (click)="createComponent('link',null,'btn')" *ngIf="participate_footer.offsetWidth < 450">
                      <i class="icon-link"></i>Enlace
                    </li>

                    <li (click)="createComponent('file',null,'btn')" *ngIf="participate_footer.offsetWidth < 513">
                      <i class="icon-attached"></i>Archivo
                    </li>

                    <li (click)="createComponent('code',null,'btn')">
                      <i class="icon-code" *ngIf="participate_footer.offsetWidth > 500"></i>Código
                    </li>
                  </div>

                </ul> -->

              </ul>


            </div>

          </div>

          <div style="min-height: 100px">
            <template #uploadfile></template>
          </div>


        </div>

        <div class="btn-group">
          <a role="button" class="btn btn__cancel" (click)="closeThisComponent('cancel')"> Cancelar </a>
          <a role="button" class="btn btn__light-blue" (click)="sendComment()">

            {{item_to_edit == null ? 'Enviar' : (item_to_edit.Event == 'edit' || item_to_edit.Event == 'editAnswer') ?
            'Editar':'Enviar'}}

            {{button_label}}
          </a>
        </div>

      </div>



    </div>
  </div>
</div>