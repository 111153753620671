<div id="course-detail" *ngIf="!closeModal">
  <!--  [style.--left-position]="tirangle_left_position+'px'" -->
  <div class="content">
    <div class="information">
      <div class="title">
        <h3>{{courseDetail.name}}</h3>
        <div #shared (clickOutsideDdown)="shared.classList.remove('open')" class="shared">
          <div (click)="shared.classList.toggle('open')" class="btn-to-share">
            <i class="icon-send-arrow"></i>
            <span>Compartir</span>
          </div>
          <div class="shared-course">
            <h4 style="font-size: 12px; margin-bottom: 7px;">Comparte este curso para que más personas puedan acceder
              gratis:</h4>
            <div class="row">
              <a (click)="popitup('https://www.facebook.com/sharer/sharer.php?u='+_appService.url_Base+'/'+courseDetail.slug)"
                class="networks">
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-facebook.svg"
                  alt="Compartir en facebook" class="hover-out">
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-facebook-hover.svg"
                  alt="Compartir en facebook" class="hover-in">
                <span>Facebook</span>
              </a>
              <a (click)="popitup('https://twitter.com/share?url='+_appService.url_Base+'/'+courseDetail.slug)"
                class="networks">
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-gorjeo.svg"
                  alt="Compartir en twitter" class="hover-out">
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-gorjeo-hover.svg"
                  alt="Compartir en twitter" class="hover-in">
                <span>Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="desc">
        <p [innerHTML]="courseDetail.descripcion"></p>
      </div>
      <div class="tabs">
        <ul class="button-tabs">
          <li (click)="tabActive=0" [ngClass]="{'active':tabActive==0}" class="button tabActive-0">
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-information.svg"
              alt="information">
            <span>Objetivos</span>
          </li>
          <li (click)="tabActive=1" [ngClass]="{'active':tabActive==1}" class="button tabActive-1">
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-list.svg" alt="information">
            <span>Unidades</span>
          </li>
          <li (click)="tabActive=2" [ngClass]="{'active':tabActive==2}" class="button tabActive-2">
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-valuations.svg" alt="information">
            <span>Detalle</span>
          </li>
        </ul>
        <div class="content-tabs">
          <!-- Objetivos -->
          <div [ngClass]="{'active':tabActive==0}" class="tab tabActive-0">
            <div class="content-objectives">
              <div class="about-course">
                <h4>Acerca del curso</h4>
                <p [innerHTML]="courseDetail.descripcion"></p>
              </div>
              <div class="youll-learn">
                <h4>¿Qué aprenderás?</h4>
                <p [innerHTML]="courseDetail.tematicas"><br></p>
              </div>
            </div>
          </div>
          <!-- Unidades -->
          <div [ngClass]="{'active':tabActive==1}" class="tab tabActive-1">
            <div class="content-unit">
              <!-- loading-start -->
              <div *ngIf="loadingUnit" class="loadingUnit">
                <div class="loader-circle e-blue"></div>
              </div>
              <!-- loading-end -->
              <div *ngFor="let lesson of unit;let i = index" [id]="'Module-'+lesson.Module.id" class="lesson">
                <div #cLesson (click)="cLesson.classList.toggle('active');" [ngClass]="{'active': unit.length==1}"
                  class="collapsible">
                  <label>
                    <i class="icon-plus"></i>
                    Unidad {{(i+1)+'. '+lesson.Module.name}}</label>
                </div>
                <ul class="class">
                  <li *ngFor="let class of lesson.Clase;let j = index">
                    Clases {{(j+1)+'. '+class.titulo}}</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Detalle -->
          <div [ngClass]="{'active':tabActive==2}" class="tab tabActive-2">
            <ul class="content-info">
              <li>
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-duration.svg">
                <span>{{ get_course_duration(courseDetail.time) }} horas de duración</span>
              </li>
              <li>
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-students.svg">
                <span>{{courseDetail.visitas}} estudiantes</span>
              </li>
              <li>
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-language.svg">
                <span>Idioma {{courseDetail.language}}</span>
              </li>
              <li>
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-heart.svg">
                <span>Acceso gratis</span>
              </li>
              <li>
                <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-certificate.svg">
                <span>Opción de certificado
                  <a [href]="_appService.url_Base+'/prices?certificate='+courseDetail.certification_id" target="_blank"
                    class="prices-c">Ver precio y descuento</a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <div class="content">
        <!-- User with session -->
        <div *ngIf="courseDetail.certification_id && _appService.user_session == 1"
          [ngClass]="{'load': loadingCertification}" class="options">
          <a *ngIf="courseDetail.certification_id != '-1'" (click)="loadingCertification==false?goToClassroom():false;"
            class="btn primary">
            <span>Ir al curso</span>
            <div class="loader-circle"></div>
          </a>
          <a *ngIf="courseDetail.certification_id == '-1'" (click)="loadingCertification==false?enrollUser():false;"
            class="btn primary">
            <span>Inscribirme</span>
            <div class="loader-circle"></div>
          </a>
        </div>
        <div *ngIf="!loadingCertification && _appService.user_session == 1 && courseDetail.certification_id != '-1'"
          class="options">
          <a *ngIf="courseDetail.certification_state==1 || _appService.user_data.role=='premium'"
            [href]=" environment.appDomain + '/dashboard/'+ courseDetail.certification_id"
            class="btn secondary ad">
            Administrar mi certificado
          </a>
          <div *ngIf="courseDetail.certification_state!=1">
            <a *ngIf="courseDetail.pending_payment == false && courseDetail.date_state"
              [href]="_appService.url_Base+'/payments/certificate/'+courseDetail.certification_id"
              class="btn secondary">
              Estudiar con certificado
            </a>
            <a *ngIf="courseDetail.pending_payment == false && !courseDetail.date_state"
              [href]="_appService.url_Base+'/helps/requests/'+courseDetail.certification_id" class="btn secondary ad">
              Solicitar activación
            </a>
            <div *ngIf="courseDetail.pending_payment == -1" class="btn">
              <div class="loader-circle"></div>
            </div>
            <a *ngIf="courseDetail.pending_payment == true"
              [href]="_appService.url_Base+'/payments/certificate/'+courseDetail.certification_id"
              class="btn tertiary">Continuar con el pago</a>
          </div>
          <div *ngIf="courseDetail.certification_state!=1" class="description">
            <div *ngIf="courseDetail.date_state" class="certificate-1">Cambiar a la opción estudiar con
              certificado antes del <strong>{{courseDetail.date}}.</strong>
            </div>
            <div *ngIf="!courseDetail.date_state" [ngClass]="{'loader-payments': courseDetail.pending_payment == -1}"
              class="certificate-2">
              <ng-container *ngIf="courseDetail.pending_payment == true; else elseTemplate">
                El tiempo para acceder al certificado con descuento finalizó el <strong>{{courseDetail.date}}</strong>.
              </ng-container>
              <ng-template #elseTemplate>
                El tiempo para cambiarte a la opción con certificado finalizó el <strong>{{courseDetail.date}}</strong>.
              </ng-template>
            </div>
            <a [href]="_appService.url_Base+'/pages/certificate/'+courseDetail.certification_id" target="_blank"
              class="info-certificate">
              Información sobre el certificado
            </a>
          </div>
        </div>
        <!-- User without session -->
        <div *ngIf="courseDetail.certification_id && _appService.user_session == 0"
          [ngClass]="{'load': loadingCertification}" class="options">
          <a [href]="_appService.url_Base+'/users/add/?cursoId='+courseDetail.id" class="btn primary">
            Inscribirme
          </a>
          <a (click)="goToClassroom();" class="btn primary outline">
            <span>Visitar</span>
            <div class="loader-circle"></div>
          </a>
        </div>
        <ul class="content-info">
          <li>
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-duration.svg">
            <span>{{ get_course_duration(courseDetail.time) }} horas de duración</span>
          </li>
          <li>
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-students.svg">
            <span>{{courseDetail.visitas}} estudiantes</span>
          </li>
          <li>
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-language.svg">
            <span>Idioma {{courseDetail.language}}</span>
          </li>
          <li>
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-heart.svg">
            <span>Acceso gratis</span>
          </li>
          <li>
            <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-certificate.svg">
            <span>Opción de certificado
              <a [href]="_appService.url_Base+'/prices?certificate='+courseDetail.certification_id" target="_blank"
                class="prices-c">Ver precio y descuento</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>