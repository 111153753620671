import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'reactions-bubble',
  templateUrl: './reactions-bubble.component.html',
  styleUrls: ['./reactions-bubble.component.scss']
})
export class ReactionsBubbleComponent {

  @Input() reactions: any;
  @Input() reactions_length: any;
  @Output() openReactionsModal = new EventEmitter<string>();

  constructor() {}


}
