<div class="float-notification" [ngClass]="{'show': _connectionService.notification.show, 'close': _connectionService.notification.close}">
    <div class="card-notification" [ngClass]="{'dark': _appService.darkTheme}">

        <section class="card-notification-head">
            <span class="title">{{_connectionService.notification.title}}</span>
            <i class="icon-close" (click)="_connectionService.switchNotificationAlert('off')"></i>
        </section>

        <section class="card-notification-body">
            <div class="photo">
                <img #photoUser 
                (error)="photoUser.src = 'https://d3puay5pkxu9s4.cloudfront.net/Users/default/medium_imagen.jpg?nocahe=1625022257'" 
                [src]="_connectionService.notification.connection.photo" 
                [class]=" 'monitor' | userClassName">
            </div>

            <div class="user-info">

                <div class="title" (click)="openUserProfile(_connectionService.notification.connection.user_id)">
                   <h4>{{_connectionService.notification.connection.username}}</h4>
                   <h4></h4>
                </div>

                <h4 class="ocupation">
                    Estudiante de Edutin
                </h4>

                <div class="btn-group notification" *ngIf="_connectionService.notification.connection.state != '1' ">
                    <a class="button to-accept" (click)="reply($event, _connectionService.notification.connection, '1')">
    
                        <span class="" style="border-radius: 3px;" *ngIf="!user_id_to_reply.includes(_connectionService.notification.connection.user_id)">
                            Aceptar
                        </span>
    
                        <div class="spinner spinner--button white"
                            *ngIf="user_id_to_reply.includes(_connectionService.notification.connection.user_id)">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </a>
    
                    <a class="button ignore" (click)="reply($event, _connectionService.notification.connection, '-1')">
                        <span style="font-weight: 600;">Ignorar</span>
                    </a>
                </div>

            </div>
            
        </section>

    </div>
</div>

<div class="connections dropdown-all-menu" (clickOutsideDdown)="showConnections=false"
    [ngClass]="{'menu-mobile-open': showMenu || showConnections}">

    <span (click)="toggleShoppingCar()" *ngIf="showConnections" class="icon-cross"></span>
    <!-- Logo -->
    <div class="item label" (click)="toggleConnections()" [ngClass]="{'open':showConnections}">
        <span class="icon-community" [ngClass]="{'active': showConnections || _connectionService.pendingConnections.length>0}"></span>
        <div class="label-red" *ngIf="_connectionService.countPendingConnection > 0">{{_connectionService.countPendingConnection}}</div>
    </div>

    

    <!-- Desplegable -->
    <div class="menu">
        <div class="header">
            <div class="head-title">Conexiones</div>
            <div class="filters">
                <span class="filter pendding" [ngClass]="{'active': activeTabs.pending}"
                    (click)="toggleTabs('pending')">Pendientes</span>
                
                <span class="filter " [ngClass]="{'active': activeTabs.suggestions, 'suggestions': _connectionService.suggestions.length > 0}"
                    (click)="toggleTabs('suggestions')">Sugerencias</span>

                <span class="filter confirmed" [ngClass]="{'active': activeTabs.confirmed}"
                    (click)="toggleTabs('confirmed')">Mi red</span>
            </div>
        </div>

        <ul id="listOfItems"  class="content" [ngClass]="{'empty': (_connectionService.pendingConnections.length == 0 && activeTabs.pending) || (_connectionService.confirmedConnections.length == 0 && activeTabs.confirmed)}">

            <li class="empty-message" *ngIf="_connectionService.pendingConnections.length == 0 && activeTabs.pending && !_connectionService.loadingPendingConnections">
                No tienes solicitudes pendientes
            </li>

            <li class="empty-message" *ngIf="_connectionService.confirmedConnections.length == 0 && activeTabs.confirmed && !_connectionService.loadingConfirmedConnections">
                Tu red de conexiones está vacía
            </li>

            <li class="empty-message" *ngIf="_connectionService.suggestions.length == 0 && activeTabs.suggestions && !_connectionService.loadingSuggestions">
                No hemos encontrado sugerencias para ti
            </li>

            <!-- pending -->
            <li class="pending" *ngIf="activeTabs.pending">
                <div *ngIf="!_connectionService.loadingPendingConnections; else loader">
                    <div class="solicitude-user" *ngFor="let user of _connectionService.pendingConnections">
                        <div class="container-elements">
                            <a class="elements">
    
                                <div class="element-list">
                                    <img #photoUser (error)="photoUser.src = user.photoDefault" [src]="user.photo"
                                        [class]=" 'monitor' | userClassName">
                                    <div [class]="'circle-status-offline'"></div>
                                </div>
    
                                <div class="info">
                                    <div class="title" (click)="openUserProfile(user.user_id)">
                                        <h4>
                                            {{user.username}}
                                        </h4>
    
                                        <h4></h4>
                                    </div>
    
                                    <h4 class="ocupation">
                                        {{user.ocupation && user.ocupation != '' ? user.ocupation : 'Estudiante de Edutin'}}
                                    </h4>

                                    <h4 class="notification-date">
                                        {{user.date | notificationsDate}}
                                    </h4>
    
                                    <!-- <div class="country">
                                        <span>
                                            <img class="country-image" [src]="user.flag">
                                        </span>
                                        <span style="text-transform: capitalize;"
                                            class="country-name">{{user.country}}</span>
                                    </div> -->
                                </div>
    
                                <div class="btn-group">
    
                                    <a class="button to-accept" (click)="reply($event, user, '1')">
    
                                        <span class="icon-tick" style="margin: 2px;"
                                            *ngIf="!user_id_to_reply.includes(user.user_id)"></span>
    
                                        <div class="spinner spinner--button white"
                                            *ngIf="user_id_to_reply.includes(user.user_id)">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </a>
    
                                    <a class="button ignore" (click)="reply($event, user, '-1')">
                                        <i style="margin: 0 4px; font-size: 13px;" class="icon-close"></i>
                                    </a>
                                </div>
    
                            </a>
                        </div>
                    </div>
                </div>
                
            </li>

            <!-- Suggestions -->
            <li class="suggestions" *ngIf="_connectionService.suggestions.length > 0 && activeTabs.suggestions ">
                <div style="position: relative;" *ngIf="!_connectionService.loadingSuggestions; else loader">
                    <div id="suggestion-{{user.user_id}}" class="solicitude-user kkk" *ngFor="let user of _connectionService.suggestions; let i = index">
                        <div class="container-elements">
                            <a class="elements">
    
                                <div class="element-list">
                                    <img #photoUser (error)="user.photoDefault" [src]="user.photo"
                                        [class]=" 'monitor' | userClassName">
                                    <div [class]="'circle-status-offline'"></div>
                                </div>
    
                                <div class="info">
                                    <div class="title" (click)="openUserProfile(user.user_id)">
                                        <h4>
                                            {{user.username}}
                                        </h4>
    
                                        <h4></h4>
                                    </div>
    
                                    <h4 class="ocupation">
                                        {{user.ocupation}}
                                    </h4>
    
                                    <div class="country">
                                        <span>
                                            <img class="country-image" [src]="user.flag">
                                        </span>
                                        <span style="text-transform: capitalize;"
                                            class="country-name">{{user.country}}</span>
                                    </div>
                                </div>
    
                                <div class="btn-group">
    
                                    <a class="button to-accept" (click)="followParticipant($event, user)">
    
                                        <span style="margin: 2px;"
                                            *ngIf="!user_id_to_connect.includes(user.user_id)">Conectar</span>
    
                                        <div class="spinner spinner--button white"
                                            *ngIf="user_id_to_connect.includes(user.user_id)">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </a>
    
                                    <a class="button ignore" (click)="deleteSuggestion($event, user)">
                                        <i style="margin: 0 4px; font-size: 13px;" class="icon-close"></i>
                                    </a>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                
            </li>

            <!-- Aproved -->
            <li class="confirmed" *ngIf="_connectionService.confirmedConnections.length > 0 && activeTabs.confirmed ">
                <div *ngIf="!_connectionService.loadingConfirmedConnections; else loader">
                    <div class="solicitude-user" *ngFor="let user of _connectionService.confirmedConnections">
                        <div class="container-elements">
                            <a class="elements">
    
                                <div class="element-list">
                                    <img #photoUser (error)="user.photoDefault" [src]="user.photo"
                                        [class]=" 'monitor' | userClassName">
                                    <div [class]="'circle-status-offline'"></div>
                                </div>
    
                                <div class="info">
                                    <div class="title" (click)="openUserProfile(user.user_id)">
                                        <h4>
                                            {{user.username}}
                                        </h4>
    
                                        <h4></h4>
                                    </div>
    
                                    <h4 class="ocupation">
                                        {{user.ocupation && user.ocupation != '' ? user.ocupation : 'Estudiante de Edutin'}}
                                    </h4>

                                    <h4 class="notification-date">
                                        Conectados desde {{user.date | notificationsDate}}
                                    </h4>
    
                                    <!-- <div class="country">
                                        <span>
                                            <img class="country-image" [src]="user.flag">
                                        </span>
                                        <span style="text-transform: capitalize;"
                                            class="country-name">{{user.country}}</span>
                                    </div> -->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </li>

        </ul>

        <!-- Ver más en pendientes -->
        <div class="showMore"
            *ngIf="activeTabs.pending && pendingNextToken != null">
            <a (click)="viewMoreConnections($event, 'pending')" *ngIf="!viewMoreLoader">Ver más</a>
            <div *ngIf="viewMoreLoader" class="loader"></div>
        </div>

        <!-- Ver más de suggestions -->
        <div class="showMore"
            *ngIf="activeTabs.suggestions && suggestionLastEvaluateKey != null">
            <a (click)="viewMoreSuggestions($event, true)" *ngIf="!viewMoreLoader">Ver más</a>
            <div *ngIf="viewMoreLoader" class="loader"></div>
        </div>

        <!-- Ver mas en mi red -->
        <div class="showMore"
            *ngIf="activeTabs.confirmed && confirmedNextToken != null">
            <a (click)="viewMoreConnections($event, 'confirmed')" *ngIf="!viewMoreLoader">Ver más</a>
            <div *ngIf="viewMoreLoader" class="loader"></div>
        </div>


    </div>


    <ng-template #loader>
        <div class="solicitude-user" *ngFor="let item of [1,2,3,4,5]">
            <div class="container-elements">
                <a class="elements">
                    
                    <div class="element-list">
                        <span class="loading-Element"
                            style="background-color: rgba(104, 119, 149, .3); width: 55px; height: 55px; border-radius: 50%; display: flex; "></span>
                    </div>


                    <div class="content" style="width: 100%; margin: 0;">
                        <div class="title">
                            <h4 class="loading-Element"
                                style="height: 18px; background-color: rgba(104, 119, 149, .3);; width: 150px; border-radius: 8px;">
                            </h4>

                            <h4></h4>
                        </div>
                        <div class="legend">

                            <h4 class="last_message loading-Element"
                                style="height: 16px; background-color: rgba(104, 119, 149, .3); width: 80%; border-radius: 8px">
                            </h4>


                        </div>
                    </div>

                </a>
            </div>


        </div>
    </ng-template>
</div>