import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, retry, debounceTime } from 'rxjs/operators';
import { ClassroomService } from 'src/app/classroom/services/classroom.service';
import { HttpClient } from '@angular/common/http';




declare global {
  interface Window { DOMParser: any; ActiveXObject: any }
}
declare const ActiveXObject: (type: string) => void;

@Injectable({
  providedIn: 'root'
})
export class PlayerBarService {

  public displayBtnOverNext:boolean = false;

  constructor(
    private _classroomService: ClassroomService,
    private http: HttpClient,
  ) {

    this.subject
      .pipe(debounceTime(200))
      .subscribe(ev => {
        this.timeToSeek = 0;

        ev.second = ev.second < 0 ? 0 : ev.second;
        this._classroomService.data.source.time = ev.second;
        if (this.player.youtube != null) {
          this.player.youtube.seekTo(ev.second);
        }

        if (this.player.local != null) {
          this.player.local.currentTime = ev.second;
        }

        this.playVideo();

      }
      );


  }

  timeToSeek = 0;

  public sourceHasUploads: boolean = null; //comprobar si el recurso actual tiene uploads (apuntes)
  public enableTakeNote: boolean = false;

  public progressTime: number = 0;

  public player: any = {
    youtube: null,
    local: null,
    event: null,
    srcDuration: 0,
    keyOptionsTrue: true
  }

  public captions: any = {
    on: false,
    subtitle: null,
    tracklist: null,
    translate: '',
    translationLanguages: null,
    hasCaptions: false,
  }

  public settings = {
    on: false,
    captions: false,
    velocidad: false,
    volumen: false
  };

  public video_time = {
    time_() {
      if (this.player.local != null) {
        return this.player.local.duration;
      }
      if (this.player.youtube != null) {
        return this.player.youtube.getDuration();
      }
    }
  }

  public isFullScreen: boolean = false;

  recentUpdate(isFinished) {

    this._classroomService.recentUpdate(this._classroomService.data.source).subscribe((recent: any) => {
      // ('recent guardado: ', recent);
      // actualizar recent del source
    });



  }


  public onStateChange(event) {

    switch (event) {
      case -1://Unstarted youtube video;       
        break;
      case 0:
        // ('FINALIZÓ EL VIDEO');        
        this.getBuffer(false);
        this.pauseVideo();

        this.progressTime = 100;
        this._classroomService.data.source.time = this._classroomService.data.source.duration;
        if (!this._classroomService.data.source.sourceCompleted) this._classroomService.data.source.sourceCompleted = true;
        if (!this._classroomService.data.source.finished) this._classroomService.data.source.finished = true;
        this.recentUpdate(true);

        this.player.event = event;

        if (this.player.youtube) {
          this.player.youtube.stopVideo();
        }


        this.display_ads();


        break;
      case 1:
        this.getBuffer(true);
        this.player.event = event;

        if (this.player.youtube) {
          let YToptions = this.player.youtube.getOptions();

          if (YToptions.includes('captions')) {
            this.player.youtube.setOption("captions", "fontSize", 1);
          }
        }
        // ('VIDEO PLAY');        
        // this._playerBarService.player.event = '1';
        break;
      case 2:
        this.getBuffer(false);
        this.player.event = event;
        // this._playerBarService.player.event = '2';
        // ('VIDEO PAUSE');        
        break;
      case 3:
        // ("Cargando Buffer")
        break;
      case 4:
        // ('Time update from localVideo');     
        break;
      case 5:
        // ('volume change: ', this.player.volume);
        break;
      case 6://Progress
        // ('progress');
        break;
    }


  }


  public bufferInterval;
  public bufferedPercent: number;
  private last_second_saved: number = 0;
  private current_second = 0;

  getBuffer(ev) {

    if (ev == true) { // && ((this.player.youtube != null || this.player.local != null) && this.player.event == 1)
      // if (this.progressTime == 0 && this.player.youtube != null) {
      //   this.youtubeCaptions();
      // }

      this.bufferInterval = setInterval(() => {
        
        this._classroomService.data.source.time = this.player.local != null ? this.player.local.currentTime : this.player.youtube != null ? this.player.youtube.getCurrentTime() : 0;

        if (this.player.youtube != null) {
          let YTtime = (this.player.youtube.getCurrentTime() != 0 ? ((this.player.youtube.getCurrentTime() * 100) / this.player.youtube.getDuration()) : 0) * 100 / 100;
          this.progressTime = YTtime > 100 ? 100 : Math.round(YTtime * 10) / 10;

        }

        if (this.player.local != null) {
          // let time = (this.player.local.currentTime != 0 ? ((this.player.local.currentTime * 100) / this._classroomService.data.source.duration) : 0) * 100 / 100;
          let time = (this.player.local.currentTime != 0 ? ((this.player.local.currentTime * 100) / this.player.local.duration) : 0) * 100 / 100;
          this.progressTime = time > 100 ? 100 : Math.round(time * 10) / 10;

        }


        if (this.current_second != 0 && this.current_second == 20) {
          // console.log('Guardar recent');

          if (this.progressTime == this._classroomService.data.source.duration) {
            this.recentUpdate(true);
            // console.log('finalizó el video');
          } else {
            this.recentUpdate(false);
            // console.log('No finalizó el video');
          }
          this.current_second = 0;



        } else {

          this.current_second++;

        }

        if(this.progressTime >= 90){
          this.displayBtnOverNext = true;
          //Mostrar botón de siguiente clase flotante video
        }else{
          this.displayBtnOverNext = false;
        }

        if(this.progressTime >= 95 && !this._classroomService.data.source.sourceCompleted){


          this._classroomService.data.source.time = this._classroomService.data.source.duration;
          this.recentUpdate(true);
          // console.log('finalizó el video');
        }




        if (this.player.youtube) {
          this.bufferedPercent = ((this.player.youtube.getVideoLoadedFraction() * 100) / this.player.youtube.getVideoBytesTotal());
        } else if (this.player.local) {
          this.bufferedPercent =
            Math.round(this.player.local.duration > 0 && this.player.local.buffered.length > 0 ?
              this.player.local.buffered.end(0) / this.player.local.duration * 100 : 0);
        }

        if (this.player.local == null && this.player.youtube == null) {
          if (this.bufferInterval) clearInterval(this.bufferInterval);
        }


      }, 1000);

    } else {
      // ('***stop buffer***');
      if (this.bufferInterval != null) {
        if (this.bufferInterval) clearInterval(this.bufferInterval);
      }
    }



  }

  subject: Subject<any> = new Subject();
  seekTime(ev?, closeModal?: boolean) {

    this.pauseVideo();

    this.timeToSeek += ev;

    let second = ((this.progressTime * this.getVideoDuration()) / 100);

    if (ev != null) {

      second = second + this.timeToSeek;
      let time = (second != 0 ? ((second * 100) / this.getVideoDuration()) : 0) * 100 / 100;
      this.progressTime = time > 100 ? 100 : Math.round(time * 10) / 10;

    }


    this.subject.next({ second, closeModal })

  }



  _seekTime(ev?, closeModal?: boolean) {



    this.pauseVideo();
    let second = ((this.progressTime * this.getVideoDuration()) / 100);

    if (ev != null) {

      second = second + ev;
      let time = (second != 0 ? ((second * 100) / this.getVideoDuration()) : 0) * 100 / 100;
      this.progressTime = time > 100 ? 100 : Math.round(time * 10) / 10;

    }


    this._classroomService.data.source.time = second;
    if (this.player.youtube != null) {
      this.player.youtube.seekTo(second);
    }

    if (this.player.local != null) {
      this.player.local.currentTime = second;
    }

    setTimeout(() => {

      // if (closeModal != false) {
      //   if (this.progressTime < 99) {
      this.playVideo();
      //     this._classroomService.switcher.modal = { state: false, option: null };
      //   }
      // }
    }, 100);

  }

  pauseVideo() {
    if (this.player.youtube) {
      this.player.youtube.pauseVideo();
    }
    if (this.player.local) {
      this.player.local.pause();
    }
  }

  playVideo() {
    if (this.player.youtube) {
      this.player.youtube.playVideo();
    }
    if (this.player.local) {
      this.player.local.play();
    }
  }

  repeatVideo() {
    this.displayBtnOverNext = false;
    this._classroomService.data.source.time = 0;
    this.progressTime = 0;
    this.seekTime(0);
    setTimeout(() => {
      this.playVideo();
    }, 1000);
  }

  getVideoDuration() {
    // let duration_in_seconds = (parseInt(tmp_duration_split[0]) * 3600) + (parseInt(tmp_duration_split[1]) * 60) + parseInt(tmp_duration_split[2]);        
    return this.player.local ? this.player.local.duration : this.player.youtube.getDuration();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      console.log('%c error::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead            
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  private log(message: string) {
    // (`ClassroomService: ${message}`);
  }


  youtubeCaptions() {

    let track = null;
    let user_language = null;

    if (this._classroomService.data.actions) {
      //Si está logueado poner el idioma correspondiente al del usuario (lenguage_id)
      //cuando se agregue un nuevo idioma oficial a la plataforma es necesario tenerlo en cuenta      
      user_language = this._classroomService.params.config.language_id == '1' ? 'es' : 'en'
    } else {
      user_language = 'es';
    }

    // n3tG77UeGQ4

    let YToptions = this.player.youtube.getOptions();

    if (YToptions.includes('captions')) {
      this.captions.tracklist = this.player.youtube.getOption('captions', "tracklist"); //Lista de subtitulos oficiales del video
      // this.captions.translationLanguages = this.player.youtube.getOption('captions', 'translationLanguages') //Listado de idiomas a los que se puede traducir el archivo de subitulo                     

      if (this.captions.tracklist != null && this.captions.tracklist.length > 0) {
        //Buscar si en el array de idiomas oficiales del video se encuentra el idioma que escogió el usuario
        this.captions.hasCaptions = true;

        track = this.captions.tracklist.find(track_ => track_.languageCode.split('-')[0] == user_language);

        if (track != null) {
          //Si el track con el idioma del usuario existe, asignarlo          
          this.player.youtube.setOption("captions", "track", { "languageCode": track.languageCode });
          this.player.youtube.setOption("captions", "fontSize", 1);
          this.captions.on = true;
          this.captions.translate = null;
        } else {

          //Si el track del idioma del usuario no existe, buscarlo en la lista de translation para traducirlo automaticamente
          if (this.captions.translationLanguages != null && this.captions.translationLanguages.length > 0) {

            let translation_track_index = this.captions.translationLanguages.findIndex(track_ => track_.languageCode.split('-')[0] == user_language);
            let translation_track;

            if (translation_track_index != null && translation_track_index != -1) {
              // Si el idioma del usuario existe para ser traducido              
              // this.captions.translationLanguages[translation_track_index].languageName = 'Español (generados automáticamente)';
              translation_track = this.captions.translationLanguages[translation_track_index];
              this.captions.tracklist.push(translation_track);
              this.captions.subtitle = translation_track;

              this.getTranscript(translation_track, 'youtubeCaptions');

            }

          }


        }

      }

    } else {

      this.captions.tracklist = [
        {
          languageName: user_language == 'es' ? 'Español' : 'Inglés',
          languageCode: user_language,
          isTranslatable: true
        }
      ]

      this.captions.translationLanguages = [
        {
          languageName: 'Español',
          languageCode: 'es'
        },
        {
          languageName: 'Inglés',
          languageCode: 'en'
        },
        {
          languageName: 'Francés',
          languageCode: 'fr'
        }
      ];


      this.getTranscript({ languageCode: user_language });
    }


  }


  getTranscript(track, from_?) {

    this.removeYTSubtitle();

    //traducción del subtitulo
    let current_track = this.player.youtube.getOption('captions', "track");
    let from = current_track != null ? current_track.languageCode : 'en';
    let video_data = this.player.youtube.getVideoData();


    // Usar este metodo en caso de que el metodo anterior haya fallado
    this.get_video_info_from_edutin(video_data.video_id).subscribe(video_info => {

      if (video_info == false) {
        //si puedo cargar la info del video        
      } else {

        if (video_info.languageCode != "es") {

          this.captions.hasCaptions = video_info.isTranslatable;

          this.getTraslate_timedText(`${video_info.baseUrl}&tlang=${track.languageCode}`).subscribe(translation => {
            this.captions.on = true;
            this.captions.translate = translation.transcript;
            if (from_ == 'youtubeCaptions') {
              if (this.captions.tracklist != null && this.captions.tracklist.length > 0) {
                this.captions.tracklist[this.captions.tracklist.length - 1]['transcript'] = translation.transcript;
              } else {
                this.captions.tracklist.push({ transcript: translation.transcript });
              }

            }

            this.player.youtube.unloadModule("captions");
            this.player.youtube.unloadModule("cc");
          })
        }

      }
    });

  }

  removeYTSubtitle() {
    this.captions.on = false;
    this.captions.translate = null;
    this.player.youtube.unloadModule("captions");
    this.player.youtube.unloadModule("cc");
  }




  getSubtitle(data): Observable<any> {
    return this.http.post('https://admin.edutin.com/apis/learn/get_subtitle.json', data)
  }





  // getVideoInfo_(id): Observable<any> {    

  //   let caption_url = 'https://www.googleapis.com/youtube/v3/videos?id='+ id;    

  //   return this.http.get(caption_url, { responseType: 'text' })
  //     .pipe(
  //       map((translation: any) => {
  //         if (translation != '') return JSON.parse(this.xml2json(this.parseXml(translation), ''))
  //       }),
  //       catchError(this.handleError<any>('getTranslate', []))
  //     );
  // }




  getTraslate_timedText(url): Observable<any> {
    // return this.http.get(`https://www.youtube.com/api/timedtext?xoaf=1&v=${data.id}&lang=${data.from}&tlang=${data.to}`, { responseType: 'text' })


    // let caption_url = 'https://e74zhoiaz9.execute-api.us-east-1.amazonaws.com/prod/get-subtitle?url=' + encodeURIComponent(url);    

    return this.http.get(url, { responseType: 'text' })
      .pipe(
        map((translation: any) => {
          if (translation != '') return JSON.parse(this.xml2json(this.parseXml(translation), ''))
        }),
        catchError(this.handleError<any>('getTranslate', []))
      );
  }

  get_video_info(data): Observable<any> {
    //Obtener info del video para la traducción automática    

    // let url = window.location.href.includes('localhost') ? `/get_video_info/?video_id=${data.id}` : `https://www.youtube.com/get_video_info/?video_id=${data.id}`;
    // let url = `http://www.youtube.com/get_video_info/?video_id=${data.id}`;
    // let url = `https://docs.google.com/get_video_info?video_id=${data.id}`;
    let url = `/get_video_info/?video_id=${data.id}`;


    return this.http.get(url, { responseType: 'text' })
      .pipe(
        map((translation: any) => {
          const decodedData = decodeURIComponent(translation);

          if (!decodedData.includes('captionTracks')) {
            //   throw new Error(`Could not find captions for video: ${data.id}`);
            return false;
          } else {
            const regex = /({"captionTracks":.*isTranslatable":(true|false)}])/;
            const [match] = regex.exec(decodedData);
            const { captionTracks } = JSON.parse(`${match}}`);
            return captionTracks[0];

          }

        }),
        catchError(this.handleError<any>('getTranslate', []))
      );
  }


  get_video_info_from_edutin(data): Observable<any> {
    //Obtener info del video para la traducción automática    

    // let url = `https://e74zhoiaz9.execute-api.us-east-1.amazonaws.com/dev/info-video/k?video_id=${data}`;
    let url = `https://e74zhoiaz9.execute-api.us-east-1.amazonaws.com/prod/info-video/k?video_id=${data}`;


    return this.http.get(url, { responseType: 'text' })
      .pipe(
        map((translation: any) => {
          const decodedData = decodeURIComponent(translation);
          // (decodedData)
          if (!decodedData.includes('captionTracks')) {
            //   throw new Error(`Could not find captions for video: ${data.id}`);
            return false;
          } else {
            const regex = /({"captionTracks":.*isTranslatable":(true|false)}])/;
            const [match] = regex.exec(decodedData);
            const { captionTracks } = JSON.parse(`${match}}`);
            return captionTracks[0];

          }

        }),
        retry(3),
        catchError(this.handleError<any>('get_video_info_from_edutin', []))
      );
  }





  parseXml(xml) {
    var dom = null;
    if (window.DOMParser) {
      try {
        dom = (new DOMParser()).parseFromString(xml, "text/xml");
      }
      catch (e) { dom = null; }
    }
    else if (window.ActiveXObject) {
      try {
        dom = new ActiveXObject('Microsoft.XMLDOM');
        dom.async = false;
        if (!dom.loadXML(xml)) // parse error ..

          window.alert(dom.parseError.reason + dom.parseError.srcText);
      }
      catch (e) { dom = null; }
    }
    else
      alert("cannot parse xml string!");
    return dom;
  }

  xml2json(xml, tab) {
    var X = {
      toObj: function (xml) {
        var o: any = {};
        if (xml.nodeType == 1) {   // element node ..
          if (xml.attributes.length)   // element with attributes  ..
            for (var i = 0; i < xml.attributes.length; i++)
              o[xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
          if (xml.firstChild) { // element has child nodes ..
            var textChild = 0, cdataChild = 0, hasElementChild = false;
            for (var n = xml.firstChild; n; n = n.nextSibling) {
              if (n.nodeType == 1) hasElementChild = true;
              else if (n.nodeType == 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
              else if (n.nodeType == 4) cdataChild++; // cdata section node
            }
            if (hasElementChild) {
              if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                X.removeWhite(xml);
                for (var n = xml.firstChild; n; n = n.nextSibling) {
                  if (n.nodeType == 3)  // text node
                    o["text"] = X.escape(n.nodeValue);
                  else if (n.nodeType == 4)  // cdata node
                    o["#cdata"] = X.escape(n.nodeValue);
                  else if (o[n.nodeName]) {  // multiple occurence of element ..
                    if (o[n.nodeName] instanceof Array)
                      o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                    else
                      o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                  }
                  else  // first occurence of element..
                    o[n.nodeName] = X.toObj(n);
                }
              }
              else { // mixed content
                if (!xml.attributes.length)
                  o = X.escape(X.innerXml(xml));
                else
                  o["text"] = X.escape(X.innerXml(xml));
              }
            }
            else if (textChild) { // pure text
              if (!xml.attributes.length)
                o = X.escape(X.innerXml(xml));
              else
                o["text"] = X.escape(X.innerXml(xml));
            }
            else if (cdataChild) { // cdata
              if (cdataChild > 1)
                o = X.escape(X.innerXml(xml));
              else
                for (var n = xml.firstChild; n; n = n.nextSibling)
                  o["#cdata"] = X.escape(n.nodeValue);
            }
          }
          if (!xml.attributes.length && !xml.firstChild) o = null;
        }
        else if (xml.nodeType == 9) { // document.node
          o = X.toObj(xml.documentElement);
        }
        else
          alert("unhandled node type: " + xml.nodeType);
        return o;
      },
      toJson: function (o, name, ind) {
        var json = name ? ("\"" + name + "\"") : "";
        if (o instanceof Array) {
          for (var i = 0, n = o.length; i < n; i++) {
            o[i] = X.toJson(o[i], "", ind + "\t");
            let tmp_o = JSON.parse(o[i]);
            tmp_o.start = parseFloat(tmp_o.start);
            tmp_o.end = tmp_o.start + parseFloat(tmp_o.dur);
            o[i] = JSON.stringify(tmp_o);
          }
          json += (name ? ":[" : "[") + (o.length > 1 ? ("\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind) : o.join("")) + "]";
        }
        else if (o == null)
          json += (name && ":") + "null";
        else if (typeof (o) == "object") {
          var arr = [];
          for (var m in o)
            arr[arr.length] = X.toJson(o[m], m, ind + "\t");
          json += (name ? ":{" : "{") + (arr.length > 1 ? ("\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind) : arr.join("")) + "}";
        }
        else if (typeof (o) == "string")
          json += (name && ":") + "\"" + o.toString() + "\"";
        else
          json += (name && ":") + o.toString();
        return json;
      },
      innerXml: function (node) {
        var s = ""
        if ("innerHTML" in node)
          s = node.innerHTML;
        else {
          var asXml = function (n) {
            var s = "";
            if (n.nodeType == 1) {
              s += "<" + n.nodeName;
              for (var i = 0; i < n.attributes.length; i++)
                s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue || "").toString() + "\"";
              if (n.firstChild) {
                s += ">";
                for (var c = n.firstChild; c; c = c.nextSibling)
                  s += asXml(c);
                s += "</" + n.nodeName + ">";
              }
              else
                s += "/>";
            }
            else if (n.nodeType == 3)
              s += n.nodeValue;
            else if (n.nodeType == 4)
              s += "<![CDATA[" + n.nodeValue + "]]>";
            return s;
          };
          for (var c = node.firstChild; c; c = c.nextSibling)
            s += asXml(c);
        }
        return s;
      },
      escape: function (txt) {
        return txt.replace(/[\\]/g, "\\\\")
          .replace(/[\"]/g, '\\"')
          .replace(/[\n]/g, '\\n')
          .replace(/[\r]/g, '\\r')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, "");
      },
      removeWhite: function (e) {
        e.normalize();
        for (var n = e.firstChild; n;) {
          if (n.nodeType == 3) {  // text node
            if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
              var nxt = n.nextSibling;
              e.removeChild(n);
              n = nxt;
            }
            else
              n = n.nextSibling;
          }
          else if (n.nodeType == 1) {  // element node
            X.removeWhite(n);
            n = n.nextSibling;
          }
          else                      // any other node
            n = n.nextSibling;
        }
        return e;
      }
    };
    if (xml.nodeType == 9) // document node
      xml = xml.documentElement;
    var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
    return "{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
  }







  display_ads(id?) {//Publicidad         

    this._classroomService.display_a_d_s.display = false;

    if (this._classroomService.data.actions) {//Si está logueado   

      let tourPage = parseInt(this._classroomService.data.certification.Certification.tour);
      let calificacion = parseInt(this._classroomService.data.certification.Certification.calification);
      let certification = this._classroomService.data.certification;
      //Datos para mostrar ads
      // certification.User.role = 'free';
      // certification.Certification.estado = '-1';
      // calificacion = 10;
      // tourPage = 0;
      //=======================

      // if (certification.User && certification.User.role != null) {
      // if (certification.User.role == 'free') {//Si es usuario free
      tourPage = tourPage == -1 ? 0 : tourPage;

      if (certification.Certification.estado == '-1' && calificacion < 90) {
        //Si no ha pagado el certificado y puede mostrar la infomación                          

        if (tourPage <= 3) {
          const { lesson, modules } = this._classroomService.data;

          if (tourPage == 0) {

            this._classroomService.display_a_d_s.display = true;

          } else {


            if (lesson.i_module != null && ((lesson.i_module == 0 && lesson.i_lesson > 0) || (lesson.i_module != 0))) {

              let last_lesson;

              if (lesson.i_module > 0 && lesson.i_lesson == 0) {
                let last_module = modules[lesson.i_module - 1];
                last_lesson = last_module.Clase[last_module.Clase.length - 1]
              }
              if (lesson.i_module >= 0 && lesson.i_lesson > 0) {
                let last_module = modules[lesson.i_module];
                last_lesson = last_module.Clase[lesson.i_lesson - 1];
              }

              if (last_lesson != null && last_lesson.progress_percentaje > 90) {
                this._classroomService.display_a_d_s.display = true;
              }

            }

          }

        }

      }

    } else {
      // ('No Logueado');
      this._classroomService.display_a_d_s.display = true;
    }


    // if (this._classroomService.display_a_d_s.display && !this._classroomService.display_a_d_s.hide) {
    //   console.log('entró acá');
    //   if (this._classroomService.data.source.id != this._classroomService.data.lesson.Source[0].id) {
    //     this.selectFile(this._classroomService.data.lesson, this._classroomService.data.lesson.Source[0]);
    //   }

    // }


  }








}



