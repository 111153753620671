import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedPreviewLinkModule } from '../../../shared/shared-preview-link.module';
import { PreviewUrlService } from '../../../shared/service/preview-url.service';
import { CourseLiveChatComponent } from './course-live-chat.component';
import { CutUserNamePipe, SafePipe, GroupByMonthPipe } from '../../pipes/course-live-chat.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClassroomSharedModule } from '../../shared-classroom/classroom-shared.module';


@NgModule({
  declarations: [ 
    CourseLiveChatComponent,
    CutUserNamePipe,
    SafePipe,
    GroupByMonthPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedPreviewLinkModule,
    SharedModule,
    ClassroomSharedModule
  ],
  providers: [
      PreviewUrlService,
  ],
  exports: [
    CourseLiveChatComponent
  ]
})
export class CourseLiveChatModule { }
