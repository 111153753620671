import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {

  constructor() { }

  audioState = 0;
  @Input() audioFile;
  progressTime: number = 0;
  audioPlayer: any = null;
  @ViewChild('HMLT5AudioPlayer') HMLT5AudioPlayer: any;


  ngOnInit(): void {
  }


  audioLoaded() {
    this.audioPlayer = this.HMLT5AudioPlayer.nativeElement;
  }



  toggleAudio() {
    if (this.audioPlayer != null) {
      if (this.audioPlayer.paused) {
        this.audioPlayer.play();
      } else {
        this.audioPlayer.pause();
      }
    }
  }


  public onStateChange(event) {

    switch (event) {
      case -1://Unstarted youtube video;       
        break;
      case 0:
        // ('FINALIZÓ EL VIDEO');     
        setTimeout(() => {
          this.progressTime = 0;
        }, 1000);
        break;
      case 1:
        // ('VIDEO PLAY');        
        this.audioState = event;
        break;
      case 2:
        // ('VIDEO PAUSE');        
        this.audioState = event;
        break;
      case 3:
        // ("Cargando Buffer")
        break;
      case 4:
        this.progressTime = (this.audioPlayer.currentTime != 0 ? ((this.audioPlayer.currentTime * 100) / this.audioPlayer.duration) : 0) * 100 / 100;
        // console.log('progressTime: ', this.progressTime);
        // ('Time update from localVideo');     
        break;
      case 5:
        // ('volume change: ', this.audioPlayer.volume);
        break;
      case 6://Progress
        // ('progress');
        break;
    }


  }



  getIntTime(ev) {
    let second = ((this.progressTime * this.audioPlayer.duration) / 100);
    this.audioPlayer.currentTime = second;
    setTimeout(() => {
      this.audioPlayer.play();
    }, 100);
  }



}
