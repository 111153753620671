import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsModalComponent } from './comments-modal.component';


import { CommunityModule } from 'src/app/classroom/community/community.module';



@NgModule({
  declarations: [CommentsModalComponent],
  imports: [
    CommonModule,
    CommunityModule
  ],
  exports: [
    CommentsModalComponent
  ],
  bootstrap: [
    CommentsModalComponent
  ],
  entryComponents: [
    CommentsModalComponent
  ]
})
export class CommentsModalModule { }