import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { PushNotificationService } from '../../../service/push-notification.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() showMenu: boolean = false;
  showNotifications: boolean = false;
  notifications_unreaded: any = null;
  notifications_list: any = null;
  notification_filter_selected: string = null;

  @Output() openNotification = new EventEmitter<any>();

  constructor(
    public _router: Router,
    public _appService: AppService,
  ) { }

  ngOnInit(): void {

  }


  updateNotificationList(filter?) {
    this.notifications_unreaded = null;

    if (this.showNotifications && this._appService.notifications.count > 0) {
      this.notifications_unreaded = this._appService.notifications.all.filter(notification => notification.estado == 1);
    }

    if (filter) {
      this.notification_filter_selected = filter;
    } else {

      if (this.notification_filter_selected == null) {
        this.notification_filter_selected = 'all';
      }

    }


    switch (this.notification_filter_selected) {
      case 'all':
        this.notifications_list = JSON.parse(JSON.stringify(this._appService.notifications.all));
        break;
      case 'unread':
        this.notifications_list = JSON.parse(JSON.stringify(this.notifications_unreaded));
        break;
    }

  }


  toggleNotifications(): void {
    this.showNotifications = !this.showNotifications;
    this.optionsHiddenN();
    // if (this.showNotifications && this._appService.notifications.count > 0) {
    //   this.setAllReaded();
    // }

    this.updateNotificationList();


  }



  load_notification: string = null;
  goToLink(notification, item): void {
    if (notification._type == 'NIB' || notification._type == 'NIBG') {
      console.log(notification);
    } else if (notification._type == 'MAN') {

      // this.openNotification.emit()
      window.open(notification.link, "_blank");
      this.load_notification = null;

    } else {

      this.load_notification = notification.fecha;

      this._appService.comment_modal_ = {
        state: false,
        data: null
      }

      this.configNotiSP(null, 2, { d: notification, e: '0' })

      let link = notification.link + '';
      let comment_id = link.split('/')[link.split('/').length - 1];
      this._appService.getCourseToRedirect(comment_id).subscribe(response => {

        this.load_notification = null;

        const { print } = response;
        print['type'] = notification._type;

        this._appService.comment_modal_ = {
          state: true,
          data: print
        }


        // if (data.print.hasOwnProperty('certification_id')) {
        this.showNotifications = false;
        //   this._router.navigateByUrl('/classroom?config=' + JSON.stringify(data.print) + '&comment_id=' + comment_id);
        // }

        // if (img_element) img_element.classList.remove('loader-circle', 'e-blue');        

      })
    }
  }

  // Notifications
  getNotificationsUnreaded(): void {
    // Traer notificaciones sin leer
    this._appService.getNotificationsUnreaded().subscribe(data => {
      if (data.valid) {
        this._appService.notifications.count = data.print;
      }
    });
  }

  getNotifications(pagination): void {
    // Traer notificaciones con paginacion
    this._appService.notifications_loaded = false;
    this._appService.getNotifications(pagination).subscribe(data => {
      if (data.valid) {
        this._appService.notifications.all = data.print;
        if (data.metadata.hasOwnProperty('last_evaluated_key')) {
          this._appService.more_notifications = true;
        } else {
          this._appService.more_notifications = false;
        }
      } else {
        this._appService.notifications.all = [];
      }
      this._appService.notifications_loaded = true;

      this.updateNotificationList();
    });
  }

  more_notifications_loaded: boolean = false;
  getMoreNotifications(): void {
    if (this._appService.notifications.all.length > 0) {
      this.more_notifications_loaded = true;
      this._appService.getNotifications(this._appService.notifications.all[this._appService.notifications.all.length - 1].fecha).subscribe(data => {
        if (data.valid) {
          data.print.forEach(element => {
            this._appService.notifications.all.push(element);
          });
          if (data.metadata.hasOwnProperty('last_evaluated_key')) {
            this._appService.more_notifications = true;
          } else {
            this._appService.more_notifications = false;
          }
        }
        this.more_notifications_loaded = false;

        this.updateNotificationList(this.notification_filter_selected);
      });
    }
  }


  reading: boolean = false;

  setAllReaded(): void {
    if (this._appService.notifications.all.length > 0) {

      this.reading = true;

      let body = {
        "data": {
          "state": "0"
        }
      };
      this._appService.setStateNotification('', body).subscribe(data => {
        if (data.valid) {
          this._appService.notifications.count = 0;
          this._appService.notifications.all = this._appService.notifications.all.map((item) => {
            item.estado = 0;
            return item;
          })
        } else {
          this._appService.notifications.count = 0;
        }
        this.reading = false;
        this.updateNotificationList();
      });
    }
  }


  display_options_item_notification: any = null;

  configNotiSP(event: any, type: number, data: any) {
    if (event) event.stopPropagation();
    switch (type) {
      case 0:
        // Abrir opciones de la notificacion
        if (this.display_options_item_notification == data.fecha) {
          this.display_options_item_notification = null;
        } else {
          this.display_options_item_notification = data.fecha;
        }

        // if (data.parentElement.children[1].style.display == "none") {
        //   data.parentElement.children[1].style.display = "block";
        // } else {
        //   data.parentElement.children[1].style.display = "none";
        // }
        break;
      case 1:
        // Eliminar notificación
        this._appService.deleteNotification(data.fecha).subscribe(data => {
          this.getNotificationsUnreaded();
          this.getNotifications(null);
          this.display_options_item_notification = null;
        });
        break;
      case 2:
      // Marcar como leído
      case 3:
        // Marcar como no leído
        let body = {
          "data": {
            "state": data.e
          }
        };
        this._appService.setStateNotification(data.d.fecha, body).subscribe(data => {
          this.getNotificationsUnreaded();
          this.getNotifications(null);
          this.display_options_item_notification = null;
        });
        break;
      case 4:
        // Cargar notificaiones anteriores
        this.getMoreNotifications();
        break;
    }
  }

  readNotification(index, data, type) {
    // Marcar como leído
    // let json1 = {
    //   "dates": [data.fecha]
    // };
    // this._appService.setAllReaded(json1).subscribe(data => {
    //   if (type == 1) {
    //     this.notifications.nuevas[index].estado = 0;
    //   } else if (type == 0) {
    //     this.notifications.anteriores[index].estado = 0;
    //   }
    // });
  }


  optionsHiddenN(): void {
    const options = document.querySelectorAll('.notifications .container-items .visible');
    if (options && options.length > 0) {
      options.forEach(element => {
        element.setAttribute('style', "display: none;");
      });
    }
  }

}
