import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'comment-loading',
  templateUrl: './comment-loading.component.html',
  styleUrls: ['./comment-loading.component.scss']
})
export class CommentLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() amount: any = 0;
  @Input() type: any = 0;

  getArray() {

    this.amount = parseInt(this.amount);

    let arr = [];
    for (let i = 0; i < this.amount; i++) {
      arr.push(i);
    }

    return arr;
  }

}
