import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessagePayload } from './notification-interface'

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  messagingFirebase: firebase.messaging.Messaging;

  constructor() {
    try {
      firebase.initializeApp(environment.firebase);
      this.messagingFirebase = firebase.messaging();
    } catch (error) {
      console.warn('Firebase SDK is not supported', error);
    }
  }

  requestPermission() {
    return new Promise(async (resolve, reject) => {
      const permission = await Notification.requestPermission();
      try {
        if (permission === "granted") {
          // navigator.serviceWorker.getRegistration("src/firebase-messaging-sw.js")
          // .then(register => {
          //   if (register) {
          //     register.update();
          //   }
          // });
          const tokenFirebase = await this.messagingFirebase.getToken();
          resolve(tokenFirebase);
        } else {
          console.warn("Unable to get permission to notify:", permission);
          reject(permission);
        }
      }
      catch (error) {
        console.error('Here is the error message', error);
      }
    })
  }

  private messaginObservable = new Observable<MessagePayload>(observe => {
    this.messagingFirebase.onMessage(payload => {
      observe.next(payload)
    })
  })

  receiveMessage() {
    return this.messaginObservable;
  }

  getToken() {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await this.messagingFirebase.getToken());
      }
      catch (error) {
        console.error('Here is the error message', error);
        reject(0);
      }
    })
  }
}