import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { CommunityService } from './community.service';
import { SharedService } from '../../shared/shared.service';
import { SortSourcesByOrder, TimeTransform, CourseProgress } from '../pipes/classroom.pipe';
import { GlobalData, UrlLambdaApi } from '../models/models';
import { AppService } from '../../app.service';
import { QueryRef } from 'apollo-angular';


@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  public modules_loaded: boolean = false;

  public cartel_option_ad: any; //No la inicialices porque empieza todas las ads a explotar

  // public video_full_top: boolean = false;
  public video_is_fullscreen: boolean = false;

  public loadWelcomeMsg: boolean = false;

  conversationsQuery: QueryRef<any>;
  conversationsObservable: Observable<any>;
  conversation;
  conversations_loaded: boolean = false;
  static ws_host = 'tcm5oi5qdveqpamqp4txivk54q.appsync-api.us-east-1.amazonaws.com';


  viewer = {
    show: false,
    file: null
  }

  currentIndex = {
    module: null,
    lesson: null,
    source: null,
  }

  public quiz_summary = {
    display: false,
    cancel: false,
    cta: false,
    data: null,
    moreInfo: false
  }

  public data: GlobalData = {
    actions: false, // si el usuario está logeado ? true : false;
    certification: null, //data del certificado y el usuario
    course: null,
    initialSource: null,
    initialLesson: null,
    lesson: null,
    modules: null,
    position: { module: null, lesson: null, isLastOne: null },
    source: null,
    uploads: null,
    url_route: null,
    file: null,
    width_size: 0,
    height_size: 0,
    img_viewer_src: null,
  }

  public certification: Object | any;

  public language = [
    { lan: 'c_cpp', name: 'C and C++', ext: 'cpp' },
    { lan: 'csharp', name: 'C#', ext: 'cs' },
    { lan: 'css', name: 'Css', ext: 'css' },
    { lan: 'html', name: 'Html', ext: 'html' },
    { lan: 'java', name: 'Java', ext: 'java' },
    { lan: 'javascript', name: 'Javascript', ext: 'js' },
    { lan: 'mysql', name: 'MySQL', ext: 'sql' },
    { lan: 'ruby', name: 'Ruby', ext: 'rb' },
    { lan: 'sql', name: 'SQL', ext: 'sql' },
    { lan: 'sqlserver', name: 'SQLServer', ext: 'sql' },
    { lan: 'swift', name: 'Swift', ext: 'Swift' },
    { lan: 'php', name: 'PHP', ext: 'php' },
    { lan: 'python', name: 'Python', ext: 'py' },
    { lan: 'typescript', name: 'Typescript', ext: 'ts' }
  ];

  public switcher: any = {
    youtube: false,
    local: false,
    viewer: false,
    file: false, //Es el file que viene de la comunidad
    quiz: false,
    modal: { state: false, option: null },
    notes: false,
    chatroom: false,
    img_viewer: false,
    tutorial: false
  }
  public config: any;
  public myParams: URLSearchParams;
  public language_id = '2'; // solo de prueba (debe venir en el certification)


  public comments_by_source_loaded: boolean = false;

  public params = {
    token: null,
    config: null
  }

  public fullSource: boolean = false;

  sidebar = {
    state: 'open',
    component: 'course_content',
    components: {
      course_content: false,
      comments: false,
      community: false,
      notes: false,
      contributions: false,
      evaluations: false
    },
    previous_state: null,
    participate: false
  }

  public upload_position = 0;
  public close_upload_position: number = 30;


  public isSavingRecentModal: boolean = false;

  private previousUrl: string;
  public currentUrl: string;

  public scholarship;

  public load_classroom_view: boolean = false;

  certification_progress: any = 0;


  unread_messages_counter: number = 0;

  public display_a_d_s: any = {
    display: false,
    source: null,
    hide: true
  };



  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private http: HttpClient,
    private _sharedService: SharedService,
    private _communityService: CommunityService,
    private _appService: AppService,
  ) {

    let queryParams_ = this._route.queryParams.subscribe(params => {
      this.currentUrl = this._router.url;
      this._router.events.subscribe((event: any) => {

        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;

          if (this.data.url_route == null) {
            // Si hay config en la url y si es diferente de la que está almacenada se almacena el nuevo config  
            //Si la url trae token y todo, limpiarla
            if (event.urlAfterRedirects.includes('classroom')) {
              this.data.url_route = '/classroom';
            };

            // Si hay token en la url y si es diferente de la que está almacenada se almacena el nuevo token  
            if (params['token'] != null) {
              this._router.navigate(
                [],
                {
                  relativeTo: this._route,
                  queryParams: { token: null },
                  queryParamsHandling: 'merge', // remove to replace all query params by provided
                });
            }
          }
        }
      });

      setTimeout(() => {
        queryParams_.unsubscribe();
      }, 250);
    });

  }

  public getSessionStorage(): Observable<any> {

    // let token = localStorage.getItem('token');
    let token = this._appService.token;
    let config = JSON.parse(sessionStorage.getItem('config'));

    this.params.token = token;
    this._communityService.params.token = token;

    this.params.config = config;
    this._communityService.params.config = config;


    if (config != null && config.certification_id != null && config.certification_id != '' && config.certification_id != '0') {
      this.data.actions = true;
      this._appService.config.actions = true;
    } else {
      this.data.actions = false;
      this._appService.config.actions = false;
    }

    if (token != null && config != null) {
      return of(true);
    } else {
      return of(false);
    }

  }



  getHeaders(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.params.token
    })
    this.params.config = JSON.parse(sessionStorage.getItem('config'));

    return ({ headers: headers, params: this.params.config });
  }

  getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.params.token
    })
    this.params.config = JSON.parse(sessionStorage.getItem('config'));

    return ({ headers: headers });
    // return ({ headers: headers, params: this.params.config });
  }


  post(url: any, formData: FormData) {
    return this.http.post(url, formData, this.getHeaders());
  }


  toggleSidebar(ev, component?) {

    // if (ev == "close") {

    //   this.sidebar.components = {
    //     course_content: false,
    //     comments: false,
    //     community: false,
    //     notes: false,
    //     contributions: false,
    //     evaluations: false
    //   }
    // } else {

    if (component != null) {
      if (this.sidebar.components[component]) {
        // ev = 'close';
        // this.sidebar.components[component] = false;
        this.sidebar.component = component;
      } else {
        for (var key in this.sidebar.components) {

          if (key == component) {
            this.sidebar.components[key] = true;
            this.sidebar.component = key;
          } else {
            this.sidebar.components[key] = false;
          }

        }
      }
    }

    // }

    // this.sidebar.state = ev;


    // if (ev != "close" && this.switcher.notes == 'full' && this.data.width_size <= 1155) {
    //   this.switcher.notes = 'medium';
    // }

    // if (ev != "open" && this.switcher.notes == 'medium') {
    //   if (this.data.width_size >= 700 && this.data.width_size <= 1155) {
    //     this.switcher.notes = 'full';
    //   }
    // }

  }


  toggleNotes(ev) {

    let tmp_switcher = JSON.parse(JSON.stringify(this.switcher));

    if (ev == 'full') {
      tmp_switcher.notes = (tmp_switcher.notes == 'full' || tmp_switcher.notes == 'medium') ? true : 'full';
      if (tmp_switcher.notes == 'full' && this.data.width_size <= 700) {
        tmp_switcher.notes = 'medium';
      }
    }

    let live_chat_element: HTMLElement = document.querySelector('.live-chat-group');

    if (ev == 'open') {

      switch (tmp_switcher.notes) {
        case 'full':
          tmp_switcher.notes = 'full-min';
          break;
        case 'full-min':
          tmp_switcher.notes = 'full';
          break;
        case 'medium':
          tmp_switcher.notes = 'medium-min';
          break;
        case 'medium-min':
          tmp_switcher.notes = 'medium';
          break;
        case true:
          tmp_switcher.notes = 'min';
          break;
        case 'min':
        default:
          tmp_switcher.notes = true;
          break;
      }


      if (live_chat_element) live_chat_element.style.display = 'none';

    }


    if (ev == 'close') {
      tmp_switcher.notes = false;
      if (live_chat_element) live_chat_element.style.display = 'flex';
    }



    this.switcher = JSON.parse(JSON.stringify(tmp_switcher));
    // Si Tomar apunte está cerrado -> abrir nuevo apunte
    // Si está true 'full' o 'medium' -> solo minimizar sin cerrar

    // setTimeout(() => {
    //   this.takeNotePosition();
    // }, 100);
  }



  getAttempts(url_request): Observable<any> {

    //Attempts del curso:
    // https://api.edutin.com/b/d/attempts?certification_id=353619

    //Attemps de la clase incial:
    // https://api.edutin.com/b/d/attempts?quiz_id=["1567","1575","2697","2698","2700"]

    return this.http.get(url_request, this.getHeadersLambda())
      .pipe(
        map((attempts: any) => {
          return attempts;
        }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getCertification', []))
      );
  }



  getCertification(): Observable<any> {

    const { curso_id, idu } = this.params.config;

    return this.http.get(UrlLambdaApi + `/dynamo/certifications?curso_id=${curso_id}&user_id=${idu}`, this.getHeadersLambda())
      .pipe(
        map((certification: any) => {

          const { data, status } = certification;

          this.data.certification = JSON.parse(JSON.stringify(data[0]));

          if (status && Object.keys(this.data.certification).length > 0) {

            if (this.data.certification.Certification.clase_id != 0) this.params.config.clase_id = this.data.certification.Certification.clase_id;

            if (this.data.certification.hasOwnProperty('User')) { this._sharedService.user_data = this.data.certification.User; }

          } else {

          }

          return certification.certification;
        }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getCertification', []))
      );
  }


  getRecent(param): Observable<any> {

    const { curso_id, clase_id, source_id } = this.params.config;

    let url_params = '';
    if (param == 'lesson') {
      url_params = `${curso_id}_${clase_id}`;
    }
    if (param == 'course') {
      url_params = curso_id;
    }

    return this.http.get(UrlLambdaApi + `/recents?course_class_source_id="${url_params}"`, this.getHeadersLambda())
      .pipe(map((response: any) => {
        const { data } = response;

        data.forEach((rec_, i_rec) => {
          let curso_id = rec_.course_class_source_id.split('_')[0];
          let source_id = rec_.course_class_source_id.split('_')[2];

          let tmp_recent = {
            Recent: {
              id: rec_.course_class_source_id,
              duration: rec_.duration,
              curso_id: curso_id,
              clase_id: rec_.class_id,
              source_id: source_id,
              state: rec_.state,
              date: rec_.date,
              time: rec_.time,
              certification_id: rec_.certification_id
            }
          }

          data[i_rec] = JSON.parse(JSON.stringify(tmp_recent));

        });

        return JSON.parse(JSON.stringify(data));
      }),
        catchError(this.handleError<any>('getSource', []))
      );

  }

  getSource(source): Observable<any> {

    if (source.type != 5) {
      return this.http.get(UrlLambdaApi + `/sources/${source.id}`, this.getHeadersLambda())
        .pipe(map((response: any) => {
          const { data, status } = response;
          let data_return;
          if (status) {
            data_return = JSON.parse(JSON.stringify(data[0]));
          }

          return data_return;
        }),
          // tap(_ => this.log('fetched learn')),
          catchError(this.handleError<any>('getSource', []))
        );
    } else {
      return of(source);
    }

  }


  getSourcesByLesson(): Observable<any> {

    const { curso_id, clase_id } = this.params.config;

    return this.http.get(UrlLambdaApi + `/cursos/${curso_id}/clases/${clase_id}/modules`, this.getHeadersLambda())
      .pipe(map((response: any) => {
        return response;
      }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getSource', []))
      );
  }


  // getSourceLesson(): Observable<any> {
  getSourceLesson = () => new Observable((observer) => {


    this.getSourcesByLesson().subscribe((response: any) => {


      const setLessonData = {
        setData: () => {



          if (lesson != null) {
            lesson.Source.forEach((source, index_source) => {

              source.i_source = index_source;

              // lesson.duration = lesson.duration + parseInt(source.type == 1 ? source.file_duration : source.time_add);
              source.duration = parseInt(source.time_add);
              lesson.duration = lesson.duration + parseInt(source.time_add);

              //LOS RECENTS SE PEDIRÁN POR APARTE! 

              if (source.hasOwnProperty('Recent') && source.Recent != null) {

                if (source.type == 5) {

                  let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;
                  source['sourceCompleted'] = source_hasAttemps;


                } else {

                  source['sourceCompleted'] = (parseInt(source.Recent.time) == parseInt(source.time_add)) ? true : false;

                }

                let tmp_source = JSON.parse(JSON.stringify(source));
                tmp_source.time = parseInt(source.Recent.time);
                source = JSON.parse(JSON.stringify(tmp_source));

              } else {
                source.time = 0;
              }


            })
          }

          if (!status) {
            lesson = 'error';
          }

          observer.next(lesson);

        }
      }



      const { data, status } = response;
      let lesson = null;
      if (status) {

        const Clase = data[0].Clase[0];
        const { Module } = data[0];
        const { Quiz, Source } = Clase;
        if (Clase.Quiz) delete Clase.Quiz;
        if (Clase.Source) delete Clase.Source;

        let tmp_lesson = {
          Clase,
          Module,
          Quiz,
          Source
        }

        lesson = JSON.parse(JSON.stringify(tmp_lesson));

        this.data.initialSource = JSON.parse(JSON.stringify(lesson));

        lesson.Source = new SortSourcesByOrder().transform(lesson.Source);

        //Añadir los quices como parte de los sources de la clase
        if (lesson.Quiz && lesson.Quiz.length > 0 && lesson.Quiz[0].id != null) {

          let quiz_id_array = lesson.Quiz.map(quiz => quiz.id);

          let url_request = UrlLambdaApi + `/attempts?quiz_id=${JSON.stringify(quiz_id_array)}`;

          this.getAttempts(url_request).subscribe(resp => {

            const { data, status } = resp;

            lesson.Quiz.forEach(quiz_ => {

              if (status) {

                let attempt_index = data.findIndex(attempt =>
                  attempt.Attempt.quiz_id == quiz_.id
                );


                if ((attempt_index != null && attempt_index > -1)) {
                  if (!quiz_.hasOwnProperty('Attempt')) quiz_['Attempt'] = [];
                  quiz_['Attempt'].push(JSON.parse(JSON.stringify(data[attempt_index].Attempt)));
                }

              }


              let time_add = 0;
              // let time_add = parseInt(quiz_.count_questions) * 600;

              let tmp_quiz = JSON.parse(JSON.stringify({ ...quiz_ }));

              tmp_quiz.isForum = tmp_quiz.type == 1 ? true : false;
              tmp_quiz.type = "5";
              tmp_quiz.time_add = time_add;
              tmp_quiz.file_duration = new TimeTransform().transform(time_add);
              tmp_quiz.description = quiz_.name;

              let time_ = 0;
              if (quiz_.Attempt != null && quiz_.Attempt.length > 0) {
                //Si tiene attempt significa que ya realizó el quiz independientemente si lo ganó o lo perdió
                time_ = time_add;
              } else {
                time_ = 0;
              }

              tmp_quiz['Recent'] = { time: time_ };
              lesson.Source.push(JSON.parse(JSON.stringify(tmp_quiz)));

              quiz_id_array.push(tmp_quiz.id);

            });

            setLessonData.setData();

          });


        } else {
          setLessonData.setData();
        }


      }


      // return lesson;

    });
  });

  getInfoCourse(): Observable<any> {

    return this.http.get(UrlLambdaApi + `/cursos/${this.params.config.curso_id}`, this.getHeadersLambda())
      .pipe(map((course: any) => {
        const { data, status } = course;
        let data_return;
        if (status) {
          data_return = JSON.parse(JSON.stringify(data[0].Curso));
          this.data.course = data[0].Curso;

          if (this.data.actions && this.data.certification == null && (this._appService.user_data.id != this.params.config.idu + '')) {
            // /category/68?course=3844

            this._router.navigateByUrl(`/category/${data[0].Categories[0].category_id}?course=${data[0].Curso.id}`);

          }
        }

        return data_return;
      }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getInfoCourse', []))
      );
  }


  getSourcesByModules() {

    return this.http.get(UrlLambdaApi + `/sources?curso_id=${this.params.config.curso_id}`, this.getHeadersLambda())
      .pipe(map((sources: any) => {

        return sources;
      }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getInfoCourse', []))
      );

  }

  getQuizzesByModules() {

    return this.http.get(UrlLambdaApi + `/quizzes?curso_id=${this.params.config.curso_id}`, this.getHeadersLambda())
      .pipe(map((sources: any) => {

        return sources;
      }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getInfoCourse', []))
      );

  }





  getModules(): Observable<any> {

    let url_params = '';
    if (this._appService.cognitoUserAuthenticated) {
      // if (this._appService.cognitoUserAuthenticated.hasOwnProperty('signInUserSession')) {
      //   // User with session           
      //   url_params = `/cursos/${this.params.config.curso_id}/modules/attempts`;
      // } else {
      // User without session              
      url_params = `/cursos/${this.params.config.curso_id}/modules`;
      // }
    }

    return this.http.get(UrlLambdaApi + url_params, this.getHeadersLambda())
      .pipe(map((course: any) => {
        const { data, status } = course;
        let data_return;
        if (status) {

          let modules = JSON.parse(JSON.stringify(data));

          if (modules != null) {

            modules.sort((a, b) => {
              return (parseInt(a.Module.order) < parseInt(b.Module.order)) ? -1 : ((parseInt(a.Module.order) > parseInt(b.Module.order)) ? 1 : 0);
            });
            modules.forEach(module_ => {
              module_.Clase.sort((a, b) => {
                return (parseInt(a.order) < parseInt(b.order)) ? -1 : ((parseInt(a.order) > parseInt(b.order)) ? 1 : 0);
              });

            });

            this.data.modules = JSON.parse(JSON.stringify(modules));


            this.getSourcesByModules().subscribe(response_sources => {

              let course_sources = response_sources.data;

              var outObjectSources = course_sources.reduce(function (a, e) {
                // GROUP BY estimated key (estKey), well, may be a just plain key
                // a -- Accumulator result object
                // e -- sequentally checked Element, the Element that is tested just at this itaration
                // new grouping name may be calculated, but must be based on real value of real field
                let estKey = (e.Source['clase_id']);
                (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
                return a;
              }, {});


              this.getQuizzesByModules().subscribe(response_quizzes => {
                let course_quizzes = response_quizzes.data;


                var outObjectQuizzes = course_quizzes.reduce(function (a, e) {
                  // GROUP BY estimated key (estKey), well, may be a just plain key
                  // a -- Accumulator result object
                  // e -- sequentally checked Element, the Element that is tested just at this itaration
                  // new grouping name may be calculated, but must be based on real value of real field
                  let estKey = (e.Quiz['clase_id']);
                  (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
                  return a;
                }, {});

                modules.forEach((module, i_module) => {

                  module.Clase.forEach((lesson, i_lesson) => {

                    lesson['Source'] = [];
                    lesson['Quiz'] = [];

                    let tmp_obj_sources = Object.keys(outObjectSources).
                      filter((key) => key.includes(lesson.id)).
                      reduce((cur, key) => {
                        return outObjectSources[key].map(src_ => {
                          src_.Source['Subtitle'] = src_.Subtitle;
                          return src_.Source
                        })
                      }, {});

                    let sources__ = (Object.keys(tmp_obj_sources).length === 0) ? [] : JSON.parse(JSON.stringify(tmp_obj_sources))

                    lesson.Source = JSON.parse(JSON.stringify(sources__));
                    // lesson = { ...lesson, ...sources__ };

                    let tmp_obj_quizzes = Object.keys(outObjectQuizzes).
                      filter((key) => key.includes(lesson.id)).
                      reduce((cur, key) => {
                        return outObjectQuizzes[key].map(quiz_ => {
                          return quiz_.Quiz
                        })
                      }, {});

                    lesson['Quiz'] = JSON.parse(JSON.stringify(tmp_obj_quizzes));


                    if (((modules.length - 1) == i_module) && ((module.Clase.length - 1) == i_lesson)) {

                      if (this.data.actions) {
                        //Si el usuario está autenticado, pedir recents de los sources y attempts de los quices

                        this.getRecent('course').subscribe((recents: any) => {

                          const { certification_id } = this.params.config;
                          let url_request = UrlLambdaApi + `/attempts?certification_id=${certification_id}`;

                          this.getAttempts(url_request).subscribe(response => {

                            let attempts = response.data;

                            modules.forEach((module_, module_index) => {
                              module_.Clase.forEach((lesson, lesson_index) => {


                                if (lesson.hasOwnProperty('Quiz') && lesson.Quiz.length > 0) {


                                  attempts.forEach(attempt => {

                                    let quiz_index = lesson.Quiz.findIndex(quiz =>
                                      quiz.id == attempt.Attempt.quiz_id
                                    );


                                    if ((quiz_index != null && quiz_index > -1)) {
                                      if (!lesson.Quiz[quiz_index].hasOwnProperty('Attempt')) lesson.Quiz[quiz_index]['Attempt'] = [];
                                      lesson.Quiz[quiz_index]['Attempt'].push(JSON.parse(JSON.stringify(attempt.Attempt)));
                                    }


                                  });


                                }


                                if (lesson && (lesson.id != this.data.lesson.id)) {
                                  //Si no es la clase actual, asignarle los recent a los sources que se acaban de obtener                   


                                  recents.forEach(rec => {

                                    let src_index = lesson.Source.findIndex(src =>
                                      src.id == rec.Recent.source_id
                                    );

                                    if (this.data.source.id == rec.Recent.source_id) {
                                      this.data.source.Recent = rec.Recent;
                                    }

                                    if ((src_index != null && src_index > -1) && !lesson.Source[src_index].hasOwnProperty('Recent')) {
                                      lesson.Source[src_index]['Recent'] = rec.Recent;
                                    }

                                  });


                                } else {
                                  //Aqui entraría al ser la misma clase inicial
                                  //En teoria esto deberia asignar lso recents de los sources de la clase actual que se obtuvieron de la primera petición de getRecents

                                  if (this.data.lesson.Source != null) {
                                    lesson.Source = JSON.parse(JSON.stringify(this.data.lesson.Source));
                                  }

                                }



                              });

                              setTimeout(() => {

                                // && (lesson_index == (module_.Clase.length - 1))
                                if ((module_index == (modules.length - 1))) {
                                  this.moduleProgress(modules, 'getModules');
                                  let modulesSubscription: Subscription = this.moduleObservable.subscribe({
                                    next: (value) => {

                                      if (modulesSubscription) {
                                        modulesSubscription.unsubscribe();
                                      }

                                      return this.data.modules

                                    }

                                  });

                                }
                              }, 250)

                            });





                          });




                        });
                      } else {

                        //Cargar modulos directamente
                        this.moduleProgress(modules, 'getModules');
                        let modulesSubscription: Subscription = this.moduleObservable.subscribe({
                          next: (value) => {

                            if (modulesSubscription) {
                              modulesSubscription.unsubscribe();
                            }


                            return this.data.modules

                          }

                        });
                      }




                    }

                  });

                });



              });










            });












          }



        } else {
          this.moduleProgress([], 'getModules');
          let modulesSubscription: Subscription = this.moduleObservable.subscribe({
            next: (value) => {

              if (modulesSubscription) {
                modulesSubscription.unsubscribe();
              }

              return this.data.modules

            }

          });
        }

        setTimeout(() => {
          // Init gets nav bar
          this._appService.startGetsNavBar();
        }, 5000);

      }),
        retry(3),
        catchError(error => {
          this.handleError('getModules');

          setTimeout(() => {
            // Init gets nav bar
            this._appService.startGetsNavBar();
          }, 5000);

          return error;
        })
      );

  }


  moduleObservable: Observable<any>;
  moduleProgress(modules, from?) {

    this.moduleObservable = Observable.create((observer) => {


      modules.forEach((module_, index_module) => {

        module_.Module.unidad = index_module + 1;

        module_.Clase.forEach((lesson, index_lesson) => {

          lesson.i_module = index_module;
          lesson.i_lesson = index_lesson;
          lesson.module_name = module_.Module.name;

          lesson.progress = 0;


          //Ordenar sources por tipo

          lesson.Source = new SortSourcesByOrder().transform(lesson.Source);

          //Añadir los quices como parte de los sources de la clase
          if (from == 'getModules' && (lesson.Quiz && lesson.Quiz.length > 0 && lesson.Quiz[0].id != null)) {


            lesson.Quiz.forEach(quiz_ => {

              let time_add = 0;
              // let time_add = parseInt(quiz_.count_questions) * 600;

              let tmp_quiz = JSON.parse(JSON.stringify({ ...quiz_ }));

              tmp_quiz.isForum = tmp_quiz.type == 1 ? true : false;
              tmp_quiz.type = "5";
              tmp_quiz.time_add = time_add;
              tmp_quiz.file_duration = new TimeTransform().transform(time_add);
              tmp_quiz.description = quiz_.name;

              let time_ = 0;

              if (quiz_.Attempt != null && quiz_.Attempt.length > 0) {
                //Si tiene attempt significa que ya realizó el quiz independientemente si lo ganó o lo perdió        
                time_ = time_add;
              } else {
                time_ = 0;
              }

              tmp_quiz['Recent'] = { time: time_ };

              let quiz_index_ = lesson.Source.findIndex(source => source.id == tmp_quiz.id);
              if (quiz_index_ == -1) {
                lesson.Source.push(JSON.parse(JSON.stringify(tmp_quiz)));
              }

            });


          }



          lesson.duration = 0;
          lesson.Source.forEach((source, index_source) => {


            if ((index_module == 0) && (index_lesson == 0) && (index_source == 0)) {
              //('PRIMER source: ', source);
              source.isTheFirstSource = true;
            } else {
              source.isTheFirstSource = false;
            }

            if ((index_module == (modules.length - 1)) && (index_lesson == (module_.Clase.length - 1)) && (index_source == (lesson.Source.length - 1))) {
              //('ULTIMO source: ', source);
              source.isTheLastSource = true;
            } else {
              source.isTheLastSource = false;
            }



            source.i_source = index_source;
            source.i_module = index_module;
            source.i_lesson = index_lesson;

            source.duration = parseInt(source.time_add);
            lesson.duration = lesson.duration + parseInt(source.time_add);

            if (source.Recent != null) {

              if (source.type == 5) {

                let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;
                source['sourceCompleted'] = source_hasAttemps;

              } else {

                source['sourceCompleted'] = source.Recent.state == '1' ? true : false;

              }


              let tmp_source = JSON.parse(JSON.stringify(source));
              tmp_source.time = parseInt(source.Recent.time);
              source = JSON.parse(JSON.stringify(tmp_source));

            } else {
              source.time = 0;
            }

            if (source.id == this.data.source.id) {

              this.currentIndex = {
                lesson: index_lesson,
                module: index_module,
                source: index_source
              }

              this.data.source.module_id = lesson.module_id;
              this.data.source.i_source = index_source;

            }

          });

          if ((lesson.duration / 3600) < 1) {
            lesson.durationInText = Math.round(lesson.duration / 60) + 'm';
          } else {
            lesson.durationInText = Math.round(lesson.duration / 3600) + 'h'
          };

          if (lesson.progress == null) lesson.progress_percentaje = 0;


          if ((this.data.lesson != null && this.data.lesson.id != null) && lesson.id == this.data.lesson.id) {
            //AQUI ASIGNO LA DATA CALCULADA A LA CLASE ACTUAL        
            this.data.lesson = JSON.parse(JSON.stringify(lesson));

            let src_index = this.data.lesson.Source.findIndex(src =>
              src.id == this.data.source.id
            );

            if (src_index != null && src_index > -1) {


              if (this.data.source.type == 5) {


                if (this.data.source.hasOwnProperty('Question') && this.data.source.hasOwnProperty('Quiz')) {

                  lesson.Source[src_index]['Question'] = JSON.parse(JSON.stringify(this.data.source['Question']));
                  lesson.Source[src_index]['Quiz'] = JSON.parse(JSON.stringify(this.data.source['Quiz']));

                  this.data.source['Question'] = JSON.parse(JSON.stringify(this.data.source['Question']));
                  this.data.source['Quiz'] = JSON.parse(JSON.stringify(this.data.source['Quiz']));

                }
              }

              let tmp_source = JSON.parse(JSON.stringify(this.data.source));

              if (tmp_source.hasOwnProperty('Subtitle')) {
                lesson.Source[src_index]['Subtitle'] = tmp_source.Subtitle;
              }


              this.data.source['isTheFirstSource'] = lesson.Source[src_index].isTheFirstSource;
              this.data.source['isTheLastSource'] = lesson.Source[src_index].isTheLastSource;
              this.data.source['i_module'] = lesson.Source[src_index].i_module;
              this.data.source['i_lesson'] = lesson.Source[src_index].i_lesson;
              this.data.source['i_source'] = lesson.Source[src_index].i_source;

              // this.data.source = JSON.parse(JSON.stringify(lesson.Source[src_index]));    

              setTimeout(() => {

                if (this.progressToUpdate) {
                  //recents que deben ser actualizados para calcular el progreso en el curso
                  // Y se debe almacenar en el certificado
                  this.updateCourseProgress(true);
                } else {
                  this.updateCourseProgress(false);
                }


              }, 100);

            }

          }




        });


        setTimeout(() => {
          if ((index_module == (modules.length - 1))) {
            this.modules_loaded = true;
          }
        }, 250);

      })

      this.data.modules = JSON.parse(JSON.stringify(modules));
      observer.next(modules);

    });

  }


  getUploads(): Observable<any> {
    if (this.data.actions) {

      const { curso_id } = this.params.config;

      return this.http.get(UrlLambdaApi + `/uploads?course_class_source_id_date=${curso_id}`, this.getHeadersLambda())
        .pipe(map((response: any) => {
          const { data, metadata } = response;
          return data;
        }),
          // tap(_ => this.log('fetched comments')),
          catchError(this.handleError<any>('getParticipans', []))
        );
    } else {
      return of([])
    }

  }

  updateConfig(config): Observable<any> {

    const { certification_id } = this.params.config;

    let config_: any = {
      data: {
        // certification_id: config.certification_id,
        clase_id: config.clase_id,
        source_id: config.source_id,
        quiz_id: config.quiz_id,
      }
    };

    return this.http.put(`${UrlLambdaApi}/certifications/${certification_id}`, config_, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('updateConfig'))
      );

  }

  updateCourseCertificationProgress(progress): Observable<any> {

    const { certification_id } = this.params.config;

    let config_: any = {
      data: {
        progress: progress
      }
    };

    return this.http.put(`${UrlLambdaApi}/certifications/${certification_id}`, config_, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('updateConfig'))
      );

  }


  progressToUpdate: boolean = false;

  updateCourseProgress(sendToCertification: boolean) {

    this.progressToUpdate = false;

    if (this.data.modules != null) {

      //Si hay modulos, cargar el progreso del usuario en el curso
      let progress_calculated = new CourseProgress().transform(this.data.modules, this.data.lesson);
      let progress_rounded = Math.round(progress_calculated.course_progress_percentaje);

      progress_rounded = progress_rounded > 100 ? 100 : progress_rounded;

      if (sendToCertification === true) {
        // update certification progress ==>
        const { Certification } = this.data.certification;
        progress_rounded = (progress_rounded < Certification.progress) ? Certification.progress : progress_rounded;

        this.updateCourseCertificationProgress(progress_rounded).subscribe(resp => {
          this.certification_progress = progress_rounded;
          if (resp.status === true) {

            let index_learning_course = this._appService.learning.findIndex(course => course.id == this.data.course.id);

            if (index_learning_course != null && index_learning_course > -1) {

              let tmp_learninig = JSON.parse(JSON.stringify(this._appService.learning));

              tmp_learninig[index_learning_course].avance = this.certification_progress;

              this._appService.learning = JSON.parse(JSON.stringify(tmp_learninig));


            }

          }

        });
      } else {
        this.certification_progress = progress_rounded;
      }

    } else {

      this.progressToUpdate = true;

    }


  };

  recentAdd(data, source?) {

    return this.http.post(`${UrlLambdaApi}/recents`, { data }, this.getHeadersLambda())
      .pipe(map((response: any) => {
        let recent = null;

        if (response.status) {
          recent = JSON.parse(JSON.stringify(data));
          recent.id = `${data.course_id}_${data.class_id}_${data.source_id}`
          recent.state = 0;
          recent.time = 0;
        }

        return JSON.parse(JSON.stringify(recent));

      }),
        catchError(this.handleError('recents_add'))
      );
  }



  recentUpdate(source): Observable<any> {

    //Recent Update se encarga de actualizar el recent al realizar un apunte de los sources o subir la solución de una actividad
    let can_save_recent = null;
    let isFinished = source ? source.time == source.duration : false;

    if (this.data.actions) { //Si está logueado tiene permitido realizar actions            

      if (source != null && (source.hasOwnProperty('Recent') && source.Recent != null && source.Recent.id != 0)) {
        //Si existe el recent en el source
        // ('tiene recent')                                
        if ((source.Recent.time < source.time)) {
          // ('recentsSource tiene time y es menor al tiempo: ')
          // si el tiempo guardado es menor al tiempo que se va enviar => se puede guardar recent
          can_save_recent = true;
        } else {
          //('no puede guardar por el tiempo')
          //Si el tiempo que se va a enviar es menor al tiempo guardado, no se guardará
          can_save_recent = false;
          //('verificando el time del soruce');
        }
      } else {
        //('Source empty o No existe el recent')
        //Si no existe el recent, se creará uno nuevo -> no debe guardar porque esta acción la hará recent_add
        can_save_recent = false;
      }

    }//END IF ACTIONS

    //('can_save_recent:', can_save_recent);

    if (can_save_recent == true && source != null) { //Si el tiempo almacenado es menor al segundo que se va a guardar

      source.Recent.time = Math.round(source.time) + '';
      source.Recent.finished = isFinished;
      let recent_data = JSON.parse(JSON.stringify(source.Recent));

      if (isFinished === true) recent_data.state = 1;
      // recent_data.progress = 20;

      return this.http.put(`${UrlLambdaApi}/recents/${recent_data.id}`, { data: recent_data }, this.getHeadersLambda())
        .pipe(map((response: any) => {

          let tmp_recent = JSON.parse(JSON.stringify(recent_data));
          tmp_recent.state = recent_data.finished ? 1 : 0;

          if (response.status == true) {

            if (source != null) {
              //Aquí solo se va a actualizar el recent del source si y solo si este source existe (diferente de null);
              // Actualizar recent a la Source, dentro de modules.
              //Al source actual
              //Recalcular tiempo, progreso
              let sourceCompleted = tmp_recent.state == 1 ? true : false;

              source.Recent = JSON.parse(JSON.stringify(tmp_recent));
              source['sourceCompleted'] = sourceCompleted;

              if (source.id == this.data.source.id) {
                // this.data.source = JSON.parse(JSON.stringify(source));//Esto hacia recargar el source
                this.data.source.Recent = JSON.parse(JSON.stringify(tmp_recent));
                this.data.source['sourceCompleted'] = sourceCompleted;

              }

              if (source.clase_id == this.data.source.clase_id) {
                let tmp_lesson = JSON.parse(JSON.stringify(this.data.lesson));
                tmp_lesson.Source[source.i_source]['Recent'] = JSON.parse(JSON.stringify(tmp_recent));
                tmp_lesson.Source[source.i_source]['sourceCompleted'] = sourceCompleted;

                this.data.lesson = JSON.parse(JSON.stringify(tmp_lesson));
              }

              if (this.data.modules != null) {

                let module_lesson = JSON.parse(JSON.stringify(this.data.modules[source.i_module].Clase[source.i_lesson]))

                module_lesson.Source[source.i_source].Recent = JSON.parse(JSON.stringify(tmp_recent));

                module_lesson.Source[source.i_source].sourceCompleted = sourceCompleted;

                this.data.modules[source.i_module].Clase[source.i_lesson] = JSON.parse(JSON.stringify(module_lesson));

              }

              if (sourceCompleted) {
                //Calcular progreso del certificado y almacenar progreso en el certificado
                this.updateCourseProgress(true);

              }

            }



          } else {
            // enviar notificación de que ocurrió un error
          }

          return tmp_recent
        }),
          catchError(this.handleError('recent_update'))
        );

    } else {

      return of(false);

    }


  }



  sourceRecentStateUpdate(recent_data, source): Observable<any> {
    //Metodo para actualizar el estado del recent de un source de lectura que se acaba de abrir.

    let config = JSON.parse(sessionStorage.getItem('config'));
    recent_data.curso_id = config.curso_id;

    const { Recent } = source;


    return this.http.put(`${UrlLambdaApi}/recents/evaluate_state/${Recent.id}`, { data: recent_data }, this.getHeadersLambda())
      .pipe(map((response: any) => {

        let tmp_recent = JSON.parse(JSON.stringify(source.Recent));
        tmp_recent.time = recent_data.time;
        tmp_recent.duration = recent_data.duration;
        tmp_recent.state = (recent_data.time == recent_data.duration) ? 1 : 0;


        if (source != null) {
          //Aquí solo se va a actualizar el recent del source si y solo si este source existe (diferente de null);
          // Actualizar recent a la Source, dentro de modules.
          //Al source actual
          //Recalcular tiempo, progreso
          let sourceCompleted = tmp_recent.state == 1 ? true : false;

          source.Recent = JSON.parse(JSON.stringify(tmp_recent));
          source['sourceCompleted'] = sourceCompleted;


          if (source.id == this.data.source.id) {
            this.data.source = JSON.parse(JSON.stringify(source));
          }

          if (source.clase_id == this.data.source.clase_id) {

            let tmp_lesson = JSON.parse(JSON.stringify(this.data.lesson));
            tmp_lesson.Source[source.i_source]['Recent'] = JSON.parse(JSON.stringify(tmp_recent));
            tmp_lesson.Source[source.i_source]['sourceCompleted'] = sourceCompleted;

            this.data.lesson = JSON.parse(JSON.stringify(tmp_lesson));
          }


          if (this.data.modules && this.data.modules.length > 0) {

            if (this.data.modules[source.i_module] != null && this.data.modules[source.i_module].hasOwnProperty('Clase')) {

              let module_lesson = JSON.parse(JSON.stringify(this.data.modules[source.i_module].Clase[source.i_lesson]))

              if (module_lesson) {

                module_lesson.Source[source.i_source].Recent = JSON.parse(JSON.stringify(tmp_recent));
                module_lesson.Source[source.i_source].sourceCompleted = sourceCompleted;
                this.data.modules[source.i_module].Clase[source.i_lesson] = JSON.parse(JSON.stringify(module_lesson));

              }
            }
          }


          //Calcular progreso del estudiante en el curso y actualizar el certificado
          if (sourceCompleted) {
            this.updateCourseProgress(true);
          }
        }



        return JSON.parse(JSON.stringify(tmp_recent));

      }),

        catchError(this.handleError('sourceRecentStateUpdate'))
      );
  }






  getQuiz(quiz, quiz_id): Observable<any> {

    let url = '';
    if (this.data.actions) {
      url = `/quizzes/${quiz_id}/records`;
    } else {
      url = `/quizzes/${quiz_id}`;
    }

    return this.http.get(`${UrlLambdaApi}${url}`, this.getHeadersLambda())
      .pipe(
        map((quiz: any) => {

          const { data, status } = quiz;

          let data_return;
          if (status) {

            data[0].Questions.forEach(question => {
              if (!question.hasOwnProperty('Records')) {
                question['Records'] = [];
              }
            });

            data_return = JSON.parse(JSON.stringify(data));
          }

          return data_return;
        }), retry(3),
        catchError(this.handleError<any>('getQuiz', []))
      );
  }



  pageTour(page?): Observable<any> {
    let page_: any = {
      "data": {
        "tour": page
      }
    };

    // return of(this.params.config)

    const { certification_id } = this.params.config;

    return this.http.put(`${UrlLambdaApi}/certifications/${certification_id}`, page_, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('pageTour'))
      );

  }


  userPageTour(page?): Observable<any> {
    //Actualizar el tour de la tabla usuario para mostroar o no las notificaciones flotantes

    let page_: any = {
      "data": {
        "tour": page
      }
    };

    // return of(this.params.config)

    const { idu } = this.params.config;
    return this.http.put(`${UrlLambdaApi}/users/${idu}`, page_, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('userPageTour'))
      );

  }



  // CHECK IF A JSON IS NOT EMPTY
  public ObjectIsEmpty(obj): Observable<any> {

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return of(false);
      //No es empty
    }

    return of(true);//empty
  }


  getScholarship(): Observable<any> {

    const { curso_id } = this.params.config;

    let course_param = curso_id != null ? `?curso_id=${curso_id}` : '';

    return this.http.get(`${UrlLambdaApi}/countries/${this._appService.user_data.country_id}/value${course_param}`, this.getHeadersLambda())
      .pipe(map((scholarship: any) => {

        const { data, status } = scholarship;

        let data_return;
        if (status) {
          data_return = JSON.parse(JSON.stringify(data[0]));

          // let flag_url = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${scholarship.Country.short_name.toLocaleLowerCase()}.svg`;

        }

        return data_return
      }),
        catchError(this.handleError<any>('getScholarship', []))
      );
  }


  public resizeCommentsFloatingMsg() {

    let lesson_content;
    if (document.querySelector('.lesson-content') != null) lesson_content = document.querySelector('.lesson-content').getBoundingClientRect();
    let comment_notification;
    if (document.querySelector('#comment_notification') != null) comment_notification = document.querySelector('#comment_notification').getBoundingClientRect();
    let summary__container: HTMLElement = document.querySelector('.summary__container');

    if (summary__container != null && comment_notification != null && lesson_content != null) {
      if (comment_notification.bottom < lesson_content.height) {
        //Mostrar la notificación tal cual es en su posición
        summary__container.style.position = 'static';
        summary__container.style.width = '100%';
      } else {
        //Mostrar la notificación fixed en la pantalla
        summary__container.style.position = 'fixed';
        summary__container.style.zIndex = '2';
        summary__container.style.bottom = this.data.width_size < 600 ? '60px' : '20px';
        summary__container.style.width = comment_notification.width + 'px';

      }

      let notifitacion_container: HTMLElement = document.querySelector('#comment_notification');
      notifitacion_container.style.minHeight = summary__container.offsetHeight + 'px';


    }

  }



  public course_rating: any = null;
  getCourseRating(): Observable<any> {

    const { curso_id, idu } = this.params.config;

    return this.http.get(UrlLambdaApi + `/ratings?course_id=${curso_id}&user_id=${idu}`, this.getHeadersLambda())
      .pipe(map((response: any) => {
        const { data, status } = response;
        let data_return;
        if (status) {
          data_return = JSON.parse(JSON.stringify(data[0]));
        }

        return data_return;
      }),
        // tap(_ => this.log('fetched learn')),
        catchError(this.handleError<any>('getSource', []))
      );

  }

  addRating(data) {

    return this.http.post(`${UrlLambdaApi}/ratings`, { data }, this.getHeadersLambda())
      .pipe(map((response: any) => {
        let recent = null;
        return JSON.parse(JSON.stringify(recent));
      }),
        catchError(this.handleError('recents_add'))
      );
  }

  // Función para generar las estadisticas del usuario by: Jesus
  saveStatistics(curso_id, certification_id, score = 'enter_course') {

    /*
      score = el performance que se le va a agregar al usuario
      score = {
        'enter_course': 1,
        'com_message': 5,
        'com_comment': 7,
      }
     */

    // guardar usuarios sugeridos para seguir


    this.postItem('/certifications_statistics', { curso_id, certification_id }).subscribe(repsonse => {
      if (!repsonse.status) {
        this.putItem(`/certifications_statistics/${curso_id}/${certification_id}`, { score }).subscribe()

      }
    })

  }


  postItem(url_post, data): Observable<any> {
    return this.http.post(`${UrlLambdaApi}${url_post}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('postItem');
        return of({ status: false });
      })
    );
  }

  putItem(url_put, data): Observable<any> {
    return this.http.put(`${UrlLambdaApi}${url_put}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('putItem');
        return of({ status: false });
      })
    );
  }




  private openSourceSubject = new Subject<any>();
  openSource$ = this.openSourceSubject.asObservable();


  openSource(lesson, source, selected?) {
    this.openSourceSubject.next({ lesson, source, selected })
  }









  private handleErrorSubject = new Subject<any>();
  handleErrorObservable$ = this.handleErrorSubject.asObservable();
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log('=========ERROR==========');
      console.log(error); // log to console instead
      //(error.error); // log to console instead      
      console.log('=========ERROR==========');
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      this.handleErrorSubject.next('error');
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  private log(message: string) {
    //(`ClassroomService: ${message}`);
  }


  clearAllData() {
    this.data = {
      actions: false, // si el usuario está logeado ? true : false;
      certification: null, //data del certificado y el usuario
      course: null,
      initialSource: null,
      initialLesson: null,
      lesson: null,
      modules: null,
      position: { module: null, lesson: null, isLastOne: null },
      source: null,
      uploads: null,
      url_route: null,
      file: null,
      width_size: 0,
      height_size: 0,
      img_viewer_src: null
    }

    this.switcher = {
      youtube: false,
      local: false,
      viewer: false,
      file: false, //Es el file que viene de la comunidad
      quiz: false,
      modal: { state: false, option: null },
      notes: false,
      chatroom: false,
      img_viewer: false,
      tutorial: false
    }


    this.viewer = {
      show: false,
      file: null
    }

    this.currentIndex = {
      module: null,
      lesson: null,
      source: null,
    }

    this.quiz_summary = {
      display: false,
      cancel: false,
      cta: false,
      data: null,
      moreInfo: false
    }

    this.sidebar = {
      state: 'open',
      component: null,
      components: {
        course_content: true,
        comments: false,
        community: false,
        notes: false,
        contributions: false,
        evaluations: false
      },
      previous_state: null,
      participate: false
    }

    this.isSavingRecentModal = false;

    this.loadWelcomeMsg = false;


    this._communityService.clearData();

    this.course_rating = null;

    this.certification_progress = 0;


    this.display_a_d_s = {
      display: false,
      source: null,
      hide: true
    };

    this.modules_loaded = false;

  }

  valConversations(data: any): any {
    if (data == null || data == undefined) {
      return { is: false, msg: 'Respond with null or undefined' };
    } else if (data.__typename == 'UserConverstationsConnection') {
      return { is: true, msg: 'Ok Value' };
    } else {
      return { is: false, msg: 'Response contains invalid data from Graph' };
    }
  }
}
