import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  @Input() showMenu: boolean = false;
  showShoppingCar: boolean = false;

  constructor(public _appService: AppService) { }

  ngOnInit(): void {
  }

  toggleShoppingCar(): void {
    this.showShoppingCar = !this.showShoppingCar;
  }

  getShoppingCart() {
    this._appService.getShoppingCart().subscribe(data => {
      if (data.valid) {
        this._appService.resultsShoppingCar = data.print;
      } else {
        this._appService.resultsShoppingCar = [];
      }
    });
  }

  goToLink(url: string): void {
    window.location.href = url;
  }

  removeProductSP(event: any, item: any, element: any) {
    if (!element.classList.contains('loader-circle')) {
      element.classList.add('loader-circle');
      // Borrar items del carrito de compra
      this._appService.deleteItemShoppingCart(item.id).subscribe(data => {
        element.classList.remove('loader-circle');
        if (data.valid) {
          this.getShoppingCart();
        }
      });
    }
    event.stopPropagation();
  }

}
