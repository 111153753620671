<div class="chat-room" (click)="close_all_menu()">
  <modal-dialog *ngIf="modal_dialog_.state"></modal-dialog>

  <div *ngIf="show_add_user" class="conversation-modal">
    <div class="conversation-modal-container">
      <div class="conversation-modal__header">
        Añadir participante

        <form autocomplete="off">
          <input type="text" placeholder="Buscar" aria-label="Search" #searchInput name="search"
            [(ngModel)]="searchtext_" (ngModelChange)="search_user(searchtext_)">
          <span class="icon-search-bold" *ngIf="searchtext_.length > 0"></span>
          <span class="icon-cross" *ngIf="searchtext_.length > 0" (click)="cleanSearch()"></span>
        </form>

      </div>

      <ul class="chat-inbox__msgs">
        <li class="received_msg" *ngFor="let conv of filtered_conversations" (click)="addUser(conv)">
          <div [class]="'circle-status-'+conv.associated[0].user.status"></div>
          <img #conversationAvatar class="receiver-avatar" (error)="conversationAvatar.src=getAvatarErrorImage(conv)"
            [src]="conv | urlImage:3">
          <div class="message__content">
            <div class="message__title">
              <div class="received__username">
                {{ conv.associated[0].user.user_name|cutUserName:'2' }}
              </div>
            </div>
          </div>
        </li>
      </ul>



      <a class="button btn btn__dark-blue" role="button" (click)="show_add_user = false">Cerrar</a>

    </div>
  </div>

  <div *ngIf="show_user_list" class="conversation-modal">
    <div class="conversation-modal-container">

      <div class="conversation-modal__header participants">

        <div>
          <div class="editor_text" contenteditable #TextGroupName
            [attr.placeholder]="_appService.conversations[_appService.current_conversation_index] | groupNamePipe"
            (input)="group_name=$event.target.innerHTML">
          </div>
          <span class="icon-cross" *ngIf="group_name.length > 0" (click)="clearGroupName()"></span>

          <button class="btn save"
            *ngIf="group_name.length > 0 && _appService.conversations[_appService.current_conversation_index].user_role == 'ADMINISTRATOR'"
            (click)="saveGroupName()">
            <div class="spinner spinner--button" *ngIf="savingGroupName; else saveLabel">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

            <ng-template #saveLabel>
              Guardar
            </ng-template>
          </button>
        </div>

        <label>Participantes</label>


      </div>


      <ul class="chat-inbox__msgs user--list">
        <li class="received_msg" *ngFor="let asso of filtered_user_list">
          <div [class]="'circle-status-'+asso.user.status"></div>
          <img #conversationAvatar class="receiver-avatar"
            (error)="conversationAvatar.src=getAvatarErrorImage({ associated : [asso]})"
            [src]="{ associated : [asso]} | urlImage:3">
          <div class="message__content">
            <div class="message__title">
              <div class="received__username">
                {{ asso.user.user_name|cutUserName:'2' }}
              </div>
            </div>
          </div>


          <div class="user-label" (click)="openParticipantsOptions($event, asso)"
            (clickOutsideDdown)="user_options = null">

            <span class="role-name">
              {{ asso.user_role == 'ADMINISTRATOR' ? 'Administrador' : 'Participante' }}
            </span>

            <span class="icon-arrow-right"
              *ngIf="_appService.conversations[_appService.current_conversation_index].state === 'ACCEPTED' && _appService.conversations[_appService.current_conversation_index].user_role === 'ADMINISTRATOR'"></span>


            <span class="menu-options"
              *ngIf="_appService.conversations[_appService.current_conversation_index].state === 'ACCEPTED' && _appService.conversations[_appService.current_conversation_index].user_role === 'ADMINISTRATOR'">

              <div class="option" [class.active]="user_options == asso.user.user_id">
                <ul>
                  <li *ngIf="asso.user_role === 'ADMINISTRATOR'" (click)="dismissAsAdmin($event, asso)"><span> Descartar
                      como administrador</span></li>
                  <li *ngIf="asso.user_role === 'USER'" (click)="setAsAdmin($event, asso)"><span> Colocar como
                      administrador</span></li>
                  <li (click)="deleteParticipantFromGroup($event, asso)"><span> Eliminar del grupo</span></li>
                </ul>
              </div>

            </span>


          </div>


        </li>
      </ul>

      <a style="width: 100%; min-height: 40px;" class="button btn btn__dark-blue" role="button"
        (click)="show_user_list = false">Cerrar</a>

    </div>
  </div>

  <div class="chat-inbox"
    *ngIf="((!_appService.conversations[_appService.current_conversation_index] && (width_screen < 560)) || (width_screen >= 560)) && !open_file_previsual">
    <header class="chat-inbox__head">

      <span class="icon-message open-search" *ngIf="!search_user_to_open_chat" (click)="search_user_to_open_chat = true"></span>

      <div *ngIf="!search_user_to_open_chat">
        <p class="chat-inbox__title">Conversaciones</p>
        <div class="chat-inbox__icon"> <span class="icon-message"></span></div>
        <span class="chat-room__close icon-cross" (click)="closeChatRoom()"></span>
      </div>

      <div class="form-container" *ngIf="search_user_to_open_chat">
        <span class="icon-arrow-left" (click)="search_user_to_open_chat=false"></span>
        <form autocomplete="off" (ngSubmit)="searchToOpenChat()">
          <input type="text" placeholder="Buscar" aria-label="Search" #searchInput name="input_search_user"
            [(ngModel)]="search_user_to_open_chat_text">
          <span class="icon-cross" *ngIf="search_user_to_open_chat_text.length > 0"
            (click)="cleanSearchToOpenChat()"></span>
        </form>
      </div>

    </header>
    <div class="chat-inbox__dialogs">
      <ul class="chat-inbox__msgs">
        <li class="received_msg" *ngFor="let conv of _appService.conversations; let i = index"
          (click)="openConversation(conv,0)" [class.active]="_appService.current_conversation_index == i"
          [ngClass]="{'unread': conv.unreaded_messages>0}">
          <div *ngIf="conv.unreaded_messages>0" class="label-red">{{conv.unreaded_messages}}</div>
          <div *ngIf="conv.associated.length === 1" [class]="'circle-status-'+conv.associated[0].user.status"></div>
          <!-- <div *ngIf="conv.associated.length > 1" class="group-icon">gr</div> -->


          <div class="chat_group_image inbox" *ngIf="conv.associated.length > 1">
            <img class="receiver-avatar" [src]="conv.associated[0] | urlImage:4">
            <img class="receiver-avatar" [src]="conv.associated[1] | urlImage:4">
          </div>


          <img *ngIf="conv.associated.length === 1" #conversationAvatar class="receiver-avatar"
            (error)="conversationAvatar.src=getAvatarErrorImage(conv)" [src]="conv | urlImage:3">
          <div class="message__content">
            <div class="message__title">
              <div class="received__username">
                {{ conv.associated.length === 1 ? (conv.associated[0].user.user_name|cutUserName:'2') : conv | groupNamePipe }}
              </div>
              <div class="received__date">
                {{conv.last_update | timeTransform:'split'}}
              </div>
            </div>
            <div *ngIf="conv.input_state != 'NONE'" class="message__dialog__bold_green">
              {{ conv.input_state }}
            </div>
            <div *ngIf="conv.last_message && (conv.input_state == 'NONE' || !conv.input_state)"
              [ngClass]="conv.unreaded_messages>0 ? 'message__dialog__bold' : 'message__dialog'">
              {{ conv.last_message }}
            </div>
            <div *ngIf="!conv.last_message && (conv.input_state == 'NONE' || !conv.input_state)"
              [ngClass]="conv.unreaded_messages>0 ? 'img_legend__bold' : 'img_legend'">
              Imagen, archivo o audio
            </div>
          </div>
        </li>
        <li id="showMoreConversation" *ngIf="!_appService.conversation_end && _appService.conversations.length>0">
          <a (click)="getPreviusConversations()" *ngIf="!loading_previus">Ver más</a>
          <i *ngIf="loading_previus" class="loader"></i>
        </li>
      </ul>
    </div>
  </div>

  <div class="direct-msg--empty"
    *ngIf="(width_screen >= 560 && !_appService.conversations[_appService.current_conversation_index]) && !open_file_previsual">

    <header class="direct-msg__head">
      <span class="chat-room__close icon-cross" (click)="closeChatRoom()"></span>
    </header>
    <div class="direct-msg__history">
      {{_appService.conversations.length > 0 ? 'Seleccione un chat para poder iniciar una conversación.' : 'No ha iniciado conversaciones'}}
    </div>
  </div>

  <div class="direct-msg"
    *ngIf="((_appService.conversations[_appService.current_conversation_index] && (width_screen < 560)) || (width_screen >= 560 && _appService.conversations[_appService.current_conversation_index])) && !open_file_previsual">

    <header class="direct-msg__head">

      <span class="direct-msg__return icon-arrow-left" (click)="closeConversation()" *ngIf="width_screen < 560"></span>

      <div class="profile">
        <a *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length === 1"
          target="_blank" (click)="openUserProfile()">
          <img #conversationAvatar class="receiver-avatar"
            (error)="conversationAvatar.src=getAvatarErrorImage(_appService.conversations[_appService.current_conversation_index])"
            [src]="_appService.conversations[_appService.current_conversation_index] | urlImage:3">
          <div *ngIf="_appService.current_conversation_index > -1"
            [class]="'circle-status-'+_appService.conversations[_appService.current_conversation_index].associated[0].user.status">
          </div>
          <div class="direct-msg_title">
            <div class="received__username">
              {{ _appService.conversations[_appService.current_conversation_index].associated[0].user.user_name | cutUserName:'2' }}
            </div>
            <div class="received__role"
              [ngStyle]="{'text-transform': !_appService.conversations[_appService.current_conversation_index].input_state || _appService.conversations[_appService.current_conversation_index].input_state=='NONE' ? 'capitalize' : null}">
              {{!_appService.conversations[_appService.current_conversation_index].input_state || _appService.conversations[_appService.current_conversation_index].input_state=='NONE' ?  (_appService.conversations[_appService.current_conversation_index].associated[0].user.user_role | roleName): _appService.conversations[_appService.current_conversation_index].input_state }}
            </div>

            

            <div *ngIf="(_appService.conversations[_appService.current_conversation_index].associated[0].user.user_role === 'assistant' && (_appService.user_data.role === 'assistant'|| _appService.user_data.role === 'moderador')) || _appService.conversations[_appService.current_conversation_index].associated[0].user.user_role !== 'assistant'" style="margin-top:3px;">
              <span
                *ngIf="_appService.conversations[_appService.current_conversation_index].state === 'ACCEPTED' && _appService.conversations[_appService.current_conversation_index].user_role === 'ADMINISTRATOR'"
                (click)="openAddUser($event)" class="add-user">
                <i class="icon-user-add"></i>
                Añadir participante
              </span>
            </div>




          </div>
        </a>
        <div class="head_group"
          *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length > 1">
          <!-- <img #conversationAvatar class="receiver-avatar"
            (error)="conversationAvatar.src=getAvatarErrorImage(conversation)" [src]="conversation | urlImage:3"> -->
          <!-- <div *ngIf="_appService.current_conversation_index > -1" class="group-icon">gr</div> -->

          <div class="chat_group_image" *ngIf="_appService.current_conversation_index > -1">
            <img (click)="openUserList()" class="receiver-avatar"
              [src]="_appService.conversations[_appService.current_conversation_index].associated[0] | urlImage:4">
            <img (click)="openUserList()" class="receiver-avatar"
              [src]="_appService.conversations[_appService.current_conversation_index].associated[1] | urlImage:4">
          </div>


          <div class="direct-msg_title">
            <div (click)="openUserList()" class="received__username">
              {{ _appService.conversations[_appService.current_conversation_index] | groupNamePipe }}
            </div>
            <div class="received__role">
              Conversación grupal
            </div>
            <span
              *ngIf="_appService.conversations[_appService.current_conversation_index].state === 'ACCEPTED' && _appService.conversations[_appService.current_conversation_index].user_role === 'ADMINISTRATOR'"
              (click)="openAddUser($event)" class="add-user">
              <i class="icon-user-add"></i>
              Añadir participante
            </span>
          </div>
        </div>
      </div>

      <span class="chat-room__close icon-cross" (click)="closeChatRoom()"></span>

    </header>
    <div *ngIf="_appService.conversations[_appService.current_conversation_index].state === 'INVITED'"
      class="direct-msg__history">
      <div class="invited-conversation">
        <p class="invited-conversation-message">Usted ha sido invitado a esta conversación, ¿desea aceptar?</p>
        <a class="button btn btn__dark-blue" role="button" (click)="acceptUserConversation()">Aceptar</a>
        <a class="button btn btn__cancel" role="button" (click)="declineUserConversation()">Rechazar</a>
      </div>
    </div>
    <div #containMessages *ngIf="_appService.conversations[_appService.current_conversation_index].state !== 'INVITED'"
      (scroll)="onScroll($event)" class="direct-msg__history direct-msg__history__messages__char-room">
      <div class="spinner" *ngIf="load_chatroom || uploading_file">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div class="file_prev" *ngIf="!load_chatroom && !uploading_file && file.src">
        <img *ngIf="file_type == 'image'" [src]="file.src | safePipe">
        <div class="no-support-file-prev" *ngIf="file_type == 'file' && !allowedVisualFileExtensions.exec(file.src)">
          <img src="./assets/icons/file.svg">
          <p>
            Vista previa no disponible
          </p>
          <p>
            {{ getFileSize(file.size) + ' MB - ' + file.file_ext.toUpperCase() }}
          </p>
        </div>

        <iframe *ngIf="file_type == 'file' && allowedVisualFileExtensions.exec(file.src)"
          [src]="file.src | safePipe"></iframe>

        <!-- Support local vídeos -->
        <video *ngIf="file_type == 'video' && allowedVideoExtensions.exec(file.src)" [src]="file.src | safePipe" controls></video>

      </div>
      <ul #chatMessages class="direct-msg__history-list" *ngIf="!load_chatroom && !uploading_file && !file.src">

        <li class="start_conversation" *ngIf="load_msg || end_msg">
          <span>
            {{((end_msg && load_msg==false)?'Inicio de la conversación':'Cargando...')}}
          </span>
        </li>

        <li class="message" [id]="message.id"
        *ngFor="let message of conversation_msg.data; let idx = index"
        [ngClass]="message.user_id == _appService.user_data.id ? 'right-msg' : 'left-msg'">

          <div class="msg" [ngClass]="message.user_id == _appService.user_data.id ? 'sent-msg' : 'received-msg'">

            <div *ngIf="message.deleted" class="msg__deleted">
              <p *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length > 1 && message.user_id != _appService.user_data.id"
                [style.color]="_appService.conversations[_appService.current_conversation_index]|labelColorPipe:message.user_id"
                class="name">
                {{ getUserMessageFrom(message.user_id) | cutUserName:'2' }}</p>
              <p class="msg__text"><span
                  class="lnr lnr-cross-circle"></span>{{message.user_id == _appService.user_data.id ? 'Eliminaste este mensaje' : 'Se eliminó este mensaje'}}
              </p>
            </div>
            <div *ngIf="!message.deleted &&  message.type === 'SYSTEM'" class="msg__deleted">
              <p class="msg__text" style="padding-left: 0;">{{ message.content }}
              </p>
            </div>
            <div *ngIf="!message.deleted && message.type != 'SYSTEM'" class="msg__content">

              <!-- [hidden]="!message.show_options" -->
              <!-- <span class="menu-options icon-ellipsis-v" (click)="showing_menu_options($event, message)">

                <div (mouseenter)="message.show_options = true" (mouseleave)="message.show_options = false"
                  [hidden]="!message.show_options_menu" class="msg_menu"
                  [ngClass]="message.user_id == _appService.user_data.id ? 'sent-msg' : 'received-msg'">
                  <ul>
                    <li (click)="delete_message($event, message)"><span> Eliminar</span></li>
                  </ul>
                </div>

              </span> -->
              <div class="sub_msg_content" (mouseenter)="reactions_msg_id = message.id" (mouseleave)="reactions_msg_id = menu_msg_id = null">
                
                <!-- message actions - icons -->
                <div class="msg_actions" [class.active]="message.id == reactions_msg_id">
                  <!-- reactions emoticons mensaje -->
                  <!-- <span class="icon-happy"></span> -->
                </div>

                <div appHoverMsg [appHoverMsg]="message" [user_data]="_appService.user_data"
                  class="content__message-and-reply_message">

                  <div class="menu-actions-message-contain">
                    <!-- Message menu actions -->
                    <span appMenuMsg [appMenuMsg]="{'m':message, 'c':containMessages,'index':idx}" class="none menu-actions-message lnr lnr-chevron-down" (click)="openAndCloseMenuMessage(message.id)">
                      <div class="actions-menu-message none">
                        <a *ngIf="message.type === 'TEXT'" (click)="replyMessage(message)"><span>Responder</span></a>
                        <a (click)="delete_message($event, message)"><span>Eliminar</span></a>
                      </div>
                    </span>
                  </div>

                  <!-- Contenido del mensaje que se está respondiendo -->
                  <div *ngIf="message.parent_id != null" class="msg__reply" (click)="goToReplyMsg(message)">
                    <!-- Solo se muestra cuando msg_content sea class reply -->

                    <!-- shadow background menu -->
                    <div class="none bg-parent-msg-menu"></div>
  
                    <!-- Si es un mensaje que se ha respondido - Mostrar nombre del usuario a quien responde -->
                    <!-- Se debe asignar la info del usuario  -->
                    <a class="name user_to_reply">{{ message.parent[0].user.user_name | cutUserName:'2' }}</a>
  
                    <p class="msg__text text_reply" [appPreviewUrl]="message.parent[0].content">
                      {{ message.parent[0].content }}
                    </p>
                  </div>
                  <!-- END Contenido del mensaje que se está respondiendo -->

                  <p appPreviewUrl [appPreviewUrl]="message.content"
                    *ngIf="message.type === 'TEXT' || !message.type" class="msg__text "
                    [ngClass]="{'user_msg_name': _appService.conversations[_appService.current_conversation_index].associated.length > 1}">

                    <!-- Cambié la validación porque a veces se rompía y no mostraba nombre de los participantes en el mensaje -->
                    <!-- *ngIf="(_appService.conversations[_appService.current_conversation_index].associated.length > 1 && message.user_id != _appService.user_data.id) && (message.type === 'TEXT' || !message.type)" -->
                    <span
                      *ngIf="(_appService.conversations[_appService.current_conversation_index].associated.length > 1 && message.user_id != _appService.user_data.id)"
                      class="name"
                      [style.color]="_appService.conversations[_appService.current_conversation_index]|labelColorPipe:message.user_id">{{ getUserMessageFrom(message.user_id) | cutUserName:'2' }}</span>
                    {{ message.content }}
                  </p>

                  <div *ngIf="message.type === 'IMAGE'" class="message_img chat_group">

                    <!-- shadow background menu -->
                    <div class="none bg-parent-msg-menu"></div>

                    <p *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length > 1 && message.user_id != _appService.user_data.id"
                      class="name"
                      [style.color]="_appService.conversations[_appService.current_conversation_index]|labelColorPipe:message.user_id">
                      {{ getUserMessageFrom(message.user_id) | cutUserName:'2'}}</p>
                    <img (click)="openfilePrevisual(message.src,message.content)" class="msg_img" [src]="message.src">
                    <p class="text-message" *ngIf="message.content != null && message.content!= ''">{{ message.content }}
                    </p>
                  </div>

                  <!-- INIT message video -->
                  <div appUrlVideo [appUrlVideo]="{src: message.src, first: firstElement}"
                    *ngIf="message.type === 'VIDEO'" class="message_video">

                    <!-- shadow background menu -->
                    <div class="none bg-parent-msg-menu"></div>
    
                    <a #firstElement [href]="getFile(message)" [download]="message.file" class="preview_file_message">
                      <div class="text_file">
                        <p class="file_name">{{ getFileName(message.file) }}</p>
                        <p *ngIf="message.file_size" class="file_metadata">{{ message.file_size + ' MB' }}</p>
                      </div>
                    </a>
                    <!-- END preview_file_message -->
    
                    <p class="text-message" *ngIf=" message.content!= null &&  message.content !=''">
                      {{ message.content }}
                    </p>
    
                  </div>
                  <!-- END message video -->

                  <div *ngIf="message.type === 'FILE'" class="message_file">
                    <p *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length > 1 && message.user_id != _appService.user_data.id"
                      class="name"
                      [style.color]="_appService.conversations[_appService.current_conversation_index]|labelColorPipe:message.user_id">
                      {{ getUserMessageFrom(message.user_id) | cutUserName:'2' }}</p>
                    <a [href]="message.src" [download]="message.file" class="preview_file_message" target="_blank">

                      <!-- shadow background menu -->
                      <div class="none bg-parent-msg-menu"></div>

                      <img [src]="message.src | srcFileIconPipe">

                      <div class="text_file">
                        <p class="file_name">{{ getFileName(message.file) }}</p>
                        <p *ngIf="message.file_size" class="file_metadata">{{ message.file_size + ' MB' }}</p>
                      </div>
                    </a>
                    <p class="text-message" *ngIf=" message.content!= null &&  message.content !=''">
                      {{ message.content }}
                    </p>
                  </div>

                  <div *ngIf="message.type === 'AUDIO'" class="message_audio" style="padding-top: 10px;">

                    <!-- shadow background menu -->
                    <div class="none bg-parent-msg-menu"></div>

                    <p *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length > 1 && message.user_id != _appService.user_data.id"
                      class="name"
                      [style.color]="_appService.conversations[_appService.current_conversation_index]|labelColorPipe:message.user_id">
                      {{ getUserMessageFrom(message.user_id) | cutUserName:'2' }}</p>
                    <img
                      [src]="message.user_id == _appService.user_data.id ? _appService.user_data.picture : get_associated(_appService.conversations[_appService.current_conversation_index],message) | urlImage :'4'">
    
                    <div class="audio-file">
                      <!-- <audio controls [src]="message.is_dummy? null:message.src"></audio> -->
    
                      <audio-player [audioFile]="message.is_dummy? null:message.src"></audio-player>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <span class="msg__date">{{message.created_at | timeTransform: 'full'}}
            </span>
          </div>

        </li>

        <div class="floating-button icon-arrow-down" *ngIf="display_floating_button"
        [ngStyle]="{'top': ((containMessages.scrollTop + containMessages.clientHeight) - 50) +'px'}"
        (click)="scrollToBottom(0)"></div>

        <div class="chat_notification" *ngIf="display_chat_notification">
          No se encontró el mensaje
        </div>

        <div class="spinner dotted typing_message" style="max-height: 40px;"
          *ngIf="(_appService.conversations[_appService.current_conversation_index].input_state != 'NONE') && _appService.conversations[_appService.current_conversation_index].input_state">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ul>
    </div>
    <div *ngIf="_appService.conversations[_appService.current_conversation_index].state === 'ACCEPTED' || _appService.conversations[_appService.current_conversation_index].state === 'CREATED'"
      class="direct-msg__footer">

      <div *ngIf="message_parent" class="reply_msg">
        <div class="msg parent-msg">
          <span class="msg__date"> Respondiendo mensaje</span>
          <div class="msg__content course__live_chat__content">
            <!-- Aquí debes poner la validación para indicar si es una respuesta en el [ngClass] para asignar la class 'reply' -->
            <div class="msg_content">
              <!-- Si es un mensaje normal mostrar nombre de quien lo envió  -> se oculta con css cuando msg_content sea class reply -->
              <p class="msg__text" appPreviewUrl [appPreviewUrl]="message_parent.content"
                *ngIf="message_parent.type === 'TEXT' || !message_parent.type">
                {{ message_parent.content }}
              </p>
            </div>
          </div>
        </div>
        <span class="reply__close icon-cross" (click)="replyMessage(null)"></span>
      </div>

      <form class="direct_msg__write" (ngSubmit)="actionSend()" autocomplete="off">
        <a (click)="buttonCancelToggling()" class="direct_msg__upload-btn btn"
          [ngClass]="(recordingSound)? 'btn-red':'btn__light-blue'" [disabled]="loadingSendMessage">
          <i [ngClass]="{'icon-contributions': !recordingSound, 'icon-delete': recordingSound}" aria-hidden="true"></i>


          <div *ngIf="show_file_options" class="direct_msg_send_file">
            <!--<div class="code">
              <a class="btn btn__dark-blue icon-code">
              </a>
            </div> -->
            <div class="video">
              <label  for="upload_video" class="btn btn__dark-blue icon-play-circle-outline"
              (click)="prevent_uploadFile_event($event)">
              <input type="file" id="upload_video" (change)="addfile($event)" [accept]="validAcceptTypes['video']"/>
              </label>
            </div>

            <div class="attached">
              <label for="upload_file" class="btn btn__dark-blue icon-attached"
                (click)="prevent_uploadFile_event($event)">
                <input type="file" id="upload_file" (change)="addfile($event)" [accept]="validAcceptTypes['file']" />
              </label>
            </div>
            <div class="image">
              <label for="upload_image" class="btn btn__dark-blue icon-image"
                (click)="prevent_uploadFile_event($event)">
                <input type="file" id="upload_image" (change)="addfile($event)" [accept]="validAcceptTypes['image']" />
              </label>
            </div>
          </div>


        </a>
        <input (keyup)="onKeyInput($event)" *ngIf="!recordingSound" (click)="show_file_options = false"
          #newMessageChatRoom autofocus type="text" class="direct_msg__input" placeholder="Escribe un mensaje"
          name="newMessage" [(ngModel)]="newMessage" />
        <div *ngIf="recordingSound" class="direct_msg__input">
          <div class="cron">
            <p #cronMinutes>00</p>:<p #cronSeconds>00</p>
          </div>
          <div>

          </div>
        </div>
        <button class="direct_msg__send-btn btn" [ngClass]="(recordingSound)? '':''" type="submit"
          [disabled]="loadingSendMessage">
          <i [ngClass]="{'loader-circle': loadingSendMessage, 'icon-send':!loadingSendMessage && (newMessage || recordingSound || file.src), 'icon-mic':!loadingSendMessage && !newMessage && !file.src}"
            aria-hidden="true"></i>
        </button>
      </form>
    </div>
  </div>
  <div *ngIf="open_file_previsual" class="file-visualizer">
    <header class="direct-msg__head">

      <span class="chat-room__close icon-arrow-left" (click)="closePrevisual()"></span>
      <div class="profile">
        <a *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length === 1"
          [href]=" environment.appDomain + '/user/profile/'+_appService.conversations[_appService.current_conversation_index].associated[0].user.user_id"
          target="_blank">
          <img #conversationAvatar class="receiver-avatar"
            (error)="conversationAvatar.src=getAvatarErrorImage(_appService.conversations[_appService.current_conversation_index])"
            [src]="_appService.conversations[_appService.current_conversation_index] | urlImage:3">
          <div *ngIf="_appService.current_conversation_index > -1"
            [class]="'circle-status-'+_appService.conversations[_appService.current_conversation_index].associated[0].user.status">
          </div>
          <div class="direct-msg_title">
            <div class="received__username">
              {{ _appService.conversations[_appService.current_conversation_index].associated[0].user.user_name |cutUserName:'2' }}
            </div>
            <div class="received__role">
              {{!_appService.conversations[_appService.current_conversation_index].input_state || _appService.conversations[_appService.current_conversation_index].input_state=='NONE' ?  (_appService.conversations[_appService.current_conversation_index].associated[0].user.user_role | roleName): _appService.conversations[_appService.current_conversation_index].input_state }}
            </div>
          </div>
        </a>
        <div class="head_group"
          *ngIf="_appService.conversations[_appService.current_conversation_index].associated.length > 1">
          <!-- <img #conversationAvatar class="receiver-avatar"
            (error)="conversationAvatar.src=getAvatarErrorImage(conversation)" [src]="conversation | urlImage:3">
          <div *ngIf="_appService.current_conversation_index > -1" class="group-icon">gr</div> -->

          <div class="chat_group_image" *ngIf="_appService.current_conversation_index > -1">
            <img class="receiver-avatar"
              [src]="_appService.conversations[_appService.current_conversation_index].associated[0] | urlImage:4">
            <img class="receiver-avatar"
              [src]="_appService.conversations[_appService.current_conversation_index].associated[1] | urlImage:4">
          </div>

          <div class="direct-msg_title">
            <div class="received__username">
              {{ _appService.conversations[_appService.current_conversation_index] | groupNamePipe }}
            </div>
            <div class="received__role">
              Conversación grupal
            </div>
          </div>
        </div>
      </div>



      <div class="icons-header">
        <a [href]="fileUrl" download="file">
          Descargar
          <!-- <span class="chat-room__close icon-download"></span> -->
        </a>

      </div>
    </header>

    <div class="file_prev">
      <div class="file_src">
        <img (click)="openTabPrevisual(previsual_src);" [src]="previsual_src">

      </div>
      <div class="file_message">
        <p>{{previsual_text}}</p>
      </div>
    </div>
  </div>
</div>
