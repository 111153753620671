import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "modal-dialog",
  templateUrl: "./modal-dialog.component.html",
  styleUrls: ["./modal-dialog.component.scss"],
})
export class ModalDialogComponent {
  constructor() { }

  ngOnInit(): void { }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('modal changes: ', changes);
  // }

  @Input() modal_type: number; // 0 Loading + mensaje //1 Solo aceptar //2 Cancelar y Aceptar
  @Output() response: EventEmitter<any> = new EventEmitter<any>();

  setMsg(text) {
    if (this.modal_type == 16) {
      this.default_msgs[16].txt = text;
    }
  }

  default_msgs = [
    //0 Solo loader
    { txt: null, btn: 0 },
    //1 Mensaje guardando cambios
    {
      txt: `<center><br><br><p>Por favor espere mientras guardamos sus cambios...</p></center>`,
      btn: 0,
    },
    //2 Problema al intentar guardar
    {
      title: "Existen campos vacíos",
      txt: `Por favor verifique y vuelva a intentarlo.`,
      btn: 1,
    },
    //3 Confirmación de eliminar apunte
    {
      title: "¿Está seguro que desea eliminar su apunte?",
      txt: `Su apunte será eliminado permanentemente y no podrá recuperarlo.`,
      btn: 2,
    },
    //4 Confirmación de cancelar de un apunte
    {
      title: "¿Está seguro que desea cancelar el apunte?",
      txt: `Perderá los cambios permanentemente si no ha guardado`,
      btn: 2,
    },
    //5 Notificación que hay un apunte abierto que no se puede guardar porque tiene elementos vacíos
    {
      title: "Tiene un apunte abierto que no se puede guardar",
      txt: `Tiene campos vacíos, verifique y vuelva a intentar.`,
      btn: 1,
    },
    //6 Eliminando...
    { txt: `<br><br><p><center>Eliminando...</center></p>`, btn: 0 },
    //7 Ocurrió error al intentar guardar
    {
      title: `<p><strong>Ocurrió un problema al intentar guardar su apunte</strong></p>`,
      txt: `<p>Por favor vuelva a intentarlo.<br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>`,
      btn: 1,
    },
    //8 Ocurrió error al subir el archivo
    {
      title: `<p><strong>Ocurrió un problema al intentar subir su archivo</strong></p>`,
      txt: `<p>Por favor vuelva a intentarlo.<br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>       
    `,
      btn: 1,
    },
    //9 commentario vacío
    {
      title: `Comentario vacío`,
      txt: `Escriba su comentario y vuelva a intentarlo.`,
      btn: 1,
    },
    //10 cancelar pregunta
    {
      title: `¿Está seguro que desea cancelar?`,
      txt: `Perderá los cambios permanentemente si no ha enviado`,
      btn: 2,
    },

    //11 Confirmación de eliminar pregunta
    {
      title: "¿Está seguro que desea eliminar su pregunta?",
      txt: `Su pregunta será eliminada permanentemente y no podrá recuperarla.`,
      btn: 2,
    },

    //12 Ocurrió problema al intentar eliminar
    {
      title: `<p><strong>Ocurrió un problema al intentar eliminar</strong></p>`,
      txt: `<p>Por favor vuelva a intentarlo.<br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>`,
      btn: 1,
    },

    //13 Confirmación de eliminar comentario
    {
      title: "¿Está seguro que desea eliminar su comentario?",
      txt: `Su comentario será eliminado permanentemente y no podrá recuperarlo.`,
      btn: 2,
    },

    //14 Confirmación de eliminar respuesta
    {
      title: "¿Está seguro que desea eliminar su respuesta?",
      txt: `Su respuesta será eliminada y no podrá recuperarla.`,
      btn: 2,
    },

    //15 Confirmación de eliminar pregunta
    {
      title: "¿Está seguro que desea eliminar su aporte?",
      txt: `Su aporte será eliminado permanentemente y no podrá recuperarlo.`,
      btn: 2,
    },

    //16 Mensaje predefinido, respuesta del backend
    {
      title: `<p><strong>No se pudo completar la acción</strong></p>`,
      txt: "",
      btn: 1,
    },

    //17 No ha iniciado sesión
    {
      title: "Usted no ha iniciado sesión ",
      txt: `Puede continuar explorando el curso. <br>Debe <a href="/users/login" target="_self">iniciar sesión</a> para poder:<br>
    <ul> 
    <li><i class="icon-tick"></i>Almacenar el progreso del curso</li>
    <li><i class="icon-tick"></i>Tomar apuntes</li>
    <li><i class="icon-tick"></i>Formular, responder y comentar preguntas</li>
    <li><i class="icon-tick"></i>Realizar y comentar aportes</li>
    </ul>`,
      btn: 1,
    },

    //18 Ocurrió problema al intentar cargar source
    {
      title: `<p><strong>Ocurrió un problema al intentar cargar el curso</strong></p>`,
      txt: `<p>Por favor <a class="help__link" href="https://edutin.com/library" target="_self">  ingrese nuevamente al curso >></a><br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a></p>`,
      btn: 1,
    },

    //19 Tiene un apunte abierto y cambiará de archivo
    {
      title: "Tiene un apunte abierto, desea continuar sin guardar su apunte?",
      txt: `Perderá los cambios permanentemente si no ha guardado.`,
      btn: 2,
    },

    //20 Ocurrió problema al enviar respuestas
    {
      title: `<p><strong>Ocurrió un problema al intentar enviar su respuesta</strong></p>`,
      txt: `<p>Por favor vuelva a intentarlo.<br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>       
        `,
      btn: 1,
    },

    //21 Enviando respuestas de quiz
    {
      txt: `<center><br><br><p>Enviando evaluación...</p></center>`,
      btn: 0,
    },

    //22 Ocurrió problema al enviar respuestas
    {
      title: `Tiene una o más preguntas sin responder`,
      txt: `<p>Debe solucionar todas las preguntas para poder enviar las respuestas</p>       
          `,
      btn: 1,
    },

    //23 Cargando apunte
    {
      txt: `<center><br><br><p>Cargando su apunte...</p></center>`,
      btn: 0,
    },

    //24 Cargando solución
    {
      txt: `<center><br><br><p>Cargando su solución...</p></center>`,
      btn: 0,
    },

    //25 Mensaje guardando cambios
    {
      txt: `<center><br><br><p>Por favor espere mientras guardamos su participación...</p></center>`,
      btn: 0,
    },

    //26 Perdió conexión
    {
      title: "Se perdió la conexión ",
      txt: `Por favor, verifique el estado de su conexión a internet para poder:<br>
    <ul> 
    <li><i class="icon-tick"></i>Almacenar el progreso del curso</li>
    <li><i class="icon-tick"></i>Tomar apuntes</li>
    <li><i class="icon-tick"></i>Formular, responder y comentar preguntas</li>
    <li><i class="icon-tick"></i>Realizar y comentar aportes</li>
    </ul>`,
      btn: 1,
    },

    //27 Restableció conexión
    {
      title: "Se restableció la conexión ",
      txt: `Puede continuar estudiando con nosotros.`,
      btn: 1,
    },




    //28 Notificación de que la pregunta fue enviada
    {
      title: "Pregunta enviada",
      txt: `Tu pregunta se ha enviado satisfactoriamente, te invitamos a continuar estudiando, nosotros te notificaremos cuando recibas respuestas.`,
      btn: 1,
    },


    //29 Notificación de que la Participación fue enviada
    {
      title: "Participación enviada",
      txt: `Tu publicación ha sido enviada satisfactoriamente, se requiere que des tu opinión a 2 de tus compañeros para la aprobación de este foro.`,
      btn: 1,
    },


    //30 Notificación de que ha enviado 1 opinión
    {
      title: "Opinión enviada",
      txt: `Tu opinión ha sido enviada satisfactoriamente, solo te falta dar una opinión a otro compañero para la aprobación de este foro.`,
      btn: 1,
    },


    //31 Notificación de que ha opinado más de 1 vez y participó cuando envió una participación
    {
      title: "Participación enviada",
      txt: `Has aprobado satisfactoriamente este foro, estamos seguros que tus opiniones serán de mucha ayuda para tus compañeros.`,
      btn: 1,
    },

    //32 Notificación de que ha opinado más de 1 vez y participó cuando envió una opinión
    {
      title: "Opinión enviada",
      txt: `Has aprobado satisfactoriamente este foro, estamos seguros que tus opiniones serán de mucha ayuda para tus compañeros.`,
      btn: 1,
    },

    //33 Notificación de que no ha publicado y opinado 1 vez
    {
      title: "Opinión enviada",
      txt: `Tu opinión ha sido enviada satisfactoriamente, solo te falta realizar tu publicación y dar una opinión a otro compañero para la aprobación de este foro.`,
      btn: 1,
    },

    //34 Notificación de que NO ha publicado y opinado más de 1 vez
    {
      title: "Opinión enviada",
      txt: `Tu opinión ha sido enviada satisfactoriamente, solo te falta realizar tu publicación para la aprobación de este foro.`,
      btn: 1,
    },


    //35 Notificación de que envió participación pero no cuenta
    {
      title: "Participación enviada",
      txt: `Tu participación ha sido enviada satisfactoriamente.`,
      btn: 1,
    },

    //36 Notificación de que envió Opinión pero no cuenta
    {
      title: "Opinión enviada",
      txt: `Tu opinión ha sido enviada satisfactoriamente.`,
      btn: 1,
    },

    //37 Notificación de que envió una respuesta
    //Seguir probando enviar esta notificación
    {
      title: "Respuesta enviada",
      txt: `Tu respuesta ha sido enviada satisfactoriamente, estamos seguros que tus respuestas serán de mucha ayuda para tus compañeros.`,
      btn: 1,
    },


    //38 Notificación de que falló algún metodo
    {

      title: `<p><strong>Ocurrió un problema al intentar cargar el curso</strong></p>`,
      txt: `<p>Por favor vuelva a intentarlo.<br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>       
          `,
      btn: 1,

    },


    //39 Notificación de que la pregunta fue editada
    {
      title: "Pregunta editada",
      txt: `Tu pregunta se ha editada satisfactoriamente, te invitamos a continuar estudiando, nosotros te notificaremos cuando recibas respuestas.`,
      btn: 1,
    },


    //40 Notificación de que envió una respuesta
    //Seguir probando enviar esta notificación
    {
      title: "Respuesta editada",
      txt: `Tu respuesta ha sido editada satisfactoriamente, estamos seguros que tus respuestas serán de mucha ayuda para tus compañeros.`,
      btn: 1,
    },



    //41 Ocurrió problema al enviar pregunta/respuestas/participación
    {
      title: `<p><strong>Ocurrió un problema al intentar enviar</strong></p>`,
      txt: `<p>Por favor vuelva a intentarlo.<br> Si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>       
        `,
      btn: 1,
    },

    //42 Esta clase no tiene archivos para visualizar
    {
      title: `<p><strong>No se puede cargar la clase</strong></p>`,
      txt: `<p>La clase que está intentando visualizar no tiene recursos para mostrar.<br> si el problema persiste <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>       
            `,
      btn: 1,
    },

    //43 El archivo que esta intentando subir no es permitido
    {
      title: `<p><strong>Lo sentimos</strong></p>`,
      txt: `<p>El formato del archivo que está intentando subir no está permitido.</p>       
              <p>Por favor intente con otro archivo</p>
              `,
      btn: 1,
    },

    //44 Formatos de imagenes
    {
      title: `<p><strong>Lo sentimos</strong></p>`,
      txt: `<p>El formato de la imagen que está intentando subir no está permitido.</p>       
                <p>Por favor intente solo con formatos .jpeg, .jpg, .png, .gif .</p>
                `,
      btn: 1,
    },

    //45 Archivos cargando
    {
      title: "Archivos cargando",
      txt: `Hay archivos que no han terminado de cargar. Por favor verifique y vuelva a intentarlo.`,
      btn: 1,
    },

    //46 Notificación de que la participación fue editada
    {
      title: "Participación editada",
      txt: `Tu participación se ha editada satisfactoriamente.`,
      btn: 1,
    },

    //47 Notificación de que la opinión fue editada
    {
      title: "Opinión editada",
      txt: `Tu opinión se ha editado satisfactoriamente.`,
      btn: 1,
    },
    //48 No ha iniciado sesión
    {
      title: "Usted no ha iniciado sesión ",
      txt: `Debe <a class="help__link" href="https://app.edutin.com/user/accounts" target="_self">iniciar sesión</a> o <a class="help__link" href="https://app.edutin.com/user/add" target="_self">crear cuenta</a> para poder realizar su pregunta y responder las preguntas de sus compañeros.`,
      btn: 1,
    },

    //49 compartir
    {
      title: "Compartir",
      txt: `Debe <a class="help__link" href="https://app.edutin.com/user/accounts" target="_self">iniciar sesión</a> o <a class="help__link" href="https://app.edutin.com/user/add" target="_self">crear cuenta</a> para poder realizar su pregunta y responder las preguntas de sus compañeros.`,
      btn: 1,
    },

    //50 Confirmación de eliminar conexión
    {
      title: "¿Está seguro que desea eliminar conexión?",
      txt: `Su conexión con este participante será eliminada.`,
      btn: 2,
    },

    //51 eliminar mensaje
    {
      title: "Eliminar mensaje",
      txt: `¿Para quién quieres eliminar este mensaje?`,
      btn: 2,
    },

    //52 eliminar mensaje
    {
      title: "Eliminar mensaje",
      txt: `¿Confirma eliminar este mensaje?`,
      btn: 1,
    },
    //53 Agregar usuario
    {
      title: "Invitar usuario",
      txt: `¿Confirma que desea invitar a este usuario a esta conversación?`,
      btn: 2,
    },

    //54 Error al enviar quiz porque no tiene weight/valor porcentual
    {
      title: `<p><strong>No se pudo enviar su solución</strong></p>`,
      txt: `<p><strong>QuizzesWeigthException:</strong> The weight of the quiz is 0.<br> Por favor <a class="help__link" href="https://edutin.com/helps/requests" target="_blank"> comuniquese con un asesor >></a>.</p>`,
      btn: 1,
    },
    //55 eliminar usuario
    {
      title: "Eliminar usuario",
      txt: `¿Confirma que desea eliminar a este usuario de esta conversación?`,
      btn: 2,
    },
    //56 eliminar usuario
    {
      title: "Eliminar usuario",
      txt: `No puede eliminar a esta persona del chat`,
      btn: 1,
    }
  ];

  responseAction(value) {
    this.response.emit(value);
  }

  ngOnDestroy() {
    this.modal_type = null;
  }
}
