import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppService } from '../../app.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {

  constructor(private _appService: AppService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      const { valid, session } = this._appService.getUserData();
      
      // Cognito User
      if (!valid) {
        // Cognito User error
        return this._router.parseUrl('/user/login');
      }

      /* User without session */

      // User session different of 1.
      if (session != 1) {

        if (!Number(next.params.certification_id)) {
          return this._router.parseUrl('/user/login');
        }

        if (state.url.includes('/dashboard')) {
          return this._router.parseUrl('/user/login');
        }

        // Anyone can access to verify route.
        if (!state.url.includes('/verify')) {
          return this._router.parseUrl('/category');
        }

        this.setActionsNavbarFooter();
        return true;
      }

      /* User with session */

      if (!Number(next.params.certification_id)) {
        return this._router.parseUrl('/category');
      }

      if (state.url.includes('/verify')) {
        this.setActionsNavbarFooter();
        return true;
      }

      if (!state.url.includes('/dashboard')) {
        return this._router.parseUrl('/category');
      }

      this.setActionsNavbarFooter();

      // Go
      return true;
  }



  
  /*
  * Show nav bar and hide footer.
  * Init gets nav bar.
  */
  private setActionsNavbarFooter(): void {
    this._appService.loadNavBar = true;
    this._appService.loadFooter = true;
    this._appService.startGetsNavBar();
  }
  
}
