<div *ngIf="_appService.categories.length>0" class="content">
  <ul>
    <li *ngFor="let category_1 of _appService.categories | slice:0:10;">
      <a [routerLink]="['/category',category_1.id]" (click)="closeThisModal()">
        <img #listCat [src]="category_1.logo"
          (error)="listCat.src='https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'"
          [alt]="category_1.name">
        <span>{{category_1.name}}</span>
      </a>
    </li>
  </ul>
  <ul>
    <li *ngFor="let category_2 of _appService.categories | slice:10" (click)="closeThisModal()">
      <a [routerLink]="['/category',category_2.id]">
        <img #listCat [src]="category_2.logo"
          (error)="listCat.src='https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg'"
          [alt]="category_2.name">
        <span>{{category_2.name}}</span>
      </a>
    </li>
  </ul>
</div>