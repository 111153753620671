import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ItemListComponent } from 'src/app/classroom/community/item-list/item-list.component';
import { ClassroomService } from 'src/app/classroom/services/classroom.service';
import { CommunityService } from 'src/app/classroom/services/community.service';


@Component({
  selector: 'comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {

  constructor(
    private _communityService: CommunityService,
    public _appService: AppService,
    public _classroomService: ClassroomService
  ) { }

  item_list = {
    state: false,
    type: null
  };

  items: any = null;

  notification_title = null;

  @ViewChild(ItemListComponent) commentList: ItemListComponent;


  ngOnInit(): void {

    this.items = null;

    this._communityService.params.token = this._appService.token != null ? true : false;

    const { comment_id, course_name, type, course_id } = this._appService.comment_modal_.data;


    // inputs and public variables and methods    

    if (comment_id) {

      this._communityService.getCommentsRedirect(comment_id).subscribe(comment => {
        // this.filter.push({ id: 'notificate', label: 'Pregunta' })
        // this.filter_selected = this.filter[this.filter.length - 1];

        this.item_list.state = true;

        if (comment != null && comment.hasOwnProperty('comments') && (comment.comments != null && comment.comments.length > 0)) {

          const { Comment } = comment.comments[0];

          let notification_label_type = '';

          if (type == 'AQN') {
            //'respuesta';

            notification_label_type = Comment.quiz_id != null && Comment.quiz_id != 0 ? 'opinión' : 'respuesta';

          }

          if (type == 'PCN') {
            //'pregunta';
            notification_label_type = Comment.quiz_id != null && Comment.quiz_id != 0 ? 'participación' : 'pregunta';
          }

          this.notification_title = `<b>${course_name}</b> tiene una <b>nueva ${notification_label_type}</b>`;



          // this._communityService.data_comments['notificate'] = (JSON.parse(JSON.stringify(comment.comments)));

          this.items = JSON.parse(JSON.stringify(comment.comments));

          this.items[0].Comment['curso_id'] = course_id;
          this.items[0].Comment['curso_nombre'] = course_name;


          setTimeout(() => {
            // this.commentList.reference = "no";
            this.commentList.items = JSON.parse(JSON.stringify(this.items));
            this.commentList.items_type = "'notificate'";
            this.commentList.actions = this._communityService.params.token != null ? true : false;
            this.commentList.current_user_data = this._appService.user_data;
            this.commentList.item_selector_id = 'com_' + this.commentList.reference.charAt(0) + '_';
          }, 10);

        } else {

          // this._communityService.data_comments['notificate'] = [];

        }



        // setTimeout(() => {

        //   let comments_section = document.querySelector('.source_comments');

        //   if (comments_section) {
        //     comments_section.scrollIntoView({
        //       behavior: 'smooth',
        //       block: "center",
        //       inline: "center"
        //     });
        //   }

        // }, 400);

      });

    } else {

      this.item_list.state = true;

    }


  }

}
