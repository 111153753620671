import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ConnectionService } from 'src/app/shared/service/connection.service';
import { environment } from 'src/environments/environment';
import { DataConnection, DataSuggestion } from './model';
import { Subject, Subscription } from 'rxjs';


@Component({
  selector: 'connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit {

  @Input() showMenu: boolean = false;
  showConnections: boolean = false;

  connectionsQuerySubscription: Subscription;

  activeTabs = { pending: true, confirmed: false, suggestions: false }

  pendingNextToken = null
  confirmedNextToken = null

  suggestionLastEvaluateKey = null

  user_id_to_reply = [];
  viewMoreLoader = false;

  constructor(
    public _appService: AppService,
    public _connectionService: ConnectionService
  ) { }

  ngOnInit() {
    if (this._appService._apolloClient) {
      this.initConnections();
    } else {
      this._appService.lazyEventStream.subscribe(r => {
        if (r) this.initConnections()
      });
    }
  }

  initConnections() {

    // Traer primero las solicitudes pendientes
    this.connectionsQuerySubscription = this._connectionService.getConnections("pending").subscribe(p_connections => {

      this.pendingNextToken = p_connections.next
      this._connectionService.loadingPendingConnections = false;

      // Cantidad de conexiones pendientes en total
      this._connectionService.getCountConnections(this._appService.user_data.id, 0).subscribe(response => {
        if (response.status) {
          this._connectionService.countPendingConnection = response.count
          this.startSuggestions('FIRTS')
        }
      });

      // Traer mis conexiones
      this._connectionService.getConnections("confirmed", 0).subscribe(c_connections => {
        this.confirmedNextToken = c_connections.next;
        this._connectionService.loadingConfirmedConnections = false;
        this.printConnections(c_connections);
      })

      this.printConnections(p_connections)

      this._connectionService.initConnectionSubcription();
    });
  }



  printConnections(dataToPrint) {

    this.connectionsQuerySubscription.unsubscribe()

    const { valid, print, count } = dataToPrint

    if (valid) {

      let data: Array<any> = print;
      let connection: DataConnection

      data.forEach((item, index) => {

        connection = this._connectionService.formmatConnectionData(item);

        switch (connection.state) {

          // Conexiones confirmadas
          case '1': this._connectionService.confirmedConnections.push(connection)
            break;

          // Conexiones pendientes
          default:
            this._connectionService.pendingConnections.push(connection)
            break;
        }
      });

      if (false) {
        // this.viewMore(null, 0)
      }
    }
  }


  startSuggestions(ctx?) {
    return new Promise((resolve, reject) => {

      let context = ctx == 'FIRTS' ? 'FIRTS' : 'APPBAR-CONNECTIONS';

      // Pido todas las sugerencias

      this._connectionService.getSuggestions(this._appService.user_data.id, this.suggestionLastEvaluateKey, context).subscribe(response => {

        this._connectionService.loadingSuggestions = false;

        const { valid, print, lastEvaluatedKey, count } = response

        // Si solo pedí la cantidad de solicitudes
        if (valid) {

          let data: Array<any> = print;
          let connected = false; // Para saber si dos usuarios ya están conectados
          let suggestion: DataSuggestion
          let urlAvatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
          let avatarDefault = 'https://d3puay5pkxu9s4.cloudfront.net/Users/default/medium_imagen.jpg?nocahe=1625022257';
          let urlFlag = 'https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/'

          data.forEach((item, index) => {

            let image = (item.User.image && !item.User.image.includes('platform-lookaside.fbsbx.com')) ? urlAvatar + item.User.id + '/medium_' + item.User.image : avatarDefault;

            if (item.User.image.includes('google') || item.User.image.includes('facebook')) {
              image = item.User.image
            }

            suggestion = {
              user_id: item.User.id,
              username: item.User.name,
              ocupation: item.Profile?.profesion,
              photo: image,
              photoDefault: avatarDefault,
              role: item.User.role,
              country: item.Country.name,
              flag: urlFlag + item.Country.short_name.toLowerCase() + '.svg',
              countryShortName: item.Country.short_name.toLowerCase(),
              state: item.Suggestion.state,
            }

            this.suggestionLastEvaluateKey = lastEvaluatedKey;

            // No recomendar usuarios que ya se siguen
            this._connectionService.confirmedConnections.forEach(connection => (connection.user_id == suggestion.user_id) ? connected = true : null);
            (!connected) ? this._connectionService.suggestions.push(suggestion) : this.deleteSuggestion(null, suggestion);

            connected = false;
          });


          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }


  toggleConnections(): void {
    this.showConnections = !this.showConnections;
  }


  toggleTabs(selectTab) {
    for (let tab in this.activeTabs) { this.activeTabs[tab] = Boolean(tab == selectTab) }
  }


  openUserProfile(user_id) {
    console.log("🚀 ~ openUserProfile ~ ");
    let url = environment.appDomain + '/user/profile/' + user_id;
    window.open(url, "_blank");
  }



  reply(event, user, res) {

    if (event) event.stopPropagation();

    // APROVAR
    if (res == "1") {

      this.user_id_to_reply.push(user.user_id);

      this._connectionService.approveFollower(user.user_id).subscribe(response => {

        if (response.status) {
          this._connectionService.countPendingConnection--;
          this.user_id_to_reply.shift();
          this._connectionService.refreshItems('pendingConnections', user, "1")

          if (this._connectionService.pendingConnections.length == 5 && this.pendingNextToken) {
            this.viewMoreConnections(null, "pending")
          }

        }
      })
    }

    // RECHAZAR
    else {

      this._connectionService.refreshItems('pendingConnections', user, "-1")
      this._connectionService.countPendingConnection--;

      this._connectionService.declineFollower(user.user_id).subscribe();

      if (this._connectionService.pendingConnections.length == 5 && this.pendingNextToken) {
        this.viewMoreConnections(null, "pending")
      }


    }
  }


  user_id_to_connect = []
  followParticipant(event, user) {

    if (event) event.stopPropagation();

    this.user_id_to_connect.push(user.user_id);

    this._connectionService.createFollower(user.user_id).subscribe(response => {

      const { status, data } = response;
      if (status) {

        // Para esperar el tiempo que se elimina la sugerencia
        setTimeout(() => {

          if (this._connectionService.suggestions.length == 6 && this.suggestionLastEvaluateKey) {
            this.viewMoreSuggestions(null, true)
          }

          this.user_id_to_connect.shift();
        }, 500);

      }

    });
  }


  deleteSuggestion(event, user) {
    if (event) event.stopPropagation();

    if (this._connectionService.suggestions.length == 6 && this.suggestionLastEvaluateKey) {
      this.viewMoreSuggestions(null)
    }

    this._connectionService.deleteSuggestion(user.user_id).subscribe();
  }


  async viewMoreSuggestions(event, scroll = false) {
    // type es el estado de la conexión
    if (event) event.stopPropagation();

    // console.log(this.suggestionLastEvaluateKey)
    if (this.suggestionLastEvaluateKey) {

      this.viewMoreLoader = true;
      await this.startSuggestions()
      this.viewMoreLoader = false;


      if (scroll) this.scrolling();



    }



  }

  scrolling() {
    let lastElement = document.getElementById(`listOfItems`);
    if (scroll) {
      setTimeout(() => lastElement.scrollTo(0, lastElement.scrollHeight), 200);
    }
  }

  viewMoreConnections(event, state) {

    // type es el estado de la conexión
    if (event) event.stopPropagation();

    const exclude_own = state == 'pending' ? 1 : 0;
    const after = state == 'pending' ? this.pendingNextToken : this.confirmedNextToken;

    if (after) {
      this.viewMoreLoader = true
      this._connectionService.getConnections(state, exclude_own, after).subscribe(connections => {

        this.viewMoreLoader = false;
        this.printConnections(connections);

        if (state == 'pending') {
          this.pendingNextToken = connections.next
        } else {
          this.confirmedNextToken = connections.next
        }

        this.scrolling();

      })
    }
  }




  ngOnDestroy() {
    this._connectionService.clearAllData();
  }



}
