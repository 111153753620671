import { Component, ComponentFactoryResolver, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { AppService } from './app.service';
import { PushNotificationService } from './shared/service/push-notification.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { LiveChatComponent } from './shared/components/live-chat/live-chat.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  //data del modulo de chat para poder llamar el módulo cuando se necesite
  chatComponent = {
    moduleId: 'chat-module',
    selector: 'chat-room'
  };

  commentsModalComponent = {
    moduleId: 'comments-modal-module',
    selector: 'comments-modal'
  };

  notifications: any = {
    count: 0,
    desk: []
  }

  user_routes = ['login', 'add', 'forgetpwd', 'confirm', 'resend', 'reset', 'resend_activation', 'confirmForgotPassword'];

  @ViewChild('liveChat', { read: ViewContainerRef }) entry: ViewContainerRef;
  

  constructor(
    public _router: Router,
    public _appService: AppService,
    public _authService: AuthService,
    public pushNotification: PushNotificationService,
    private resolver: ComponentFactoryResolver,
    private renderer2: Renderer2,
  ) {
    document.documentElement.setAttribute("data-browser", navigator.userAgent);
    
    this._router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this._appService.url_route = event.url.replace('/', '').split('/')[0].split('?')[0];
        }
      }
    );


    this._authService.isUserAuthenticated().subscribe();



    this._appService.notification_msg_subject.subscribe(notification => {

      //Se ejecuta cuando llega una notificación de mensaje directo de otro usuario
      notification['direct_msg_notification'] = true;
      notification.associated[0].user.status = 'online';

      let conv = { execute: 0, data: notification };


      if (!this.isAssist()) {
        this.openLiveChat(conv);
      }

    })

  }


  ngOnInit() {

    console.log('%c App Edutin Inc. - ' + new Date().getFullYear(), 'color: white; background: #1d448e');

    if ('serviceWorker' in navigator && Notification.permission === 'granted') {
      this.pushNotification.receiveMessage().subscribe(payload => {
      })
    }

    this._appService.closeLiveChatObservable.subscribe(() => {

      this.entry.clear();
      this.chat_user = null;
      this.chats = [];
      this.chat_index = -1;

    });
  }


  chat_user;
  chats = [];
  chat_index = -1;
  openLiveChat(data) {

    this._appService.current_conversation_index = -1;

    let width_screen = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);

    if (data.execute == 1) {

      this.chat_user = JSON.parse(JSON.stringify(data));

      if (this.chats.length > 0) {
        this.entry.clear();
        this.chats = [];
        this.chat_index = -1;
      }

    } else {

      if (width_screen < 600 && data.execute == 0) {
        this.chat_user = JSON.parse(JSON.stringify(data));

        setTimeout(() => {
          this._appService.chatroom_state = true;
        }, 100);

        if (this.chats.length > 0) {
          this.entry.clear();
          this.chats = [];
          this.chat_index = -1;
        }

        return false;
      }



      let chat_is_opened = this.chats.find(chat => chat.id == data.data.conversation.id);

      if (chat_is_opened == undefined || chat_is_opened == null) {

        // if (this.chats.length == 2) {
        if (this.chats.length == 1) {
          this.entry.remove(0);
          this.chats.splice(0, 1);


          this.chats.forEach((element, index) => {
            this.chat_index = this.chats.length;
            element.component.instance.chat_index = index;
          });
        } else {
          this.chat_index++;
        }

        this.chat_user = JSON.parse(JSON.stringify(data));

        let class_name = '';
        if (data.data && data.data.conversation && data.data.conversation.id) {
          class_name = data.data.conversation.id;
        }

        const factory = this.resolver.resolveComponentFactory(LiveChatComponent);
        const componentRef = this.entry.createComponent(factory);
        this.renderer2.addClass(componentRef.location.nativeElement, class_name);


        componentRef.instance.chat_index = this.chat_index;
        componentRef.instance.conv_index = data.index;
        componentRef.instance.openChat(this.chat_user);
        componentRef.instance.live_chat_state = 'open';

        this.chats.push({ component: componentRef, id: data.data.conversation.id });

        componentRef.instance.closeThisChat.subscribe(closed_chat_index => {

          this.entry.remove(closed_chat_index);
          this.chats.splice(closed_chat_index, 1);


          this.chats.forEach((element, index) => {
            this.chat_index = this.chats.length;
            element.component.instance.chat_index = index;
          });

          if (this.chats.length == 0) this.chat_index = -1;

        });


      }

    }


  }


  // Start notifications push
  subscribe_user_notification_push(response): void {
    if (this._appService.show_desktop_notification && response) {
      if ('serviceWorker' in navigator) {
        this.pushNotification.requestPermission().then(
          (token) => {
            this._appService.show_desktop_notification = false;
            if (token) {
              // ('Permission granted! Save to the server!', token);
              this.pushNotification.receiveMessage().subscribe(payload => {
                this.notifications.count++;
                this.notifications.desk.unshift(JSON.parse(payload.data.default));
              })
              this.updateSubscriptionOnServer(token);
            }
          }, (permission) => {
            if (permission === "default" || permission === "denied") {
              this._appService.show_desktop_notification = false;
            }
          }
        );
      }
    } else {
      this._appService.show_desktop_notification = false;
      localStorage.setItem('desk_push', JSON.stringify(false));
      // Create date of return
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("date_push", date.toDateString());
    }
  }

  updateSubscriptionOnServer(token) {
    if (this._appService.user_data && token) {
      let body = {
        "data": {
          "user_id": parseInt(this._appService.user_data.id),
          "token": token
        }
      };
      this._appService.updateSubscriptionOnServer(JSON.stringify(body)).subscribe();
    }
  }

  show_notification(desk, index) {
    if (desk) {
      //(desk);
      this.goToLink(desk.url);
      this.notifications.count--;
      this.notifications.desk.splice(index, 1);
    }
  }

  goToLink(url: string): void {
    window.open(url, "_blank");
  }

  verifyAuthenticatedUserEmail() {

    //Verificar si lo que es aquí es el correo o es el telefono

    const { email, phone_number } = this._appService.cognitoUserAuthenticated.signInUserSession.idToken.payload;

    if (email != null && email != '') {
      this._appService.verifyEmail = email;
      this._router.navigate(['/user/confirm']);
    }


  }




  /*  -------------------------------------------------- Assist app init --------------------------------------------------  */

  isAssist(): boolean {
    return this._router.url.includes('/assist') || this._appService.user_data.role == "assistant";
  }

  /*  -------------------------------------------------- Assist app end --------------------------------------------------  */


}
