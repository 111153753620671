import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { gql, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { ConstantPool } from '@angular/compiler';


@Injectable()
export class ChatRoomService {
    //conversations
    chatConversationsQuery: QueryRef<any>;
    conversationsObservable: Observable<any>;
    conversations: Array<any> = [];
    nextToken: String = null;
    conversation_end: boolean = true;
    static ws_host = 'tcm5oi5qdveqpamqp4txivk54q.appsync-api.us-east-1.amazonaws.com';

    static GET_CONVERSATIONS_MESSAGES = gql`
    query getConversationMessages($conversation_id: ID!, $after: String, $first: Int) {
        allMessageConnection(conversation_id: $conversation_id, after: $after, first: $first) {
            nextToken,
            messages {
                conversation_id
                id
                created_at
                content
                user_id
                type
                file
                file_size
                recipients {
                    user_id_date
                    user_id
                    state
                    conversation_id
                }
                parent_id
                parent{
                    content
                    type
                    user{
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                }
            }
        }
    }`;

    static GET_GROUP_COURSE_CONVERSATIONS_MESSAGES = gql`
    query getConversationMessages($conversation_id: ID!, $after: String, $first: Int) {
        allMessageConnection(conversation_id: $conversation_id, after: $after, first: $first) {
            nextToken,
            messages {
                conversation_id
                id
                created_at
                content
                user_id
                type
                file
                file_size
                state
                user{
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                parent_id
                parent{
                    content
                    type
                    user{
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                }
                reactions{
                    conversation_id
                    message_id
                    user_id
                    created_at
                    reaction
                    user{
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                }
            }
        }
    }`;

    static GET_GROUP_COURSE_MESSAGES_BY_CONVERSATION = gql`
    subscription subscribeToNewMessage($conversation_id: ID!) {
        subscribeToNewMessage(conversation_id: $conversation_id) {
            conversation_id
            created_at
            id
            user_id
            content
            type
            file
            file_size
            state
            user{
                user_id
                user_name
                user_image
                user_role
                user_fuente
            }
            parent_id
            parent{
                content
                type
                user{
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
            }
            reactions{
                conversation_id
                message_id
                user_id
                created_at
                reaction
                user{
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
            }
        }
    }`;

    static GET_GROUP_COURSE_REACTION_BY_CONVERSATION = gql`
    subscription subscribeToNewReaction($conversation_id: ID!) {
        subscribeToNewReaction(conversation_id: $conversation_id) {
            conversation_id
            message_id
            user_id
            created_at
            reaction
            user{
                user_id
                user_name
                user_image
                user_role
                user_fuente
            }
        }
    }`;

    static GET_MESSAGES_BY_CONVERSATION = gql`
    subscription subscribeToNewMessage($conversation_id: ID!) {
        subscribeToNewMessage(conversation_id: $conversation_id) {
            conversation_id
            created_at
            id
            user_id
            content
            type
            file
            file_size
            recipients{
                user_id_date
                user_id
                state
                conversation_id
            }
        }
    }`;

    static GET_RECIPENTS_BY_CONVERSATION = gql`
    subscription subscribeToNewRecipent($conversation_id: ID!) {
        subscribeToDeletedMessage(conversation_id: $conversation_id) {
            message_id
            user_id_date
            user_id
            state
            conversation_id
        }
    }`;

    static CREATE_EMPTY_CONVERSATION = gql`
    mutation createNewConversation($id: ID!, $name: String!, $created_at: String!, $type: String!, $user_id: ID!, $user_id2: ID!, $content: String!, $state: String!, $state1: String!) {
        cnc: createConversationDummy(id: $id, name: $name, created_at: $created_at, type: $type) {
            id
            name
            created_at
            type
        }
        coc: createUserConversationsDummy(conversation_id: $id, user_id: $user_id2, last_message: $content, last_update: $created_at, state: $state1){
            conversation {
                id
                name
                type
                image
            }
            associated {
                user {
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                state
                user_role
            }
            user_id
            conversation_id
            last_message
            last_update
            state
            unreaded_messages
            user_role
        }
        cc: createUserConversationsDummy(conversation_id: $id, user_id: $user_id, last_message: $content, last_update: $created_at, state: $state){
            conversation {
                id
                name
                type
                image
            }
            associated {
                user {
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                state
                user_role
            }
            user_id
            conversation_id
            last_message
            last_update
            state
            unreaded_messages
            user_role
        }
    }`;

    static CREATE_NEW_CONVERSATION = gql`
    mutation createNewConversation($id: ID!, $name: String!, $created_at: String!, $type: String!) {
        cnc: createConversation(id: $id, name: $name, created_at: $created_at, type: $type) {
            id
            name
            created_at
            type
        }
    }`;

    static INPUT_STATUS_EX = gql`
    subscription subscribeInputStatusEx($conversation_id: ID!) {
        subscribeToInputStateEx(conversation_id: $conversation_id){
            conversation_id
            input_state
        }
  }`;
    constructor(
        private http: HttpClient,
        private _appService: AppService
    ) {
    }

    getHeaders(): any {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._appService.token
        })
        return ({ headers: headers });
    }

    post(url: any, formData: FormData) {
        return this.http.post(url, formData, this.getHeaders());
    }

    getMessages(conversation_id, after): Observable<any> {
        this.chatConversationsQuery = this._appService._apolloClient.watchQuery({
            query: ChatRoomService.GET_CONVERSATIONS_MESSAGES,
            variables: {
                conversation_id: conversation_id,
                after: after
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });

        return this.chatConversationsQuery.valueChanges.pipe(
            map((messages: any) => {
                return messages;
            })
        );
    }

    getMessagesForGroupCourse(conversation_id, after): Observable<any> {
        this.chatConversationsQuery = this._appService._apolloClient.watchQuery({
            query: ChatRoomService.GET_GROUP_COURSE_CONVERSATIONS_MESSAGES,
            variables: {
                conversation_id: conversation_id,
                after: after
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });

        return this.chatConversationsQuery.valueChanges.pipe(
            map((messages: any) => {
                return messages;
            })
        );
    }

    sendMessage(variables, recipients, ignore_state = true): Observable<any> {
        var mut_head = `mutation createMessage($conversation_id: ID!, $id: ID!, $created_at: String!, $content: String!, $type: String, $file: String, $file_size: Int, $user_id: ID!, $state: String!, $user_role: String,  $parent_id: ID`;
        var mut_body = ``;
        var mut = ``;

        recipients.forEach(function (r, i) {
            if (r.state == "ACCEPTED" || r.state == "INVITED" || !ignore_state) {
                mut_head = mut_head + `, $user_id` + i + `: ID!, $state` + i + `: String!, $user_role` + i + `: String`;

                mut_body += `cc` + i + `: createUserConversations(conversation_id: $conversation_id, user_id: $user_id` + i + `, last_message: $content, last_update: $created_at, state: $state` + i + `, user_role: $user_role` + i + `){
                    conversation {
                        id
                        name
                        type
                        image
                    }
                    associated {
                        user {
                            user_id
                            user_name
                            user_image
                            user_role
                            user_fuente
                        }
                        state
                        user_role
                    }
                    user_id
                    conversation_id
                    last_message
                    last_update
                    state
                    unreaded_messages
                    user_role
                }`;

                variables['user_id' + i] = r.user.user_id;
                variables['state' + i] = ignore_state ? "ACCEPTED" : r.state;
                variables['user_role' + i] = r.user_role;
            }
        });

        mut_head += `){`;

        mut = mut_head + `cc: createUserConversations(conversation_id: $conversation_id, user_id: $user_id, last_message: $content, last_update: $created_at, state: $state, user_role: $user_role){
                conversation {
                    id
                    name
                    type
                    image
                }
                associated {
                    user {
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                    state
                    user_role
                }
                user_id
                conversation_id
                last_message
                last_update
                state
                unreaded_messages
                user_role
            }` + mut_body + `
            cm: createMessage(conversation_id: $conversation_id, id: $id, created_at: $created_at, content: $content, type: $type, file: $file, file_size: $file_size, parent_id: $parent_id){
                conversation_id
                created_at
                id
                content
                user_id
                type
                file
                file_size
                parent_id
                parent{
                    content
                    type
                    user{
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                }
            }
        }`;
        const ADD_MESSAGE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: ADD_MESSAGE,
            variables: variables
        });
    }


    sendCourseGroupMessage(variables): Observable<any> {
        var mut = `mutation createMessage($conversation_id: ID!, $id: ID!, $created_at: String!, $content: String!, $type: String, $parent_id: ID, $state: String){
            cm: createMessage(conversation_id: $conversation_id, id: $id, created_at: $created_at, content: $content, type: $type, parent_id: $parent_id, state: $state){
                conversation_id
                created_at
                id
                content
                user_id
                type
                state
                user{
                    user_fuente
                    user_id
                    user_image
                    user_name
                    user_role
                }
                parent_id
                parent{
                    content
                    type
                    user{
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                }
            }
        }`;
        const ADD_MESSAGE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: ADD_MESSAGE,
            variables: variables
        });
    }




    sendCourseGroupMessageFiles(variables): Observable<any> {
        var mut = `mutation createMessage($conversation_id: ID!, $id: ID!, $created_at: String!, $content: String!, $type: String, $file: String, $file_size: Int, $state: String) {
            cm: createMessage(conversation_id: $conversation_id, id: $id, created_at: $created_at, content: $content, type: $type, file: $file, file_size: $file_size, state: $state) {
                conversation_id
                created_at
                id
                content
                user_id
                type
                file
                state
                file_size
                user{
                    user_fuente
                    user_id
                    user_image
                    user_name
                    user_role
                }
            }
        }`;
        const ADD_MESSAGE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: ADD_MESSAGE,
            variables: variables
        });
    }




    deleteMessageGroupCourse(variables): Observable<any> {
        var mut = `mutation deleteMessageOnDB($created_at: String!, $conversation_id: ID!) {
            rm: deleteMessageOnDB(created_at: $created_at, conversation_id: $conversation_id) {
                id
                user_id
                type
            }
        }`;

        const DELETE_MESSAGE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: DELETE_MESSAGE,
            variables: variables
        });
    }
    
    sendCourseGroupReaction(variables): Observable<any> {
        var mut = `mutation createReaction($conversation_id: ID!, $created_at: String!, $message_id: ID!, $reaction: String){
            cm: createReaction(conversation_id: $conversation_id, created_at: $created_at, message_id: $message_id, reaction: $reaction){
                conversation_id
                message_id
                user_id
                created_at
                reaction
                user{
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
            }
        }`;
        const ADD_MESSAGE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: ADD_MESSAGE,
            variables: variables
        });
    }



    static DELETE_MESSAGE_GROUP = gql`
    subscription subscribeToCourseConversation($course_id: ID!) {
        subscribeToCourseConversation(course_id: $course_id) {
            id
        }
    }`;


    
    deleteCourseGroupReaction(variables): Observable<any> {
        var mut = `mutation deleteReaction($conversation_id: ID!, $message_id: ID!){
            cm: deleteReaction(conversation_id: $conversation_id, message_id: $message_id){
                conversation_id
                message_id
                user_id
                created_at
                reaction
            }
        }`;
        const ADD_MESSAGE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: ADD_MESSAGE,
            variables: variables
        });
    }

    sendRecipents(rvars, recipients): Observable<any> {
        var mut_head = `mutation addRecipients($conversation_id: ID!, $id: ID!, $created_at: String!,$user_id: ID!,$recipient_state: String!`;
        var mut_body = ``;
        var mut = ``;

        recipients.forEach(function (r, i) {
            if (r.state == "ACCEPTED") {
                mut_head = mut_head + `, $user_id` + i + `: ID!, $recipient_state` + i + `: String!`;

                mut_body += `cr` + i + `: createRecipient(message_id: $id, user_id: $user_id` + i + `, created_at: $created_at, state: $recipient_state` + i + `, conversation_id: $conversation_id){
                    message_id
                    user_id_date
                    user_id
                    state
                    conversation_id
                }`;

                rvars['user_id' + i] = r.user.user_id;
                rvars['recipient_state' + i] = 'UNREADED';
            }
        });

        mut_head += `){`;

        mut = mut_head + `cr: createRecipient(message_id: $id, user_id: $user_id, created_at: $created_at, state: $recipient_state, conversation_id: $conversation_id){
                message_id
                user_id_date
                user_id
                state
                conversation_id
            }
        ` + mut_body + `}`;

        const ADD_RECIPIENTS = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: ADD_RECIPIENTS,
            variables: rvars
        });
    }

    deleteMessage(variables, me: boolean, recipients): Observable<any> {
        if (me) {
            const DELETE_MESSAGE_FOR_ME = gql`
            mutation deleteMessage($conversation_id: ID!, $message_id: ID!, $created_at: String!, $user_id: ID!, $state: String!) {
                rm: deleteMessage(message_id: $message_id, user_id: $user_id, created_at: $created_at, conversation_id: $conversation_id, state: $state){
                    message_id
                    user_id_date
                    user_id
                    state
                    conversation_id
                }
            }`;

            return this._appService._apolloClient.mutate({
                mutation: DELETE_MESSAGE_FOR_ME,
                variables: variables
            });
        } else {
            var mut_head = `mutation deleteMessage($conversation_id: ID!, $message_id: ID!, $created_at: String!, $state: String!, $user_id: ID!`;
            var mut_body = ``;
            var mut = ``;

            recipients.forEach(function (r, i) {
                mut_head = mut_head + `, $user_id` + i + `: ID!`;

                mut_body += `cr` + i + `: deleteMessage(message_id: $message_id, user_id: $user_id` + i + `, created_at: $created_at, conversation_id: $conversation_id, state: $state){
                    message_id
                    user_id_date
                    user_id
                    state
                    conversation_id
                }`;

                variables['user_id' + i] = r.user.user_id;
            });

            mut_head += `){`;

            mut = mut_head + `cr: deleteMessage(message_id: $message_id, user_id: $user_id, created_at: $created_at, conversation_id: $conversation_id, state: $state){
                    message_id
                    user_id_date
                    user_id
                    state
                    conversation_id
                }
            ` + mut_body + `}`;

            const DELETE_MESSAGE_FOR_ALL = gql(mut);

            return this._appService._apolloClient.mutate({
                mutation: DELETE_MESSAGE_FOR_ALL,
                variables: variables
            });
        }
    }

    createEmptyConversation(variables): Observable<any> {
        return this._appService._apolloClient.mutate({
            mutation: ChatRoomService.CREATE_EMPTY_CONVERSATION,
            variables: variables
        });
    }

    createNewConversation(variables): Observable<any> {
        return this._appService._apolloClient.mutate({
            mutation: ChatRoomService.CREATE_NEW_CONVERSATION,
            variables: variables
        });
    }

    createConversation(receiver_id): Observable<any> {
        return this.http.post(`${this._appService.baseUrl}/init_conversations.json`, receiver_id, this.getHeaders())
            .pipe(
                catchError(this.handleError('createConversation'))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.log(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
    private log(message: string) {
        // console.log(`ClassroomService: ${message}`);
    }


    getDataFile(src) {
        var r = this.http.get(src, { responseType: 'blob' });
        return r;
    }


    sendInputState(rvars, recipients): Observable<any> {
        var mut_head = `mutation sendInputState($conversation_id: ID!, $input_state: String!`;
        var mut_body = ``;
        var mut = ``;

        recipients.forEach(function (r, i) {
            if (r.state == "ACCEPTED") {
                mut_head = mut_head + `, $user_id` + i + `: ID!`;

                mut_body += `cis` + i + `: changeInputState(user_id: $user_id` + i + `, conversation_id: $conversation_id, input_state: $input_state){
                    user_id
                    conversation_id
                    input_state
                }`;

                rvars['user_id' + i] = r.user.user_id;
            }
        });

        mut_head += `){`;

        mut = mut_head + mut_body + `}`;

        const SEND_INPUT_STATE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: SEND_INPUT_STATE,
            variables: rvars
        });
    }

    sendInputStateEx(rvars): Observable<any> {
        var mut = `mutation sendInputState($conversation_id: ID!, $input_state: String!){
            cis: changeInputStateEx(conversation_id: $conversation_id, input_state: $input_state){
                conversation_id
                input_state
            }
        }`;

        const SEND_INPUT_STATE = gql(mut);

        return this._appService._apolloClient.mutate({
            mutation: SEND_INPUT_STATE,
            variables: rvars
        });
    }


    sendUserConversation(variables, recipients): Observable<any> {
        var mut_head = `mutation sendUserConversation($conversation_id: ID!, $id: ID!, $created_at: String!, $type: String, $user_id: ID!, $content: String!, $state: String!, $user_role: String`;
        var mut_body = ``;
        var mut = ``;

        recipients.forEach(function (r, i) {
            if (r.state == "ACCEPTED") {
                mut_head = mut_head + `, $user_id` + i + `: ID!, $user_role` + i + `: String`;

                mut_body += `cc` + i + `: createUserConversations(conversation_id: $conversation_id, user_id: $user_id` + i + `, last_message: $content, last_update: $created_at, state: $state, user_role: $user_role` + i + `){
                    conversation {
                        id
                        name
                        type
                        image
                    }
                    associated {
                        user {
                            user_id
                            user_name
                            user_image
                            user_role
                            user_fuente
                        }
                        state
                        user_role
                    }
                    user_id
                    conversation_id
                    last_message
                    last_update
                    state
                    unreaded_messages
                    user_role
                }`;

                variables['user_id' + i] = r.user.user_id;
                variables['user_role' + i] = r.user_role;
            }
        });

        mut_head += `){`;

        mut = mut_head + `uc: createUserConversations(conversation_id: $conversation_id, user_id: $user_id, last_message: $content, last_update: $created_at, state: $state, user_role: $user_role){
                conversation {
                    id
                    name
                    type
                    image
                }
                associated {
                    user {
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                    state
                    user_role
                }
                user_id
                conversation_id
                last_message
                last_update
                state
                unreaded_messages
                user_role
            }` + mut_body + `
            cm: createMessage(conversation_id: $conversation_id, id: $id, created_at: $created_at, content: $content, type: $type){
                conversation_id
                created_at
                id
                content
                user_id
                type
                file
                file_size
            }
        }`;
        const ADD_USER = gql(mut);


        return this._appService._apolloClient.mutate({
            mutation: ADD_USER,
            variables: variables
        });
    }

    sendUserConversationEx(variables): Observable<any> {
        const ADD_USEReX = gql`mutation sendUserConversation($conversation_id: ID!, $created_at: String!, $user_id: ID!, $content: String!, $state: String!, $user_role: String)
        {
            ucx: createUserConversations(conversation_id: $conversation_id, user_id: $user_id, last_message: $content, last_update: $created_at, state: $state, user_role: $user_role)
            {
                conversation {
                    id
                    name
                    type
                    image
                }
                associated {
                    user {
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                    state
                    user_role
                }
                user_id
                conversation_id
                last_message
                last_update
                state
                unreaded_messages
                user_role
            }
        }`;


        return this._appService._apolloClient.mutate({
            mutation: ADD_USEReX,
            variables: variables
        });
    }

    changeConversationName(variables): Observable<any> {
        const CHANGE_CONVERSATION_NAME = gql`mutation updateConversation11($id: ID!, $name: String!)
        {
            ucxx: updateConversation(id: $id, name: $name)
            {
                id
                name
                type
                image
            }
        }`;


        return this._appService._apolloClient.mutate({
            mutation: CHANGE_CONVERSATION_NAME,
            variables: variables
        });
    }


}
