<div id="frequent-questions">
  <div class="content">
    <h2>Preguntas frecuentes</h2>
    <ul>
      <li #questionItem *ngFor="let question of questions;">
        <div (click)="questionItem.classList.toggle('open');" class="btn-item">
          {{question.title}}
          <i class="lnr lnr-chevron-up" [ngClass]="{'lnr-chevron-down': !questionItem.classList.contains('open')}"></i>
        </div>
        <div class="menu">
          <p [innerHTML]="question.description"></p>
        </div>
      </li>
    </ul>
  </div>
</div>
