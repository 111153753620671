/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { environment } from './environments/environment';

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",

  // "aws_user_pools_id": "us-east-1_i8nNTMW3T",
  // "aws_user_pools_web_client_id": "4jqe22600nihj07cgv7jno9keq",

  "aws_user_pools_id": "us-east-1_URoshfP3Q",
  "aws_user_pools_web_client_id": "18s0tft5v5nepjuq5ucqdlba7i",
  "oauth": {
    // "domain": "us-east-1-i8nntmw3t.auth.us-east-1.amazoncognito.com",
    "domain": "auth.edutin.com",
    "scope": [
      "email",
      "openid",
      "phone"
    ],

    "redirectSignIn": environment.redirectSignIn,
    "redirectSignOut": environment.redirectSignOut,
    "responseType": "token"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "cookieStorage": {
    "domain": ".edutin.com",
    // "domain":"127.0.0.1",
    "path": "/",
    "expires": 365,
    "sameSite": "strict",
    "secure": true,
  },
};

export default awsmobile;