import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_ROUTING } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppService } from './app.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStategy } from './custom-route-reuse-strategy';
import { PushNotificationService } from './shared/service/push-notification.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor } from './auth/services/cookies.interceptor';
import { ChatRoomModule } from './shared/components/chat-room/chat-room.module';
import { LiveChatComponent } from './shared/components/live-chat/live-chat.component';
import { CommentsModalModule } from './shared/components/comments-modal/comments-modal.module';




@NgModule({
  declarations: [
    AppComponent,
    LiveChatComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    SharedModule,
    ChatRoomModule,
    CommentsModalModule,
    HttpClientModule,
  ],
  providers: [AppService, {
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStategy
  },
    PushNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {

}
