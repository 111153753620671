import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.scss']
})
export class MenuCategoriesComponent implements OnInit {

  @Output() closeModal = new EventEmitter<any>();

  constructor(
    public _appService: AppService
  ) { }

  ngOnInit() {
  }

  closeThisModal(){
    this.closeModal.emit();
  }

}
