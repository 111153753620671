  <!-- <select [(ngModel)]="modal_type">
    <option *ngFor="let mensaje of default_msgs; let i_mesaje = index" [value]="i_mesaje">
      Cartel {{i_mesaje}}
    </option>
  </select> -->
  <div class="modal" *ngIf="modal_type != null">
    <div class="modal__container _msg_">
      <div class="modal__header" *ngIf="default_msgs[modal_type].title!=null">
        <p [innerHTML]="default_msgs[modal_type].title"></p>
      </div>
      <div class="modal__body" [ngClass]="'t-'+modal_type">


        <div class="spinner spinner--button" *ngIf="default_msgs[modal_type].btn==0">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <p class="description" [innerHTML]="default_msgs[modal_type].txt" *ngIf="default_msgs[modal_type].txt!=null">
        </p>

        <div class="more-info" *ngIf="modal_type == 8">
          <input type="checkbox" class="more-info__input" id="more_info_input">
          <label class="more-info__label" for="more_info_input">Más información...</label>
          <p class="more-info__description">Este error puede deberse a que la hora o fecha de su ordenador no sean
            correctas
          </p>
        </div>


      </div>
      <div class="modal__footer" *ngIf="default_msgs[modal_type].btn!=0 && modal_type != 51 && modal_type != 52 && modal_type != 53">
        <a class="button btn btn__cancel" role="button" (click)="responseAction(false)"
          *ngIf="default_msgs[modal_type].btn==2">Cancelar</a>
        <a class="button btn btn__light-blue" role="button" (click)="responseAction(true)">Aceptar</a>
      </div>
      <div class="modal__footer" *ngIf="modal_type == 51 || modal_type == 52">
        <a *ngIf="default_msgs[modal_type].btn==2" class="button btn btn__dark-blue" role="button" (click)="responseAction(2)">Eliminar para todos</a>
        <a class="button btn btn__cancel" role="button" (click)="responseAction(0)">Cancelar</a>
        <a class="button btn btn__dark-blue" role="button" (click)="responseAction(1)">Eliminar para mí</a>
      </div>
      <div class="modal__footer" *ngIf="modal_type == 53">
        <a *ngIf="default_msgs[modal_type].btn==2" class="button btn btn__dark-blue" role="button" (click)="responseAction(2)">Agregar como usuario</a>
        <a class="button btn btn__cancel" role="button" (click)="responseAction(0)">Cancelar</a>
        <a class="button btn btn__dark-blue" role="button" (click)="responseAction(1)">Agregar como administrador</a>
      </div>
    </div>
  </div>
