import { Component, OnInit, Input, SimpleChanges, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import { ClassroomService } from '../../services/classroom.service';
import { CommunityService } from '../../services/community.service';
import { ModalDialogComponent } from '../../shared-classroom/modal-dialog/modal-dialog.component';
import { SortOpinionByClosestDate } from '../../pipes/community.pipe';
import { AppService } from 'src/app/app.service';


@Component({
    selector: 'item-list',
    templateUrl: './item-list.component.html',
    styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {
    constructor(
        public _classroomService: ClassroomService,
        public _communityService: CommunityService,
        private _appService: AppService
    ) {

    }

    ngOnInit() {
        if (this.reference) this.item_selector_id = 'com_' + this.reference.charAt(0) + '_';
    }

    @Input() items;
    copy_items: any;
    @Input() reference;
    @Input() items_type;
    @Output() updateJson: EventEmitter<any> = new EventEmitter<any>();


    public actions: boolean = false; //será true cuando el usuario esté logeado, es decir si el usuario tiene permiso para realizar acciones

    public current_user_data: any = null;//data del usuario que se encuentra autenticado


    children: any; //comment's/contribution's children

    opinions_: any = { //respecto a los comentarios de aportes/preguntas/respuestas
        data: [],//Todos los comentarios de aporte/pregunta
        new: {},//Nuevo comentario de un aporte/pregunta
        edit: {},//Comentario de un aporte/pregunta a editar
        parent: null,//data del aporte/pregunta padre        
        ofAnswers: [],//Comentarios de respuesta de una pregunta
        OpenAnswer: null,//respuesta abierta
        ofAnswersEdit: {},//comentario de la respuesta a editar
        ofAnswersNew: {//nuevo comentario de la respuesta
            description: '',
            children_comment_id: null,
            url: ''
        }
    };

    element_edited = null;
    item_selector_id: string; //id selector para el item según tab seleccionada "lesson", "course", "importants"    

    file_formats_toview = ['doc', 'docx', 'ppt', 'pptx', 'xsl', 'xlsx', 'pdf', 'png', 'jpg', 'gif'];


    empty_text =
        {
            l: `Actualmente esta clase no posee preguntas. <br>  Sé el primero en realizar una pregunta`,
            c: `Actualmente este curso no posee preguntas. <br>  Sé el primero en realizar una pregunta.`,
            na: `Actualmente no hay preguntas sin responder.`,
            mq: `Usted no ha realizado preguntas.`,
            rec: `Actualmente esta clase no posee preguntas recientes.`,
            pop: `Actualmente esta clase no posee populares.`,
            mqs: `Usted no ha realizado preguntas.`,
        };

    fileds = {
        files: `FilesComments`,
        like: 'Voto',
        data: 'data_comments',
    }

    public urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

    comment_participate_component = null; //data del comment que está siendo editado 
    children_participate_component = null; //data del children que está siendo creado, editado
    children_type_case_participate = null; //tipo de caso en el que se está creando el comment (nuevo comment, nueva participacion, nuevo children, etc...)
    comment_type_case_participate = null; //tipo de caso en el que se está creando el comment (nuevo comment, nueva participacion, nuevo children, etc...)

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.   

        if (changes.hasOwnProperty('items') && this.items != null) {

            // Si los ordeno por fecha cada vez que se actualiza el scroll se está organizando dife|rente
            this.copy_items = JSON.parse(JSON.stringify(this.items));

            this.hasMorePages = true;

            if (this._communityService.full_view.state && this._communityService.config['comments'] == 'full') {

                let item_ = this._communityService.data_comments[this.items_type].findIndex(item => item['Comment'].id == this._communityService.full_view.indexItem);
                if (item_ != null && item_ != -1) {
                    this.items = [JSON.parse(JSON.stringify(this._communityService.data_comments[this.items_type][item_]))];
                }
            }


        }



        this.actions = this._classroomService.data.actions;

        if (this._classroomService.data.certification != null && this._classroomService.data.certification.User != null) {

            this.current_user_data = this._classroomService.data.certification.User;

        }

    }


    giveLike: boolean = false;
    giveChildrenLike: boolean = false;

    //parentId: string;
    parentIndex = null;
    getChildren(data, index) {

        this.parent_id_view_answer = null;

        this._communityService.answers[this.reference] = null;

        let parent_id = (this._communityService.answers_parentId == data.Comment.id ? null : data.Comment.id);
        this.parentIndex = index;


        if (this._communityService.answers_parentId != data.Comment.id) {
            this._communityService.answers_parentId = parent_id;
            this._communityService.getChildren(data.Comment.id).subscribe((children: any) => {

                setTimeout(() => {
                    this._communityService.answers[this.reference] = children;
                    data.Comment.count_children = this._communityService.answers[this.reference].length;
                }, 1000);
            });
        } else {
            this._communityService.answers_parentId = parent_id;
        }

    }


    deleted_item_id: string;
    comment_to_edit = null;
    children_to_edit = null;
    comments_id_full_view = [];
    parent_id_view_answer: string;

    sending_comment_voto;
    sending_children_voto;

    doAction(event) {

        if (this.actions || (!this.actions && event.ev == "getAnswerOpinions")) {
            //Si ya inicio sesión
            // let comments_;

            // comments_ = JSON.parse(JSON.stringify(this._communityService.data.comments));

            switch (event.ev) {

                case 'all-answers':

                    if (this.parent_id_view_answer == event.comment.Comment.id) {
                        this.parent_id_view_answer = null;
                    } else {
                        this.parent_id_view_answer = event.comment.Comment.id;
                    }

                    break;

                case 'full-view':

                    if (this.comments_id_full_view.includes(event.comment.Comment.id)) {
                        this.comments_id_full_view.splice(this.comments_id_full_view.indexOf(event.comment.Comment.id), 1);
                    } else {
                        this.comments_id_full_view.push(event.comment.Comment.id);
                    }


                    break;

                case 'edit':
                case 'answer':
                case 'editAnswer':
                    this.element_edited = event.comment;
                    event.comment.Event = event.ev;

                    if (this.reference == 'notification') {
                        event.comment['Parent'] = JSON.parse(JSON.stringify(this.items[0].Comment));
                    }

                    // this._communityService.openParticipate({ item: event.comment, component: 'comments' });
                    if (this.children_participate_component != null && ((event.comment.Comment && this.children_participate_component.id == event.comment.Comment.id) || (event.comment.ChildrenComment && this.children_participate_component.id == event.comment.ChildrenComment.id))) {

                        // this.children_participate_component = null;

                    } else {


                        // (event.ev == 'answer' || event.ev == 'edit') && 
                        if (this.children_participate_component == null || ((this.children_participate_component != null && event.comment.hasOwnProperty('Comment')) && (this.children_participate_component.id != event.comment.Comment.id))) {



                            if (event.ev == 'edit') {
                                this.comment_participate_component = event.comment.Comment;
                                this.comment_type_case_participate = event.ev;
                                this.comment_to_edit = event.comment;
                            }

                            if (event.ev == 'answer' && this.children_participate_component == null || (this.children_participate_component != null && (this.children_participate_component.id != event.comment.Comment.id))) {

                                if (event.comment['Comment'].count_children > 0) {

                                    if (this._communityService.answers_parentId == event.comment.Comment.id) {
                                        //Ya están cargados los children de este comment, no debería volver a cargarlos
                                    } else {
                                        this.getChildren(event.comment, event.i_item);
                                    }

                                }

                            }

                            if (event.ev == 'answer' || event.ev == 'editAnswer') {
                                this.children_participate_component = event.comment.Comment || event.comment.ChildrenComment;

                                this.children_to_edit = event.comment;
                            }

                            if (event.ev == 'edit' || event.ev == 'editAnswer') {

                                // this.current_user_data = event.comment.User;

                            }

                            this.children_type_case_participate = event.ev;

                        }
                    }


                    if (event.ev == 'answer') {
                        setTimeout(() => {

                            // event.parentElement.getBoundingClientRect();
                            event.parentElement.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "end"
                            });

                        }, 1000);

                    }

                    break;
                case 'delete':
                case 'delete_answer':

                    this.deleted_item_id = event.ev == 'delete' ? 'comment-' + event.item.id : 'answer-' + event.item.id;

                    setTimeout(() => {

                        let delete_url = '';
                        const { clase_id, source_id, user_id, curso_id, comment_id } = event.item;

                        if (event.ev == 'delete') {
                            let course_id = event.item.hasOwnProperty('curso_id') ? curso_id : this._classroomService.data.course.id;
                            event.url = '/comments_delete.json';
                            this.modal_dialog.modal_type = 11;
                            // delete_url = `/comments/${event.item.id}`;


                            let date = event.item.fecha.replace(' ', 'T');
                            // https://api.edutin.com/b/d/comments/3135959/2631/409342540/133292/"2021-09-21T19:08:12"
                            delete_url = `/comments/${user_id}/${course_id}/${clase_id}/${source_id}/"${date}"`;


                        }
                        if (event.ev == 'delete_answer') {

                            let course_id = (this._classroomService.data.course != null) ? this._classroomService.data.course.id : this.items[0].Comment.curso_id;

                            this.modal_dialog.modal_type = 14;
                            // delete_url = `/children_comments/${event.item.id}`;
                            let date = event.item.date_creation.replace(' ', 'T');
                            // https://api.edutin.com/b/d/children_comments/2631/314025/"2022-06-15T15:35:35"/3135959
                            delete_url = `/children_comments/${course_id}/${comment_id}/"${date}"/${user_id}`;

                        }

                        event.id = event.item.id;
                        // event.id = '1020985847';

                        let __response = this.modal_dialog.response.subscribe(response => {

                            if (response) {
                                this.modal_dialog.modal_type = 6;//Mostrar eliminando----


                                this._communityService.deleteItem(delete_url).subscribe((deleted: any) => {

                                    if (deleted.status) {

                                        if (event.ev == 'delete') {

                                            this.updateJSON_Delete(event.item);

                                        }

                                        if (event.ev == 'delete_answer') {
                                            let tmp_children = JSON.parse(JSON.stringify(this._communityService.answers[this.reference]));
                                            tmp_children.splice(event.item.index, 1);
                                            tmp_children.forEach((answer, index) => {
                                                answer.ChildrenComment.index = index;
                                            });
                                            this._communityService.answers[this.reference] = JSON.parse(JSON.stringify(tmp_children));

                                            let item_index = this._communityService.data_comments[this.items_type].findIndex(item => item.Comment.id == event.item.comment_id);

                                            this._communityService.data_comments[this.items_type][item_index].Comment.count_children = tmp_children.length;

                                            this.updateJSON(this._communityService.data_comments[this.items_type][item_index]);

                                        }

                                        __response.unsubscribe();
                                    }

                                });

                            } else {
                                this.deleted_item_id = null;
                                this.modal_dialog_.state = false;
                            }

                            __response.unsubscribe();
                        });


                        setTimeout(() => {

                            let modal_ = document.querySelector('.modal__container._msg_');
                            modal_.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center"
                            });

                        }, 250);

                    }, 250);
                    break;
                case 'delete_opinion':
                case 'delete_answer_opinion':

                    this.deleted_item_id = 'answer-' + event.opinion.children_comment_id;
                    // this.modal_dialog_.state = true;
                    this.opinion_modal_dialog_.state = true;

                    setTimeout(() => {

                        this.modal_dialog.modal_type = 13;

                        let response_ = this.modal_dialog.response.subscribe(response => {

                            if (response) {
                                this.modal_dialog.modal_type = 6;//Mostrar eliminando....

                                event.url = '/opinions_comments_delete.json';
                                event.id = event.opinion.id;

                                let deleted_url = `/opinions/${event.opinion.id}`;

                                // https://37lyp7qtzf.execute-api.us-east-1.amazonaws.com/dev/opinions/1

                                this._communityService.deleteItem(deleted_url).subscribe((opinion_deteled => {

                                    //Falta poner mensaje de error en caso de que falle la eliminación
                                    if (event.ev == 'delete_answer_opinion' && opinion_deteled.status) {
                                        let answer_opinions = JSON.parse(JSON.stringify(this.opinions_.ofAnswers));

                                        answer_opinions.splice(answer_opinions.findIndex(opinion => opinion.Opinion.id == event.opinion.id), 1);

                                        answer_opinions = new SortOpinionByClosestDate().transform(answer_opinions);

                                        if (answer_opinions.length > 0) {
                                            answer_opinions.forEach((opinion, index) => {
                                                opinion.Opinion.index = index;

                                                if (index == (answer_opinions.length - 1)) {
                                                    this.opinions_.ofAnswers = JSON.parse(JSON.stringify(answer_opinions));

                                                    this._communityService.answers[this.reference][this.opinions_.OpenAnswer.index].ChildrenComment.count_opinions = this.opinions_.ofAnswers.length;
                                                }
                                            });
                                        } else {
                                            this.opinions_.ofAnswers = JSON.parse(JSON.stringify(answer_opinions));

                                            this._communityService.answers[this.reference][this.opinions_.OpenAnswer.index].ChildrenComment.count_opinions = this.opinions_.ofAnswers.length;
                                        }

                                    }

                                    this.deleted_item_id = null;
                                    this.modal_dialog_.state = false;
                                    response_.unsubscribe();

                                }));


                            } else {
                                this.deleted_item_id = null;
                                this.opinion_modal_dialog_.state = false;
                            }

                        });

                    }, 200);

                    break;


                case 'getOpinions':

                    this.opinions_.data = [];
                    event.data.index_in_items = event.index;
                    this.opinions_.parent = (this.opinions_.parent != null && this.opinions_.parent.id == event.data.id) ? null : event.data;

                    // this.opinions_.parent.index_in_items = event.index;

                    let url = '/opinions_comments.json?parent_id=';

                    this.opinions_.new = {
                        description: '',
                        url: '/opinions_comments_add.json'
                    }
                    this.opinions_.new['comment_id'] = event.data.id;

                    if (event.data.count_opinions > 0 && this.opinions_.parent != null) {
                        this._communityService.getOpinions(event.data.id).subscribe((opinions_: any) => {
                            // comments.Data = new SortOpinionByClosestDate().transform(comments.Data);                          

                            opinions_.Data.forEach((opinion, index) => {

                                opinion.Opinion.index = index;

                                if (index == (opinions_.Data.length - 1)) {//Cuando termine de recorrer el array, haga lo siguiente                                    

                                    this.opinions_.data = JSON.parse(JSON.stringify(opinions_.Data));

                                    let item_index = this._communityService.data_comments[this.items_type].findIndex(item => item['Comment'].id == event.data.id);

                                    this._communityService.data_comments[this.items_type][item_index]['Comment'].count_opinions = this.opinions_.data.length;

                                    this.updateJSON(this._communityService.data_comments[this.items_type][item_index]);

                                }
                            });


                        });
                    }
                    setTimeout(() => {
                        event.element.scrollIntoView({ behavior: 'smooth' });
                    }, 205);

                    break;
                case 'addOpinion':

                    this.modal_dialog_.state = true;
                    this.modal_dialog.modal_type = 1;

                    break;
                case 'getAnswerOpinions':

                    this.opinions_.ofAnswers = [];
                    this.opinions_.OpenAnswer = (this.opinions_.OpenAnswer != null && this.opinions_.OpenAnswer.id == event.data.id) ? null : event.data;


                    (this.opinions_.OpenAnswer)

                    this.opinions_.ofAnswersNew = {
                        description: '',
                        children_comment_id: event.data.id,
                        url: '/opinions_comments_add.json'
                    }

                    if (event.data.count_opinions > 0 && this.opinions_.OpenAnswer != null) {
                        this._communityService.getOpinions(event.data.id).subscribe((opinions_: any) => {

                            opinions_.forEach((opinion, index) => {

                                opinion.Opinion.index = index;

                                if (index == (opinions_.length - 1)) {

                                    this.opinions_.ofAnswers = JSON.parse(JSON.stringify(opinions_));
                                    this._communityService.answers[this.reference][this.opinions_.OpenAnswer.index].ChildrenComment.count_opinions = opinions_.length;

                                }
                            });


                        });
                    }

                    break;
                case 'addAnswerComment':

                    let opinion_data_add = JSON.parse(JSON.stringify(event.comment));

                    let url_post = opinion_data_add.url;

                    opinion_data_add['user_id'] = this.current_user_data.id;
                    delete opinion_data_add.url;

                    // if (user_id != null && this.current_user_data != null && (this.current_user_data.id != user_id)) {
                    //     this._communityService.saveSuggestion(this.current_user_data.id, user_id, 'like');
                    // }

                    this._communityService.addOpinion(opinion_data_add, url_post).subscribe((opinion_: any) => {
                        if (opinion_.status) {

                            if (event.comment.id != null) {
                                //opinion edited

                                this.opinions_.ofAnswers[event.comment.index].Opinion.description = opinion_.data.description;
                                this.opinions_.ofAnswersEdit = {};


                            } else {
                                //new opinion

                                let answer_opinions = JSON.parse(JSON.stringify(this.opinions_.ofAnswers));

                                let tmp_comment = {
                                    User: this.current_user_data,
                                    Opinion: opinion_.data,
                                    // Data: opinion_.data 
                                }
                                this.opinions_.ofAnswersNew.description = this.opinions_.ofAnswersEdit.description = '';
                                answer_opinions.push(tmp_comment);
                                answer_opinions.forEach((opinion, index) => {

                                    opinion.Opinion['index'] = index;

                                    if (index == (answer_opinions.length - 1)) {
                                        answer_opinions = new SortOpinionByClosestDate().transform(answer_opinions);
                                        this.opinions_.ofAnswers = JSON.parse(JSON.stringify(answer_opinions));

                                        this._communityService.answers[this.reference][this.opinions_.OpenAnswer.index].ChildrenComment.count_opinions = this.opinions_.ofAnswers.length;

                                    }

                                });




                            }

                        }
                    });
                    break;

                case 'giveLike':

                    if (!this.giveLike) {

                        this.giveLike = true;
                        this.sending_comment_voto = event.data['Comment'].id;

                        const _comment_ = {

                            setResponse: (valor, data) => {

                                if (this.reference != 'notification') {

                                    let item_index = this._communityService.data_comments[this.items_type].findIndex(item => item['Comment'].id == event.data['Comment'].id);

                                    this._communityService.data_comments[this.items_type][item_index]['Comment'].like = parseInt(this._communityService.data_comments[this.items_type][item_index]['Comment'].like) + valor;

                                    this._communityService.data_comments[this.items_type][item_index].Votos = data;

                                    this.updateJSON(this._communityService.data_comments[this.items_type][item_index]);


                                } else {

                                    let item_index = this.items.findIndex(item => item['Comment'].id == event.data['Comment'].id);

                                    this.items.like = parseInt(this.items[item_index]['Comment'].like) + valor;

                                    this.items[item_index].Votos = data;

                                    this.updateJSON(this.items[item_index]);


                                }


                                this.giveLike = false;
                            }

                        }


                        const { Votos } = event.data;

                        if (Votos != null && Object.keys(Votos.length > 0) && (Votos[0] != null && Votos[0].id != null && Votos[0].id != '')) {

                            // let deleted_url = `/comments/${Votos[0].comment_id}/votes/${Votos[0].id}`;

                            let course_id = event.data.Comment.curso_id || this._classroomService.data.course.id;
                            const { user_id, clase_id, source_id, fecha } = event.data.Comment;
                            let date = fecha.replace(' ', 'T');


                            let deleted_url = `/comments/${user_id}/${course_id}/${clase_id}/${source_id}/"${date}"/votes/${Votos[0].id}`;

                            this._communityService.deleteItem(deleted_url).subscribe(deleted => {

                                _comment_.setResponse(-1, []);

                            });

                        } else {
                            //Toca crear el Voto

                            let data = {
                                tipo: 1,
                                user_id: this.current_user_data.id
                            };




                            let url_post_voto = '';

                            if (event.data.hasOwnProperty('Comment')) {

                                let course_id = event.data.Comment.curso_id || this._classroomService.data.course.id;
                                const { user_id, clase_id, source_id, fecha } = event.data.Comment;
                                let date = fecha.replace(' ', 'T');
                                url_post_voto = `/comments/${user_id}/${course_id}/${clase_id}/${source_id}/"${date}"/votes`;


                                if (user_id != null && this.current_user_data != null && (this.current_user_data.id != user_id)) {
                                    this._communityService.saveSuggestion(this.current_user_data.id, user_id, 'like');
                                }

                            }



                            this._communityService.postItem(url_post_voto, data).subscribe(resp_voto => {
                                if (resp_voto.status) {
                                    _comment_.setResponse(1, [resp_voto.data]);
                                }
                            });

                        }

                    }
                    break;

                case 'giveAnswerLike':
                    //===============new children voto======================

                    if (!this.giveChildrenLike) {
                        this.giveChildrenLike = true;
                        this.sending_children_voto = event.data['ChildrenComment'].id;

                        const _comment_children = {

                            setResponse: (valor, data) => {

                                let item_index = this._communityService.answers[this.reference].findIndex(item => item['ChildrenComment'].id == event.data['ChildrenComment'].id);
                                this._communityService.answers[this.reference][item_index]['ChildrenComment'].like = parseInt(this._communityService.answers[this.reference][item_index]['ChildrenComment'].like) + valor;
                                this._communityService.answers[this.reference][item_index].Votos = data;
                                this.giveChildrenLike = false;

                            }

                        }


                        const { Votos } = event.data;

                        let course_id = event.course_id || this._classroomService.data.course.id;

                        const { user_id, date_creation, comment_id } = event.data.ChildrenComment;
                        let date = date_creation.replace(' ', 'T');

                        if (Votos != null && Object.keys(Votos.length > 0) && (Votos[0] != null && Votos[0].id != null && Votos[0].id != '')) {
                            // https://api.edutin.com/b/d/children_comments/2631/314025/"2022-06-15T15:35:35"/3135959/votes/36891
                            // let deleted_url = `/children_comments/${Votos[0].children_comment_id}/votes/${Votos[0].id}`;
                            let deleted_url = `/children_comments/${course_id}/${comment_id}/"${date}"/${user_id}/votes/${Votos[0].id}`;

                            this._communityService.deleteItem(deleted_url).subscribe(deleted => {
                                _comment_children.setResponse(-1, []);
                            });

                        } else {
                            //Toca crear el Voto
                            let data = {
                                tipo: 1,
                                user_id: this.current_user_data.id
                            };

                            let url_post_voto = `/children_comments/${event.data['ChildrenComment'].id}/votes`;


                            if (event.data.hasOwnProperty('ChildrenComment')) {
                                url_post_voto = `/children_comments/${course_id}/${comment_id}/"${date}"/${user_id}/votes`;

                                if (user_id != null && this.current_user_data != null && (this.current_user_data.id != user_id)) {
                                    this._communityService.saveSuggestion(this.current_user_data.id, user_id, 'like');
                                }


                            }


                            this._communityService.postItem(url_post_voto, data).subscribe(resp_voto => {
                                if (resp_voto.status) {
                                    _comment_children.setResponse(1, [resp_voto.data]);
                                }
                            });

                        }


                    }

                    break;

                case 'open-file':
                    if (this.file_formats_toview.includes(event.file.file_ext)) {

                        this._classroomService.switcher.file = true;
                        this._classroomService.data.file = event.file;
                    }

                    break;

            }


        }//end if actions 

        if (!this.actions) {

            this.modal_dialog_.state = true;


            setTimeout(() => {

                this.modal_dialog.modal_type = 48;


                setTimeout(() => {

                    let modal_ = document.querySelector('.modal__container._msg_');
                    modal_.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center"
                    });

                }, 250);


                let __response = this.modal_dialog.response.subscribe(response => {
                    this.modal_dialog_.state = false;
                    __response.unsubscribe();
                });

            }, 250);
        }
    }



    updateJSONFromParticipateComponent($event) {

        if (this.reference == 'notification') {

            if ($event.ev == "edit-comment") {
                this.items[0]['FilesComments'] = JSON.parse(JSON.stringify($event.item_edit.FilesComments));
            }


            if ($event.ev == "new-answer") {


                let newAnswer: any = {
                    ChildrenComment: {},
                    // FilesComments: JSON.parse(JSON.stringify($event.item.extra_data.files_comments)),
                    User: JSON.parse(JSON.stringify(this._appService.user_data)),
                    VotosChild: [],
                }

                for (const prop in $event.item) {
                    if (typeof $event.item[prop] != 'object') {
                        newAnswer.ChildrenComment[prop] = $event.item[prop];
                    }
                }


                newAnswer.ChildrenComment['files_comments'] = JSON.parse(JSON.stringify($event.item.extra_data.files_comments));
                //   let tmp_comments = JSON.parse(JSON.stringify(this._communityService.data_comments[ref]));

                this._communityService.answers['notification'].unshift(newAnswer);
            }


            if ($event.ev == "edit-answer") {

                if (this._communityService.answers['notification'] != null && this._communityService.answers['notification'].length > 0) {
                    this._communityService.answers['notification'][$event.item_edit.index].ChildrenComment.files_comments = $event.item_edit.Files;
                }

            }

        } else {

            this.updateJson.emit($event)

        }

    }


    updateJSON(item) {

        let arr_ = ['source', 'no_answers', 'my_questions', 'search', 'recent', 'popular', 'my_questions_sidebar'];

        if (this.reference != 'notification') {

            arr_.forEach(ref => {
                if (this._communityService.data_comments[ref] != null && this._communityService.data_comments[ref].length > 0) {

                    let item_index = this._communityService.data_comments[ref].findIndex(comment => comment['Comment'].id == item['Comment'].id);
                    //SI el item se encuentra en el array original

                    if (item_index != null && item_index != -1) {
                        let tmp_course = JSON.parse(JSON.stringify(this._communityService.data_comments[ref]));
                        tmp_course[item_index] = JSON.parse(JSON.stringify(item));

                        this._communityService.data_comments[ref] = JSON.parse(JSON.stringify(tmp_course));
                    }
                }

            });

        } else {

            this.items[0] = JSON.parse(JSON.stringify(item));

        }

    }


    updateJSON_Delete(item) {
        let arr_ = ['source', 'no_answers', 'my_questions'];

        arr_.forEach(ref => {

            //SI el item se encuentra en el array original                    
            let item_index = this._communityService.data_comments[ref].findIndex(comment => comment['Comment'].id == item.id);

            if (item_index != null && item_index != -1) {
                this._communityService.data_comments[ref].splice(item_index, 1);
                this._communityService.commentsPages[ref] = this._communityService.commentsPages[ref] - 1;

            }

        });


    }


    @HostListener('click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        let element = <Element>event.target;
        if (!element.matches('.icon-ellipsis-h') && !element.matches('.dropdown-input')) {
            var dropdowns = document.getElementsByClassName("dropdown-menu-options");
            for (let i = 0; i < dropdowns.length; i++) {
                var input = dropdowns[i].querySelector('input');
                input.checked = false;
            }
        }
    }




    hasMorePages: boolean = true;
    loadingComments: boolean = false;

    pagination() {

        let X = {

            setResponse: (comments) => {

                if (comments.length > 0) {
                    let tmp_comments = this._communityService.data_comments[this.items_type].concat(comments)
                    this._communityService.data_comments[this.items_type] = (JSON.parse(JSON.stringify(tmp_comments)));
                    // this._communityService.commentsPages[this.items_type] += 1;
                } else {
                    this.hasMorePages = false;
                }
                this.loadingComments = false;

            }
        }


        if (!this._communityService.full_view.state) {

            this.loadingComments = true;
            this._communityService.getComments(this.items_type, this._classroomService.data.source, this._communityService.commentsPages[this.items_type].LastEvaluatedKey).subscribe((commentsResponse: any) => {

                if (commentsResponse != null && commentsResponse.length > 0) {
                    commentsResponse.forEach((comment_, index) => {
                        comment_.Comment.index = index;

                        if (index == (commentsResponse.length - 1)) {
                            X.setResponse(commentsResponse);
                        }
                    });
                } else {
                    X.setResponse(commentsResponse);
                }


            });

        }


    }



    onRightClick(ev) {
        return false;
    }


    showImage(img) {
        // this._classroomService.data.img_viewer_src = 'https://s3.amazonaws.com/' + file.file_url;
        this._classroomService.data.img_viewer_src = img.cloneNode(true);
        this._classroomService.switcher.img_viewer = true;
    }



    openExternalLink(link) {
        if (!link.includes('https://') && !(/^http:\/\//.test(link))) {
            link = "http://" + link;
            alert('link: ' + link);
        }


        window.open(link, '_blank');
    }



    modal_dialog_ = {
        state: false,
        type: null
    };

    opinion_modal_dialog_ = {
        state: false,
        type: null
    };


    @ViewChild(ModalDialogComponent) modal_dialog: ModalDialogComponent;
    showModalDialog(type) {
        this.modal_dialog_.state = true;
        this.modal_dialog.modal_type = 9;


        let __response = this.modal_dialog.response.subscribe(response => {
            this.modal_dialog_.state = false;
            __response.unsubscribe();
        });
    }


    isLoading: boolean = null;
    downloadFile(file) {
        this.isLoading = file.id;
        // https://s3.amazonaws.com/
        fetch(`${file.file_url}`)
            .then(r => r.blob())
            .then(blob => {
                // otherwise only Chrome works like it should
                var newBlob = new Blob([blob], {
                    type: "application/octet-stream"
                })

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                const nav = (window.navigator as any);
                if (nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(newBlob);
                }

                // For other browsers: 
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);

                var link = document.createElement("a");
                link.download = `archivo.${file.file_ext}`;
                link.href = data;
                link.target = '_blank';
                link.click();

                setTimeout(() => {
                    this.isLoading = null;
                }, 1000);

            }).catch(() => {
                this.isLoading = null;
            })

    }



    closeParticipateCommentComponent(id) {
        this.comment_participate_component = null;
    }

    closeParticipateChildrenComponent() {
        this.children_participate_component = null;
    }


    @Output() goToSource: EventEmitter<any> = new EventEmitter<any>();
    openUploadFromOtherSource(item) {

        item['Clase'] = item.Clases[0];
        item['Source'] = { id: item.Comment.source_id };

        if (item.Source.id != this._classroomService.data.source.id) {
            // this.openUpload(item);
            this.goToSource.emit({ event: 'go_to_source_from_notes', item: item });
        }


    }


    openChildren(event) {

        if (event.event.ev == "new-answer" && (this._communityService.answers[this.reference] == null || this._communityService.answers[this.reference].length == 0)) {

            let newAnswer: any = {
                ChildrenComment: {},
                FilesComments: JSON.parse(JSON.stringify(event.event.item.extra_data.files_comments)),
                User: this.current_user_data,
                Votos: [],
            }

            for (const prop in event.event.item) {
                if (typeof event.event.item[prop] != 'object') {
                    newAnswer.ChildrenComment[prop] = event.event.item[prop];
                }
            }

            if (this._communityService.answers[this.reference] != null && (this._communityService.answers_parentId != null && this._communityService.answers_parentId === newAnswer.ChildrenComment.comment_id)) {

                this._communityService.answers[this.reference].unshift(newAnswer);

            } else {

                this._communityService.answers_parentId = newAnswer.ChildrenComment.comment_id;
                this._communityService.answers[this.reference] = [newAnswer];

            }


        }

    }

}