<!-- _appService.url_route == 'classroom' &&  -->
<div class="content-academy" [ngClass]="{'dark':_appService.darkTheme}">
  <comments-modal *ngIf="_appService.comment_modal_.state"></comments-modal>

  <div class="alert" *ngIf="_appService.display_verification_alert && !user_routes.includes(_appService.url_route)">
    <div>
      <p>
        Es necesario <a (click)="verifyAuthenticatedUserEmail()">verificar su correo electrónico</a>
      </p>
    </div>

    <span class="icon-close" (click)="_appService.display_verification_alert=false"></span>
  </div>

  <div class="app-content">

    <popup-msg *ngIf="_appService.show_desktop_notification" [config]="_appService.popUpData"
      (response)="subscribe_user_notification_push($event)"></popup-msg>

    <nav-bar *ngIf="_appService.loadNavBar" [ngClass]="{'display__nav-bar': _appService.display_navbar}"
      (open_live_chat)="openLiveChat($event)"></nav-bar>
    <router-outlet></router-outlet>
    <footer *ngIf="_appService.loadFooter"></footer>

    <div class="spinner" *ngIf="_appService.display_spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

  </div>


  <chat-room class="over" *ngIf="_appService.chatroom_state" [chat_user]="chat_user || _appService.chat_user"
    [input_state]="_appService.conversations[_appService.current_conversation_index]?.input_state"></chat-room>
  <!-- <live-chat></live-chat>   -->

  <div class="live-chat-group" [ngClass]="_appService.url_route">
    <template #liveChat></template>
  </div>

</div>