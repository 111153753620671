<div class="new-file"
  [ngClass]="{'img-file': (type == 'image' && file.src != null), 'text': type=='text', 'new':isNew}">
  <!-- *ngIf="item_type!='activity' || (item_type=='activity' && (type == 'link' || type=='text' || type=='code'))" -->

  <button class="btn icon-delete" (click)="close()"
    *ngIf="(type!='text' && index!=0) || (type=='text' && index!=0) || (type!='text' && index==0) "></button>
  <div class="thumbnail" *ngIf="type != 'link' && type!='text' && type!='code'"
    [ngStyle]="{'max-width':  bucket.path == 'Uploads' ? '400px' : null,  'min-height': (file.src == null) ? '60px' : null }">

    <img [src]="file.src | safePipe"
      *ngIf=" (file.src != null && file.file_ext!=null && (file.type == 'image' || (file.type =='file' && img_formats.includes(file.file_ext)))); else showIcon"
      width="100%">

    <ng-template #showIcon>
      <i [ngClass]="{'icon-image' : type == 'image', 'icon-attached' :type == 'file', 'icon-video-source': type == 'video'}"
        [class.active]="file.file_url!=null" *ngIf="!uploading_file"></i>
    </ng-template>


    <div class="spinner spinner--button" *ngIf="uploading_file">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div *ngIf="type != 'link' && type!='text' && type!='code'">
    <input type="file" [id]="unique_random_id" (change)="addfile($event)" [accept]="validAcceptTypes[type]"
      [disabled]="uploading_file" />
    <!-- accept="image/*,.pdf,.xls,.doc,.docx,.pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,text/html,video/,.rar*,.zip" -->
    <label class="btn" [for]="unique_random_id">

      <span *ngIf="!uploading_file">
        {{ 
          ( file.file_url != null )  ? 'Reemplazar ' + file_label[type] : 'Seleccionar ' + file_label[type] 
        }}
      </span>

      <div class="spinner spinner--button" *ngIf="uploading_file">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </label>
    <span *ngIf=" file.file_url == null; else else_file_name">
      {{type == 'image' ? 'Archivos de hasta 5MB en prácticamente cualquier formato gráfico' : type == 'video' ? 'Video de hasta 250MB en formato .mp4' : 'Archivos de hasta 250MB en prácticamente cualquier formato'}}
    </span>
    <ng-template #else_file_name>
      <span *ngIf="uploading_file">archivo.{{file.file_ext}}</span>
      <span class="uploaded">
        <i class="icon-tick-outline"></i>
        {{type == 'image' ? 'Imagen subida ' : type == 'video' ? 'Video subido ' : 'Archivo subido '}} con éxito
      </span>
    </ng-template>

  </div>
  <div class="url" *ngIf="type == 'link'">
    Añade la dirección de Url o enlace
    <input type="text" placeholder="https://Wikipedia, Blogs, o cualquier otro sitio web..." [(ngModel)]="file.link"
      (ngModelChange)="setTextContent()" [tabindex]="index">

  </div>


  <!-- text-editor -->
  <div class="text-editor" *ngIf="type=='text'">
    <!-- <div class="toolbar">
      <button class="btn tool-items fa fa-underline" (click)="formatText('underline')"></button>
      <button class="btn tool-items fa fa-italic" (click)="formatText('italic')"></button>
      <button class="btn tool-items fa fa-bold" (click)="formatText('bold')"></button>
      <button class="btn tool-items fa fa-align-center" (click)="formatText('justifyCenter')"></button>
      <button class="btn tool-items fa fa-align-left" (click)="formatText('justifyLeft')"></button>
      <button class="btn tool-items fa fa-align-right" (click)="formatText('justifyRight')"></button>
    </div> -->

    <div class="center">
      <div class="editor_text" [ngClass]="{'activity':item_type=='activity', 'feedback': item_type == 'feedback'}" contenteditable #TextEditor [attr.placeholder]="placeholder_text[item_type]"
        (input)="file.description=$event.target.innerHTML;setTextContent()" (paste)="pasteSomething($event)"
        [tabindex]="index"></div>
    </div>
  </div>
  <!-- end text-editor -->


  <div class="code-editor" *ngIf="type=='code'">
    <div>
      <label for="language">Lenguaje:</label>
      <select id="language" [(ngModel)]="language_selected" (ngModelChange)="select_language()">
        <option [value]="lang.lan" *ngFor="let lang of languages; let i_lang = index">{{lang.name}}
        </option>
      </select>
    </div>


    <div ace-editor [(text)]="file.description" (textChanged)="setTextContent()" [mode]="language_selected"
      [theme]="'tomorrow_night'" [readOnly]="false" [autoUpdateContent]="true" [tabindex]="index">
    </div>
  </div>
</div>


<!-- *ngIf="(item_type=='activity' && (type == 'link' || type=='text' || type=='code'))" -->

<!-- <div class="new-file" *ngIf="item_type=='activity' && (type != 'link' && type!='text' && type!='code')">

  <div>
    <input type="file" [id]="unique_random_id" (change)="addfile($event)" [accept]="validAcceptTypes[type]"
      [disabled]="uploading_file" />
    <label class="btn solution-file" [for]="unique_random_id">
      <span *ngIf="!uploading_file">
        {{ 
          ( file.file_url != null )  ? 'Reemplazar ' + file_label[type] : 'Seleccionar ' + file_label[type] 
        }}
      </span>
      <div class="spinner spinner--button" *ngIf="uploading_file">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </label>
    <span *ngIf=" file.file_url == null; else else_file_name">
    </span>
    <ng-template #else_file_name>
      <span *ngIf="uploading_file">archivo.{{file.file_ext}}</span>
      <span class="uploaded">
        <i class="icon-tick-outline"></i>
        {{type == 'image' ? 'Imagen subida ' : type == 'video' ? 'Video subido ' : 'Archivo subido '}} con éxito
      </span>
    </ng-template>

  </div>

</div> -->