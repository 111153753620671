import { Pipe, PipeTransform, ɵSWITCH_RENDERER2_FACTORY__POST_R3__ } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as countries_data from '../../../assets/countries.json';


@Pipe({ name: 'safePipe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Pipe({ name: 'safeHtmlPipe' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}



@Pipe({ name: 'setTarget_blank' })
export class SetTarget_blank implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(text) {
    return this.sanitizer.bypassSecurityTrustHtml(("" + text).replace(/<a\s+href=/gi, '<a class="title" target="_blank" href='));
  }
}


@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
  transform(username: string, length: string): string {

    username = username.toLocaleLowerCase();
    let split_username_length = username.trim().split(/\s+/).length;
    var un = username.split(' ');
    switch (length) {
      case '1':
        var user_name = un[0];
        break;
      case '2':
        var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
        break;
    }
    let name = '';
    user_name.split(' ').forEach(U => {
      name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
    });

    return name;

  }
}



@Pipe({ name: 'cutChildUserName' })
export class CutChildUserNamePipe implements PipeTransform {
  transform(username: string): string {
    username = username.toLocaleLowerCase();
    var un = username.split(' ');
    return un[0]
  }
}


@Pipe({ name: 'timeTransform' })
export class TimeTransform implements PipeTransform {
  transform(time: number): string {
    var horas = Math.floor(time / 3600);
    var minutos = Math.floor((time - (horas * 3600)) / 60);
    var segundos = Math.round(time - (horas * 3600) - (minutos * 60));
    return (horas < 10 ? '0' : '') + horas + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos;
  }
}


@Pipe({ name: 'toLowerCase' })
export class ToLowerCase implements PipeTransform {
  transform(text: string): string {
    return text.toLowerCase();
  }
}



@Pipe({ name: "sortSourcesByType" })
export class SortSourcesByType implements PipeTransform {
  transform(array: any[], field?: any): any[] {
    array.sort(function (a, b) {
      return (parseInt(a.type) < parseInt(b.type)) ? -1 : (parseInt(a.type) > parseInt(b.type)) ? 1 : 0;
    });
    return array;
  }
}


@Pipe({ name: "sortMyActivityByDay" })
export class SortMyActivityByDay implements PipeTransform {
  transform(array: any[]): any[] {
    array.sort(function (a, b) {
      return (parseInt(a.Day) > parseInt(b.Day)) ? -1 : (parseInt(a.Day) < parseInt(b.Day)) ? 1 : 0;
    });
    return array;
  }
}



@Pipe({ name: 'roleName' })
export class RoleName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case 'premium':
      case 'free':
        return 'estudiante'
        break;
      // return 'estudiante oficial'
      // break;
      case 'propietario':
        return 'profesor'
        break;
      default:
        return role
        break;
    }
  }
}

// @Pipe({ name: "sortByRole" })
// export class SortByRole implements PipeTransform {
//   transform(array: any[]): any[] {
//     if (array) {
//       array.sort((a, b) => {
//         return (a.User.role_type < b.User.role_type) ? -1 : (a.User.role_type > b.User.role_type) ? 1 : 0;
//       });
//       return array;
//     }
//   }
// }

@Pipe({ name: "sortByRole" })
export class SortByRole implements PipeTransform {
  transform(array: any[]): any[] {
    if (array) {
      array.sort((a, b) => {
        return (a.User.name < b.User.name) ? -1 : (a.User.name > b.User.name) ? 1 : 0;
      });
      return array;
    }
  }
}




@Pipe({ name: 'getModuleOrder' })
export class GetModuleOrder implements PipeTransform {
  transform(order): number {
    return parseInt(order) + 1;
  }
}

@Pipe({ name: 'fileTypeName' })
export class FileTypeName implements PipeTransform {

  sources = ['Introducción', 'Desarrollo', 'Discusión', 'Recurso', 'Conclusión', 'Evaluación'];

  transform(type): string {
    return this.sources[parseInt(type)];
  }
}


@Pipe({ name: "sortByPriority" })
export class SortByPriorityPipe implements PipeTransform {
  transform(array: any[]): any[] {
    array.sort((a, b) => {
      return (a.prioridad > b.prioridad) ? -1 : (a.prioridad < b.prioridad) ? 1 : 0;
    });
    return array;
  }
}



@Pipe({ name: "dateFormat" })
export class DateFormat implements PipeTransform {
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  transform(_date: Date): string {
    let date = new Date(_date);
    let day = date.getUTCDate();
    let month = date.getUTCMonth();
    let year = date.getUTCFullYear();
    let date_ = day + ' de ' + this.months[month] + ' del ' + year;
    return date_;
  }
}



@Pipe({ name: "sortByClosestDate" })
export class SortByClosestDate implements PipeTransform {


  transform(array: any): any {
    if (array) {
      array.sort((a: any, b: any) => {
        let date1: Date = new Date(a.Comment.fecha);
        let date2: Date = new Date(b.Comment.fecha);
        return (date1 > date2) ? -1 : (date1 < date2) ? 1 : 0;
      });
      return array;
    }
  }

}


@Pipe({ name: "sortOpinionByClosestDate" })
export class SortOpinionByClosestDate implements PipeTransform {


  transform(array: any): any {
    if (array) {
      array.sort((a: any, b: any) => {
        let date1: Date = new Date(a.Opinion.date);
        let date2: Date = new Date(b.Opinion.date);
        return (date1 > date2) ? -1 : (date1 < date2) ? 1 : 0;
      });
      return array;
    }
  }

}





@Pipe({ name: "avatarUrlPipe" })
export class AvatarURLPipe implements PipeTransform {

  transform(user: any): any {


    if (user) {
      let url;
      let urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
      if (user.image == '') {
        if (user.role == 'propietario') {
          url = urlavatar + 'professor/small_imagen.jpg';
        } else {
          url = urlavatar + 'default/small_imagen.jpg';
        }
      } else {

        if (user.image.includes('https://')) {

          if (user.image.includes('platform-lookasid')) {
            url = urlavatar + 'default/small_imagen.jpg';
          } else {
            url = user.image;
          }

        } else {

          url = urlavatar + user.id + '/small_' + user.image;

        }

      }

      return url;
    }
  }

}


@Pipe({ name: 'typeNameSource' })
export class TypeNameSource implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  sources = ['Introducción', 'Desarrollo', 'Discusión', 'Recurso', 'Conclusión', 'Evaluación'];
  transform(type): string {
    return this.sources[parseInt(type)];
  }

}

@Pipe({ name: 'likePipe' })
export class LikePipe implements PipeTransform {
  transform(number): string {

    let likes: string;
    // Enumerate number abbreviations
    var abbrev = ["", "K", "M", "B", "T"];

    // what tier? (determines SI symbol)
    var tier = Math.log10(number) / 3 | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = abbrev[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;
    var formatted = scaled.toFixed(1) + '';
    if (/\.0$/.test(formatted)) formatted = formatted.substr(0, formatted.length - 2);

    // format number and add suffix
    return formatted + suffix;
  }

}
@Pipe({ name: 'timeAgoPipe' })
export class TimeAgoPipe implements PipeTransform {
  transform(fecha): string {

    if (fecha != null) {
      let date = +new Date(fecha.replace(/\s/, 'T'));
      var seconds = Math.floor((+new Date() - date) / 1000);
      seconds = Math.abs(Math.abs(seconds) - (new Date(date).getTimezoneOffset()) * 60)

      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + (Math.floor(interval) > 1 ? " años" : " año");
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + (Math.floor(interval) > 1 ? " meses" : " mes");
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + (Math.floor(interval) > 1 ? " dias" : " dia");
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + (Math.floor(interval) > 1 ? " horas" : " hora");
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + (Math.floor(interval) > 1 ? " minutos" : " minuto");
      }
      return Math.floor(seconds) + (Math.floor(seconds) > 1 ? " segundos" : " segundo");
    } else {
      return "1 minuto";
    }
  }

}


@Pipe({ name: 'userCountryflagPipe' })
export class UserCountryflagPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  countries: any = (countries_data as any).default;
  transform(user_country): string {


    let url_flag = null;

    if (user_country.short_name != null && user_country.short_name != '') {
      url_flag = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${user_country.short_name.toLocaleLowerCase()}.svg`;
    } else {
      let country = this.countries.find(c => c.id == user_country.id);
      url_flag = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${country.short_name.toLocaleLowerCase()}.svg`;
    }

    return url_flag;
  }

}


@Pipe({ name: 'labelChildPipe' })
export class LabelChildPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(source_type, comment_filter): string {
    let url_flag = 'Ver más preguntas';

    if (comment_filter == 'rec' || comment_filter == 'pop' || comment_filter == 'mqs') {
      url_flag = 'Ver más preguntas';
    } else {
      url_flag = 'Ver más preguntas';
      if (source_type == 5) {
        url_flag = 'Ver más discusiones';
      }
    }



    return url_flag;
  }

}