import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssistNavbarService {

  private activateAssist: Subject<boolean> = new Subject<boolean>();
  private dataSearch: Subject<string>;
  private dataFilter: Subject<string> = new Subject<string>();
  private lastID: Subject<boolean> = new Subject<boolean>();
  private controlStatistics: Subject<boolean> = new Subject<boolean>();
  private activateChatsCourses: Subject<boolean> = new Subject<boolean>();

  constructor() { }



  /*
  * Initialize new object Subject to data search;
  */
  initObjectDataSearch(): void {
    this.dataSearch = new Subject<string>();
  }


  /*
  * Get value to show about assist in the navbar.
  */
  getActivateAssist(): Observable<boolean> {
    return this.activateAssist.asObservable();
  }



  /*
  * Set value to show about assist in the navbar.
  */
  setActivateAssist(value: boolean): void {
    this.activateAssist.next(value);
  }


  /*
  * Get value to show about assist in the navbar.
  */
  getActivateUnreadChatsCourses(): Observable<boolean> {
    return this.activateChatsCourses.asObservable();
  }



  /*
  * Set value to show about assist in the navbar.
  */
  setActivateUnreadChatsCourses(value: boolean): void {
    this.activateChatsCourses.next(value);
  }



  /*
  * Returns the value of the navbar's user browser.
  */
  getDataSearch(): Observable<string> {
    return this.dataSearch.asObservable();
  }



  /*
  * Sets the value taken from the navbar's user browser.
  */
  setDataSearch(value: string): void {
    this.dataSearch.next(value);
  }



  /*
  * Returns the value of the navbar user filter.
  */
  getValueFilter(): Observable<string> {
    return this.dataFilter.asObservable();
  }



  /*
  * Sets the value taken from the navbar user filter.
  */
  setValueFilter(value: string): void {
    this.dataFilter.next(value);
  }



  /*
  * Return boolean value for variable last_id.
  */
  getValueLastID(): Observable<boolean> {
    return this.lastID.asObservable();
  }



  /*
  * Sets boolean value for variable last_id.
  */
  setValueLastID(value: boolean): void {
    this.lastID.next(value);
  }



  /*
  * Set value to hidde elements of assist app.
  */
  setControlStatistics(value: boolean): void {
    this.controlStatistics.next(value);
  }



  /*
  * Get value to hidde elements of assist app.
  */
  getControlStatistics(): Observable<boolean> {
    return this.controlStatistics.asObservable();
  }



  resetDataSearch(): void {
    this.dataSearch = null;
  }
}
