import { Injectable } from '@angular/core';
import { Observable, Subject, Observer } from 'rxjs';


@Injectable()
export class SharedService {


    constructor() { }
    public user_data: Object | any;
    private category = new Subject<any>();

    setCategory(category: Object) {
        this.category.next(category);
    }

    clearCategory() {
        this.category.next();
    }

    getCategory(): Observable<any> {
        return this.category.asObservable();
    }


    setSidebarState(category: Object) {
        this.category.next(category);
    }

    getSidebarState(): Observable<any> {
        return this.category.asObservable();
    }

    public numb_ = 0;
    showSum() {
        this.numb_++;
        // console.log('shared-sum: ', this.numb_);
    }


}
