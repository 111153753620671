import { Component, OnInit, ViewChild, Input, ViewContainerRef, ComponentFactoryResolver, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ClassroomService } from '../../services/classroom.service';
import { CommunityService } from '../../services/community.service';
import { PlayerBarService } from '../../main/components/player-bar/player-bar.service';
import { UploadFileComponent } from '../../shared-classroom/upload-file/upload-file.component';
import { AWSS3Service } from '../../shared-classroom/awsS3.service';
import { ModalDialogComponent } from '../../shared-classroom/modal-dialog/modal-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { EvaluationService } from '../../services/evaluation.service';


@Component({
  selector: 'community-participate',
  templateUrl: './participate.component.html',
  styleUrls: ['./participate.component.scss']
})
export class ParticipateComponent implements OnInit {

  display_more_items: boolean = false;
  search_show: boolean = false;
  search_text = '';
  this_component = { type: null, state: false };
  @Input() item_to_edit;
  bucket;
  @Input() current;
  @Input() data_user;
  @Input() component;
  @Input() comment_type;
  @Output() updateJson: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeParticipateComponentComment: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeParticipateComponentChild: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateJsonContribution: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _classroomService: ClassroomService,
    private _communityService: CommunityService,
    private _playerBarService: PlayerBarService,
    private resolver: ComponentFactoryResolver,
    private _awsS3Service: AWSS3Service,
    private renderer2: Renderer2,
    private _evaluationService: EvaluationService,

  ) {
  }

  index = 0;
  componentRef: any;
  files = [];

  @ViewChild('uploadfile', { read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild(ModalDialogComponent) modal_dialog: ModalDialogComponent;


  button_label = 'respuesta';


  ngOnInit() {

    switch (this.comment_type) {
      case 'answer':
        this.button_label = 'respuesta';
        break;
      case 'opinion':
        // this.button_label = 'opinión';
        this.button_label = '';
        break;
      case 'question':
        this.button_label = 'pregunta';
        break;
      case 'participation':
        // this.button_label = 'participación';
        this.button_label = '';
        break;
      default:
        this.button_label = 'pregunta';
        break;
    }


    // this.bucket = { 'name': 'edutin-publico', 'path': 'Comments' };
    this.bucket = { 'name': 'edutin-publico', 'path': 'File_Comments' };

    this._playerBarService.player.keyOptionsTrue = false;

    if (this.item_to_edit != null) {
      if (this.item_to_edit.hasOwnProperty('Comment') || this.item_to_edit.hasOwnProperty('ChildrenComment')) {
        this.this_component = {
          type: (this.item_to_edit.Event == 'answer' || this.item_to_edit.Event == "editAnswer") ? 'respuesta' : 'pregunta',
          state: true
        }
      }


      if (this.item_to_edit.Event == 'edit') {

        // this.current.modules.forEach((module_, i_module) => {
        //   module_.Clase.forEach((lesson, i_lesson) => {
        //     if (lesson.id == this.item_to_edit.Comment.clase_id) {
        //       this.position = {
        //         lesson: i_lesson,
        //         module: i_module,
        //         source: lesson.Source.findIndex(source => {
        //           return source.id == this.item_to_edit['Comment'].source_id
        //         })
        //       }
        //       setTimeout(() => { this.createComponentsToEdit() }, 500);
        //     }
        //   });
        //   return;
        // });

        setTimeout(() => { this.createComponentsToEdit() }, 500);

      } else if (this.item_to_edit.Event == 'answer') {
        setTimeout(() => { this.createComponent('text'); }, 500);
      } else if (this.item_to_edit.Event == 'editAnswer') {
        setTimeout(() => { this.createComponentsToEdit() }, 500);
      }
    } else {
      this.this_component = { state: true, type: 'pregunta' };

      setTimeout(() => {
        this.createComponent('text');
      }, 200)
    }


  }//END OnInit

  createComponentsToEdit() {

    // let tpm_item_files = JSON.parse(JSON.stringify(this.item_to_edit.hasOwnProperty('FilesComments') ? this.item_to_edit.FilesComments : this.item_to_edit.Comment.files_comments));

    let tpm_item_files = JSON.parse(JSON.stringify(this.item_to_edit.hasOwnProperty('ChildrenComment') ? this.item_to_edit.ChildrenComment.files_comments : this.item_to_edit.Comment.files_comments));

    tpm_item_files.forEach(file => {
      this.createComponent(file.type, file);
    });

  }


  createComponent(type, data?, from?) {

    const factory = this.resolver.resolveComponentFactory(UploadFileComponent);
    const componentRef = this.entry.createComponent(factory);
    this.renderer2.addClass(componentRef.location.nativeElement, type);

    if (data) componentRef.instance.data_edited_file = JSON.parse(JSON.stringify(data));
    componentRef.instance.type = type;
    if (from && from == 'btn') { componentRef.instance.isNew = true; }
    componentRef.instance.index = this.index++;


    if (this.item_to_edit != null && (this.item_to_edit.Event == 'answer' || this.item_to_edit.Event == 'editAnswer')) {
      this.bucket.path = 'File_ChildrenComments'
      componentRef.instance.item_type = 'answer';
    } else {
      this.bucket.path = 'File_Comments'
      componentRef.instance.item_type = 'comment';
    }


    componentRef.instance.bucket = this.bucket;
    // this.files.push(componentRef);
    this.files.push({ component: componentRef, data: null });

    componentRef.instance.closeFile.subscribe(closed_component => {

      if (closed_component != -1) {


        if (this.files[closed_component].component.instance.file.id) {

          if (this.item_to_edit) {

            let tpm_item_files = JSON.parse(JSON.stringify(this.item_to_edit.hasOwnProperty('ChildrenComment') ? this.item_to_edit.ChildrenComment.files_comments : this.item_to_edit.Comment.files_comments));

            let f_index = tpm_item_files.findIndex(f => f.id == this.files[closed_component].component.instance.file.id);
            if (f_index > -1) {
              if (this.item_to_edit.hasOwnProperty('ChildrenComment')) {
                this.item_to_edit.ChildrenComment.files_comments[f_index].state = '-1';
              } else {
                this.item_to_edit.Comment.files_comments[f_index].state = '-1';
              }
            }
          }

          // this.files[closed_component].component.instance.file.state = '-1';
        }

        this.entry.remove(closed_component);
        this.files.splice(closed_component, 1);

        // this.item_to_edit.FilesComments.splice(closed_component, 1);

        this.files.forEach((element, index) => {
          this.index = this.files.length;
          element.component.instance.index = index;
        });

        if (this.files.length == 0) this.index = 0;

      }
    });



    componentRef.instance.file_error_emitter.subscribe(error_emitter => {

      this.modal_dialog_state = true;
      this.modal_dialog.modal_type = error_emitter;
      let __response = this.modal_dialog.response.subscribe(response => {
        this.modal_dialog_state = false;
        componentRef.instance.cleanFile();
        __response.unsubscribe();
      });

    });


  }


  data: any;


  sendComment() {

    let comment_text = '';
    let clasification: string = '';

    let commentCheckedtoSave: Observable<any>;
    let commentCheckedtoSubscriber: Subscription;
    let tmp_files = [];

    commentCheckedtoSave = Observable.create((observer) => {


      let showNotification: boolean = false;
      let files_ = this.files;

      if (files_ != null && files_.length > 0) {
        // Comprobar si se creó o se modificaron files de los records        

        for (let file_index = 0; file_index < files_.length; file_index++) {
          let save_this_file = false;

          let file_saved = null;
          let file_edited = files_[file_index].component.instance.getFile();
          file_edited.user_id = this.data_user.id;

          if (file_edited == false) {
            observer.next('loadFile');
          }
          else {


            if (this.item_to_edit != null && file_edited != null && this.item_to_edit != null) {

              let tpm_item_files = JSON.parse(JSON.stringify(this.item_to_edit.hasOwnProperty('ChildrenComment') ? this.item_to_edit.ChildrenComment.files_comments : this.item_to_edit.Comment.files_comments));

              let f_index = tpm_item_files.findIndex(f => f.id == file_edited.id);
              if (f_index > -1) {
                file_saved = JSON.parse(JSON.stringify(tpm_item_files[f_index]))
              }
            }
            // if (file_edited.id == file_saved.id) {
            //Es el mismo file
            if (file_edited.type == 'text') {
              if (file_edited.description != null && file_edited.description != '') {

                // ('El texto fue modificado deberia guardar texto');              
                if (file_saved != null) {
                  save_this_file = (file_edited.description != file_saved.description) ? true : false;
                } else {
                  // Si es un file que no existia si debe guardar
                  save_this_file = true;
                }
              } else {
                showNotification = true;
              }

              comment_text = comment_text + ' ' + file_edited.description;



            }
            else if (file_edited.type == 'code') {
              if (file_edited.description != null && file_edited.description != '') {

                // ('El código fue modificado deberia guardar code');
                if (file_saved != null) {
                  save_this_file = ((file_edited.description != file_saved.description) || (file_edited.language != file_saved.language)) ? true : false;

                } else {
                  // Si es un file que no existia si debe guardar
                  save_this_file = true;
                }

              } else {
                showNotification = true;
              }
            }
            else if (file_edited.type == 'image' || file_edited.type == 'video' || file_edited.type == 'file') {

              if (file_edited.file_name != null && file_edited.file_name != '') {
                // ('El archivo fue modificado deberia guardar file');              
                if (file_saved != null) {

                  save_this_file = (file_edited.file_url != file_saved.file_url) ? true : false;

                } else {
                  // Si es un file que no existia si debe guardar
                  save_this_file = true;
                }

              } else {

                showNotification = true;

              }

            }
            else if (file_edited.type == 'link') {

              if (file_edited.link != null && file_edited.link != '') {
                // ('El link fue modificado deberia guardar link');               
                if (file_saved != null) {
                  save_this_file = (file_edited.link != file_saved.link) ? true : false;

                } else {
                  // Si es un file que no existia si debe guardar
                  save_this_file = true;
                }
              } else {

                showNotification = true;

              }

            } else {
              //('Es el mismo file y no debe guardar cambios');
              save_this_file = false;
            }


            if (showNotification) {
              //('%c file_edited::: ', 'color: black; background:yellow', JSON.parse(JSON.stringify(file_edited)));
            }

            if (save_this_file) {

              file_edited.state = '2';//Este estado (2) es unicamente para saber si se modificó este archivo       

            }

            //Esto es mientras tanto, ver que puedo hacer para mejorarlo
            if ((this.item_to_edit != null && this.item_to_edit.Event == 'answer') || this.item_to_edit == null) {
              delete file_edited.state;
            }

            if (file_edited.type == 'image' || file_edited.type == 'video' || file_edited.type == 'file') {

              delete file_edited.description;
              delete file_edited.file;
              delete file_edited.file_key;
              delete file_edited.file_type;
              delete file_edited.id;
              delete file_edited.progress;
              delete file_edited.size;
              delete file_edited.src;

            }

            tmp_files.push(JSON.parse(JSON.stringify(file_edited)));

            if (file_index == (files_.length - 1)) {


              if (showNotification) {
                observer.next(false);
              } else {



                if (this.item_to_edit != null) {

                  // let intersect = this.feedback_edited.FilesFeedback.filter(a => tmp_files.some(b => a.id === b.id));                  
                  let tpm_item_files = JSON.parse(JSON.stringify(this.item_to_edit.hasOwnProperty('ChildrenComment') ? this.item_to_edit.ChildrenComment.files_comments : this.item_to_edit.Comment.files_comments));

                  let isDelete = tpm_item_files.filter(a => a.state == '-1');

                  if (isDelete != null && isDelete.length > 0) {
                    // tmp_files.push(isDelete[0]);
                    let tmp_array = tmp_files.concat(isDelete);

                    tmp_files = JSON.parse(JSON.stringify(tmp_array));
                  }

                }


                if (tmp_files.length > 0) {

                  let isForo = (this._classroomService.data.source && this._classroomService.data.source.type == '5') ? true : false;

                  // Respuesta de una pregunta
                  // if (this.item_to_edit != null && (this.comment_type == 'answer' || this.comment_type == 'opinion')) {

                  if (this.comment_type == 'answer' || this.comment_type == 'opinion') {

                    if (this.item_to_edit != null && this.item_to_edit.hasOwnProperty('ChildrenComment')) {
                      //Editar un children
                      const { Parent } = this.item_to_edit;

                      this.data = {
                        index: this.item_to_edit.ChildrenComment.index,
                        user_id: this.item_to_edit.ChildrenComment.user_id,
                        id: this.item_to_edit.ChildrenComment.id,
                        comment_id: this.item_to_edit.ChildrenComment.comment_id,
                        Files: [],
                        url: '/children_comments_edit.json'
                      }

                      if (Parent != null) {
                        if (Parent.curso_id != null) this.data['curso_id'] = Parent.curso_id;
                        if (Parent.curso_nombre != null) this.data['curso_nombre'] = Parent.curso_nombre;
                      }
                    } else {

                      const { Comment } = this.item_to_edit;

                      let curso_id = Comment.curso_id != null ? Comment.curso_id : this._classroomService.data.certification.Certification.curso_id;

                      let quiz_id = Comment.quiz_id != null ? Comment.quiz_id : this._classroomService.data.source.id;

                      //Crear un children
                      this.data = {
                        id: null,
                        comment_id: Comment.id,
                        clase_id: Comment.clase_id,
                        curso_id: curso_id,
                        source_id: isForo ? '0' : Comment.source_id,
                        quiz_id: !isForo ? '0' : quiz_id,
                        Files: [],
                        index: Comment.count_children,
                        parent_index: Comment.index,
                        fecha_format: 'hace 0 segundos',
                        url: '/children_comments_add.json'
                      }

                      if (Comment.curso_id != null) this.data['curso_id'] = Comment.curso_id;
                      if (Comment.curso_nombre != null) this.data['curso_nombre'] = Comment.curso_nombre;
                    }




                  } else {
                    // Enviar comment        

                    if (this.item_to_edit) {
                      //Está siendo editado                      

                      const { Comment } = this.item_to_edit;

                      this.data = {
                        id: Comment.id,
                        clase_id: Comment.clase_id,
                        source_id: Comment.source_id,
                        quiz_id: Comment.quiz_id,
                        user_id: Comment.user_id,
                        url: '/comments_edit.json'
                        // index: this.item_to_edit ? this.item_to_edit['Comment'].index : null,
                      }

                      if (Comment.curso_id != null) this.data['curso_id'] = Comment.curso_id;
                      if (Comment.curso_nombre != null) this.data['curso_nombre'] = Comment.curso_nombre;

                    } else {
                      //Es un nuevo Comment

                      this.data = {
                        id: this.item_to_edit ? this.item_to_edit['Comment'].id : null,
                        clase_id: this.current.lesson.id,
                        curso_id: this._classroomService.data.course.id,
                        source_id: isForo ? '0' : this.current.source.id,
                        quiz_id: !isForo ? '0' : this.current.source.id,
                        user_id: this.item_to_edit ? this.item_to_edit['Comment'].user_id : null,
                        index: this.item_to_edit ? this.item_to_edit['Comment'].index : null,
                        url: this.item_to_edit ? '/comments_edit.json' : '/comments_add.json',
                      }

                    }



                  }

                  this.data['Files'] = JSON.parse(JSON.stringify(tmp_files));
                  observer.next(true);

                } else {

                  // if (ev == 'edit') {
                  //   this.clearEditComment();
                  //   this.loading_edit = false;
                  // } else {
                  //   this.clearNewComment();
                  // }

                }




              }


            }

          }
        }

        // }

      }




    });



    commentCheckedtoSubscriber = commentCheckedtoSave.subscribe({

      next: (value) => {
        if (value === true) {

          this.modal_dialog_state = true;

          if (this._classroomService.data.source) {
            this.modal_dialog.modal_type = this._classroomService.data.source.type == 5 ? 25 : 1;
          } else {
            this.modal_dialog.modal_type = 1;
          }

          const { url } = this.data;

          let tmp_data = {
            curso_id: this.data.curso_id != null ? this.data.curso_id : this._classroomService.data.course.id,
            source_id: this.data.source_id,
            clase_id: this.data.clase_id,
            quiz_id: this.data.quiz_id,
            user_id: this.data_user.id,
            extra_data: {
              files_comments: JSON.parse(JSON.stringify(this.data.Files)),
              cursos: {
                nombre: this.data.curso_nombre != null ? this.data.curso_nombre : this._classroomService.data.course.nombre
              },
              users: {
                id: this.data_user.id,
                name: this.data_user.name
              }
            }
          };

          if (url == "/children_comments_add.json" || url == "/children_comments_edit.json") {

            tmp_data.extra_data.users = {
              id: this.item_to_edit.User.id,
              name: this.item_to_edit.User.name
            }
            tmp_data['comment_id'] = this.data.comment_id;


            if (this.data_user != null && this.item_to_edit != null && (this.data_user.id != this.item_to_edit.User.id) && url == "/children_comments_add.json") {
              this._communityService.saveSuggestion(this.data_user.id, this.item_to_edit.User.id, 'comment_reply');
            }
          }

          if (url == "/comments_edit.json") {
            tmp_data['id'] = this.item_to_edit.Comment.id;
            tmp_data['fecha'] = this.item_to_edit.Comment.fecha.replace(' ', 'T');
          }

          if (url == "/children_comments_edit.json") {
            tmp_data['id'] = this.item_to_edit.ChildrenComment.id;
            tmp_data['fecha'] = this.item_to_edit.ChildrenComment.date_creation.replace(' ', 'T');
          }


          // AQUI PARA LOS COMENTARIOS
          // Actualizar las estadisticas del usuario cada vez que haga un comentario by: Jesus
          if (this._classroomService.data.course) {
            const curso_id = this._classroomService.data.course.id
            const certification_id = this._classroomService.data.certification.Certification.id
            this._classroomService.saveStatistics(curso_id, certification_id, 'com_comment')
          }
          // Fin

          const send = {
            addItem: () => {


              this._communityService.addItem(url, tmp_data).subscribe((res: any) => {


                if (res.status == false) {
                  //Si al enviar algo, da error
                  this.modal_dialog_state = true;
                  this.modal_dialog.modal_type = 41;
                  let __response_ = this.modal_dialog.response.subscribe(response => {
                    this.modal_dialog_state = false;
                    __response_.unsubscribe();
                  });

                } else {

                  //Si no da error       

                  let item = JSON.parse(JSON.stringify(res.data));

                  if (item.id != null) {
                    let tex_T = comment_text.replace(/[\\]/g, "\\\\").replace((/  |\r\n|\n|\r/gm), "").replace(/['"]+/g, '').replace(/&nbsp;/g, '');
                    this._communityService.messageClasification(tex_T, this.comment_type, item.id, this.data_user.id).subscribe((response: any) => {
                      // clasification = response.clasification;
                    });
                  }

                  let updateJSON = {
                    response: () => {


                      switch (url) {
                        case '/comments_add.json':

                          // // Estructura del nuevo item que será creado
                          let new_item: any = {
                            Clases: {
                              id: this.current.lesson.id,
                              titulo: this.current.lesson.titulo,
                              order: this.current.lesson.order,
                              module_id: this.current.lesson.module_id,
                              Module: this.current.modules[this.current.lesson.i_module].Module
                            },
                            Source: {
                              id: this.current.source.id,
                              type: this.current.source.type
                            },
                            User: this.data_user,
                            Voto: []
                          };

                          new_item.Comment = {};
                          for (const prop in item) {
                            if (typeof item[prop] != 'object') {
                              new_item.Comment[prop] = item[prop];
                            }
                          }
                          new_item.Comment.files_comments = item.extra_data.files_comments;

                          this.updateJson.emit({ ev: 'new-comment', new_item });

                          break;

                        case '/comments_edit.json':
                        case '/children_comments_edit.json':

                          let files = JSON.parse(JSON.stringify(item));

                          var filtered = files.filter((file) => {
                            return file.state != '-1';
                          });

                          this.data.Files = JSON.parse(JSON.stringify(filtered));

                          let item_edit = JSON.parse(JSON.stringify(this.data));

                          if (url == '/comments_edit.json') {
                            this.updateJson.emit({ ev: 'edit-comment', item_edit });
                          }

                          if (url == '/children_comments_edit.json') {
                            this.updateJson.emit({ ev: 'edit-answer', item_edit });
                          }


                          break;

                        case '/children_comments_add.json':

                          this.updateJson.emit({ ev: 'new-answer', item });

                          break;

                      }


                    }
                  }



                  if (this._classroomService.data.source && this._classroomService.data.source.type == 5) {
                    //Si es participación     

                    let source = JSON.parse(JSON.stringify(this._classroomService.data.source));

                    this._communityService.forumsEvaluate(source).subscribe((foro_response: any) => {

                      if (foro_response.hasOwnProperty('data') && foro_response.status) {

                        let Attempt;

                        if (source.hasOwnProperty('Attempt') && source.Attempt.length > 0) {

                          //Si el source ya tiene intentos,actualizar los campos

                          let tmp_attempt = source.Attempt.filter(src => src.id == foro_response.data.id);

                          if (tmp_attempt.length > 0) {

                            //Si se encontró dentro del source el intento con el mismo id de la repsuesta del post se actualiza los datos

                            Attempt = tmp_attempt[0];
                            Attempt.count_opinions = foro_response.data.count_opinions;
                            Attempt.count_participations = foro_response.data.count_participations;
                            Attempt.date_calificated = foro_response.data.date_calificated;
                            Attempt.result = foro_response.data.result;
                            Attempt.state = foro_response.data.state;

                          } else {

                            //Si hay intentos pero no se encuentra con el id que se está buscando, crear un intento nuevo

                            Attempt = {
                              count_opinions: foro_response.data.count_opinions,
                              count_participations: foro_response.data.count_participations,
                              date_calificated: foro_response.data.date_calificated,
                              result: foro_response.data.result,
                              sstate: foro_response.data.state,
                              id: foro_response.data.id
                            }
                          }



                        } else {

                          //Si el source no tiene intentos se crea uno nuevo

                          Attempt = {
                            certification_id: this._classroomService.data.certification.Certification.id,
                            count_opinions: foro_response.data.count_opinions,
                            count_participations: foro_response.data.count_participations,
                            curso_id: source.curso_id,
                            date_calificated: foro_response.data.date_calificated,
                            has_comment: 0,
                            has_feedback: 0,
                            id: foro_response.data.id,
                            quiz_id: source.id,
                            result: foro_response.data.result,
                            state: foro_response.data.state
                          }


                        }

                        // let attempt = JSON.parse(JSON.stringify(foro_response.data.data.Attempt));
                        let sourceCompleted = Attempt.state == 1 ? true : false; //Si el attempt viene con estado 1 significa que realizó las tres acciones requeridas

                        let tmp_source = JSON.parse(JSON.stringify(this._classroomService.data.source));

                        tmp_source['Attempt'] = [Attempt];
                        tmp_source['sourceCompleted'] = sourceCompleted;

                        this._classroomService.data.source = JSON.parse(JSON.stringify(tmp_source));

                        let tmp_lesson = JSON.parse(JSON.stringify(this._classroomService.data.lesson));
                        tmp_lesson.Source[this._classroomService.currentIndex.source] = JSON.parse(JSON.stringify(tmp_source));

                        this._classroomService.data.lesson = JSON.parse(JSON.stringify(tmp_lesson));

                        this._classroomService.data.modules[this._classroomService.currentIndex.module].Clase[this._classroomService.currentIndex.lesson] = JSON.parse(JSON.stringify(tmp_lesson));


                        //Actualizar Array de Evaluaciones
                        let evaluation_index = this._evaluationService.evaluations.findIndex(evaluation =>
                          evaluation.Quiz.id == tmp_source.id
                        );

                        if ((evaluation_index != null && evaluation_index > -1)) {
                          if (!this._evaluationService.evaluations[evaluation_index].hasOwnProperty('Attempt')) this._evaluationService.evaluations[evaluation_index]['Attempt'] = [];
                          this._evaluationService.evaluations[evaluation_index]['Attempt'] = [JSON.parse(JSON.stringify(Attempt))];
                        }

                        //Actualizar Array de Foros de la comunidad
                        let forum_index = this._evaluationService.forums.findIndex(evaluation =>
                          evaluation.Quiz.id == tmp_source.id
                        );

                        if ((forum_index != null && forum_index > -1)) {
                          if (!this._evaluationService.forums[forum_index].hasOwnProperty('Attempt')) this._evaluationService.forums[forum_index]['Attempt'] = [];
                          this._evaluationService.forums[forum_index]['Attempt'] = [JSON.parse(JSON.stringify(Attempt))];
                        }


                      }



                      let source_hasAttemps = (this._classroomService.data.source.Attempt != null && this._classroomService.data.source.Attempt.length > 0 && this._classroomService.data.source.Attempt[0] != null) ? true : false;


                      if (source_hasAttemps) {

                        if (this._classroomService.data.source.Attempt[0].count_participations == 0) {

                          if (url == '/children_comments_add.json' && (this._classroomService.data.source.Attempt[0].count_opinions == 1)) {
                            this.modal_dialog.modal_type = 33;
                          }

                          if (url == '/children_comments_add.json' && (this._classroomService.data.source.Attempt[0].count_opinions > 1)) {
                            this.modal_dialog.modal_type = 34;
                          }

                        } else {

                          if ((url == '/comments_add.json') && (this._classroomService.data.source.Attempt[0].count_participations == 1 && this._classroomService.data.source.Attempt[0].count_opinions == 0)) {
                            this.modal_dialog.modal_type = 29;
                          }

                          if ((url == '/comments_add.json') && (this._classroomService.data.source.Attempt[0].count_participations > 1)) {
                            //Participación enviada pero ya deja de contar
                            this.modal_dialog.modal_type = 35;
                          }

                          if (url == '/comments_edit.json') {
                            //Notificar participación editada
                            this.modal_dialog.modal_type = 46;
                          }


                          if ((url == '/children_comments_add.json') && (this._classroomService.data.source.Attempt[0].count_participations > 0 && (this._classroomService.data.source.Attempt[0].count_opinions == 0 || this._classroomService.data.source.Attempt[0].count_opinions > 2))) {
                            //Opinión enviada pero no cuenta
                            this.modal_dialog.modal_type = 36;
                          }



                          if (url == '/children_comments_add.json' && (this._classroomService.data.source.Attempt[0].count_participations > 0 && this._classroomService.data.source.Attempt[0].count_opinions == 1)) {
                            this.modal_dialog.modal_type = 30;
                          }


                          if (this._classroomService.data.source.Attempt[0].count_participations > 0 && this._classroomService.data.source.Attempt[0].count_opinions == 2) {
                            if (url == '/comments_add.json') this.modal_dialog.modal_type = 31;
                            if (url == '/children_comments_add.json') this.modal_dialog.modal_type = 32;
                          }


                          if (url == '/children_comments_edit.json') {
                            //Notificar chlid editada
                            this.modal_dialog.modal_type = 47;
                          }

                        }

                        let __response = this.modal_dialog.response.subscribe(response => {
                          console.log('%c 🚀 ~ 871 forumsEvaluate ~ response', 'color: green, background:black', response)
                          this.modal_dialog_state = false;

                          updateJSON.response();


                          if (this.comment_type == 'question' || this.comment_type == 'participation') {
                            if (this.item_to_edit != null) {
                              this.closeParticipateComponentComment.emit();
                            } else {
                              this._communityService.closeParticipateComponent(this.component);
                            }
                          } else {
                            this.closeParticipateComponentChild.emit();
                          }

                          __response.unsubscribe();
                        });



                      } else {
                        this.modal_dialog_state = false;

                        updateJSON.response();

                        if (this.comment_type == 'question' || this.comment_type == 'participation') {
                          if (this.item_to_edit != null) {
                            this.closeParticipateComponentComment.emit();
                          } else {
                            this._communityService.closeParticipateComponent(this.component);
                          }
                        } else {
                          this.closeParticipateComponentChild.emit();
                        }

                      }


                      // this.modal_dialog_state = false;

                    });

                  } else {

                    // this.modal_dialog_state = false;
                    //Si es pregunta 

                    if (url == '/comments_add.json') {
                      //Notificar pregunta enviada
                      this.modal_dialog.modal_type = 28;
                    }

                    if (url == '/children_comments_add.json') {
                      //Notificar respuesta enviada
                      this.modal_dialog.modal_type = 37;
                    }


                    if (url == '/comments_edit.json') {
                      //Notificar pregunta enviada
                      this.modal_dialog.modal_type = 39;
                    }

                    if (url == '/children_comments_edit.json') {
                      //Notificar respuesta enviada
                      this.modal_dialog.modal_type = 40;
                    }


                    let __response = this.modal_dialog.response.subscribe(response => {
                      //Responde al aceptar despues de enviar el comment
                      this.modal_dialog_state = false;

                      updateJSON.response();

                      if (this.comment_type == 'question' || this.comment_type == 'participation') {
                        if (this.item_to_edit != null) {
                          this.closeParticipateComponentComment.emit();
                        } else {
                          this._communityService.closeParticipateComponent(this.component);
                        }
                      } else {
                        this.closeParticipateComponentChild.emit();
                      }
                      __response.unsubscribe();
                    });

                  }


                }//End else =>  sino es error
              });//End AddMoment


            }
          }


          if (tmp_data.extra_data.cursos.nombre == null || tmp_data.extra_data.cursos.nombre == '') {

            //Si el nombre del curso está vacio o indefinido
            //Pedir primero la información del curso

            this._classroomService.getInfoCourse().subscribe(resp => {

              if (resp != null) {
                tmp_data.extra_data.cursos.nombre = resp.nombre;
              } else {
                tmp_data.extra_data.cursos.nombre = '';
              }

              //Validar si el nombre viene, o si da error
              //Si el nombre no viene o da error, entonces enviarlo vacío
              send.addItem();
            });

          } else {

            //Si el nombre del curso si está, simplemente almacenar. 
            send.addItem();

          }



        } else {

          // Hay files vacíos, se debe mostrár notificación            
          this.modal_dialog_state = true;
          this.modal_dialog.modal_type = (value == 'loadFile') ? 45 : 2;
          let __response = this.modal_dialog.response.subscribe(response => {
            this.modal_dialog_state = false;
            __response.unsubscribe();
          });

        }

        setTimeout(() => {
          commentCheckedtoSubscriber.unsubscribe();
        }, 100);






        // setTimeout(() => {

        //   let modal_ = document.querySelector('.modal__container._msg_');
        //   modal_.scrollIntoView({
        //     behavior: "smooth",
        //     block: "center",
        //     inline: "center"
        //   });

        // }, 350);



      }

    });





  }


  modal_dialog_state
  closeThisComponent(event) {

    if (event == 'cancel') {
      this.modal_dialog_state = true;
      this.modal_dialog.modal_type = 10;//Preguntar si desea cancelar pregunta             

      setTimeout(() => {

        if (this.modal_dialog_state) {
          //Visualizar modal en la mitad de la pantalla
          let modal_ = document.querySelector('.modal__container._msg_');
          modal_.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
          });
        }

      }, 250);


      let __response = this.modal_dialog.response.subscribe(response => {
        if (response) {

          if (this.comment_type == 'question' || this.comment_type == 'participation') {

            if (this.item_to_edit != null) {
              this.closeParticipateComponentComment.emit();
            } else {
              this._communityService.closeParticipateComponent(this.component);
            }
          } else {
            this.closeParticipateComponentChild.emit();
          }


          this.removeFiles();
        }

        this.modal_dialog_state = false;

        __response.unsubscribe();
      });
    } else {

      if (this.comment_type == 'question' || this.comment_type == 'participation') {
        if (this.item_to_edit != null) {
          this.closeParticipateComponentComment.emit();
        } else {
          this._communityService.closeParticipateComponent(this.component);
        }
      } else {
        this.closeParticipateComponentChild.emit();
      }

    }
  }


  removeFiles() {

    this.modal_dialog_state = true;
    this.modal_dialog.modal_type = 0;//Mostrar loader

    if (this.files != null && this.files.length > 0) {

      let file_data = this.files[0].component.instance.getFile();
      if ((file_data.type == 'image' || file_data.type == 'file' || file_data.type == 'video') && file_data.file_key) {
        const params = { Bucket: 'edutin-publico', Key: file_data.file_key };

        this._awsS3Service.getS3Bucket().deleteObject(params, (err, data) => {

          this.files.splice(0, 1);
          this.removeFiles();
          if (err) {
            //('There was an error deleting your file: ', err.message);
            return;
          }

        });


      } else {
        this.files.splice(0, 1);
        this.removeFiles();
      }


    } else {

      this.entry.clear();
      this.files = [];
      this.index = 0;
      if (this.item_to_edit) {
        this.item_to_edit = null;
      }
      this.modal_dialog_state = false;

      if (this.comment_type == 'question' || this.comment_type == 'participation') {
        if (this.item_to_edit != null) {
          this.closeParticipateComponentComment.emit();
        } else {
          this._communityService.closeParticipateComponent(this.component);
        }
      } else {
        this.closeParticipateComponentChild.emit();
      }
      // this.createComponent('text');
    }

  }

  ngOnDestroy() {
    this._playerBarService.player.keyOptionsTrue = true;
  }
}
