import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClassroomService } from '../../../services/classroom.service';
import { CommunityService } from '../../../services/community.service';
import { SearchBarService } from './search-bar.service';


@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {

  constructor(
    public _classroomService: ClassroomService,
    public _communityService: CommunityService,
    public _searchBarService: SearchBarService
  ) { }

  searchtext_: string = '';
  searchChanged = new Subject<string>();
  @Input() text_to_search: string;
  @Output() eventSearch: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('text_to_search')) {
      this.searchtext_ = this.text_to_search;
    }
  }

  ngAfterViewInit() {

    this.searchChanged.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((term): void => {
      this.eventSearch.emit(term);
    });
  }


  cleanSearch() {
    this.searchtext_ = '';
    this.eventSearch.emit(this.searchtext_);
  }


}
