import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';


@Pipe({ name: 'timeTransform' })
export class TimeTransform implements PipeTransform {

    transform(date, _case?): string {
        let conversation_date;

        // let today = new Date();
        let chat_date = new Date(date);
        let today = new Date('2020-06-19 23:46:15');


        switch (_case) {
            case 'split':
                //fecha corta del ultimo mensaje

                var fechaInicio = chat_date.getTime();
                var fechaFin = today.getTime();

                var diff = fechaFin - fechaInicio;
                let diferencia = diff / (1000 * 60 * 60 * 24);


                let today_string = today.toDateString();
                let chat_date_string = chat_date.toDateString();



                if (today_string == chat_date_string) {
                    // Si el ultimo mensahe es del mismo dia, devolver la hora
                    var hours = chat_date.getHours();
                    var minutes: any = chat_date.getMinutes();
                    var ampm = hours >= 12 ? 'pm' : 'am';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? '0' + minutes : minutes;

                    conversation_date = hours + ':' + minutes + ' ' + ampm;

                } else {
                    //Si el ultimo mensaje no es del mismo dia
                    if ((diferencia > 0 && diferencia < 6) && (chat_date.getDay() < today.getDay())) {
                        // console.log('son en la misma semana')
                        if (chat_date.getDay() == (today.getDay() - 1)) {
                            // console.log('ayer');
                            conversation_date = 'ayer';
                        } else {
                            //Dia de la semana del ultimo mensaje
                            // let days_ = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sábado'];
                            conversation_date = chat_date.toLocaleString("es-ES", { weekday: "long" });
                            // conversation_date = days_[chat_date.getDay()];
                        }
                    } else {
                        // console.log('NO son en la misma semana')
                        conversation_date = chat_date.toLocaleDateString();
                    }
                }

                break;
            case 'full':
                //fecha full del mensaje { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12: "false" }
                let options: any = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: "true" };
                conversation_date = chat_date.toLocaleString("es-ES", options);
                break;
            case 'hour':
                //hora del mensaje
                conversation_date = chat_date.toLocaleString("es-ES", { hour: '2-digit', minute: '2-digit', hour12: true });
                break;
            case 'date':
                //fecha del mensaje
                conversation_date = chat_date.toLocaleString("es-ES", { year: "numeric", month: "long", day: "numeric" });
                break;
        }




        return conversation_date
    }

}



@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
    transform(username: string, length: string): string {

        username = username.toLocaleLowerCase();
        let split_username_length = username.trim().split(/\s+/).length;

        var un = username.split(' ');
        switch (length) {
            case '1':
                var user_name = un[0];
                break;
            case '2':
                var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
                break;
        }
        let name = '';

        user_name.split(' ').forEach(U => {
            name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
        });

        return name;

    }
}


@Pipe({ name: 'roleName' })
export class RoleName implements PipeTransform {
    transform(role: string): string {
        switch (role.toLocaleLowerCase()) {
            case 'premium':
                return 'estudiante oficial'
                break;
            case 'free':
                return 'estudiante libre'
                break;
            case 'propietario':
                return 'profesor'
                break;
            default:
                return role
                break;
        }
    }
}


@Pipe({ name: 'audioPlayeTimePipe' })
export class AudioPlayeTimePipe implements PipeTransform {
    transform(time: number, duration): string {

        let duration_in_seconds
        if (duration != null) {

            //   let tmp_duration_split = duration.split(':');

            duration_in_seconds = duration;
        }

        if (time > duration_in_seconds) {
            time = duration_in_seconds;
        }


        var horas = Math.floor(time / 3600);
        var minutos = Math.floor((time - (horas * 3600)) / 60);
        var segundos = Math.round(time - (horas * 3600) - (minutos * 60));


        // let alg =  (horas < 10 ? '0' : '') + horas + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos;

        var obj = time >= 3600 ? (horas < 10 ? '0' : '') + horas + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos : (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos;
        return obj;
    }
}

@Pipe({ name: 'audioPlayeDurationPipe' })
export class AudioPlayeDurationPipe implements PipeTransform {
    transform(duration): string {

        var horas = Math.floor(duration / 3600);
        var minutos = Math.floor((duration - (horas * 3600)) / 60);
        var segundos = Math.round(duration - (horas * 3600) - (minutos * 60));

        // let alg =  (horas < 10 ? '0' : '') + horas + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos;

        var obj = duration >= 3600 ? (horas < 10 ? '0' : '') + horas + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos : (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos;
        return obj;
    }
}

@Pipe({ name: 'audioPlayeSecondPipe' })
export class AudioPlayeSecondPipe implements PipeTransform {
    transform(time: number, duration): number {

        let second = (time != 0 ? ((time * 100) / duration) : 0) * 100 / 100;

        return second;
    }
}


@Pipe({ name: 'safeStyle' })
export class SafeStylePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) { }
    transform(value: string): SafeStyle {
        if (!value) return '';

        return this.sanitizer.sanitize(SecurityContext.STYLE, value);
    }
}

@Pipe({ name: 'labelColorPipe' })
export class LabelColorPipe implements PipeTransform {

    color_arr = [
        "#CD5334",
        "#F49F0A",
        "#390099",
        "#679436",
        "#9448BC",
        "#264027",
        "#78586F",
        "#235789",
        "#EF7B45",
        "#57C4E5"
    ];

    transform(conversation, user_id): string {

        let label_color = null;

        if (conversation) {
            let find = conversation.associated.findIndex(user => user.user.user_id == user_id);

            // if (find) label_color = conversation.associated[find].user.label_color;
            if (find != null && find > -1) {
                let index_to_set_color = find.toString().split("").pop();
                label_color = this.color_arr[index_to_set_color];
            }
        }

        return label_color;
    }
}


@Pipe({ name: 'chatRoleName' })
export class ChatRoleName implements PipeTransform {
    transform(item: any, execute): string {

        let role = 'Participante';
        if(execute == 0){
            role = '';
        }

        // Usuario de chat
        if (item && item.user_role && item.user_role == 'ADMINISTRATOR') {
            role = 'Administrador';
            if(execute == 0){
                role = 'Admin';
            }
        }

        return role;
    }
}
