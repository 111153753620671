import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
environment

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number;
  environment: any = environment;
  constructor() { }

  ngOnInit() {
    this.year = new Date().getFullYear();
  }

}
