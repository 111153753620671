import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClassroomService } from '../../../services/classroom.service';
import { CommunityService } from '../../../services/community.service';
import { EvaluationService } from '../../../services/evaluation.service';


@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent {

  constructor(
    public _classroomService: ClassroomService,
    public _communityService: CommunityService,
    public _evaluationService: EvaluationService
  ) { }

  environment: any = environment;
  @Input() is_forums;
  
  @Input() modules_loaded;
  @Output() goToSource: EventEmitter<any> = new EventEmitter<any>();
  evaluations_loaded: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {

    if ((changes.hasOwnProperty('modules_loaded') && this.modules_loaded === true)) {

      this._evaluationService.getEvaluations(this.is_forums).subscribe(evaluations => {


        evaluations.sort((a, b) => {
          return (parseInt(a.Clase.order) < parseInt(b.Clase.order)) ? -1 : ((parseInt(a.Clase.order) > parseInt(b.Clase.order)) ? 1 : 0);
        });


        let result = evaluations.reduce((r, a) => {
          r[a.Module.order] = r[a.Module.order] || [];
          r[a.Module.order].push(a);
          return r;
        }, Object.create(null));

        let tmp_evaluations = [];
        Object.keys(result).forEach(key => {
          tmp_evaluations = tmp_evaluations.concat(result[key]);
        });

        // if (this.is_forums == true) {
        //Llenar array de forums 
        if (tmp_evaluations && tmp_evaluations.length > 0) this._evaluationService.forums = JSON.parse(JSON.stringify(tmp_evaluations.filter(e => e.Quiz.is_evaluation == 0)));
        // } else {
        this._evaluationService.evaluations = JSON.parse(JSON.stringify(tmp_evaluations));
        // }

        this.evaluations_loaded = true;//Independientemente si cargó correctamente o no, es true porque ya hizo la petición y esta respondió    

      })
    }
  }


  selectEvaluation(evaluation, type?) {

    if (evaluation.Quiz.id != this._classroomService.data.source.id) {
      evaluation.Source = evaluation.Quiz;
      this.goToSource.emit({ event: 'go_to_source_from_notes', item: evaluation });
    }

    // if (type == 'takepart') {
    this._communityService.participate_from_forums = {
      forum_id: evaluation.Quiz.id,
      state: true
    }
    // }
  }



}
