
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { String } from 'aws-sdk/clients/appstream';



@Directive({
  selector: '[appMenuMsg]'
})
export class MenuMsgDirective {


  @Input() appMenuMsg;

  constructor(
    private _messageElement: ElementRef,
    private _renderer2: Renderer2,
  ) { }


  @HostListener('click')
  click() { this.setMenuPosition() }




  /*
  * Verify info, position and show menu in specific position.
  */
  setMenuPosition(): void {

    this.setMenuPositionX();

    if (!this.isPositionMessageNormal()) {
      this.setMenuUp();
      return;
    }

    this.setMenuDown();
  }




  /*
  * Check horizontal position and set the position x to display menu.
  */
  setMenuPositionX(): String {

    let width = this.appMenuMsg.c.offsetWidth;
    let list = this.appMenuMsg.c.getElementsByClassName('message_assist');
    let menu = this._messageElement.nativeElement.getElementsByClassName('actions-menu-message');
    
    if (!menu.length) {
      return;
    }

    if (!list.length) {
      list = this.appMenuMsg.c.getElementsByClassName('message');
      if (!list.length) {
        return;
      }
    }

    let message = list[this.appMenuMsg.index].getElementsByClassName('sub_msg_content');

    if (!message.length) {
      return;
    }

    menu[0].classList.remove('left-menu');
    menu[0].classList.remove('right-menu');

    let widthMenu = 150; // Width of menu to show in the message. - Change this variable if the width of menu is changed in css.

    // Left message not own.
    if (list[this.appMenuMsg.index].classList.contains('left-msg')) {

      // To show the menu to the right.
      if (message[0].offsetWidth < (width - (width / 3))) {
        
        /*
        * If the chat is live-chat or chat width is equal or less than to live-chat width.
        * - 25 is the rest about padding or margin to the icon menu or container of the message approximately.
        * If the menu can to show to the right.
        */
        if (list[this.appMenuMsg.index].offsetWidth <= 320 && (message[0].offsetWidth + widthMenu - 25) < list[this.appMenuMsg.index].offsetWidth) {
          menu[0].classList.add('left-menu');
          return;
        }

        /*
        * If the chat is live-chat or chat width is equal or less than to live-chat width.
        * If the menu can not to show to the right.
        */
        if (list[this.appMenuMsg.index].offsetWidth <= 320) {
          menu[0].classList.add('right-menu');
          return;
        }

        // If the chat width is more than about live-chat width.
        menu[0].classList.add('left-menu');
        return;
      }

      // To show the menu to the left if the menu can not show to the right.
      menu[0].classList.add('right-menu');
      return;
    }

    /*
    * Right message own.
    * All messages own will be shown width menu to the left.
    */
    if (list[this.appMenuMsg.index].classList.contains('right-msg')) {
      menu[0].classList.add('right-menu');
      return;
    }

    // If the above cases are not applied.
    menu[0].classList.add('left-menu');
  }




  /*
  * Check if the menu must be displayed up or down.
  * Return true to down and false to up.
  */
  isPositionMessageNormal(): boolean {

    let offsetContainerMessages = this.appMenuMsg.c.offsetHeight;
    let list = this.appMenuMsg.c.getElementsByClassName('message_assist');
    let init = this.appMenuMsg.c.getElementsByClassName('start_conversation');
    let index = this.appMenuMsg.index;

    if (!list.length) {
      list = this.appMenuMsg.c.getElementsByClassName('message');
      if (!list.length) {
        return;
      }
    }

    let sum_height_li = 0;
    for (let i = 0, n = (index); i < n; i++) {
      sum_height_li = sum_height_li + list[i].offsetHeight;
    }

    if (init.length) {
      return ((sum_height_li + init[0].offsetHeight) - this.appMenuMsg.c.scrollTop) < (offsetContainerMessages - (offsetContainerMessages / 3));
    }

    return (sum_height_li - this.appMenuMsg.c.scrollTop) < (offsetContainerMessages - (offsetContainerMessages / 3));
  }




  /*
  * Display menu up of the icon menu.
  */
  setMenuUp(): void {

    let element = this._messageElement.nativeElement.getElementsByClassName('actions-menu-message');

    if (element[0].classList.contains('none')) {
      element[0].classList.remove('none');
    } else {
      element[0].classList.add('none');
    }

    if (element[0].classList.contains('up-menu')) {
      element[0].classList.remove('up-menu');
      return;
    }
    
    this._renderer2.addClass(element[0], 'up-menu');
  }




  /*
  * Display menu down of the icon menu.
  */
  setMenuDown(): void {

    let element = this._messageElement.nativeElement.getElementsByClassName('actions-menu-message');

    if (element[0].classList.contains('none')) {
      element[0].classList.remove('none');
    } else {
      element[0].classList.add('none');
    }

    if (element[0].classList.contains('down-menu')) {
      element[0].classList.remove('down-menu');
      return;
    }

    this._renderer2.addClass(element[0], 'down-menu');
  }
}
