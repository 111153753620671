<div class="participant_info">
  <div class="progress-circle" [attr.data-progress]="user.Certification.progress">
    <img [src]="user|urlImage:5">
    <span class="online_dot" *ngIf="user_state"></span>
  </div>

  <div class="participant_name">
    <a class="user_link" [innerHTML]="user.User.name|cutUserName:'2'"
      [href]="environment.appDomain + '/user/profile/' + user.User.id" target="_blank"></a>

    <p *ngIf="user.hasOwnProperty('Country')">
      <img class="user_country_flag" [src]="user.Country | userCountryflagPipe">
      <span [innerHTML]="user.Country.name | toLowerCase" *ngIf="user.Country"></span>
      <span [innerHTML]="'Internacional' | toLowerCase" *ngIf="!user.Country"></span>
    </p>

    <div class="certifications">

      <div class="certifications_empty loading-Element" *ngIf="user.Courses==null"></div>
      <div class="certifications_empty loading-Element" *ngIf="user.Courses==null"></div>

      <div class="studying" *ngIf="user.Courses && user.Courses.Free_certifications">
        <span><i class="icon-notes"></i>Está estudiando </span>
        <a class="course_link" *ngFor="let course of user.Courses.Free_certifications.cursos"
          [href]="environment.domain + '/' + course.slug" target="_blank">
          {{course.nombre | cutCertificationName}}{{user.Courses.Free_certifications.quantity > 1 ? ',': ''}}
        </a>
        <p *ngIf="user.Courses.Free_certifications.quantity > 0">y {{user.Courses.Free_certifications.quantity}} cursos
          más</p>
      </div>
      <div class="certified" *ngIf="user.Courses && user.Courses.Certifications">
        <span><i class="icon-certificate"></i>Se certificó en </span>
        <a class="course_link" *ngFor="let course of user.Courses.Certifications.cursos">
          {{course.nombre | cutCertificationName}}{{user.Courses.Certifications.quantity > 0 ? ',': ''}}
        </a>
        <p *ngIf="user.Courses.Certifications.quantity > 0">y {{user.Courses.Certifications.quantity}} certificados más
        </p>

      </div>
    </div>


    <div class="btn_group">

      <a *ngIf="!hover_assist" class="connection" [class]="(user.Followed != null && user.Followed.followed_id != null) ? 'followed': null"
        [ngClass]="{'disabled': !actions}" (click)="followParticipant.emit(user); user_id_to_follow = true;">
        <i *ngIf="!user_id_to_follow"
          [ngClass]="(user.Followed != null && user.Followed.followed_id != null )? 'icon-tick-outline' : 'icon-follow'">
        </i>

        <span *ngIf="!user_id_to_follow">
          {{ (user.Followed != null  && user.Followed.followed_id != null) ? 'Conectado' : 'Conectar' }}
        </span>

        <div class="spinner spinner--button" *ngIf="user_id_to_follow">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

      </a>

      <a class="button chat" (click)="_openChat(user)" [ngClass]="{'disabled': !actions}">
        <span *ngIf="!conversations_loading">
          <i class="icon-message"></i>
          Mensaje
        </span>

        <div class="spinner spinner--button" *ngIf="conversations_loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </a>

    </div>

  </div>
</div>
