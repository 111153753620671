import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ClassroomService } from 'src/app/classroom/services/classroom.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'reactions-modal',
  templateUrl: './reactions-modal.component.html',
  styleUrls: ['./reactions-modal.component.scss']
})
export class ReactionsModalComponent {

  environment: any = environment;
  @Input() reactions;//reacciones totales realizadas  
  @Input() resumed_reactions;//Reacciones agrupadas por tipo - 1 emoji por cada tipo de reaccion
  @Input() reactions_length;
  @Output() closeReactionsModalEmitter = new EventEmitter<string>();

  constructor(
    public _classroomService: ClassroomService
  ) {

  }



  //Código para poder mover las tabs de las reacciones con scroll horizontal cuando excede el tamaño del popup
  isDown = false;
  startX;
  scrollLeft;

  @ViewChild('itemSlider') itemSlider_;
  mousedown_slider(el) {

    this.isDown = true;
    this.itemSlider_.nativeElement.classList.add('active');
    if (el.changedTouches != null) {
      this.startX = el.changedTouches[0].pageX - this.itemSlider_.nativeElement.offsetLeft;
    } else {
      this.startX = el.pageX - this.itemSlider_.nativeElement.offsetLeft;
    }

    this.scrollLeft = this.itemSlider_.nativeElement.scrollLeft;
  }

  mouseleave_slider() {
    this.isDown = false;
    this.itemSlider_.nativeElement.classList.remove('active');
  }

  mouseup_slider() {
    this.isDown = false;
    this.itemSlider_.nativeElement.classList.remove('active');
  }

  mousemove_slider(el) {
    if (!this.isDown) return;
    el.preventDefault();

    let x;
    if (el.changedTouches != null) {
      x = el.changedTouches[0].pageX - this.itemSlider_.nativeElement.offsetLeft;
    } else {
      x = el.pageX - this.itemSlider_.nativeElement.offsetLeft;
    }

    const walk = (x - this.startX) * 2; //scroll-fast
    this.itemSlider_.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  //End Código para poder mover las tabs de las reacciones con scroll horizontal cuando excede el tamaño del popup


  reaction_filter_selected: string = 'all';
  selectReactionFilter(reaction) {
    this.reaction_filter_selected = reaction;
  }

  show_reactions;
  positionOpenReactionsModal: any = null;
  public openReactionsModal(container_list, element_content, container_id) {
    // container_list - elemento html del listado (ej: listado de mensajes del chat)
    // element_content - elemento html contenedor (ej: mensajes del chat)
    // container_id - id del contenedor del modal
    // container_index - indice del contenedor del modal 
    this.show_reactions = container_id;

    let container_top = container_list.getBoundingClientRect().top;
    let element_top = element_content.getBoundingClientRect().top;
    //Se calcula la posicion del elemento contenedor para saber en que posición se verá el modal
    if ((element_top - 300) < container_top && this._classroomService.data.width_size >= 600) {
      // Si la posición del elemento está por arriba del top del contenedor padre, se mostrará el modal por debajo del top
      this.positionOpenReactionsModal = '-300px';
    } else {
      this.positionOpenReactionsModal = 'null';
    }

    if (this._classroomService.data.width_size < 600) {
      setTimeout(() => {
        let modal_reactions = document.querySelector('.conversation-modal');
        if (modal_reactions) modal_reactions.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 350);
    }

  }

  closeReactionsModal() {
    this.closeReactionsModalEmitter.emit();
    this.show_reactions = null;
    this.positionOpenReactionsModal = 'null';
    this.reaction_filter_selected = 'all';
  }
}
