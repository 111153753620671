import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ClassroomService } from '../../services/classroom.service';

@Component({
  selector: 'slider-menu',
  templateUrl: './slider-menu.component.html',
  styleUrls: ['./slider-menu.component.scss']
})
export class SliderMenuComponent implements OnInit {


  isDown: boolean = false;
  startX: number;
  scrollLeft: number;
  @Input() options: Array<any> = [];
  @Input() typing_chat: boolean = false;
  @Input() option_selected: string;
  @ViewChild('MenuSlider') menuSlider;

  constructor(
    public _appService: AppService,
    public _classroomService: ClassroomService
  ) { }

  ngOnInit() {  
  }


  toggleSidebar(ev) {

    if (ev == 'community') {
      this._classroomService.unread_messages_counter = 0;
    }

    this._classroomService.toggleSidebar('open', ev);

  }





  mousedown_slider(el) {
    this.isDown = true;
    this.menuSlider.nativeElement.classList.add('active');
    if (el.changedTouches != null) {
      this.startX = el.changedTouches[0].pageX - this.menuSlider.nativeElement.offsetLeft;
    } else {
      this.startX = el.pageX - this.menuSlider.nativeElement.offsetLeft;
    }

    this.scrollLeft = this.menuSlider.nativeElement.scrollLeft;
  }

  mouseleave_slider(e) {
    this.isDown = false;
    this.menuSlider.nativeElement.classList.remove('active');
  }

  mouseup_slider(e) {
    this.isDown = false;
    this.menuSlider.nativeElement.classList.remove('active');
  }

  mousemove_slider(el) {
    if (!this.isDown) return;
    el.preventDefault();

    let x;
    if (el.changedTouches != null) {
      x = el.changedTouches[0].pageX - this.menuSlider.nativeElement.offsetLeft;
    } else {
      x = el.pageX - this.menuSlider.nativeElement.offsetLeft;
    }

    const walk = (x - this.startX) * 2; //scroll-fast
    this.menuSlider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }



  moveSlider() {
    let slider: HTMLElement = this.menuSlider.nativeElement;
    const { left } = slider.getBoundingClientRect();
    slider.scrollLeft = left;
  }


  // moveToItem() {
  //   setTimeout(() => {

  //     let slider = this.menuSlider.nativeElement;
  //     var middle = slider.children[Math.floor((slider.children.length - 1) / 2)];//Elemento del centro       
  //     let item_active: HTMLElement = document.querySelector('.slider_item.active');

  //     if (this.menuSlider != null && item_active != null) this.menuSlider.nativeElement.scrollLeft = (item_active != null && item_active.offsetLeft < middle.offsetLeft) ? 0 : (middle.offsetLeft + middle.offsetWidth / 2 - slider.offsetWidth / 2);

  //   }, 1000);
  // }




}
