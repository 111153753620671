<div class="live-chat__container" [ngClass]="live_chat_state">

  <div class="live-chat__header" (click)="toggleLiveChat()">

    <div class="chat_group_image" *ngIf="chat_user.data.associated.length > 1; else avatarLiveChatUser">
      <img [src]="chat_user.data.associated[0] | urlImage:4">
      <img [src]="chat_user.data.associated[1] | urlImage:4">
    </div>

    <ng-template #avatarLiveChatUser>
      <img [src]="chat_user?.data | urlImage:3">
    </ng-template>

    <!-- <img [src]="chat_user?.data | urlImage:3"> -->

    <div *ngIf="chat_user.data.associated.length === 1"
      [class]="'circle-status-'+chat_user.data?.associated[0].user.status"></div>

    <div class="user_label">

      <div class="spinner dotted typing_message transparent" style="max-height: 40px;"
        *ngIf="((live_chat_state === 'collapsed' ) && ((_appService.conversations[_appService.current_conversation_index]?.conversation && chat_user?.data.conversation) && (_appService.conversations[_appService.current_conversation_index]?.conversation.id === chat_user?.data.conversation.id) && ( _appService.conversations[_appService.current_conversation_index].input_state !== 'NONE' &&  _appService.conversations[_appService.current_conversation_index].input_state))); else user_name">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <ng-template #user_name>
        <p (click)="openModal($event, 'info')">
          {{chat_user.data | groupNamePipe}}
          <span style="font-weight: 400; font-style: italic;"
            *ngIf="(_appService.conversations[_appService.current_conversation_index]?.conversation && chat_user?.data.conversation) && (_appService.conversations[_appService.current_conversation_index]?.conversation.id === chat_user?.data.conversation.id) && ( _appService.conversations[_appService.current_conversation_index].input_state !=='NONE')">
            {{_appService.conversations[_appService.current_conversation_index].input_state}}
            <!-- <div class="active-dot"></div> -->
          </span>
        </p>

      </ng-template>


      <div class="actions-wrapper" *ngIf="controlAdd">

        <i class="icon-user-add" (click)="openModal($event, 'add')"
          *ngIf="chat_user.data.state === 'ACCEPTED' &&chat_user.data.user_role === 'ADMINISTRATOR'"></i>


        <i *ngIf="chat_user.data.associated.length === 1 && chat_user.data.associated[0].user.user_role === 'assistant'" class="icon-cam" (click)="openMeet($event)"></i>

      </div>


    </div>


    <div class="btn-group">
      <button class="btn icon-minus"></button>
      <button class="btn icon-cross" (click)="closeChat($event)"></button>
    </div>

  </div>


  <div class="live-chat__content" [hidden]="live_chat_state=='collapsed'">
    <chat-room class="inside" [chat_user]="chat_user" #chat_room [input_state]="chat_user.data?.input_state"
      (closeThisLiveChat)="closeChat($event)"></chat-room>
  </div>

</div>