import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UrlLambdaApi } from '../models/models';
import { SortOpinionByClosestDate, CutUserNamePipe } from '../pipes/community.pipe';
import { gql, QueryRef } from 'apollo-angular';
import { IoTThingsGraph } from 'aws-sdk';
import { responsePathAsArray } from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  communityQuery: QueryRef<any>;

  constructor(
    private http: HttpClient,
    public _appService: AppService
  ) { }


  public actions;


  public pages = {
    source: 0,
    my_questions: 0,
    no_answers: 0
  }


  public data = {
    comments: null,
    participants: null,
    comments_answer: null
  }

  public config: any = {
    users: false,
    comments: false,
    contributions: false,
    participate: false
  }

  public answers_parentId: string;

  public certification: Object | any;

  public switcher = {
    youtube: false,
    local: false,
    viewer: false,
    modal: { state: false, option: null }
  }

  public img_formats = ['jpg', 'jpeg', 'png', 'gif'];

  public language_id = '2'; // solo de prueba (debe venir en el certification)

  public params = {
    token: null,
    config: null
  }

  public data_comments = {
    source: null,
    no_answers: null,
    my_questions: null,
    search: null,
    recent: null,
    popular: null,
    my_questions_sidebar: null,
  }

  public commentsPages = {
    source: null,
    no_answers: null,
    my_questions: null,
    search: null,
    recent: null,
    popular: null,
    my_questions_sidebar: null,
  }


  answers = {
    l: [],
    na: [],
    mq: [],
    search: [],
    rec: [],
    pop: [],
    mqs: []
  }

  participate_from_forums = {
    forum_id: null,
    state: false
  }



  //Contador de pagina actual
  public usersPages = {
    recent: null,
    popular: null,
    followers: null
  };

  //Cantidad de paginas totales
  public participantsPages = {
    recent: 0,
    popular: 0,
    followers: 1
  }

  public participants = {
    recent: null,
    popular: null,
    followers: null,
    now: null
  }

  public copy_participants = {
    recent: null,
    popular: null,
    followers: null
  }

  public comments_by_popular_loaded = false;

  getHeaders(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.params.token
    })

    this.params.config = JSON.parse(sessionStorage.getItem('config'));
    return ({
      headers: headers, params: this.params.config
    });
  }

  getHeadersLambda(): any {

    this.params.token = this._appService.token;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.params.token
    })
    this.params.config = JSON.parse(sessionStorage.getItem('config'));

    return ({ headers: headers });
  }



  post(url: any, formData: FormData) {
    return this.http.post(url, formData, this.getHeaders());
  }


  getCommentsPages(method, source): Observable<any> {

    let config = JSON.parse(sessionStorage.getItem('config'));

    let url = '';
    if (method == 'source') url = `&pages=true`;
    if (method == 'no_answers') url = `&curso_id=${config.curso_id}&count_children=0&pages=true`;
    if (method == 'my_questions') url = `&curso_id=${config.curso_id}&user_id=${config.idu}&pages=true`;

    //paginación del sidebar
    if (method == 'recent') url = `?curso_id=${config.curso_id}&pages=true`;
    if (method == 'popular') url = `?curso_id=${config.curso_id}&pages=true`;
    if (method == 'my_questions_sidebar') url = `?curso_id=${config.curso_id}&user_id=${config.idu}&estado=[0,1]&pages=true`;

    const { actions } = this._appService.config;

    // let url_source_or_quiz = source.type == '5' ? 'quiz_id' : 'source_id';

    // let url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}?${url_source_or_quiz}=${source.id}${url}&order_by="id"&order="desc"`;

    let url_source_or_quiz = source.type == '5' ? 'quiz_id' : 'source_id';

    let url_comment = '';

    if (method == 'recent' || method == 'popular' || method == 'my_questions_sidebar') {
      if (method == 'recent') {
        url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}${url}&order_by="id"&order="desc"`;
      }
      if (method == 'popular') {
        url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}${url}&order_by="like"&order="desc"`;
      }

      if (method == 'my_questions_sidebar') {
        url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}${url}`;
      }

      url_comment = url_comment + '&quiz_id=0';
    } else {
      url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}?${url_source_or_quiz}=${source.id}${url}&order_by="id"&order="desc"`;
    }



    return this.http.get(url_comment, this.getHeadersLambda())
      .pipe(map((comments: any) => {
        const { status, pages } = comments;

        let data_return = null;
        if (status) {
          data_return = pages;
        }

        return data_return;
      }),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getCommentsPages', []))
      );
  }


  getComments(method, source, LastEvaluatedKey?): Observable<any> {

    let config = JSON.parse(sessionStorage.getItem('config'));


    let url = '';
    if (method == 'source') url = `&curso_id=${config.curso_id}&clase_id=${config.clase_id}`;
    if (method == 'no_answers') url = `&curso_id=${config.curso_id}&count_children=0&clase_id=${config.clase_id}`;
    if (method == 'my_questions') url = `&curso_id=${config.curso_id}&clase_id=${config.clase_id}&user_id="${config.idu}"`;
    if (method == 'recent') url = `?curso_id=${config.curso_id}`;
    if (method == 'popular') url = `?curso_id=${config.curso_id}`;
    if (method == 'my_questions_sidebar') url = `?curso_id=${config.curso_id}&user_id="${config.idu}"`;

    const { actions } = this._appService.config;


    let url_source_or_quiz = source.type == '5' ? 'quiz_id' : 'source_id';

    let url_comment = '';

    if (method == 'recent' || method == 'popular' || method == 'my_questions_sidebar') {
      if (method == 'recent') {
        // url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}${url}&order_by="id"&order="desc"`;
        url_comment = `${UrlLambdaApi}/comments/recent${actions ? '/votes' : ''}${url}`;
      }
      if (method == 'popular') {
        // url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}${url}&order_by="like"&order="desc"`;
        url_comment = `${UrlLambdaApi}/comments/popular${actions ? '/votes' : ''}${url}`;
      }

      if (method == 'my_questions_sidebar') {

        url_comment = `${UrlLambdaApi}/comments${actions ? '/votes' : ''}${url}`;
      }

      // url_comment = url_comment + '&quiz_id=0';
    } else {

      if (url_source_or_quiz == 'quiz_id') {

        url_comment = `${UrlLambdaApi}/comments/recent${actions ? '/votes' : ''}?${url_source_or_quiz}="${source.id}"${url}&source_id=0`;

      } else {

        url_comment = `${UrlLambdaApi}/comments/recent${actions ? '/votes' : ''}?${url_source_or_quiz}=${source.id}${url}`;

      }

    }

    if (LastEvaluatedKey != null) {

      url_comment = `${url_comment}&LastEvaluatedKey=${JSON.stringify(LastEvaluatedKey)}`;

    }

    return this.http.get(url_comment, this.getHeadersLambda())
      .pipe(map((comments: any) => {
        const { data, status, data_dynamo } = comments;

        let data_return = [];
        if (status) {
          data_return = JSON.parse(JSON.stringify(data));
          if (data_dynamo) {
            if (data_dynamo.length > 0 && data_dynamo[0].hasOwnProperty('LastEvaluatedKey')) {
              this.commentsPages[method] = data_dynamo[0];
            }
            if (data_dynamo.length == 0) {
              this.commentsPages[method] = null;
            }

          }
        } else {
          this.commentsPages[method] = null;
        }

        return data_return;
      }),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getComments', []))
      );
  }


  getChildren(parent_id): Observable<any> {

    const { actions } = this._appService.config;
    // https://api.edutin.com/b/d
    // return this.http.get(`${UrlLambdaApi}/children_comments${actions ? '/votes' : ''}?comment_id=${parent_id}&order_by=%22id%22&order=%22desc%22`, this.getHeadersLambda())
    return this.http.get(`${UrlLambdaApi}/children_comments${actions ? '/votes' : ''}?comment_id=${parent_id}`, this.getHeadersLambda())
      .pipe(
        map((children: any) => {

          const { data, status } = children;

          let data_return = [];
          if (status) {

            data.forEach((item, index) => {
              item.ChildrenComment.index = index;

              if (index == (data.length - 1)) {
                data_return = JSON.parse(JSON.stringify(data));
              }
            });

          }

          return data_return;
        }),
        catchError(this.handleError<any>('getChildren', []))
      );
  }

  getOpinions(children_id): Observable<any> {

    // this._getOpinions(`/opinions_comments.json?parent_id=${children_id}`).subscribe(console.log);
    return this.http.get(`${UrlLambdaApi}/opinions?children_comment_id=${children_id}&order_by="id"&order="desc"`, this.getHeadersLambda())
      .pipe(map((opinions: any) => {

        let { data, status } = opinions;

        let data_return = [];
        if (status) {

          data = new SortOpinionByClosestDate().transform(data);

          data_return = JSON.parse(JSON.stringify(data));

        }

        // opinions.Data = JSON.parse(JSON.stringify(opinions.opinions_comments));
        return data_return;
      }),
        catchError(this.handleError<any>('getOpiniones', []))
      );
  }


  addOpinion(data_opinion, url): Observable<any> {
    if (url == '/opinions_comments_add.json') {
      //add opinion (post)
      return this.addNewOpinion(data_opinion);
    }

    if (url == '/opinions_comments_edit.json') {
      //edit opinion (put)
      return this.editOpinion(data_opinion);
    }

  }


  addNewOpinion = (data_opinion) => new Observable((observer) => {

    let url_post = '/opinions';
    this.postItem(url_post, data_opinion).subscribe(opinion_ => {
      observer.next(opinion_);
    })

    // return of('addNewOpinion');
  });

  editOpinion(data_opinion): Observable<any> {
    let opinion: any = {
      data: {
        description: data_opinion.description
      }
    };
    return this.http.put(`${UrlLambdaApi}/opinions/${data_opinion.id}`, opinion, this.getHeadersLambda())
      .pipe(
        map((opinion: any) => {

          if (opinion.status) {
            opinion.data = JSON.parse(JSON.stringify(data_opinion));
          }

          return opinion
        }),
        catchError(this.handleError('editOpinion'))
      );
  }

  getParticipantsPages(filter): Observable<any> {

    let config = JSON.parse(sessionStorage.getItem('config'));

    const { actions } = this._appService.config;

    let url = '';

    if (actions) {
      if (filter != 'followers') {
        url = `/participants/${config.curso_id}/${filter}?followed_by=${config.idu}&order_by="role"&pages=true`;
      } else {
        url = `/followers?curso_id=${config.curso_id}&pages=true`;
      }

    } else {
      url = `/participants/${config.curso_id}/${filter}?order_by="role"&pages=true`;
    }



    return this.http.get(UrlLambdaApi + url, this.getHeadersLambda())
      .pipe(map((comments: any) => {
        const { status, pages } = comments;

        let data_return = null;
        if (status) {
          data_return = pages;
        }


        return data_return;
      }),
        // tap(_ => this.log('fetched comments')),
        catchError((error: any) => {
          this.handleError('getParticipantsPages');
          return of(1);
        })
      );
  }

  getParticipants(url, filter): Observable<any> {
    return this.http.get(UrlLambdaApi + url, this.getHeadersLambda())
      .pipe(
        map((participants: any) => {

          const { data, status, metadata } = participants
          let data_return = [];

          if (status) {
            if (data != null && data.length > 0) {
              data.forEach((user, index) => {
                user['index'] = index;
                user.User.name = new CutUserNamePipe().transform(user.User.name, '2');
              });
            }

            data_return = JSON.parse(JSON.stringify(data));

          }

          // if (metadata && metadata.hasOwnProperty('last_evaluated_key')) {
          //   const { last_evaluated_key } = metadata;
          //   this.usersPages[filter] = { last_evaluated_key };
          // }
          // console.log('_newGetParticipants: '+url, participants);
          return data_return
        }), retry(3),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getParticipans', []))
      );
  }

  _newgetParticipants(url, filter): Observable<any> {

    // let url = '';
    // if (this._appService.config.actions) {
    //   url = `/participants/${curso_id}?followed_by=${idu}&page=${page}&order_by="role"&order="asc"`;
    // } else {
    //   url = `/participants/${curso_id}?page=${page}&order_by="role"&order="asc"`;
    // }

    // this._newGetParticipants(url, filter).subscribe(resp => {

    // })

    return this.http.get(UrlLambdaApi + url, this.getHeadersLambda())
      .pipe(
        map((participants: any) => {

          const { data, status } = participants
          let data_return = [];

          if (status) {
            if (data != null && data.length > 0) {
              data.forEach((user, index) => {
                user['index'] = index;
                user.User.name = new CutUserNamePipe().transform(user.User.name, '2');
              });
            }

            data_return = JSON.parse(JSON.stringify(data));

          }


          return data_return
        }), retry(3),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getParticipans', []))
      );
  }


  searchParticipants(data): Observable<any> {

    const { curso_id, idu } = this.params.config;
    let url = '';

    if (this._appService.config.actions) {
      url = `/participants/${curso_id}?followed_by=${idu}&page=${data.page}&name="${data.part}"`;
    } else {
      url = `/participants/${curso_id}?page=${data.page}&name="${data.part}"`;
    }


    // return this.http.post(`${UrlApi}/search_participants.json`, data, this.getHeaders())
    return this.http.get(UrlLambdaApi + url, this.getHeadersLambda())
      .pipe(

        catchError(this.handleError('searchParticipants'))

      );
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  private log(message: string) {
    // console.log(`ClassroomService: ${message}`);
  }



  deleteItem(url_delete): Observable<any> {

    let tmp_url_delete = `${UrlLambdaApi}${url_delete}`;

    // if (!url_delete.includes('https://api.edutin.com/')) {
    //   tmp_url_delete = `${UrlLambdaApi}${url_delete}`;
    // }

    // if (url_delete.includes('/comments/')) {
    //   if (!url_delete.includes('https://api.edutin.com/')) {
    //     tmp_url_delete = `${UrlLambdaApi}${url_delete}`;
    //   } else {
    //     tmp_url_delete = `${url_delete}`;
    //   }
    // }

    // return this.http.delete(`${UrlLambdaApi}${url_delete}`, this.getHeadersLambda())
    return this.http.delete(tmp_url_delete, this.getHeadersLambda())
      .pipe(

        catchError((error: any) => {
          this.handleError('deleteItem');
          return of({ status: 'error', error });
        })
      );
  }




  forumsEvaluate(source) {

    let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;

    let foro = {
      Quiz: {
        id: source.id,
        curso_id: source.curso_id,
        clase_id: source.clase_id,
        weight: source.weight,
        certification_id: this.params.config.certification_id,
        attempt_id: source_hasAttemps ? source.Attempt[0].id : 0
      }
    }


    let data = {
      quiz_id: source.id,
      certification_id: this.params.config.certification_id,
      curso_id: source.curso_id,
      weight: source.weight,
      attempt_id: source_hasAttemps ? source.Attempt[0].id : 0,
      user_id: this.params.config.idu
    }


    return this.http.post(`${UrlLambdaApi}/forums`, { data }, this.getHeadersLambda()).pipe(
      catchError(this.handleError('forumsEvaluate'))
    );


    // return this.http.post(`${UrlApi}/forums_evaluate.json`, foro, this.getHeaders()).pipe(
    //   catchError(this.handleError('forumsEvaluate'))
    // );
  }



  public item_to_edit: any;
  public full_view = {
    state: false,
    indexItem: null //index  del item que se está visualizando
  }

  config_copy: any;
  openParticipate(ev) {

    this.config[ev.component] = (this.config[ev.component] == true || this.config[ev.component] == 'full') ? 'active' : this.config[ev.component];
    this.config.participate = true;
    this.item_to_edit = ev.item;

  }

  closeParticipateComponent(component) {

    this.item_to_edit = null;
    this.config.participate = false;
 
    // if (this.full_view.state) {
    //   this.config[component] = 'full';
    // } else {
    //   this.config[component] = true;
    // }

    setTimeout(() => {
      this.config.participate = true;
    }, 10);

  }

  openFullView(component) {


    this.config[component] = this.config[component] == true ? 'full' : this.config[component];
    this.config.participate = false;

  }

  closeFullView(component) {

    this.config[component] = this.config[component] == 'full' ? true : this.config[component];

  }


  getCommentsRedirect(comment_id): Observable<any> {

    return this.http.get(UrlLambdaApi + '/comments/' + comment_id, this.getHeadersLambda())
      .pipe(map((comment: any) => {

        const { data, status } = comment;

        let data_return = null;
        if (status) {

          data.forEach((item, index) => {
            if (index == (data.length - 1)) {
              data_return = {
                comments: JSON.parse(JSON.stringify(data))
              }
            }
          });

        }

        return data_return;
      }),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getComments', []))
      );
  }



  messageClasification(message, type, comment_id, idu): Observable<any> {
    // https://api.edutin.com/b/d/message_clasification?message="esto tal vez robo pueda ser"
    let _type = '';

    switch (type) {
      case 'question':
      case 'participation':
        _type = '&type="comment"';
        break;
      case 'answer':
      case 'opinion':
        _type = '&type="children_comment"';
        break;

    }



    return this.http.get(`${UrlLambdaApi}/message_clasification?message="${message}"${_type}&id=${comment_id}&user_id=${idu}`, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('postItem');
        return of({ status: false });
      })
    );
  }



  addItem = (url, data) => new Observable((observer) => {

    switch (url) {
      case '/comments_add.json':
        this.postItem(`/comments`, data).subscribe(resp => {
          observer.next(resp);
        });


        break;

      case '/children_comments_add.json':

        this.postItem(`/children_comments`, data).subscribe(resp => {
          observer.next(resp);
        });


        break;

      case "/comments_edit.json":
      case '/children_comments_edit.json':


        data.url = url;
        this.editComment(data).subscribe((resp: any) => {

          observer.next(resp);

        })

        break;

    }



  });



  saveSuggestion(user_id, user_id1, priority) {

    /*
      user_id = id del usuario que envía la solicitud
      user_id1 = id del usuario que será relacionado para sugerencia
      priority = tipo de prioridad
        priority = {
            'profile_visit': 5,
            'message_reply': 4,
            'comment_reply': 4,
            'like': 2,
        }
     */

    // guardar usuarios sugeridos para seguir


    this.postItem('/suggestions', { user_id, user_id1, priority }).subscribe(repsonse => {
      if (!repsonse.status) {
        this.putItem(`/suggestions/${user_id}/${user_id1}`, { priority }).subscribe()

      }
    })

  }

  postItem(url_post, data): Observable<any> {
    // return this.http.post(`https://api.edutin.com/b/d${url_post}`, { data }, this.getHeadersLambda())
    return this.http.post(`${UrlLambdaApi}${url_post}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('postItem');
        return of({ status: false });
      })
    );
  }

  putItem(url_put, data): Observable<any> {
    return this.http.put(`${UrlLambdaApi}${url_put}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('putItem');
        return of({ status: false });
      })
    );
  }



  edit_comment_index = 0;
  edit_files_length = 0;
  edit_comment;
  // async editComment(data_comment): Promise<any> {
  editComment = (data_comment) => new Observable((observer) => {


    this.edit_comment = JSON.parse(JSON.stringify(data_comment));

    let Files = data_comment.extra_data.files_comments;
    this.edit_files_length = Files.length;

    let X = {

      addNewFile: (data): Observable<any> => {
        let File = JSON.parse(JSON.stringify(Files[this.edit_comment_index]));
        let url_post = (data_comment.url == "/comments_edit.json") ? `/comments/${data_comment.id}/files` : `/children_comments/${data_comment.id}/files`;
        delete File.id;
        delete File.state;

        // return data
        return this.http.post(`${UrlLambdaApi}${url_post}`, { data: File }, this.getHeadersLambda())
          .pipe(
            catchError(this.handleError('addNewFile'))
          );
      },

      editFile: (data, url): Observable<any> => {
        // return of(id)        

        let file_to_send: any = {
          description: data.description,
          file_ext: data.file_ext,
          file_name: data.file_name,
          file_url: data.file_url,
          language: data.language,
          link: data.link,
          state: data.state,
          type: data.type
        }

        let tmp_url = `${UrlLambdaApi}${url}`;
        const { curso_id, clase_id, source_id, user_id } = data_comment;

        if (data.hasOwnProperty('comment_id') && data.comment_id != null) {
          //Si es un comment (Parent)          
          tmp_url = `${UrlLambdaApi}/comments/${user_id}/${curso_id}/${clase_id}/${source_id}/"${data_comment.fecha}"/files/${data.id}`;
        }

        if (data.hasOwnProperty('children_comment_id') && data.children_comment_id != null && data.children_comment_id != "None") {
          // console.log('%c Es un children ', 'color:blue; background:white');
          // https://api.edutin.com/b/d/children_comments/2631/314025/"2022-06-15T15:35:35"/3135959/files/626784
          tmp_url = `${UrlLambdaApi}/children_comments/${curso_id}/${data_comment.comment_id}/"${data_comment.fecha}"/${user_id}/files/${data.id}`;
        }

        file_to_send = {
          "files_comments": file_to_send
        }


        return this.http.put(tmp_url, { data: file_to_send }, this.getHeadersLambda())
          .pipe(
            map((opinion: any) => {

              if (opinion.status) {
                opinion.data = JSON.parse(JSON.stringify(data));
              }
              return opinion
            }),
            catchError((error: any) => {
              this.handleError('editFile');
              return of({ status: 'error', error });
            })
          );
      },

      deleteFile: (url_delete) => new Observable((observer_delete) => {

        this.deleteItem(url_delete).subscribe(resp => {
          observer_delete.next(resp);
          observer_delete.complete();
        })

      }),


      setResponse: (response) => {
        if (response.status === true) {

          this.edit_comment_index++;

          if (this.edit_comment_index < this.edit_files_length) {
            X.chooseEvent();
          }

          if (this.edit_comment_index == this.edit_files_length) {

            let resp = {
              status: true,
              data: JSON.parse(JSON.stringify(Files))
            }

            this.edit_comment_index = 0;
            this.edit_files_length = 0;
            observer.next(resp);
            observer.complete();
          }
        }

        if (!response.status || response.status == 'error') {

          let resp = {
            status: false,
          }

          this.edit_comment_index = 0;
          this.edit_files_length = 0;

          observer.next(resp);
          observer.complete();

        }
      },

      chooseEvent: () => {

        let File = JSON.parse(JSON.stringify(Files[this.edit_comment_index]));
        const { state } = File;

        if (state == 2 || state == '2') {
          //Si el archivo está siendo editado

          if (Files[this.edit_comment_index].id != null && Files[this.edit_comment_index].id != '' && Files[this.edit_comment_index].id != '0') {

            //if is a file edited
            let url = '';
            if (data_comment.url == "/comments_edit.json") url = `/comments/${File.comment_id}/files/${File.id}`;
            if (data_comment.url == "/children_comments_edit.json") url = `/children_comments/${File.children_comment_id}/files/${File.id}`;


            X.editFile(File, url).subscribe(async (editResponse: any) => {

              X.setResponse(editResponse);

            })

          }

          if (Files[this.edit_comment_index].id == null || Files[this.edit_comment_index].id == '' || Files[this.edit_comment_index].id == '0') {
            //if is a new file
            X.addNewFile(Files[this.edit_comment_index]).subscribe(addResponse => {

              Files[this.edit_comment_index] = JSON.parse(JSON.stringify(addResponse.data));

              X.setResponse(addResponse);

            });

          }
        } else if (state == "-1" || state == -1) {
          //if the file is deleted

          let File = JSON.parse(JSON.stringify(Files[this.edit_comment_index]));

          let url = '';
          if (data_comment.url == "/comments_edit.json") {
            // url = `/comments/${File.comment_id}/files/${File.id}`;

            const { curso_id, clase_id, source_id, user_id } = data_comment;
            url = `/comments/${user_id}/${curso_id}/${clase_id}/${source_id}/"${data_comment.fecha}"/files/${File.id}`;

          }
          if (data_comment.url == "/children_comments_edit.json") {

            const { curso_id, comment_id, user_id } = data_comment;
            url = `/children_comments/${curso_id}/${comment_id}/"${data_comment.fecha}"/${user_id}/files/${File.id}`;

          }

          X.deleteFile(url).subscribe(async (deleteResponse: any) => {
            X.setResponse(deleteResponse);
          });

        } else {
          X.setResponse({ status: true });
        }
      }

    }


    X.chooseEvent();


  });




  clearData() {

    this.actions = null;


    this.pages = {
      source: 0,
      my_questions: 0,
      no_answers: 0
    }


    this.data = {
      comments: null,
      participants: null,
      comments_answer: null
    }

    this.config = {
      users: false,
      comments: false,
      contributions: false,
      participate: false
    }

    this.answers_parentId = '';

    this.certification = null;

    this.switcher = {
      youtube: false,
      local: false,
      viewer: false,
      modal: { state: false, option: null }
    }

    this.params = {
      token: null,
      config: null
    }

    this.data_comments = {
      source: null,
      no_answers: null,
      my_questions: null,
      search: null,
      recent: null,
      popular: null,
      my_questions_sidebar: null,
    }

    this.commentsPages = {
      source: null,
      no_answers: null,
      my_questions: null,
      search: null,
      recent: null,
      popular: null,
      my_questions_sidebar: null,
    }

    this.usersPages = {
      recent: null,
      popular: null,
      followers: null
    };


    this.answers = {
      l: [],
      na: [],
      mq: [],
      search: [],
      rec: [],
      pop: [],
      mqs: []
    }

    this.edit_comment_index = 0;
    this.edit_files_length = 0;
    this.edit_comment = null;

  }
  popularStatusLoaded: boolean = false;
  recentStatusLoaded: boolean = false;
  connectionStatusLoaded: boolean = false;
  loadUsersStatus(arr, type) {
    const ws_headers = {
      "Authorization": AppService.idtoken,
      "host": this._appService.ws_host
    };
    arr.forEach(e => {
      e.User.status = 'offline';

      this._appService.statusQuery = this._appService._apolloClient.watchQuery({
        query: AppService.GET_STATUS,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          id: e.User.id
        }
      });

      this._appService.statusQuery.valueChanges.pipe(
        map((response: any) => {
          switch (type) {
            case 'popular':
              this.popularStatusLoaded = true;
              break;
            case 'recent':
              this.recentStatusLoaded = true;
              break;
            case 'follower':
              this.connectionStatusLoaded = true;
              break;
          }
          e.User.status = response.data.status.status;
          return response;
        })
      ).subscribe((data: any) => { });

      /*this._appService.statusQuery.subscribeToMore({
        document: AppService.STATUS,
        variables: {
          id: e.User.id
        },
  
        extensions: {
          authorization: ws_headers
        },
        updateQuery: (prev, { subscriptionData }) => {
          //evaluate to proccess a subscription if no message is sended
          //replacing old object
          e.User.status = subscriptionData.data.onStatus.status;
        }
      });*/
    });
  }

  connectUserCourse(connect = true, config_?): Observable<any> {
    //(":::connecting to course...:::");
    var config = JSON.parse(sessionStorage.getItem('config'));

    let variables: any;

    if (connect) {
      variables = {
        id: config.idu,
        course_id: config.curso_id
      };
    };
    if (!connect) {
      variables = {
        id: config_.idu,
        course_id: config_.curso_id
      };
    };



    return this._appService._apolloClient.mutate({
      mutation: connect ? AppService.CONNECT_USER_BY_COURSE : AppService.DISCONNECT_USER_COURSE,
      variables: variables
    });
  }

  heartbeatObservable: Observable<any>;
  setCourseHeartBeat() {
    let config = JSON.parse(sessionStorage.getItem('config'));

    this._appService.heartbeatQuery = this._appService._apolloClient.watchQuery({
      query: AppService.HEARTBEAT_BY_COURSE,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        id: config.idu,
        course_id: config.curso_id
      }
    });
    this.heartbeatObservable = this._appService.heartbeatQuery.valueChanges.pipe(
      map((response: any) => {
        return response;
      })
    );

  }


  sendCourseHeartBeat() {
    this.heartbeatObservable.subscribe((data: any) => {
    });
  }

  public ws_host = 'tcm5oi5qdveqpamqp4txivk54q.appsync-api.us-east-1.amazonaws.com';
  initCourseSubscription() {
    const ws_headers = {
      "Authorization": AppService.idtoken,
      "host": this.ws_host
    };
    var config = JSON.parse(sessionStorage.getItem('config'));
    this.communityQuery.subscribeToMore({
      document: AppService.COURSE_STATUS,
      variables: {
        course_id: config.curso_id
      },

      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {
        //evaluate to proccess a subscription if no message is sended
        if (subscriptionData.data.onStatus.id != config.idu) {
          // console.log(":::subscriptionData:::", subscriptionData);      
          // console.log(":::this.participants['now']:::", this.participants['now']);    

          let usr = this.participants['now'].findIndex(u => u.User.id == subscriptionData.data.onStatus.id);
          if (subscriptionData.data.onStatus.status == "online") {
            if (usr == -1) {
              //Si el usuario no existe en la lista, se agrega a la lista de conectados      
              this.getUsersData([subscriptionData.data.onStatus.id]).subscribe((data: any) => {

                if (data.data[0]) this.participants['now'].unshift(data.data[0]);

                // setTimeout(() => {
                //   //enviar notificación cada vez que se conecta alguien
                //   if (this.participants['now'].length > 0 && this.participants['now'][this.participants['now'].length - 1]) this._appService.onlineUserNotification(data.data[0]);
                // }, 100);

              });
            }
          } else {
            if (usr > -1) {
              //Si el usuario se desconecta se elimina de la lista
              this.participants['now'].splice(usr, 1);
            }
          }


        }

      }
    });
  }


  loadConnectedUsers(): Observable<any> {
    let config = JSON.parse(sessionStorage.getItem('config'));

    this.communityQuery = this._appService._apolloClient.watchQuery({
      query: AppService.GET_COURSE_CONNECTED_USERS,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables: {
        course_id: config.curso_id
      }
    });


    return this.communityQuery.valueChanges.pipe(
      map((usr: any) => {
        return usr;
      })
    );
  }


  getUsersData(users): Observable<any> {
    let config = JSON.parse(sessionStorage.getItem('config'));

    return this.http.get(UrlLambdaApi + '/participants/' + config.curso_id + '?user_id=[' + users.toString() + ']' + '&followed_by=' + config.idu
      , this.getHeadersLambda())
      .pipe(map((users: any) => {
        return users;
      }),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getUsersData', []))
      );
  }


  getUsersCertifications(user): Observable<any> {
    //Obtener los certificados que está estudiando el usuario y los certificados que ha comprado
    let url = `${UrlLambdaApi}/users/${user.id}/certifications`;

    return this.http.get(url, this.getHeadersLambda())
      .pipe(map((response: any) => {

        const { data, status } = response;
        let certifications = null
        if (status) {
          certifications = data;
        }

        return certifications;
      }),
        // tap(_ => this.log('fetched comments')),
        catchError(this.handleError<any>('getUsersCertifications', []))
      );
  }
}
