import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SharedService } from './shared.service';
import { FooterComponent } from './components/footer/footer.component';


import { CutUserNamePipe, GetProgress, RoleName, UserClassName, UrlImage, shoppingCarType, notificationsDate, messageDate, categoryName, CssChildHeight, CourseConfigUrl, GroupNamePipe, UserCountryflagPipe, ToLowerCase, CutCertificationNamePipe, GroupEmojiTypePïpe, FilterByReactionPipe, ReactionEmojiPipe, SrcFileIconPipe } from './shared.pipe';
import { ClickOutsideDdownDirective } from './directives/clickOutsideDdown.directive';
import { RouterModule } from '@angular/router';
import { CourseDetailComponent } from './components/course-detail/course-detail.component';
import { NotificationsComponent } from './components/nav-bar/notifications/notifications.component';
import { ConversationsComponent } from './components/nav-bar/conversations/conversations.component';
import { PopupMsgComponent } from './components/popup-msg/popup-msg.component';
import { ShoppingCartComponent } from './components/nav-bar/shopping-cart/shopping-cart.component';
import { FrequentQuestionsComponent } from './components/frequent-questions/frequent-questions.component';
import { HoverCardComponent } from './components/hover-card/hover-card.component';

import { MainChatRoomAssistService } from '../assist/components/chat-room-assist/main-chat-room-assist.service';
import { ReactionsComponent } from './components/reactions/reactions.component';
import { ReactionsBubbleComponent } from './components/reactions/reactions-bubble/reactions-bubble.component';
import { ReactionsModalComponent } from './components/reactions/reactions-modal/reactions-modal.component';
import { DniModalComponent } from './components/dni-modal/dni-modal.component';
import { ConnectionsComponent } from './components/nav-bar/connections/connections.component';
import { MenuCategoriesComponent } from './components/nav-bar/menu-categories/menu-categories.component';
import { NewCoursesComponent } from './components/new-courses/new-courses.component';


@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent,
    CourseDetailComponent,
    CutUserNamePipe,
    GetProgress,
    RoleName,
    UserClassName,
    UrlImage,
    shoppingCarType,
    notificationsDate,
    messageDate,
    categoryName,
    GroupNamePipe,
    CssChildHeight,
    UserCountryflagPipe,
    ToLowerCase,
    CutCertificationNamePipe,
    GroupEmojiTypePïpe,
    FilterByReactionPipe,
    ReactionEmojiPipe,
    SrcFileIconPipe,
    CourseConfigUrl,
    ClickOutsideDdownDirective,
    NotificationsComponent,
    ConversationsComponent,
    PopupMsgComponent,
    ShoppingCartComponent,
    FrequentQuestionsComponent,
    HoverCardComponent,
    ReactionsComponent,
    ReactionsBubbleComponent,
    ReactionsModalComponent,
    ConnectionsComponent,
    MenuCategoriesComponent,
    NewCoursesComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    CourseDetailComponent,
    ClickOutsideDdownDirective,
    PopupMsgComponent,
    FrequentQuestionsComponent,
    categoryName,
    CssChildHeight,
    CourseConfigUrl,
    GroupNamePipe,
    UrlImage,
    GroupEmojiTypePïpe,
    FilterByReactionPipe,
    HoverCardComponent,
    CutCertificationNamePipe,
    ReactionEmojiPipe,
    SrcFileIconPipe,
    ReactionsComponent,
    ReactionsBubbleComponent,
    ReactionsModalComponent,
    MenuCategoriesComponent,
    NewCoursesComponent,
  ],
  providers: [
    SharedService,
    MainChatRoomAssistService,
  ]
})
export class SharedModule { }
