import { Component, HostListener, ElementRef, ViewChild, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { ModalDialogComponent } from '../../shared-classroom/modal-dialog/modal-dialog.component';

import { ClassroomService } from '../../services/classroom.service';
import { AppService } from 'src/app/app.service';
import { CommunityService } from '../../services/community.service';
import { SearchBarService } from '../../sidebar/components/search-bar/search-bar.service';
import { ChatRoomService } from '../../../shared/components/chat-room/chat-room.service';
import { AWSS3Service } from '../../shared-classroom/awsS3.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'course-live-chat',
  templateUrl: './course-live-chat.component.html',
  styleUrls: ['./course-live-chat.component.scss']
})
export class CourseLiveChatComponent {


  //Variables relacionadas a las reacciones en los mensajes del chat
  reactions_msg_id = null;//id del mensaje que tiene popup-reactions visible 
  message_reactions_open: any = null;//id del mensaje que se está visualizando el modal de reacciones
  @ViewChild('reactions_modal') reactionsModal;//componente de modal del registro de las reacciones que se han realizado en el mensaje
  //Variables relacionadas a las reacciones en los mensajes del chat

  allow_scroll_more_mgs: boolean = false;


  environment: any = environment;
  @ViewChild('newMessageChatRoom') newMessageChatRoom: ElementRef;
  conversation_msg = { data: [], next_token: String };
  input_state = "NONE";
  scrolling = new Subject<string>();
  hoverUserCard = new Subject<string>();
  hoverMsgUserCard = new Subject<string>();
  message_parent = null;
  @Input() openGroupChat;

  @Output() openUsersOnlineEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() followParticipant: EventEmitter<any> = new EventEmitter<any>();
  @Output() openChat: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(ModalDialogComponent) modal_dialog: ModalDialogComponent;
  @ViewChild('directMsgHistory') msgHistoryEl;



  modal_dialog_ = {
    state: false,
    type: null
  };

  math = Math;

  /* Assist app variables */
  is_assist: boolean;
  menu_msg_id: number | null;
  /* Assist app end */



  /* Support to files variables INIT */
  show_file_options: boolean = false; // To show options to upload
  uploading_file: boolean = false;
  input_element;
  open_file_previsual: boolean = false;
  previsual_src: string;
  fileUrl: any;
  file: any = {
    description: '',
    id: '0',
    state: '1',
  };
  allowedVisualFileExtensions = /(\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.ppt|\.pptx|\.txt)$/i;
  allowedFileExtensions = /(\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.ppt|\.pptx|\.txt|\.rar|\.zip)$/i;
  allowedVideoExtensions = /(\.mp4|\.mov|\.3gp)$/i;
  /* Support to files variables END */


  display_floating_button: boolean = false;//Mostrar boton flotante para bajar en el chat
  display_chat_notification: boolean = false;//mostrar notificación de que no se encontró el mensaje


  display_assist_panel: boolean = false;


  /* ----------------------------- Constructor INIT ----------------------------- */
  constructor(
    public _classroomService: ClassroomService,
    public _communityService: CommunityService,
    public _appService: AppService,
    private _chatRoomService: ChatRoomService,
    public _searchBarService: SearchBarService,
    private _router: Router,
    private _awsS3Service: AWSS3Service,
    private sanitizer: DomSanitizer,
  ) {


    /* Assist code constructor */
    this.is_assist = this.isAssist();
    /* Assist code constructor end */



    this.scrolling.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((ev: any): void => {
      //este pipe se encarga de que cuando se haga Scroll solo se tome el ultimo evento del scroll y sea cuando se ejecute la función. |

      if (this.allow_scroll_more_mgs) {
        this.onScroll(ev);
      }

      if ((ev.target.scrollHeight - ev.target.scrollTop) > (ev.target.clientHeight + 100)) {
        this.display_floating_button = true;
      } else {
        this.display_floating_button = false;
      }

    });





    this.hoverUserCard.pipe(
      debounceTime(40),
      distinctUntilChanged()
    ).subscribe((user: any): void => {
      if (user != null) {

        if (this.user_hover == null || (user.User.id != this.user_hover.User.id)) {
          //Si no hay información de usuario visible o si hay una pero es de un usuario diferente, mostrar la info del usuario actual
          this.user_hover = user;
          this.openUserHoverCard(user);
        }
      }
    });

    this.hoverMsgUserCard.pipe(
      debounceTime(40),
      distinctUntilChanged()
    ).subscribe((msg: any): void => {
      if (msg != null) {

        if (this.msg_user_hover == null || (msg.user.user_id != this.msg_user_hover.User.id)) {
          if (this._classroomService.data.certification == null || this._classroomService.data.certification.User.id != msg.user_id) {
            //Si no hay información de usuario visible o si hay una pero es de un usuario diferente, mostrar la info del usuario actual
            this.msg_user_hover = {
              User: {
                id: msg.user.user_id,
                name: msg.user.user_name,
                image: msg.user.user_image
              },
              Certification: {
                progress: 0
              },
              msg_id: msg.id
            };

            this.display_msg_hover_card = true;

            this.openMsgUserHoverCard(msg); //Mostrar card de info del usuario desde el mensaje
          }
        }
      }
    });

  }
  /* ----------------------------- Constructor END ----------------------------- */


  @HostListener("click", ['$event.target'])
  clicked(targetElement) {
    if (this.show_reactions) {
      if (targetElement) {
        let modal_reactions = document.querySelector('.conversation-modal');
        let clickedInside = targetElement.contains(modal_reactions);
        if (!clickedInside
          && (targetElement.className
            && !targetElement.className.includes('msg_reacted')
            && !targetElement.className.includes('tab')
            && !targetElement.className.includes('chat-inbox__msgs')
          )) {
          this.closeReactionsModal();
        }
      }
    }

    if (this.reactions_msg_id) {
      if (targetElement) {
        let msg_actions = document.querySelector('.msg_actions.active');
        let clickedInside = msg_actions.contains(targetElement);
        if (!clickedInside && (targetElement.className && !targetElement.className.includes('icon-contributed'))) {
          this.closeReactions();
        }
      }
    };

  }

  scroll_first_time: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.hasOwnProperty('openGroupChat') && this.openGroupChat != undefined && this.openGroupChat == true) && this.scroll_first_time) {

      this.scroll_first_time = false;
      setTimeout(() => {
        this.scrollToBottom(0);
      }, 1500);

    }
  }





  //card info user form user list
  user_hover: any;
  display_hover_card: boolean = false;

  //card info user form chat
  msg_user_hover: any;
  display_msg_hover_card: boolean = false;

  @Input() participants_online: any;



  openUserHoverCard(user) {
    this.display_hover_card = true;

    let tmp_user = JSON.parse(JSON.stringify(this.user_hover));

    this._communityService.getUsersCertifications(user.User).subscribe(certifications => {
      //cursos y certificados que tiene el usuario 
      if (this.display_hover_card) {
        if (user.User.id == this.user_hover.User.id) {
          tmp_user = user;
          tmp_user['Courses'] = certifications[0];
        }
      } else {
        this.user_hover = null;
        // subs.unsubscribe();
      }


      this.user_hover = JSON.parse(JSON.stringify(tmp_user));
    });

    this.hoverUserCard.next(undefined);
  }


  closeUserHoverCard() {
    if (this.user_id_to_follow != null && this.user_hover && (this.user_id_to_follow == this.user_hover.User.id)) {
    } else {
      this.user_hover = null;
      this.display_hover_card = false;
      this.hoverUserCard.next(undefined);
    }
  }



  openMsgUserHoverCard(message) {

    this.display_msg_hover_card = true;
    // message.user.user_id,
    let user: any;

    this._communityService.getUsersData([message.user.user_id]).subscribe((data: any) => {
      if (data.data && data.data[0]) {
        user = data.data[0];
        this.msg_user_hover = user;
        this.msg_user_hover.msg_id = message.id;

        let tmp_user = JSON.parse(JSON.stringify(this.msg_user_hover));

        this._communityService.getUsersCertifications(user.User).subscribe(certifications => {
          //cursos y certificados que tiene el usuario 
          if (this.display_msg_hover_card) {
            if ((user && this.msg_user_hover) && (user.User.id == this.msg_user_hover.User.id)) {
              tmp_user = user;
              tmp_user['Courses'] = certifications[0];
              tmp_user.msg_id = message.id;

              this.msg_user_hover = JSON.parse(JSON.stringify(tmp_user));
            }
          } else {
            this.msg_user_hover = null;
            // subs.unsubscribe();
          }

        });
      }
    });

    this.hoverMsgUserCard.next(undefined);


  }

  closeMsgUserHoverCard() {
    if (this.user_id_to_follow != null && this.msg_user_hover && (this.user_id_to_follow == this.msg_user_hover.User.id)) {
    } else {

      this.msg_user_hover = null;
      this.display_msg_hover_card = false;
      this.hoverMsgUserCard.next(undefined);

    }
  }




  end_msg = false;
  load_msg = false;

  load_chatroom = true;
  chatQuerySubscription: Subscription;
  scroll_elem: number = 0;

  heartbeatinterval;

  ngAfterContentInit() {
    if (this.chatQuerySubscription) {
      this.chatQuerySubscription.unsubscribe();
    }

    if (this._appService._apolloClient) {
      this.getConversationData();
    } else {
      this._appService.lazyEventStream.subscribe(r => {
        if (r) {
          this.getConversationData();
        }
      });
    }

  }

  ngOnDestroy() {
    if (this.chatQuerySubscription) {
      this.chatQuerySubscription.unsubscribe();
    }
    this.conversation_msg.next_token = null;
    this.conversation_msg.data = [];
  }

  getMessages() {
    this.chatQuerySubscription = this._chatRoomService.getMessagesForGroupCourse("GROUP-COURSE-" + this._classroomService.data.course.id, null).subscribe(messages => {
      if (messages != null) {
        messages.data.allMessageConnection.messages.slice(0).reverse().forEach(message => {


          let m = JSON.parse(JSON.stringify(message));

          if (m.type === 'VIDEO' || m.type === 'FILE' || m.type === 'IMAGE') {
            m = this.getLocalFiles(m, { 'conversation_id': m.conversation_id });
          }

          m.deleted = false;

          //m.type = message.hasOwnProperty('type') ? message.type : "TEXT";
          //m.input_state = 'NONE';
          m.my_reaction = null;
          m.is_dummy = false;

          m.resumed_reactions = [...new Map(m.reactions.map(v => [v.reaction, v])).values()];

          if (!m.state || (m.state && m.state == "SENDED")) {
            //message.type = message.type ? message.type: 'TEXT';       
            this.conversation_msg.data = this.conversation_msg.data.concat(m);
          }
        });
        setTimeout(() => {
          if (this.newMessageChatRoom) this.newMessageChatRoom.nativeElement.focus();
        }, 1);
        this.conversation_msg.next_token = messages.data.allMessageConnection.nextToken;

        if (this.load_chatroom) {
          this.scrollToBottom(0);
          this.load_chatroom = false;
        }

        // Used after loading messages with the preview of the URLs.
        // setTimeout(() => {
        //   this.scrollToBottom(0);
        // }, 1250);

        if (this.conversation_msg.next_token) {
          this.end_msg = false;
        } else {
          this.end_msg = true;
        }
      }
    });
  }




  getMoreMessages() {
    this._chatRoomService.getMessagesForGroupCourse("GROUP-COURSE-" + this._classroomService.data.course.id, this.conversation_msg.next_token).subscribe(messages => {

      if (messages != null) {
        let tmp_conversation_msg = JSON.parse(JSON.stringify(this.conversation_msg.data));

        if (this.conversation_msg.next_token) {
          messages.data.allMessageConnection.messages.forEach((message, index) => {
            let m = JSON.parse(JSON.stringify(message));

            if (m.type === 'VIDEO' || m.type === 'FILE' || m.type === 'IMAGE') {
              m = this.getLocalFiles(m, { 'conversation_id': m.conversation_id });
            }

            m.deleted = false;
            m.my_reaction = null;
            m.is_dummy = false;

            m.resumed_reactions = [...new Map(m.reactions.map(v => [v.reaction, v])).values()];

            if (!m.state || (m.state && m.state == "SENDED")) {
              tmp_conversation_msg.unshift(m);
              this.conversation_msg.data = JSON.parse(JSON.stringify(tmp_conversation_msg));
            }

          });

          this.load_msg = false;
          this.conversation_msg.next_token = messages.data.allMessageConnection.nextToken;
          // this.scrollToBottom(this.conversation_msg.data.length);
        } else {
          messages.data.allMessageConnection.messages.slice(0).reverse().forEach(message => {
            let m = JSON.parse(JSON.stringify(message));
            if (m.type === 'VIDEO' || m.type === 'FILE' || m.type === 'IMAGE') {
              m = this.getLocalFiles(m, { 'conversation_id': m.conversation_id });
            }
            m.deleted = false;

            //m.type = message.hasOwnProperty('type') ? message.type : "TEXT";
            //m.input_state = 'NONE';
            m.is_dummy = false;

            if (!m.state || (m.state && m.state == "SENDED")) {
              //message.type = message.type ? message.type: 'TEXT';
              tmp_conversation_msg = tmp_conversation_msg.concat(m);
              this.conversation_msg.data = JSON.parse(JSON.stringify(tmp_conversation_msg));
            }
          });
          setTimeout(() => {
            if (this.newMessageChatRoom) this.newMessageChatRoom.nativeElement.focus();
          }, 1);
          this.load_chatroom = false;
          this.conversation_msg.next_token = messages.data.allMessageConnection.nextToken;
          // this.scrollToBottom(0);
          //console.log(":::messages:::",messages);
        }

        if (this.conversation_msg.next_token) {
          this.end_msg = false;
        } else {
          this.end_msg = true;
        }
      }

      let msg_: any = document.querySelectorAll(".message");
      if (msg_) {
        msg_[0].scrollIntoView({ behavior: "smooth", block: "start" });
        //Se mueve scroll del mensaje en la posición 0 al top  para que cuando se vuelva a mover el scroll al top del historial se cargen más mensajes
      }

      setTimeout(() => {
        if (this.searchReplyMsg != null) {
          if (this.searchReplyMsg < 5) {
            //Se hará 5 intentos de buscar el mensaje en el historial
            setTimeout(() => {
              this.goToReplyMsg(this.msgReply, this.searchReplyMsg == 4);
              this.searchReplyMsg++;
            }, 50);
          } else {
            //Si ya se acabó el numero de intentos, se anula la búsqueda
            this.searchReplyMsg = null;
            this.msgReply = null;
          }
        }


      }, 250);
    });
  }

  getConversationData() {
    this.getMessages();

    const ws_headers = {
      "Authorization": AppService.idtoken,
      "host": this._appService.ws_host
    };

    this._chatRoomService.chatConversationsQuery.subscribeToMore({
      document: ChatRoomService.GET_GROUP_COURSE_MESSAGES_BY_CONVERSATION,
      variables: {
        conversation_id: "GROUP-COURSE-" + this._classroomService.data.course.id
      },
      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {

        console.log("reiciving data...");
        if (subscriptionData.data.subscribeToNewMessage.user_id != this._appService.user_data.id || subscriptionData.data.subscribeToNewMessage.user_id == this._appService.user_data.id && subscriptionData.data.subscribeToNewMessage.type != 'TEXT') {
          let m = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewMessage));
          m = this.getLocalFiles(m, { 'conversation_id': m.conversation_id });
          m.is_dummy = false;
          m.deleted = false;
          m.my_reaction = null;
          m.reactions = [];
          m.resumed_reactions = [];
          //m.input_state = 'NONE';

          //Para que no se mueva el scroll cuando recibe mensaje nuevo
          this.allow_scroll_more_mgs = false;
          setTimeout(() => {
            this.allow_scroll_more_mgs = true;
          }, 1000);
          //Para que no se mueva el scroll cuando recibe mensaje nuevo
          this.conversation_msg.data.push(m);

          setTimeout(() => {
            //Se utiliza timeout para que de el delay necesario para poner scroll en el fondo
            if (this.msgHistoryEl.nativeElement) this.msgHistoryEl.nativeElement.scrollTop = this.msgHistoryEl.nativeElement.scrollHeight;
          }, 1);

          //Si el chat grupal está colapsado se muestra la notificación de que tiene mensajes nuevos sin leer
          if (!this._classroomService.sidebar.components.community) {
            this._classroomService.unread_messages_counter++;

            //Muestra el contador de mensajes sin leer
            this._appService.onlineUserNotification({ Unread: this._classroomService.unread_messages_counter });

            setTimeout(() => {
              // Muestra notificación de quien escribió
              this._appService.onlineUserNotification({ Unread_User: m.user });
            }, 250);
          }

        }
      }
    });


    this._chatRoomService.chatConversationsQuery.subscribeToMore({
      document: ChatRoomService.DELETE_MESSAGE_GROUP,
      variables: {
        course_id: this._classroomService.data.course.id + ""
      },

      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {
        console.log(subscriptionData)
      }
    });


    this._chatRoomService.chatConversationsQuery.subscribeToMore({
      document: ChatRoomService.GET_GROUP_COURSE_REACTION_BY_CONVERSATION,
      variables: {
        conversation_id: "GROUP-COURSE-" + this._classroomService.data.course.id
      },
      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data.subscribeToNewReaction.user_id != this._appService.user_data.id) {
          let ind = this.conversation_msg.data.findIndex(x => x.id == subscriptionData.data.subscribeToNewReaction.message_id);

          if (ind > -1) {
            let rind = this.conversation_msg.data[ind].reactions.findIndex(x => x.user_id == subscriptionData.data.subscribeToNewReaction.user_id);
            if (rind > -1) {
              if (this.conversation_msg.data[ind].reactions[rind].reaction == subscriptionData.data.subscribeToNewReaction.reaction) {
                this.conversation_msg.data[ind].reactions.splice(rind, 1);
              } else {
                this.conversation_msg.data[ind].reactions.splice(rind, 1);
                let r = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewReaction));
                this.conversation_msg.data[ind].reactions.push(r)
              }
              this.conversation_msg.data[ind].resumed_reactions = [...new Map(this.conversation_msg.data[ind].reactions.map(v => [v.reaction, v])).values()];
            } else {
              let r = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewReaction));
              this.conversation_msg.data[ind].reactions.push(r)
              this.conversation_msg.data[ind].resumed_reactions = [...new Map(this.conversation_msg.data[ind].reactions.map(v => [v.reaction, v])).values()];
            }
          }
        }
      }
    });

    this._chatRoomService.chatConversationsQuery.subscribeToMore({
      document: ChatRoomService.INPUT_STATUS_EX,
      variables: {
        conversation_id: "GROUP-COURSE-" + this._classroomService.data.course.id
      },

      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!this.is_writing) {
          this.input_state = subscriptionData.data.subscribeToInputStateEx.input_state;
          if (!this._classroomService.sidebar.components.community) {
            //Mostrar puntitos de que están escribiendo mensajes
            if (this.input_state != 'NONE') this._appService.onlineUserNotification({ Typing: this.input_state });
          }
        }
      }
    });
  }

  getImageUrl(item) {
    var url = "https://d3puay5pkxu9s4.cloudfront.net/Users/";
    if (item.user) {
      if (item.user.user_fuente == 'facebook' || item.user.user_fuente == 'google') {
        if (item.user.user_image != '') {
          if (item.user.user_image.substring(0, 4) == 'http') {
            url = item.user.user_image;
          } else {
            url += item.user.user_id + '/small_' + item.user.user_image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.user.user_image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.user.user_id + '/small_' + item.user.user_image;
      }
    } else {
      url += 'default/small_imagen.jpg';
    }

    return url;
  }

  scrollToBottom(newlength) {

    if (this.conversation_msg.data.length > 0) {

      //Asignar clase smooth para que baje con suavidad -  se debe eliminar la clase para no generar mala experiencia en otros scrolling
      if (this.msgHistoryEl.nativeElement) this.msgHistoryEl.nativeElement.classList.add('smooth');

      setTimeout(() => {

        //ocultar botón de ir abajo
        if (this.display_floating_button) this.display_floating_button = false;

        if (this.msgHistoryEl.nativeElement) {
          this.msgHistoryEl.nativeElement.scrollTop = this.msgHistoryEl.nativeElement.scrollHeight;
          this.msgHistoryEl.nativeElement.classList.remove('smooth');
        }

      }, 550);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (event.target.scrollTop <= 30) {
      if (this.load_msg == false) {
        this.scroll_elem = this.conversation_msg.data.length;
        this.load_msg = true;
        this.getPreviousMessages();

      }
    }
  }

  getPreviousMessages(): void {
    if (this.conversation_msg.next_token) {
      this.getMoreMessages();
    } else {
      this.end_msg = true;
      this.load_msg = false;
    }
  }

  timeout: any = null;
  is_writing: boolean = false;
  onKeyInput(event: any) {
    if (event.keyCode != 13) {
      if (!this.is_writing) {
        //send starting mutation
        this.sendInputState(this._appService.user_data.name.split(' ').slice(0, -1).join(' ').toLowerCase() + ' esta escribiendo...');
        this.is_writing = true;
      }
      clearTimeout(this.timeout);
    }
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        //send stop mutation
        $this.sendInputState('NONE');
        $this.is_writing = false;
      }
    }, 1000);
  }

  sendInputState(input_state) {
    let rvars;
    rvars = {
      "conversation_id": "GROUP-COURSE-" + this._classroomService.data.course.id,
      "input_state": input_state
    }
    this._chatRoomService.sendInputStateEx(rvars).subscribe(response => {
    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });
  }


  newMessage: string;
  loadingSendMessage: boolean = false;

  generateUUID(): string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  message_id;
  file_type;

  timeTransform(date) {
    let conversation_date;

    // let today = new Date();
    let chat_date = new Date(date);

    let options: any = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: "true" };
    conversation_date = chat_date.toLocaleString("es-ES", options);
    return conversation_date;
  }

  user_id_to_follow;
  _followParticipant(user) {

    let tmp_user;
    let user_index = this._communityService.participants['now'].findIndex(participant => participant.User.id == user.User.id);


    if (user_index > -1) {
      tmp_user = JSON.parse(JSON.stringify(this._communityService.participants['now'][user_index]));
    } else {
      tmp_user = JSON.parse(JSON.stringify(user));
    }

    this.user_id_to_follow = tmp_user.User.id;

    if (this._classroomService.data.actions) {

      if (tmp_user.Followed != null) {

        //Eliminar el Follow        
        let url_delete = '';
        url_delete = `/followers/${tmp_user.Followed.id}`;


        this.modal_dialog_.state = true;

        setTimeout(() => {
          this.modal_dialog.modal_type = 50;

          let __response = this.modal_dialog.response.subscribe(response => {

            if (response) {
              this.modal_dialog_.state = false;
              // this.modal_dialog.modal_type = 0;
              this._communityService.deleteItem(url_delete).subscribe(resp => {

                if (resp.status) {
                  let tmp_followed = null;
                  tmp_user.Followed = tmp_followed;
                  _follow_.setResponse(JSON.parse(JSON.stringify(tmp_user)));
                  this.modal_dialog_.state = false;
                }

              });


            } else {
              this.user_id_to_follow = false;
              this.modal_dialog_.state = false;
            }
            __response.unsubscribe();
          });

        }, 250);


      } else {

        let follow_data = {
          user_id: this._classroomService.data.certification.User.id,
          followed_id: tmp_user.User.id,
        }

        this._communityService.postItem('/followers', follow_data).subscribe(response => {

          if (response.status) {

            let tmp_followed = {
              id: response.data.id,
              privacy: null,
              followed_id: tmp_user.User.id,
              user_id: this._classroomService.data.certification.User.id,
              date_created: response.data.date_created,
              state: response.data.state
            }

            tmp_user.Followed = JSON.parse(JSON.stringify(tmp_followed));

            _follow_.setResponse(JSON.parse(JSON.stringify(tmp_user)));

          } else {

          }


        });

      }


      const _follow_ = {
        setResponse: (user_data) => {
          // agregar seguidor a la lista de followers
          let user_online_index = this._communityService.participants['now'].findIndex(participant => participant.User.id == user_data.User.id);

          if (user_online_index != -1 && user_online_index > -1) {
            this._communityService.participants['now'][user_online_index] = JSON.parse(JSON.stringify(user_data));
          }

          if (this.msg_user_hover && (this.msg_user_hover.User.id == user_data.User.id)) {

            let tmp_msg_user_hover = JSON.parse(JSON.stringify(this.msg_user_hover));
            tmp_msg_user_hover['Followed'] = JSON.parse(JSON.stringify(user_data.Followed));

            this.msg_user_hover = JSON.parse(JSON.stringify(tmp_msg_user_hover));

          }

          this.user_hover = JSON.parse(JSON.stringify(user_data));

          setTimeout(() => {
            this.user_id_to_follow = null;
            this.closeUserHoverCard();
          }, 2000);

        }
      }


    }
  }





  replyMessage(msg) {
    this.message_parent = msg;
    if (this.newMessageChatRoom) this.newMessageChatRoom.nativeElement.focus();
  }

  closeReplyMessage() {
    this.message_parent = null;
    if (this.newMessageChatRoom) this.newMessageChatRoom.nativeElement.focus();
  }


  openReactions(msg_id) {
    let timeout: number = 0;
    if (this.reactions_msg_id != null && this.reactions_msg_id != msg_id) {
      this.reactions_msg_id = null;
      timeout = 1;
    }
    let message_index = this.conversation_msg.data.findIndex(msg => msg.id == msg_id);

    setTimeout(() => {
      this.reactions_msg_id = msg_id;
      let index = this.conversation_msg.data[message_index].reactions.findIndex(x => x.user_id == this._appService.user_data.id);
      if (index > -1) {
        this.conversation_msg.data[message_index].my_reaction = this.conversation_msg.data[message_index].reactions[index].reaction;
      }
    }, timeout);

  }

  closeReactions() {
    let th = this;
    setTimeout(() => {
      th.reactions_msg_id = null;
    }, 200);
  }


  show_reactions = null;
  reactMsg(react, msg_id, unreact) {
    let message_index = this.conversation_msg.data.findIndex(msg => msg.id == msg_id);
    let variables: any;

    if (unreact) {
      variables = {
        "conversation_id": "GROUP-COURSE-" + this._classroomService.data.course.id,
        "message_id": this.conversation_msg.data[message_index].id
      };
      this._chatRoomService.deleteCourseGroupReaction(variables).subscribe(msg => {
        let index = this.conversation_msg.data[message_index].reactions.findIndex(x => x.user_id == this._appService.user_data.id);
        if (index > -1) {
          this.conversation_msg.data[message_index].reactions.splice(index, 1);
        }
        this.conversation_msg.data[message_index].my_reaction = null;
        this.conversation_msg.data[message_index].resumed_reactions = [...new Map(this.conversation_msg.data[message_index].reactions.map(v => [v.reaction, v])).values()];
        this.closeReactions();
      }, (error) => {
        console.log('there was an error sending the mutation', error);
      });
    } else {

      var now = new Date().toISOString();

      variables = {
        "conversation_id": "GROUP-COURSE-" + this._classroomService.data.course.id,
        "created_at": now,
        "message_id": this.conversation_msg.data[message_index].id,
        "reaction": react.name
      };

      this.conversation_msg.data[message_index].my_reaction = react.name;
      this._chatRoomService.sendCourseGroupReaction(variables).subscribe(msg => {
        let index = this.conversation_msg.data[message_index].reactions.findIndex(x => x.user_id == this._appService.user_data.id);
        if (index > -1) {
          this.conversation_msg.data[message_index].reactions.splice(index, 1);
        }

        let r = JSON.parse(JSON.stringify(msg.data.cm));
        this.conversation_msg.data[message_index].reactions.push(r)
        this.conversation_msg.data[message_index].resumed_reactions = [...new Map(this.conversation_msg.data[message_index].reactions.map(v => [v.reaction, v])).values()];
        this.closeReactions();
      }, (error) => {
        console.log('there was an error sending the mutation', error);
      });
    }
  }


  /*
  * Take a message from template and send data to delete the message clicked.
  */
  deleteMessageGroupCourse(message: any): void {

    let variables = {
      "created_at": message.created_at,
      "conversation_id": message.conversation_id,
    };

    this._chatRoomService.deleteMessageGroupCourse(variables).subscribe(
      deleted => this.deleteMessageConversationMsg(deleted.data.rm.id),
      error => console.log(error),
    );
  }




  /*
  * Delete message into conversation_msg data array.
  */
  deleteMessageConversationMsg(deletedId: string): void {

    let index = this.conversation_msg.data.findIndex(message => message.id == deletedId);

    if (index < 0) {
      return;
    }

    this.conversation_msg.data.splice(index, 1);
  }





  /*  -------------------------------------------------- Assist app init --------------------------------------------------  */

  isAssist(): boolean {
    return this._router.url.includes('/assist');
  }


  openAndCloseMenuMessage(msg_id: number) {

    if (msg_id == this.menu_msg_id) {
      this.menu_msg_id = null;
      return;
    }

    this.menu_msg_id = msg_id;
  }


  /*  -------------------------------------------------- Assist app end --------------------------------------------------  */




  sendMessage(): void {

    // Actualizar las estadisticas del usuario cada vez que haga una opinion by Jesus
    const curso_id = this._classroomService.data.course.id
    const certification_id = this._classroomService.data.certification.Certification.id
    this._classroomService.saveStatistics(curso_id, certification_id, 'com_comment')
    // Fin

    if (!this.newMessage && !this.file.src) {
      return;
    }

    //Para que no se mueva el scroll cuando recibe mensaje nuevo
    this.allow_scroll_more_mgs = false;
    setTimeout(() => {
      this.allow_scroll_more_mgs = true;
    }, 1000);
    //Para que no se mueva el scroll cuando recibe mensaje nuevo

    this.loadingSendMessage = true;
    let variables: any = this.getVariablesObject();

    if (this.file.src) {
      this.newMessage = '';
      this.sendMessageGroupCourseFiles(variables);
      return;
    }

    let dummy_message = {
      is_dummy: true,
      user_id: this._appService.user_data.id,
      type: variables.type,
      content: variables.cont,
      id: variables.id,
      created_at: variables.now,
      src: '',
      file: '',
      file_size: '0',
      user: {
        user_fuente: '',
        user_id: this._appService.user_data.id,
        user_image: this._appService.user_data.image,
        user_name: this._appService.user_data.name,
        user_role: this._appService.user_data.role
      }
    }

    if (this.message_parent) {
      variables['parent_id'] = this.message_parent.id;
      dummy_message['parent'] = this.message_parent;

      let parent_user_id = this.message_parent.user_id;
      if (this._appService.user_data != null && parent_user_id != null && (this._appService.user_data.id != parent_user_id)) {
        this._communityService.saveSuggestion(this._appService.user_data.id, parent_user_id, 'like');
      }

    }

    this.newMessage = '';
    variables['state'] = "SENDED";
    this.conversation_msg.data.push(dummy_message);
    this.sendMessageGroupCourse(variables);
  }





  /* ----------------------------------------  Support files Functions INIT ----------------------------------------  */


  buttonCancelToggling(): void {
    this.show_file_options = !this.show_file_options;
  }

  // Types files extensions allowed to upload.
  validAcceptTypes = {
    image: 'image/*',
    file: '.pdf,.xls,.doc,.docx,.pptx,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,text/html,video/,.rar,.zip,.dwg,.py',
    video: 'video/mov, video/mp4, video/3gp'
  };

  prevent_uploadFile_event(event) {
    event.stopPropagation();
  }

  addfile(event) {
    this.loadingSendMessage = true;
    this.file_type = event.target.id.substr(event.target.id.indexOf('_') + 1);
    this.file.progress = 0;
    this.input_element = event;
    this.show_file_options = false;
    this.uploading_file = true;

    let fileList: FileList = event.target.files; // Obtiene el file  del input html.
    let fileInput = event.target;
    let filePath = event.target.value;
    var allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (this.file_type == 'image' && !allowedImageExtensions.exec(filePath)) {
      this.uploading_file = false;
      fileInput.value = '';
      return false;
    }

    if (fileList.length <= 0) {
      this.file.file_name = "Adjuntar archivo";
      return;
    }

    this.file.size = fileList[0].size;
    this.file.file = fileList[0].name;

    if (this.file.size > (1048576 * 250)) {
      this.file.file_name = 'Su archivo supera los 250MB'; // Le asigna msj de error al  del input file, cuando supero los 250 megas.
      return;
    }

    this.file.file_type = fileList[0].type; // Asigna el nombre file.
    this.file.file_ext = fileList[0].name.toLowerCase().split('.').pop();
    this.file.file_name = `${fileList[0].name.split('.').shift()}.${this.file.file_ext}`;
    this.message_id = this.generateUUID();
    let folderKey = `Conversations/${"GROUP-COURSE-" + this._classroomService.data.course.id}/Messages/${this.message_id}/`;
    let fileKey = `${folderKey}file1.${this.file.file_ext}`;

    const params = {
      Bucket: 'edutin',
      Key: fileKey,
      Body: fileList[0],
      ContentType: this.file.file_type
    };

    let options = { partSize: 5 * 1024 * 1024, queueSize: 1 };

    this._awsS3Service.getS3Bucket().upload(params, options, (err, data) => {

      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      let tmp_file = JSON.parse(JSON.stringify(this.file));
      tmp_file.file_key = data.Key;
      tmp_file.src = null;
      tmp_file.file_url = data.Location;
      tmp_file.progress = 100;
      this.file = JSON.parse(JSON.stringify(tmp_file));

      setTimeout(() => {
        this.uploading_file = false;
      }, 250);

      switch (this.file_type) {
        case 'image':
          this.file.src = URL.createObjectURL(event.target.files[0]);
          break;
        case 'file':
          this.file.src = "https://docs.google.com/gview?embedded=true&url=https://video.edutin.com/" + this.file.file_key;
          break;
        case 'video':
          this.file.src = "https://video.edutin.com/" + this.file.file_key;
          break;
      }

      this.loadingSendMessage = false;
      setTimeout(() => {
        if (this.input_element && this.input_element.target && this.input_element.target.value) {
          this.input_element.target.value = "";
        }
        if (this.input_element && this.input_element.target && this.input_element.target.files) {
          this.input_element.target.files = null;
        }
      }, 1000);

      // return true;
    })
      .on('httpUploadProgress', (percentage) => {
        // console.log('httpUploadProgress.', percentage);
      });
  }


  /*
  * Return type any: object with variables to send a message.
  */
  getVariablesObject(): any {

    let conversation_id = "GROUP-COURSE-" + this._classroomService.data.course.id;
    var now = new Date().toISOString();
    let cont: string = (this.newMessage ? this.newMessage.replace(/"/g, '\\"') : "");

    // Type TEXT
    if (!this.file.src) {
      this.file_type = "TEXT";
      this.message_id = this.generateUUID();
      return {
        "conversation_id": conversation_id,
        "created_at": now,
        "id": this.message_id,
        "type": this.file_type,
        "content": cont
      };
    }

    // Type FILES
    return {
      "conversation_id": conversation_id,
      "created_at": now,
      "id": this.message_id, // Id already generated in addfile function.
      "type": this.file_type.toUpperCase(),
      "content": cont,
      "file": `${this.file.file_name.split('.').shift()}.${this.file.file_ext}`,
      "file_size": this.file.size,
    };
  }




  /*
  * Param type any: object with variables to send message of type text.
  * Return type void.
  */
  sendMessageGroupCourse(variables: any): void {
    this._chatRoomService.sendCourseGroupMessage(variables).subscribe(
      msg => this.setConversation_msg(JSON.parse(JSON.stringify(msg.data.cm))),
      error => console.log('there was an error sending the mutation', error),
    );
  }




  /*
  * Param type any: object with variables to send message of type files.
  * Return type void.
  */
  sendMessageGroupCourseFiles(variables: any): void {
    this._chatRoomService.sendCourseGroupMessageFiles(variables).subscribe(
      msg => {
        this.message_parent = this.file.src = null;
        setTimeout(() => {
          // this.scrollToBottom(0);
          this.loadingSendMessage = false;
        }, 1500);
      },
      error => console.log('there was an error sending the mutation', error),
    );
  }




  /*
  * Set files locally to conversation msg data.
  * Param type any: from send message functions.
  * Param type any: object with variables from getVariablesObject function.
  * Return type any: data with files to show in chats.
  */
  getLocalFiles(m: any, variables: any): any {
    switch (m.type) {
      case "IMAGE":
        m.src = `https://video.edutin.com/Conversations/${variables.conversation_id}/Messages/${m.id}/file1.${m.file.toLowerCase().split('.').pop()}`;
        break;
      case "FILE":
      case "VIDEO":
        m.file_size = Math.ceil(m.file_size / 1048576);
        m.src = `https://video.edutin.com/Conversations/${variables.conversation_id}/Messages/${m.id}/file1.${m.file.toLowerCase().split('.').pop()}`;
        this._chatRoomService.getDataFile(m.src).subscribe((base: any) => {
          m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));
        });
        break;
    }
    return m;
  }




  /*
  * Param type any: Response from send message functions.
  * Return type void.
  */
  setConversation_msg(data: any): void {
    this.loadingSendMessage = false;
    let m = JSON.parse(JSON.stringify(data));
    m.is_dummy = m.deleted = false;
    m.my_reaction = null;
    m.reactions = [];
    m.resumed_reactions = [];
    this.message_parent = this.file.src = null;
    this.conversation_msg.data = this.conversation_msg.data.map(function (men) {
      if (!men.id || men.id == m.id) {
        return m
      }
      return men;
    });
    this.scrollToBottom(0);
    this.loadingSendMessage = false;
  }




  openfilePrevisual(src) {
    this.previsual_src = src;
    this.open_file_previsual = true;
    this._chatRoomService.getDataFile(src).subscribe((baseImage: any) => {
      this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(baseImage));
    });
  }




  getFileSize(size): number { // Public Domain/MIT
    return Math.ceil(size / 1048576);
  }




  getFile(m: any): any { // Public Domain/MIT
    if (m.fileUrl) {
      return m.fileUrl;
    }
  }




  getFileName(name: string): string { // Public Domain/MIT
    if (name.split('.').shift().length > 20) {
      return name.split('.').shift().substring(0, 20) + '...' + name.split('.').pop();
    } else {
      return name;
    }
  }




  closePrevisual() {
    this.open_file_previsual = false;
    this.previsual_src = null;
    this.scrollToBottom(0);
  }



  openTabPrevisual(previsual_src) {
    window.open(previsual_src, '_blank');
  }



  openReactionsModal(directMsgHistory, msg_content, message_id, message?) {
    this.show_reactions = message_id;
    this.message_reactions_open = message;
    setTimeout(() => {
      this.reactionsModal.openReactionsModal(directMsgHistory, msg_content, message_id);
    }, 20);
  }



  closeReactionsModal() {
    this.show_reactions = null;
  }

  searchReplyMsg: number = null;
  msgReply: any = null;
  goToReplyMsg(message, lastSearch?) {
    // lastSearch se envía cuando se ejecuta la funcion desde  getMoreMessages() y vendrá con valor true cuando el contador sea = 4 (la ultima iteración para buscar en el chat)
    this.display_chat_notification = false;
    this.msgReply = message;
    const { parent_id } = message;
    let msg_: any = document.getElementById(parent_id);

    if (this.msgHistoryEl.nativeElement) {
      if (msg_) {
        //Si se encuentra mensaje en el historial se resalta y se mueve el scroll
        this.searchReplyMsg = null;
        this.msgReply = null;
        this.msgHistoryEl.nativeElement.scrollTop = msg_.offsetTop - ((this.msgHistoryEl.nativeElement.clientHeight / 2) - 40);
      } else {

        //Si no se encuentra el mensaje en el historial que ya está cargado (el historial inicial), 
        //se mueve el scroll al top para que se siga buscando por 5 veces (al mover el scroll al top, automaticamente se cargan más mensajes anteriores)
        this.msgHistoryEl.nativeElement.scrollTop = 0;

        if (this.searchReplyMsg == null) {
          //Cuando es el historial inicial, se inicializa en 0
          this.searchReplyMsg = 0;
        } else {
          //A medida que va cargando se va aumentando una

        }
      }
    }


    setTimeout(() => {
      if (msg_) {
        msg_.classList.add('active');
      } else {
        if (lastSearch != null && lastSearch === true) {
          //Si es la 5 vez que búsca y no se encuentra, se muestra la notificación
          this.display_chat_notification = true;

          setTimeout(() => {
            this.display_chat_notification = false;
          }, 7000);
        }
      }


      setTimeout(() => {
        if (msg_) {
          msg_.classList.remove('active');
        }
      }, 1500);
    }, 400);
    // if (msg_) msg_.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
