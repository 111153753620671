<div *ngIf="_appService.user_data" [ngClass]="{'menu-mobile-open': showMenu || _appService.showMessaging}"
  (clickOutsideDdown)="_appService.showMessaging=false" class="messaging dropdown-all-menu">
  <span (click)="toggleMessaging()" *ngIf="_appService.showMessaging" class="icon-cross"></span>
  <div (click)="toggleMessaging()" [ngClass]="{'open':_appService.showMessaging, 'label':_appService.messageCounter>0}"
    class="item">

    <span class="icon-message"></span>

    <div class="label-red">{{_appService.messageCounter}}</div>
  </div>

  <div class="menu" *ngIf="_appService.conversations == null">

    <div class="header">
      <div>Mensajes</div>
    </div>

    <ul class="content" style="height: 300px;">
      <li *ngFor="let item of [1,2,3,4,5]">
        <div class="messages">
          <div class="container-items">

            <a class="items">
              <div class="item-list">
                <!-- <img [src]="conversation | urlImage: 3"> -->
                <span class="loading-Element"
                  style="background-color: rgba(104, 119, 149, .3); width: 35px; height: 35px; border-radius: 50%; display: flex; "></span>
              </div>


              <div class="content">
                <div class="title">
                  <h4 class="loading-Element"
                    style="height: 18px; background-color: rgba(104, 119, 149, .3);; width: 150px; border-radius: 8px;">
                  </h4>

                  <h4></h4>
                </div>
                <div class="legend">

                  <h4 class="last_message loading-Element"
                    style="height: 16px; background-color: rgba(104, 119, 149, .3); width: 80%; border-radius: 8px">
                  </h4>


                </div>
              </div>

            </a>

          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="menu" *ngIf="_appService.conversations != null">
    <div [ngClass]="{'empty': _appService.conversations.length==0}" class="header">
      <div>Mensajes</div>
      <div style="display: none;">Mensajes...</div>
    </div>
    <ul class="content" [ngClass]="{'empty': _appService.conversations.length==0}">

      <div class="conversations-empty" *ngIf="_appService.conversations && _appService.conversations.length==0">No ha
        iniciado conversaciones</div>

      <li *ngFor="let conversation of _appService.conversations; let i = index">
        <div class="messages"
          *ngIf="conversation.associated && conversation.associated.length > 0 && conversation.associated[0].user != null">
          <div [class]="(conversation.unreaded_messages>0?'recent':'viewed')" class="container-items">
            <a #itemConv (click)="openChatRoom(0,conversation,i)" class="items"
              [class.active]="display_item_conversation == conversation.conversation.id">
              <div class="item-list">
                <img *ngIf="conversation.associated.length === 1" [src]="conversation | urlImage: 3"
                  [alt]="conversation.associated[0].user.user_name"
                  [class]="conversation.associated[0].user.user_role | userClassName">
                <!-- <img *ngIf="conversation.associated.length > 1" [src]="conversation | urlImage: 3"
                  [alt]="conversation.conversation.name" class="student-free"> -->

                <div class="chat_group_image conversations" *ngIf="conversation.associated.length > 1">
                  <img class="receiver-avatar" [src]="conversation.associated[0] | urlImage:4">
                  <img class="receiver-avatar" [src]="conversation.associated[1] | urlImage:4">
                </div>

                <!-- <div *ngIf="conversation.unreaded_messages>0" class="label-red">{{conversation.unreaded_messages}}</div> -->
                <div *ngIf="conversation.associated.length === 1"
                  [class]="'circle-status-'+conversation.associated[0].user.status"></div>
                <!-- <div *ngIf="conversation.associated.length > 1" class="group-icon">gr</div> -->
              </div>
              <div class="content">
                <div class="title">
                  <h4>
                    {{ conversation.associated.length === 1 ? (conversation.associated[0].user.user_name |cutUserName:'2') : conversation | groupNamePipe }}
                  </h4>

                  <h4></h4>
                </div>
                <div class="legend">

                  <h4 class="last_message" *ngIf="conversation.last_message != null" [ngClass]="!conversation.input_state || conversation.input_state == 'NONE' ? 'msg' : 'typing'"
                    [ngStyle]="{'font-style': conversation.last_message == '' ? 'italic' : null, 'font-weight': !conversation.input_state || conversation.input_state == 'NONE' ? 'normal' : 'bold'}">
                    {{ (!conversation.input_state || conversation.input_state == 'NONE') ? (conversation.last_message != '' ? conversation.last_message : 'Imagen, archivo o audio') : conversation.input_state}}
                  </h4>

                  <span class="dot" *ngIf="conversation.last_message != null">
                    <div></div>
                  </span>
                  <h4>{{conversation.last_update | messageDate}}</h4>

                  <div class="options" (click)="configConvSP($event,-2,conversation)">
                    <span>...</span>
                    <!-- <span *ngIf="!_appService.chatroom_state">...</span> -->
                    <!-- <div *ngIf="_appService.chatroom_state && _appService.chat_user.data.id == conversation.conversation.id" class="loader-circle e-blue"></div> -->
                  </div>


                </div>
              </div>

              <span class="dot-notification"></span>

              <span class="icon-activity"></span>

              <div class="visible"
                [ngStyle]="{'display': ((conversation.conversation.id !== null) ? ((display_item_conversation == conversation.conversation.id) ? 'block' : 'none') : 'none')}">
                <a (click)="configConvSP($event,-1,conversation)">Eliminar conversación</a>
                <a (click)="configConvSP($event,0,conversation)" class="read">Marcar
                  como leída</a>
                <a (click)="configConvSP($event,1,conversation)" class="no-read">Marcar como no leída</a>
              </div>

            </a>
          </div>
        </div>
      </li>
      <li id="showMoreConversation" *ngIf="!_appService.conversation_end && _appService.conversations.length>0">
        <a (click)="configConvSP($event,2,'');" *ngIf="!loading_previus">Ver más</a>
        <i *ngIf="loading_previus" class="loader"></i>
      </li>
    </ul>

    <div class="notification_footer" *ngIf="_appService.conversations.length>0">
      <a (click)="openChatRoom(1,[],-1)">
        Ver todos los mensajes
      </a>
    </div>
  </div>
</div>
