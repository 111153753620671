import { Directive, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appUrlVideo]'
})
export class UrlVideoDirective {

  @Input() appUrlVideo: any = '';

  constructor(
    private elementMessageVideo: ElementRef,
    private _renderer2: Renderer2,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('appUrlVideo') && changes.appUrlVideo.currentValue != undefined) {
      if (changes.appUrlVideo.currentValue !== '') {
          this.setPreviewVideo(this.appUrlVideo.src, this.appUrlVideo.first);
        }
    }
  }




  /*
  * Set sources video to display url video.
  */
  setPreviewVideo(url: string, firstElement: any): void {

    const elementVideoPreview = this.elementMessageVideo.nativeElement;

    let videoContent = this._renderer2.createElement('video');
    let sourceMP4 = this._renderer2.createElement('source');
    let sourceMOV = this._renderer2.createElement('source');
    let source3GP = this._renderer2.createElement('source');

    this._renderer2.setAttribute(videoContent, 'controls', '');

    this._renderer2.setAttribute(sourceMP4, 'src', url);
    this._renderer2.setAttribute(sourceMP4, 'type', 'video/mp4');

    this._renderer2.setAttribute(sourceMOV, 'src', url);
    this._renderer2.setAttribute(sourceMOV, 'type', 'video/mov');

    this._renderer2.setAttribute(source3GP, 'src', url);
    this._renderer2.setAttribute(source3GP, 'type', 'video/3gp');

    this._renderer2.appendChild(videoContent, sourceMP4);
    this._renderer2.appendChild(videoContent, sourceMOV);
    this._renderer2.appendChild(videoContent, source3GP);

    this._renderer2.insertBefore(elementVideoPreview, videoContent, firstElement);
  }

}
