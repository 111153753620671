<div class="loading-Element" *ngFor="let item of getArray()" [ngClass]="{'opinions': type == 'opinions'}" >
  <div class="comment-loading-avatar"></div>

  <div class="comment_loading--container text-1">

    <div class="comment_loading--text text-1" *ngIf="type != 'opinions'"></div>
    <div class="comment_loading--text text-2"></div>
    <div class="comment_loading--text text-3"></div>
    <div class="comment_loading--text text-2" *ngIf="type != 'opinions'"></div>

    <div class="comment_loading--options" *ngIf="type != 'opinions'">
      <div class="comment_loading--options-like"></div>
      <div class="comment_loading--options-text"></div>
    </div>

  </div>

</div>
