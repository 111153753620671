<div class="comment_input_container" *ngIf="actions">
  <div class="opinion-new" [id]="id_textarea" contenteditable #commentNew
    [attr.placeholder]="'Escriba su comentario...'" (input)="_comment.description=$event.target.innerHTML"
    (paste)="pasteSomething($event)">
  </div>
  <button class="btn send" (click)="sendComment()">

    <span class="icon-send" *ngIf="!sending_comment"></span>

    <div class="spinner white spinner--button" *ngIf="sending_comment">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>


  </button>
</div>




<div class="card section-comments" *ngIf="items != null">

  <p style="font-size: 14px; padding: 10px 20px 20px; text-align: center; font-weight: 600;"
    *ngIf="items.length == 0 && !actions">Aún no hay
    comentarios. Sé el primero en realizar un comentario.</p>

  <div class="card-content answer" *ngFor="let opinion of items; let i_answercomment = index">
    <div>
      <img #commentUserAvatar (error)="commentUserAvatar
      .src=urlavatar+(opinion.User.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
        [src]="opinion.User|avatarUrlPipe">

      <div class="dropdown-menu-options"
        *ngIf="actions && ((current_user_data.id==opinion.User.id) || _classroomService.data.certification.User.role=='moderador')">

        <label class="icon-ellipsis-h" [for]="'ddwn-'+opinion.Opinion.id">
          <input class="dropdown-input" [id]="'ddwn-'+opinion.Opinion.id" type="checkbox" name="child" #ddown_opinion>
          <ul class="dropdown-content">
            <li (click)="editComment(opinion.Opinion); ddown_opinion.checked = false">Editar</li>
            <li (click)="deleteComment(opinion); ddown_opinion.checked = false">
              Eliminar</li>
          </ul>
        </label>

      </div>
    </div>
    <div>

      <p *ngIf="comment_to_edit == null || (comment_to_edit != null && comment_to_edit.id != opinion.Opinion.id)"
        [innerHTML]="opinion.Opinion.description">
      </p>


      <div class="comment_input_container" *ngIf="comment_to_edit != null && comment_to_edit.id == opinion.Opinion.id">

        <div class="opinion-edited" [id]="id_textarea" contenteditable #commentEdited
          [attr.placeholder]="'Escriba su comentario...'" (input)="opinion.Opinion.description=$event.target.innerHTML">
        </div>


        <button class="btn icon-cross" (click)="closeEditComment()"
          *ngIf="comment_to_edit!=null && comment_to_edit.id == opinion.Opinion.id"></button>
        <button class="btn send" (click)="sendEditedComment(opinion)">
          <span class="icon-send" *ngIf="!sending_edit_comment"></span>

          <div class="spinner white spinner--button" *ngIf="sending_edit_comment">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

        </button>
      </div>



      <div class="student">
        <a [href]="'https://app.edutin.com/user/profile/' + opinion.User.id"
          target="_blank">{{opinion.User.name | cutUserName: '2'}}</a>
        <span>{{opinion.User.role | roleName}}</span>
        <span class="dot">
          <div></div>
        </span>
        <span>hace {{opinion.Opinion.date | timeAgoPipe}}</span>
      </div>
    </div>

  </div>

  <a class="display_more" *ngIf="lastIndex < comment_list.length" (click)="loadMore()">Ver más comentarios...</a>

</div>
