<div class="footer">
  <div class="back-blue-dark">
    <div class="main">

      <div class="row">

        <div class="about column">
          <ul>
            <li>Sobre nosotros</li>
            <li><a [href]="environment.domain + '/about-us'">Sobre Edutin Academy</a></li>
            <li><a [href]="environment.domain + '/history'">Nuestra historia</a></li>
            <li><a [href]="environment.domain + '/virtual-campus'">Campus virtual</a></li>
            <li><a [href]="environment.domain + '/academy-contents'">Contenidos académicos</a></li>
            <li><a href="https://edutin.us" target="_blank">Empleos</a></li>
          </ul>
        </div>

        <div class="column">
          <ul>
            <li>Productos y servicios</li>
            <li><a [href]="environment.domain + '/cursos-gratis'">Cursos gratis</a></li>
            <li><a [href]="environment.domain + '/certificado-de-estudios'">Certificado de estudios</a></li>
            <li><a [href]="environment.domain + '/prices'">Precios</a></li>
          </ul>
        </div>

        <div class="column">
          <ul>
            <li>Ayuda</li>
            <li><a [href]="environment.domain + '/helps/home'" target="_blank">Centro de ayuda</a></li>
            <li><a [href]="environment.domain + '/helps/account'" target="_blank">Cuenta de estudiante</a></li>
            <!--<li><a target="_blank" href="https://edutin.com/helps/admissions">Admisiones y matrícula</a></li>
						<li><a target="_blank" href="https://edutin.com/helps/careers">Carreras profesionales</a></li>-->
            <li><a [href]="environment.domain + '/helps/courses'" target="_blank">Cursos gratis</a></li>
            <li><a [href]="environment.domain + '/helps/certificates'" target="_blank">Certificado de estudios</a></li>
            <li><a [href]="environment.domain + '/helps/classroom'" target="_blank">Aula de clases</a></li>
            <li><a [href]="environment.domain + '/helps/payments'" target="_blank">Pagos y reembolsos</a></li>
          </ul>
        </div>

        <div class="column">
          <ul class="talk-person-real">
            <li>Habla con una persona real en español</li>
            <li>
              <a class="phone">
                <img class="hover-out"
                  src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/call.svg">
                <img class="hover-in"
                  src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/call-hover.svg">
                <div>+1 302-261-5750</div>
              </a>
            </li>
            <li>
              <a target="_blank" href="http://m.me/EdutinAcademy">
                <img class="hover-out"
                  src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/messenger.svg">
                <img class="hover-in"
                  src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/messenger-hover.svg">
                <div>Chat de facebook</div>
              </a>
            </li>
            <li>
              <a [href]="environment.domain + '/helps/requests'" target="_blank">
                <img class="hover-out"
                  src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/email.svg">
                <img class="hover-in"
                  src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/email-hover.svg">
                <div>Enviar una solicitud</div>
              </a>
            </li>
          </ul>
          <ul>
            <li>Siguenos en redes</li>
          </ul>
          <div class="social">
            <a target="_blank" href="https://www.facebook.com/EdutinAcademy">
              <img class="hover-out ls-is-cached lazyloaded" alt="Edutin Academy en Facebook"
                data-src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/facebook.svg"
                src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/facebook.svg">
              <img class="hover-in lazyloaded" alt="Edutin Academy en Facebook"
                data-src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/facebook-hover.svg"
                src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/facebook-hover.svg">
            </a>
            <a target="_blank" href="https://www.instagram.com/edutinacademy/">
              <img class="hover-out ls-is-cached lazyloaded" alt="Edutin Academy en Instagram"
                data-src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/instagram.svg"
                src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/instagram.svg">
              <img class="hover-in lazyloaded" alt="Edutin Academy en Instagram"
                data-src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/instagram-hover.svg"
                src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/instagram-hover.svg">
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/edutinacademy">
              <img class="hover-out ls-is-cached lazyloaded" alt="Edutin Academy en LinkedIn"
                data-src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/linkedin.svg"
                src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/linkedin.svg">
              <img class="hover-in lazyloaded" alt="Edutin Academy en LinkedIn"
                data-src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/linkedin-hover.svg"
                src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/elements/footer/linkedin-hover.svg">
            </a>
            <!-- <a target="_blank" href="https://twitter.com/edutin">
              <img class="hover-out" alt="Edutin Academy en Twitter"
                src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/gorjeo.svg">
              <img class="hover-in" alt="Edutin Academy en Twitter"
                src="https://s3.amazonaws.com/edutin-publico/academy/iconos/elements/footer/gorjeo-hover.svg">
            </a> -->
          </div>
        </div>

      </div>

    </div>
  </div>
  <div class="back-blue-light">
    <div class="legal">
      <div>
        <a [href]="environment.domain + '/pages/terminos'" target="_blank">Términos y condiciones</a>
        <span class="dot">.</span>
        <a [href]="environment.domain + '/pages/politicas'" target="_blank">Política de privacidad</a>
      </div>
      <h4>Edutin Academy es una incorporación de educación virtual, constituida en Estados Unidos.</h4>
      <h4>© 2013 - {{year}} Edutin Inc. Todos los derechos reservados.</h4>

      <!-- <div class="cc">
        <a [href]="environment.domain + '/academy-contents'" target="_blank">
          <img src="https://licensebuttons.net/l/by-nc-sa/4.0/88x31.png" alt="Licencia de Creative Commons" />
        </a>
        <h4>Los contenidos de nuestros cursos están licenciados bajo una licencia internacional Creative Commons
          Attribution 4.0, excepto donde se indique lo contrario.</h4>
      </div> -->

    </div>
  </div>
</div>