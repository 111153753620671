import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import { UrlLambdaApi } from '../models/models';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class AWSS3Service {

    constructor(
        private _appService: AppService,
        private http: HttpClient
    ) {

    }

    public getS3Bucket(): any {
        const bucket = new S3(
            {
                accessKeyId: 'AKIA6LPQ6GUE66PRTKMV',
                secretAccessKey: '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',
                region: 'us-east-1'
            }
        );

        return bucket;
    }

    public generateRandomString() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 10; i++)text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    }




    uploadfile(file, path) {
        var aux = this.generateRandomString();
        let fileName = 'archivo.' + file.name.toLowerCase().split('.').pop();
        let folderKey = 'tmp/' + path.path + '/' + aux + '/';
        let fileKey = folderKey + fileName;
        const params = {
            Bucket: path.name,
            Key: fileKey,
            Body: file,
            ContentType: file.type
        };

        let options = { partSize: 5 * 1024 * 1024, queueSize: 1 };
        this.getS3Bucket().upload(params, options, (err, data) => {
            if (err) {
                // console.log('There was an error uploading your file: ', err);
                return false;
            }
            // console.log('Successfully uploaded file.', data);
            // this.reSuccessfulSubject.next(data);
            return true;
        }).on('httpUploadProgress', (percentage) => {
            return percentage;
            // this.resGetProgressSubject.next(percentage);
        })

    }


    getHeadersLambda(): any {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this._appService.token
        })

        return ({ headers: headers });
    }



    _generate_url(url, data, file): Observable<any> {

        let API_URL = 'https://api.edutin.com/s/p/files/url/';
        return this.http.post(API_URL + url, data, this.getHeadersLambda()).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }



    generate_url = (url, data, file) => new Observable((observer) => {


        this._generate_url(url, data, file).subscribe(response => {

            const { status, data } = response;
            if (status == true) {
                const { AWSAccessKeyId, policy, signature, key } = data.fields;
                const xAmzSecurityToken = data.fields.hasOwnProperty('x-amz-security-token') ? data.fields['x-amz-security-token'] : false;


                if (AWSAccessKeyId && xAmzSecurityToken && policy && signature && key) {

                    data.fields['x_amz_security_token'] = xAmzSecurityToken;
                    data.fields['file'] = file;


                    this.upload_file(data).subscribe(resp => {
                        observer.next({ data: data.fields, status: true });
                    });

                }

            } else {
                observer.next({ data: [], status: false });
            }


        });


    });






    upload_file(url_generated) {

        const { fields } = url_generated;

        let fd = new FormData();
        fd.append("key", fields.key);
        fd.append("AWSAccessKeyId", fields.AWSAccessKeyId);
        fd.append("x-amz-security-token", fields.x_amz_security_token);
        fd.append("policy", fields.policy);
        fd.append("signature", fields.signature);
        fd.append("file", fields.file);


        return this.http.post(url_generated.url, fd).pipe(
            map(res => {
                return res;
            }),
            catchError((error: any) => {
                // this.handleError('upload_file');
                return of('error');
            })
        );
    }

}