import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { UrlImage } from '../../shared.pipe';


@Component({
  selector: 'live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss', '../chat-room/chat-room.component.scss']
})
export class LiveChatComponent implements OnInit {

  constructor(
    public _appService: AppService
  ) { }

  id_chat
  conv_index;
  chat_index;//Numeración en la que fue creado el chat 
  chat_user;//data que se pasa a chat-room para cargar la conversación
  user_associated;//info del usuario con quien se chatea
  live_chat_state = 'collapsed'; //collapsed - close - open
  @Output() closeThisChat = new EventEmitter();//enviar evento a app.component para cerrar este chat
  @ViewChild('chat_room') chat_room;
  public urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
  controlAdd: boolean = false;

  ngOnInit(): void { }


  toggleLiveChat() {
    this.live_chat_state = this.live_chat_state == 'collapsed' ? 'open' : 'collapsed';

    if (this.live_chat_state == 'collapsed') {
      this.chat_room.show_file_options = false;


      if (this.chat_room.recordingSound) {
        this.chat_room.buttonCancelToggling()
      }

    }

  }


  openChat(chat) {

    this.chat_user = JSON.parse(JSON.stringify(chat));
    this._appService.chat_user = JSON.parse(JSON.stringify(this.chat_user));

    if (this.chat_user.data && this.chat_user.data.hasOwnProperty('associated')) {
      
      this.user_associated = this.chat_user.data.associated[0].user;

      // if(this.chat_user.data.associated.length > 1){
      //   this.user_associated.user_name = this.chat_user.data.conversation.name;
      // }ñl+
      this.user_associated.url_image = new UrlImage().transform(this.chat_user.data, 1);

      if ((this.chat_user.data.associated[0].user.user_role === 'assistant' && (this._appService.user_data.role === 'assistant' || this._appService.user_data.role === 'moderador')) || this.chat_user.data.associated[0].user.user_role !== 'assistant') {
        this.controlAdd = true;
      }
    }
    
    
  }


  closeChat(event) {
    this.closeThisChat.emit(this.chat_index);
    if (event) event.stopPropagation();
  }


  openModal(event, type?) {

    if (this.chat_room) {
      if (type == 'add') {
        this.chat_room.openAddUser(event);
      }

      if (type == 'info') {

        if (this.chat_user.data.associated.length > 1) {
          if (this.live_chat_state == 'open') {
            this.chat_room.openUserList(event);
          }
        } else {
          this.toggleLiveChat();
        }
      }
    }


    if (event) event.stopPropagation();
  }


  openMeet(event) {
    // environment.appDomain - appDomain contiene la url de la app según si está en local o en producción o en develop 
    
    window.open(`${environment.appDomain}/meet?config=${JSON.stringify(this.chat_user.data.associated[0].user)}`, "_blank");
    if (event) event.stopPropagation();
  }

}
