import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
  transform(username: string, length: string): string {

    username = username.toLocaleLowerCase();
    let split_username_length = username.trim().split(/\s+/).length;
    var un = username.split(' ');
    switch (length) {
      case '1':
        var user_name = un[0];
        break;
      case '2':
        var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
        break;
    }
    let name = '';
    user_name.split(' ').forEach(U => {
      name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
    });

    return name;

  }
}



@Pipe({ name: 'safePipe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Pipe({ name: 'groupByMonthPipe' })
export class GroupByMonthPipe implements PipeTransform {

  transform(conversation_msg, reload?) {
    //reload: solo se usa para que el pipe detecte cambios en el array
    const sortedObj = {};
    conversation_msg.forEach(e => {                 // loop over all elements
      const created_at = new Date(e.created_at);
      e['time'] = created_at.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'});     //Message sending time
      const k = e.created_at.split('T');                      // key in YYYY-MM (e.g. 2019-10)
      // const fk = k[0];                                        // key with appended '-01'               
      // const fk = MONTHS[moonLanding.getUTCMonth()];
      
      var today: any = new Date();

      let chat_date = new Date(k[0]);
      const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
      const date_time = chat_date.toLocaleDateString("es-ES", options);
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      let label_date = date_time;  


      if (today.toDateString() === created_at.toDateString()) {
        label_date = 'HOY';
        // return true;
      }


      if (yesterday.toDateString() === created_at.toDateString()) {
        label_date = 'AYER';
        // return true;
      }


      sortedObj[label_date] = sortedObj[label_date] || [];                    // create new entry if no value for key exists
      sortedObj[label_date].push(e);                                  // add key to existing list
    });


    // const sortedArr = Object.entries(sortedObj).sort((a, b) => new Date(a[0]) - new Date(b[0]));

    const sortedArr =  Object.entries(sortedObj).sort((a, b) => { 
      return new Date(a[0]).getTime() - new Date(b[0]).getTime() 
    });

    return sortedArr;
  }
}