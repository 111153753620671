<div class="tabs">

  <div class="summary" id="comment_notification"
    *ngIf="notification_.display && _classroomService.comments_by_source_loaded">

    <div class="summary__container" style="position: relative;">
      <div [innerHTML]="notification_.msj"></div>
      <a class="btn icon-cross" (click)="notification_.display = false"></a>
    </div>


  </div>

  <section>

    <modal-dialog *ngIf="modal_dialog_.state"></modal-dialog>


    <div class="options__group comments_option_group"
      *ngIf="(!_communityService.full_view.state || _communityService.config.comments != 'full') && _classroomService.comments_by_source_loaded"
      [ngClass]="{'tight': options.offsetWidth < 379 }" #options>


      <h4>{{_classroomService.data.source.type == 5 ? 'Participaciones' : 'Preguntas'}}</h4>


      <label class="select_list_2 icon-filter" [class.disabled]="searchtext_.length > 0"
        (clickOutsideDdown)="ddown_comments.checked = false">
        <!-- <span>{{filter_selected.label}}</span> -->
        <span>Ordenar por</span>
        <input class="dropdown-input" type="checkbox" #ddown_comments />
        <ul>
          <li *ngFor="let item of filter; let i_item = index" (click)="itemSelected(i_item)"
            [class.active]="filter_selected.id == filter[i_item].id">
            {{item.label}}
          </li>
        </ul>
      </label>

      <!-- <a type="button" class="btn btn__dark-blue" (click)="takePart()">{{_classroomService.data.source.type == 5 ?
        'Participar' : 'Preguntar'}}</a> -->
    </div>



    <div class="comments">

      <div class="comment-add" [ngClass]="{'empty': !_communityService.config.participate}">

        <div class="user-avatar">
          <img #studentAvatar class="avatar"
          (error)="studentAvatar.src=urlavatar+(_classroomService.data.certification.User.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
          [src]="_classroomService.data.certification.User|avatarUrlPipe">
        </div>

        <ng-content select="[add]"></ng-content>
      </div>


      <div class="items-list" *ngIf="is_sidebar_component && _classroomService.data.certification">
        <comment-loading [amount]="5" *ngIf="!_communityService.comments_by_popular_loaded"></comment-loading>

        <!-- Preguntas recent -->
        <item-list [items]="_communityService.data_comments['recent']" [reference]="'rec'"
          [class.active]="filter_selected.id == 'recent'" [items_type]="'recent'" (goToSource)="goToSource.emit($event)"
          (updateJson)="updateJson($event)">
        </item-list>

        <!-- Preguntas populares -->
        <item-list [items]="_communityService.data_comments['popular']" [reference]="'pop'"
          [class.active]="filter_selected.id == 'popular'" [items_type]="'popular'"
          (goToSource)="goToSource.emit($event)" (updateJson)="updateJson($event)">
        </item-list>

        <!-- Mis preguntas -->
        <item-list [items]="_communityService.data_comments['my_questions_sidebar']" [reference]="'mqs'"
          [class.active]="filter_selected.id == 'my_questions_sidebar'" [items_type]="'my_questions_sidebar'"
          (goToSource)="goToSource.emit($event)" (updateJson)="updateJson($event)">
        </item-list>

      </div>

      <div *ngIf="is_sidebar_component!=true && _classroomService.comments_by_source_loaded">

        <!-- Listado de preguntas en la clase -->
        <item-list id="lesson_comment_list" [items]="_communityService.data_comments['source']" [reference]="'l'"
          [class.active]="filter_selected.id == 'source'" [items_type]="'source'" (updateJson)="updateJson($event)">
        </item-list>

        <!-- Listado de preguntas en el sin respuestas -->
        <item-list [items]="_communityService.data_comments['no_answers']" [reference]="'na'"
          [class.active]="filter_selected.id == 'no_answers'" [items_type]="'no_answers'">
        </item-list>

        <!-- Mis preguntas -->
        <item-list [items]="_communityService.data_comments['my_questions']" [reference]="'mq'"
          [class.active]="filter_selected.id == 'my_questions'" [items_type]="'my_questions'">
        </item-list>

        <!-- Pregunta redireccionada de la notificación -->
        <item-list [items]="_communityService.data_comments['notificate']" [reference]="'notification'"
          [class.active]="filter_selected.id == 'notificate'" [items_type]="'notificate'"
          *ngIf="filter_selected.id == 'notificate' && (_communityService.data_comments['notificate'] != null && _communityService.data_comments['notificate'].length > 0)">
        </item-list>

      </div>


      <div class="cmmnt-empty"
        *ngIf="filter_selected.id == 'notificate' && (_communityService.data_comments['notificate'] == null || _communityService.data_comments['notificate'].length == 0)">

        Lo sentimos, la pregunta que desea visualizar fue eliminada

      </div>

    </div>
  </section>

</div>