<!-- listado de usuarios conectados -->
<modal-dialog *ngIf="modal_dialog_.state"></modal-dialog>

<div class="assist-wrapper" *ngIf="display_assist_panel">
  <div class="jitsi-wrapper"></div>
  <div class="assist-info">
    <p>Ana Guete</p>
    <span>Asistente académico</span>
  </div>
  <div class="btn-group">
    <!-- Si contiene la clase enable se dibuja de color azul -->
    <a class="icon-cam enable"></a>
    <!-- Si contiene la clase disabled aparece la linea roja diagonal -->
    <a class="icon-mic disabled"></a>
    <a class="icon-headphone"></a>
  </div>
</div>


<!-- INIT file-visualizer -->
<div *ngIf="open_file_previsual && !load_chatroom" class="file-visualizer">
  <div class="file_prev">
    <div class="icons-header">
      <span class="chat-room__close" (click)="closePrevisual()">Ocultar</span>
    </div>
    <div class="file_src">
      <img (click)="openTabPrevisual(previsual_src);" [src]="previsual_src">
      <a class="download_file" [href]="fileUrl" download="file">
        <span class="icon-download-bottom"></span>
        Descargar
      </a>
    </div>
  </div>
</div>
<!-- END file-visualizer -->

<!-- Preview files before sending a message INIT -->
<div class="file_prev" *ngIf="!load_chatroom && !uploading_file && file.src && !open_file_previsual">
  <img *ngIf="file_type == 'image'" [src]="file.src | safePipe">

  <!-- No support preview to files or vídeos -->
  <div class="no-support-file-prev"
    *ngIf="(file_type == 'file' && !allowedVisualFileExtensions.exec(file.src)) || (file_type == 'video' && !allowedVideoExtensions.exec(file.src))">
    <img src="./assets/icons/file.svg">
    <p>Vista previa no disponible</p>
    <p>{{ getFileSize(file.size) + ' MB - ' + file.file_ext.toUpperCase() }}</p>
  </div>

  <iframe *ngIf="file_type == 'file' && allowedVisualFileExtensions.exec(file.src)" [src]="file.src | safePipe">
  </iframe>

  <!-- Support local vídeos -->
  <video *ngIf="file_type == 'video' && allowedVideoExtensions.exec(file.src)" [src]="file.src | safePipe"
    controls></video>
</div>
<!-- Preview files before sending a message END -->


<div #online_users class="floating-user-bubbles" *ngIf="participants_online!=null && participants_online.length > 0">
  <ul>
    <li *ngFor="let student of participants_online| slice:0: math.round(online_users.offsetWidth/60); let i=index"
      [class]="'user_info_'+student.User.id" (mouseenter)="hoverUserCard.next(student)"
      (mouseleave)="closeUserHoverCard()">


      <img [class]="'avatar_'+student.User.id" [src]="student|urlImage:5">
      <!-- Info hover participan -->
      <hover-card #hover__card [user_hover]="user_hover" [hover_assist]="is_assist"
        *ngIf="(user_hover && user_hover.User.id == student.User.id) && display_hover_card"
        [actions]="_classroomService.data.actions" [style.left]="i > 2 ? null : '4px'"
        [style.right]="i > 2 ? ((i * 53) - 340) +'px' : null" [user_state]="true"
        (followParticipant)="_followParticipant($event)" (openChat)="openChat.emit($event)">
      </hover-card>

    </li>

    <button class="btn icon-arrow-right" (click)="openUsersOnlineEvent.emit()"></button>

  </ul>
</div>


<div class="direct-msg"
  [ngStyle]="{'height': (participants_online!=null && participants_online.length > 0) ? display_assist_panel ? 'calc(100% - 78px)' : 'calc(100% - 72px)' : display_assist_panel ? 'calc(100% - 30px)' : 'calc(100% - 5px)'}"
  [ngClass]="is_assist ? 'direct-msg-assist' : ''">

  <div #directMsgHistory class="direct-msg__history" (scroll)="scrolling.next($event)">
    <div class="spinner" *ngIf="uploading_file">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>


    <div class="loading-chat loading-Element" *ngIf="load_chatroom">

      <div class="loading-msg" *ngFor="let m of [0,1,0,0,0,1,0,0,0,1]" [ngClass]="m==0 ? 'left':'right'">
        <div class="loading-avatar">

        </div>
        <div class="loading-text">

        </div>
      </div>

    </div>



    <!-- <ul #chatMessages class="direct-msg__history-list" [ngClass]="{ 'hidde': load_chatroom || file.src || uploading_file || open_file_previsual }" *ngIf="!load_chatroom && !file.src && !uploading_file && !open_file_previsual"> -->
    <ul #chatMessages class="direct-msg__history-list"
      [ngClass]="{ 'none': load_chatroom || file.src || uploading_file || open_file_previsual }">

      <li class="start_conversation" *ngIf="load_msg || end_msg">
        <span>
          {{((end_msg && load_msg==false)?'Inicio de la conversación':'Cargando...')}}
        </span>
      </li>

      <ul class="msg__history-group"
        *ngFor="let group of conversation_msg.data | groupByMonthPipe : conversation_msg.data.length">

        <div class="date_label">
          <p>{{group[0]}}</p>
        </div>
        <li class="message" *ngFor="let message of group[1]; let i = index" (mouseenter)="message.show_options = true;"
          (mouseleave)="message.show_options = false;" [id]="message.id">

          <div class="msg" #msg_content *ngIf="message.user!=null && _appService.user_data != null"
            [ngClass]="message.user.user_id == _appService.user_data.id ? 'sent-msg' : 'received-msg'">

            <div *ngIf="!message.deleted &&  message.type === 'SYSTEM'" class="msg__deleted">
              <p class="msg__text" style="padding-left: 0;">{{ message.content }}
              </p>
            </div>

            <!-- [ngClass]="{ 'served-user': message.served_user == true && message.user.user_id != _appService.user_data.id && _appService.user_data.role == 'assistant' && message.type === 'TEXT', 'not-served-user': message.served_user == false && message.user.user_id != _appService.user_data.id && _appService.user_data.role == 'assistant' && message.type === 'TEXT' }" -->
            <div *ngIf="!message.deleted && message.type != 'SYSTEM'" class="msg__content course__live_chat__content">

              <div class="avatar_container" (mouseenter)="hoverMsgUserCard.next(message)"
                (mouseleave)="closeMsgUserHoverCard()">
                <img [src]="message | urlImage: 4" class="profile-user-msg">


                <hover-card [user_hover]="msg_user_hover" [hover_assist]="is_assist"
                  *ngIf="(msg_user_hover &&  (msg_user_hover.msg_id == message.id)) && display_msg_hover_card"
                  [actions]="_classroomService.data.actions" [style.left]="'4px'" [user_state]="false"
                  (followParticipant)="_followParticipant($event)" (openChat)="openChat.emit($event)">
                </hover-card>

              </div>


              <!-- Aquí debes poner la validación para indicar si es una respuesta en el [ngClass] para asignar la class 'reply' -->
              <div class="msg_content" [ngClass]="{'reply': message.parent_id != null }">

                <!-- Si es un mensaje normal mostrar nombre de quien lo envió  -> se oculta con css cuando msg_content sea class reply -->
                <a class="name" [href]="environment.appDomain + '/user/profile/' + message.user.user_id"
                  target="_blank">
                  {{ message.user.user_name | cutUserName:'2' }}
                </a>


                <!-- Contenido del mensaje que se está respondiendo -->
                <div *ngIf="message.parent_id != null" class="msg__reply" (click)="goToReplyMsg(message)">
                  <!-- Solo se muestra cuando msg_content sea class reply -->

                  <!-- Si es un mensaje que se ha respondido - Mostrar nombre del usuario a quien responde -->
                  <!-- Se debe asignar la info del usuario  -->
                  <a [href]="environment.appDomain + '/user/profile/' + message.parent[0].user.user_id" target="_blank"
                    class="name user_to_reply"
                    *ngIf="message.parent[0] != null && message.parent[0].user != null && message.parent[0].user.user_id != null">
                    {{ message.parent[0].user.user_name | cutUserName:'2' }}
                  </a>

                  <p class="msg__text text_reply" [appPreviewUrl]="message.parent[0].content">
                    {{ message.parent[0].content }}
                  </p>
                </div>
                <!-- END Contenido del mensaje que se está respondiendo -->




                <p class="msg__text" appPreviewUrl [appPreviewUrl]="message.content"
                  (mouseenter)="message.show_options = true" (mouseleave)="message.show_options = false"
                  *ngIf="(message.type === 'TEXT' || !message.type) && message.type !== 'IMAGE' && message.type !== 'VIDEO' && message.type !== 'FILE'">

                  {{ message.content }}
                </p>

                <!-- Message type image INIT -->
                <div class="msg_content_file" (mouseenter)="message.show_options = true"
                  (mouseleave)="message.show_options = false" *ngIf="message.type === 'IMAGE'">

                  <div class="msg_content_preview img_preview">
                    <img class="msg_img" (click)="openfilePrevisual(message.src)" [src]="message.src">
                    <div class="text_file">
                      <p class="file_name">{{ getFileName(message.file) }}</p>
                    </div>
                  </div>

                  <p class="msg__text" *ngIf="message.content != null && message.content!= ''">
                    {{ message.content }}
                  </p>
                </div>
                <!-- Message type image END -->

                <!-- INIT message video -->
                <div class="msg_content_file" (mouseenter)="message.show_options = true"
                  (mouseleave)="message.show_options = false" *ngIf="message.type === 'VIDEO'">

                  <div class="msg_content_preview video_preview" appUrlVideo
                    [appUrlVideo]="{src: message.src, first: firstElement}">
                    <a #firstElement [href]="getFile(message)" [download]="message.file" class="preview_file_message">
                      <div class="text_file">
                        <p class="file_name">{{ getFileName(message.file) }}</p>
                        <p *ngIf="message.file_size" class="file_metadata">{{ message.file_size + ' MB' }}</p>
                      </div>
                    </a>
                  </div>


                  <p class="msg__text" *ngIf=" message.content!= null &&  message.content !=''">
                    {{ message.content }}
                  </p>

                </div>
                <!-- END message video -->

                <!-- INIT message_file -->
                <div class="msg_content_file message_file" (mouseenter)="message.show_options = true"
                  (mouseleave)="message.show_options = false" *ngIf="message.type === 'FILE'">

                  <!-- INIT preview_file_message -->
                  <div class="msg_content_preview file_preview">
                    <a class="preview_file_message" [href]="getFile(message)" [download]="message.file">

                      <img [src]="message.src | srcFileIconPipe">

                      <div class="text_file">
                        <p class="file_name">{{ getFileName(message.file) }}</p>
                        <p class="file_metadata" *ngIf="message.file_size">{{ message.file_size + ' MB' }}</p>
                      </div>
                    </a>

                  </div>


                  <!-- END preview_file_message -->
                  <p class="msg__text" *ngIf=" message.content!= null &&  message.content !=''">
                    {{ message.content }}
                  </p>
                </div>
                <!-- END message_file -->


                <!-- Modal de reacciones relacionadas al mensaje -->
                <reactions-bubble *ngIf="message && (message.reactions && message.reactions.length > 0)"
                  [ngClass]="message.user.user_id == _appService.user_data.id ? 'right':'left'"
                  [reactions]="message.resumed_reactions" [reactions_length]="message.reactions.length"
                  (openReactionsModal)="openReactionsModal(directMsgHistory,msg_content, message.id, message)">
                </reactions-bubble>
                <!-- Modal de reacciones relacionadas al mensaje -->


              </div>

              <div class="msg_actions" [class.active]="message.id == reactions_msg_id" (mouseleave)="closeReactions()">
                <!-- Responder mensaje -->
                <span *ngIf="message.type === 'TEXT'" class="icon-reply" (click)="replyMessage(message)"></span>

                <!-- Menu message -->
                <span *ngIf="_appService.user_data.role === 'assistant'" class="icon-ellipsis-v"
                  (click)="openAndCloseMenuMessage(message.id)">
                  <div class="actions-menu-message" *ngIf="menu_msg_id == message.id">
                    <!-- <a *ngIf="_appService.user_data.id != message.user.user_id"
                      (click)="deleteMessageGroupCourse(message)">Atendido</a> -->
                    <a (click)="deleteMessageGroupCourse(message)">Eliminar</a>
                  </div>
                </span>

                <!-- Reaccionar al mensaje -->
                <span class="icon-happy" (click)="openReactions(message.id)"></span>

                <!--Popup Reacciones -->
                <popup-reactions *ngIf="message.id == reactions_msg_id" [reaction_selected]="message.my_reaction"
                  [msg]="message" (reatcEvent)="reactMsg($event, message.id, $event.name == message.my_reaction)"
                  [ngClass]="message.user.user_id == _appService.user_data.id ? 'left': 'right'">
                </popup-reactions>
                <!--Popup Reacciones -->
              </div>

            </div>
            <!-- <span class="msg__date">{{ timeTransform(message.created_at) }} -->
            <span class="msg__date">{{ message.time }}
            </span>


            <!-- Modal de las reacciones relacionadas al mensaje -->
            <reactions-modal #reactions_modal *ngIf="show_reactions && show_reactions == message.id"
              [reactions_length]="message_reactions_open.reactions.length"
              [reactions]="message_reactions_open.reactions"
              [resumed_reactions]="message_reactions_open.resumed_reactions"
              (closeReactionsModalEmitter)="closeReactionsModal()">
            </reactions-modal>
            <!-- Modal de las reacciones relacionadas al mensaje -->

          </div>
        </li>
      </ul>


      <div *ngIf="(input_state != 'NONE')" class="spinner dotted typing_message" style="max-height: 40px;">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div class="floating-button icon-arrow-down" *ngIf="display_floating_button"
        [ngStyle]="{'top': ((directMsgHistory.scrollTop + directMsgHistory.clientHeight) - 50) +'px'}"
        (click)="scrollToBottom(0)"></div>

      <div class="chat_notification" *ngIf="display_chat_notification">
        No se encontró el mensaje
      </div>

    </ul>

  </div>
  <div class="direct-msg__footer">








    <div *ngIf="message_parent" class="reply_msg">

      <div class="msg parent-msg">
        <span class="msg__date"> Respondiendo mensaje</span>

        <div class="msg__content course__live_chat__content">
          <!-- Aquí debes poner la validación para indicar si es una respuesta en el [ngClass] para asignar la class 'reply' -->
          <div class="msg_content">

            <!-- Si es un mensaje normal mostrar nombre de quien lo envió  -> se oculta con css cuando msg_content sea class reply -->
            <a [href]="environment.appDomain + '/user/profile/' + message_parent.user.user_id" target="_blank"
              class="name">
              {{ message_parent.user.user_name | cutUserName:'2' }}
            </a>

            <p class="msg__text" appPreviewUrl [appPreviewUrl]="message_parent.content"
              (mouseenter)="message_parent.show_options = true" (mouseleave)="message_parent.show_options = false"
              *ngIf="message_parent.type === 'TEXT' || !message_parent.type">

              {{ message_parent.content }}
            </p>

          </div>
        </div>

      </div>
      <span class="reply__close icon-cross" (click)="closeReplyMessage()"></span>
    </div>




    <form class="direct_msg__write" (ngSubmit)="sendMessage()" autocomplete="off"
      [ngClass]="{ 'form-assist': is_assist }">
      <!-- Icons to send files, vídeos or images INIT - Only assistant role  allowed -->
      <a *ngIf="_appService.user_data.role ==='assistant'" (click)="buttonCancelToggling()"
        class="direct_msg__upload-btn btn btn__light-blue">

        <!-- Icon + to show or hidde options -->
        <i class="icon-contributions" aria-hidden="true"></i>

        <!-- Options to send files -->
        <div *ngIf="show_file_options" class="direct_msg_send_file">
          <!-- To send vídeos -->
          <div class="video">
            <label for="upload_video" class="btn btn__dark-blue icon-play-circle-outline"
              (click)="prevent_uploadFile_event($event)">
              <input type="file" id="upload_video" (change)="addfile($event)" [accept]="validAcceptTypes['video']" />
            </label>
          </div>
          <!-- To send files -->
          <div class="attached">
            <label for="upload_file" class="btn btn__dark-blue icon-attached"
              (click)="prevent_uploadFile_event($event)">
              <input type="file" id="upload_file" (change)="addfile($event)" [accept]="validAcceptTypes['file']" />
            </label>
          </div>
          <!-- To send images -->
          <div class="image">
            <label for="upload_image" class="btn btn__dark-blue icon-image" (click)="prevent_uploadFile_event($event)">
              <input type="file" id="upload_image" (change)="addfile($event)" [accept]="validAcceptTypes['image']" />
            </label>
          </div>
        </div>
      </a>
      <!-- Icons to send files or images END -->
      <input #newMessageChatRoom autofocus type="text" class="direct_msg__input" (keyup)="onKeyInput($event)"
        placeholder="Escribe un mensaje" name="newMessage" [(ngModel)]="newMessage"
        [ngStyle]="{'max-width': _appService.user_data.role ==='assistant' ? 'calc(100% - 90px)' : 'unset'}" />
      <button class="direct_msg__send-btn btn" type="submit" [disabled]="loadingSendMessage">
        <i [ngClass]="{'loader-circle': loadingSendMessage, 'icon-send':!loadingSendMessage}" aria-hidden="true"></i>
      </button>
    </form>
  </div>
</div>