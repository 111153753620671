<div class="tabs" [ngClass]="{ 'section-assist': is_assist === true }">
  <modal-dialog *ngIf="modal_dialog_.state"></modal-dialog>

  <a class="return" *ngIf=" users_filter == 'now'" (click)="returnToChat.emit()">
    <!-- <span> {{back_char}}</span> -->
    <span class="icon-arrow-left"></span>
    Volver
  </a>


  <section
    [ngStyle]="{'height': ((_communityService.participants[users_filter]!=null && _communityService.participants[users_filter].length > 4) || _communityService.participants[users_filter]==null  ? '100%' : 'fit-content') }">

    <div class="participants" (scroll)="scrolling.next($event)"
      [ngStyle]="{'height': _communityService.participants[users_filter]==null  ? '100%' : 'fit-content' }">
      <div class="card empty"
        *ngIf="_communityService.participants[this.users_filter]!=null && _communityService.participants[this.users_filter].length == 0 && !loadingUsers">
        <p [innerHTML]="empty_text" *ngIf="searchtext_.length == 0"></p>
        <p *ngIf="searchtext_.length > 0">No se encontraron resultados relacionados a su búsqueda
          <strong><em>"{{searchtext_}}"</em></strong>
        </p>
      </div>

      <ng-container *ngFor="let student of _communityService.participants[this.users_filter]">
        <div class="card">

          <div class="progress-circle" [attr.data-progress]="student.Certification.progress">
            <img #studentAvatar
              (error)="studentAvatar.src=urlavatar+(student.User.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
              [src]="student.User|avatarUrlPipe">
          </div>
          <div class="circle-status-online" *ngIf="users_filter == 'now'"></div>
          <div [class]="'circle-status-'+student.User.status"></div>




          <div class="participant_name">
            <a [innerHTML]="student.User.name|cutUserName:'2'"
              [href]="'https://app.edutin.com/user/profile/' + student.User.id" target="_blank"></a>

            <p>
              <img class="user_country_flag" [src]="student.Country | userCountryflagPipe"
                *ngIf="student.Country.name != 'INTERNATIONAL'">

              <span [innerHTML]="student.Country.name | toLowerCase" *ngIf="student.Country"></span>
              <span [innerHTML]="'Internacional' | toLowerCase" *ngIf="!student.Country"></span>
            </p>
          </div>


          <div class="btn_group">
            <!-- Connection state not enabled for assist -->
            <a *ngIf="!is_assist" class="connection" (click)="followParticipant(student)"
              [class]="(student.Followed != null && student.Followed.followed_id != null) ? 'followed': null"
              [ngClass]="{'disabled': !_classroomService.data.actions}">
              <i *ngIf="user_id_to_follow != student.User.id"
                [ngClass]="(student.Followed != null && student.Followed.followed_id != null )? 'icon-tick-outline' : 'icon-follow'"></i>
              <span *ngIf="user_id_to_follow != student.User.id">{{ (student.Followed != null &&
                student.Followed.followed_id != null) ? 'Conectado' : 'Conectar' }}</span>

              <div class="spinner spinner--button" *ngIf="user_id_to_follow == student.User.id">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

            </a>
            <a role="button chat" (click)="openChat(student)"
              [ngClass]="{'disabled': !_classroomService.data.actions || !can_send_message || !conversations_loaded}">
              <span *ngIf="loading_chat_user_id != student.User.id">
                <i class="icon-message"></i>
                Mensaje
              </span>

              <div class="spinner spinner--button"
                *ngIf="loading_chat_user_id == student.User.id  || (!can_send_message || !conversations_loaded)">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </a>
          </div>


        </div>
      </ng-container>

      <div *ngIf="load_scroll_users || _communityService.participants[this.users_filter]==null" style="width: 100%;">
        <div *ngFor="let student of [0,1,2,3,4,5,6,7,8,9]" style="width: 100%;">

          <div class="card loading loading-Element">
            <div class="progress-circle" [attr.data-progress]="0"></div>

            <div class="participant_name">
              <a></a>
              <p></p>
            </div>

            <div class="btn_group">
              <a class="buttons"></a>
              <a class="buttons"></a>
            </div>

          </div>

        </div>
      </div>

    </div>
  </section>

</div>