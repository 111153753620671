import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// import { PdfViewerModule } from 'ng2-pdf-viewer';

import { CommunityComponent } from './community.component';
import { CommentsComponent } from './comments/comments.component';
import { UsersComponent } from './users/users.component';
// import { CourseLiveChatComponent } from './course-live-chat/course-live-chat.component';
import { CourseLiveChatModule } from './course-live-chat/course-live-chat.module';
import { ParticipateComponent } from './participate/participate.component';
import { ItemListComponent } from './item-list/item-list.component';


import { CutUserNamePipe, CutChildUserNamePipe, TimeTransform, SafePipe, ToLowerCase, SetTarget_blank, SortSourcesByType, RoleName, GetModuleOrder, SortByPriorityPipe, SafeHtmlPipe, SortByClosestDate, AvatarURLPipe, SortMyActivityByDay, SortOpinionByClosestDate, TypeNameSource, SortByRole, LikePipe, TimeAgoPipe, UserCountryflagPipe, LabelChildPipe } from '../pipes/community.pipe';

import { ReactiveFormsModule } from '@angular/forms';

import { AceEditorModule } from 'ng2-ace-editor';

import { CommentComponent } from './item-list/comment/comment.component';


import { CommunityService } from '../services/community.service';

import { SearchBarModule } from '../sidebar/components/search-bar/search-bar.module';

import { ClassroomSharedModule } from '../shared-classroom/classroom-shared.module';
import { SelectLisComponent } from './participate/select-list.component';





@NgModule({
    imports: [
        HttpClientModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        AceEditorModule,
        SearchBarModule,
        ClassroomSharedModule,
        CourseLiveChatModule,
    ],
    declarations: [
        CommunityComponent,
        CommentsComponent,
        UsersComponent,
        // CourseLiveChatComponent,
        ParticipateComponent,
        CutUserNamePipe, CutChildUserNamePipe, TimeTransform, SafePipe, ToLowerCase, SetTarget_blank, SortSourcesByType, RoleName, GetModuleOrder, SortByPriorityPipe, SafeHtmlPipe, SortByClosestDate, AvatarURLPipe, SortMyActivityByDay, SortOpinionByClosestDate, TypeNameSource, SortByRole, LikePipe, TimeAgoPipe, UserCountryflagPipe, LabelChildPipe,
        ItemListComponent,
        CommentComponent,
        SelectLisComponent
    ],
    exports: [
        CommunityComponent,
        CommentsComponent,
        UsersComponent,
        // CourseLiveChatComponent,
        ParticipateComponent,
        ItemListComponent,
        CommentComponent,
        SelectLisComponent,
        CutUserNamePipe,
    ],
    providers: [CommunityService]
})
export class CommunityModule { }
