export const GET_CONNECTIONS_SCHEMA = `query allFollowerConnection($state: String, $exclude_own: Int, $after: String, $first: Int) {
    allFollowerConnection(state: $state, after: $after, first: $first, exclude_own: $exclude_own) {
        nextToken,
        followers {
            user_id
            followed_id
            date
            id
            petitioner
            privacy
            state

            user {
                user_id
                user_name
                user_image
                user_role
                user_fuente
            }
            
            followed {
                user_id
                user_name
                user_image
                user_role
                user_fuente
            }
        }
    }
}`;


export const ADD_FOLLOWER_SCHEMA = `mutation createFollower($user_id: ID!, $followed_id: ID!, $petitioner: String, $privacy: String){
    cf: createFollower(user_id: $user_id, followed_id: $followed_id, petitioner: $petitioner, privacy: $privacy){
        user_id
        followed_id
        date
        id
        petitioner
        privacy
        state

        user {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

        followed {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

    }

    cf1: createFollower(user_id: $followed_id, followed_id: $user_id, petitioner: $petitioner, privacy: $privacy){
        user_id
        followed_id
        date
        id
        petitioner
        privacy
        state

        user {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

        followed {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

    }
}`;


export const APPROVE_FOLLOWER_SCHEMA = `mutation approveFollower($user_id: ID!, $followed_id: ID!){
    af: approveFollower(user_id: $user_id, followed_id: $followed_id){
        user_id
        followed_id
        date
        id
        petitioner
        privacy
        state

        user {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

        followed {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }
    }

    af1: approveFollower(user_id: $followed_id, followed_id: $user_id){
        user_id
        followed_id
        date
        id
        petitioner
        privacy
        state

        user {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

        followed {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

    }
}`;


export const DELETE_FOLLOWER_SCHEMA = `mutation declineFollower($user_id: ID!, $followed_id: ID!){
    af: declineFollower(user_id: $user_id, followed_id: $followed_id){
        user_id
        followed_id
        date
        id
        petitioner
        privacy
        state

        user {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }

        followed {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }
    }

    af1: declineFollower(user_id: $followed_id, followed_id: $user_id){
        user_id
        followed_id
        date
        id
        petitioner
        privacy
        state
        user {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }
        followed {
            user_id
            user_name
            user_image
            user_role
            user_fuente
        }
    }
}`;


export const NEW_CONNECTION = `
subscription MySubscription ($user_id: ID!) {
    subscribeToNewFollower(user_id: $user_id) {
        date
        state
        followed_id
        id
        petitioner
        privacy
        user_id
        followed {
            state
            user_fuente
            user_id
            user_image
            user_name
            user_role
        }
    }
}`;



