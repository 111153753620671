
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { retry } from "rxjs/operators";

import { AppService } from '../../app.service';

@Injectable()
export class PreviewUrlService {
    
    headers: any;
    data_user: any;
    url_api: string;
    baseLambdaUrl: string;


    constructor(
        private _http: HttpClient,
        private _appService: AppService,
    ) {
        this.headers = this._appService.getHeaders();
        this.data_user = this._appService.user_data;
        this.url_api = this._appService.url_Base;
        this.baseLambdaUrl = this._appService.baseLambdaUrl;
    }




    getMetaDataUrl(url: string): Observable<any> {
        // url params encode base64.
        let siteWeb = btoa(url);
        return this._http.get(this.baseLambdaUrl + '/webpages/metadata?url="' + siteWeb + '"', this.headers).pipe(retry(3));
    }




    /*
    * Get title from a specific document.
    */
    getTitleFromDocument(doc: any): string | null {

        let title = doc.querySelector('meta[property="og:title"]') as HTMLMetaElement;

        if (title != null && title.content.length > 0) {
            return title.content;
        }

        const twitterTitle = doc.querySelector('meta[name="twitter:title"]') as HTMLMetaElement;

        if (twitterTitle != null && twitterTitle.content.length > 0) {
            return twitterTitle.content;
        }

        const docTitle = doc.title;

        if (docTitle != null && docTitle.length > 0) {
            return docTitle;
        }

        const h1 = doc.querySelector("h1").innerHTML;

        if (h1 != null && h1.length > 0) {
            return h1;
        }

        const h2 = doc.querySelector("h1").innerHTML;

        if (h2 != null && h2.length > 0) {
            return h2;
        }

        return null;
    }




    /*
    * Get description from a specific document.
    */
    getDescriptionFromDocument(doc: any): string | null {

        const ogDescription = doc.querySelector('meta[property="og:description"]') as HTMLMetaElement;

        if (ogDescription != null && ogDescription.content.length > 0) {
            return ogDescription.content;
        }

        const twitterDescription = doc.querySelector('meta[name="twitter:description"]') as HTMLMetaElement;

        if (twitterDescription != null && twitterDescription.content.length > 0) {
            return twitterDescription.content;
        }

        const metaDescription = doc.querySelector('meta[name="description"]') as HTMLMetaElement;

        if (metaDescription != null && metaDescription.content.length > 0) {
            return metaDescription.content;
        }

        let paragraphs = doc.querySelectorAll("p");
        let fstVisibleParagraph = null;

        for (let i = 0, n = paragraphs.length; i < n; i++) {
            // if object is visible in dom
            if (paragraphs[i].offsetParent !== null && !paragraphs[i].childElementCount) {
                fstVisibleParagraph = paragraphs[i].textContent;
                break;
            }
        }

        return fstVisibleParagraph;
    }




    /*
    * Get domain from a specific document.
    */
    getDomainName(doc, uri): string | null {

        const canonicalLink = doc.querySelector("link[rel=canonical]");

        if (canonicalLink != null && canonicalLink.href.length > 0) {
            return canonicalLink.href;
        }

        const ogUrlMeta = doc.querySelector('meta[property="og:url"]');

        if (ogUrlMeta != null && ogUrlMeta.content.length > 0) {
            return ogUrlMeta.content;
        }

        return uri;
    }




    /*
  * Get image from a specific document.
  */
    getImageFromDocument(doc: any, uri: any): string | null {

        // Take image from meta elements or head by specific tag.

        const ogImg = doc.querySelector('meta[property="og:image"]');

        if (ogImg != null && ogImg.content.length > 0) {
            if (this.isValidURL(ogImg.content)) {
                return ogImg.content + '';
            }
        }

        const imgRelLink = doc.querySelector('link[rel="image_src"]');

        if (imgRelLink != null && imgRelLink.href.length > 0) {
            if (this.isValidURL(imgRelLink)) {
                // return imgRelLink.href + '';
                return imgRelLink.href.replace('http:', 'https:');
            }
        }

        const twitterImg = doc.querySelector('meta[name="twitter:image"]');

        if (twitterImg != null && twitterImg.content.length > 0) {
            if (this.isValidURL(twitterImg.content)) {
                return twitterImg.content + '';
            }
        }

        // Take all links if before search was not successfully.

        const imgsRelLinks = Array.from(doc.getElementsByTagName("link"));

        for (let index = 0, n = imgsRelLinks.length; index < n; index++) {
            const re_link = imgsRelLinks[index] as any;

            if (!re_link.hasAttribute('href')) {
                continue;
            }

            if (re_link.href.match(/\.(jpeg|jpg|gif|png|svg)$/)) {
                if (this.isValidURL(re_link.href)) {
                    return re_link.href + '';
                }
            }
        }

        // If not found images in meta elements or images from head, then images are searched in DOM.

        let imgs = Array.from(doc.getElementsByTagName("img"));

        if (!imgs.length) {
            return null;
        }

        let data_images = [];

        imgs.forEach((img: any) => {
            if (img.hasAttribute("src")) {
                if (img.src.match(/\.(jpeg|jpg|gif|png|svg)$/)) {
                    data_images.push(img);
                }
            }
        });

        if (data_images.length) {

            // When image with src attribute is found.

            let image = data_images[0].getAttribute('src');

            if (image.indexOf('//') === -1) {

                try {
                    if (image.charAt(0) === '/') {
                        image = `${new URL(uri).origin}${image}`;
                        return image + '';
                    }
    
                    image = `${new URL(uri).origin}/${image}`;
                } catch (error) {}

                return image + '';
            }

            // If hostname is replaced by local hostname and hostname of uri is diferent.

            try {
                if ((new URL(image).origin).indexOf('localhost') !== -1) {
                    let first_url = new URL(uri).origin;
                    let second_url = new URL(image).origin;
                    image = image.replace(second_url, first_url);
                    return image + '';
                }
            } catch (error) {}


            if (this.isValidURL(image + '')) {
                return image + '';
            }
        }

        // if not found some image, then images by tag img with attribute diferent of src are searched.

        for (let img_index = 0, n = imgs.length; img_index < n; img_index++) {
            let img = imgs[img_index] as HTMLElement;
            if (img.attributes.length) {
                let attributes = img.attributes;
                for (let attr_index = 0, n = attributes.length; attr_index < n; attr_index++) {
                    if (attributes[attr_index].name != 'class' && attributes[attr_index].name != 'id' && attributes[attr_index].name != 'alt' && attributes[attr_index].name != 'style') {
                        if (this.isValidURL(attributes[attr_index].value)) {
                            try {
                                if (new URL(window.location.href).origin != new URL(attributes[attr_index].value).origin) {
                                    return attributes[attr_index].value + '';
                                }
                            } catch (error) {}
                        }
                    }
                }
            }
        }

        return null;
    }





    /*
    * Check if string is url valid or not.
    */
    isValidURL(text: string): boolean {

        let url;

        if (!text.length) {
            return false;
        }

        try {
            url = new URL(text);
            if (url.protocol === "http:" || url.protocol === "https:") {
                return true;
            }
        } catch (error) {
            return false;
        }
    }

}