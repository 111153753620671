import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { DataConnection, DataSuggestion } from '../components/nav-bar/connections/model';
import { gql, QueryRef } from 'apollo-angular';
import { ADD_FOLLOWER_SCHEMA, APPROVE_FOLLOWER_SCHEMA, DELETE_FOLLOWER_SCHEMA, GET_CONNECTIONS_SCHEMA, NEW_CONNECTION } from './connetion-schemas';
import { ProfileService } from 'src/app/auth/services/profile.service';


@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  public ws_host = 'tcm5oi5qdveqpamqp4txivk54q.appsync-api.us-east-1.amazonaws.com';

  // graphql
  connectionsQuery: QueryRef<any>;
  connectionsObservable: Observable<any>;
  connectionStatus: Subject<any> = new Subject<any>();

  public notification = {
    show: false,
    close: false,
    title: '',
    connection: {} as DataConnection
  }

  user_id
  headers: any;
  data_user: any;
  UrlLambdaApi: string;

  // Loaders
  public loadingSuggestions = true;
  public loadingPendingConnections = true;
  public loadingConfirmedConnections = true;


  // public isFirstTime = true;
  public countPendingConnection = 0;
  public pendingConnections: Array<DataConnection> = [];
  public confirmedConnections: Array<DataConnection> = [];
  
  public suggestions: Array<DataSuggestion> = [];

  constructor(
    private http: HttpClient,
    private _appService: AppService,
    private _profileUserService: ProfileService
  ) {
    this.headers = this._appService.getHeaders();
    this.data_user = this._appService.user_data;
    this.UrlLambdaApi = this._appService.baseLambdaUrl;

    this.user_id = this._appService.user_data.id;
  }

  private getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })

    return ({ headers: headers });
  }

  public switchNotificationAlert(op) {
    this.notification.show = op == 'on' ? true : false;
    this.notification.close = op == 'on' ? false : true;;
  }

  public formmatConnectionData(data){

    let connection = {} as DataConnection;

    let urlAvatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
    let avatarDefault = 'https://d3puay5pkxu9s4.cloudfront.net/Users/default/medium_imagen.jpg?nocahe=1625022257';

    let image = data?.followed.user_image ? urlAvatar + data?.followed.user_id + '/medium_' + data?.followed.user_image : avatarDefault;

    if(data?.followed.user_image.includes('google') || data?.followed.user_image.includes('facebook')){
      image = data?.followed.user_image
    }

    connection = {
      user_id: data?.followed.user_id,
      username: data?.followed.user_name,
      // ocupation: item.Profile[0]?.pro  fesion,
      photo: image,
      photoDefault: avatarDefault,
      role: data?.followed.user_role,
      // country: item.Country.name,
      // flag: urlFlag + item.Country.short_name.toLowerCase() + '.svg',
      // countryShortName: item.Country.short_name.toLowerCase(),
      state: data?.state,
      date: data ? data.date : '',
      petitioner: data?.petitioner
    }

    return connection
  }

  initConnectionSubcription(){
    
    const ws_headers = {
      "Authorization": AppService.idtoken,
      "host": this.ws_host
    };

    setTimeout(() => {
      this.connectionsQuery.subscribeToMore({
        document: gql(NEW_CONNECTION),
        variables: { user_id: this.user_id },
        extensions: { authorization: ws_headers },

        updateQuery: (prev, { subscriptionData }) => {

          // console.log(":::updateQuery:::", subscriptionData);

          const {state, petitioner, user_id, followed_id} = subscriptionData.data.subscribeToNewFollower

          this.connectionStatus.next(subscriptionData.data.subscribeToNewFollower);

          // Elimino la sugerencia en caso que exista
          this.deleteSuggestion(followed_id).subscribe()
          this.deleteSuggestion(user_id).subscribe()

          // Quito la alerta en caso que se esté mostrando una
          if (this.notification.show) this.switchNotificationAlert('off');

          // Si le  enviaron una nueva solicitud al usuario logueado
          if (state == "0" && petitioner != this.user_id.toString()){

            this.countPendingConnection ++;
            this._appService.playTone()

            let connection: DataConnection = this.formmatConnectionData(subscriptionData.data.subscribeToNewFollower);
            this.pendingConnections.unshift(connection)

            this.switchNotificationAlert('on');            
            this.notification.title = "Nueva solicitud de seguimiento"
            this.notification.connection = connection;
          }

          // Si aceptaron una solicitud que el usuario logueado había enviado
          else if (state == "1" && petitioner == this.user_id){

            this._appService.playTone()
            
            let connection: DataConnection = this.formmatConnectionData(subscriptionData.data.subscribeToNewFollower);

            this.switchNotificationAlert('on');
            this.notification.title = `${connection.username.split(" ")[0]} ha aceptado tu solicitud`
            this.notification.connection = connection;


          }

          else {
            // Todo
          }

        }

      })
    }, 1000);
  }

  getConnections(state, exclude_own=1, after?): Observable<any> {

    const conn_status = {
      'pending': "0",
      'confirmed': "1",
    }

    this.connectionsQuery = this._appService._apolloClient.watchQuery({
      query: gql `${GET_CONNECTIONS_SCHEMA}`,
      variables: {
        state: conn_status[state], 
        exclude_own,
        after
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all'

    });

    return this.connectionsQuery.valueChanges.pipe(

      map((response: any) => {

        let data = response.data.allFollowerConnection

        if (data.followers.length == 0) return { valid: false, print: []}

        return {
          valid: true,
          print: data.followers,
          next: data.nextToken
        }
      })
    );
  }


  createFollower(followed_id): Observable<any> {
    const mutation = {
      mutation: gql(ADD_FOLLOWER_SCHEMA),
      variables: {
        followed_id, 
        user_id: this.user_id, 
        petitioner: this.user_id,
        privacy: 'public'
      }
    }

    return this._appService._apolloClient.mutate(mutation).pipe(
      map( (response:any) => {

        // Si la respuesta me la propiedad salió todo bien
        const status = response.data.hasOwnProperty('cf');

        return {status, data: response};

      })
    );
  }


  approveFollower(followed_id): Observable<any> {

    const mutation = {
      mutation: gql(APPROVE_FOLLOWER_SCHEMA),
      variables: {
        user_id: this.user_id,
        followed_id: followed_id
      }
    }

    return this._appService._apolloClient.mutate(mutation).pipe(
      map((response:any) => {

        const status = response.data.hasOwnProperty('af');

        return {status, data: response};

      })
    );
  }


  declineFollower(followed_id): Observable<any> {

    const mutation = {
      mutation: gql(DELETE_FOLLOWER_SCHEMA),
      variables: {
        user_id: this.user_id,
        followed_id: followed_id
      }
    }

    return this._appService._apolloClient.mutate(mutation).pipe(
      map((response:any) => {
        const status = response.data.hasOwnProperty('af');

        return {status, data: response};
      })
    );
  }


  getCountConnections(user_id, state): Observable<any> {

    let url =  `/followers?user_id=${user_id}&state=${state}&pages=true&petitioner=0`

    return this.http.get(this.UrlLambdaApi + url, this.getHeadersLambda()).pipe(
      map((response: any) => {

        const {status, data, lastEvaluatedKey} = response

        return { count: status ? data[0].count : 0, status }

      }),
      retry(3),
      catchError(this.handleError<any>('getCountFolloweds', []))
    );
  }

  subjectSuggestion: Subject<any> = new Subject<any>();

  /**
   * 
   * @param user_id 
   * @param last 
   * @param pages 
   * @param context El contexto sirve para saber desde donde se ejecuta esta función y programar una logica en base a eso
   * @returns 
   */
  getSuggestions(user_id, last?, context='APPBAR-CONNECTIONS'): Observable<any> {

    let url =  `/users/${user_id}/suggestions`
    
    if (last?.hasOwnProperty("user_id1")) url += `?LastEvaluatedKey=${JSON.stringify(last)}`


    return this.http.get(this.UrlLambdaApi + url, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const {status, data, lastEvaluatedKey} = response

        if (status) {

          const data_return = { print: data, valid: status, lastEvaluatedKey: lastEvaluatedKey, context }

          this.subjectSuggestion.next(data_return)

          return data_return;

        } else {

          this.subjectSuggestion.next({valid: false})

          return { print: data[0], valid: status }

        }
      }),
      retry(3),
      catchError(this.handleError<any>('getFolloweds', []))
    );
  }


  deleteSuggestion(user_id1): Observable<any>{

    return this.http.delete(`${this.UrlLambdaApi}/suggestions/${this.user_id}/${user_id1}`, this.getHeadersLambda()).pipe(
      map((response: any) => {

        this.refreshItems('suggestions', {user_id: user_id1},  '-1')
        return response;

      }),
      catchError((error: any) => {
        this.handleError('putItem');
        return of({ status: false });
      })
    );
  }

  /**
   * 
   * @param source La variable que se va a refrescar pueden ser 3
   * 
   * `pendingConnections`: Conexiones pendientes
   * `suggestions`: Listado de sugerencias
   * ``
   * @param data Especificar un objeto con user_id ejemplo `{user_id: 123}`
   * @param res Para manejos internos `default: '-1' `
   */
  refreshItems(source, data, res='-1') {

    let user: DataConnection
    for (let i=0; i < this[source].length; i++){
      if (this[source][i].user_id == data.user_id){
        user = this[source].splice(i, 1)[0]
        if (res == "1" && source == 'pendingConnections') this.confirmedConnections.push(user);
      }
    }
  }


  putItem(url_put, data): Observable<any> {
    return this.http.put(`${this.UrlLambdaApi}${url_put}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {

        return response;
      }),
      catchError((error: any) => {
        this.handleError('putItem');
        return of({ status: false });
      })
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      console.log('%cerror::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead            
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  clearAllData() {
    this.countPendingConnection = 0;
    this.pendingConnections = [];
    this.confirmedConnections = [];
  }

}
