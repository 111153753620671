<!-- Modal de las reacciones en el mensaje -->
<div class="reactions-modal" #reactionsModal (mouseleave)="closeReactionsModal()"
  [ngStyle]="{'bottom': positionOpenReactionsModal}">
  <div class="reactions-modal-container">
    <div class="reactions-modal__header">


      <div class="modal-tabs" #itemSlider (mousedown)="mousedown_slider($event)" (touchstart)="mousedown_slider($event)"
        (mouseleave)="mouseleave_slider()" (mouseup)="mouseup_slider()" (touchend)="mouseup_slider()"
        (mousemove)="mousemove_slider($event)" (touchmove)="mousemove_slider($event)">

        <a class="tab tab_all" [class.active]="reaction_filter_selected == 'all'" (click)="selectReactionFilter('all')">
          Todas
          <span class="tab_reaction-counter">
            {{ reactions_length }}
          </span>
        </a>

        <a class="tab tab_reaction" *ngFor="let r of reactions | groupEmojiTypePipe : resumed_reactions"
          [attr.data-label]="(r[0].name | reactionEmojiPipe).label" (click)="selectReactionFilter(r[0].name)"
          [class.active]="reaction_filter_selected == r[0].name">
          {{ (r[0].name | reactionEmojiPipe).emoji }}
          <span class="tab_reaction-counter">
            {{ r[0].reactions }}
          </span>
        </a>

      </div>


    </div>

    <ul class="chat-inbox__msgs">
      <li class="received_msg" *ngFor="let react of reactions | filterByReactionPipe:reaction_filter_selected">
        <img #conversationAvatar class="receiver-avatar" [src]="react | urlImage:4">
        <div class="message__content">
          <div class="message__title">
            <a [href]="environment.appDomain + '/user/profile/' + react.user.user_id" target="_blank"
              class="received__username">
              {{ react.user.user_name|cutUserName:'2' }}
            </a>
            <div class="received__date" [attr.data-label]="(react.reaction | reactionEmojiPipe).label">
              {{ (react.reaction | reactionEmojiPipe).emoji }}
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- Este botón solo aparece cuando se ve desde celular -->
    <a class="button btn btn__dark-blue" role="button" (click)="show_reactions = null;">
      Cerrar
    </a>

  </div>
</div>