  <div class="controls">

    <audio #HMLT5AudioPlayer [src]="audioFile" rossorigin="anonymous" (loadedmetadata)="audioLoaded()"
      (play)="onStateChange(1)" (pause)="onStateChange(2)" (ended)="onStateChange(0)"
      (timeupdate)="onStateChange(4)"></audio>

    <button class="player-button" (click)="toggleAudio()">
      <span [ngClass]="audioState == 1 ? 'icon-pause' : 'icon-play-arrow'"></span>
    </button>
    <input type="range" class="timeline" min="0" max="100" step="0.01" step="0.01" [(ngModel)]="progressTime"
      [style.--progress-width]="(progressTime) + '%' | safeStyle" (ngModelChange)="getIntTime($event)"
      (mousedown)="audioPlayer.pause()" (touchstart)="audioPlayer.pause()" />
    <!-- <button class="sound-button ">
      <span class="icon-volume-high"></span>
    </button> -->
  </div>

  <label *ngIf="audioPlayer">
    {{ audioPlayer.currentTime | audioPlayeTimePipe: audioPlayer.duration}} /
    {{ audioPlayer.duration | audioPlayeDurationPipe }}
  </label>
  <label *ngIf="!audioPlayer">
    00:00 / 00:00
  </label>
