
import { NgModule } from '@angular/core';
import { MenuMsgDirective } from './directives/menu-msg.directive';
import { HoverMsgDirective } from './directives/hover-msg.directive';


@NgModule({
  declarations: [
    HoverMsgDirective,
    MenuMsgDirective,
  ],
  imports: [],
  exports: [
    HoverMsgDirective,
    MenuMsgDirective,
  ]
})
export class MessageModule { }
