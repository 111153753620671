import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ClassroomGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(private _appService: AppService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { valid, session } = this._appService.getUserData();
    // Cognito User
    if (valid) {
      // User with session or without session
      if (session != -1) {
        const { config } = next.queryParams;
        // Url with config
        if (config || sessionStorage.getItem('config')) {
          // sessionStorage.setItem('config', config);
          // Show nav bar and footer
          this._appService.loadNavBar = true;
          this._appService.loadFooter = false;
          // Go
          return true;
        }
      }
      // User session in -1
      return this._router.parseUrl('/category');
    }
    // Cognito User error
    return this._router.parseUrl('/user/login');
  }

  canDeactivate() {
    const { session, print } = this._appService.getUserData();
    // User with session
    if (session == 1) {
      const { certification_id } = print;
      // User with certification
      if (certification_id && certification_id != '0' && certification_id != '-1') {
        if (this._appService.learning.length > 0) {
          // update learning
          this._appService.learning[0].active = false;
        }
      }
    }
    return true;
  }

}
