import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'hover-card',
  templateUrl: './hover-card.component.html',
  styleUrls: ['./hover-card.component.scss']
})
export class HoverCardComponent {

  constructor() { }

  environment:any = environment;
  user: any;
  @Input() user_hover: any;
  @Input() user_state: boolean = false;
  @Input() actions: boolean = false;
  @Output() followParticipant: EventEmitter<any> = new EventEmitter<any>();
  @Output() openChat: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('hover_card') hover_card;

  conversations_loaded = false;
  can_send_message = false;

  // Assist variables
  @Input() hover_assist: boolean = false;

  // ngOnInit(): void {
  //   // lambda para obtener certificados del usuario
  //   // https://api.edutin.com/b/d/users/239965/certifications

  //   // console.log('user: ', this.user);
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('user_hover') && this.user_hover != undefined) {
      this.user = JSON.parse(JSON.stringify(this.user_hover));
      this.user_id_to_follow = false;
    }
  }


  is_completed: boolean = false;

  user_id_to_follow: boolean = false;
  // loading_chat_user_id = null;
  conversations_loading = false;
  _openChat(user) {
    this.conversations_loading = true;
    this.openChat.emit(user);

    setTimeout(() => {
      this.conversations_loading = false;
    }, 3000);

  }

}
