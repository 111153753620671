
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { messageDate } from '../shared.pipe';

@Directive({
  selector: '[appHoverMsg]'
})
export class HoverMsgDirective {

  @Input() appHoverMsg;
  @Input() user_data;

  constructor(
    private _messageElement: ElementRef,
    private _renderer2: Renderer2,
  ) { }




  @HostListener('mouseenter')
  mouseenter() { this.onElement() }

  @HostListener('mouseleave')
  mouseout() { this.resetStylesElement() }




  /*
  * Verify and set styles or set functionality according element.
  */
  onElement(): void {

    if (this.appHoverMsg.type !== 'TEXT') {
      this.setStylesMessageFile(this.appHoverMsg);
      return;
    }

    if (this.isParentMessage(this.appHoverMsg)) {
      this.setStylesParentMessage();
      return;
    }

    if (this.isMessageURL(this.appHoverMsg)) {
      if (this.isUrlYoutube(this.appHoverMsg.content)) {
        this.setStylesMessageYoutube();
        return;
      }
      this.setStylesMessageURL();
      return;
    }

    if (!this.isMessageOwn(this.appHoverMsg, this.user_data)) {
      this.setStylesLeft();
      return;
    }

    this.setStylesRight();
  }




  /*
  * Verify if meesage is parent.
  * Param: Type any and this have all properties of a message.
  */
  isParentMessage(message: any): boolean {
    return ((message.parent_id === null || message.parent_id === undefined) ? false : true);
  }




  /*
  * Check if string of message is url valid or not.
  * Param: Type any and this have all properties of a message.
  */
  isMessageURL(message: any): boolean {

    if (message.type !== 'TEXT') {
      return false;
    }

    if (!message.content.length) {
      return false;
    }

    try {
      let url = new URL(message.content);
      if (url.protocol !== "http:" && url.protocol !== "https:") {
        return false;
      }
    } catch (error) {
      return false;
    }

    return true;
  }




  /*
  * Check if url is youtube.
  */
  isUrlYoutube(url: string): any {
    return (url.indexOf('https://www.youtube.com/watch?v=') !== -1 || url.indexOf('https://youtu.be/') !== -1);
  }




  /*
  * Param1: Type any and this have all properties of a message.
  * Param2 : Type any and this have all properties and data of a user logged.
  */
  isMessageOwn(message: any, user: any): boolean {
    return (Number(message.user_id) === Number(user.id));
  }




  /*
  * Set styles to message own.
  */
  setStylesRight(): void {

    let element = this._messageElement.nativeElement.getElementsByClassName("menu-actions-message-contain");
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');

    if (!element.length || !icon.length) {
      return;
    }

    // Styles contain.
    this._renderer2.addClass(element[0], 'bg-normal-msg-right-menu');
    this._renderer2.setStyle(element[0], 'width', ((this._messageElement.nativeElement.offsetWidth < 40) ? '100%' : '40px'));
    this._renderer2.setStyle(element[0], 'height', '40px');

    // Styles icon menu.
    this._renderer2.removeClass(icon[0], 'none');
  }




  /*
  * Set styles to message not own.
  */
  setStylesLeft(): void {

    let element = this._messageElement.nativeElement.getElementsByClassName("menu-actions-message-contain");
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');

    if (!element.length || !icon.length) {
      return;
    }

    // Styles contain.
    this._renderer2.addClass(element[0], 'bg-normal-msg-left-menu');
    this._renderer2.setStyle(element[0], 'width', ((this._messageElement.nativeElement.offsetWidth < 40) ? '100%' : '40px'));
    this._renderer2.setStyle(element[0], 'height', '40px');

    // Styles icon menu.
    this._renderer2.removeClass(icon[0], 'none');
  }




  /*
  * Set styles to parent message own and not own.
  */
  setStylesParentMessage(): void {

    let replyContain = this._messageElement.nativeElement.getElementsByClassName('msg__reply');
    let replyBgMenu = this._messageElement.nativeElement.getElementsByClassName('bg-parent-msg-menu');
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');

    if (!icon.length || !replyBgMenu.length || !replyContain.length) {
      return;
    }

    // Styles contain.
    this._renderer2.setStyle(replyBgMenu[0], 'width', ((replyContain[0].offsetWidth < 200) ? '100%' : '200px'));
    this._renderer2.removeClass(replyBgMenu[0], 'none');

    // Styles icon menu.
    this._renderer2.setStyle(icon[0], 'color', '#e6e6e6');
    this._renderer2.setStyle(icon[0], 'z-index', '1');
    this._renderer2.removeClass(icon[0], 'none');

    let content_info_youtube = this._messageElement.nativeElement.getElementsByClassName('content-info-youtube');
    let content_img_info_url = this._messageElement.nativeElement.getElementsByClassName('content-img-info');

    if (!content_info_youtube.length && !content_img_info_url.length) {
      return;
    }

    this._renderer2.removeAttribute(((content_info_youtube.length) ? content_info_youtube[0] : content_img_info_url[0]), 'href');
  }




  /*
  * Set styles to message url own and not own.
  */
  setStylesMessageURL(): void {

    let content_preview_url = this._messageElement.nativeElement.getElementsByClassName('content-preview-url');
    let content_img_info = this._messageElement.nativeElement.getElementsByClassName('content-img-info');
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');
    let div_bg_menu = this._renderer2.createElement("div");

    if (!content_preview_url.length || !content_img_info.length) {
      return;
    }
    
    // Create background to show menu.
    this._renderer2.setStyle(content_preview_url[0], 'position', 'relative');
    this._renderer2.addClass(div_bg_menu, 'bg-parent-msg-menu');
    this._renderer2.setStyle(div_bg_menu, 'width', '200px');
    this._renderer2.setStyle(div_bg_menu, 'height', '40px');

    // Add background menu to element preview url.
    this._renderer2.insertBefore(content_preview_url[0], div_bg_menu, content_img_info[0]);

    let bg_menu = content_preview_url[0].getElementsByClassName('bg-parent-msg-menu');
    
    if (!icon.length || !bg_menu.length) {
      return;
    }

    // Styles icon menu.
    this._renderer2.setStyle(bg_menu[0], 'border-radius', '0px');
    this._renderer2.setStyle(icon[0], 'color', '#e6e6e6');
    this._renderer2.setStyle(icon[0], 'top', '20px');
    this._renderer2.setStyle(icon[0], 'right', '25px');
    this._renderer2.setStyle(icon[0], 'z-index', '1');
    this._renderer2.removeClass(icon[0], 'none');
  }




  /*
  * Set styles to message youtube own or not own.
  */
  setStylesMessageYoutube(): void {

    let content_preview_youtube = this._messageElement.nativeElement.getElementsByClassName('content-preview-youtube');
    let content_video_youtube = this._messageElement.nativeElement.getElementsByClassName('content-video-youtube');
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');
    let div_bg_menu = this._renderer2.createElement("div");

    if (!content_preview_youtube.length || !content_video_youtube.length) {
      return;
    }
    
    // Create background to show menu.
    this._renderer2.setStyle(content_preview_youtube[0], 'position', 'relative');
    this._renderer2.addClass(div_bg_menu, 'bg-parent-msg-menu');
    this._renderer2.setStyle(div_bg_menu, 'width', '200px');
    this._renderer2.setStyle(div_bg_menu, 'height', '20px');
    
    // Add background menu to element preview url.
    this._renderer2.insertBefore(content_preview_youtube[0], div_bg_menu, content_video_youtube[0]);


    if (!icon.length) {
      return;
    }

    // Styles icon menu.
    this._renderer2.setStyle(icon[0], 'color', '#e6e6e6');
    this._renderer2.setStyle(icon[0], 'top', '20px');
    this._renderer2.setStyle(icon[0], 'right', '25px');
    this._renderer2.setStyle(icon[0], 'z-index', '1');
    this._renderer2.removeClass(icon[0], 'none');
  }




  /*
  * Set styles to message file own and not own.
  */
  setStylesMessageFile(message): void {

    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');
    let bg_msg_menu = this._messageElement.nativeElement.getElementsByClassName('bg-parent-msg-menu');

    if (!icon.length || !bg_msg_menu.length || !message.type) {
      return;
    }

    this._renderer2.setStyle(icon[0], 'color', '#e6e6e6');
    this._renderer2.removeClass(bg_msg_menu[0], 'none');
    this._renderer2.removeClass(icon[0], 'none');
    
    if (message.type === 'FILE') {
      this._renderer2.setStyle(icon[0], 'top', '20px');
      this._renderer2.setStyle(icon[0], 'right', '15px');
      this._renderer2.setStyle(icon[0], 'z-index', '1');
      this._renderer2.setStyle(bg_msg_menu[0], 'width', ((bg_msg_menu[0].offsetWidth < 150) ? '100%' : '150px'));
      return;
    }

    if (message.type === 'VIDEO') {
      this._renderer2.setStyle(icon[0], 'top', '10px');
      this._renderer2.setStyle(icon[0], 'right', '12px');
      this._renderer2.setStyle(icon[0], 'z-index', '1');
      this._renderer2.setStyle(bg_msg_menu[0], 'top', '6px');
      this._renderer2.setStyle(bg_msg_menu[0], 'right', '6px');
      this._renderer2.setStyle(bg_msg_menu[0], 'border-radius', '0px');
      this._renderer2.setStyle(bg_msg_menu[0], 'width', '150px');
      return;
    }

    if (message.type === 'IMAGE') {
      this._renderer2.setStyle(icon[0], 'top', '20px');
      this._renderer2.setStyle(icon[0], 'right', '15px');
      this._renderer2.setStyle(icon[0], 'z-index', '3');
      this._renderer2.setStyle(bg_msg_menu[0], 'top', '15px');
      this._renderer2.setStyle(bg_msg_menu[0], 'right', '8px');
      this._renderer2.setStyle(bg_msg_menu[0], 'z-index', '1');
      this._renderer2.setStyle(bg_msg_menu[0], 'border-radius', '0px');
      this._renderer2.setStyle(bg_msg_menu[0], 'width', '150px');
      return;
    }

    if (message.type === 'AUDIO') {
      this._renderer2.setStyle(icon[0], 'z-index', '1');
      this._renderer2.setStyle(bg_msg_menu[0], 'width', '150px');
    }
  }




  /*
  * Delete styles about message.
  */
  resetStylesElement(): void {

    this.resetMenuMessage();

    if (this.isMessageURL(this.appHoverMsg)) {
      this.resetStylesElementURL();
      return;
    }

    this.resetStylesElementNormal();
  }




  /*
  * Reset the styles only if message is not about url.
  */
  resetStylesElementNormal(): void {

    let element = this._messageElement.nativeElement.getElementsByClassName("menu-actions-message-contain");
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');
    
    if (!element.length || !icon.length) {
      return;
    }
    
    // Styles contain.
    this._renderer2.setStyle(element[0], 'width', '0px');
    this._renderer2.setStyle(element[0], 'height', '0px');
    this._renderer2.removeClass(element[0], 'bg-normal-msg-left-menu');
    this._renderer2.removeClass(element[0], 'bg-normal-msg-right-menu');

    // Styles icon menu.
    this._renderer2.addClass(icon[0], 'none');

    let replyBgMenu = this._messageElement.nativeElement.getElementsByClassName('bg-parent-msg-menu');

    if (!replyBgMenu.length) {
      return;
    }

    // Styles contain.
    this._renderer2.addClass(replyBgMenu[0], 'none');
  }




  /*
  * Reset the styles and delete elements only message about url.
  */
  resetStylesElementURL(): void {

    let div_bg_menu = this._messageElement.nativeElement.getElementsByClassName('bg-parent-msg-menu');
    let contain_youtube = this._messageElement.nativeElement.getElementsByClassName('content-preview-youtube');
    let icon = this._messageElement.nativeElement.getElementsByClassName('menu-actions-message');

    if (icon.length) {
      this._renderer2.addClass(icon[0], 'none');
      this._renderer2.setStyle(icon[0], 'top', '7px');
      this._renderer2.setStyle(icon[0], 'right', '10px');
    }
    
    if (contain_youtube.length && div_bg_menu.length) {
      this._renderer2.removeChild(contain_youtube[0], div_bg_menu[0]);
      return;
    }

    let contain_url = this._messageElement.nativeElement.getElementsByClassName('content-preview-url');

    if (contain_url.length) {
      this._renderer2.removeChild(contain_url[0], div_bg_menu[0]);
    }
  }




  /*
  * Reset to menu of message.
  */
  resetMenuMessage(): void {

    let menu = this._messageElement.nativeElement.getElementsByClassName('actions-menu-message');

    if (!menu.length) {
      return;
    }

    this._renderer2.removeClass(menu[0], 'up-menu');
    this._renderer2.removeClass(menu[0], 'down-menu');
    this._renderer2.removeClass(menu[0], 'left-menu');
    this._renderer2.removeClass(menu[0], 'right-menu');

    if (menu[0].classList.contains('none')) {
      return;
    }

    this._renderer2.addClass(menu[0], 'none');
  }

}
