import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-courses',
  templateUrl: './new-courses.component.html',
  styleUrls: ['./new-courses.component.scss']
})
export class NewCoursesComponent implements OnInit {

  environment: any = environment;

  windowWidth: number;
  @ViewChild('rowCards_') rowCards_: ElementRef;
  rowWidth: number;
  cardsShow: number = 0;
  cardWidth: number;
  translate: number = 0;
  translateAbs: number = 0;
  @ViewChild('leftArrow_') leftArrow_: ElementRef;
  @ViewChild('rigthArrow_') rigthArrow_: ElementRef;

  stopGetnewCourses: boolean = true;

  constructor(
    public appService: AppService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onCarouselResize(true);
    }, 0);
  }

  nextPage() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onCarouselResize(true);
  }

  onCarouselResize(init: boolean) {
    this.windowWidth = window.innerWidth;
    this.rowWidth = Number(this.rowCards_.nativeElement.offsetWidth);
    if (this.windowWidth <= 580) {
      this.cardsShow = 1;
      this.cardWidth = this.rowWidth;
    } else if (this.windowWidth <= 832) {
      this.cardsShow = 2;
      this.cardWidth = this.rowWidth / this.cardsShow;
    } else if (this.windowWidth <= 1024) {
      this.cardsShow = 3;
      this.cardWidth = this.rowWidth / this.cardsShow;
    } else {
      this.cardsShow = 4;
      this.cardWidth = this.rowWidth / this.cardsShow;
    }
    // Default translate
    this.translate = 0;
    this.rowCards_.nativeElement.children[0].style.transform = "translate(" + this.translate + "px, 0px)";
    this.translateAbs = Math.abs(this.translate);
  }

  desplaceCards(type: any) {
    let cardsLength: number = 0;
    this.rowWidth = Number(this.rowCards_.nativeElement.offsetWidth);
    if (typeof (type) != "boolean") {
      type++;
      if (type <= this.cardsShow) {
        this.translate = 0;
      } else {
        this.translate = 0;
      }
    } else {
      this.translate = (type) ? this.translate - this.rowWidth : this.translate + this.rowWidth;
      if (window.innerWidth <= 580) {
        cardsLength = (this.appService.newCourses.length * this.rowWidth) - this.rowWidth;
      } else if (window.innerWidth <= 832) {
        cardsLength = (this.appService.newCourses.length * (this.rowWidth / 2)) - this.rowWidth;
      } else if (window.innerWidth <= 1024) {
        cardsLength = (this.appService.newCourses.length * (this.rowWidth / 3)) - this.rowWidth;
      } else {
        cardsLength = (this.appService.newCourses.length * (this.rowWidth / 4)) - this.rowWidth;
      }
    }
    this.rowCards_.nativeElement.children[0].style.transform = "translate(" + this.translate + "px, 0px)";
    this.translateAbs = Math.abs(this.translate);
    if (this.translateAbs >= (cardsLength - this.rowWidth) && this.stopGetnewCourses) {
      this.appService.loadNewCoursesPage = true;
      this.appService.getNewCourses().subscribe(data => {
        if (data.valid) {
          if (data.print.length > 0) {
            // Limit pagination for next query
            if (this.appService.newCoursesPage == 10) {
              this.stopGetnewCourses = false;
            }
            // Set response
            this.appService.newCourses = this.appService.newCourses.concat(data.print);
            // Next pagination
            this.appService.newCoursesPage += 1;
            // Values for new carrusel
            cardsLength = (this.appService.newCourses.length * (this.rowWidth / 2)) - this.rowWidth;
            if (this.translate == 0) {
              this.leftArrow_.nativeElement.hidden = true;
              this.rigthArrow_.nativeElement.hidden = false;
            } else if (this.translate > 0 && this.translate < cardsLength) {
              this.rigthArrow_.nativeElement.hidden = false;
              this.leftArrow_.nativeElement.hidden = false;
            } else if (this.translate >= cardsLength) {
              this.rigthArrow_.nativeElement.hidden = true;
              this.leftArrow_.nativeElement.hidden = false;
            }
          } else {
            this.stopGetnewCourses = false;
            if (this.translate == 0) {
              this.leftArrow_.nativeElement.hidden = true;
              this.rigthArrow_.nativeElement.hidden = false;
            } else if (this.translate > 0 && this.translate < cardsLength) {
              this.rigthArrow_.nativeElement.hidden = false;
              this.leftArrow_.nativeElement.hidden = false;
            } else if (this.translate >= cardsLength) {
              this.rigthArrow_.nativeElement.hidden = true;
              this.leftArrow_.nativeElement.hidden = false;
            }
          }
        } else {
          this.stopGetnewCourses = false;
        }
        this.appService.loadNewCoursesPage = false;
      });
    } else {
      if (this.translate == 0) {
        this.leftArrow_.nativeElement.hidden = true;
        this.rigthArrow_.nativeElement.hidden = false;
      } else if (this.translate > 0 && this.translate < cardsLength) {
        this.rigthArrow_.nativeElement.hidden = false;
        this.leftArrow_.nativeElement.hidden = false;
      } else if (this.translate >= cardsLength) {
        this.rigthArrow_.nativeElement.hidden = true;
        this.leftArrow_.nativeElement.hidden = false;
      }
    }
  }
}
