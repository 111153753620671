import { Component, OnInit, HostListener, ViewChild, ViewChildren, ElementRef, OnDestroy, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ChatRoomService } from './chat-room.service';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AWSS3Service } from '../../../classroom/shared-classroom/awsS3.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';
import { ModalDialogComponent } from '../../../classroom/shared-classroom/modal-dialog/modal-dialog.component';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss']
})
export class ChatRoomComponent implements OnInit, OnDestroy {

  environment:any = environment;
  static chatQuerySubscription: Subscription;
  width_screen;
  urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
  urlavatar1 = 'https://d3puay5pkxu9s4.cloudfront.net/Conversations/';
  /*conversation: conversationInterface = { conversation: {},last_message: null,
    last_update: null};*/

  conversation_msg = { data: [], next_token: String };
  end_msg: boolean = false;
  newMessage: string;
  message_id: string;
  open_file_previsual: boolean = false;
  previsual_src: string;
  previsual_text: string;
  fileUrl: any;
  show_add_user: boolean = false;

  // reply message and options message
  menu_msg_id: number | null;
  reactions_msg_id: any | null;
  message_parent: any | null;
  display_chat_notification: boolean; // Mostrar notificación cuando no se halló el mensaje replicado.
  searchReplyMsg: number = null;
  msgReply: any = null;
  display_floating_button: boolean = false; // Mostrar boton flotante para bajar en el chat.

  @Output() closeThisLiveChat = new EventEmitter();//enviar evento a app.component para cerrar este chat

  @ViewChild('chatMessages') chatMessages: ElementRef;
  @ViewChild('newMessageChatRoom') newMessageChatRoom: ElementRef;
  @ViewChild('cronSeconds') cronSeconds: ElementRef;
  @ViewChild('cronMinutes') cronMinutes: ElementRef;

  @Input() chat_user;
  @Input() input_state;

  load_chatroom = true;
  load_msg = false;
  new_chat: boolean = false;
  show_file_options: boolean = false;
  searchtext_: string = '';

  validAcceptTypes = {
    image: 'image/*',
    file: '.pdf,.xls,.doc,.docx,.pptx,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,text/html,video/,.rar,.zip,.dwg,.py',
    // video: 'video/*'
    video: 'video/mov, video/mp4, video/3gp'
  };

  file_type = '';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width_screen = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
  }

  scroll_elem: number = 0;

  group_name: string = '';


  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    
    if (event.target.scrollTop == 0) {
      if (this.load_msg == false) {
        this.scroll_elem = this.conversation_msg.data.length;
        this.getPreviousMessages();
      }
    }

    if ((event.target.scrollHeight - event.target.scrollTop) > (event.target.clientHeight + 100)) {
      this.display_floating_button = true;
    } else {
      this.display_floating_button = false;
    }
  }

  constructor(
    private _chatRoomService: ChatRoomService,
    public _appService: AppService,
    private _router: Router,
    private _awsS3Service: AWSS3Service,
    private sanitizer: DomSanitizer
  ) {
    //this.getConversations();
    this.width_screen = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
  }

  ngOnInit() {
    // this.initSubscription();
    // Mostrar los mensajes de una conversacion activa
    // this._appService.chat_user = JSON.parse(JSON.stringify(this.chat_user));
    this.showMessages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('input_state') && this.input_state != undefined) {

      if (this.input_state != 'NONE') {
        this.scrollToBottom(0);
      }
    }
  }

  get_associated(conversation, message) {
    return conversation.associated.find(element => element.user.user_id === message.user_id);
  }


  ngOnDestroy() {
    this._appService.current_conversation_index = -1;
    this.conversation_msg = { data: [], next_token: String };
    this._appService.chat_user = null;
    AppService.current_opened_conversation = undefined;
    //this.querySubscription.unsubscribe();    
  }

  showMessages() {
    this.new_chat = false;
    if (this._appService.chat_user && this._appService.chat_user.execute == 0) {

      let i = this._appService.conversations.findIndex(elemt => elemt.associated.length === 1 && elemt.associated[0].user.user_id == this._appService.chat_user.data.associated[0].user.user_id);


      if (this._appService.chat_user && this._appService.chat_user.data.associated.length > 1) {
        i = this._appService.conversations.findIndex(elemt => elemt.conversation.id == this._appService.chat_user.data.conversation.id);
      }



      if (i == -1) {
        // Nueva conversacion
        this.createEmptyConversation();


        //this.new_chat = true;
      } else {
        // Mostrar una conversacion
        this.openConversation(this._appService.conversations[i]);
      }
    } else if (this._appService.chat_user && this._appService.chat_user.execute == 1) {
      // Mostrar todas las conversaciones
      this.conversation_msg = { data: [], next_token: String };
      this.load_chatroom = false;
      this._router.navigate([], {
        queryParams: {
          conversation: -1
        },
        queryParamsHandling: 'merge',
      });
    }

  }


  close_all_menu(): void {
    this.conversation_msg.data.forEach(m => {
      m.show_options_menu = false;
    });
  }

  showing_menu_options(event: MouseEvent, m: any): void {
    m.show_options_menu = !m.show_options_menu;
    this.conversation_msg.data.forEach(me => {
      if (me.id != m.id) {
        me.show_options_menu = false;
      }
    });
    event.stopPropagation();
  }



  @ViewChild(ModalDialogComponent) modal_dialog: ModalDialogComponent;
  delete_message(event: MouseEvent, msg: any): void {
    this.modal_dialog_.state = true;
    let th = this;

    setTimeout(() => {
      if (msg.user_id === this._appService.user_data.id) {
        th.modal_dialog.modal_type = 51;
      } else {
        th.modal_dialog.modal_type = 52;
      }

      let __response = th.modal_dialog.response.subscribe(response => {

        var now = new Date().toISOString();
        switch (response) {
          case 1:
            this.loadingSendMessage = true;
            let variables1: any = {
              "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
              "created_at": now,
              "message_id": msg.id,
              "state": "DROPED",
              "user_id": this._appService.user_data.id
            };

            this._chatRoomService.deleteMessage(variables1, true, []).subscribe(rcpt => {
              if (msg.id == this.conversation_msg.data[this.conversation_msg.data.length - 1].id) {
                this._appService.conversations.forEach(conve => {
                  if (conve.conversation.id == this._appService.conversations[this._appService.current_conversation_index].conversation.id) {
                    if (this.conversation_msg.data[this.conversation_msg.data.length - 2]) {
                      conve.last_message = this.conversation_msg.data[this.conversation_msg.data.length - 2].content;
                    } else {
                      conve.last_message = '';
                    }
                    //TODO update database
                  }
                });
              }

              this.conversation_msg.data = this.conversation_msg.data.filter(element => {
                return element.id != msg.id;
              });

              //saving cache
              this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);



              this.loadingSendMessage = false;
            }, (error) => {
              console.log('there was an error sending the mutation', error);
            });

            break;
          case 2:
            this.loadingSendMessage = true;
            let variables: any = {
              "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
              "created_at": now,
              "message_id": msg.id,
              "state": "DELETED",
              "user_id": this._appService.user_data.id
            };

            this._chatRoomService.deleteMessage(variables, false, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(rcpt => {
              if (msg.id == this.conversation_msg.data[this.conversation_msg.data.length - 1].id) {
                this._appService.conversations.forEach(conve => {
                  if (conve.conversation.id == this._appService.conversations[this._appService.current_conversation_index].conversation.id) {
                    conve.last_message = 'Eliminaste este mensaje';
                    conve.deleted = true;
                    //TODO update database
                  }
                });
              }

              msg.recipients.push(rcpt.data.rm);
              this.conversation_msg.data.forEach((element, index) => {
                if (element.id === msg.id) {
                  msg.deleted = true;
                  this.conversation_msg.data[index] = msg;
                }
              });
              //saving cache
              this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);

              this.loadingSendMessage = false;
            }, (error) => {
              console.log('there was an error sending the mutation', error);
            });
            break;
        }
        this.scrollToBottom(0);
        th.modal_dialog_.state = false;
        __response.unsubscribe();
      });
    }, 100);
    event.stopPropagation();
  }

  openConversation(conversation) {

    if (this._appService.current_conversation_index > -1 && this._appService.conversations[this._appService.current_conversation_index].hasOwnProperty('direct_msg_notification') && this._appService.conversations[this._appService.current_conversation_index].direct_msg_notification == true) {

      //agegué esta validación porque estaba generando conflicto al momento de abrir el live chat cuando recibo el mensaje directo.       
      delete this._appService.conversations[this._appService.current_conversation_index].direct_msg_notification;
      this._appService.current_conversation_index = -1;
    }


    AppService.current_opened_conversation = conversation.conversation.id;
    if (this._appService.conversations[this._appService.current_conversation_index] && conversation.conversation.id === this._appService.conversations[this._appService.current_conversation_index].conversation.id) {
      return;
    }

    let i = -1;
    if (this._appService.conversations[this._appService.current_conversation_index]) {
      this.sendInputState('NONE');
    }

    this._appService.conversations.forEach(conv => {
      //console.log(conversation.conversation.id,conv.conversation.id);
      if (conversation.conversation.id == conv.conversation.id) {
        this._appService.current_conversation_index = i + 1;
      }
      i++;
    });
    this.load_chatroom = true;
    this.load_msg = true;
    //this.conversation = JSON.parse(JSON.stringify(conversation));
    this.file.src = null;




    if (ChatRoomComponent.chatQuerySubscription) {
      ChatRoomComponent.chatQuerySubscription.unsubscribe();
    }

    if (conversation != null) {
      this._router.navigate([], {
        queryParams: {
          conversation: conversation.conversation.id
        },
        queryParamsHandling: 'merge',
      });


      if (this._appService.conversations[this._appService.current_conversation_index].unreaded_messages > 0) {
        this.setUnreadConversation();
      }

      let conver = this._appService._apolloClient.getClient().readFragment({
        id: 'Conversation:' + conversation.conversation.id,
        fragment: AppService.GET_CACHED_CONVERSATION,
      });
      if (conversation.state == "CREATED") {
        conver = conversation;
        conver.messages = [];
      }

      if (conver && conver.messages && conver.messages.length > 0) {
        ChatRoomComponent.chatQuerySubscription = this._chatRoomService.getMessages(conversation.conversation.id, null).subscribe(messages => { });
        this.conversation_msg.data = conver.messages.slice(0).map(function (msg) {
          let m = JSON.parse(JSON.stringify(msg));
          m.show_options = false;
          m.show_options_menu = false;
          return m;
        });
        this.conversation_msg.next_token = conver.next_token;
        this.finishOpenChat(0, true);
      } else {
        this.conversation_msg.data = [];
        this.conversation_msg.next_token = null;

        ChatRoomComponent.chatQuerySubscription = this._chatRoomService.getMessages(conversation.conversation.id, null).subscribe(messages => {
          if (messages != null) {
            let th = this;
            messages.data.allMessageConnection.messages.slice(0).reverse().forEach(message => {
              var insert: boolean = true;

              let m = JSON.parse(JSON.stringify(message));

              m.deleted = false;
              if (m.hasOwnProperty('recipients')) {
                m.recipients.forEach(recipient => {
                  if (recipient.user_id == th._appService.user_data.id) {
                    if (recipient.state == 'DROPED') {
                      insert = false;
                    }
                    if (recipient.state == 'DELETED') {
                      m.deleted = true;
                    }
                  }
                });
              }

              m.type = message.hasOwnProperty('type') ? message.type : "TEXT";

              m.is_dummy = false;
              m.show_options = false;
              m.show_options_menu = false;
              switch (m.type) {
                case "IMAGE":
                  m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${m.id}/file1.${m.file.toLowerCase().split('.').pop()}`;
                  break;
                case "FILE":
                case "VIDEO":
                  m.file_size = Math.ceil(m.file_size / 1048576);
                  m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${m.id}/file1.${m.file.toLowerCase().split('.').pop()}`;

                  this._chatRoomService.getDataFile(m.src).subscribe((base: any) => {
                    m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));
                  });
                  break;
                case "AUDIO":
                  m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${m.id}/file1.wav`;

                  /*this._chatRoomService.getDataFile(m.src).subscribe((base : any) => {
                    m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));       
                  });*/
                  break;
              }

              if (insert) {
                //message.type = message.type ? message.type: 'TEXT';
                this.conversation_msg.data = this.conversation_msg.data.concat(m);
              }

            });
            this.conversation_msg.next_token = messages.data.allMessageConnection.nextToken;

            //saving cache
            this.saveApolloCache(conversation.conversation.id);

            this.finishOpenChat(0, true);
          }
        });
      }
      this.initConversationSubscription(conversation.conversation.id);
    }
    //}
  }

  initConversationSubscription(id) {
    const ws_headers = {
      "Authorization": AppService.idtoken,
      "host": this._appService.ws_host
    };
    this._chatRoomService.chatConversationsQuery.subscribeToMore({
      document: ChatRoomService.GET_MESSAGES_BY_CONVERSATION,
      variables: {
        conversation_id: id
      },
      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data.subscribeToNewMessage.user_id != this._appService.user_data.id) {
          let m = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewMessage));
          m.type = subscriptionData.data.subscribeToNewMessage.hasOwnProperty('type') ? subscriptionData.data.subscribeToNewMessage.type : "TEXT";

          m.is_dummy = false;
          m.show_options = false;
          m.show_options_menu = false;
          m.deleted = false;
          //TODO get recipents
          m.recipients = [];
          switch (m.type) {
            case "IMAGE":
              m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${subscriptionData.data.subscribeToNewMessage.id}/file1.${subscriptionData.data.subscribeToNewMessage.file.toLowerCase().split('.').pop()}`;
              break;
            case "FILE":
            case "VIDEO":
              m.file_size = Math.ceil(m.file_size / 1048576);
              m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${subscriptionData.data.subscribeToNewMessage.id}/file1.${subscriptionData.data.subscribeToNewMessage.file.toLowerCase().split('.').pop()}`;

              this._chatRoomService.getDataFile(m.src).subscribe((base: any) => {
                m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));
              });
              break;
            case "AUDIO":
              m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${subscriptionData.data.subscribeToNewMessage.id}/file1.wav`;

              break;
          }
          this.conversation_msg.data.push(m);
          this.scrollToBottom(0);
        }
        if (this._appService.conversations[this._appService.current_conversation_index] && this._appService.conversations[this._appService.current_conversation_index].conversation.id === subscriptionData.data.subscribeToNewMessage.conversation_id) {
          this.setUnreadConversation();
        }
      }
    });
    this._chatRoomService.chatConversationsQuery.subscribeToMore({
      document: ChatRoomService.GET_RECIPENTS_BY_CONVERSATION,
      variables: {
        conversation_id: id
      },
      extensions: {
        authorization: ws_headers
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data.subscribeToDeletedMessage.user_id == this._appService.user_data.id) {

          this.conversation_msg.data.forEach((element, index) => {
            if (element.id === subscriptionData.data.subscribeToDeletedMessage.message_id) {
              element.recipients.push(subscriptionData.data.subscribeToDeletedMessage);
              element.deleted = true;
              this.conversation_msg.data[index] = element;
            }
          });
          //saving cache
          this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);
        }
      }
    });

  }


  saveApolloCache(id): void {
    this._appService._apolloClient.getClient().writeFragment({
      id: 'Conversation:' + id,
      fragment: AppService.WRITE_CACHED_CONVERSATION,
      data: {
        messages: this.conversation_msg.data,
        next_token: this.conversation_msg.next_token
      },
    });
  }

  finishOpenChat(scrollPos, scroll): void {
    this.show_file_options = false;
    if (this.conversation_msg.next_token) {
      this.end_msg = false;
    } else {
      this.end_msg = true;
    }
    this.load_msg = false;
    this.load_chatroom = false;
    if (scroll)
      this.scrollToBottom(scrollPos);
    this.uploading_file = false;
    this.urlSoundObject = null;
    this.blobSoundObject = null;
    this.from_sound = false;
    this.refreshIntervalId = null;
    //this.refreshMilliSeconds = null;
    this.record_time = null;
    this.record = null;

    setTimeout(() => {
      if (this.newMessageChatRoom) this.newMessageChatRoom.nativeElement.focus();
    }, 1);
  }

  getPreviousMessages(): void {
    if (this.conversation_msg.next_token) {
      this.load_msg = true;
      this._chatRoomService.getMessages(this._appService.conversations[this._appService.current_conversation_index].conversation.id, this.conversation_msg.next_token).subscribe(messages => {
        if (messages) {
          let th = this;
          messages.data.allMessageConnection.messages.forEach(message => {
            var insert: boolean = true;

            let m = JSON.parse(JSON.stringify(message));

            m.deleted = false;
            if (m.hasOwnProperty('recipients')) {
              m.recipients.forEach(recipient => {
                if (recipient.user_id == th._appService.user_data.id) {
                  if (recipient.state == 'DROPED') {
                    insert = false;
                  }
                  if (recipient.state == 'DELETED') {
                    m.deleted = true;
                  }
                }
              });
            }

            m.type = message.hasOwnProperty('type') ? message.type : "TEXT";
            m.is_dummy = false;
            m.show_options = false;
            m.show_options_menu = false;
            switch (m.type) {
              case "IMAGE":
                m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${message.id}/file1.${message.file.toLowerCase().split('.').pop()}`;
                break;
              case "FILE":
              case "VIDEO":
                m.file_size = Math.ceil(m.file_size / 1048576);
                m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${message.id}/file1.${message.file.toLowerCase().split('.').pop()}`;

                this._chatRoomService.getDataFile(m.src).subscribe((base: any) => {
                  m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));
                });
                break;
              case "AUDIO":
                m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${message.id}/file1.wav`;

                /*this._chatRoomService.getDataFile(m.src).subscribe((base : any) => {
                  m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));       
                });*/
                break;
            }


            if (insert) {
              this.conversation_msg.data.unshift(m);
            }

          });
          this.conversation_msg.next_token = messages.data.allMessageConnection.nextToken;

          //saving cache
          this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);

          this.finishOpenChat(this.conversation_msg.data.length, true);

          this.searchingReply();
        } else {
          this.load_msg = false;
        }
      });
    } else {
      this.end_msg = true;
      this.load_msg = false;
    }
  }

  closeConversation() {
    //this.conversation = null;
    this._appService.current_conversation_index = -1;
    this._appService.chat_user = null;
    if (ChatRoomComponent.chatQuerySubscription) {
      ChatRoomComponent.chatQuerySubscription.unsubscribe();
    }

    this.conversation_msg = { data: [], next_token: String };
    this.end_msg = false;
  }

  closeChatRoom() {
    if (this.file.src) {
      this.file.src = null;
      this.load_chatroom = false;
      this.uploading_file = false;

      //TODO delete S3 file
    } else {
      this._appService.chatroom_state = false;
      this._appService.chat_user = null;

      this._appService.clear_created_conversations();

      this.closeConversation();

      this._router.navigate([], {
        queryParams: {
          conversation: null
        },
        queryParamsHandling: 'merge',
      });
    }

  }

  loadingSendMessage: boolean = false;
  from_sound: boolean = false;
  sendMessage() {
    this.show_file_options = false;

    if (this.recordingSound) {
      clearInterval(this.refreshIntervalId);

      this.stopRecording();
      return;
    }
    if (this.newMessage || this.file.src || this.from_sound) {

      this.loadingSendMessage = true;
      var now = new Date().toISOString();
      let variables: any;
      let cont: string = "";
      if (this.newMessage) {
        cont = this.newMessage.replace(/"/g, '\\"');
      }

      if (this.from_sound) {
        this.sendInputState('NONE');
        this.from_sound = false;
        this.file_type = "AUDIO";
        variables = {
          "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
          "created_at": now,
          "id": this.message_id,
          "user_id": this._appService.user_data.id,//user_is from token
          "content": cont,
          "state": "ACCEPTED", //TODO determine state blocking system
          "type": this.file_type,
          "file": 'file1.wav',
          "file_size": this.record_time,
          "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
        };
      } else {
        if (!this.file.src) {
          this.sendInputState('NONE');
          this.is_writing = false;
          this.file_type = "TEXT";
          this.message_id = this.generateUUID();
          variables = {
            "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
            "created_at": now,
            "id": this.message_id,
            "user_id": this._appService.user_data.id,//user_is from token
            "content": cont,
            "state": "ACCEPTED", //TODO determine state blocking system
            "type": this.file_type.toUpperCase(),
            "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
          };
        } else {

          variables = {
            "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
            "created_at": now,
            "id": this.message_id,
            "user_id": this._appService.user_data.id,//user_is from token
            "content": cont,
            "state": "ACCEPTED", //TODO determine state blocking system
            "type": this.file_type.toUpperCase(),
            "file": `${this.file.file_name.split('.').shift()}.${this.file.file_ext}`,
            "file_size": this.file.size,
            "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
          };
        }
      }

      let dummy_message = {
        is_dummy: true,
        user_id: this._appService.user_data.id,
        type: this.file_type,
        content: cont,
        src: '',
        file: '',
        file_size: '0',
        id: this.message_id,
        created_at: now
      }
      this.newMessage = '';
      this.scrollToBottom(0);

      if (this.message_parent) {
        variables['parent_id'] = this.message_parent.id;
        dummy_message['parent'] = this.message_parent;
        this.replyMessage(null);
      }

      if (this.file_type != 'AUDIO') {
        this.conversation_msg.data.push(dummy_message);
      }

      let rvars = {
        "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
        "created_at": now,
        "recipient_state": 'SENDED',
        "id": this.message_id,
        "user_id": this._appService.user_data.id
      }
      if (this._appService.conversations[this._appService.current_conversation_index].state == "CREATED") {
        this.createNewConversation(this._appService.conversations[this._appService.current_conversation_index]);
      }


      this._chatRoomService.sendRecipents(rvars, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(rcpt => {
        this._chatRoomService.sendMessage(variables, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(msg => {
          this.file.src = null;
          this._appService.conversations[this._appService.current_conversation_index].last_message = msg.data.cm.content;
          this._appService.conversations[this._appService.current_conversation_index].last_update = msg.data.cm.created_at;

          //msg.data.cm.recipients = [rcpt.data.cr, rcpt.data.cr2];

          let m = JSON.parse(JSON.stringify(msg.data.cm));
          m.type = msg.data.cm.hasOwnProperty('type') ? msg.data.cm.type : "TEXT";

          m.is_dummy = false;
          m.show_options = false;
          m.show_options_menu = false;
          m.deleted = false;
          switch (m.type) {
            case "IMAGE":
              m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${msg.data.cm.id}/file1.${msg.data.cm.file.toLowerCase().split('.').pop()}`;
              break;
            case "FILE":
            case "VIDEO":
              m.file_size = Math.ceil(m.file_size / 1048576);
              m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${msg.data.cm.id}/file1.${msg.data.cm.file.toLowerCase().split('.').pop()}`;

              this._chatRoomService.getDataFile(m.src).subscribe((base: any) => {
                m.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(base));
              });
              break;
            case "AUDIO":
              m.src = `https://video.edutin.com/Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${msg.data.cm.id}/file1.wav`;

              break;
          }

          this.conversation_msg.data = this.conversation_msg.data.map(function (men) {
            if (!men.id || men.id == m.id) {
              return m
            } else {
              return men;
            }
          });

          if (this.new_chat) {
            this.new_chat = false;
          }

          //saving cache
          this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);
          //this._appService.current_conversation_index = 0;
          this.loadingSendMessage = false;

          setTimeout(() => {
            this.scrollToBottom(0);
          }, 300);

          this.registerServedUser();

        }, (error) => {
          console.log('there was an error sending the mutation', error);
        });
      }, (error) => {
        console.log('there was an error sending the mutation', error);
      });


    } else {
      let mediaConstraints = {
        video: false,
        audio: true
      };


      navigator.mediaDevices.getUserMedia(mediaConstraints).then(
        this.successRecordingCallback.bind(this), this.errorRecordingCallback.bind(this)
      ).catch(function (err) {
        console.log(err)
      });

      //wave recorder
      /*var wavesurfer = WaveSurfer.create({
        container: '#ws-waveform',
        waveColor: 'white',
        progressColor: 'white',
        scrollParent: true,
      });
      var mic, recorder, soundFile;
      var isRecording = false;*/
    }
  }

  pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }
  refreshIntervalId;
  //refreshMilliSeconds;
  record_time;
  record;

  successRecordingCallback(stream) {
    var totalSeconds = 0;
    var th = this;
    this.record_time = 0;

    if (this._appService.conversations[this._appService.current_conversation_index].associated.length === 1) {
      this.sendInputState('grabando audio...');
    } else {
      this.sendInputState(this._appService.user_data.name.split(' ').slice(0, -1).join(' ').toLowerCase() + ' esta grabando un audio...');
    }

    this.refreshIntervalId = setInterval(function () {
      ++totalSeconds;
      th.record_time = totalSeconds;
      th.cronSeconds.nativeElement.innerHTML = th.pad(totalSeconds % 60);
      th.cronMinutes.nativeElement.innerHTML = th.pad(parseInt(String(totalSeconds / 60)));
    }, 1000);

    this.recordingSound = true;



    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 2,
      sampleRate: 46100,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }


  stopRecording() {
    this.recordingSound = false;
    this.loadingSendMessage = true;
    this.record.stop(this.processRecording.bind(this));
  }

  /**
  * processRecording Do what ever you want with blob
  * @param  {any} blob Blog
  */
  urlSoundObject;
  blobSoundObject;
  processRecording(blob) {
    this.blobSoundObject = blob;
    this.urlSoundObject = URL.createObjectURL(blob);

    this.message_id = this.generateUUID();

    let folderKey = `Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${this.message_id}/`;

    let fileKey = `${folderKey}file1.wav`;
    const params = {
      Bucket: 'edutin',
      Key: fileKey,
      Body: blob,
      ContentType: 'audio/wav'
    };
    let options = { partSize: 5 * 1024 * 1024, queueSize: 1 };

    let dummy_message = {
      is_dummy: true,
      user_id: this._appService.user_data.id,
      type: 'AUDIO',
      content: '',
      src: '',
      file: '',
      file_size: '0',
      created_at: new Date().toISOString()
    }
    this.newMessage = '';
    this.scrollToBottom(0);

    this.conversation_msg.data.push(dummy_message);

    // this._awsS3Service.getS3Bucket().upload(params, options, (err, data) => {
    //Esto es el nuevo metodo para subir los archivos al S3
    let url = `${window.btoa('edutin')}/${window.btoa(folderKey + 'file1.wav')}`;

    let send = {
      "bucket": 'edutin',
      "key": folderKey + 'file1.wav'
    }

    this._awsS3Service.generate_url(url, send, blob).subscribe((response: any) => {

      const { data, status } = response;

      if (status == false) {
        //this.file_error_emitter.emit(8);
        return false;
      }


      this.from_sound = true;
      this.sendMessage();

      setTimeout(() => {
        this.uploading_file = false;
      }, 250);


      setTimeout(() => {
        if (this.input_element && this.input_element.target && this.input_element.target.value) {
          this.input_element.target.value = "";
        }
        if (this.input_element && this.input_element.target && this.input_element.target.files) {
          this.input_element.target.files = null;
        }
      }, 1000);

      //this.changeDetector.emit('Guardar');

    });
    //   return true;
    // }).on('httpUploadProgress', (percentage) => {
    //   console.log('httpUploadProgress.', percentage);
    // });


    //console.log("blob", blob);
    //console.log("url", this.urlSoundObject);






    //let audio = new Audio();
    //audio.src = this.urlSoundObject;
    //audio.load();
    //audio.play();
  }

  errorRecordingCallback(error) {
    console.log(error);
  }

  createEmptyConversation() {
    var now = new Date().toISOString();
    let id = this.generateUUID();
    AppService.current_opened_conversation = id;
    let variables = {
      "id": id,
      "name": "uc-" + id,
      "created_at": now,
      "type": "CONVERSATION",
      "user_id": this._appService.user_data.id,//user_is from token
      "user_id2": this._appService.chat_user.data.associated[0].user.user_id,
      "content": " ",
      "state": "CREATED", //TODO determine state
      "state1": "DUMMY" //TODO determine state
    };
    this._chatRoomService.createEmptyConversation(variables).subscribe(msg => {
      msg.data.cc.associated.push({
        user: {
          user_fuente: this._appService.chat_user.data.associated[0].user.user_fuente,
          user_id: this._appService.chat_user.data.associated[0].user.user_id,
          user_image: this._appService.chat_user.data.associated[0].user.user_image,
          user_name: this._appService.chat_user.data.associated[0].user.user_name,
          user_role: this._appService.chat_user.data.associated[0].user.user_role
        }, state: "INVITED"
      });
      msg.data.cc.conversation = {
        id: msg.data.cc.conversation_id,
        image: null,
        name: "uc-" + msg.data.cc.conversation_id,
        type: "CONVERSATION"
      };

      //connected status
      const ws_headers = {
        "Authorization": AppService.idtoken,
        "host": this._appService.ws_host
      };
      let convv = JSON.parse(JSON.stringify(msg.data.cc));
      convv.associated[0].user.status = 'offline';
      convv.input_state = 'NONE';
      convv.unreaded_messages = 0;
      this._appService.statusQuery = this._appService._apolloClient.watchQuery({
        query: AppService.GET_STATUS,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          id: convv.associated[0].user.user_id
        }
      });

      this._appService.statusQuery.valueChanges.pipe(
        map((response: any) => {
          convv.associated[0].user.status = response.data.status.status;
          return response;
        })
      ).subscribe((data: any) => { });

      this._appService.statusQuery.subscribeToMore({
        document: AppService.STATUS,
        variables: {
          id: convv.associated[0].user.user_id
        },

        extensions: {
          authorization: ws_headers
        },
        updateQuery: (prev, { subscriptionData }) => {
          //evaluate to proccess a subscription if no message is sended
          //replacing old object
          convv.associated[0].user.status = subscriptionData.data.onStatus.status;
        }
      });
      //connected status


      this._appService.conversations.unshift(convv);


      //console.log("this._appService.conversations:::",this._appService.conversations);
      this.openConversation(convv);
    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });
  }

  createNewConversation(conv) {
    var now = new Date().toISOString();
    let id = conv.conversation.id;
    AppService.current_opened_conversation = id;
    let variables = {
      "id": id,
      "name": "uc-" + id,
      "created_at": now,
      "type": "CONVERSATION"
    };

    this._chatRoomService.createNewConversation(variables).subscribe(msg => {
      this._appService.conversations[this._appService.current_conversation_index].state = "ACCEPTED";
    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });
  }


  //neww
  setUnreadConversation() {
    let variables = {
      "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
      "unreaded_messages": 0
    };


    this._appService.setStateConversation(variables).subscribe(cnv => {
      //replacing old object
      let th = this;
      this._appService.conversations = this._appService.conversations.map(function (con) {
        if (con.conversation.id === cnv.data.su.conversation.id) {
          let convv = JSON.parse(JSON.stringify(cnv.data.su));

          convv.associated = con.associated;

          return convv;
        } else {
          return con;
        }
      });
    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });
  }


  array_move(arr, old_index, new_index): [] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }


  generateUUID(): string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  getFileDescription(ext: string): string { // Public Domain/MIT
    switch (ext) {
      case "doc":
      case "docx":
        return "Documento de Microsoft Word";
        break;
      case "ppt":
      case "pptx":
        return "Presentación de Power Point";
        break;
      case "xls":
      case "xlsx":
        return "Hoja de cálculo de Excel";
        break;
      case "pdf":
        return "Documento portable";
        break;
      case "txt":
        return "Documento de texto plano";
        break;
      case "zip":
      case "rar":
        return "Archivo comprimido";
        break;
      default:
        return "Archivo de formato desconocido";
    }
  }

  getFileName(name: string): string { // Public Domain/MIT
    if (name.split('.').shift().length > 20) {
      return name.split('.').shift().substring(0, 20) + '...' + name.split('.').pop();
    } else {
      return name;
    }
  }


  scrollToBottom(newlength) {
    if (this.conversation_msg.data.length > 0) {
      setTimeout(() => {
        let msg_: any = document.querySelectorAll(".message");
        let last_msg = (newlength > 0 ? msg_[msg_.length - this.scroll_elem] : msg_[msg_.length - 1]);
        if (last_msg) last_msg.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 250);
    }
  }

  updateConvesations() {
    // Traer las conversaciones
    //this._appService.getConversations(null).subscribe();
    // Mostrar los mensajes de una conversacion activa
    this.showMessages();
  }

  uploading_file: boolean = false;
  input_element;
  file: any = {
    description: '',
    id: '0',
    state: '1',
  };
  allowedVisualFileExtensions = /(\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.ppt|\.pptx|\.txt)$/i;
  allowedFileExtensions = /(\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.ppt|\.pptx|\.txt|\.rar|\.zip)$/i;
  allowedVideoExtensions = /(\.mp4|\.mov|\.3gp)$/i;
  addfile(event) {


    this.loadingSendMessage = true;
    this.file_type = event.target.id.substr(event.target.id.indexOf('_') + 1);

    this.file.progress = 0;
    this.input_element = event;

    //this.changeDetector.emit('Guardar');

    this.show_file_options = false;
    this.uploading_file = true;

    let fileList: FileList = event.target.files;//obtiene el file  del input html

    let fileInput = event.target;
    let filePath = event.target.value;

    var allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (this.file_type == 'image' && !allowedImageExtensions.exec(filePath)) {
      // alert('Please upload file having extensions .jpeg, .jpg, .png, .gif only.');
      //this.file_error_emitter.emit(44);
      this.uploading_file = false;
      fileInput.value = '';
      return false;
    } else if (this.file_type == 'file' && !this.allowedFileExtensions.exec(filePath)) {
      //('Please upload file with right extension.');
      //this.file_error_emitter.emit(43);
      //this.uploading_file = false;
      //fileInput.value = '';
      //return false;
    }
    else {
      //Image preview
      /*if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // document.getElementById('imagePreview').innerHTML = '<img src="' + e.target.result + '"/>';
        };
        reader.readAsDataURL(fileInput.files[0]);
      }*/
    }

    if (fileList.length > 0) {
      //this.file.size = Math.ceil(fileList[0].size/1048576);//obtiene el size del file
      this.file.size = fileList[0].size;
      this.file.file = fileList[0].name;
      if ((this.file.size <= 1048576 * 250)) {//valida que el size del file sea <= 250  

        this.file.file_type = fileList[0].type;//asigna el nombre file             
        this.file.file_ext = fileList[0].name.toLowerCase().split('.').pop();
        this.file.file_name = `${fileList[0].name.split('.').shift()}.${this.file.file_ext}`;

        this.message_id = this.generateUUID();

        let folderKey = `Conversations/${this._appService.conversations[this._appService.current_conversation_index].conversation.id}/Messages/${this.message_id}/`;

        let fileKey = `file1.${this.file.file_ext}`;

        //Esto es el nuevo metodo para subir los archivos al S3
        let url = `${window.btoa('edutin')}/${window.btoa(folderKey + fileKey)}`;

        let send = {
          "bucket": 'edutin',
          "key": folderKey + fileKey
        }
        this._awsS3Service.generate_url(url, send, fileList[0]).subscribe((response: any) => {

          const { data, status } = response;

          if (status == false) {
            //this.file_error_emitter.emit(8);
            return false;
          }

          this.file.file_key = data.key;
          this.file.src = null;
          this.file.progress = 100;

          // let tmp_file = JSON.parse(JSON.stringify(this.file));
          
          // tmp_file.file_key = data.Key;
          // tmp_file.src = null;
          
          // tmp_file.file_url = data.Location;
          // tmp_file.file_url = 'edutin/' + data.Key;
          // tmp_file.progress = 100;

          // this.file = JSON.parse(JSON.stringify(tmp_file));

          setTimeout(() => {
            this.uploading_file = false;
          }, 250);
          switch (this.file_type) {
            case 'image':
              this.file.src = URL.createObjectURL(event.target.files[0]);
              break;
            case 'file':
              this.file.src = "https://docs.google.com/gview?embedded=true&url=https://video.edutin.com/" + this.file.file_key;
              break;
            case 'video':
              this.file.src = "https://video.edutin.com/" + this.file.file_key;
              break;
          }
          this.loadingSendMessage = false;
          // let output;
          // output.onload = function () {
          //   URL.revokeObjectURL(output.src) // free memory
          // }

          setTimeout(() => {
            if (this.input_element && this.input_element.target && this.input_element.target.value) {
              this.input_element.target.value = "";
            }
            if (this.input_element && this.input_element.target && this.input_element.target.files) {
              this.input_element.target.files = null;
            }
          }, 1000);


        });
      } else {
        //TODO
        this.file.file_name = 'Su archivo supera los 250MB';//le asigna msj de error al  del input file, cuando supero los 250 megas
      }
    } else {
      this.file.file_name = "Adjuntar archivo";
    }
  }

  openfilePrevisual(src, text) {
    this.previsual_src = src;
    this.previsual_text = text;
    this.open_file_previsual = true;

    this._chatRoomService.getDataFile(src).subscribe((baseImage: any) => {
      this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(baseImage));
    });
  }

  closePrevisual() {
    this.previsual_src = null;
    this.previsual_text = null;
    this.open_file_previsual = false;
    this.scrollToBottom(0);
  }

  openTabPrevisual(previsual_src) {
    window.open(previsual_src, '_blank');
  }

  getFile(m: any): any { // Public Domain/MIT
    if (m.fileUrl) {
      return m.fileUrl;
    }
  }

  getFileSize(size): number { // Public Domain/MIT
    return Math.ceil(size / 1048576);
  }

  get_time_from_number(n): string {
    n = n / 1000;
    var minutes = Math.floor(n / 60);
    var seconds = Math.ceil(n - minutes * 60);
    //return `${minutes}:${seconds}`;
    return minutes + ":" + ("0" + (seconds)).slice(-2);
  }

  buttonCancelToggling() {
    if (this.recordingSound) {
      this.recordingSound = false;
      this.urlSoundObject = null;
      clearInterval(this.refreshIntervalId);
      this.record_time = null;
      this.record = null;

      this.cronSeconds.nativeElement.innerHTML = "00";
      this.cronMinutes.nativeElement.innerHTML = "00";
      this.sendInputState('NONE');
      return;
    } else {
      this.show_file_options = !this.show_file_options;
    }
  }

  prevent_uploadFile_event(event) {
    event.stopPropagation();
  }


  //recording voice
  recordingSound: boolean = false;


  loading_previus: boolean = false;
  getPreviusConversations(): void {
    this.loading_previus = true;
    if (this._appService.nextToken) {
      this.getPreviousConversations();
    } else {
      this.loading_previus = false;
      this._appService.conversation_end = true;
    }
  }

  getPreviousConversations(): void {
    if (this._appService.nextToken) {
      const ws_headers = {
        "Authorization": AppService.idtoken,
        "host": this._appService.ws_host
      };

      this._appService.getMoreConversations().subscribe((data: any) => {
        if (data.valid) {
          let th = this;
          data.print = JSON.parse(JSON.stringify(data.print));
          let temp_conversations = [];
          data.print.forEach(element => {
            if (this._appService.verifyAssociatedConversation(element)) {
              temp_conversations.push(element);
            }
          });
          temp_conversations.forEach(conv => {
            if (conv.unreaded_messages > 0) {
              th._appService.messageCounter += 1;
            }
            conv.input_state = "NONE";

            conv.associated.forEach(e => {
              e.user.status = 'offline';

              th._appService.statusQuery = this._appService._apolloClient.watchQuery({
                query: AppService.GET_STATUS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                  id: e.user.user_id
                }
              });

              th._appService.statusQuery.valueChanges.pipe(
                map((response: any) => {
                  e.user.status = response.data.status.status;
                  return response;
                })
              ).subscribe((data: any) => { });

              this._appService.statusQuery.subscribeToMore({
                document: AppService.STATUS,
                variables: {
                  id: e.user.user_id
                },

                extensions: {
                  authorization: ws_headers
                },
                updateQuery: (prev, { subscriptionData }) => {
                  //evaluate to proccess a subscription if no message is sended
                  //replacing old object
                  e.user.status = subscriptionData.data.onStatus.status;
                }
              });
            });

            th._appService.conversations.push(conv);
          });
          this._appService.nextToken = data.next;


          if (this._appService.nextToken) {
            this._appService.conversation_end = false;
          } else {
            this._appService.conversation_end = true;
          }

          /*TODO: open based in route
          this.conversationId = Number(this.route.snapshot.queryParamMap.get('conversation'));
          if (this.conversationId) {
              // Open chat
              this.openChatRoom(this.conversationId);
          }*/
        } else {
          this._appService.conversations = [];
        }

        this.loading_previus = false;
        let th = this;
        this._appService.conversations.forEach(e => {

        });
      });
    } else {
      //this.end_msg = true;
      //this.load_msg = false;
    }
  }

  modal_dialog_ = {
    state: false,
    type: null
  };


  timeout: any = null;
  is_writing: boolean = false;


  sendInputState(input_state) {
    if (this._appService.conversations[this._appService.current_conversation_index].state != "CREATED" || this._appService.conversations[this._appService.current_conversation_index].associated.length > 1) {
      let rvars;
      rvars = {
        "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
        "input_state": input_state
      }

      if (this._appService.conversations[this._appService.current_conversation_index].associated.length === 1) {
        if (this._appService.conversations[this._appService.current_conversation_index].associated[0].state == "ACCEPTED") {
          this._chatRoomService.sendInputState(rvars, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(response => {
          }, (error) => {
            console.log('there was an error sending the mutation', error);
          });
        }
      } else {
        this._chatRoomService.sendInputState(rvars, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(response => {
        }, (error) => {
          console.log('there was an error sending the mutation', error);
        });
      }
    }
  }

  onKeyInput(event: any) {
    if (event.keyCode != 13) {
      if (!this.is_writing) {
        //send starting mutation
        if (this._appService.conversations[this._appService.current_conversation_index].associated.length === 1) {
          this.sendInputState('escribiendo...');
        } else {
          this.sendInputState(this._appService.user_data.name.split(' ').slice(0, -1).join(' ').toLowerCase() + ' esta escribiendo...');
        }
        this.is_writing = true;
      }
      clearTimeout(this.timeout);
    }
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        //send stop mutation
        $this.sendInputState('NONE');
        $this.is_writing = false;
      }
    }, 1000);
  }



  getAvatarImage(conv: any): string {
    if (conv.associated.length === 1) {
      if (conv.associated[0].user.user_image == '') {
        if (conv.associated[0].user.user_role == 'propietario') {
          return this.urlavatar + 'professor/small_imagen.jpg';
        } else {
          return this.urlavatar + 'default/small_imagen.jpg';
        }
      } else {
        return this.urlavatar + conv.associated[0].user.user_id + '/small_' + conv.associated[0].user.user_image;
      }
    } else {
      if (!conv.conversation.image || conv.conversation.image == '') {
        return this.urlavatar + 'default/small_imagen.jpg';
      } else {
        return this.urlavatar1 + conv.conversation.id + '/Image/' + conv.conversation.image;
      }
    }
  }

  getAvatarErrorImage(conv: any): string {
    if (conv.associated.length === 1) {
      if (conv.associated[0].user.user_role == 'propietario') {
        return this.urlavatar + 'professor/small_imagen.jpg';
      } else {
        return this.urlavatar + 'default/small_imagen.jpg';
      }
    } else {
      return this.urlavatar + 'default/small_imagen.jpg';
    }
  }

  getUserMessageFrom(usr: string): any {
    let name = '';

    let find = this._appService.conversations[this._appService.current_conversation_index].associated.find(user => user.user.user_id == usr);
    if (find) name = find.user.user_name;

    return name;
  }

  filtered_conversations: Array<any> | any = null;
  tmp_filtered_conversations: Array<any> | any = null;
  openAddUser(event) {
    var th = this;
    this.filtered_conversations = this._appService.conversations.filter(function (con) {

      if (con.associated.length == 1) {
        var put = true;

        th._appService.conversations[th._appService.current_conversation_index].associated.forEach(c => {
          if (c.user.user_id == con.associated[0].user.user_id) {
            put = false;
          }
        });
        if (put) {
          return con;
        }
      }
    });

    this.tmp_filtered_conversations = this.filtered_conversations;
    this.show_add_user = true;

    if (this.show_user_list == true) this.show_user_list = false;

    event.stopPropagation();
  }

  show_user_list = false;
  filtered_user_list: Array<any> | any = null;
  openUserList(event) {
    this.filtered_user_list = this._appService.conversations[this._appService.current_conversation_index].associated;
    this.show_user_list = true;
    if (this.show_add_user == true) this.show_add_user = false;
    if (event) event.stopPropagation();
  }


  addUser(conv) {

    if (conv.associated[0].user.user_role === 'assistant' && this._appService.user_data.role !== 'assistant' && this._appService.user_data.role !== 'moderador') {
      return;
    }

    this.modal_dialog_.state = true;
    var th = this;
    var now = new Date().toISOString();
    this.message_id = this.generateUUID();

    setTimeout(() => {
      th.modal_dialog.modal_type = 53;

      let __response = th.modal_dialog.response.subscribe(response => {
        if (response == 1 || response == 2) {
          this.loadingSendMessage = true;
          var variables = {
            "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
            "created_at": now,
            "id": this.message_id,
            "user_id": this._appService.user_data.id,
            "content": this._appService.user_data.name + " ha invitado a " + conv.associated[0].user.user_name + " a esta conversación",
            "state": "ACCEPTED",
            "user_idx": conv.associated[0].user.user_id,
            "contentx": this._appService.user_data.name + " te ha invitado a esta conversación",
            "statex": "INVITED",
            "type": "SYSTEM",
            "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
          };
          var variablesEx = {
            "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
            "created_at": now,
            "user_id": conv.associated[0].user.user_id,
            "content": this._appService.user_data.name + " te ha invitado a esta conversación",
            "state": "INVITED",
            "user_role": response == 1 ? "ADMINISTRATOR" : "USER"
          };

          this._chatRoomService.sendUserConversationEx(variablesEx).subscribe(msg => {
            this._chatRoomService.sendUserConversation(variables, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(msg => {
              this._appService.conversations[this._appService.current_conversation_index].last_message = msg.data.cm.content;
              this._appService.conversations[this._appService.current_conversation_index].last_update = msg.data.cm.created_at;

              //msg.data.cm.recipients = [rcpt.data.cr, rcpt.data.cr2];
              let m = JSON.parse(JSON.stringify(msg.data.cm));

              m.is_dummy = false;
              m.show_options = false;
              m.show_options_menu = false;
              m.deleted = false;

              this.conversation_msg.data.push(m);

              /*this.conversation_msg.data = this.conversation_msg.data.map(function (men) {
                if (!men.id || men.id == m.id) {
                    return m
                } else {
                    return men;
                }
              });*/

              //saving cache
              this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);

              this._appService.current_conversation_index = 0;
              this.loadingSendMessage = false;
              this.modal_dialog_.state = false;
              this.show_add_user = false;
              setTimeout(() => {
                this.scrollToBottom(0);
              }, 300);

            }, (error) => {
              console.log('there was an error sending the mutation', error);
            });
          }, (error) => {
            console.log('there was an error sending the mutation', error);
          });
        }
        this.modal_dialog_.state = false;
        __response.unsubscribe();
      });
    }, 100);
  }



  acceptUserConversation() {
    var now = new Date().toISOString();
    this.message_id = this.generateUUID();
    this.loadingSendMessage = true;
    var variables = {
      "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
      "created_at": now,
      "id": this.message_id,
      "user_id": this._appService.user_data.id,
      "content": this._appService.user_data.name + " ha aceptado participar en esta conversación",
      "state": "ACCEPTED",
      "type": "SYSTEM",
      "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
    };


    this._chatRoomService.sendMessage(variables, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(msg => {
      this._appService.conversations[this._appService.current_conversation_index] = msg.data.cc;
      //this.conversation = null;
      this._appService.current_conversation_index = -1;
      this.openConversation(msg.data.cc);

      let m = JSON.parse(JSON.stringify(msg.data.cm));

      m.is_dummy = false;
      m.show_options = false;
      m.show_options_menu = false;
      m.deleted = false;
      this.conversation_msg.data.push(m);
      this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);



      //this.conversation.state == "ACCEPTED";
      this._appService.current_conversation_index = 0;
      this.loadingSendMessage = false;
      setTimeout(() => {
        this.scrollToBottom(0);
      }, 300);

    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });
  }

  declineUserConversation() {
    var now = new Date().toISOString();
    this.message_id = this.generateUUID();
    this.loadingSendMessage = true;
    var variables = {
      "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
      "created_at": now,
      "id": this.message_id,
      "user_id": this._appService.user_data.id,
      "content": this._appService.user_data.name + " no ha aceptado participar en esta conversación",
      "state": "DELETED",
      "type": "SYSTEM",
      "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
    };


    this._chatRoomService.sendMessage(variables, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(msg => {
      this._appService.conversations.splice(this._appService.current_conversation_index, 1);
      this.openConversation(this._appService.conversations[0]);
      //this.conversation.state == "ACCEPTED";
      this.loadingSendMessage = false;
      setTimeout(() => {
        this.scrollToBottom(0);
      }, 300);

      if (this.input_state != 'NONE') {
        this.closeThisLiveChat.emit();
      }

    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });
  }

  getGroupalConversationName(conv): string {

    let name = '';

    if (conv && conv.associated.length > 0) {
      if (conv.associated.length === 2) {
        name = conv.associated[0].user.user_name.split(' ')[0] + " y " + conv.associated[1].user.user_name.split(' ')[0];
      } else {
        name = conv.associated[0].user.user_name.split(' ')[0] + ", " + conv.associated[1].user.user_name.split(' ')[0] + " y " + (conv.associated.length - 2).toString() + " más";
      }
    }

    return name;
  }




  cleanSearch() {
    this.searchtext_ = '';
  }



  clearGroupName() {

    this.group_name = '';
    this.textGroupName.nativeElement.innerHTML = '';
  }


  savingGroupName: boolean = false;
  @ViewChild('TextGroupName') textGroupName: ElementRef;

  saveGroupName() {
    if (this.textGroupName.nativeElement.textContent) {
      this.savingGroupName = true;
      var now = new Date().toISOString();
      this.message_id = this.generateUUID();
      this.loadingSendMessage = true;
      var variables = {
        "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
        "created_at": now,
        "id": this.message_id,
        "user_id": this._appService.user_data.id,
        "content": this._appService.user_data.name + " ha cambiado el asunto de este grupo a " + this.textGroupName.nativeElement.textContent,
        "state": "ACCEPTED",
        "type": "SYSTEM",
        "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
      };
      var variablesEx = {
        "id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
        "name": this.textGroupName.nativeElement.textContent,
      };

      this._chatRoomService.changeConversationName(variablesEx).subscribe(msg => {
        this._chatRoomService.sendMessage(variables, this._appService.conversations[this._appService.current_conversation_index].associated).subscribe(msg => {
          this._appService.conversations[this._appService.current_conversation_index] = msg.data.cc;
          //this.conversation = null;
          this._appService.current_conversation_index = -1;
          this.openConversation(msg.data.cc);

          let m = JSON.parse(JSON.stringify(msg.data.cm));

          m.is_dummy = false;
          m.show_options = false;
          m.show_options_menu = false;
          m.deleted = false;
          this.conversation_msg.data.push(m);
          this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);


          this.savingGroupName = false;
          //this.conversation.state == "ACCEPTED";
          this.show_user_list = false;
          this.loadingSendMessage = false;
          this._appService.current_conversation_index = 0;
          setTimeout(() => {
            this.scrollToBottom(0);
          }, 300);

        }, (error) => {
          console.log('there was an error sending the mutation', error);
        });
      }, (error) => {
        console.log('there was an error sending the mutation', error);
      });
    }
  }

  search_user() {
    var th = this;
    if (this.searchtext_ == "") {
      console.log('true');
      setTimeout(() => {
        th.filtered_conversations = th.tmp_filtered_conversations;
      }, 500);

    } else {
      console.log('false');
      setTimeout(() => {
        th._appService.search_user(th.searchtext_).subscribe((data: any) => {

          th.filtered_conversations = [];
          th.filtered_conversations = data.data.filter(function (usr) {
            var r = true;
            th._appService.conversations[th._appService.current_conversation_index].associated.forEach(u => {
              if (u.user.user_id == usr.id) {
                r = false;
              }
            });

            if (r && th._appService.user_data.id != usr.id) {
              return usr;
            }
          }).map(function (usr) {
            return {
              associated: [{
                user: {
                  status: 'offline',
                  user_name: usr.fields.name[0] ? usr.fields.name[0] : '',
                  user_fuente: usr.fields.fuente[0] ? usr.fields.fuente[0] : 'form',
                  user_image: usr.fields.image[0] ? usr.fields.image[0] : '',
                  user_id: usr.id
                }
              }],
            };
          });
          this.filtered_conversations.forEach(u => {
            this._appService.statusQuery = this._appService._apolloClient.watchQuery({
              query: AppService.GET_STATUS,
              errorPolicy: 'all',
              fetchPolicy: 'network-only',
              variables: {
                id: u.associated[0].user.user_id
              }
            });

            this._appService.statusQuery.valueChanges.pipe(
              map((response: any) => {
                u.associated[0].user.status = response.data.status.status;
                return response;
              })
            ).subscribe((data: any) => { });
          });
        });
      }, 500);
    }
  }




  openUserProfile() {
    let url = environment.appDomain + '/user/profile/' + this._appService.conversations[this._appService.current_conversation_index].associated[0].user.user_id;
    window.open(url, "_blank");
  }



  user_options: any;

  openParticipantsOptions(event: MouseEvent, user: any) {

    this.user_options = user.user.user_id;
    console.log('openParticipantsOptions: ', this.user_options);

    event.stopPropagation();
  }


  dismissAsAdmin(event: MouseEvent, user: any) {
    this.changeUserRole('USER', user);
    event.stopPropagation();
  }

  changeUserRole(role: String, user: any) {
    var now = new Date().toISOString();
    this.message_id = this.generateUUID();
    this.loadingSendMessage = true;
    var variables = {
      "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
      "created_at": now,
      "id": this.message_id,
      "user_id": this._appService.user_data.id,
      "content": this._appService.user_data.name + " ha " + (role == 'ADMINISTRATOR' ? "colocado" : "quitado") + " a " + user.user.user_name + " como administrador de esta conversación",
      "state": "ACCEPTED",
      "type": "SYSTEM",
      "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
    };
    var foundIndex = this._appService.conversations[this._appService.current_conversation_index].associated.findIndex(x => x.user.user_id == user.user.user_id);
    if (foundIndex > -1) {
      this._appService.conversations[this._appService.current_conversation_index].associated[foundIndex].user_role = role;
    }

    foundIndex = this.filtered_user_list.findIndex(x => x.user.user_id == user.user.user_id);
    if (foundIndex > -1) {
      this.filtered_user_list[foundIndex].user_role = role;
    }

    var variablesEx = {
      "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
      "created_at": now,
      "user_id": user.user.user_id,
      "content": this._appService.user_data.name + " te ha " + (role == 'ADMINISTRATOR' ? "colocado" : "quitado") + " como administrador de esta conversación",
      "state": user.state,
      "user_role": role
    };

    this._chatRoomService.sendUserConversationEx(variablesEx).subscribe(msg => {
      this._chatRoomService.sendMessage(variables, this._appService.conversations[this._appService.current_conversation_index].associated, false).subscribe(msg => {
        this._appService.conversations[this._appService.current_conversation_index].last_message = msg.data.cm.content;
        this._appService.conversations[this._appService.current_conversation_index].last_update = msg.data.cm.created_at;

        //msg.data.cm.recipients = [rcpt.data.cr, rcpt.data.cr2];
        let m = JSON.parse(JSON.stringify(msg.data.cm));

        m.is_dummy = false;
        m.show_options = false;
        m.show_options_menu = false;
        m.deleted = false;

        this.conversation_msg.data.push(m);
        this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);
        this.loadingSendMessage = false;
        this.user_options = null;
        setTimeout(() => {
          this.scrollToBottom(0);
        }, 300);
      }, (error) => {
        console.log('there was an error sending the mutation', error);
      });
    }, (error) => {
      console.log('there was an error sending the mutation', error);
    });



    event.stopPropagation();
  }


  setAsAdmin(event: MouseEvent, user: any) {
    this.changeUserRole('ADMINISTRATOR', user);
    event.stopPropagation();
  }


  deleteParticipantFromGroup(event: MouseEvent, user: any) {
    var th = this;
    if (this._appService.conversations[this._appService.current_conversation_index].associated.length > 1) {
      this.modal_dialog_.state = true;

      var now = new Date().toISOString();
      this.message_id = this.generateUUID();

      setTimeout(() => {
        th.modal_dialog.modal_type = 55;

        let __response = th.modal_dialog.response.subscribe(response => {
          if (response == 1) {
            this.message_id = this.generateUUID();
            this.loadingSendMessage = true;
            var variables = {
              "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
              "created_at": now,
              "id": this.message_id,
              "user_id": this._appService.user_data.id,
              "content": this._appService.user_data.name + " ha eliminado a " + user.user.user_name + " de esta conversación",
              "state": "ACCEPTED",
              "type": "SYSTEM",
              "user_role": this._appService.conversations[this._appService.current_conversation_index].user_role
            };
            console.log(':::deleteParticipantFromGroup::: ', user);
            var foundIndex = this._appService.conversations[this._appService.current_conversation_index].associated.findIndex(x => x.user.user_id == user.user.user_id);

            this._appService.conversations[this._appService.current_conversation_index].associated.splice(foundIndex, 1);

            var variablesEx = {
              "conversation_id": this._appService.conversations[this._appService.current_conversation_index].conversation.id,
              "created_at": now,
              "user_id": user.user.user_id,
              "content": this._appService.user_data.name + " te ha eliminado de esta conversación",
              "state": "DELETED",
              "user_role": user.user_role
            };

            this._chatRoomService.sendUserConversationEx(variablesEx).subscribe(msg => {
              this._chatRoomService.sendMessage(variables, this._appService.conversations[this._appService.current_conversation_index].associated, false).subscribe(msg => {
                this._appService.conversations[this._appService.current_conversation_index].last_message = msg.data.cm.content;
                this._appService.conversations[this._appService.current_conversation_index].last_update = msg.data.cm.created_at;

                //msg.data.cm.recipients = [rcpt.data.cr, rcpt.data.cr2];
                let m = JSON.parse(JSON.stringify(msg.data.cm));

                m.is_dummy = false;
                m.show_options = false;
                m.show_options_menu = false;
                m.deleted = false;

                this.conversation_msg.data.push(m);
                this.saveApolloCache(this._appService.conversations[this._appService.current_conversation_index].conversation.id);
                this.loadingSendMessage = false;
                setTimeout(() => {
                  this.scrollToBottom(0);
                }, 300);

              }, (error) => {
                console.log('there was an error sending the mutation', error);
              });
            }, (error) => {
              console.log('there was an error sending the mutation', error);
            });
          }
          this.modal_dialog_.state = false;
          __response.unsubscribe();
        });


      }, 100);
    } else {
      th.modal_dialog_.state = true;
      setTimeout(() => {
        th.modal_dialog.modal_type = 56;

        let __response = th.modal_dialog.response.subscribe(response => {
          this.modal_dialog_.state = false;
          __response.unsubscribe();
        });


      }, 100);
    }

    event.stopPropagation();
  }




  search_user_to_open_chat: boolean = false;
  search_user_to_open_chat_text = '';

  searchToOpenChat() {
    console.log('searchToOpenChat');
  }

  cleanSearchToOpenChat() {
    this.search_user_to_open_chat_text = '';
  }




  openAndCloseMenuMessage(msg_id: number): void {

    if (msg_id == this.menu_msg_id) {
      this.menu_msg_id = null;
      return;
    }

    this.menu_msg_id = msg_id;
  }




  replyMessage(message: any): void {
    this.message_parent = message;
  }



  goToReplyMsg(message: any, lastSearch?: any): void {

    // lastSearch se envía cuando se ejecuta la funcion desde  getMoreMessages() y vendrá con valor true cuando el contador sea = 4 (la ultima iteración para buscar en el chat)
    this.display_chat_notification = false;
    this.msgReply = message;
    const { parent_id } = message;
    let msg__history: any = document.querySelector(".direct-msg__history__messages__char-room");
    let msg_: any = document.getElementById(parent_id);
    let found = !false;

    if (msg_) {
      let msg = msg_.getElementsByClassName('msg__deleted');
      found = !(msg.length > 0);
    }

    if (msg__history) {
      if (msg_ && found) {
        //Si se encuentra mensaje en el historial se resalta y se mueve el scroll
        this.searchReplyMsg = null;
        this.msgReply = null;
        msg__history.scrollTop = msg_.offsetTop - ((msg__history.clientHeight / 2) - 40);
      } else {
        //Si no se encuentra el mensaje en el historial que ya está cargado (el historial inicial), 
        //se mueve el scroll al top para que se siga buscando por 5 veces (al mover el scroll al top, automaticamente se cargan más mensajes anteriores)
        msg__history.scrollTop = 0;
        if (this.searchReplyMsg == null) {
          //Cuando es el historial inicial, se inicializa en 0
          this.searchReplyMsg = 0;
        } else {
          //A medida que va cargando se va aumentando una
        }
      }
    }

    setTimeout(() => {
      if (msg_ && found) {
        msg_.classList.add('active-reply-found');
      } else {
        if (lastSearch != null && lastSearch === true || !this.conversation_msg.next_token) {
          //Si es la 5 vez que búsca y no se encuentra, se muestra la notificación
          this.display_chat_notification = true;
          setTimeout(() => {
            this.display_chat_notification = false;
          }, 7000);
        }
      }

      setTimeout(() => {
        if (msg_ && found) {
          msg_.classList.remove('active-reply-found');
        }
      }, 3000);
    }, 400);
    // if (msg_) msg_.scrollIntoView({ behavior: "smooth", block: "start" });
  }




  searchingReply(): void {
    
    let msg_: any = document.querySelectorAll(".message");

    if (msg_) {
      msg_[0].scrollIntoView({ behavior: "smooth", block: "start" });
      //Se mueve scroll del mensaje en la posición 0 al top  para que cuando se vuelva a mover el scroll al top del historial se cargen más mensajes
    }

    setTimeout(() => {
      if (this.searchReplyMsg != null) {
        if (this.searchReplyMsg < 5) {
          //Se hará 5 intentos de buscar el mensaje en el historial
          setTimeout(() => {
            this.goToReplyMsg(this.msgReply, this.searchReplyMsg == 4);
            this.searchReplyMsg++;
          }, 50);
        } else {
          //Si ya se acabó el numero de intentos, se anula la búsqueda
          this.searchReplyMsg = null;
          this.msgReply = null;
        }
      }
    }, 250);
  }




  /*
  * Register chat with user if it is a new conversation.
  */
  registerChatUser = (): any => {
    return new Promise((resolve, reject) => {
      
      let current_conversation = this._appService.conversations[this._appService.current_conversation_index];

      if (this.conversation_msg.data.length) {
        resolve(true);
        return;
      }

      let user_id1 = Number(current_conversation.associated[0].user.user_id);
      let conversation_id = current_conversation.conversation.id;
      let data = { user_id1: user_id1, conversation_id: conversation_id };
  
      this._appService.registerChatUser(data).subscribe(data => resolve(data.status), error => reject(false));
    });
  };




  /*
  * If not register chat user then send message action is aborted.
  */
  actionSend(): void {
    this.registerChatUser().then((resolved: boolean) => {
      if (resolved !== true) {
        this.newMessage = '';
        this.file.src = null;
        this.loadingSendMessage = this.show_file_options = false;
        return;
      }
      this.sendMessage();
    });
  }




  /*
  * Register users served by assistant.
  * Not register if the user was served in the same hour.
  */
  registerServedUser(): void {

    if (this._appService.user_data.role !== 'assistant') {
      return;
    }

    if (!this.conversation_msg.data.length) {
      return;
    }
    
    let current_conversation = this._appService.conversations[this._appService.current_conversation_index];
    let len = this.conversation_msg.data.length;
    let created_at = this.conversation_msg.data[len - 1].created_at;
    let user_id1 = Number(current_conversation.associated[0].user.user_id);

    if (this.is_registered(this.getHourDateFromISO(created_at), len)) {
      return;
    }
    
    let served = {
      user_id1: user_id1,
      date: created_at
    };

    this._appService.registerServedUser(served).subscribe();
  }




  /*
  * Check if the user was registered as a served user in the last hour.
  */
  is_registered(dateObject: Date, len: number): boolean {

    if (len === 1) {
      return false;
    }

    for (let index = (len - 2); index >= 0; index--) {
      if (Number(this.conversation_msg.data[index].user_id) === Number(this._appService.user_data.id)) {
        return dateObject.getTime() == this.getHourDateFromISO(this.conversation_msg.data[index].created_at).getTime();
      }
    }

    return false;
  }




  /*
  * Get object date without minutes and seconds from iso string.
  */
  getHourDateFromISO(dateString: string): Date {
    let created_at_date = new Date(dateString);
    let hourDate = new Date(created_at_date.getFullYear(), created_at_date.getMonth(), created_at_date.getDate(), created_at_date.getHours());
    return hourDate;
  }
}


