import { environment } from "src/environments/environment";
export const UrlPhpApi = environment.domain;
export const UrlLambdaApi = environment.urlAPI;


export interface GlobalData {

  actions: boolean, // si el usuario está logeado ? true : false;
  certification: { Certification: Certification, User: User }, //data del certificado y el usuario
  course: Course,
  initialSource: any,
  // learn: Learn | Lesson,
  initialLesson: any,
  lesson: any,
  modules: any,
  position: { module: any, lesson: any, isLastOne: boolean },
  source: any,
  uploads: any,
  url_route: string,
  file: any,
  width_size: number,
  height_size: number,
  img_viewer_src: any,
}

export interface Certification {
  alerta: string,
  calificacion: any,
  calification: any,
  cierre: string | number,
  clase_id: string | number,
  count_revisions: string | number,
  curso_id: string | number,
  duracion: any,
  duracion_min: string | number,
  estado: string | number,
  fecha: string,
  fecha_inscripcion: string,
  id: string | number,
  language_id: string | number,
  payment_id: string | number,
  question_success: any,
  question_total: any,
  rating: string | number,
  tipo: string | number,
  tour: any,
  progress: any,
  user_id: string | number,
}

interface User {
  active: string | number,
  className: string,
  email: string,
  id: string | number,
  image: string,
  language_id: string | number,
  name: string,
  role: string,
  tour: string,
  username: string
}

interface Course {
  duracion: string | number,
  estado: string | number,
  id: string | number,
  nombre: string,
  name?: string,
  nombre_certificado: string,
  tipo: string | number,
  user_id: string | number,
  slug: string
}

export interface Learn {
  Clase: {
    course_id: string | number,
    descripcion: string,
    id: string | number,
    module_id: string | number,
    order: string | number,
    titulo: string
  },
  Curso: Course,
  Recent: [{
    certification_id: string | number,
    evaluation: string | number,
    id: string | number,
    lesson_id: string | number,
    position: string | number,
    user_id: string | number,
  }],
  Source: any[]

}

interface Recent {
  course_id: string | number
  evaluation: string | number
  fecha: string,
  id: string | number,
  lesson_id: string | number,
  position: string | number,
  user_id: string | number
}

interface Lesson {
  Clase: {
    course_id: string | number,
    descripcion: string,
    id: string | number,
    module_id: string | number,
    order: string | number,
    titulo: string
  },
  Quiz: any[],
  Recent: any[],
  Source: any[],
  course_id: string | number,
  descripcion: string,
  duration: string | number,
  durationInText: string,
  i_lesson: number,
  i_module: number,
  id: string | number,
  module_id: string | number,
  module_name: string,
  order: string | number,
  progress: string | number,
  progress_percentaje: number,
  slug: string,
  titulo: string
}



export interface Upload {
  source_info: Sourceinfo;
  user_id: string;
  class_info: Classinfo;
  date_time: string;
  course_class_source_id_date: string;
  name: string;
  state: string;
  type: string;
  files: File[];
  position?: number;
}

interface File {
  description?: string;
  state: string;
  type: string;
  date_creation: string;
  language?: string;
  file_url?: string;
  file_bucket?: string;
  file_ext?: string;
  file_name?: string;
  link?: string;
}

interface Classinfo {
  name: string;
  id: string;
}

interface Sourceinfo {
  name: string;
  is_video: string;
  id: string;
  type: string;
}






export interface Question {
  id: number;
  tipo: any;
  name: string;
  descripcion: string;
  feedback: string;
  tipo_respuesta: string;
  quiz_id: number;
  Answers: QuestionAnswer[];
  FilesQuestions: any[];
  Records?: any;
  index?: number;
  feedback_counter?: number;
  Evaluation?:string;
}

interface QuestionAnswer {
  id: number;
  file_name: string;
  file_url: string;
  file_ext: string;
  tipo: number | string;
  descripcion: string;
  question_id: number;
}

export interface Quiz {
  id: number;
  name: string;
  weight: string;
  type: number;
  description: string;
  file_name: string;
  is_evaluation: number;
  file_url?: any;
  file_ext: string;
  file_type: string;
  file_buckets?: any;
  clase_id: number;
}