import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild, AfterContentInit } from '@angular/core';
import { CommunityService } from '../services/community.service';
import { ClassroomService } from '../services/classroom.service';
import { SearchBarService } from '../sidebar/components/search-bar/search-bar.service';
import { input } from 'aws-amplify';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements AfterContentInit {




  console = console;
  @Input() current;
  @Input() config_comments;
  @Input() component;
  @Input() from_sidebar;
  @Input() status;

  @ViewChild('communityUsers') communityUsers_;
  @ViewChild('communityUsersNow') communityUsersNow;
  @ViewChild('communityComments') communityComments_;
  @ViewChild('communityCommentsSidebar') communityCommentsSidebar_;


  @Output() goToSource: EventEmitter<any> = new EventEmitter<any>();
  @Output() events_: EventEmitter<any> = new EventEmitter<any>();

  /* Assist app variables */

  is_assist: boolean;
  @Input() actions_assist;

  /* Assist app end */

  constructor(
    public _communityService: CommunityService,
    public _classroomService: ClassroomService,
    public _searchBarService: SearchBarService,
    private _router: Router,
  ) {
    this.is_assist = this.isAssist();
  }
  display_searchbar = false;

  ngAfterContentInit() {

    if (this.component != null) {

      this._communityService.config[this.component] = true;
    }

    this.display_searchbar = true;

    if (this.from_sidebar) {
      setTimeout(() => {
        this.sidebar_component = 'users';
      }, 10);
      // this.getParticipantsPages();
    }

    if (this.actions_assist) {
      this._classroomService.data.actions = this.actions_assist;
    } 
  }


  ngOnDestroy() {
    
  }


  search_show: boolean = false;
  search_text = '';
  searchComment() { }


  updateJson(ev) {
    this.communityComments_.updateJson(ev);
  }


  sidebar_component;

  selectComponent(component) {
    this.sidebar_component = component;
  }

  sidebar_filter = {
    users: 0,
    comments: 1,
    forums: 0
  }

  selectFilter(filter) {
    this.sidebar_filter[this.sidebar_component] = filter;
    switch (filter) {
      case 0:
        if (this._communityService.participants.recent && !this._communityService.recentStatusLoaded) {
          if (this._communityService._appService._apolloClient) {
            this._communityService.loadUsersStatus(this._communityService.participants.recent, 'recent');
          } else {
            this._communityService._appService.lazyEventStream.subscribe(r => {
              if (r) {
                this._communityService.loadUsersStatus(this._communityService.participants.recent, 'recent');
              }
            });
          }
          this._communityService.recentStatusLoaded = true;
        }
        break;
      case 2:
        if (this._communityService.participants.followers && !this._communityService.connectionStatusLoaded) {
          if (this._communityService._appService._apolloClient) {
            this._communityService.loadUsersStatus(this._communityService.participants.followers, 'follower');
          } else {
            this._communityService._appService.lazyEventStream.subscribe(r => {
              if (r) {
                this._communityService.loadUsersStatus(this._communityService.participants.followers, 'follower');
              }
            });
          }

          this._communityService.connectionStatusLoaded = true;
        }

    }

  }

  info_card = {
    home: ``,
    users: ``,
    comments: `  
      <div class="card">   
        <div>
          <span>Reconocimiento por la mejor respuesta</span>
          <i class="icon-rate"></i>
        </div>
        <div>
          Puedes ayudar a otros estudiantes de la comunidad respondiendo las preguntas que han formulado. Cuando tu
          respuesta es marcada como "mejor respuesta" se sumará + 1 hora de esfuerzos a tu certificado de estudios.

        </div> 
      </div>   
    `,
  }




  getParticipantsPages() {
    let types = ['recent', 'popular'];
    let types_position = 0;

    let X = {
      setResponse: (response) => {
        this._communityService.participantsPages[types[types_position]] = response;
        types_position++;
        if (types_position < types.length) {
          X.getParticipantsPages();
        }
      },
      getParticipantsPages: () => {
        if (types[types_position] != null && types[types_position] != '') {
          this._communityService.getParticipantsPages(types[types_position]).subscribe(pages => {
            X.setResponse(pages);
          });
        }
      }
    }

    X.getParticipantsPages();
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('status')) {
      /*if(!this._communityService.popularStatusLoaded && this.status){
        if(this._communityService._appService._apolloClient){
          this._communityService.loadUsersStatus(this._communityService.participants.popular,'now');
        }else{
          this._communityService._appService.lazyEventStream.subscribe(r => {
            if(r){
              this._communityService.loadUsersStatus(this._communityService.participants.popular,'now');
            }
          });
        }

        
        //let th = this;
        //do{

          //setTimeout(function () {
        
          //}, 1000);
        //}while(!this._communityService.popularStatusLoaded);
      }*/
    }
  }


  openUsersOnline() {

    this.selectComponent('users');
    this.selectFilter(1);

  }


    /*  -------------------------------------------------- Assist app init --------------------------------------------------  */

    isAssist(): boolean {
      return this._router.url.includes('/assist');
    }
  
    /*  -------------------------------------------------- Assist app end --------------------------------------------------  */
  

}
