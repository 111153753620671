import { Component, HostListener, Input, OnInit, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {

  environment: any = environment;
  @Input() config;
  @Input() carousel_translate;
  closeModal: Boolean = true;
  courseDetail: any = {};
  loadingCertification: boolean = false;
  tabActive: number = 1;
  loadingUnit: boolean = true;
  unit: Array<any> = [];

  constructor(
    private _router: Router,
    public _appService: AppService,
    // private _categoryService: CategoryService,
  ) { }


  public changeSize: Subject<string> = new Subject<string>();

  ngOnInit(): void {

    this.changeSize.pipe(
      debounceTime(50),
      distinctUntilChanged()
    ).subscribe(change => {
      this.setTrianglePosition();
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.setTrianglePosition();
    let windowWidth = window.innerWidth;
    let tmp_changes = JSON.stringify({ config: this.config, translate: this.carousel_translate, windowWidth });
    this.changeSize.next(tmp_changes);
  }

  tirangle_left_position: number = 0;
  setTrianglePosition() {

    let el = document.querySelector('#course_card_' + this.courseDetail.id + '_' + this.courseDetail.campaign_id)

    if (el) {
      let parent: any = el.parentNode;
      let parentBounding = parent.getBoundingClientRect();
      let elements = el.getBoundingClientRect();

      const { width, right } = elements;

      let middle = width / 2;
      this.tirangle_left_position = ((right - middle) - (parentBounding.x + 10)) + this.carousel_translate;

    }

  }


  ngOnChanges(changes: SimpleChange) {
    // Validar que los cambios de URL contengan el parametro config
    if (changes.hasOwnProperty('config') && this.config != null) {
      if (this.config.execute == "open") {
        this.closeModal = false;
        this.tabActive = 1;
        this.courseDetail = this.config.data;
        if (this._appService.user_session == 1) {
          // User with session
          this.getCertification();
          if (this.config.type == "fixed") {
            this.getCourseInfo(this.courseDetail.id);
          }
        }
        this.getUnit(this.courseDetail.id);
        const element = document.getElementById(this.config.anchor);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 500);
        }
      } else {
        this.closeModal = true;
      }
    }


    let tmp_changes = JSON.stringify({ config: this.config, translate: this.carousel_translate });
    this.changeSize.next(tmp_changes);
  }

  getCourseInfo(courseId): void {
    let i = this._appService.learning.findIndex(elemt => elemt.id == courseId);
    if (i != -1) {
      if (this._appService.learning[i].hasOwnProperty('descripcion') == false || !this._appService.learning[i].descripcion) {
        this._appService.getCourseInfo(courseId).subscribe(data => {
          if (data.valid) {
            if (data.print.length > 0) {
              let course = {
                descripcion: data.print[0].Curso.descripcion,
                tematicas: data.print[0].Curso.tematicas,
                visitas: data.print[0].Curso.visitas
              }
              let courseInfo = Object.assign(this._appService.learning[i], course);
              this._appService.learning[i] = courseInfo;
              this.courseDetail = courseInfo;
            }
          } else {
            // Error get detail course
          }
        });
      } else {
        // Course data is ok
      }
    }
  }

  getUnit(courseId): void {
    this.loadingUnit = true;
    this.unit = [];
    if (this._appService.unit.length == 0) {
      this._appService.getUnit(courseId).subscribe(data => {
        if (data.valid) {
          if (data.print.length > 0) {
            this.unit = data.print;
            this._appService.unit.push({ courseId: courseId, unit: this.unit });
          }
        } else {
          this.unit = [];
        }
        this.loadingUnit = false;
      });
    } else {
      let i = this._appService.unit.findIndex(elemt => elemt.courseId == courseId);
      if (i != -1) {
        this.unit = this._appService.unit[i].unit;
        this.loadingUnit = false;
      } else {
        this._appService.getUnit(courseId).subscribe(data => {
          if (data.valid) {
            if (data.print.length > 0) {
              this.unit = data.print;
              this._appService.unit.push({ courseId: courseId, unit: this.unit });
            }
          } else {
            this.unit = [];
          }
          this.loadingUnit = false;
        });
      }
    }
  }
  // action
  get_course_duration(d) {
    d = d / 3600;
    // if (d >= 3 && d <= 10) {
    //   return Math.round(d * 3);
    // } else if (d < 3) {
    //   return Math.round(d * 5);
    // } else if (d < 1) {
    //   return 1;
    // }
    return Math.round(d);
  }

  goToClassroom() {
    if (this.courseDetail && this.courseDetail.hasOwnProperty('id')) {
      let config;
      if (this._appService.user_session == 0) {
        // User without session
        config = {
          curso_id: this.courseDetail.id,
          certification_id: '0',
          type: '1',
          clase_id: '0',
          idu: '0',
          source_id: '0',
          quiz_id: '0',
          language_id: (this.courseDetail.language == 'Español' ? '1' : this.courseDetail.language == 'Ingles' ? '2' : '1'),
        }
        sessionStorage.setItem('config', JSON.stringify(config));
        window.open('/classroom?config=' + JSON.stringify(config), "_blank");
      } else if (this._appService.user_session == 1) {
        this.loadingCertification = true;
        // User with session
        config = {
          curso_id: this.courseDetail.id,
          certification_id: this.courseDetail.certification_id,
          type: this.courseDetail.tipo,
          clase_id: this.courseDetail.clase_id,
          idu: this._appService.user_data.id,
          source_id: this.courseDetail.source_id,
          quiz_id: this.courseDetail.quiz_id,
          language_id: this.courseDetail.language_id,
          state: this.courseDetail.certification_state,
          tour: this.courseDetail.certification_tour != null ? this.courseDetail.certification_tour : '-1'
        }
        let body = {
          "data":
          {
            "certification_id": "" + this.courseDetail.certification_id
          }
        };
        this._appService.putUser(JSON.stringify(body)).subscribe(data => {
          if (data.valid == false) {
            console.log("Error putUser():", data);
          }
        });

        // update learning
        this._appService.learning = this._appService.learning.filter(item => item.id != this.courseDetail.id).map((item) => { item.active = false; return item });
        this._appService.learning.unshift(Object.assign(this.courseDetail, { active: true }));

        sessionStorage.setItem('config', JSON.stringify(config));
        this._router.navigateByUrl('/classroom?config=' + JSON.stringify(config));
      }
    }
  }

  getCertification() {
    // Validar certification id
    this.loadingCertification = true;
    this._appService.getCertification(this.courseDetail.id).subscribe(async data => {
      if (data.valid) {
        if (this.courseDetail.id == data.id) {
          if (data.print.hasOwnProperty('Certification')) {
            const certification = data.print.Certification;
            this.courseDetail.certification_id = certification.id;
            this.courseDetail.clase_id = certification.clase_id;
            this.courseDetail.source_id = certification.source_id;
            this.courseDetail.quiz_id = certification.quiz_id;
            this.courseDetail.language_id = certification.language_id;
            this.courseDetail.certification_tour = certification.tour;
            this.courseDetail.certification_state = certification.estado;
            // Default value
            this.courseDetail.date = '';
            this.courseDetail.date_state = false;
            this.courseDetail.pending_payment = false;
            // Set date and date_state
            this.courseDetail = Object.assign(this.courseDetail, this._appService.dateCertification(certification.fecha_inscripcion));
            // Set pending_payment
            if (this.courseDetail.certification_state != 1) {
              // const { date_state } = this.courseDetail;
              // if (date_state) {
              this.courseDetail.pending_payment = -1;
              await this._appService.paymentsCertification(this.courseDetail.certification_id).then((res) => {
                this.courseDetail = Object.assign(this.courseDetail, res);
              });
              // }
            }

            this.loadingCertification = false;
          } else {
            this.courseDetail.date_state = false;
            var fecha = new Date();
            const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            this.courseDetail.date = fecha.getDate() + ' ' + monthNames[fecha.getMonth()] + ' ' + fecha.getFullYear();
            this.courseDetail.certification_id = -1;
            this.loadingCertification = false;
          }
        } else {
          // console.log("Skip getCertification()");
        }
      } else {
        this.loadingCertification = false;
      }
    });
  }

  enrollUser() {
    this.loadingCertification = true;
    let body = {
      "data": {
        "user_id": this._appService.user_data.id,
        "curso_id": this.courseDetail.id
      }
    };
    this._appService.enrollUser(body).subscribe(data => {
      if (data.valid) {
        if (data.print.hasOwnProperty('extra_data') && data.print.extra_data.hasOwnProperty('config_redirect') && data.print.extra_data.config_redirect.hasOwnProperty('certification_id')) {
          let certification_id = data.print.extra_data.config_redirect.certification_id;
          // Updating certification_id in user_data
          if (this._appService.user_data.hasOwnProperty('certification_id') && this._appService.user_data.certification_id == "0") {
            certification_id = JSON.stringify(certification_id);
            localStorage.setItem("last-certificate-default", certification_id);
            this._appService.user_data.certification_id = certification_id;
          }
          // Next updates in courseDetail
          this.courseDetail.certification_id = certification_id;
          this.courseDetail.clase_id = data.print.extra_data.config_redirect.clase_id;
          this.courseDetail.source_id = data.print.extra_data.config_redirect.source_id;
          this.courseDetail.quiz_id = data.print.extra_data.config_redirect.quiz_id;
          this.courseDetail.language_id = data.print.extra_data.config_redirect.language_id;

          this.courseDetail.date = '';
          this.courseDetail.date_state = false;
          this.courseDetail.pending_payment = false;
          this.courseDetail = Object.assign(this.courseDetail, this._appService.dateCertification(data.print.extra_data.config_redirect.fecha_inscripcion));

          this.goToClassroom();
        } else {
          console.log("Error enrollUser() not contain property: extra_data && config_redirect && certification_id", data);
        }
        this.loadingCertification = false;
      } else {
        console.log("Error enrollUser():", data);
        this.loadingCertification = false;
      }
    });
  }

  popitup(url) {
    if (window.focus) {
      window.open(url, 'Compartiendo curso de edutin Academy', 'height=800,width=500').focus()
    }
    return false;
  }
}
