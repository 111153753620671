import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class SearchGuard implements CanActivate {

  constructor(private _appService: AppService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { q, index } = next.queryParams;
    if (q && index) {
      // Show nav bar and footer
      this._appService.loadNavBar = this._appService.loadFooter = true;
      // Init gets nav bar
      this._appService.startGetsNavBar();
      // Go
      return true;
    } else {
      return this._router.parseUrl('/category');
    }
  }

}
