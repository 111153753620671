import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  @Input() showMenu: boolean = false;
  @Output() open_live_chat: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public _appService: AppService
  ) { this.getConversations(); }

  ngOnInit(): void {
  }
  
  toggleMessaging(): void {
    this._appService.messageCounter = 0;
    this._appService.showMessaging = !this._appService.showMessaging;
    if(this._appService.conversations && this._appService.isFirstTime){
        this._appService.isFirstTime = false;
        this._appService.loadConversationsStatus(this._appService.conversations);
        this._appService.loadConversationsInputStatus();
    }
  }


  getConversations(): void {
    /*this._appService.getConversations(null).subscribe(data => {
      if (data.valid) {
        this._appService.conversations = data.print;
        this._appService.messageCounter = this._appService.conversations.filter(msg => {
          return msg.state == '1';
        }).length;
        if (data.metadata && data.metadata.hasOwnProperty('last_evaluated_key')) {
          this._appService.conversation_metadata = data.metadata;
          this._appService.conversation_end = false;
        } else {
          this._appService.conversation_end = true;
        }
      } else {
        this._appService.conversations = [];
      }
    });*/

    /*this._appService.conversationsObservable.subscribe(data => {
      if (data.valid) {
        this._appService.conversations = data.print;
        this._appService.nextToken = data.next;
        /*this._appService.messageCounter = this._appService.conversations.filter(msg => {
          return msg.state == '1';
        }).length;
        if (data.metadata && data.metadata.hasOwnProperty('last_evaluated_key')) {
          this._appService.conversation_metadata = data.metadata;
          this._appService.conversation_end = false;
        } else {
          this._appService.conversation_end = true;
        }

        console.log(this._appService.conversations)
      } else {
        this._appService.conversations = [];
      }
    });*/

  }

  loading_previus: boolean = false;
  getPreviusConversations(): void {
    this.loading_previus = true;
    if (this._appService.nextToken) {
      this.getPreviousConversations();
    } else {
      this.loading_previus = false;
      this._appService.conversation_end = true;
    }
  }

  openChatRoom(action, data, index): void {

    this._appService.showMessaging = false;

    if (action == 1) {
      this._appService.chat_user = { execute : 1}
      this._appService.chatroom_state = true;

    }
    // console.log({ execute: action, data: data, index: index });
    this.open_live_chat.emit({ execute: action, data: data, index: index });


    /*let i = this._appService.conversations.findIndex(elemt => elemt == data);
    if (i != -1) {
      if (this._appService.conversations[i].state == 1) {
        this._appService.conversations[i].state = 0;
        this.configConvSP(null, 0, data);
        if (this._appService.messageCounter > 0) {
          this._appService.messageCounter--;
        }
      }
    }*/

    // this._appService.chat_user = { execute: action, data: data };

  }


  display_item_conversation: any = null;
  //neww1
  configConvSP(event: any, type: number, data: any) {
    if (event) {
      event.stopPropagation();
    }
    switch (type) {
      case -2:
        // Abrir opciones de los mensajes
        // if (data.parentElement.children[1].style.display == "none") {
        //   data.parentElement.children[1].style.display = "block";
        // } else {
        //   data.parentElement.children[1].style.display = "none";
        // }
        if (this.display_item_conversation == data.conversation.id) {
          this.display_item_conversation = null;
        } else {
          this.display_item_conversation = data.conversation.id;
        }
        break;
      case -1:
        // Eliminar notificación
        this._appService.deleteConversation(data.conversation.id).subscribe(data => {
          this.display_item_conversation = null;
          this._appService.conversations = this._appService.conversations.filter(con => con.conversation.id != data.data.su.conversation.id);
        });
        break;
      //leido y no leido
      case 0:
      case 1:
        let variables = {
          "conversation_id": data.conversation.id,
          "unreaded_messages": type
        };
        this._appService.setStateConversation(variables).subscribe(cnv => {
          //replacing old object
          let th = this;
          this._appService.conversations = this._appService.conversations.map(function (con) {
            if (con.conversation.id === cnv.data.su.conversation.id) {
              if (!th._appService.chatroom_state) {
                if (type == 1) {
                  th._appService.messageCounter += 1;
                } else {
                  th._appService.messageCounter -= 1;
                }
              }
              return cnv.data.su;
            } else {
              return con;
            }
          });
          this.display_item_conversation = null;
        }, (error) => {
          console.log('there was an error sending the mutation', error);
        });
        break;
      case 2:
        // Traer conversaciones anteriores
        this.getPreviusConversations();
        break;
    }
  }

  optionsHiddenM(): void {
    const options = document.querySelectorAll('.messaging .container-items .visible');
    if (options && options.length > 0) {
      options.forEach(element => {
        element.setAttribute('style', "display: none;");
      });
    }
  }

  getPreviousConversations(): void {
    if (this._appService.nextToken) {
      this._appService.getMoreConversations().subscribe((data: any) => {
        if (data.valid) {
          let th = this;
          data.print = JSON.parse(JSON.stringify(data.print));
          data.print.forEach(conv => {
            if (this._appService.verifyAssociatedConversation(conv)) {
              conv.input_state = "NONE";
              th._appService.conversations.push(conv);
            }
          });
          this._appService.nextToken = data.next;


          if (this._appService.nextToken) {
            this._appService.conversation_end = false;
          } else {
            this._appService.conversation_end = true;
          }

          /*TODO: open based in route
          this.conversationId = Number(this.route.snapshot.queryParamMap.get('conversation'));
          if (this.conversationId) {
              // Open chat
              this.openChatRoom(this.conversationId);
          }*/
        } else {
          this._appService.conversations = [];
        }

        this.loading_previus = false;
        //console.log(this.conversations)
      });
    } else {
      //this.end_msg = true;
      //this.load_msg = false;
    }
  }

  getGroupalConversationName(conv): string {

    let name = '';

    if (conv && conv.associated.length > 0) {
      if (conv.associated.length === 2) {
        name = conv.associated[0].user.user_name.split(' ')[0] + " y " + conv.associated[1].user.user_name.split(' ')[0];
      } else {
        name = conv.associated[0].user.user_name.split(' ')[0] + ", " + conv.associated[1].user.user_name.split(' ')[0] + " y " + (conv.associated.length - 2).toString() + " más";
      }
    }

    
    return name;
  }
}
