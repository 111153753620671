import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommunityService } from '../../../services/community.service';




@Injectable({
    providedIn: 'root'
})
export class SearchBarService {

    constructor(private _communityService: CommunityService) {

    }
    //ASIGNAR AQUÍ TODOS LOS MEDOTOS REFERENTES A LA BÚQUEDA



    public invalids = ["y", "a", "o", "ó", "ha", "he", "tu", "su", "ante", "bajo", "con", "cabe", "de", "desde", "hasta", "sin", "sobre", "tras", "contra", "el", "él", "la", "lo", "los", "las", "en", "entre", "del", "para", "es", "que", "qué", "un", "una", "unas", "donde", "cual", "cuál", "por", "por qué", "por que", "porque", "cuando", "hola", "como"];

    public acentuadas = {
        '&aacute;': 'á', '&eacute;': 'é', '&iacute;': 'í', '&oacute;': 'ó', '&uacute;': 'ú', '&ntilde;': 'ñ', '&agrave;': 'à', '&egrave;': 'è', '&ograve;': 'ò'
    };

    public putmark(text, match_) {


        var text_out = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        var index_word = text_out.indexOf(match_[0]);
        match_.forEach((match, index) => {
            if (index_word >= 0 && text_out.includes(match, index_word)) {
                index_word = index_word + (13 * index);
                let tmp_desc = text.substring(0, index_word) + "<mark>" + text.substring(index_word, index_word + match.length) + "</mark>" + text.substring((index_word + match.length), text.length);
                text = tmp_desc;
                index_word = index_word - (13 * index);
                index_word = text_out.indexOf(match_[index + 1], index_word + match.length);
            }
        });
        return text
    }

}