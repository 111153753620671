import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safePipe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}



@Pipe({ name: "toFixed" })
export class ToFixed implements PipeTransform {
  transform(number) {
    number = parseFloat(number).toFixed(0)
    return number;
  }
}


@Pipe({ name: 'evaluationStatePipe' })
export class EvaluationStatePipe implements PipeTransform {

  transform(evaluation, certification_state): string {
    // console.log('🚀 ~ evaluation ~ ', evaluation)
    let state = 'Sin resolver';

    if (evaluation.Attempt != null && evaluation.Attempt[0] != null) {

      if (certification_state == 1) {

        if (evaluation.Attempt[0].state == 1) {
          state = 'Calificado';
        } else {
          state = 'En revisión';
        }

      } else {
        state = 'Resuelta';
      }

    } else {
      state = 'Sin resolver';
    }

    // Estado: <p> {{(evaluation.Attempt != null && evaluation.Attempt[0] != null) ? _classroomService.data.certification.Certification.estado == 1 ? evaluation.Attempt[0].state == 1 ?
    //   ' Calificado' : ' En revisión' : 'Resuelta' : ' Sin resolver' }}</p>


    return state;
  }
}

//Pipe mostrar mensaje tooltip para conocer que falta realizar en la evaluación
@Pipe({ name: 'evaluationTooltipPipe' })
export class EvaluationTooltipPipe implements PipeTransform {

  transform(evaluation): string {
    let state = 'Recuerde que para aprobar este foro requiere realizar una participación y dar su opinión a participaciones de 2 de sus compañeros';

    if (evaluation.Attempt != null && evaluation.Attempt[0] != null) {

      if (evaluation.Attempt[0].count_participations == 1) {
        //Si ya realizó su participación 

        if (evaluation.Attempt[0].count_opinions == 0) {
          //Si no ha realizado ninguna opinión
          state = 'Recuerde que aún se requiere que de su opinión a 2 de sus compañeros para la aprobación de este foro.'
        }
        if (evaluation.Attempt[0].count_opinions == 1) {
          //Si ya ha realizado una opinión
          state = 'Recuerde que aún se requiere que de su opinión a otro de sus compañeros para la aprobación de este foro.'
        }

      } else {

        //Si aún no ha realizado participación
        if (evaluation.Attempt[0].count_opinions == 1) {
          //Si no ha realizado ninguna participación pero ya dio una opinión
          state = 'Recuerde que aún se requiere que participe en el foro y de su opinión a otro de sus compañeros para la aprobación de este foro.'
        }
        if (evaluation.Attempt[0].count_opinions > 1) {
          //Si ya ha realizado las dos opiniones pero aún no ha participado
          state = 'Recuerde que aún se requiere que participe en el foro para la aprobación de este foro.'
        }


      }

      // se requiere que des tu opinión a 2 de tus compañeros para la aprobación de este foro.
    }

    return state;
  }
}
