<div class="evaluations">

  <div class="summary" *ngIf="is_forums != true">


    <div class="course_progress">
      <div class="progress">
        <div class="progress-bar"
          [style.width]="(_classroomService.data.certification != null && _classroomService.data.certification.Certification != null && _classroomService.data.certification.Certification.calification) > 100 ? '100%' : _classroomService.data.certification.Certification.calification+'%'"
          *ngIf="_classroomService.data.certification != null && _classroomService.data.certification.Certification != null ">
        </div>
      </div>

      <div class="label-progress">
        <span>0</span>
        <span>50</span>
        <span>100</span>
      </div>

    </div>

    <p>
      <strong>Puntaje acumulado: </strong>Se requiere un minimo de 50 puntos para la aprobación de este curso.
    </p>

    <div class="btn__group">

      <a class="btn btn__light-blue btn-certification"
        *ngIf="_classroomService.data.actions && _classroomService.data.certification!=null && _classroomService.data.certification.Certification.estado == 1"
        [href]="environment.appDomain + '/dashboard/'+ _classroomService.data.certification.Certification.id"
        target="_blank">Administrar
        certificado</a>

      <a class="btn btn__info btn-certification"
        *ngIf="_classroomService.data.actions && _classroomService.data.certification!=null && _classroomService.data.certification.Certification.estado == -1"
        href="https://edutin.com/certificado-de-estudios" target="_blank">Más
        información</a>


      <a class="btn btn__light-blue btn-certification"
        *ngIf="_classroomService.data.actions && _classroomService.data.certification!=null && _classroomService.data.certification.Certification.estado == -1"
        [href]="'https://edutin.com/payments/certificate/'+ _classroomService.data.certification.Certification.id"
        target="_self">Estudiar
        con
        certificado</a>
    </div>


    <p *ngIf="!_classroomService.data.actions">Debe iniciar sesión para poder realizar las actividades acumular puntaje
      en su certificado.</p>

  </div>

  <div class="evaluation empty"
    *ngIf="_evaluationService.evaluations != null && _evaluationService.evaluations.length == 0">
    {{is_forums == true ? 'Este curso no cuenta con foros' : 'Este curso no cuenta con evaluaciones'}}
  </div>

  <div *ngIf="evaluations_loaded == true; else loading_evaluations">
    <!-- *ngFor="let evaluation of (is_forums ? _evaluationService.forums : _evaluationService.evaluations); let i_evaluation = index" -->
    <div class="evaluation" *ngFor="let evaluation of _evaluationService.evaluations; let i_evaluation = index"
      [ngClass]="{'active': _classroomService.data.source && (_classroomService.data.source.id == evaluation.Quiz.id), 'forum' : evaluation.Quiz.type == 1}"
      (click)="selectEvaluation(evaluation, null)">

      <strong>{{ (evaluation.Quiz.name != null && evaluation.Quiz.name != '') ? evaluation.Quiz.name :
        'Evaluación' }} </strong>

      <div class="evaluation-summary" [ngClass]="{'forums': is_forums == true}">

        <div class="evaluation_state" *ngIf="_classroomService.data.actions"
          [attr.data-tooltip]=" evaluation | evaluationTooltipPipe">
          <!-- Si state es 1 significa que fue calificada, y solo aplica si pagó certificado -->
          Estado:
          <p *ngIf="_classroomService.data.certification">
            {{ evaluation | evaluationStatePipe : _classroomService.data.certification.Certification.estado }}
          </p>

        </div>
        <div class="evaluation-feedback" [attr.data-tooltip]="'Retroalimentación'" *ngIf="evaluation.Quiz.type != 1">
          <i class="icon-feedback"></i>
          <p>
            {{(evaluation.Attempt != null && evaluation.Attempt[0] != null) ? evaluation.Attempt[0].has_feedback : '0'
            }}
          </p>
        </div>

        <div class="summary-calification" [attr.data-tooltip]="'Calificación'">
          <i class="icon-rate"></i>
          <p>
            {{((evaluation.Attempt != null && evaluation.Attempt[0] != null) ? ((evaluation.Attempt[0].result *
            evaluation.Quiz.weight)/ 100) : '0') | toFixed }}
            / {{evaluation.Quiz.weight | toFixed}} <span *ngIf="is_forums == true"> puntos</span></p>
          <!--  20 hace referencia al puntaje (peso) de la evaluación -->
        </div>


        <button *ngIf="is_forums == true" class="btn btn__dark-blue"
          (click)="selectEvaluation(evaluation, 'takepart'); $event.stopPropagation()">Participar</button>

      </div>


    </div>
  </div>


  <ng-template #loading_evaluations>

    <div class="loading-Element" *ngFor="let item of [0,1,2,3,4,5]">

      <div class="activity_loading--text"></div>

      <div class="activity_loading--summary" [ngClass]="{'forums': is_forums}">
        <div class="activity_loading--summary- state"></div>
        <div class="activity_loading--summary- feedback"></div>
        <div class="activity_loading--summary- calification"></div>
        <div class="activity_loading--summary- participate"></div>
      </div>

    </div>

  </ng-template>


</div>