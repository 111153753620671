import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ClassroomService } from 'src/app/classroom/services/classroom.service';
import { environment } from 'src/environments/environment';

// Assist app - init
import { AssistNavbarService } from '../../communication-services/assist-navbar.service';
import { MainChatRoomAssistService } from '../../../assist/components/chat-room-assist/main-chat-room-assist.service';
// Assist app - end

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  display_popup_dark_theme: boolean = false;
  display_categories: boolean = false;
  environment: any = environment;
  showMyCourses: boolean = false;
  loadingCertification: boolean = false;
  @ViewChild('myCourses_') myCourses_: ElementRef;

  showSearch: boolean = false;
  public notesModelChanged: Subject<string> = new Subject<string>();
  public notesModelChangeSubscription: Subscription;
  resultsSearch: Array<any> = [];
  preventBlur: boolean = true;
  indexSuggestion: number = -1;
  loadingSearch: boolean = false;
  @ViewChild('searchInput_') searchInput_: ElementRef;

  showAboutWe: boolean = false;

  showProfile: boolean = false;

  showMenu: boolean = false;

  @Output() open_live_chat: EventEmitter<any> = new EventEmitter<any>();

  // Assist app variables - init.
  filter_chat: string;
  assist: boolean;
  statistics: boolean;
  unread_courses_chats: boolean;
  @ViewChild('icon_search_in') icon_search_in: ElementRef;
  @ViewChild('icon_search_close') icon_search_close: ElementRef;
  @ViewChild('input_search_users') input_search_users: ElementRef;
  // Assist app variables - end.

  isAppleMobile: boolean = false; //True si es iPhone o Ipad

  constructor(
    public _router: Router,
    public _appService: AppService,
    public _authService: AuthService,
    // public _categoryService: CategoryService,
    public _classroomService: ClassroomService,
    private _assistNavbarService: AssistNavbarService, // Assist app - service - shared.
    private _mainChatRoomAssistService: MainChatRoomAssistService,
  ) {
    // Initialize assist variables.
    this.assist = this.statistics = this.unread_courses_chats = false;
    this.filter_chat = 'all';

    const isTouchSupported = "ontouchstart" in window || window.navigator.maxTouchPoints;

    this.isAppleMobile = (navigator.userAgent.includes("Macintosh") || navigator.userAgent.includes("iPad") || navigator.userAgent.includes("iPhone")) && isTouchSupported ? true : false;

  }

  ngOnInit(): void {
    // Crear evento de rebote en el search
    this.createDebounce();
    // Assist app method.
    this.showAssistElements();
    this.actionStatistics();
    this.showActivateChatsCourses();





    if (localStorage.getItem('darkTheme') != null) {
      //obtener tema desde el localstorage
      const darkTheme: any = localStorage.getItem('darkTheme');
      this._appService.darkTheme = (darkTheme.toLowerCase() == 'true');
    } else {
      //Si no existe darkTheme en el localstorage
      //Si el tema del sistema es oscuro se asigna tema dark a la app    
      // const colorSchemeIsDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      // if (colorSchemeIsDark) this.toggleDarkTheme(true);
    }


    if (localStorage.getItem('popupTheme') != null) {
      //obtener variable desde el localstorage para saber si debo mostrar notificación de usar el popup  
    } else {
      //Si no existe popupTheme en el localstorage
      if (!localStorage.getItem('darkTheme')) {
        setTimeout(() => {
          this.display_popup_dark_theme = true;
        }, 5000);
      }

    }


  }

  closeThemePopup() {
    this.display_popup_dark_theme = false;
    localStorage.setItem('popupTheme', 'true'); 
  }

  toggleDarkTheme(checked) {
    //Mostrar habilitado/deshabilitado tema en el menú de usuario
    localStorage.setItem('darkTheme', checked);
    this._appService.darkTheme = checked;

    this.closeThemePopup();
  }


  ngOnDestroy() {
    // this.notesModelChangeSubscription.unsubscribe();    
  }

  goToApp(): void {
    if (this._appService.user_session != -1) {
      if (this._appService.user_session == 1) {
        // if (this._appService.url_route != 'home') {
        const { print } = this._appService.getUserData();
        const { certification_id } = print;
        // User with certification
        if (certification_id && certification_id != '0' && certification_id != '-1') {
          this._router.navigate(['/home']);
        } else {
          this._router.navigate(['/category']);
        }
        // } else {
        //   if (this._appService.learning.length > 0) {
        //     this.openCourse(this._appService.learning[0]);
        //   } else {
        //     this._router.navigate(['/category']);
        //   }
        // }
      } else {
        if (this._appService.url_route != 'category') {
          this._router.navigate(['/category']);
        } else {
          window.location.href = environment.domain;
        }
      }
    }
  }

  toggleMyCourses(): void {
    this.showMyCourses = !this.showMyCourses;
    this.myCoursesScrollB();
  }

  myCoursesScrollB(): void {
    setTimeout(() => {
      this.myCourses_.nativeElement.scrollTop = 0;
      // this.myCourses_.nativeElement.scrollHeight;
    }, 5);
  }

  getPrevious(): void {
    // this._appService.pagination += 1;
    this._appService.loadingPagination = true;
    this._appService.getLearning(this._appService.pagination).subscribe(data => {
      // console.log('getLearning:: ', data);
      if (data.valid) {
        if (data.print && data.print.length > 0) {
          data.print.forEach(async (element, index) => {

            // let i = (this._appService.pagination * 10) + index;
            if (element.certification_state != 1) {
              // Call when you do not have the certificate
              element.pending_payment = -1;
              // this._appService.learning[i].pending_payment = -1;
              await this._appService.paymentsCertification(element.certification_id).then((res) => {
                element = Object.assign(element, res);
                this._appService.learning.push(Object.assign(element, { active: false }));
              });
            }
          });
        } else {
          // this._appService.pagination -= 1;
        }
      } else {
        // this._appService.pagination -= 1;
      }
      setTimeout(() => {
        this._appService.loadingPagination = false;
      }, 1000);
    });
  }

  courseId: number;
  openCourse(course, openNotification?): void {

    if (course && course.hasOwnProperty('id')) {

      //Actualizar visualización de classroom
      this._classroomService.clearAllData();
      this._classroomService.load_classroom_view = false;

      if (this._appService.url_route == 'classroom') this._appService.display_spinner = true;
      this._appService.url_route = '';
      //Actualizar visualización de classroom

      this.courseId = course.id;
      this.showMyCourses = false;

      this.loadingCertification = true;
      // this._appService.getCertification({ certification_id: course.certification_id }).subscribe(data => {
      this._appService.getCertification(course.id).subscribe(data => {


        //Actualizar visualización de classroom
        this._appService.url_route = 'classroom';
        this._appService.display_spinner = false;
        //Actualizar visualización de classroom
        if (data.valid) {
          if (course.id == data.id) {
            if (data.print.hasOwnProperty('Certification')) {
              let certification = data.print.Certification;

              course.certification_id = certification.id;
              course.clase_id = certification.clase_id != '' ? certification.clase_id : 0;
              course.source_id = certification.source_id != '' ? certification.source_id : 0;
              course.quiz_id = certification.quiz_id != '' ? certification.quiz_id : 0;
              course.language_id = certification.language_id;

              let config = {
                curso_id: course.id,
                certification_id: course.certification_id,
                type: course.tipo,
                clase_id: course.clase_id,
                idu: this._appService.user_data.id,
                source_id: course.source_id,
                quiz_id: course.quiz_id,
                language_id: course.language_id,
                state: course.certification_state,
                tour: course.certification_tour != null ? course.certification_tour : '-1'
              }

              // update certification user
              let body = {
                "data":
                {
                  "certification_id": "" + course.certification_id
                }
              };
              this._appService.putUser(JSON.stringify(body)).subscribe(data => {
                if (data.valid == false) {
                  console.log("Error putUser():", data);
                }
              });

              // update learning
              this._appService.learning = this._appService.learning.filter(item => item.id != course.id).map((item) => { item.active = false; return item });
              this._appService.learning.unshift(Object.assign(course, { active: true }));

              sessionStorage.setItem('config', JSON.stringify(config));

              // if (openNotification != null) {
              //   this._router.navigateByUrl('/classroom?config=' + JSON.stringify(config));
              // } else {
              //   this._router.navigateByUrl('/classroom?config=' + JSON.stringify(config));
              // }
              // this._router.navigateByUrl('/classroom?config=' + JSON.stringify(config));
              this._router.navigateByUrl(`/classroom?course_id=${config.curso_id}&source_id=${config.source_id != 0 ? config.source_id : config.quiz_id}`);

            } else {
              console.log("Error getCertification() not contain property: Certification", data);
            }
          } else {
            // console.log("Skip getCertification()");
          }
        } else {
          // console.log("Error getCertification()", data);
        }
        this.loadingCertification = false;
      });

    }
  }

  disableClick(event: any) {
    event.stopPropagation();
  }

  goToCategory(): void {
    this.showMyCourses = false;
    this._appService.learning = this._appService.learning.map((item) => { item.active = false; return item });
    this._router.navigate(['/category']);
  }

  configCourseSP(event: any, type: number, data: any) {
    event.stopPropagation();
    switch (type) {
      case 0:
        // Abrir opciones de la notificacion
        // if (data.parentElement.children[1].style.display == "none") {
        //   data.parentElement.children[1].style.display = "block";
        // } else {
        //   data.parentElement.children[1].style.display = "none";
        // }
        break;
    }
  }

  createDebounce() {
    this.notesModelChangeSubscription = this.notesModelChanged.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(newText => {
      // this._appService.searchText = newText;
      if (newText != '' && !this.goActive) {
        this.getResultSearch();
      } else {
        this.goActive = false;
        this.resultsSearch = [];
      }
    });
  }

  getResultSearch(): void {
    this.loadingSearch = true;
    this.resultsSearch = [];
    this._appService.getResultSearch(this._appService.searchText, 0).subscribe(data => {
      if (data.valid) {
        this.showSearch = true;
        this.resultsSearch = data.print;
      } else {
        this.showSearch = false;
        this.resultsSearch = [];
      }
      this.goActive = false;
      this.preventBlur = true;
      this.loadingSearch = false;
    });
  }

  onKeydownEvent(e) {
    switch (e.key) {
      case "Enter":
        if (this.indexSuggestion == -1) {
          this.goToSearch(-1);
        } else {
          this.goToSearch(this.indexSuggestion);
        }
        break;
      case "Escape":
        this._appService.searchText = '';
        this.resultsSearch = [];
        break;
      case "ArrowUp":
        e.preventDefault();
        if (this.indexSuggestion > -1) {
          this.indexSuggestion--;
        } else {
          this.indexSuggestion = this.resultsSearch.length - 1;
        }
        break;
      case "ArrowDown":
        if (this.resultsSearch.length - 1 > this.indexSuggestion) {
          this.indexSuggestion++;
        } else {
          this.indexSuggestion = 0;
        }
        break;
      default:
        this.indexSuggestion = -1;
        break
    }
  }

  omit_special_char(e: any) {
    try {
      let regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]*$/;
      if (regex.test(e.key)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    } catch (e) {
    }
  }

  blurOnSearch(): void {
    setTimeout(() => this.searchInput_.nativeElement.blur(), 0);
  }

  showInput() {
    setTimeout(() => {
      this.searchInput_.nativeElement.focus();
    }, 0);
  }

  goActive: boolean = false;
  goToSearch(i): void {


    if (this._appService.searchText != '') {

      if (i == -1) {

        if (this._appService.learning && this._appService.learning.length > 0 && this._appService.user_session == 1) {
          // update learning
          this._appService.learning[0].active = false;
        }
        this.blurOnSearch();
        this.goActive = true;
        this.preventBlur = true;
        this.showSearch = false;
        this._router.navigate(['/search'], {
          queryParams: {
            q: this._appService.searchText,
            index: i
          }
        });
        // this._appService.searchText = '';

      } else {
        if (this.resultsSearch && this.resultsSearch.length > 0 && this.resultsSearch[i] && this.resultsSearch[i].fields && this.resultsSearch[i].fields['slug']) {
          window.open(`${environment.domain}/${this.resultsSearch[i].fields['slug']}`, "_blank");
        }
      }


    }
  }

  toggleAboutWe(): void {
    this.showAboutWe = !this.showAboutWe;
  }

  goToLink(url: string): void {
    window.open(url, "_blank");
  }

  toggleProfile(): void {
    this.showProfile = !this.showProfile;
  }

  logout(url) {
    this._appService.disconnectUserFromGraphQL().subscribe(data => {
      // console.log(data);
    });

    // Call actions functions assist.
    clearInterval(this._mainChatRoomAssistService.heartbeatinterval);
    this._assistNavbarService.getActivateAssist();
    this._assistNavbarService.resetDataSearch();

    clearInterval(this._appService.heartbeatinterval);

    localStorage.clear();
    sessionStorage.clear();
    this._appService.defaultGetsNavBar();
    this._authService.signOut();
    this._appService.nextToken = null;

    // window.location = url;

    // Assist app variables.
    this.assist = false;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  goToProfile() {
    this.showProfile = false;

    if (this._appService.url_route.includes('profile')) {
      window.open(`${environment.appDomain}/user/profile/${this._appService.user_data.id}`, "_self");
    } else {
      this._router.navigateByUrl('/user/profile/' + this._appService.user_data.id);
    }

  }

  goToMeet() {
    console.log(this._appService.user_data);
    this.showProfile = false;
    let user = {
      status: "online",
      url_image: this._appService.user_data.picture,
      user_fuente: "form",
      user_id: this._appService.user_data.id,
      user_image: this._appService.user_data.image,
      user_name: this._appService.user_data.name,
      user_role: this._appService.user_data.role
    };
    window.open(`${environment.appDomain}/meet?config=${JSON.stringify(user)}`, "_blank");
  }

  // ------------------------------------------- Assist app methods - init. -------------------------------------------


  /*
  * Show assist app elements and hidde main app elements,
  * those elements assist are not required.
  */
  showAssistElements(): void {
    this._assistNavbarService.getActivateAssist().subscribe(activate => {
      this.assist = activate;
    });
  }




  /*
  * Set activate unread chats courses.
  */
  showActivateChatsCourses(): void {
    this._assistNavbarService.getActivateUnreadChatsCourses().subscribe(activate => {
      this.unread_courses_chats = activate;
    });
  }



  /*
  * Set the value of the user filter.
  */
  onChangeFilter(): void {
    this.input_search_users.nativeElement.value = '';
    this.actionsIconsSearch(true);
    this.setDataFilter();
  }



  /*
  * Always send false value for last_id variable for user pagination with to-do (tareas pendientes) filter.
  */
  setDataLastID(): void {
    this._assistNavbarService.setValueLastID(false);
  }



  /*
  * Set data from filter.
  */
  setDataFilter(): void {
    this._assistNavbarService.setValueFilter(this.filter_chat);
  }



  /*
  * Set data from search.
  */
  setDataSearch(value: string): void {
    this._assistNavbarService.setDataSearch(value);
  }



  /*
  * Hidde or show elements about statistics not required.
  */
  actionStatistics(): void {
    this._assistNavbarService.getControlStatistics().subscribe(control => {
      this.statistics = control;
      if (!this.statistics) {
        this.filter_chat = 'all';
      }
    });
  }



  /*
  * Get text from input search to get chats about data entered.
  */
  onTypingSearchAssist(event, input): void {
    let value = input.value;

    // Get chats again by current filter if the search have not data.
    if (value == "") {
      this.setDataLastID();
      this.actionsIconsSearch(true);
      this.onChangeFilter();
      return;
    }

    if (event.type == 'keyup') {
      // Two options implemented to get enter key on browsers all.
      if (!(event.key == "Enter" && event.keyCode == 13)) {
        return;
      }
    }

    if (event.type == 'click') {
      if (this.icon_search_in.nativeElement.className.search("none") !== -1) {
        this.actionsIconsSearch(true);
        input.value = "";
        this.setDataLastID();
        this.onChangeFilter();
        return;
      }
    }

    this.actionsIconsSearch(false);
    this.setDataSearch(value);
  }



  // Show and hidde icons on search.
  actionsIconsSearch(value): void {
    if (value) {
      this.icon_search_in.nativeElement.classList.remove("none");
      this.icon_search_close.nativeElement.classList.add("none");
      return;
    }
    this.icon_search_in.nativeElement.classList.add("none");
    this.icon_search_close.nativeElement.classList.remove("none");
  }


  // ------------------------------------------- Assist app methods - end -------------------------------------------.
}
