import { Inject, Injectable, Injector, NgModuleFactory, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRoute, NavigationStart, NavigationEnd, Router, Event, NavigationCancel, NavigationError } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
// import { UrlLambdaApi } from '../../../classroom/models/models';

// import { Global } from '../../services/global';

import { AppService } from '../../../app.service';

//graphql
import { gql, QueryRef } from 'apollo-angular';
// import { Subscription } from 'rxjs';
import { LAZY_MODULES_MAP } from '../../../shared/graphql/lazy_module_map';
import { Integer } from 'aws-sdk/clients/apigateway';
//graphql

@Injectable()
export class MainChatRoomAssistService {

    // public url_Base: string = "https://develop.edutin.com";
    public display_spinner: boolean = false;
    public headers: any;
    public url_Base: string;
    // baseLambdaUrl = 'https://api.edutin.com/b/d';
    baseLambdaUrl: string;
    baseUrl: string;

    public chat_user: any;
    
    public showMessaging: boolean = false;
    lazyEventStream = new Subject();
    
    //graphql
    public ws_host: string;
    static idtoken: string;
    static current_opened_conversation: string;
    public token: string;

    heartbeatQuery: QueryRef<any>;
    conversationsQuery: QueryRef<any>;
    statusQuery: QueryRef<any>;
    heartbeatObservable: Observable<any>;
    conversationsObservable: Observable<any>;

    conversations: Array<any> | any = null;
    current_conversation_index: Integer = -1;
    nextToken: String = null;
    conversation_end: boolean = true;
    messageCounter: number = 0;




    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private injector: Injector,
        private loader: NgModuleFactoryLoader,
        @Inject(LAZY_MODULES_MAP) private modulesMap,
        private _appService: AppService,
    ) {
        this.chat_user = { execute: 1 };
        let routerEventsSubscriber = this.router.events.subscribe((routerEvent: Event) => {

            if (routerEvent instanceof NavigationStart) {
                this.display_spinner = true;
            }

            if (routerEvent instanceof NavigationEnd ||
                routerEvent instanceof NavigationError ||
                routerEvent instanceof NavigationCancel) {
                this.display_spinner = false;
                if (routerEventsSubscriber) routerEventsSubscriber.unsubscribe();

            }
        });
        this.baseLambdaUrl = _appService.baseLambdaUrl;
        this.headers = this._appService.getHeaders()
        MainChatRoomAssistService.idtoken = AppService.idtoken;
        this.token = _appService.token;
        this.url_Base = _appService.url_Base;
        this.baseUrl = this.url_Base;
        this.ws_host = _appService.ws_host;
    }




    delete(url: any) {
        return this.http.delete(url, this.headers);
    }




    //graphql lazy loading
    _apolloClientAssist
    heartbeatinterval
    loadGraphQLModule() {

        this.loader
            .load(this.modulesMap['graphQLModule'])
            .then((moduleFactory: NgModuleFactory<any>) => {
                const moduleRef = moduleFactory.create(this.injector);

                const nuwModule = moduleRef.injector.get((<any>moduleFactory.moduleType).createApolloInit);

                this._apolloClientAssist = moduleRef.injector.get((<any>moduleFactory.moduleType).apolloClient);

                this.lazyEventStream.next(true);
                //graphql
                //connecting user to grapql
                this.connectUserToGraphQL().subscribe(data => {
                });

                this.setHeartBeat();
                let th = this;

                this.heartbeatinterval = setInterval(function () {
                    th.setHeartBeat();
                    th.sendHeartBeat();
                }, 20000);

                // here before code.
                this.initConversationsObjects();

                // here initSubscription before
                //graphql
            });
    }




    loadDataConversationsInit(): void {
        this.conversationsObservable.subscribe((data: any) => {
            if (data.valid) {
                data.print = JSON.parse(JSON.stringify(data.print));
                let temp_conversations = [];
                data.print.forEach(element => {
                    if (this._appService.verifyAssociatedConversation(element)) {
                        temp_conversations.push(element);
                    }
                });
                this.nextToken = data.next;
                
                let th = this;
                
                temp_conversations.forEach(e => {
                    e.input_state = "NONE";
                    if (e.unreaded_messages > 0) {
                        th.messageCounter += 1;
                    }
                });
                
                if (this.showMessaging) {
                    this.conversations = temp_conversations;
                    this.loadConversationsStatus(temp_conversations);
                    this.loadConversationsInputStatus();
                }

                if (this.nextToken) {
                    this.conversation_end = false;
                } else {
                    this.conversation_end = true;
                }
            } else {
                this.conversations = [];
            }
        });
    }





    loadConversationsInputStatus(){
        const ws_headers = {
            "Authorization": MainChatRoomAssistService.idtoken,
            "host": this.ws_host
        };
        this.statusQuery.subscribeToMore({
            document: MainChatRoomAssistService.INPUT_STATUS,
            variables: {
                user_id: this._appService.user_data.id
            },

            extensions: {
                authorization: ws_headers
            },
            updateQuery: (prev, { subscriptionData }) => {
                //evaluate to proccess a subscription if no message is sended
                //replacing old object
                var th = this;
                this.conversations = this.conversations.map(function (con) {
                    if (con.conversation.id === subscriptionData.data.subscribeToInputState.conversation_id) {
                        con.input_state = subscriptionData.data.subscribeToInputState.input_state
                    } 
                    return con;
                });
            }
        });
    }
    



    loadConversationsStatus(arr) {
        const ws_headers = {
            "Authorization": MainChatRoomAssistService.idtoken,
            "host": this.ws_host
        };
        arr.forEach(f => {
            f.associated.forEach(e => {
                e.user.status = 'offline';

                this.statusQuery = this._apolloClientAssist.watchQuery({
                    query: MainChatRoomAssistService.GET_STATUS,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                    variables: {
                        id: e.user.user_id
                    }
                });

                this.statusQuery.valueChanges.pipe(
                    map((response: any) => {
                        e.user.status = response.data.status.status;
                        return response;
                    })
                ).subscribe((data: any) => { });

                this.statusQuery.subscribeToMore({
                    document: MainChatRoomAssistService.STATUS,
                    variables: {
                        id: e.user.user_id
                    },

                    extensions: {
                        authorization: ws_headers
                    },
                    updateQuery: (prev, { subscriptionData }) => {
                        //evaluate to proccess a subscription if no message is sended
                        //replacing old object
                        e.user.status = subscriptionData.data.onStatus.status;
                    }
                });
            });
        });
    }




    static GET_CONVERSATIONS = gql`
    query get_user_conversation_connection_through_user($after: String, $first: Int) {
        me {
            conversations(first: $first, after: $after) {
                nextToken
                userConversations {
                    associated {
                        user {
                            user_id
                            user_name
                            user_image
                            user_role
                            user_fuente
                        }
                        state
                        user_role
                    }
                    conversation {
                        id
                        name
                        type
                        image
                    }
                    last_message
                    last_update
                    unreaded_messages
                    state
                    user_role
                }
            }
        }
    }`;




    static GET_MORE_CONVERSATIONS = gql`
    query get_user_conversation($after: String, $first: Int) {
        allUserConversationConnection(after: $after, first: $first) {
            nextToken,
            userConversations {
                associated {
                    user {
                        user_id
                        user_name
                        user_image
                        user_role
                        user_fuente
                    }
                    state
                    user_role
                }
                conversation {
                    id
                    name
                    type
                    image
                }
                last_message
                last_update
                unreaded_messages
                state
                user_role
            }
        }
    }`;




    static NEW_CONVERSATION = gql`
    subscription subscribeToNewUCs($user_id: ID!) {
        subscribeToNewUCs(user_id: $user_id) {
            conversation {
                id
                name
                type
                image
            }
            associated {
                user {
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                state
                user_role
            }
            user_id
            last_message
            last_update
            unreaded_messages
            state
            user_role
        }
    }`;




    static STATUS = gql`
    subscription subscribeStatus($id: ID!) {
        onStatus(id: $id) {
            id
            status
        }
    }`;




    static INPUT_STATUS = gql`
    subscription subscribeInputStatus($user_id: ID!) {
        subscribeToInputState(user_id: $user_id){
            user_id
            conversation_id
            input_state
        }
    }`;




    static GET_CACHED_CONVERSATION = gql`
    fragment active_conver on Conversation {
        id
        name
        image
        type
        messages
        next_token
    }
    `;




    static WRITE_CACHED_CONVERSATION = gql`
    fragment active_conver on Conversation {
        next_token
        messages
    }
    `;




    static WRITE_PENDING_MESSAGES = gql`
    fragment active_conver on Conversation {
        pending_messages
    }
    `;




    static DELETE_CONVERSATION = gql`
    mutation deleteConversation($conversation_id: ID!, $state: String!) {
        su: updateUserConversations(conversation_id: $conversation_id, state: $state){
            conversation {
                id
                name
            }
        }
    }`;




    static CONNECT_USER = gql`
    mutation connectUser($id: ID!) {
        cu: connect(id: $id){
            id
            status
        }
    }`;




    static DISCONNECT_USER = gql`
    mutation disconnectUser($id: ID!) {
        cu: disconnect(id: $id){
            id
            status
        }
    }`;




    static HEARTBEAT = gql`
    query send_heartbeat($id: ID!) {
        heartbeat(id: $id){
            id
            status
        }
    }`;




    static GET_STATUS = gql`
    query get_status($id: ID!) {
        status(id: $id){
            id
            status
        }
    }`;




    static SET_UNREADED_MESSAGES = gql`
    mutation setUnreadMessages($conversation_id: ID!, $unreaded_messages: Int!) {
        su: updateUserConversations(conversation_id: $conversation_id, unreaded_messages: $unreaded_messages){
            conversation {
                id
                name
                type
                image
            }
            associated {
                user {
                    user_id
                    user_name
                    user_image
                    user_role
                    user_fuente
                }
                state
                user_role
            }
            user_id
            conversation_id
            last_message
            last_update
            unreaded_messages
            state
            user_role
        }
    }`;




    setHeartBeat() {
        this.heartbeatQuery = this._apolloClientAssist.watchQuery({
            query: MainChatRoomAssistService.HEARTBEAT,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables: {
                id: this._appService.user_data.id
            }
        });
        this.heartbeatObservable = this.heartbeatQuery.valueChanges.pipe(
            map((response: any) => {
                return response;
            })
        );
    }




    sendHeartBeat() {
        this.heartbeatObservable.subscribe((data: any) => {
        });
    }




    initConversationsObjects() {
        this.conversationsQuery = this._apolloClientAssist.watchQuery({
            query: MainChatRoomAssistService.GET_CONVERSATIONS,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables: {
                after: this.nextToken
            }
        });

        this.conversationsObservable = this.conversationsQuery.valueChanges.pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valConversations(response.data.me.conversations);
                if (valData.is) {
                    return {
                        valid: true,
                        print: response.data.me.conversations.userConversations.filter(element => {
                            return element.__typename == "UserConversations"
                                && element.hasOwnProperty('associated')
                                && element.hasOwnProperty('conversation')
                                && element.hasOwnProperty('last_message')
                                && element.hasOwnProperty('last_update')
                                && element.conversation;
                        }),
                        next: response.data.me.conversations.nextToken
                    };
                } else {
                    return { valid: false, print: valData.msg };
                }
            })
        );
    }




    connectUserToGraphQL(): Observable<any> {
        let variables = {
            id: this._appService.user_data.id
        };

        return this._apolloClientAssist.mutate({
            mutation: MainChatRoomAssistService.CONNECT_USER,
            variables: variables
        });
    }




    disconnectUserFromGraphQL(): Observable<any> {
        let variables = {
            id: this._appService.user_data.id
        };

        return this._apolloClientAssist.mutate({
            mutation: MainChatRoomAssistService.DISCONNECT_USER,
            variables: variables
        });
    }




    valConversations(data: any): any {
        if (data == null || data == undefined) {
            return { is: false, msg: 'Respond with null or undefined' };
        } else if (data.__typename == 'UserConverstationsConnection') {
            return { is: true, msg: 'Ok Value' };
        } else {
            return { is: false, msg: 'Response contains invalid data from Graph' };
        }
    }




    initSubscription() {
        const ws_headers = {
            "Authorization": MainChatRoomAssistService.idtoken,
            "host": this.ws_host
        };
        var th = this;
        setTimeout(function () {
            th.conversationsQuery.subscribeToMore({
                document: MainChatRoomAssistService.NEW_CONVERSATION,
                variables: {
                    user_id: th._appService.user_data.id//user_id from token
                },

                extensions: {
                    authorization: ws_headers
                },
                updateQuery: (prev, { subscriptionData }) => {
                    //evaluate to proccess a subscription if no message is sended
                    //replacing old object
                    if(subscriptionData.data.subscribeToNewUCs.state != "DELETED"){
    
                        //move to 0 position new object
                        let conv = th.conversations.findIndex(c => c.conversation.id === subscriptionData.data.subscribeToNewUCs.conversation.id);
    
                        if (conv > -1) {
                            th.conversations = th.conversations.map(function (con) {
                                if (con.conversation.id === subscriptionData.data.subscribeToNewUCs.conversation.id) {
                                    let convv = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewUCs));
                                    
                                    if(convv.associated.length === 1){
                                        convv.associated[0].state = "ACCEPTED";
                                        if (con.associated.length === 2) {
                                            convv.associated[0].user.status = 'offline';
                                            th.statusQuery = th._apolloClientAssist.watchQuery({
                                                query: MainChatRoomAssistService.GET_STATUS,
                                                errorPolicy: 'all',
                                                fetchPolicy: 'network-only',
                                                variables: {
                                                    id: convv.associated[0].user.user_id
                                                }
                                            });

                                            th.statusQuery.valueChanges.pipe(
                                                map((response: any) => {
                                                    convv.associated[0].user.status = response.data.status.status;
                                                    return response;
                                                })
                                            ).subscribe((data: any) => { });

                                            th.statusQuery.subscribeToMore({
                                                document: MainChatRoomAssistService.STATUS,
                                                variables: {
                                                    id: convv.associated[0].user.user_id
                                                },
                    
                                                extensions: {
                                                    authorization: ws_headers
                                                },
                                                updateQuery: (prev, { subscriptionData }) => {
                                                    //evaluate to proccess a subscription if no message is sended
                                                    //replacing old object
                                                    convv.associated[0].user.status = subscriptionData.data.onStatus.status;
                                                }
                                            });
                                        } else {
                                            convv.associated = con.associated;
                                        }
                                    } else {
                                        if(convv.associated.length === 0){
                                            convv.associated = con.associated;
                                        }
                                    }
                                    return convv;
                                } else {
                                    return con;
                                }
                            });
                            th.array_move(th.conversations, conv, 0);
                            
                            if (conv === th.current_conversation_index) {
                                th.current_conversation_index = 0;
                            } else if (th.current_conversation_index < conv && th.current_conversation_index != -1) {
                                th.current_conversation_index += 1;
                            }
                        } else {
                            subscriptionData.data.subscribeToNewUCs = JSON.parse(JSON.stringify(subscriptionData.data.subscribeToNewUCs));
                            subscriptionData.data.subscribeToNewUCs.associated.forEach(e => {
                                e.user.status = 'offline';
                                
                                th.statusQuery = th._apolloClientAssist.watchQuery({
                                    query: MainChatRoomAssistService.GET_STATUS,
                                    errorPolicy: 'all',
                                    fetchPolicy: 'network-only',
                                    variables: {
                                        id: e.user.user_id
                                    }
                                });
    
                                th.statusQuery.valueChanges.pipe(
                                    map((response: any) => {
                                        e.user.status = response.data.status.status;
                                        return response;
                                    })
                                ).subscribe((data: any) => { });
    
                                th.statusQuery.subscribeToMore({
                                    document: MainChatRoomAssistService.STATUS,
                                    variables: {
                                        id: e.user.user_id
                                    },
        
                                    extensions: {
                                        authorization: ws_headers
                                    },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        //evaluate to proccess a subscription if no message is sended
                                        //replacing old object
                                        e.user.status = subscriptionData.data.onStatus.status;
                                    }
                                });
                            });
                            th.conversations.unshift(subscriptionData.data.subscribeToNewUCs);
                        }
                        
                        if (MainChatRoomAssistService.current_opened_conversation == undefined || MainChatRoomAssistService.current_opened_conversation != subscriptionData.data.subscribeToNewUCs.conversation.id) {
                            if (subscriptionData.data.subscribeToNewUCs.unreaded_messages === 1) {
                                th.messageCounter++;
                            }
                            th.playTone();
                        }
                        th.saveApolloCache(subscriptionData.data.subscribeToNewUCs.conversation.id);
                    }
                }
            });
        }, 1000);
    }




    array_move(arr, old_index, new_index): [] {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    }




    playTone() {
        let audio = new Audio();
        audio.src = "../../../../assets/sound/tone.wav";
        audio.load();
        audio.play();
    }




    clear_created_conversations(): void {
        this.conversations = this.conversations.filter(element => {
            return element.state != 'CREATED';
        });
    }




    saveApolloCache(id): void {
        /* const conver = this._apolloClientAssist.getClient().readFragment({
            id: 'Conversation:' + id,
            fragment: MainChatRoomAssistService.GET_CACHED_CONVERSATION,
        }); */

        this._apolloClientAssist.getClient().writeFragment({
            id: 'Conversation:' + id,
            fragment: MainChatRoomAssistService.WRITE_CACHED_CONVERSATION,
            data: {
                messages: [],
                next_token: null
            },
        });
    }




    getMoreConversations(): Observable<any> {
        this.conversationsQuery = this._apolloClientAssist.watchQuery({
            query: MainChatRoomAssistService.GET_MORE_CONVERSATIONS,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
            variables: {
                after: this.nextToken
            }
        });

        return this.conversationsQuery.valueChanges.pipe(
            map((response: any) => {
                // Validar datos
                let valData = this.valConversations(response.data.allUserConversationConnection);
                if (valData.is) {
                    return {
                        valid: true,
                        print: response.data.allUserConversationConnection.userConversations.filter(element => {
                            return element.__typename == "UserConversations"
                                && element.hasOwnProperty('associated')
                                && element.hasOwnProperty('conversation')
                                && element.hasOwnProperty('last_message')
                                && element.hasOwnProperty('last_update')
                                && element.conversation;
                        }),
                        next: response.data.allUserConversationConnection.nextToken
                    };
                } else {
                    return { valid: false, print: valData.msg };
                }
            })
        );
    }




    deleteConversation(conversation_id): Observable<any> {
        let variables = {
            "conversation_id": conversation_id,
            "state": 'DELETED'
        };

        this.delete(this.baseLambdaUrl + '/conversations/' + conversation_id).subscribe();

        return this._apolloClientAssist.mutate({
            mutation: MainChatRoomAssistService.DELETE_CONVERSATION,
            variables: variables
        });
    }
    



    setStateConversation(variables): Observable<any> {
        return this._apolloClientAssist.mutate({
            mutation: MainChatRoomAssistService.SET_UNREADED_MESSAGES,
            variables: variables
        });
    }




    isJsonString(strJson: string): boolean {
        // Validar JSON
        try {
            JSON.parse(strJson);
        } catch (e) {
            return false;
        }
        return true;
    }


    search_user(search_string): Observable<any> {
        return this.http.get('https://api.edutin.com/b/d/users/search?search_string="' + search_string + '"&start=0', this.headers);
    }
}
