import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UrlLambdaApi } from '../models/models';
import { AppService } from 'src/app/app.service';
import { ClassroomService } from './classroom.service';


@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  public evaluation: any;
  answers_notificate = [];
  public editAnswer = [];
  public forums: any = null;
  public evaluations: any = null;
  public displayModalQuizEvaluation: boolean = false;
  public disable_btn_send_activity: boolean = true;

  constructor(
    private http: HttpClient,
    private _appService: AppService,
    private _classroomService: ClassroomService
  ) { }


  getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })

    return ({ headers: headers });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log('=========ERROR==========');
      console.log(error); // log to console instead
      //(error.error); // log to console instead      
      console.log('=========ERROR==========');
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  private log(message: string) {
    //(`ClassroomService: ${message}`);
  }

  getFeedbacks(record_id): Observable<any> {

    return this.http.get(`${UrlLambdaApi}/feedbacks?record_id=${record_id}`, this.getHeadersLambda())
      .pipe(
        map((feedbacks: any) => {
          const { data, status } = feedbacks;
          let data_return = [];
          if (status) {
            data_return = JSON.parse(JSON.stringify(data));
          }
          return data_return
        }),
        catchError(this.handleError<any>('getFeedbacks', []))
      );
  }


  getChildrenFeedbacks(feedback_id): Observable<any> {

    return this.http.get(`${UrlLambdaApi}/children_feedbacks?feedback_id=${feedback_id}`, this.getHeadersLambda())
      .pipe(
        map((children_feedbacks: any) => {
          const { data, status } = children_feedbacks;
          let data_return = [];
          if (status) {
            data_return = JSON.parse(JSON.stringify(data));
          }
          return data_return
        }),
        retry(3),
        catchError(this.handleError<any>('getFeedbacks', []))
      );
  }


  addChildrenFeedback(url, data): Observable<any> {

    return this.http.post(UrlLambdaApi + url, { data }, this.getHeadersLambda())
      .pipe(
        catchError(this.handleError('addChildrenFeedback'))
      );

  }



  item_index = 0;
  edit_files_length = 0;
  // edit_comment;

  editItem = (data_item, put_url) => new Observable((observer) => {

    // put_url can be record_edit or feedback_edit

    if (put_url == 'record_edit') {
      data_item.Files = [];
      data_item.Quiz.Question.forEach(question => {

        question.Records.forEach(record => {
          if (record.id == '0') record.question_id = question.id;
          data_item.Files.push(record);
        });

      });
    }

    // this.edit_comment = JSON.parse(JSON.stringify(data_item));

    const { Files } = data_item;
    this.edit_files_length = Files.length;


    let X = {

      addNewFile: (data): Observable<any> => {
        let File = JSON.parse(JSON.stringify(Files[this.item_index]));
        let url_post = (put_url == "feedback_edit") ? `/children_feedbacks/${data_item.id}/files` : `/records`;

        if (url_post == '/records') {
          const { description, file_key, file_ext, file_name, language, link, type, question_id } = File;

          let tmp_data = {
            file_name,
            file_ext,
            description,
            language,
            link,
            file_url: file_key,
            attempt_id: data_item.Quiz.attempt_id,
            question_id,
            type,
            user_id: this._classroomService.data.certification.User.id,
            certification_id: this._classroomService.data.certification.Certification.id
          };

          File = JSON.parse(JSON.stringify(tmp_data));

        }

        if (put_url == "feedback_edit") {
          File.user_id = this._classroomService.data.certification.User.id;
        }

        delete File.id;
        delete File.state;

        // return data
        return this.http.post(`${UrlLambdaApi}${url_post}`, { data: File }, this.getHeadersLambda())
          .pipe(map((response: any) => {

            // if (response.status) {
            //   response.data = JSON.parse(JSON.stringify(tmp_item));
            // }
            return response
          }),
            catchError((error: any) => {
              this.handleError('add_new_item');
              return of({ status: 'error', error });
            })
          );
      },

      editFile: (file, url): Observable<any> => {

        const { description, file_key, file_ext, file_name, language, link, type } = file;

        let tmp_item = {
          description,
          file_ext,
          file_name,
          language,
          link,
          type
        }
        if (file_key != null && file_key != '') tmp_item['file_url'] = file_key;

        return this.http.put(`${UrlLambdaApi}${url}`, { data: tmp_item }, this.getHeadersLambda())
          .pipe(
            map((response: any) => {

              if (response.status) {
                response.data = JSON.parse(JSON.stringify(tmp_item));
              }
              return response
            }),
            catchError((error: any) => {
              this.handleError('edit_item');
              return of({ status: 'error', error });
            })
          );
      },

      deleteFile: (url_delete) => new Observable((observer_delete) => {
        this.delete(url_delete).subscribe(resp => {
          observer_delete.next(resp);
          observer_delete.complete();
        })
      }),


      setResponse: (response) => {
        if (response.status === true) {

          this.item_index++;

          if (this.item_index < this.edit_files_length) {
            X.chooseEvent();
          }

          if (this.item_index == this.edit_files_length) {

            let Records = Files.filter(record => record.state != -1);


            let resp = {
              status: true,
              data: JSON.parse(JSON.stringify(Records))
            }
            this.item_index = 0;
            this.edit_files_length = 0;
            observer.next(resp);
            observer.complete();
          }
        }

        if (!response.status || response.status == 'error') {

          let resp = {
            status: false,
          }

          this.item_index = 0;
          this.edit_files_length = 0;

          observer.next(resp);
          observer.complete();

        }
      },

      chooseEvent: () => {

        const { state } = Files[this.item_index];

        if (state == 2 || state == '2') {

          if (Files[this.item_index].id != null && Files[this.item_index].id != '' && Files[this.item_index].id != '0') {

            //if is a file edited
            let File = JSON.parse(JSON.stringify(Files[this.item_index]));

            let url = '';
            if (put_url == 'feedback_edit') url = `/children_feedbacks/${File.children_feedback_id}/files/${File.id}`;
            if (put_url == 'record_edit') url = `/records/${File.id}`;

            // delete File.id;
            // delete File.state;

            X.editFile(File, url).subscribe(async (editResponse: any) => {
              X.setResponse(editResponse);
            })

          }
          if (Files[this.item_index].id == null || Files[this.item_index].id == '' || Files[this.item_index].id == '0') {
            //if is a new file
            X.addNewFile(Files[this.item_index]).subscribe(addResponse => {

              Files[this.item_index] = JSON.parse(JSON.stringify(addResponse.data))
              X.setResponse(addResponse);

            });

          }

        } else if (state == "-1" || state == -1) {
          //if the file is deleted

          let File = JSON.parse(JSON.stringify(Files[this.item_index]));

          let url = '';
          if (put_url == 'feedback_edit') url = `/children_feedbacks/${File.children_feedback_id}/files/${File.id}`;
          if (put_url == 'record_edit') url = `/records/${File.id}`;

          X.deleteFile(url).subscribe(async (deleteResponse: any) => {
            X.setResponse(deleteResponse);

          });

        } else {
          X.setResponse({ status: true });
        }

      }

    }


    X.chooseEvent();


  });


  delete(url_delete): Observable<any> {
    return this.http.delete(`${UrlLambdaApi}${url_delete}`, this.getHeadersLambda())
      .pipe(

        catchError((error: any) => {
          this.handleError('delete');
          return of({ status: 'error', error });
        })
      );
  }



  quizEvaluate(data): Observable<any> {
    return this.http.post(`${UrlLambdaApi}/quizzes`, { data }, this.getHeadersLambda())
      .pipe(
        map((response: any) => {
          return response
        }),
        catchError(this.handleError('quizEvaluate'))
      );
  }



  getEvaluations(is_forums): Observable<any> {

    const { curso_id } = this._classroomService.params.config;

    let url = '';
    if (this._classroomService.data.actions) {
      if (is_forums == true) {
        //Si viene de la comunidad (Foros)
        url = `/evaluations/attempts?curso_id=${curso_id}&type=1`;
      } else {
        url = `/evaluations/attempts?curso_id=${curso_id}`;
      }
    } else {

      if (is_forums == true) {
        //Si viene de la comunidad (Foros)
        url = `/evaluations?curso_id=${curso_id}&type=1`;
      } else {
        url = `/evaluations?curso_id=${curso_id}`;
      }
    }

    return this.http.get(`${UrlLambdaApi}${url}`, this.getHeadersLambda())
      .pipe(map((activities: any) => {

        const { data, status } = activities;
        let data_return = [];

        if (status) {
          data_return = JSON.parse(JSON.stringify(data));
        }

        return activities.data
      }),
        catchError(error => {
          this.handleError<any>('getEvaluations', [])
          return of([])
        })
      );
  }



  ngOnDestroy() {
    this.forums = null;
    this.evaluations = null;
  }


}



