import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChatRoomComponent } from './chat-room.component';
import { ChatRoomService } from './chat-room.service';
import { TimeTransform, CutUserNamePipe, RoleName, LabelColorPipe, ChatRoleName } from './chat-pipe.pipe';
import { HttpClientModule } from '@angular/common/http';
import { SafePipe } from './chat-room.pipe';
import { ClassroomSharedModule } from 'src/app/classroom/shared-classroom/classroom-shared.module';
import { AudioPlayerModule } from './audio-player/audio-player.module';

import { PreviewUrlService } from '../../service/preview-url.service';
import { SharedPreviewLinkModule } from '../../shared-preview-link.module';
import { MessageModule } from '../../message.module';


@NgModule({
    declarations: [
        ChatRoomComponent,
        TimeTransform,
        CutUserNamePipe,
        RoleName,
        SafePipe,
        LabelColorPipe,
        ChatRoleName
    ],
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ClassroomSharedModule,
        AudioPlayerModule,
        SharedPreviewLinkModule,
        MessageModule,
    ],
    exports: [
        ChatRoomComponent,
    ],
    providers: [
        ChatRoomService,
        PreviewUrlService,
    ],
    bootstrap: [
        ChatRoomComponent,
    ],
    entryComponents: [
        ChatRoomComponent,
    ]
})
export class ChatRoomModule { }
