import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({ name: 'fileTypeName' })
export class FileTypeName implements PipeTransform {

  sources = ['Introducción', 'Desarrollo', 'Discusión', 'Recurso', 'Conclusión', 'Evaluación', 'Publicidad'];

  transform(type): string {
    return this.sources[parseInt(type)];
  }
}

@Pipe({ name: 'classIconSource' })
export class ClassIconSource implements PipeTransform {

  classes = ['icon-text-source', 'icon-text-source', 'icon-text-source', 'icon-text-source', 'icon-text-source', 'icon-evaluacion-source', 'icon-select-arrow'];

  transform(source): string {
    let class_name;
    if (source.is_video == '1') {
      class_name = 'icon-play-arrow';
    } else {
      class_name = this.classes[parseInt(source.type)];
    }

    if (source.hasOwnProperty('isForum') && source.isForum == true) {
      class_name = 'icon-forum';
    }

    return class_name;
  }
}



@Pipe({ name: "dateFormat" })
export class DateFormat implements PipeTransform {
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  transform(_date: Date): string {
    let date = new Date(_date);
    let day = date.getUTCDate();
    let month = date.getUTCMonth();
    let year = date.getUTCFullYear();
    let date_ = day + ' de ' + this.months[month] + ' del ' + year;
    return date_;
  }
}



@Pipe({ name: "sortSourcesByOrder" })
export class SortSourcesByOrder implements PipeTransform {
  transform(array: any[], field?: any): any[] {
    array.sort(function (a, b) {
      return (parseInt(a.order) < parseInt(b.order)) ? -1 : (parseInt(a.order) > parseInt(b.order)) ? 1 : 0;
    });
    return array;
  }
}


@Pipe({ name: 'timeTransform' })
export class TimeTransform implements PipeTransform {
  transform(time: number): string {
    var horas = Math.floor(time / 3600);
    var minutos = Math.floor((time - (horas * 3600)) / 60);
    var segundos = Math.round(time - (horas * 3600) - (minutos * 60));
    return (horas < 10 ? '0' : '') + horas + ':' + (minutos < 10 ? '0' : '') + minutos + ':' + (segundos < 10 ? '0' : '') + segundos;
  }
}




@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
  transform(username: string, length: string): string {

    username = username.toLocaleLowerCase();
    let split_username_length = username.trim().split(/\s+/).length;
    var un = username.split(' ');
    switch (length) {
      case '1':
        var user_name = un[0];
        break;
      case '2':
        var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
        break;
    }
    let name = '';
    user_name.split(' ').forEach(U => {
      name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
    });

    return name;

  }
}

@Pipe({ name: "sortByPriority" })
export class SortByPriorityPipe implements PipeTransform {
  transform(array: any[], field?: any): any[] {
    array.sort((a, b) => {
      return (a.prioridad > b.prioridad) ? -1 : (a.prioridad < b.prioridad) ? 1 : 0;
    });
    return array;
  }
}


@Pipe({ name: 'safePipe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({ name: 'safeHtmlPipe' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}



@Pipe({ name: 'orderRandom' })
export class OrderRandom implements PipeTransform {

  transform(array): string {
    array.sort(() => Math.random() - 0.5);
    return array;
  }

}

@Pipe({ name: 'setTarget_blank' })
export class SetTarget_blank implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(text) {
    return this.sanitizer.bypassSecurityTrustHtml(("" + text).replace(/<a\s+href=/gi, '<a class="title" target="_blank" href='));
  }
}


@Pipe({ name: "avatarUrlPipe" })
export class AvatarURLPipe implements PipeTransform {

  transform(user: any): any {
    if (user) {
      let url;
      let urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
      if (user.image == '') {
        if (user.role == 'propietario') {
          url = urlavatar + 'professor/small_imagen.jpg';
        } else {
          url = urlavatar + 'default/small_imagen.jpg';
        }
      } else {
        url = urlavatar + user.id + '/small_' + user.image;
      }

      // answer_comment.User.image == '' ? answer_comment.User.role == 'propietario' ? urlavatar+'professor/small_imagen.jpg' : urlavatar+'default/small_imagen.jpg' : urlavatar+answer_comment.User.id+'/small_' + answer_comment.User.image


      return url;
    }
  }

}


@Pipe({ name: 'roleName' })
export class RoleName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case 'premium':
      case 'free':
        return 'estudiante'
        break;
      // return 'estudiante oficial'
      // break;
      case 'propietario':
        return 'profesor'
        break;
      default:
        return role
        break;
    }
  }
}


@Pipe({ name: "sortCommentByClosestDate" })
export class SortCommentByClosestDate implements PipeTransform {

  transform(array: any): any {
    if (array) {
      array.sort((a: any, b: any) => {
        let date1: Date = new Date(a.ChildrenFeedback.date);
        let date2: Date = new Date(b.ChildrenFeedback.date);
        return (date1 > date2) ? -1 : (date1 < date2) ? 1 : 0;
      });
      return array;
    }
  }

}



@Pipe({ name: "sortByRecordID" })
export class SortByRecordID implements PipeTransform {
  transform(array: any[], field?: any): any[] {
    array.sort((a, b) => {
      return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0;
    });
    return array;
  }
}



@Pipe({ name: "toFixed" })
export class ToFixed implements PipeTransform {
  transform(number) {
    number = parseFloat(number).toFixed(0)
    return number;
  }
}

@Pipe({ name: 'timeAgoPipe' })
export class TimeAgoPipe implements PipeTransform {
  transform(fecha): string {

    let date = +new Date(fecha);
    var seconds = Math.floor((+new Date() - date) / 1000);
    seconds = Math.abs(Math.abs(seconds) - (new Date(date).getTimezoneOffset()) * 60)

    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + (Math.floor(interval) > 1 ? " años" : " año");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + (Math.floor(interval) > 1 ? " meses" : " mes");
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + (Math.floor(interval) > 1 ? " dias" : " dia");
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + (Math.floor(interval) > 1 ? " horas" : " hora");
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + (Math.floor(interval) > 1 ? " minutos" : " minuto");
    }
    return Math.floor(seconds) + (Math.floor(seconds) > 1 ? " segundos" : " segundo");


  }

}


@Pipe({ name: 'timeTransformPipe' })
export class TimeTransformPipe implements PipeTransform {

  transform(time) {

    let duration: string;
    if ((time / 3600) < 1) {
      // lesson.duration = (lesson.duration);
      duration = Math.round(time / 60) + 'm';
    } else {
      // lesson.duration = (lesson.duration);
      duration = Math.round(time / 3600) + 'h'
    };


    return duration;
  }
}



@Pipe({ name: 'getProgress' })
export class GetProgress implements PipeTransform {

  transform(lesson) {
    lesson.duration = lesson.progress = 0;


    if (lesson.hasOwnProperty('Source')) {

      lesson.Source.forEach((source, i_source) => {
        source.i_source = i_source;
        source.duration = parseInt(source.time_add);
        lesson.duration = lesson.duration + parseInt(source.time_add);


        if (source.Recent != null) {

          let tmp_time: any = 0;

          if (source.type == 5) {

            let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;
            source['sourceCompleted'] = source_hasAttemps;

            tmp_time = source_hasAttemps ? source.time_add : source.Recent.time;

          } else {

            source['sourceCompleted'] = source.Recent.state == '1' ? true : false;
            tmp_time = source.Recent.state == '1' ? source.time_add : source.Recent.time;

          }


          source.time = parseInt(source.Recent.time);


          if (source.is_video == 1 && tmp_time > 0) {

            let tmp_duration_split = source.file_duration.split(':');
            let duration_in_seconds = (parseInt(tmp_duration_split[0]) * 3600) + (parseInt(tmp_duration_split[1]) * 60) + parseInt(tmp_duration_split[2]);

            if (source.time_add > duration_in_seconds) {

              var difference = Math.abs(source.time_add - duration_in_seconds);
              tmp_time = parseInt(tmp_time) + difference;
              lesson.duration = lesson.duration + difference;

            }

          }

          lesson.progress = lesson.progress == null ? parseInt(tmp_time) : (lesson.progress + parseInt(tmp_time));


        } else {
          source.time = 0;
        }


      });

    }

    if ((lesson.duration / 3600) < 1) {
      lesson.duration = (lesson.duration);
      lesson.durationInText = Math.round(lesson.duration / 60) + 'm';
    } else {
      lesson.duration = (lesson.duration);
      lesson.durationInText = Math.round(lesson.duration / 3600) + 'h'
    };

    if (lesson.progress == null) lesson.progress_percentaje = 0;

    lesson.progress_percentaje = ((lesson.progress / lesson.duration) * 100) > 99 ? 100 : ((lesson.progress / lesson.duration) * 100);

    if (isNaN(lesson.progress_percentaje)) {
      lesson.progress_percentaje = 0;
    }

    return Math.round(lesson.progress_percentaje);
  }

}



@Pipe({ name: 'courseProgress' })
export class CourseProgress implements PipeTransform {

  transform(modules, lesson) {
    //Probando recalculo del progreso:

    let course_duration = 0;
    let course_progress = 0;

    modules.forEach((module_, m_index) => {

      module_.duration = module_.progress = 0;

      module_.Clase.forEach((lesson, l_index) => {

        lesson.duration = lesson.progress = 0;
        lesson.Source.forEach((source, i_source) => {
          source.i_source = i_source;

          source.duration = parseInt(source.time_add);
          lesson.duration = lesson.duration + parseInt(source.time_add);

          if (source.Recent != null) {

            let tmp_time: any = 0;

            if (source.type == 5) {

              let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;
              source['sourceCompleted'] = source_hasAttemps;

              tmp_time = source_hasAttemps ? source.time_add : source.Recent.time;

            } else {

              source['sourceCompleted'] = source.Recent.state == '1' ? true : false;
              tmp_time = source.Recent.state == '1' ? source.time_add : source.Recent.time;

            }

            source.time = parseInt(source.Recent.time);

            if (source.is_video == 1 && tmp_time > 0) {
              /*
                Ingresa aquí cuando es un video para poder calcular su duración correspondiente dentro del contenido
              */
              let tmp_duration_split = source.file_duration.split(':');
              let duration_in_seconds = (parseInt(tmp_duration_split[0]) * 3600) + (parseInt(tmp_duration_split[1]) * 60) + parseInt(tmp_duration_split[2]);

              if (source.time_add > duration_in_seconds) {
                /*
                  duration_in_seconds = corresponde a la duración real del video
                  time_add = corresponde a la duración asignada al recurso en video, puede ser mayor o igual al del video según como lo asigne el profesor
                */

                if (tmp_time == source.time_add) {
                  //Ya terminó de ver el video, mantiene el tiempo acumulado
                } else {
                  tmp_time = (parseInt(tmp_time) * parseInt(source.time_add)) / duration_in_seconds;
                }
              }
            }

            lesson.progress = lesson.progress == null ? parseInt(tmp_time) : (lesson.progress + parseInt(tmp_time));

          } else {
            source.time = 0;
          }



        });

        if (lesson.progress == null) lesson.progress_percentaje = 0;

        lesson.progress_percentaje = ((lesson.progress / lesson.duration) * 100) > 99 ? 100 : ((lesson.progress / lesson.duration) * 100);

        if (isNaN(lesson.progress_percentaje)) {
          lesson.progress_percentaje = 0;
        }

        module_.duration += lesson.duration;
        module_.progress += lesson.progress;

        if (l_index == (module_.Clase.length - 1)) {
          course_duration += module_.duration;
          course_progress += module_.progress;
        }

      });//end lesson foreach      

    });//end module foreach    

    let course_progress_percentaje = ((course_progress / course_duration) * 100) > 99 ? 100 : ((course_progress / course_duration) * 100);

    // return Math.round(course_progress_percentaje);
    return { course_progress_percentaje, modules }
  }

}




@Pipe({ name: 'moduleProgress' })
export class ModuleProgress implements PipeTransform {

  transform(module, lesson) {
    //Probando recalculo del progreso:

    module.duration = module.progress = 0;

    module.Clase.forEach((lesson, l_index) => {

      lesson.duration = lesson.progress = 0;

      if (lesson.hasOwnProperty('Source')) {
        lesson.Source.forEach((source, i_source) => {
          source.i_source = i_source;

          source.duration = parseInt(source.time_add);
          lesson.duration = lesson.duration + parseInt(source.time_add);

          if (source.Recent != null) {

            let tmp_time: any = 0;

            if (source.type == 5) {

              let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;
              source['sourceCompleted'] = source_hasAttemps;

              tmp_time = source_hasAttemps ? source.time_add : source.Recent.time;

            } else {

              source['sourceCompleted'] = source.Recent.state == '1' ? true : false;
              tmp_time = source.Recent.state == '1' ? source.time_add : source.Recent.time;

            }

            source.time = parseInt(source.Recent.time);

            if (source.is_video == 1 && tmp_time > 0) {

              let tmp_duration_split = source.file_duration.split(':');
              let duration_in_seconds = (parseInt(tmp_duration_split[0]) * 3600) + (parseInt(tmp_duration_split[1]) * 60) + parseInt(tmp_duration_split[2]);

              if (source.time_add > duration_in_seconds) {

                var difference = Math.abs(source.time_add - duration_in_seconds);
                tmp_time = parseInt(tmp_time) + difference;
                lesson.duration = lesson.duration + difference;

              }

            }


            lesson.progress = lesson.progress == null ? parseInt(tmp_time) : (lesson.progress + parseInt(tmp_time));


          } else {
            source.time = 0;
          }
        });
      }
      if (lesson.progress == null) lesson.progress_percentaje = 0;

      lesson.progress_percentaje = ((lesson.progress / lesson.duration) * 100) > 99 ? 100 : ((lesson.progress / lesson.duration) * 100);
      //lesson.progress_percentaje = equivale al valor en porcentaje del progreso de la clase (suma de los time de cada source)

      if (isNaN(lesson.progress_percentaje)) {
        lesson.progress_percentaje = 0;
      }

      module.duration += lesson.duration;
      module.progress += lesson.progress;

    });//end lesson foreach


    let module_progress_percentaje = ((module.progress / module.duration) * 100) > 99 ? 100 : ((module.progress / module.duration) * 100);

    return Math.round(module_progress_percentaje);
  }

}

//calcular fecha de vencimiento del certificado
@Pipe({ name: 'scholarshipPipe' })
export class ScholarshipPipe implements PipeTransform {

  transform(certification, scholarship) {

    let name = scholarship.Country.name.split(' ');

    let full_name = '';
    name.forEach(word => {
      full_name += ' ' + word.charAt(0).toUpperCase() + word.toLocaleLowerCase().slice(1);
    });

    scholarship.Country.name = full_name;
    // scholarship.Country.name.charAt(0).toUpperCase() + scholarship.Country.name.toLocaleLowerCase().slice(1);

    let msj = '';

    var fecha = new Date(certification.Certification.fecha_inscripcion); //fecha de creación del certificado    
    var hoy = new Date();
    fecha.setDate(fecha.getDate() + 7); //7 dias a partir de la creación del certificado es la duración del descuento

    // fecha.setMonth(fecha.getMonth() + 1); //30 dias a partir de la creación del certificado es la duración del descuento

    let value = null;

    if (fecha < hoy) {

    } else {
      //('si mostrar notificacion')
      const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      scholarship.vencimiento = fecha.getDate() + ' de ' + monthNames[fecha.getMonth()];

      // let flag_url = `https://countryflags.io/${scholarship.Country.short_name}/flat/32.png`;
      let flag_url = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${scholarship.Country.short_name.toLocaleLowerCase()}.svg`;

      // msj = `                            
      //         <p> 
      //           &nbsp;<img class="flag" width="20" height="20" src="${flag_url}">&nbsp; 
      //         <h2> ${scholarship.Country.discount}% off </h2> en ${scholarship.Country.name} hasta el ${scholarship.vencimiento}
      //         </p>`;



      value = {
        flag_url: flag_url,
        discount: scholarship.Country.discount,
        country_name: scholarship.Country.name,
        fecha_vencimiento: scholarship.vencimiento
      }
    }


    return value;
  }
}



@Pipe({ name: 'leftPosition' })
export class LeftPositionPipe implements PipeTransform {

  transform(data, width) {

    // const { offsetLeft, offsetWidth } = data.scontainer;
    // let calc = offsetLeft + offsetWidth;

    let position = -80;


    if (width > 945) {
      position = -80;
      position - 400;
    } else if (width > 681) {
      position = - 60;
    } else if (width > 640) {
      position = - 55;
    } else if (width > 600) {
      position = - 52;
    } else {
      position = 0;
    }


    return position + 'px';
  }
}


@Pipe({ name: 'topPosition' })
export class TopPositionPipe implements PipeTransform {

  transform(data) {

    let parent = data.childNodes[3];
    let child = parent.querySelector('.lesson_label')

    const { bottom } = child.getBoundingClientRect();
    // let calc = offsetLeft + offsetWidth;

    let position = bottom + 55;

    return position + 'px';
  }
}


@Pipe({ name: 'numberFormat' })
export class NumberFormatPipe implements PipeTransform {
  transform(x) {
    let number = x.toString().replace(/^0+/, '').replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    return number;
  }
}

@Pipe({ name: 'leftPositionTutorialPipe' })
export class LeftPositionTutorialPipe implements PipeTransform {
  transform(modalTutorial, width_size) {

    let left = '0px';

    let my_courses_item = document.querySelector('.my-courses').getBoundingClientRect();
    if (my_courses_item != null) {

      let tutorial_mycourses: HTMLElement = document.querySelector('.modal_container.my_courses');
      // tutorial_mycourses.style.left = my_courses_item.left + 'px';

      // if (this._classroomService.data.width_size < 600) {
      //   tutorial_mycourses.style.left = '14px';
      // }

    }
    return my_courses_item.left + 'px';
  }
}


@Pipe({ name: 'quizProgressPipe' })
export class QuizProgressPipe implements PipeTransform {
  transform(question_index, question_length) {

    let progress = ((question_index + 1) * 100) / question_length;

    return progress + '%';
  }
}

@Pipe({ name: 'questionDescriptionPipe' })
export class QuestionDescriptionPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(description) {


    let desc = description.toLowerCase().replace(/(<([^>]+)>)/gi, "");
    let desc_return = description;

    if (
      desc == 'suba su respuesta aqui: '
      || desc == 'suba su respuesta aqu&iacute'
      || desc == 'suba aqu&iacute; su soluci&oacute;n&nbsp;'
      || desc == 'suba aqu&iacute; la soluci&oacute;n de su actividad'
      || desc == '.'
      || desc.length < 4
    ) {
      // ? '' :  question.descripcion
      desc_return = ''
    }

    // return desc_return;
    return this.sanitizer.bypassSecurityTrustHtml(desc_return);
  }
}



@Pipe({ name: 'sourceCircleProgressPipe' })
export class CourceCircleProgressPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  transform(progress) {

    // let progress = ((question_index + 1) * 100) / question_length;

    let prog = `linear-gradient(#2A79FF 0 0) left/${progress + '%'} 100% no-repeat #dbdcef`;

    console.log('🚀 ~ CourceCircleProgressPipe ~ transform ~ prog', prog)
    return this._sanitizer.bypassSecurityTrustStyle(prog);
  }
}

