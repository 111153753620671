import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ClassroomService } from '../../../services/classroom.service';


@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {

  constructor(
    public _classroomService: ClassroomService,
  ) { }

  items;
  @Input() actions;
  @Input() current_user_data;
  @Input() comment_list;
  @Input() _comment;
  @Input() id_textarea;
  @ViewChild('commentNew') commentNew;
  @ViewChild('commentEdited') commentEdited;
  @Output() addOpinion = new EventEmitter();
  @Output() eventOpinion = new EventEmitter();
  @Output() deleteOpinion = new EventEmitter();



  comment_to_edit;
  urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

  lastIndex = 0;
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('comment_list')) {

      if (this.lastIndex <= 5) {

        //Si es primer vez que entra carga los primeros 5

        this.lastIndex = 0;

        let tmp_items = [];
        let iterations = this.comment_list.length < 5 ? this.comment_list.length : 5;
        for (let i = 0; i < iterations; i++) {
          tmp_items.push(this.comment_list[this.lastIndex]);
          this.lastIndex++;
        }

        this.items = tmp_items;
      } else {

        //Si se está actualizando el json, se cargan la cantidad que ya se habia cargado               
        let tmp_items = [];

        let index = (this.lastIndex + 1) <= this.comment_list.length ? (this.lastIndex + 1) : this.lastIndex;

        for (let i = 0; i < index; i++) {

          tmp_items.push(this.comment_list[i]);

          if (i == (this.lastIndex - 1)) {

            this.items = JSON.parse(JSON.stringify(tmp_items));

          }
        }

      }



    }
  }

  loadMore() {
    if (this.lastIndex != this.comment_list.length) {

      let faltante = (this.comment_list.length - this.lastIndex);
      let iterations_ = faltante < 5 ? faltante : 5;
      for (let i = 0; i < iterations_; i++) {
        this.items.push(this.comment_list[this.lastIndex]);
        this.lastIndex++;
      }

    }
  }

  // ngAfterViewInit() {

  // if (this._comment.description != '' && this._comment.description.length > 35) {
  //   this.setHeight(this.comment_.nativeElement);
  // } else {
  //   this.comment_.nativeElement.style.height = "30px";
  // }
  // }



  sending_comment: boolean = false;

  sendComment() {

    if (!this.sending_comment) {

      // Actualizar las estadisticas del usuario cada vez que haga una opinion by Jesus
      const curso_id = this._classroomService.data.course.id
      const certification_id = this._classroomService.data.certification.Certification.id
      this._classroomService.saveStatistics(curso_id, certification_id, 'com_comment')
      // Fin

      this.sending_comment = true;
      if (this._comment.description == '') {
        this.eventOpinion.emit();
        this.sending_comment = false;
      } else {
        this.addOpinion.emit(this._comment);
        setTimeout(() => {
          this.sending_comment = false;
          this._comment.description = '';
          this.commentNew.nativeElement.innerHTML = '';

        }, 500);
      }

    }

  }


  editComment(event) {

    event.type = 'answer-edit';
    this.comment_to_edit = event;

    setTimeout(() => {
      this.commentEdited.nativeElement.innerHTML = this.comment_to_edit.description;
    }, 250);

  }

  closeEditComment() {

    this.comment_to_edit = null;

  }


  sending_edit_comment: boolean = false;

  sendEditedComment(opinion) {

    if (!this.sending_edit_comment) {
      this.sending_edit_comment = true;

      if (opinion.Opinion.description == '') {

        this.eventOpinion.emit();
        this.sending_edit_comment = false;

      } else {

        opinion.Opinion.url = '/opinions_comments_edit.json';
        this.addOpinion.emit(opinion.Opinion);
        setTimeout(() => {
          this.comment_to_edit = null;
          this.sending_edit_comment = false;
          this.commentEdited.nativeElement.innerHTML = '';
        }, 500);

      }
    }

  }





  deleteComment(opinion) {
    this.deleteOpinion.emit(opinion.Opinion);
  }



  pasteSomething(el) {
    //Solo permitir que pegue texto plano 
    el.preventDefault()
    var text = el.clipboardData.getData('text/plain')
    document.execCommand('insertText', false, text)
  }


}
