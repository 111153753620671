import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UrlLambdaApi } from 'src/app/classroom/models/models';
import { UserData } from './auth.models';
import { AuthService } from './auth.service';

/**
 * Este servicio se encarga de gestionar los datos que se manejan dentro del Profile de un estudiante
 */
@Injectable({
  providedIn: 'root'
})
export class ProfileService {


  constructor(
    private http: HttpClient,
    private _appService: AppService,
    private _authService: AuthService,
  ) { }

  // Variables
  public restricted: Boolean = true
  public userIdInRoute: any;
  public userData: UserData;

  // Loaders
  LoadingUserInfo = true;
  LoadingResume = true;


  getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })

    return ({ headers: headers });
  }

  getHeadersKlazify(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZGNmZDE1MmQ2OGU1NmEwNGJmN2UyZmJmYjRkMjk5M2U0NTkxNzJkOWYwZjg1N2I0MjBjZTcxOTE5YTM1NzJiODJiMTgzOWNjMTBkZTJkYWEiLCJpYXQiOjE2NTk1NDEzNjksIm5iZiI6MTY1OTU0MTM2OSwiZXhwIjoxNjkxMDc3MzY5LCJzdWIiOiI2OTE0Iiwic2NvcGVzIjpbXX0.aAv7mRFnUuPCiPPfTlfEU68Yf9Pl8LK-gCA0GYc96G5sqMXSYvQLizg_8HNmG2YKjvclnPJyZCFYgU-BjhaKPA"
    })

    return ({ headers: headers });
  }

  getUserProjects(): Observable<any> {
    const id = this.userIdInRoute;

    return this.http.get(`${UrlLambdaApi}/users/records?user_id="${id}"`, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { data, status } = response;
        let data_return = [];

        if (status) data_return = JSON.parse(JSON.stringify(data));

        return data_return
      }),
      retry(3),
      catchError(this.handleError<any>('getUserPtojects', []))
    );
  }

  getUserData(): Observable<any> {

    const id = this.userIdInRoute;

    return this.http.get(`${UrlLambdaApi}/users/${id}/profile`, this.getHeadersLambda())
      .pipe(map((user: any) => {
        const { data, status } = user;
        let data_return = [];

        if (status) {
          data_return = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('getUserData', []))
      );
  }

  getCertificationLogotype(url): Observable<any> {

    const urldomain = url

    return this.http.post(`https://www.klazify.com/api/categorize`, urldomain, this.getHeadersKlazify())
      .pipe(map((response: any) => {
        if (response.success) {
          return { valid: true, print: response.domain.logo_url }
        }
      }),
        catchError(this.handleError<any>('getUserData', []))
      );
  }

  resume: Subject<any> = new Subject<any>();
  userSkills: Subject<any> = new Subject<any>();
  skillsDefaults: Array<any> = []

  getUserResume(): Observable<any> {
    return this.http.get(UrlLambdaApi + `/users/${this.userIdInRoute}/resume`, this.getHeadersLambda()).pipe(
      map((data: any) => {
        if (data.status) {
          this.resume.next(data.data);
          this.skillsDefaults = data.data[0].Defaults
          this.userSkills.next(data.data[0].Skills)
          // console.log("Defaults: ")
          return { valid: true, print: data.data[0] }
        } else {
          this.resume.next(null);
        }
      }),
      retry(3),
      catchError(this.handleError<any>('getResume', []))
    );
  }

  loadingCertification = false;
  getCertifications(all=false): Observable<any> {
    this.loadingCertification = true;
    const id = this.userIdInRoute;

    let url = all ? `${UrlLambdaApi}/users/${id}/certifications` : `${UrlLambdaApi}/users/${id}/profile/certifications?estado=1`;

    return this.http.get(url, this.getHeadersLambda()).pipe(
      map((certifications: any) => {
        const { data, status } = certifications;

        let data_return = [];
        if (status) {
          data_return = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
      retry(5),
      catchError(this.handleError<any>('getCertifications', []))
    );

  }

  pagination: number = 0;
  public learning_item_count: number = 0;
  learningSubject:Subject<any> = new Subject<any>();
  getLearning(key): Observable<any> {

    const id = this.userIdInRoute;
    // const fields = ["progress", "calification", "id", "estado", "tour", "clase_id", "quiz_id", "rating", "source_id"]; &fields=' + JSON.stringify(fields)

    let last_evaluated_key = key != 0 ? '&LastEvaluatedKey=' + JSON.stringify(key) : '';

    return this.http.get(UrlLambdaApi + `/users/${id}/profile/certifications?order_by="fecha"` + last_evaluated_key, this.getHeadersLambda()).pipe(
      map((response: any) => {

        const { data, metadata } = response;

        if (response.status) {
          response.data = response.data.map((item) => {
            let certification = {
              progress: item.Certification.progress,
              calification: item.Certification.calification,
              certification_id: item.Certification.id,
              certification_state: item.Certification.estado,
              id: item.Course.id,
              name: item.Course.nombre,
              state: item.Course.estado,
              time: item.Certification.duracion,
              fecha: item.Certification.fecha,
              fecha_inscripcion: item.Certification.fecha_inscripcion,
            };
            certification = Object.assign(certification, this.dateCertification(item.Certification.fecha_inscripcion));
            return Object.assign(item.Course, certification);
          });



          if (metadata) {
            if (metadata.hasOwnProperty('last_evaluated_key')) {
              // console.log('metadata tiene last_evaluated_key:: ', metadata.last_evaluated_key);
              this.pagination = JSON.parse(JSON.stringify(metadata.last_evaluated_key));
            } else {
              this.pagination = 0;
            }
            if (metadata.hasOwnProperty('item_count')) {

              this.learning_item_count = metadata.item_count;
              // if (data.length === this.learning_item_count) {
              //     console.log('es la misma cantidad de certificados');
              // }
            }
          }

          this.learningSubject.next(response.data)
          return { valid: true, print: response.data };
        } else {
          this.learningSubject.next(null)
          return { valid: false, print: response.data };
        }

      }),
      retry(6),
      catchError(this.handleError<any>('getLearning', []))
    );
  }

  public dateCertification(fecha_inscripcion: string): any {
    let date, date_state;
    let fecha = new Date(fecha_inscripcion.replace(/\s/, 'T'));
    let limit = new Date("2019-11-01");
    let hoy = new Date();
    if (fecha < limit) {
        fecha = limit;
    }
    fecha.setDate(fecha.getDate() + 7);
    // fecha.setMonth(fecha.getMonth() + 1);
    if (fecha < hoy) {
        date_state = false;
    } else {
        date_state = true;
    }
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    date = fecha.getDate() + ' ' + monthNames[fecha.getMonth()] + ' ' + fecha.getFullYear();
    return { date, date_state };
  }

  getRecommendedConnections(curso_id): Observable<any> {

    let url = `/followers/connections?curso_id=${curso_id}`;

    return this.http.get(UrlLambdaApi + url, this.getHeadersLambda()).pipe(
      map((response: any) => {

        const { print, pages, status } = response

        if (response.status) {
          return { print: response.data, valid: response.status }
        } else {
          console.log("Respuesta invalida de la función")
          return { print: response.data, valid: response.status }
        }
      }),
      retry(5),
      catchError(this.handleError<any>('getConnections', []))
    );
  }

  count_followeds = 0;

  /** Establece el estado del follow 
   * 
   * - `null` = Ha cargado el follow
   * - `-1` = No hay follow
   * - `0` = El usuario logueado le envió una solicitud a otro usuario y se encuentra en pendiente
   * - `1` = Se siguen
   * - `2` = El otro le envió una solicitud al usuario logueado y se encuentra en pendiente "Aceptar e ignorar"
   */

  connection_status = null;  

  /**
 * ### GetConnections()
 * @param params los parametros que recibe son:
 * - ``pages: bool`` : Si deseas traer las paginas o no
 * 
 * - ``followed_id: bool`` : Si deseas traer un seguimiento en especifico aquí se puede poner el id de ese usuario
 * 
 * - ``last: LastEvaluatedKey``: Para continuar con la consulta (paginación)
 * 
 * - ``petitioner: number``: Define que tipo de follow vas a obtener:
 * 
 * >**OTHER_USERS_TO_ME:** `0`
 * 
 * >**ME_TO_OTHER_USERS**: `1`
 * 
 * >**ALL:** `-1`
 * 
 * - ``state: int``: Si deseas traer las solicitudes en estado:
 * 
 * >**Pendiente:** `0`
 * 
 * >**Confirmadas**: `1`
 * 
 * >**Rechazadas:** `-1`
 * 
 * **_--Si no se especifica el estado los trae todos--_**
 * ______
 * `pagesNumber` = 10
 * ____
 * @returns An object with the properties ``print`` that contains the data and ``valid`` that contains the status of the response
 */
  getConnections(params): Observable<any> {

    let  {state, petitioner, pages, last, user_id, followed_id} = params
    
    user_id = user_id ? user_id : this._appService.user_data.id;

    let url =  `/followers?user_id=${user_id}${state!=null ? '&state='+state : ''}${petitioner ? '&petitioner='+petitioner : ''}${pages ? '&pages='+pages : ''}${followed_id ? '&followed_id='+followed_id : ''}`
    
    if (last?.hasOwnProperty("followed_id")) url += `&LastEvaluatedKey=${JSON.stringify(last)}`

    return this.http.get(UrlLambdaApi + url, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const {status, data, lastEvaluatedKey} = response

        if (status) {

          if (pages== true) return data[0]
          return { print: data, valid: status, lastEvaluatedKey: lastEvaluatedKey }

        } else {

          return { print: data[0], valid: status }

        }
      }),
      retry(3),
      catchError(this.handleError<any>('getFolloweds', []))
    );
  }

  postResume(data): Observable<any> {
    return this.http.post(UrlLambdaApi + `/users/${this.userIdInRoute}/resume`, data, this.getHeadersLambda()).pipe(
      map((data: any) => {
        if (data.status) {
          // console.log("success: ", data.data)
          return { valid: true, print: data.data[0] }
        } else {
          console.log("Error al guardar los datos")
          return { valid: false, print: data.data[0] }
        }
      }),
      retry(3),
      catchError(this.handleError<any>('postResume', []))
    );
  }

  editUserResume(data): Observable<any> {
    return this.http.put(UrlLambdaApi + `/users/${this.userIdInRoute}/resume`, data, this.getHeadersLambda()).pipe(
      map((data: any) => {
        if (data.status) {
          // console.log("success: ", data.data)
          return { valid: true }
        } else {
          console.log("Error al guardar los datos")
          return { valid: false }
        }
      }),
      retry(3),
      catchError(this.handleError<any>('postResume', []))
    );
  }

  postItem(url_post, data): Observable<any> {
    return this.http.post(`${UrlLambdaApi}${url_post}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('postItem');
        return of({ status: false });
      })
    );
  }

  putItem(url_put, data): Observable<any> {
    return this.http.put(`${UrlLambdaApi}${url_put}`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        this.handleError('putItem');
        return of({ status: false });
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      console.log('%cerror::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead            
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
