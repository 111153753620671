<div class="modal__content__tabs">
  <div class="content_items" #MenuSlider (mousedown)="mousedown_slider($event)" (touchstart)="mousedown_slider($event)"
    (mouseleave)="mouseleave_slider($event)" (mouseup)="mouseup_slider($event)" (touchend)="mouseup_slider($event)"
    (mousemove)="mousemove_slider($event)" (touchmove)="mousemove_slider($event)">

    <!-- <div class="lesson_item" *ngIf="_classroomService.display_a_d_s.display"
    [ngClass]="{'active': !_classroomService.display_a_d_s.hide}" (click)="selectDisplayAds()">
    <span class="item icon-certificate"></span>
  </div> -->

    <div class="slider_item" *ngFor="let option of options" (click)="toggleSidebar(option.option);"
      [ngClass]="{'selected': option_selected == option.option}">
      {{option.label}}
      <div class="unread_counter" *ngIf="option.option == 'community' && _classroomService.unread_messages_counter > 0">
        {{_classroomService.unread_messages_counter}}
      </div>

      <div class="spinner dotted typing_msg_notification" *ngIf="option.option == 'community' && typing_chat">
        <div></div>
        <div></div>
        <div></div>
      </div>

    </div>
  </div>


  <!-- <a class="icon-arrow-right" (click)="moveSlider()"></a> -->
</div>