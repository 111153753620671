import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { ClassroomService } from '../../services/classroom.service';
import { AWSS3Service } from '../awsS3.service';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],

})
export class UploadFileComponent implements OnInit {
  @Input() type: string = '';
  @Input() isNew: boolean = false;
  @Input() index;
  @Input() data_edited_file;
  @Input() bucket;
  @Input() item_type;
  @Output() file_error_emitter = new EventEmitter();
  @Output() closeFile = new EventEmitter();
  @Output() changeDetector = new EventEmitter();
  @ViewChild('TextEditor') textEditor: ElementRef;
  file: any =
    {
      description: '',
      id: '0',
      state: '1',
    };


  validAcceptTypes = {
    image: 'image/*',
    file: '.pdf,.xls,.doc,.docx,.pptx,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,text/html,video/,.rar,.zip,.dwg,.py,.ai,.rvt',
    video: 'video/*'
  }

  file_label = {
    image: 'imagen',
    file: 'archivo',
    video: 'video'
  }

  language_selected: any = 'html';
  textEditorContent: any;
  unique_random_id: string;

  public languages = [
    { lan: 'c_cpp', name: 'C and C++', ext: 'cpp' },
    { lan: 'csharp', name: 'C#', ext: 'cs' },
    { lan: 'css', name: 'Css', ext: 'css' },
    { lan: 'html', name: 'Html', ext: 'html' },
    { lan: 'java', name: 'Java', ext: 'java' },
    { lan: 'javascript', name: 'Javascript', ext: 'js' },
    { lan: 'mysql', name: 'MySQL', ext: 'sql' },
    { lan: 'ruby', name: 'Ruby', ext: 'rb' },
    { lan: 'sql', name: 'SQL', ext: 'sql' },
    { lan: 'sqlserver', name: 'SQLServer', ext: 'sql' },
    { lan: 'swift', name: 'Swift', ext: 'Swift' },
    { lan: 'php', name: 'PHP', ext: 'php' },
    { lan: 'python', name: 'Python', ext: 'py' },
    { lan: 'typescript', name: 'Typescript', ext: 'ts' }
  ];

  public img_formats = ['jpg', 'jpeg', 'png', 'gif'];

  placeholder_text = {
    comment: 'Escriba su pregunta...',
    contribution: 'Escriba su contribución...',
    answer: 'Escriba su respuesta, su compañero agradece su ayuda...',
    aporte: 'Escriba su aporte, la comunidad desea leer lo que quiere compartir...',
    upload: 'Escriba un apunte...',
    activity: 'Escriba su solución de la actividad...'
    // activity: 'Realice la actividad para registrar su aprendizaje y sumar tiempo a su certificado...'
  }

  setTextContent() {
    this.changeDetector.emit('Guardar');
  }



  constructor(
    private _classroomService: ClassroomService,
    private _awsS3Service: AWSS3Service) { }


  ngOnInit() {

    this.unique_random_id = Math.random().toString(36).substr(2, 9);

    if (this._classroomService.data.source) {
      if (this.item_type == 'comment' && this._classroomService.data.source.type == 5) {
        this.placeholder_text.comment = 'Escriba su participación...';
      }

      if (this.item_type == 'answer' && this._classroomService.data.source.type == 5) {
        this.placeholder_text.answer = 'Escriba su opinión...';
      }
    }



    if (this.item_type == 'feedback') {
      this.file =
      {
        description: '',
        id: 0,
        state: 1,
        children_feedback_id: null,
      };
    }


    if (this.data_edited_file) {

      this.file = JSON.parse(JSON.stringify(this.data_edited_file));
      if (this.type == 'image' || this.type == 'file' || this.type == 'video') {
        this.file.progress = 100;
        // this.file.src = `https://s3.amazonaws.com/${this.data_edited_file.file_url}`;
      }

      if (this.type == 'image' && (this.bucket.path == 'File_Uploads' || this.item_type == 'feedback')) {
        this.file.src = (this.bucket.path == 'File_Uploads' || this.file.file_url.includes('edutin/Files_Feedback')) ? this.file.url_download : this.file.file_url;
      } else {
        // this.file.src = 'https://s3.amazonaws.com/' + this.file.file_url;
        // this.file.src = (this.file.file_url != null && this.file.file_url.includes('Record')) ? this.file.file_url : 'https://s3.amazonaws.com/' + this.file.file_url;
        this.file.src = this.file.file_url;
      }

    } else {
      this.file.type = this.type;
      if (this.type == 'image' || this.type == 'file' || this.type == 'video') {
        this.file.file_name = null;
        this.file.file_type = null;
        this.file.file_ext = null;
        this.file.progress = 0;
        this.file.file_key = null;

      }

    }


    if (this.type == "code") {
      this.language_selected = this.file.language ? this.file.language : 'html';
    }


    setTimeout(() => {

      if (this.item_type == 'upload' && this.index == 0) {
        this.textEditor.nativeElement.focus();
      }

    }, 500);


  }


  focused() {
    // if (this.textEditor.nativeElement.innerHTML == `<p style="color:#95A2B5">Escriba un apunte de lo que está aprendiendo y sume tiempo a su certificado...</p>`) {
    //   this.textEditor.nativeElement.innerHTML = ' ';      
    // }
  }


  ngAfterViewInit() {

    if (this.data_edited_file != null && this.data_edited_file.type == 'text') {
      // this.textEditorContent = document.querySelector(".editor_text");
      if (this.data_edited_file) {
        this.textEditor.nativeElement.innerHTML = this.data_edited_file.description;
      }
    }

    setTimeout(() => {
      this.isNew = false;
    }, 1000)

    // if (this.data_edited_file == null && this.type == 'text' && this.index == 0) {
    //   this.textEditor.nativeElement.innerHTML = `<p style="color:#95A2B5">Escriba un apunte de lo que está aprendiendo y sume tiempo a su certificado...</p>`;
    // }

  }



  formatText(command) {
    document.execCommand(command, false, '');
  }



  public close() {
    if (this.type == 'image' || this.type == 'file') {
      const params = { Bucket: this.bucket.name, Key: this.file.file_key };
      this._awsS3Service.getS3Bucket().deleteObject(params, function (err, data) {
        if (err) {
          // console.log('There was an error deleting your file: ', err.message);
          return;
        }
        // console.log('Successfully deleted file.');
      });
    }

    this.closeFile.emit(this.index);
  };






  uploading_file: boolean = false;
  input_element;
  addfile(event) {

    this.file.progress = 0;

    this.input_element = event;


    this.changeDetector.emit('Guardar');
    this.uploading_file = true;
    let fileList: FileList = event.target.files;//obtiene el file  del input html

    let fileInput = event.target;
    let filePath = event.target.value;

    var allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    var allowedFileExtensions = /(\.pdf|\.xls|\.xlsx|\.doc|\.docx|\.ppt|\.pptx|\.txt|\.rar|\.zip)$/i;
    if (this.type == 'image' && !allowedImageExtensions.exec(filePath)) {
      // alert('Please upload file having extensions .jpeg, .jpg, .png, .gif only.');
      this.file_error_emitter.emit(44);
      this.uploading_file = false;
      fileInput.value = '';
      return false;
    } else if (this.type == 'file' && !allowedFileExtensions.exec(filePath)) {
      //('Please upload file with right extension.');
      this.file_error_emitter.emit(43);
      this.uploading_file = false;
      fileInput.value = '';
      return false;
    }
    else {
      //Image preview
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // document.getElementById('imagePreview').innerHTML = '<img src="' + e.target.result + '"/>';
        };
        reader.readAsDataURL(fileInput.files[0]);
      }
    }

    if (fileList.length > 0) {
      this.file.size = fileList[0].size;//obtiene el size del file
      this.file.file = fileList[0].name;

      let config = JSON.parse(sessionStorage.getItem('config'))

      if ((this.file.size <= (1024 * 1024 * 250))) {//valida que el size del file sea <= 250                       
        this.file.file_type = fileList[0].type;//asigna el nombre file             
        this.file.file_ext = fileList[0].name.toLowerCase().split('.').pop();
        var time = new Date().getTime();
        this.file.file_name = `${time}-archivo.${this.file.file_ext}`;
        var aux = this._awsS3Service.generateRandomString();

        let folderKey = '';

        // if (this.bucket.path == 'File_ChildrenComments' || this.bucket.path == 'File_Comments') {
        //   folderKey = `edutin-publico/${this.bucket.path}/${aux}/`;
        // } else {
        folderKey = this.bucket.path == 'File_Uploads' ? `${this.bucket.path}/${config.idu}_${this._classroomService.data.source.id}_${aux}/` : `${this.bucket.path}/${aux}/`;

        // `tmp/${this.bucket.path}/${aux}/`
        // }


        let url = `${window.btoa(this.bucket.name)}/${window.btoa(folderKey + this.file.file_name)}`;

        let send = {
          "bucket": this.bucket.name,
          "key": folderKey + this.file.file_name
        }

        this._awsS3Service.generate_url(url, send, fileList[0]).subscribe((response: any) => {

          const { data, status } = response;

          if (status == false) {
            // console.log('There was an error uploading your file: ', err);
            this.file_error_emitter.emit(8);
            return false;
          }


          let tmp_file = JSON.parse(JSON.stringify(this.file));

          tmp_file.file_key = data.key;
          tmp_file.src = null;


          //Tener en cuenta este cambio a cuales archivos publicos afecta
          //Tener en cuenta que tambien están las evaluaciones  
          tmp_file.file_url = (this.bucket.path == 'File_Uploads' ? 'edutin/' : 'https://edutin-publico.s3.amazonaws.com/') + data.key;
          // tmp_file.file_url = 'edutin/' + data.key;


          tmp_file.progress = 100;

          this.file = JSON.parse(JSON.stringify(tmp_file));

          setTimeout(() => {
            this.uploading_file = false;
          }, 250);


          // if (this.bucket.path == 'File_Uploads' || this.item_type == 'feedback' || this.bucket.path == 'Records') {
          this.file.src = URL.createObjectURL(event.target.files[0]);
          // }

          // let output;
          // output.onload = function () {
          //   URL.revokeObjectURL(output.src) // free memory
          // }

          setTimeout(() => {

            if (this.input_element && this.input_element.target && this.input_element.target.value) {
              this.input_element.target.value = "";
            }
            if (this.input_element && this.input_element.target && this.input_element.target.files) {
              this.input_element.target.files = null;
            }

          }, 1000);

          this.changeDetector.emit('Guardar');


          //   return true;
          // }).on('httpUploadProgress', (percentage) => {
          //   // console.log('httpUploadProgress.', percentage);
          // })

        });

        let fileKey = folderKey + this.file.file_name;
        const params = {
          Bucket: this.bucket.name,
          Key: fileKey,
          Body: fileList[0],
          ContentType: this.file.file_type
        };

        let options = { partSize: 5 * 1024 * 1024, queueSize: 1 };

        // this._awsS3Service.getS3Bucket().upload(params, options, (err, data) => {
        //   if (err) {
        //     // console.log('There was an error uploading your file: ', err);
        //     this.file_error_emitter.emit(8);
        //     return false;
        //   }



        //   let tmp_file = JSON.parse(JSON.stringify(this.file));

        //   tmp_file.file_key = data.Key;
        //   tmp_file.src = null;
        //   tmp_file.file_url = this.bucket.path == 'File_Uploads' ? 'edutin/' + data.Key : data.Location;
        //   // tmp_file.file_url = 'edutin/' + data.Key;
        //   tmp_file.progress = 100;

        //   this.file = JSON.parse(JSON.stringify(tmp_file));

        //   setTimeout(() => {
        //     this.uploading_file = false;
        //   }, 250);


        //   // if (this.bucket.path == 'File_Uploads' || this.item_type == 'feedback' || this.bucket.path == 'Records') {
        //   this.file.src = URL.createObjectURL(event.target.files[0]);
        //   // }

        //   // let output;
        //   // output.onload = function () {
        //   //   URL.revokeObjectURL(output.src) // free memory
        //   // }

        //   setTimeout(() => {

        //     if (this.input_element && this.input_element.target && this.input_element.target.value) {
        //       this.input_element.target.value = "";
        //     }
        //     if (this.input_element && this.input_element.target && this.input_element.target.files) {
        //       this.input_element.target.files = null;
        //     }

        //   }, 1000);

        //   this.changeDetector.emit('Guardar');


        //   return true;
        // }).on('httpUploadProgress', (percentage) => {
        //   // console.log('httpUploadProgress.', percentage);
        // })
      } else {
        this.file.file_name = 'Su archivo supera los 250MB';//le asigna msj de error al  del input file, cuando supero los 250 megas
      }
    } else {
      this.file.file_name = "Adjuntar archivo";
    }


  }


  select_language(ev) {
    this.file.language = this.language_selected;
  }



  cleanFile() {
    this.file.file = null;
    this.file.file_name = null;
    this.file.file_type = null;
    this.file.file_ext = null;
    this.file.progress = 0;
    this.file.file_key = null;
    this.uploading_file = false;

    this.input_element.value = "";
    this.input_element.files = null;
  }


  getFile(): Object {
    //Limpiar input  

    let data_return;
    if ((this.type == 'image' || this.type == 'file' || this.type == 'video') && this.file.file != null) {

      if (this.file.progress == 100) {

        data_return = JSON.parse(JSON.stringify(this.file));

        if (this.input_element && this.input_element.target && this.input_element.target.id) {
          (<HTMLInputElement>document.getElementById(this.input_element.target.id)).value = "";
          (<HTMLInputElement>document.getElementById(this.input_element.target.id)).files = null;
          this.input_element = this.file.file = null;
        }

        this.file.language = this.language_selected;
        delete this.file.progress;
        delete this.file.size;
        delete this.file.file;

        if (this.bucket.path != 'File_Uploads' && this.item_type != 'feedback' && this.bucket.path != 'Records') {
          delete this.file.src;
        }


      } else {
        data_return = false;
      }


    } else {
      data_return = this.file;
    }

    return data_return;
  }


  pasteSomething(el) {
    //Solo permitir que pegue texto plano 
    el.preventDefault()
    var text = el.clipboardData.getData('text/plain')
    document.execCommand('insertText', false, text)
  }


}
