<div id="nav-bar" [ngClass]="_appService.url_route">
  <div class="messages">
    <h4>Messages</h4>
  </div>
  <div class="content">
    <div class="logo">
      <span (click)="goToApp()" class="item">
        <img class="positive-logo" src="https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg"
          alt="Edutin Academy">
        <img class="negative-logo" src="./assets/img/edutin-negative.svg" alt="Edutin Academy">
      </span>
      <span (click)="goToApp()" class="item">
        <div class="logo-label">Edutin Academy</div>
      </span>
    </div>


    <!-- Assist app nabvar elements - init --------------------------------- -->

    <div *ngIf="assist && !statistics" id="search-chats">
      <!-- Search element -->
      <div>
        <input #input_search_users id="search-chats-messages" (keyup)="onTypingSearchAssist($event, input_search_users)"
          type="text" placeholder="Buscar usuario" kl_vkbd_parsed="true">
        <div id="icon-search-in">
          <!-- <i (click)="onTypingSearchAssist($event, input_search_users)" class="fa fa-search" aria-hidden="true"></i> -->
          <i #icon_search_in (click)="onTypingSearchAssist($event, input_search_users)" class="icon-search"
            aria-hidden="true"></i>
        </div>
        <div id="icon-search-close" class="">
          <!-- <i (click)="onTypingSearchAssist($event, input_search_users)" class="fa fa-times-circle" aria-hidden="true"></i> -->
          <i #icon_search_close (click)="onTypingSearchAssist($event, input_search_users)" class="icon-close none"
            aria-hidden="true"></i>
        </div>
      </div>
      <!-- Select filter element -->
      <div>
        <select id="select-filter-chats" [(ngModel)]="filter_chat" (ngModelChange)="onChangeFilter()">
          <option value="all">Todos</option>
          <option value="unread">Sin leer</option>
          <option value="recently-read">Leídos recientes</option>
          <option value="alert-tasks">Tareas pendientes</option>
          <option value="group-courses">Cursos - Grupos</option>
          <option value="comments-courses">Cursos - Comentarios</option>
        </select>
      </div>
    </div>

    <div *ngIf="assist && !statistics" class="unread-courses-chats">
      <div *ngIf="unread_courses_chats" class="label-red"></div>
    </div>

    <div *ngIf="statistics" class="action-assist">
      <a [routerLink]="'/assist/chat-room-assist'" class="item">
        <div>
          Chat Room
        </div>
      </a>
    </div>

    <div *ngIf="assist && !statistics" class="action-assist">
      <a [routerLink]="'/assist/statistics'" class="item">
        <div>
          Estadísticas
        </div>
      </a>
    </div>

    <!-- Assist app nabvar elements - end --------------------------------- -->





    <div *ngIf="!assist" class="options-1">

      <!-- Explore -->
      <div *ngIf="!assist" (clickOutsideDdown)="display_categories=false" class="about-us dropdown">
        <div (click)="display_categories = !display_categories" [ngClass]="{'open': display_categories}" class="item">
          Explorar
          <span class="lnr lnr-chevron-up" [ngClass]="{'lnr-chevron-down': !display_categories}"></span>
        </div>
        <menu-categories *ngIf="display_categories" (closeModal)="display_categories=false"></menu-categories>
      </div>
      <!-- Explore -->

      <!-- Search -->
      <div (clickOutsideDdown)="showSearch=false" class="form-search">
        <div [ngClass]="{'open': showSearch,'result': resultsSearch.length>0}" class="form">
          <input #searchInput_ [(ngModel)]="_appService.searchText" (ngModelChange)="notesModelChanged.next($event);"
            (keydown)="onKeydownEvent($event)" (focus)="showSearch=true" (blur)="showSearch=(preventBlur)?false:true;"
            autocomplete="off" type="text" placeholder="Buscar..." maxlength="256"
            (keypress)="omit_special_char($event)">

          <span class="search" *ngIf="!showSearch || _appService.searchText!=''" (click)="goToSearch(-1)"
            [ngClass]="loadingSearch ? 'loader': 'icon-search-bold'" (mouseover)="preventBlur=false"
            (mouseout)="preventBlur=true"></span>

          <span class="search cancel delete icon-cross" *ngIf="showSearch && _appService.searchText==''"
            (click)="_appService.searchText='';showSearch=false;"></span>

          <!-- <img *ngIf="!showSearch || _appService.searchText!=''" (click)="goToSearch(-1)"
            [ngClass]="{'loader': loadingSearch}" (mouseover)="preventBlur=false" (mouseout)="preventBlur=true"
            class="search" src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-search.svg"> -->
          <!-- <img *ngIf="showSearch && _appService.searchText==''" (click)="_appService.searchText='';showSearch=false;"
            class="search cancel delete"
            src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-cancel.svg"> -->



          <span class="search btn-sm icon-search-bold" (click)="showSearch=true;showInput()"></span>
          <span class="search btn-sm cancel icon-cross" (click)="showSearch=false"></span>

          <!-- <img (click)="showSearch=true;showInput()" class="search btn-sm"
            src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-search.svg"> -->

          <!-- <img (click)="showSearch=false" class="search btn-sm cancel"
            src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-cancel.svg"> -->

        </div>
        <ul [ngClass]="{'hidden': resultsSearch.length==0 || _appService.searchText==''}"
          (mouseover)="preventBlur=false" (mouseout)="preventBlur=true" class="menu-search">
          <li *ngFor="let data of resultsSearch;let i = index" (click)="goToSearch(i);" (mouseover)="indexSuggestion=i;"
            [ngStyle]="{ 'background-color':((indexSuggestion==i)?'rgba(3, 27, 78, .1)':'') }">
            <!-- <img src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-search-recomendation.svg"> -->
            <i class="icon-search-bold"></i>
            <span [innerHTML]="data.highlights.name"></span>
          </li>
        </ul>
        <div *ngIf="_appService.searchText=='' && showSearch && _appService.user_session>-1"
          (mouseover)="preventBlur=false" (mouseout)="preventBlur=true" class="menu-categories">

          <menu-categories (closeModal)="showSearch=false"></menu-categories>

          <!-- loading -->
          <div *ngIf="_appService.categories.length==0" class="loader-circle"></div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'searchOpen': showSearch}" class="options-2">
      <!-- About Us -->
      <!-- <div *ngIf="!assist" (clickOutsideDdown)="showAboutWe=false" class="about-us dropdown">
        <div (click)="toggleAboutWe()" [ngClass]="{'open': showAboutWe}" class="item">Sobre nosotros
          <span class="lnr lnr-chevron-up" [ngClass]="{'lnr-chevron-down': !showAboutWe}"></span>
        </div>
        <ul class="menu">
          <li>
            <a class="link-a" target="_blank" [href]="environment.domain + '/about-us'">Sobre Edutin Academy</a>
            <div>Conoce nuestra misión y valores.</div>
          </li>
          <li>
            <a class="link-a" target="_blank" [href]="environment.domain + '/history'">Nuestra historia</a>
            <div>Conoce nuestra historia.</div>
          </li>
          <li>
            <a class="link-a" target="_blank" [href]="environment.domain + '/virtual-campus'">Campus virtual</a>
            <div>Conoce nuestra infraestructura tecnológica.</div>
          </li>
          <li>
            <a class="link-a" target="_blank" [href]="environment.domain + '/academy-contents'">Contenidos
              académicos</a>
            <div>Conoce el tipo de contenidos académicos utilizados en nuestra biblioteca de cursos.</div>
          </li>
          <li>
            <a class="link-a" target="_blank" href="https://edutin.us">Empleos</a>
            <div>Explore nuestros puestos vacantes, encuentre un trabajo que le encante y postule.</div>
          </li>
        </ul>
      </div> -->


      <!-- My courses -->
      <div *ngIf="_appService.user_session==1" (clickOutsideDdown)="showMyCourses=false;" class="my-courses">
        <div (click)="toggleMyCourses()" [ngClass]="{'open': showMyCourses}" class="item">
          <span class="text">Mis cursos</span>
          <span class="lnr lnr-chevron-up" *ngIf="!loadingCertification"
            [ngClass]="{'lnr-chevron-down': !showMyCourses}"></span>
          <span *ngIf="loadingCertification" class="lnr loader-circle"></span>
        </div>
        <div class="menu">
          <ul #myCourses_ id="my-courses" [ngClass]="{'nuevo': _appService.learning.length<=2, 'padding_right': isAppleMobile}">
            <li *ngFor="let course of _appService.learning; let i = index" (click)="openCourse(course)"
              [ngClass]="{'active': course.active}" class="card">
              <div class="content-img">
                <img #img_
                  [src]="'https://d3puay5pkxu9s4.cloudfront.net/curso/'+course.id+'/'+(course.imagen != null ? course.imagen : 'card_imagen.jpg')"
                  (error)="img_.src='https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg'"
                  alt="{{course.name}}">
                <div class="progress">
                  <div class="progress-color" [style.width]="course | getProgress"></div>
                </div>
              </div>
              <div class="description">
                <h4 [title]="course.name">{{course.name}}</h4>
                <div *ngIf="course.certification_id != '-1'" (click)="disableClick($event)" class="btns">
                  <a *ngIf="course.certification_state==1 || _appService.user_data.role=='premium'"
                    [href]="environment.appDomain + '/dashboard/'+course.certification_id" target="_blank"
                    class="btn">Administrar certificado</a>
                  <div *ngIf="course.certification_state!=1 && _appService.user_data.role!='premium'">
                    <a *ngIf="course.pending_payment == false && course.date_state"
                      [href]="_appService.url_Base+'/payments/certificate/'+course.certification_id"
                      class="btn secondary">Estudiar con certificado</a>
                    <a *ngIf="course.pending_payment == false && !course.date_state"
                      [href]="_appService.url_Base+'/helps/requests/'+course.certification_id" class="btn">Solicitar
                      activación</a>
                    <div *ngIf="course.pending_payment == -1" class="btn">
                      <div class="loader-circle"></div>
                    </div>
                    <a *ngIf="course.pending_payment == true"
                      [href]="_appService.url_Base+'/payments/certificate/'+course.certification_id"
                      class="btn tertiary">Continuar con el pago</a>
                  </div>
                </div>
              </div>
              <!-- <div #courseE class="options">
                    <img (click)="configCourseSP($event,0,courseE)"
                      src="https://d3puay5pkxu9s4.cloudfront.net/academy/iconos/learn/icon-options.svg" alt="options">
                  </div> -->
            </li>


            <div *ngIf="_appService.loadingPagination || _appService.loadingLearning">
              <li class="card loading-Element" *ngFor="let item of [1,2,3,4,5]">
                <div class="content-img"></div>
                <div class="description">
                  <h4></h4>
                  <div class="btns"></div>
                </div>
              </li>
            </div>

            <div *ngIf="!_appService.loadingLearning && _appService.learning.length==0">
              <li class="card empty" *ngFor="let item of [1]">

                <div class="description">
                  <h3>
                    Explora nuestras categorías e inscríbete gratis al curso de tu interés
                  </h3>
                  <div class="btns"></div>
                </div>
              </li>
            </div>


            <div *ngIf="_appService.loadingLearning" class="load">
              <div class="loader"></div>
            </div>
            <!-- <li (click)="((!_appService.loadingPagination)?getPrevious():false);showMyCourses=true;"
                  *ngIf="!_appService.loadingLearning && _appService.learning.length>9" id="pagination" class="card">
                  <span [hidden]="_appService.loadingPagination">Ver más</span>
                  <i [hidden]="!_appService.loadingPagination" class="loader"></i>
                </li> -->
            <li (click)="((!_appService.loadingPagination)?getPrevious():false);showMyCourses=true;"
              *ngIf="!_appService.loadingLearning && _appService.pagination != 0" id="pagination" class="card">
              <span [hidden]="_appService.loadingPagination">Ver más</span>
              <i [hidden]="!_appService.loadingPagination" class="loader"></i>
            </li>
          </ul>
          <!-- Add new course -->
          <div (click)="goToCategory()" id="addCourse">
            <div class="add">
              +
            </div>
            <h4>Agregar un nuevo curso</h4>
          </div>
        </div>
      </div>
      <!-- My courses 2 -->
      <div *ngIf="_appService.user_session==0" (clickOutsideDdown)="showMyCourses=false;"
        class="my-courses my-courses-2">
        <div (click)="showMyCourses=!showMyCourses" [ngClass]="{'open': showMyCourses}" class="item">
          <span class="text">Cursos</span>
          <span class="lnr lnr-chevron-up" [ngClass]="{'lnr-chevron-down': !showMyCourses}"></span>
        </div>
        <div class="" *ngIf="showMyCourses">
          <menu-categories [ngStyle]="{'right': 0, 'left': 'unset'}"
            (closeModal)="showMyCourses=false"></menu-categories>
          <!-- loading -->
          <div *ngIf="_appService.categories.length==0" class="loader-circle"></div>
        </div>
      </div>
      <!-- My courses  3 -->
      <div *ngIf="_appService.user_session==-1" class="my-courses my-courses-3"></div>



      <!-- Certificates -->
      <div *ngIf="!assist" class="certificate">
        <a [href]="_appService.url_Base+'/certificado-de-estudios'" class="item">
          Certificados
        </a>
      </div>
      <!-- Shopping Car -->
      <shopping-cart [showMenu]="showMenu" *ngIf="this._appService.user_session==1 && !assist"></shopping-cart>
      <!-- Notifications -->
      <notifications [showMenu]="showMenu" *ngIf="this._appService.user_session==1"
        (openNotification)="openCourse($event)"></notifications>
      <!-- Messaging -->
      <conversations [showMenu]="showMenu" *ngIf="this._appService.user_session==1"
        (open_live_chat)="open_live_chat.emit($event)"></conversations>
      <!-- Connections -->
      <connections [showMenu]="showMenu" *ngIf="this._appService.user_session==1"></connections>
      <!-- Profile -->
      <div *ngIf="_appService.user_session==1" (clickOutsideDdown)="showProfile=false" class="profile dropdown">
        <div (click)="toggleProfile()" [ngClass]="{'open':showProfile}" class="item">
          <img #userAvatar [src]="_appService.user_data.picture"
            (error)="userAvatar.src= 'https://d3puay5pkxu9s4.cloudfront.net/Users/'+(_appService.user_data.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
            [className]="_appService.user_data.role | userClassName">
        </div>
        <ul class="menu">
          <li>

            <a (click)="goToProfile()">
              <div class="row">
                <img [src]="_appService.user_data.picture" #menuUserAvatar
                  (error)="menuUserAvatar.src= 'https://d3puay5pkxu9s4.cloudfront.net/Users/'+(_appService.user_data.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
                  [className]="_appService.user_data.role | userClassName">
                <div class="name">
                  <h3>{{_appService.user_data.name | cutUserName: '2'}}</h3>
                  <h4>{{_appService.user_data.role | roleName}}</h4>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a (click)="goToProfile()">Perfil de estudiante</a>
          </li>
          <li class="theme-option">
            <!-- *ngIf="_appService.url_route == 'classroom'" -->
            Tema oscuro
            <span class="switch">
              <input #inputTheme id="switch-round" type="checkbox" (change)="toggleDarkTheme(inputTheme.checked)"
                [(ngModel)]="_appService.darkTheme" />
              <label for="switch-round"></label>
            </span>
          </li>
          <!-- <li *ngIf="_appService.user_data.role === 'assistant' || _appService.user_data.role === 'moderador'">
            <a [routerLink]="['/assist']">AssistApp</a>
          </li> -->
          <li>
            <a (click)="showProfile=false" [routerLink]="['/category']">Agregar un nuevo curso</a>
          </li>
          <li
            *ngIf="_appService.user_data.role=='propietario' || _appService.user_data.role=='moderador'  || _appService.user_data.role=='monitor'  || _appService.user_data.role=='assistant'">
            <a [href]="environment.domain + '/users/redirect_designer/'" target="_blank">Diseñador de cursos</a>
          </li>
          <li>
            <a (click)="showProfile=false" [routerLink]="['/user/edit']">Configuración de cuenta</a>
          </li>
          <!-- <li
            *ngIf="_appService.user_data.role=='propietario' || _appService.user_data.role=='moderador'  || _appService.user_data.role=='monitor'  || _appService.user_data.role=='assistant'">
            <a (click)="goToMeet()">Reuniones</a>
          </li> -->

          <!-- <li>
            <a (click)="showProfile=false" [routerLink]="['/user/edit']">Editar perfil</a>
          </li> -->
          <li>
            <a (click)="logout(_appService.url_Base+'/users/logout')">Cerrar sesión</a>
          </li>
        </ul>


        <div class="theme-popup" *ngIf="display_popup_dark_theme">
          <span class="icon-cross" (click)="closeThemePopup()"></span>
          <div class="theme-option">
            <span class="switch">
              <input #inputTheme id="switch-round" type="checkbox" (change)="toggleDarkTheme(inputTheme.checked);"
                [(ngModel)]="_appService.darkTheme" />
              <label for="switch-round"></label>
            </span>
          </div>
          <h3>Activa el tema oscuro</h3>
          <p>Facilita la visualización del contenido en situaciones de poca luz</p>
        </div>


      </div>
      <!-- Profile Loading -->
      <ul *ngIf="_appService.user_session==-1" class="loading-icons-nav">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <!-- Profile no user -->
      <div *ngIf="_appService.user_session==0" class="btn-no-user">
        <a [routerLink]="['/user/login']" class="login">Acceder</a>
        <a [routerLink]="['/user/add']" class="add">Crear cuenta</a>
      </div>
    </div>
    <!-- Menu mobile responsive -->
    <div *ngIf="!assist" (clickOutsideDdown)="showMenu=false" [ngClass]="{'active': showMenu}"
      class="menu-mobile dropdown">
      <div (click)="toggleMenu()" class="btn-menu">
        <svg viewBox="0 0 100 100" width="55" class="svg-menu">
          <path
            d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            class="line top"></path>
          <path d="m 30,50 h 40" class="line middle"></path>
          <path
            d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            class="line bottom"></path>
        </svg>
      </div>
      <div [ngClass]="{'open':showMenu}" class="menu">
        <!-- Profile no user -->
        <div *ngIf="_appService.user_session==0" class="btn-no-user">
          <a [routerLink]="['/user/login']" class="login">Acceder</a>
          <a [routerLink]="['/user/add']" class="add">Crear cuenta</a>
        </div>
        <ul class="about-us">
          <li>
            <a [href]="environment.domain + '/about-us'" target="_blank" class="link-a">Sobre
              Edutin Academy</a>
            <div>Conoce nuestra misión y valores.</div>
          </li>
          <li>
            <a [href]="environment.domain + '/history'" target="_blank" class="link-a">Nuestra
              historia</a>
            <div>Conoce nuestra historia.</div>
          </li>
          <li>
            <a [href]="environment.domain + '/virtual-campus'" target="_blank" class="link-a">Campus virtual</a>
            <div>Conoce nuestra infraestructura tecnológica.</div>
          </li>
          <li>
            <a [href]="environment.domain + '/academy-contents'" target="_blank" class="link-a">Contenidos
              académicos</a>
            <div>Conoce el tipo de contenidos académicos utilizados en nuestra biblioteca de
              cursos.</div>
          </li>
          <li>
            <a href="https://edutin.us" target="_blank" target="_blank" class="link-a">Empleos</a>
            <div>Explore nuestros puestos vacantes, encuentre un trabajo que le encante y postule.</div>
          </li>
          <li>
            <a [href]="environment.domain + '/certificado-de-estudios'" target="_blank" class="link-a">Certificados</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>