import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as countries_data from '../../assets/countries.json';

@Pipe({ name: 'getProgress' })
export class GetProgress implements PipeTransform {
  transform(progress: any): string {
    return progress.avance + "%";
  }
}

@Pipe({ name: 'urlImage' })
export class UrlImage implements PipeTransform {
  transform(item: any, type: number): string {


    let url = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
    if (type == 0) {
      // Formato normal

      if (item.sender.image.includes('platform-lookaside.fbsbx.com')) {
        url += 'default/small_imagen.jpg';
      } else {

        if (item.sender.fuente == 'facebook' || item.sender.fuente == 'google') {
          if (item.sender.image != '') {
            if (item.sender.image.substring(0, 4) == 'http') {
              url = item.sender.image;
            } else {
              url += item.sender.id + '/small_' + item.sender.image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.sender.image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.sender.id + '/small_' + item.sender.image;
        }


      }

    } else if (type == 1) {
      // Formato mapeado
      if (item.associated.length === 1) {
        if (item.associated[0].user.user_fuente == 'facebook' || item.associated[0].user.user_fuente.S == 'google') {
          if (item.associated[0].user.user_image != '') {
            if (item.associated[0].user.user_image.substring(0, 4) == 'http') {
              url = item.associated[0].user.user_image;
            } else {
              url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.associated[0].user.user_image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
        }
      } else {
        if (item.conversation.image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.conversation.id + '/Image/' + item.conversation.image;
        }
      }
    } else if (type == 2) {
      // Formato cursos
      if (item.User.fuente == 'facebook' || item.User.fuente == 'google') {
        if (item.User.image != '') {
          if (item.User.image.substring(0, 4) == 'http') {
            url = item.User.image;
          } else {
            url += item.User.id + '/small_' + item.User.image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.User.image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.User.id + '/small_' + item.User.image;
      }
    } else if (type == 3) {
      // Formato mapeado lambda 
      if (item.associated.length > 0) {
        if (item.associated[0].user.user_fuente == 'facebook' || item.associated[0].user.user_fuente == 'google') {
          if (item.associated[0].user.user_image != '') {
            if (item.associated[0].user.user_image.substring(0, 4) == 'http') {
              url = item.associated[0].user.user_image;
            } else {
              url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.associated[0].user.user_image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
        }
      } else {
        if (item.conversation.image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.conversation.image ? item.conversation.id + '/Image/' + item.conversation.image : 'default/small_imagen.jpg';
        }
      }
    }
    else if (type == 4) {
      // Usuario de chat
      if (item.user) {
        if (item.user.user_fuente == 'facebook' || item.user.user_fuente == 'google') {
          if (item.user.user_image != '') {
            if (item.user.user_image.substring(0, 4) == 'http') {
              url = item.user.user_image;
            } else if (item.user.user_image.includes('data')) {
              url += 'default/small_imagen.jpg';
            } else {
              url += item.user.user_id + '/small_' + item.user.user_image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.user.user_image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.user.user_id + '/small_' + item.user.user_image;
        }
      } else {

        url += 'default/small_imagen.jpg';

      }
    }
    else if (type == 5) {
      // Usuario de chat
      if (item.User) {

        if (item.User.image.substring(0, 4) == 'http') {
          url = item.User.image;
        } else if (item.User.image.includes('data')) {
          url += 'default/small_imagen.jpg';
        }
        else {

          if (item.User.image == '') {
            if (item.User.role == 'propietario') {
              url += 'professor/small_imagen.jpg';
              // if (item.user.user_image.substring(0, 4) == 'http') {              
              //   url += item.user.user_id + '/small_' + item.user.user_image;
              // }
            } else {
              url += 'default/small_imagen.jpg';
            }
          } else {
            url += item.User.id + '/small_' + item.User.image;
          }
        }

      } else {

        url += 'default/small_imagen.jpg';

      }


      // https://d3puay5pkxu9s4.cloudfront.net/Users/4859030/small_{"data":{"height":50,"is_silhouette":false,"url":"https://platform-lookaside.fbsbx.com/platform/prof
    }
    return url;
  }
}


@Pipe({ name: 'roleName' })
export class RoleName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case "free":
        return "Estudiante libre";
      case "premium":
        return "Estudiante oficial";
      case "propietario":
      case "professor":
        return "Profesor";
      case "moderador":
      case "monitor":
        return "Monitor";
      case "administrator":
        return "Administrador";
      case "assistant":
      case "assistant_professor":
        return "Profesor asistente";
      default:
        return role
    }
  }
}

@Pipe({ name: 'userClassName' })
export class UserClassName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case "free":
        return "student-free";
      case "premium":
        return "student-official";
      case "monitor":
        return "monitor";
      case "propietario":
      case "professor":
        return "teacher";
      case "moderador":
      case "assistant":
      case "administrator":
      case "assistant_professor":
        return "moderador";
    }
  }
}


@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
  transform(username: string, length: string): string {

    username = username.toLocaleLowerCase();

    let split_username_length = username.trim().split(/\s+/).length;
    var un = username.split(' ');
    switch (length) {
      case '1':
        var user_name = un[0];
        break;
      case '2':
        var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
        break;
    }
    let name = '';

    user_name.split(' ').forEach(U => {
      name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
    });

    return name;

  }
}

@Pipe({ name: 'shoppingCarType' })
export class shoppingCarType implements PipeTransform {
  transform(type: number): string {
    switch (type) {
      case 1:
        return 'Certificado de estudios';
      case 2:
        return 'Carrera online';
    }
  }
}

@Pipe({ name: 'notificationsDate' })
export class notificationsDate implements PipeTransform {
  transform(_date: string): string {
    var today: any = new Date();
    var yesterday = "";
    var dd = String(today.getDate()).padStart(2, '0');
    var dd1 = String(today.getDate() - 1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    yesterday = yyyy + '-' + mm + '-' + dd1;

    if (_date.substring(0, 10) == today) {
      return 'Hoy';
    } else if (_date.substring(0, 10) == yesterday) {
      return 'Ayer';
    } else {
      var monthNames = [
        "Ene", "Feb", "Mar",
        "Abr", "May", "Jun", "Jul",
        "Ago", "Sep", "Oct",
        "Nov", "Dic"
      ];
      return parseInt(_date.substring(8, 10)) + ' ' + monthNames[parseInt(_date.substring(5, 7)) - 1];
    }
  }
}

@Pipe({ name: 'messageDate' })
export class messageDate implements PipeTransform {
  transform(_date: string): string {
    if (_date == null) { return ''; }
    var today: any = new Date();
    var yesterday = "";
    var dd = String(today.getDate()).padStart(2, '0');
    var dd1 = String(today.getDate() - 1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    yesterday = yyyy + '-' + mm + '-' + dd1;


    if (_date.substring(0, 10) == today) {
      return 'Hoy';
    } else if (_date.substring(0, 10) == yesterday) {
      return 'Ayer';
    } else {
      var monthNames = [
        "Ene", "Feb", "Mar",
        "Abr", "May", "Jun", "Jul",
        "Ago", "Sep", "Oct",
        "Nov", "Dic"
      ];

      return parseInt(_date.substring(8, 10)) + ' ' + monthNames[parseInt(_date.substring(5, 7)) - 1];
    }
  }
}

@Pipe({ name: 'categoryName' })
export class categoryName implements PipeTransform {
  transform(categoryid: any, categories: any): string {
    if (categoryid && categories) {
      let result = categories.find(category => category.id == categoryid);
      return result ? result.name : '';
    }
    return '';
  }
}

@Pipe({ name: 'cssChildHeight' })
export class CssChildHeight implements PipeTransform {
  transform(elemt: any): number {
    const child = elemt.children[0];
    return parseFloat(window.getComputedStyle(child).getPropertyValue('height'));
  }
}


@Pipe({ name: 'courseConfigUrl' })
export class CourseConfigUrl implements PipeTransform {
  transform(course: any, user_id): any {
    let config = {
      curso_id: course.id,
      certification_id: course.certification_id,
      type: course.tipo,
      clase_id: course.clase_id != '' ? course.clase_id : 0,
      idu: user_id,
      source_id: course.source_id != '' ? course.source_id : 0,
      quiz_id: course.quiz_id != '' ? course.quiz_id : 0,
      language_id: course.language_id,
      state: course.certification_state,
      tour: course.certification_tour != null ? course.certification_tour : '-1'
    }

    // let url = environment.appDomain + '/classroom?config=' + JSON.stringify(config)

    return config;
  }
}




@Pipe({ name: 'groupNamePipe' })
export class GroupNamePipe implements PipeTransform {
  transform(conversation): string {

    let name = '';

    const user_name = (user) => {
      user = user.split(' ')[0];
      return user.charAt(0).toUpperCase() + user.slice(1);
    };


    if (conversation && conversation.associated.length == 1) {

      name = conversation.associated[0].user.user_name;
    } else if (conversation && conversation.associated.length > 0) {
      if (conversation.conversation.name != "uc-" + conversation.conversation.id && conversation.conversation.name != "uc") {
        name = conversation.conversation.name;
      } else {
        if (conversation.associated.length === 2) {
          name = user_name(conversation.associated[0].user.user_name) + " y " + user_name(conversation.associated[1].user.user_name);
        } else {
          name = user_name(conversation.associated[0].user.user_name) + ", " + user_name(conversation.associated[1].user.user_name) + " y " + (conversation.associated.length - 2).toString() + " más";
        }
      }
    }

    return name;
  }

}



@Pipe({ name: 'userCountryflagPipe' })
export class UserCountryflagPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  countries: any = (countries_data as any).default;
  transform(user_country): string {


    let url_flag = null;

    if (user_country.short_name != null && user_country.short_name != '') {
      url_flag = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${user_country.short_name.toLocaleLowerCase()}.svg`;
    } else {
      let country = this.countries.find(c => c.id == user_country.id);
      url_flag = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${country.short_name.toLocaleLowerCase()}.svg`;
    }

    return url_flag;
  }

}


@Pipe({ name: 'toLowerCase' })
export class ToLowerCase implements PipeTransform {
  transform(text: string): string {
    return text.toLowerCase();
  }
}


//Pipe para recortar nombre del certificado y solo diga el nombre del curso sin Curso de
@Pipe({ name: 'cutCertificationName' })
export class CutCertificationNamePipe implements PipeTransform {
  transform(certificate: string): string {

    let cert = certificate.toLocaleLowerCase().split('curso de ');
    let name = cert.length > 0 ? cert[1] : certificate;

    return name;

  }
}

//Pipe para recortar nombre del certificado y solo diga el nombre del curso sin Curso de
@Pipe({ name: 'groupEmojiTypePipe' })
export class GroupEmojiTypePïpe implements PipeTransform {
  transform(reactions: any, resumed_reactions: any): any {
    const result = reactions.reduce(function (r, a) {
      r[a.reaction] = r[a.reaction] || [];
      r[a.reaction].push(a);
      return r;
    }, Object.create(null));
    const toArr = Object.keys(result).map((key) => [{ name: key, reactions: result[key].length }]);
    return toArr;
  }
}

//Pipe para obtener array segun filter de reacciones, si se selecciona corazón se filtrará reacciones por HEART
@Pipe({ name: 'filterByReactionPipe' })
export class FilterByReactionPipe implements PipeTransform {
  transform(reactions: any, filter: string): any {
    let result: any = reactions;
    if (filter != 'all') {
      result = reactions.filter(r => {
        return r.reaction == filter;
      });
    }
    return result;
  }
}
//Pipe para obtener emoji
@Pipe({ name: 'reactionEmojiPipe' })
export class ReactionEmojiPipe implements PipeTransform {
  transform(reaction: any): any {

    let reactions_types = [
      { name: 'LIKE', emoji: `👍`, label: 'Me gusta' },
      { name: 'HEART', emoji: `❤️`, label: 'Me encanta' },
      { name: 'THINKING', emoji: `🤔`, label: 'Interesante' },
      { name: 'HUSHED', emoji: `😲`, label: 'Me asombra' },
      { name: 'BRAIN', emoji: `🧠`, label: 'Aprendí' },
      { name: 'LIGHT', emoji: `💡`, label: 'Buena idea' },
      { name: 'HELLO', emoji: `👋`, label: 'Hola' },
      { name: 'CLAP', emoji: `👏`, label: 'Aplauso' },
      { name: 'SMILE', emoji: `😊`, label: 'Sonrisa' },
      { name: 'SAD', emoji: `🙁`, label: 'Triste' },
    ];

    let result = reactions_types[reactions_types.findIndex(x => x.name == reaction)];

    return result;
  }
}
//Pipe para obtener url del icono del archivo
@Pipe({ name: 'srcFileIconPipe' })
export class SrcFileIconPipe implements PipeTransform {
  transform(file: any): any {

    let icon_name = '';

    switch (file.toLowerCase().split('.').pop()) {
      case 'doc':
      case 'docx':
        icon_name = 'doc.svg';
        break;
      case 'xls':
      case 'xlsx':
        icon_name = 'xls.svg';
        break;
      case 'ppt':
      case 'pptx':
        icon_name = 'ppt.svg';
        break;
      case 'txt':
        icon_name = 'txt.svg';
        break;
      case 'rar':
        icon_name = 'rar.svg';
        break;
      case 'zip':
        icon_name = 'zip.svg';
        break;
      case 'pdf':
        icon_name = 'pdf.svg';
        break;
      default:
        icon_name = 'file.svg';
        break;

    }

    let src = `./assets/icons/${icon_name}`;

    return src;
  }
}