import { Component, Input, Output, EventEmitter, SimpleChanges, HostListener, ElementRef } from '@angular/core';



@Component({
    selector: 'select-list',
    template: `
    
    <ul class="select_list" (click)="displayList()">
        <p *ngIf="item_selected!=null">
        Unidad {{item_selected+1}}: {{list[item_selected].Module.name}}
        </p>
        <div *ngIf="display_list">
            <li *ngFor="let item of list; let i_item = index" (click)="itemSelected(i_item)" [class.active]="item_selected == i_item">
                
                <span *ngIf="type_list == 'modules'">
                    Unidad {{i_item + 1}}: {{item.Module.name}}
                </span>

                <span *ngIf="type_list == 'lessons'">
                    Clase: {{item.titulo}}
                </span>

                <span *ngIf="type_list == 'sources'">
                    Recurso: {{item.type | typeNameSource}}
                </span>

            </li> 
        </div>
    </ul>
  `,
    styleUrls: ['./participate.component.scss']
})
export class SelectLisComponent {

    @Input() list;
    @Input() type_list;
    @Input() item_selected;
    @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

    constructor(private el: ElementRef) {

    }

    @HostListener('document:click', ['$event.target'])
    public onClick(target) {
        const clickedInside = this.el.nativeElement.contains(target);
        if (!clickedInside && this.display_list) {
            // this click event from outside            
            this.display_list = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.item_selected = parseInt(this.item_selected);
    }

    display_list: boolean = false;
    displayList() {
        this.display_list = !this.display_list;
    }

    itemSelected(item) {        
        this.optionSelected.emit(item);
    }
}