import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewUrlDirective } from './directives/preview-url.directive';
import { UrlVideoDirective } from './directives/url-video.directive';


@NgModule({
  declarations: [
    PreviewUrlDirective,
    UrlVideoDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PreviewUrlDirective,
    UrlVideoDirective,
  ]
})
export class SharedPreviewLinkModule { }
