<div class="community_modal">
  <div class="modal_container">

    <a class="btn icon-cross" (click)="_appService.comment_modal_.state = false"></a>

    <span class="lnr lnr-cross-circle" *ngIf="item_list.state && items == null"></span>


    <div class="title-loading loading-Element" *ngIf="!item_list.state"></div>

    <div class="modal-title" *ngIf="item_list.state">

      <p [innerHTML]="notification_title" *ngIf="items != null"></p>

      <p class="title-deleted" *ngIf="items == null"> <b>
          Lo sentimos, no es posible cargar la publicación.
        </b>
      </p>

    </div>

    <div class="modal-content">

      <item-list [reference]="'notification'" *ngIf="item_list.state && items != null"></item-list>

      <div class="modal-content-deleted" *ngIf="item_list.state && items == null">

        La publicación que desea visualizar fue eliminada por el autor.


      </div>

      <div class="modal-content-empty loading-Element" *ngIf="!item_list.state">

      </div>

    </div>

  </div>
</div>
