import { Component, Input, ViewChild, SimpleChange, Output, EventEmitter } from '@angular/core';
import { ClassroomService } from '../../services/classroom.service';
import { CommunityService } from '../../services/community.service';
import { SearchBarService } from '../../sidebar/components/search-bar/search-bar.service';
import { ActivatedRoute } from '@angular/router';
import { ModalDialogComponent } from '../../shared-classroom/modal-dialog/modal-dialog.component';

@Component({
  selector: 'community-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {

  constructor(
    public _classroomService: ClassroomService,
    public _communityService: CommunityService,
    public _searchBarService: SearchBarService,
    private _route: ActivatedRoute,
  ) { }

  @Input() current_lesson;
  @Input() current_source;
  @Input() is_sidebar_component;
  @Input() sidebar_component_filter;
  last_source_id: string; //el id de la ultima clase vista, para evitar pedir los de la clase, si se mueve entre archivos de la misma clase  
  @ViewChild('courseItemList') courseItemList_;
  comment_from_notification: string;
  @Output() goToSource: EventEmitter<any> = new EventEmitter<any>();
  @Output() comments_loaded: EventEmitter<any> = new EventEmitter<any>();

  modal_dialog_ = {
    state: false,
    type: null
  };


  @ViewChild(ModalDialogComponent) modal_dialog: ModalDialogComponent;


  filter = [
    { id: 'source', label: 'Ver todas las preguntas' },
    { id: 'my_questions', label: 'Ver mis preguntas' },
    { id: 'no_answers', label: 'Ver sin respuesta' },
  ];

  filter_selected = this.filter[0];

  tab_selected: string;

  urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

  current_source_id: string;


  ngAfterViewInit() {
    if (this.is_sidebar_component) {
      setTimeout(() => {
        this.loadComments(this.sidebar_component_filter);
        this.filter_selected = { id: this.sidebar_component_filter, label: '' };
      }, 1);
    }
  }


  ngOnChanges(changes: SimpleChange) {

    // changes.hasOwnProperty('comments') ||    
    if (((changes.hasOwnProperty('current_source')) && this.current_source != null && this.current_source_id != this.current_source.id) && !this.is_sidebar_component) {

      this.notification_ = {
        display: false,
        msj: null
      }

      this.current_source_id = this.current_source.id;

      if (this._classroomService.data.actions) {
        let filter = [
          { id: 'source', label: this._classroomService.data.source.type == 5 ? 'Ver todas discusiones' : 'Ver todas las preguntas' },
          { id: 'my_questions', label: this._classroomService.data.source.type == 5 ? 'Ver mis discusiones' : 'Ver mis preguntas' },
          { id: 'no_answers', label: 'Ver sin respuestas' },
        ];

        this.filter = JSON.parse(JSON.stringify(filter));
        this.filter_selected = this.filter[0];
      } else {
        this.filter = [
          { id: 'source', label: 'Ver todas las preguntas' },
          { id: 'no_answers', label: 'Ver sin respuestas' },
        ];
      }



      this._communityService.data_comments.source = null;
      this._communityService.data_comments.my_questions = null;
      this._communityService.data_comments.no_answers = null;

      // this._communityService.commentsPages.source = 0;
      // this._communityService.commentsPages.my_questions = 0;
      // this._communityService.commentsPages.no_answers = 0;



      if (this.last_source_id != this.current_source.id) {

        this.last_source_id = this.current_lesson.id;
        // this._communityService.commentsPages.source = 0;

        // let data_comment = {
        //   source_id: this._classroomService.data.source.type == 5 ? '-1' : this._classroomService.data.source.id,
        //   quiz_id: this._classroomService.data.source.type == 5 ? this._classroomService.data.source.id : '-1',
        //   page: this._communityService.commentsPages.source,
        //   limit: 10
        // };


        this.loadComments('source');

      }



      setTimeout(() => {

        if (this._classroomService.data.source && this._classroomService.data.source.type == 5) {

          if (this._classroomService.data.actions) {
            if (this._communityService.data_comments['source'] == null || this._communityService.data_comments['source'].length == 0) {
              this.notification_ = {
                display: true,
                msj: 'Para aprobar este foro, te invitamos a crear una publicación y brindar tu opinión a 2 de tus compañeros.'
              }
            }

            if (this._communityService.data_comments['no_answers'] != null && this._communityService.data_comments['no_answers'].length > 0) {


              let source = this._classroomService.data.source;

              let source_hasAttemps = (source.Attempt != null && source.Attempt.length > 0 && source.Attempt[0] != null) ? true : false;

              if (source_hasAttemps) {

                if (source.Attempt[0].count_participations == 0) {

                  if (source.Attempt[0].count_opinions == 0) {
                    this.notification_ = {
                      display: true,
                      msj: 'Para aprobar este foro, te invitamos a crear una publicación y brindar tu opinión a 2 de tus compañeros.'
                    }
                  }

                  // if (source.Attempt[0].count_opinions == 1) {
                  //   this.notification_ = {
                  //     display: true,
                  //     msj: 'Tu opinión ha sido enviada satisfactoriamente, solo te falta realizar tu publicación y dar una opinión a otro compañero para la aprobación de este foro.'
                  //   }
                  // }

                  // if (source.Attempt[0].count_opinions > 1) {
                  //   this.notification_ = {
                  //     display: true,
                  //     msj: 'Tu opinión ha sido enviada satisfactoriamente, solo te falta realizar tu publicación para la aprobación de este foro.'
                  //   }
                  // }

                } else {

                  // if (source.Attempt[0].count_participations > 0 && source.Attempt[0].count_opinions == 0) {
                  //   this.notification_ = {
                  //     display: true,
                  //     msj: 'Tu publicación ha sido enviada satisfactoriamente, se requiere que des tu opinión a 2 de tus compañeros para la aprobación de este foro.'
                  //   }
                  // }


                  // if (source.Attempt[0].count_participations > 0 && source.Attempt[0].count_opinions == 1) {
                  //   this.notification_ = {
                  //     display: true,
                  //     msj: 'Tu opinión ha sido enviada satisfactoriamente, solo te falta dar una opinión a otro compañero para la aprobación de este foro. '
                  //   }
                  // }


                  // if (source.Attempt[0].count_participations > 0 && source.Attempt[0].count_opinions > 1) {
                  //   this.notification_ = {
                  //     display: true,
                  //     msj: 'Has aprobado satisfactoriamente este foro, estamos seguros que tus opiniones serán de mucha ayuda para tus compañeros.'
                  //   }
                  // }

                  // if (source.Attempt[0].count_opinions == 0) {
                  //   this.notification_ = {
                  //     display: true,
                  //     msj: 'Tu publicación ha sido enviada satisfactoriamente, se requiere que des tu opinión a 2 de tus compañeros para la aprobación de este foro.'
                  //   }
                  // }

                }

              } else {
                this.notification_ = {
                  display: true,
                  msj: 'Para aprobar este foro, te invitamos a crear una publicación y brindar tu opinión a 2 de tus compañeros.'
                }
              }

            }
          } else {
            // this.notification_ = {
            //   display: true,
            //   msj: 'Debe iniciar sesión para poder realizar su participación y opinar en la participación de sus compañeros.'
            // }
          }


        } else {

          if (this._classroomService.data.actions) {
            if (this._communityService.data_comments['source'] == null || this._communityService.data_comments['source'].length == 0) {
              this.notification_ = {
                display: true,
                msj: 'Si tienes alguna duda sobre el tema, te invitamos a leer las preguntas de tus compañeros o a realizar tus propias preguntas. Tus compañeros estarán encantados de responder.'
                // msj: 'Puedes formular y responder preguntas para conectarte y compartir conocimiento con estudiantes de todo el mundo.'
              }
            }

            if (this._communityService.data_comments['no_answers'] != null && this._communityService.data_comments['no_answers'].length > 0) {
              this.notification_ = {
                display: true,
                msj: 'Hay compañeros que necesitan ayuda en este tema, te invitamos a leer sus preguntas y brindarles una respuesta.'
              }
            }
          } else {
            // this.notification_ = {
            //   display: true,
            //   msj: 'Debe iniciar sesión para poder realizar su pregunta y responder las preguntas de sus compañeros.'
            // }
          }
        }



        // if (this.notification_.display) {
        //Poner a flotar el mensaje
        //   setTimeout(() => {
        //     this._classroomService.resizeCommentsFloatingMsg();//Cambiar tamaño de la notificación flotante de comments
        //   }, 1000);
        // }

      }, 2000);


    }



    this._route.queryParams.subscribe(params => {

      if (!this.is_sidebar_component && (params.hasOwnProperty('comment_id') && params.comment_id != null && params.comment_id != this.comment_from_notification)) {

        this.comment_from_notification = params.comment_id;

        this._communityService.getCommentsRedirect(params.comment_id).subscribe(comment => {
          this.filter.push({ id: 'notificate', label: 'Pregunta' })
          this.filter_selected = this.filter[this.filter.length - 1];

          if (comment != null && comment.hasOwnProperty('comments') && (comment.comments != null && comment.comments.length > 0)) {

            this._communityService.data_comments['notificate'] = (JSON.parse(JSON.stringify(comment.comments)));

          } else {

            this._communityService.data_comments['notificate'] = [];

          }

          setTimeout(() => {

            let comments_section = document.querySelector('.source_comments');

            if (comments_section) {
              comments_section.scrollIntoView({
                behavior: 'smooth',
                block: "center",
                inline: "center"
              });
            }

          }, 400);

        });
      }


    });



  }

  notification_ = {
    display: false,
    msj: null
  }


  // comments_search = null;
  // eventSearch(text) {
  //   this.searchtext_ = text;
  //   this._communityService.data_comments.search = null;
  //   this._communityService.commentsPages.search = 0;
  //   if (text.length > 0) {
  //     // this.loadingUsers = true;
  //     let data = {
  //       part: text,
  //       limit: 10,
  //       page: this._communityService.commentsPages.search
  //     }

  //     this._communityService.searchComments(data).subscribe(comments => {
  //       this._communityService.data_comments.search = JSON.parse(JSON.stringify(comments.comments));
  //       this._communityService.commentsPages.search += 1;
  //     })
  //   } else {
  //     // this.loadingUsers = false;
  //     this._communityService.data_comments.search = null;
  //   }
  // }

  event_backup; //guardar los datos de busqueda


  itemSelected(item) {

    if (this.filter_selected.id == 'notificate' && (this._communityService.data_comments['notificate'] != null && this._communityService.data_comments['notificate'].length == 0)) {

      if (this.filter.length == 4) {

        this.filter.splice(3, 1);

      }

    }

    this.filter_selected = this.filter[item];
  }



  takePart() {

    if (this._classroomService.data.actions) {

      if (this._communityService.config.comments == 'active') {
        // this._communityService.closeParticipateComponent('comments');
      } else {
        this._communityService.openParticipate({ item: null, component: 'comments' });

        // setTimeout(() => {
        //   let el = document.querySelector('.participate-container');
        //   if (el != null) {
        //     el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        //   }
        // }, 1000);

      }


    } else {

      this.modal_dialog_.state = true;

      setTimeout(() => {
        this.modal_dialog.modal_type = 48;

        setTimeout(() => {

          let modal_ = document.querySelector('.modal__container._msg_');
          modal_.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
          });

        }, 250);


        let __response = this.modal_dialog.response.subscribe(response => {
          this.modal_dialog_.state = false;
          __response.unsubscribe();
        });

      }, 250);
    }




  }



  searchtext_: string = '';



  comments_filter = ['source', 'no_answers', 'my_questions'];

  public updateJson(event) {

    //('::updateJson:: ', JSON.parse(JSON.stringify(event)));

    let _reference;
    switch (this.filter_selected.id) {
      case 'source':
        _reference = 'l';
        break;
      case 'no_answers':
        _reference = 'na';
        break;
      case 'my_questions':
        _reference = 'mq';
        break;
      case 'search':
        _reference = 'search';
        break;
      case 'recent':
        _reference = 'rec';
        break;
      case 'popular':
        _reference = 'pop';
        break;
      case 'my_questions_sidebar':
        _reference = 'mqs';
        break;
    }


    switch (event.ev) {
      case 'new-comment':    
        event.new_item['Like'] = event.new_item.Voto;
        let arr_ = ['source', 'no_answers', 'my_questions'];

        if (this.is_sidebar_component) {
          arr_ = ['source', 'no_answers', 'my_questions', 'recent', 'popular', 'my_questions_sidebar'];
        }


        arr_.forEach(ref => {
          //SI el item se encuentra en el array original             
          // let tmp_comments = [event.new_item, ...JSON.parse(JSON.stringify(this._communityService.data_comments[ref]))];
          let tmp_comments_array = JSON.parse(JSON.stringify(this._communityService.data_comments[ref]));
          tmp_comments_array.unshift(event.new_item);
          this._communityService.data_comments[ref] = JSON.parse(JSON.stringify(tmp_comments_array));

        });

        let label = _reference.charAt(0);
        // setTimeout(() => {
        //   let element = document.querySelector(`#com_${label}_${event.new_item.Comment.id}`);

        //   if (element != null) {
        //     element.scrollIntoView({
        //       behavior: "smooth",
        //       block: "center",
        //       inline: "center"
        //     });
        //   }
        // }, 250);



        break;
      case 'edit-comment':
        let arr_edit = [];

        if (this._communityService.data_comments.search != null) {
          arr_edit = ['source', 'no_answers', 'my_questions', 'search'];
        } else {
          arr_edit = ['source', 'no_answers', 'my_questions'];
        }

        if (this.is_sidebar_component) {
          arr_edit = ['source', 'no_answers', 'my_questions', 'recent', 'popular', 'my_questions_sidebar'];
        }

        arr_edit.forEach(ref => {

          //SI el item se encuentra en el array original 
          let item_index = this._communityService.data_comments[ref].findIndex(comment => comment.Comment.id == event.item_edit.id);

          if (item_index != null && item_index != -1) {
            let tmp_course = JSON.parse(JSON.stringify(this._communityService.data_comments[ref]));

            if (event.item_edit.hasOwnProperty('ChildrenComment')) {
              tmp_course[item_index].ChildrenComment.files_comments = event.item_edit.Files;
            } else {
              tmp_course[item_index].Comment.files_comments = event.item_edit.Files;
            }

            this._communityService.data_comments[ref] = JSON.parse(JSON.stringify(tmp_course));
          }

        });


        break;
      case 'new-answer':

        let newAnswer: any = {
          ChildrenComment: {},
          User: this._classroomService.data.certification.User,
          VotosChild: [],
        }
        newAnswer.ChildrenComment.files_comments = JSON.parse(JSON.stringify(event.item.extra_data.files_comments));

        for (const prop in event.item) {
          if (typeof event.item[prop] != 'object') {
            newAnswer.ChildrenComment[prop] = event.item[prop];
          }
        }

        if (this._communityService.answers[_reference] != null && (this._communityService.answers_parentId != null && this._communityService.answers_parentId === newAnswer.ChildrenComment.comment_id)) {
          this._communityService.answers[_reference].unshift(newAnswer);
        }



        let arr_new_answer = [];

        if (this._communityService.data_comments.search != null) {
          arr_new_answer = ['source', 'no_answers', 'my_questions', 'search'];
        } else {
          arr_new_answer = ['source', 'no_answers', 'my_questions'];
        }

        if (this.is_sidebar_component) {
          arr_new_answer = ['source', 'no_answers', 'my_questions', 'recent', 'popular', 'my_questions_sidebar'];
        }

        arr_new_answer.forEach(ref => {

          //SI el item se encuentra en el array original 
          let item_index = this._communityService.data_comments[ref].findIndex(comment => comment.Comment.id == event.item.comment_id);

          if (item_index != null && item_index != -1) {
            let tmp_comments = JSON.parse(JSON.stringify(this._communityService.data_comments[ref]));
            tmp_comments[item_index].Comment.count_children = parseInt(tmp_comments[item_index].Comment.count_children) + 1;
            this._communityService.data_comments[ref] = JSON.parse(JSON.stringify(tmp_comments));
          }

        });



        break;

      case 'edit-answer':
        if (this._communityService.answers[_reference] != null && this._communityService.answers[_reference].length > 0) {

          let edited_answer_index = this._communityService.answers[_reference].findIndex(answer_ => answer_.ChildrenComment.id == event.item_edit.id);

          this._communityService.answers[_reference][edited_answer_index].ChildrenComment.files_comments = event.item_edit.Files;
          // this._communityService.answers[_reference][event.item.index].Files = event.item.Files;
        }
        break;

    }

  }


  loadComments(type) {

    let types = null;

    let types_position = 0;

    if (this.is_sidebar_component) {
      //Si es listado desde el sidebar
      types = ['recent', 'popular', 'my_questions_sidebar'];

      types_position = types.indexOf(this.sidebar_component_filter);

    } else {

      types = ['source', 'my_questions', 'no_answers'];

    }




    let X = {


      setResponse: (response) => {


        if (types[types_position] == 'source') {
          //Si entra en esta condición debe abrirse la opción de participar
          if (this._communityService.participate_from_forums.state && this._communityService.participate_from_forums.forum_id == this.current_source.id) {
            setTimeout(() => {
              // this.takePart();
              this._communityService.participate_from_forums = {
                forum_id: null,
                state: false
              }

              let source_container = document.querySelector('.s-container');
              if (source_container) source_container.scrollIntoView({ behavior: 'auto', block: 'start' });

            }, 400);
          }

          this._classroomService.comments_by_source_loaded = true;
          //Notificar que ya cargaron los comments del source para que deje de mostrar la precarga
        }

        if (types[types_position] == 'popular') {
          this._communityService.comments_by_popular_loaded = true;
        }


        this._communityService.data_comments[types[types_position]] = (JSON.parse(JSON.stringify(response)));
        // this._communityService.commentsPages[types[types_position]] += 1;

        if (this.is_sidebar_component != true) {
          types_position++;
        }

        if (types_position < types.length && this.is_sidebar_component != true) {


          if (types[types_position] == 'my_questions') {

            if (this._classroomService.data.actions && this._communityService.data_comments.my_questions == null) {

              X.getComments();

            } else {

              types_position++;
              X.getComments();

            }

          }

          if (types[types_position] == 'no_answers' && this._communityService.data_comments.no_answers == null) {
            X.getComments();
          }

        }


        if (this.is_sidebar_component) {

          if (this._communityService.data_comments[types[types_position]] == null) {
            X.getComments();
          }

        }

      },

      getComments: () => {


        if (types[types_position] != null && types[types_position] != '') {

          this._communityService.getComments(types[types_position], this.current_source).subscribe((commentsResponse: any) => {

            if (commentsResponse != null && commentsResponse.length > 0) {
              commentsResponse.forEach((comment_, index) => {
                comment_.Comment.index = index;

                if (index == (commentsResponse.length - 1)) {
                  X.setResponse(commentsResponse);
                }
              });
            } else {
              X.setResponse(commentsResponse);
            }


          });



        }

      }
    }


    X.getComments();
    this.takePart();

  }


  getCommentsPages() {

    // let types = ['source', 'my_questions', 'no_answers'];

    let types = null;

    let types_position = 0;

    if (this.is_sidebar_component) {
      //Si es listado desde el sidebar
      types = ['recent', 'popular', 'my_questions_sidebar'];

      types_position = types.indexOf(this.sidebar_component_filter);

    } else {

      types = ['source', 'my_questions', 'no_answers'];

    }



    let X = {


      setResponse: (response) => {


        this._communityService.pages[types[types_position]] = response;

        types_position++;

        if (types_position < types.length) {

          X.getComments();

        }


      },

      getComments: () => {

        if (types[types_position] != null && types[types_position] != '') {

          this._communityService.getCommentsPages(types[types_position], this.current_source).subscribe(pages => {
            X.setResponse(pages);
          });


        }

      }
    }


    X.getComments();


  }





}