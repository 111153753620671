import { Directive, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';

import { PreviewUrlService } from '../service/preview-url.service';

@Directive({
  selector: '[appPreviewUrl]'
})
export class PreviewUrlDirective {

  @Input() appPreviewUrl: string = '';

  constructor(
    private _previewUrlService: PreviewUrlService,
    private contentMessageElement: ElementRef,
    private _renderer2: Renderer2,
  ) { }




  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('appPreviewUrl') && changes.appPreviewUrl.currentValue != undefined) {
        if (changes.appPreviewUrl.currentValue !== '') {
          this.startPreviewLink(this.appPreviewUrl);
        }
    }
  }




  /*
  * Start get and set data preview link.
  */
  startPreviewLink(url: string) {

    let url_data = this.isValidURL(url);

    if (!url_data) {
      return;
    }

    this.getDataFromUrlPromise(this.getFormatUrl(url)).then((data) => {

      let parser = null;
      let doc = null;

      try {
        parser = new DOMParser();
        doc = parser.parseFromString(data, 'text/html');
        if (parser === null || doc === null) {
          this.setDataPreviewLink(null, url, url_data);
          return;
        }
      } catch (error) {
        this.setDataPreviewLink(null, url, url_data);
        return;
      }

      let youtube_data = this.checkUrlYoutube(url, url_data);

      if (youtube_data.isYoutubeVideo) {

        let data = {
          doc: doc,
          url_data: url_data,
          url_video: youtube_data.url_embed,
          url: url,
        };

        this.setDataPreviewLinkYoutube(data);

        return;
      }

      // Set preview link all sites except youtube vídeos.
      this.setDataPreviewLink(doc, url, url_data);
    },
    (err) => {
      console.log(err);
      this.setDataPreviewLink(null, url, url_data);
    })
    .catch(err => {
      console.log(err);
    });
  }



  /*
  * Format url without sign ampersand.
  */
  getFormatUrl(url: string): string {

    return url.replace('&', 'sign-ampersand-replace');
  }



  /*
  * Check if url can be used with iframe.
  * Youtube url apllied.
  */
  checkUrlYoutube(url: string, url_data: any): any {

    if (url.indexOf('https://www.youtube.com/watch?v=') !== -1) {

      let param_v = url_data.searchParams.get('v');
      let url_embed = url_data.origin + '/embed/' + param_v;

      return { isYoutubeVideo: true, url_embed: url_embed };
    }

    if (url.indexOf('https://youtu.be/') !== -1) {

      let pathname = url_data.pathname;
      let url_embed = 'https://www.youtube.com/embed' + pathname;

      return { isYoutubeVideo: true, url_embed: url_embed };
    }

    return { isYoutubeVideo: false, url_embed: '' };
  }



  /*
  * Set metadata and set preview link youtube vídeos.
  */
  setDataPreviewLinkYoutube(data: any): void {
    // Set data of specific site.
    let title = this._previewUrlService.getTitleFromDocument(data.doc);
    let description = this._previewUrlService.getDescriptionFromDocument(data.doc);

    const elementPreview = this.contentMessageElement.nativeElement;

    let contentPreview = this._renderer2.createElement('div');
    this._renderer2.addClass(contentPreview, 'content-preview-youtube');
    
    let contentVideo = this._renderer2.createElement('div');
    this._renderer2.addClass(contentVideo, 'content-video-youtube');
    
    let contentInfo = this._renderer2.createElement('a');
    this._renderer2.addClass(contentInfo, 'content-info-youtube');
    this._renderer2.setAttribute(contentInfo, 'href', data.url);
    this._renderer2.setAttribute(contentInfo, 'target', '_blank');
    
    let titleContent = this._renderer2.createElement('p');
    this._renderer2.addClass(titleContent, 'content-title-youtube');
    let textTitle = this._renderer2.createText(title);
    this._renderer2.appendChild(titleContent, textTitle);
    
    let descriptionContent = this._renderer2.createElement('p');
    this._renderer2.addClass(descriptionContent, 'content-description-youtube');
    let textDescription = this._renderer2.createText(description);
    this._renderer2.appendChild(descriptionContent, textDescription);
    
    let domainContent = this._renderer2.createElement('p');
    this._renderer2.addClass(domainContent, 'content-domain-youtube');
    let textDomain = this._renderer2.createText(data.url_data.hostname);
    this._renderer2.appendChild(domainContent, textDomain);
    
    // Content URL is not created on replied message. -------------------
    let parent = this._renderer2.parentNode(elementPreview);
    let urlContent = '';

    if (!parent.classList.contains('msg__reply')) {
      
      urlContent = this._renderer2.createElement('a');
      this._renderer2.addClass(urlContent, 'content-url-youtube');
      this._renderer2.setAttribute(urlContent, 'href', data.url);
      this._renderer2.setAttribute(urlContent, 'target', '_blank');
      let textUrl = this._renderer2.createText(data.url);

      this._renderer2.appendChild(urlContent, textUrl);
    } // -------------------------------------------------------
    
    let iframe = this._renderer2.createElement('iframe');
    this._renderer2.setAttribute(iframe, 'src', data.url_video);
    this._renderer2.setAttribute(iframe, 'title', 'YouTube video player');
    this._renderer2.setAttribute(iframe, 'frameborder', '0');
    this._renderer2.setAttribute(iframe, 'allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    this._renderer2.setAttribute(iframe, 'allowfullscreen', '');

    this._renderer2.appendChild(contentInfo, titleContent);
    this._renderer2.appendChild(contentInfo, descriptionContent);
    this._renderer2.appendChild(contentInfo, domainContent);

    this._renderer2.appendChild(contentVideo, iframe);

    this._renderer2.appendChild(contentPreview, contentVideo);
    this._renderer2.appendChild(contentPreview, contentInfo);
    
    // URL is not added on replied message.
    if (!parent.classList.contains('msg__reply')) {
      this._renderer2.appendChild(contentPreview, urlContent);
    }

    elementPreview.innerText = '';
    this._renderer2.setStyle(elementPreview, 'width', '100%');
    this._renderer2.addClass(elementPreview, 'element-message-preview-youtube');
    this._renderer2.appendChild(elementPreview, contentPreview);
  }



  /*
  * Set metadata with specific properties or elements.
  */
  setDataPreviewLink(doc: any = null, uri: string, url_data: any): void {
    
    // Set data of specific site.
    let title = (doc !== null) ? this._previewUrlService.getTitleFromDocument(doc) : '';
    let description = (doc !== null) ? this._previewUrlService.getDescriptionFromDocument(doc) : '';
    let image = (doc !== null) ? this._previewUrlService.getImageFromDocument(doc, uri) : null;
    // let domain = this._previewUrlService.getDomainName(doc, uri);

    const elementPreview = this.contentMessageElement.nativeElement;

    let contentPreview = this._renderer2.createElement('div');
    let contentInfo = this._renderer2.createElement('div');
    let contentImg = this._renderer2.createElement('div');
    let contentImgInfo = this._renderer2.createElement('a');
    
    this._renderer2.addClass(contentPreview, 'content-preview-url');
    this._renderer2.addClass(contentInfo, 'content-info');
    this._renderer2.addClass(contentImg, 'content-img');
    
    this._renderer2.addClass(contentImgInfo, 'content-img-info');
    this._renderer2.setAttribute(contentImgInfo, 'href', uri);
    this._renderer2.setAttribute(contentImgInfo, 'target', '_blank');
    
    // Content URL is not created on replied message. -------------------
    let parent = this._renderer2.parentNode(elementPreview);
    let contentUrl = '';

    if (!parent.classList.contains('msg__reply')) {

      contentUrl = this._renderer2.createElement('a');
      this._renderer2.addClass(contentUrl, 'content-url');
      this._renderer2.setAttribute(contentUrl, 'href', uri);
      this._renderer2.setAttribute(contentUrl, 'target', '_blank');
      let textContentUrl = this._renderer2.createText(uri);

      this._renderer2.appendChild(contentUrl, textContentUrl);
    } // -------------------------------------------------------
    
    let contentTitle = this._renderer2.createElement('p');
    this._renderer2.addClass(contentTitle, 'content-title');
    let textTitle = this._renderer2.createText(title);
    this._renderer2.appendChild(contentTitle, textTitle);
    
    let contentDescription = this._renderer2.createElement('p');
    this._renderer2.addClass(contentDescription, 'content-description');
    let textDescription = this._renderer2.createText(((description) ? description : ''));
    this._renderer2.appendChild(contentDescription, textDescription);
    
    let contentDomain = this._renderer2.createElement('p');
    this._renderer2.addClass(contentDomain, 'content-domain');
    let textDomain = this._renderer2.createText(url_data.hostname);
    this._renderer2.appendChild(contentDomain, textDomain);

    this._renderer2.appendChild(contentInfo, contentTitle);
    this._renderer2.appendChild(contentInfo, contentDescription);
    this._renderer2.appendChild(contentInfo, contentDomain);
    
    let img = this._renderer2.createElement('img');

    // Add image when it was found.
    if (image != null) {

      this._renderer2.addClass(img, 'img');
      this._renderer2.setAttribute(img, 'src', image + '');

      this._renderer2.appendChild(contentImg, img);
      this._renderer2.appendChild(contentImgInfo, contentImg);
    }

    this._renderer2.appendChild(contentImgInfo, contentInfo);

    this._renderer2.appendChild(contentPreview, contentImgInfo);

    // URL is not added on replied message.
    if (!parent.classList.contains('msg__reply')) {
      this._renderer2.appendChild(contentPreview, contentUrl);
    }

    elementPreview.innerText = '';
    this._renderer2.addClass(elementPreview, 'element-message-preview-url');
    this._renderer2.appendChild(elementPreview, contentPreview);
  }
  



  /*
  * Check if string is url valid or not.
  */
  isValidURL(text: string): any {

    let url;

    if (!text.length) {
      return false;
    }

    try {
      url = new URL(text);
      if (url.protocol === "http:" || url.protocol === "https:") {
        return url;
      }
    } catch (error) {
      return false;
    }
  }




  /*
  * Get info data from url.
  */
  getDataFromUrlPromise = (url: string): any => {
    return new Promise((resolve, reject) => {
      this._previewUrlService.getMetaDataUrl(url).subscribe((data) => {
        if (data.status === false) {
          resolve(false);
          return;
        }
        resolve(data.data);
        }, (error) => {
          reject(false);
          console.log(error);
        });
      });
  }

}
