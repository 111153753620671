import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'popup-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss']
})
export class ReactionsComponent {


  reactions = [
    { name: 'HELLO', emoji: `👋`, label: 'Hola' },
    { name: 'CLAP', emoji: `👏`, label: 'Aplauso' },
    { name: 'SMILE', emoji: `😊`, label: 'Sonrisa' },
    { name: 'LIKE', emoji: `👍`, label: 'Me gusta' },
    { name: 'THINKING', emoji: `🤔`, label: 'Interesante' },
    { name: 'HUSHED', emoji: `😲`, label: 'Me asombra' },
    { name: 'HEART', emoji: `❤️`, label: 'Me encanta' },
    { name: 'BRAIN', emoji: `🧠`, label: 'Aprendí' },
    { name: 'LIGHT', emoji: `💡`, label: 'Buena idea' },
    { name: 'SAD', emoji: `🙁`, label: 'Triste' },
  ];

  slice_reactions: number = 6;
  @Input() reaction_selected: any;
  @Output() reatcEvent = new EventEmitter<string>();

  constructor() { }



  ngOnChanges(changes: SimpleChanges): void {
    this.slice_reactions = 6;
  }


  show_reactions = null;
  selected_index_reactions = -1;
  reactMsg(react) {
    this.reatcEvent.emit(react);
  }

}
