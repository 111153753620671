import { Component, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ClassroomService } from '../../services/classroom.service';
import { AppService } from 'src/app/app.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CutUserNamePipe, SortByPriorityPipe } from '../../pipes/classroom.pipe';
import { CommunityService } from '../../services/community.service';
import { SearchBarService } from '../../sidebar/components/search-bar/search-bar.service';
import { Subject, Subscription } from 'rxjs';
import { ModalDialogComponent } from '../../shared-classroom/modal-dialog/modal-dialog.component';
import { Router } from '@angular/router';


@Component({
  selector: 'community-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  querySubscription: Subscription;
  @Input() users;
  @Input() users_filter;
  @Output() follow_event: EventEmitter<any> = new EventEmitter<any>();
  @Output() returnToChat: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputStudents') inputStudents_;
  @ViewChild('inputMonitors') inputMonitors_;
  @ViewChild('inputTeachers') inputTeachers_;
  @ViewChild(ModalDialogComponent) modal_dialog: ModalDialogComponent;

  modal_dialog_ = {
    state: false,
    type: null
  };

  back_char = '<<';


  /* Assist app variables */

  is_assist: boolean;

  /* Assist app end */



  constructor(
    public _classroomService: ClassroomService,
    public _communityService: CommunityService,
    private _appService: AppService,
    public _searchBarService: SearchBarService,
    private _router: Router,
  ) {
    this.is_assist = this.isAssist();
  }


  // participants: any;
  // copy_participants: any;

  lastIndex: number = 0;
  searchtext_: string = '';

  empty_text = `Aún no hay participantes conectados en este curso.`


  urlavatar = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';

  heartbeatinterval;
  scrolling = new Subject<string>();
  load_scroll_users: boolean = false;

  ngAfterContentInit() {
    this.scrolling.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((ev): void => {

      if ((this._communityService.usersPages[this.users_filter] != null) && !this.load_scroll_users) {
        this.onScroll(ev);
      }

    });

    const { curso_id, idu } = this._classroomService.params.config;


    if (this.users_filter == 'now') {
      if (this._appService._apolloClient) {
        this._communityService.connectUserCourse().subscribe(data => {
        });

        this._communityService.setCourseHeartBeat();
        let th = this;

        this.heartbeatinterval = setInterval(function () {
          th._communityService.setCourseHeartBeat();
          th._communityService.sendCourseHeartBeat();
        }, 174000);



        this.querySubscription = this._communityService.loadConnectedUsers().subscribe((data: any) => {

          this._communityService.getUsersData(data.data.course_status.users_id.filter(user => user != idu)).subscribe((data: any) => {
            if (data.data) {
              this._communityService.participants['now'] = data.data;
            } else {
              this._communityService.participants['now'] = [];
            }
          });

        });

        this._communityService.initCourseSubscription();
      } else {
        this._appService.lazyEventStream.subscribe(r => {
          if (r) {
            this._communityService.connectUserCourse().subscribe(data => {
            });

            this._communityService.setCourseHeartBeat();
            let th = this;

            this.heartbeatinterval = setInterval(function () {
              th._communityService.setCourseHeartBeat();
              th._communityService.sendCourseHeartBeat();
            }, 174000);

            this.querySubscription = this._communityService.loadConnectedUsers().subscribe((data: any) => {

              this._communityService.getUsersData(data.data.course_status.users_id.filter(user => user != idu)).subscribe((data: any) => {
                if (data.data) {
                  this._communityService.participants['now'] = data.data;

                  setTimeout(() => {
                    //enviar notificación al iniciar el curso con la cantidad de compañeros conectados
                    if (this._communityService.participants['now'].length > 0) this._appService.onlineUserNotification({ Users: `${this._communityService.participants['now'].length}` });
                  }, 100);

                } else {
                  this._communityService.participants['now'] = [];
                }
              });

            });

            this._communityService.initCourseSubscription();
          }
        });
      }
    } else {
      let url = '';
      if (this._appService.config.actions) {

        if (this.users_filter != 'followers') {
          url = `/participants/${curso_id}/${this.users_filter}?followed_by=${idu}&order_by="role"`;

          this.empty_text = this.users_filter == 'recent' ? 'Aún hay más participantes de este curso.' : 'Aún hay participantes populares de este curso.';

        } else {
          url = `/followers?curso_id=${curso_id}`;

          this.empty_text = 'Aún no se ha conectado con ningún participante de este curso.';
        }

      } else {
        url = `/participants/${curso_id}/${this.users_filter}&order_by="role"`;
      }

      this._communityService.getParticipants(url, this.users_filter).subscribe((users: any) => {
        this.users = users;
        if (this.users != null) {
          this._communityService.participants[this.users_filter] = [];
          this._communityService.copy_participants[this.users_filter] = [];

          this.users.forEach(function (element) {
            element.User.status = "offline";
          });


          // this._communityService.usersPages[this.users_filter] += 1;

          //Modificar esto cuando vuelva a colocar los participants de las lambdas
          this._communityService.participants[this.users_filter] = JSON.parse(JSON.stringify(this.users));

          this._communityService.copy_participants[this.users_filter] = JSON.parse(JSON.stringify(this._communityService.participants[this.users_filter]));

        }
        //console.log(this.users);

      });
    }



    if (this._appService.conversations_loaded) {
      this.conversations_loaded = true;
      this.can_send_message = true;
    } else {
      this._appService.lazyConversationsStream.subscribe(r => {
        if (r) {
          this.conversations_loaded = true;
        }
      });
      this._appService.lazyEventStream.subscribe(r => {
        if (r) {
          this.can_send_message = true;
        }
      });
    }
  }
  conversations_loaded = false;
  can_send_message = false;
  // ngOnChanges(changes: SimpleChanges) {

  // }

  // hasMorePages: boolean = true;
  loadingUsers: boolean = false;

  onScroll(el) {
    if (this.searchtext_ == '' && !this.load_scroll_users) {

      if ((Math.round(el.target.scrollTop + el.target.offsetHeight) + 10) > el.target.scrollHeight) {

        this.load_scroll_users = true;

        this.loadingUsers = true;
        let participants_: Subscription;

        if (participants_ == null) {


          const { curso_id, idu } = this._classroomService.params.config;

          let url = '';

          if (this._appService.config.actions) {

            if (this.users_filter != 'followers') {

              let lastEvaluatedKey = null;
              if (this._communityService.usersPages[this.users_filter].hasOwnProperty('last_evaluated_key')) {

                const { last_evaluated_key } = this._communityService.usersPages[this.users_filter];

                lastEvaluatedKey = last_evaluated_key != null ? '&LastEvaluatedKey=' + JSON.stringify(last_evaluated_key) : '';
              }

              url = `/participants/${curso_id}/${this.users_filter}?followed_by=${idu}${lastEvaluatedKey}&order_by="role"`;

              // console.log('url ==> ', url);
            } else {
              url = `/followers?curso_id=${curso_id}`;
            }

          } else {
            url = `/participants/${curso_id}/${this.users_filter}?page=${this._communityService.usersPages[this.users_filter]}&order_by="role"`;
          }


          participants_ = this._communityService.getParticipants(url, this.users_filter).subscribe((users: any) => {
            // this._communityService.usersPages[this.users_filter] += 1;
            if (users.length > 0) {
              if (this._communityService._appService._apolloClient) {
                this._communityService.loadUsersStatus(users, this.users_filter);
              } else {
                this._communityService._appService.lazyEventStream.subscribe(r => {
                  if (r) {
                    this._communityService.loadUsersStatus(users, this.users_filter);
                  }
                });
              }

              let tmp_users = this._communityService.participants[this.users_filter].concat(users);

              this.users = JSON.parse(JSON.stringify(tmp_users));

              this._communityService.participants[this.users_filter] = this._communityService.copy_participants[this.users_filter] = JSON.parse(JSON.stringify(this.users));
            } else {
              // this.hasMorePages = false
            }
            this.loadingUsers = false;

            this.load_scroll_users = false;

            participants_.unsubscribe();
          });
        }

      }
    }

  }

  data = {
    part: '',
    limit: 10,
    page: 0
  }

  eventSearch(text) {

    this.searchtext_ = text;
    this.data.part = text;
    if (text.length > 0) {
      this.loadingUsers = true;
      this._communityService.participants[this.users_filter] = [];
      this._communityService.searchParticipants(this.data).subscribe(response => {
        if (response && response.status) {

          this._communityService.participants[this.users_filter] = JSON.parse(JSON.stringify(response.data));
        } else {

          if (this.data.part.length == 0) {
            this._communityService.participants[this.users_filter] = JSON.parse(JSON.stringify(this._communityService.copy_participants[this.users_filter]));
          } else {
            this._communityService.participants[this.users_filter] = [];
          }
        }
        this.loadingUsers = false;
      })
    } else {
      this.loadingUsers = false;
      this._communityService.participants[this.users_filter] = JSON.parse(JSON.stringify(this._communityService.copy_participants[this.users_filter]));
    }
  }


  eventSearch_(text) {
    this.searchtext_ = text;
    if (text != '') {
      // this.participants = this.users.filter(student => {
      //   return student.User.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
      // });

      let searched_text = text.replace(/[`~!@#$%^&*()_|+\-=¿¡?;:'",.<>\{\}\[\]\\\/]+/gi, '').replace(/\s\s+/g, ' ').replace(/^\s\s*/, '').replace(/\s\s*$/, '').normalize('NFD').replace(/[\u0300-\u036f]/g, "");

      let exp = new RegExp((searched_text.toLowerCase().replace(/[ ]/g, '|')), 'g');
      let items_: any = [];
      let tmp_users = JSON.parse(JSON.stringify(this.users));

      if (searched_text != '') {
        tmp_users.filter((item, index) => {
          let match_user_name = [];
          let match_country_name = [];

          let name = new CutUserNamePipe().transform(item.User.name, '2');
          item.User.name = name.replace(/<\/?[^>]+(>|$)/g, "").replace(/&aacute;|&eacute;|&iacute;|&oacute;|&uacute;|&ntilde;|&agrave;|&egrave;|&ograve;/g, (m) => { return this._searchBarService.acentuadas[m]; }).toLowerCase();
          item.prioridad = 0;
          let matches = item.User.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(exp);//palabras que coincidan con las buscadas            
          //normalize elimina todos los acentos de la descripción
          if (matches != null && matches.length > 0) {

            matches.forEach((match) => { if (!this._searchBarService.invalids.includes(match)) { match_user_name.push(match) } });
            item.prioridad = 100 * match_user_name.length; //cantidad de palabras que coincidan x prioridad 100          
            item.User.name = this._searchBarService.putmark(item.User.name, match_user_name);//metodo para subrayar las palabras que coincidan                                                         
          }

          item.Country.name = item.Country.name.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase();
          let matches_country = item.Country.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(exp);
          if (matches_country != null && matches_country.length > 0) {

            matches_country.forEach((match) => { if (!this._searchBarService.invalids.includes(match)) { match_country_name.push(match) } });
            item.prioridad = item.prioridad + (50 * matches_country.length);
            item.Country.name = this._searchBarService.putmark(item.Country.name, match_country_name);//metodo para subrayar las palabras que coincidan                                           

          }

          if (match_user_name.length > 0 || match_country_name.length > 0) items_.push(item);
        });

        let order_items = new SortByPriorityPipe().transform(items_);
        this._communityService.participants[this.users_filter] = JSON.parse(JSON.stringify(order_items));
      }
    } else {
      this._communityService.participants[this.users_filter] = JSON.parse(JSON.stringify(this._communityService.copy_participants[this.users_filter]));
    }

  }

  ngOnDestroy() {
    if (this.users_filter == 'now') {
      //(":::destroying users component...:::");
      clearInterval(this.heartbeatinterval);
      if (this._communityService._appService._apolloClient) {
        this._communityService.connectUserCourse(false, this._classroomService.params.config).subscribe(data => {
        });

        this.querySubscription.unsubscribe();
      }
    }
  }

  user_id_to_follow;
  followParticipant(user) {

    let user_index = this._communityService.participants[this.users_filter].findIndex(participant => participant.User.id == user.User.id);
    let participants_index = this._communityService.participants[this.users_filter].findIndex(participant => participant.User.id == user.User.id);
    // let copy_participants_index = this._communityService.copy_participants[this.users_filter].findIndex(participant => participant.User.id == user.User.id);

    let tmp_user = JSON.parse(JSON.stringify(this._communityService.participants[this.users_filter][user_index]));
    this.user_id_to_follow = tmp_user.User.id;

    // console.log('tmp_user: ', tmp_user);

    if (this._classroomService.data.actions) {

      if (tmp_user.Followed != null) {

        //Eliminar el Follow        
        let url_delete = '';
        url_delete = `/followers/${tmp_user.Followed.id}`;


        this.modal_dialog_.state = true;

        setTimeout(() => {
          this.modal_dialog.modal_type = 50;

          let __response = this.modal_dialog.response.subscribe(response => {

            if (response) {
              this.modal_dialog.modal_type = 0;
              this._communityService.deleteItem(url_delete).subscribe(resp => {

                if (resp.status) {
                  let tmp_followed = null;
                  tmp_user.Followed = tmp_followed;
                  _follow_.setResponse(JSON.parse(JSON.stringify(tmp_user)));
                  this.modal_dialog_.state = false;
                }

              });


            } else {
              this.user_id_to_follow = false;
              this.modal_dialog_.state = false;
            }
            __response.unsubscribe();
          });

        }, 250);


      } else {

        let follow_data = {
          user_id: this._classroomService.data.certification.User.id,
          followed_id: tmp_user.User.id,
        }

        this._communityService.postItem('/followers', follow_data).subscribe(response => {

          if (response.status) {

            let tmp_followed = {
              id: response.data.id,
              privacy: null,
              followed_id: tmp_user.User.id,
              user_id: this._classroomService.data.certification.User.id,
              date_created: response.data.date_created,
              state: response.data.state
            }

            tmp_user.Followed = JSON.parse(JSON.stringify(tmp_followed));

            _follow_.setResponse(JSON.parse(JSON.stringify(tmp_user)));

          } else {

          }


        });

      }


      const _follow_ = {
        setResponse: (user_data) => {

          this._communityService.participants[this.users_filter][participants_index] = JSON.parse(JSON.stringify(user_data));
          this._communityService.participants[this.users_filter][user_index] = JSON.parse(JSON.stringify(user_data));


          // agregar seguidor a la lista de followers
          if (user_data.Followed != null) {

            let user_online_index = this._communityService.participants['now'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_online_index != -1 && user_online_index > -1) {
              this._communityService.participants['now'][user_online_index] = JSON.parse(JSON.stringify(user_data));
            }

          } else {

            let user_online_index = this._communityService.participants['now'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_online_index != -1 && user_online_index > -1) {
              this._communityService.participants['now'][user_online_index] = JSON.parse(JSON.stringify(user_data));
            }

          }


          setTimeout(() => {
            this.user_id_to_follow = false;
          }, 500);


        }
      }


    }
  }



  //conectar con participante, antes de que solo fuese listado de participantes conectados
  followParticipant_masDeUnFiltro(user) {

    let user_index = this._communityService.participants[this.users_filter].findIndex(participant => participant.User.id == user.User.id);
    let participants_index = this._communityService.participants[this.users_filter].findIndex(participant => participant.User.id == user.User.id);
    // let copy_participants_index = this._communityService.copy_participants[this.users_filter].findIndex(participant => participant.User.id == user.User.id);

    let tmp_user = JSON.parse(JSON.stringify(this._communityService.participants[this.users_filter][user_index]));
    this.user_id_to_follow = tmp_user.User.id;


    if (this._classroomService.data.actions) {

      if (tmp_user.Followed != null) {

        //Eliminar el Follow        
        let url_delete = '';
        url_delete = `/followers/${tmp_user.Followed.id}`;


        this.modal_dialog_.state = true;

        setTimeout(() => {
          this.modal_dialog.modal_type = 50;

          let __response = this.modal_dialog.response.subscribe(response => {

            if (response) {
              this.modal_dialog.modal_type = 0;
              this._communityService.deleteItem(url_delete).subscribe(resp => {

                if (resp.status) {
                  let tmp_followed = null;
                  tmp_user.Followed = tmp_followed;
                  _follow_.setResponse(JSON.parse(JSON.stringify(tmp_user)));
                  this.modal_dialog_.state = false;
                }

              });


            } else {
              this.user_id_to_follow = false;
              this.modal_dialog_.state = false;
            }
            __response.unsubscribe();
          });

        }, 250);




      } else {

        let follow_data = {
          user_id: this._classroomService.data.certification.User.id,
          followed_id: tmp_user.User.id,
        }

        this._communityService.postItem('/followers', follow_data).subscribe(response => {

          if (response.status) {

            let tmp_followed = {
              id: response.data.id,
              privacy: null,
              followed_id: tmp_user.User.id,
              user_id: this._classroomService.data.certification.User.id,
              date_created: response.data.date_created,
              state: response.data.state
            }

            tmp_user.Followed = JSON.parse(JSON.stringify(tmp_followed));

            _follow_.setResponse(JSON.parse(JSON.stringify(tmp_user)));

          } else {

          }


        });

      }


      const _follow_ = {
        setResponse: (user_data) => {

          // if (this.users_filter == 'followers') {
          //   //Si deja de seguir al usuario
          //   if (user_data.Followed == null) {
          //     this._communityService.participants[this.users_filter].splice(participants_index, 1);
          //   }

          // } else {
          this._communityService.participants[this.users_filter][participants_index] = JSON.parse(JSON.stringify(user_data));

          // this._communityService.copy_participants[this.users_filter][copy_participants_index] = JSON.parse(JSON.stringify(user_data));

          this._communityService.participants[this.users_filter][user_index] = JSON.parse(JSON.stringify(user_data));


          // agregar seguidor a la lista de followers
          if (user_data.Followed != null) {
            this._communityService.participants['followers'].unshift(JSON.parse(JSON.stringify(user_data)));
            this._communityService.copy_participants['followers'].unshift(JSON.parse(JSON.stringify(user_data)));


            let user_recent_index = this._communityService.participants['recent'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_recent_index != -1 && user_recent_index > -1) {
              this._communityService.participants['recent'][user_recent_index] = JSON.parse(JSON.stringify(user_data));
            }

            let user_popular_index = this._communityService.participants['popular'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_popular_index != -1 && user_popular_index > -1) {
              this._communityService.participants['popular'][user_popular_index] = JSON.parse(JSON.stringify(user_data));
            }


          } else {


            let user_recent_index = this._communityService.participants['recent'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_recent_index != -1 && user_recent_index > -1) {
              this._communityService.participants['recent'][user_recent_index] = JSON.parse(JSON.stringify(user_data));
            }

            let user_popular_index = this._communityService.participants['popular'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_popular_index != -1 && user_popular_index > -1) {
              this._communityService.participants['popular'][user_popular_index] = JSON.parse(JSON.stringify(user_data));
            }

            let user_follow_index = this._communityService.participants['followers'].findIndex(participant => participant.User.id == user_data.User.id);

            if (user_follow_index != -1 && user_follow_index > -1) {
              this._communityService.participants['followers'].splice(user_follow_index, 1);
            }

          }


          // }


          setTimeout(() => {
            this.user_id_to_follow = false;
          }, 500);


        }
      }


    }
  }

  loading_chat_user_id = null;
  openChat(user) {

    if (this._classroomService.data.actions) {
      if (this._appService.isFirstTime) {
        this._appService.isFirstTime = false;
        this._appService.loadConversationsStatus(this._appService.conversations);
        this._appService.loadConversationsInputStatus();
      }
      let data = {
        associated: [{
          user: {
            user_id: user.User.id,
            user_name: user.User.name,
            user_role: user.User.role,
            user_image: user.User.image,
            user_fuente: user.User.fuente
          }
        }]
      }

      this.loading_chat_user_id = user.User.id;
      setTimeout(() => {
        this.loading_chat_user_id = null;
      }, 2000);

      this._appService.chat_user = { execute: 0, data: data };

      this._appService.chatroom_state = true;


    }
  }


  

  /*
  * Its used to show the elements, styles and functionalities about assist app.
  */
  isAssist(): boolean {
    return this._router.url.includes('/assist');
  }
}
