<div class="section" [ngClass]="{'section-assist': is_assist == true}">

  <div class="community-container">

    <div class="community_sidebar_container"
      [ngClass]="{'forums': sidebar_component == 'forums', 'course_live-chat': sidebar_filter['users'] == 0, 'h-usrs-active': is_assist && _communityService.participants['now'] != null && _communityService.participants['now'].length, 'h-usrs-not-active': is_assist && (_communityService.participants['now'] == null || !_communityService.participants['now'].length) }"
      *ngIf="from_sidebar == true">

      <!-- Chat en vivo -->
      <course-live-chat [participants_online]="_communityService.participants['now']"
        [openGroupChat]="_classroomService.sidebar.components.community"
        [hidden]="sidebar_component != 'users' || (sidebar_component == 'users' && sidebar_filter['users'] != 0)"
        (openUsersOnlineEvent)="openUsersOnline()" (followParticipant)="communityUsersNow.followParticipant($event)"
        (openChat)="communityUsersNow.openChat($event)">
      </course-live-chat>

      <!-- Estudiando ahora -->
      <community-users #communityUsersNow
        [hidden]="sidebar_component != 'users' || (sidebar_component == 'users' && sidebar_filter['users'] != 1)"
        [users_filter]="'now'" (returnToChat)="selectFilter(0);">
      </community-users>

    </div>

    <!-- Comunidad debajo del archivo del contenido de la clase -->
    <!-- <community-participate *ngIf="_communityService.config.comments == 'active' && component == 'comments'"
      [current]="{lesson:_classroomService.data.lesson, source: _classroomService.data.source, modules:_classroomService.data.modules}"
      [comment_type]="_classroomService.data.source.type == 5 ? 'participation' : 'question'" [component]="'comments'"
      [data_user]="_classroomService.data.certification.User" (updateJson)="updateJson($event)">
    </community-participate> -->

    <community-comments #communityComments *ngIf="component == 'comments'"
      [current_lesson]="_classroomService.data.lesson" [current_source]="_classroomService.data.source">

      <!-- *ngIf="_communityService.config.comments == 'active' && component == 'comments'" -->
      <community-participate *ngIf="_communityService.config.participate" 
      [current]="{lesson:_classroomService.data.lesson, source: _classroomService.data.source, modules:_classroomService.data.modules}"
      [comment_type]="_classroomService.data.source.type == 5 ? 'participation' : 'question'" [component]="'comments'"
      [data_user]="_classroomService.data.certification.User" (updateJson)="updateJson($event)" add>
    </community-participate>


    </community-comments>


    <section class="info-card" [innerHTML]="info_card['comments']"
      *ngIf="_classroomService.sidebar.state == 'maximize' && _classroomService.sidebar.components.comments && !_communityService.config.participate">
    </section>

  </div> <!-- end community-container -->
</div>
