import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {

  questions: Array<any> = [];

  constructor() { 
    this.questions = [
      {
        title: "¿En qué moneda debo realizar el pago?",
        description: "El valor de su pago aparecerá y será cobrado en la moneda local de su país."
      },
      {
        title: "¿Cómo realizar mi pago por efectivo?",
        description: "A continuación, proporcionaré la lista de algunos países junto a sus respectivos medios de pago en efectivo, incluyendo indicaciones. Tenga en cuenta que, después de generar su referencia de pago en efectivo, el sistema siempre va a indicar los puntos de pago autorizados. <a class='link-a' target='_blank' href='https://edutin.com/helps/payments/q87'>Leer más</a>"
      },
      {
        title: "¿Cuáles son los medios de pago disponibles para mi país?",
        description: "La mayoría de países disponen de transferencia bancaria o efectivo, sin embargo, al momento de realizar su pago el sistema le indicará cuáles son los medios de pago disponibles según su ubicación. A continuación, le indicaré algunas posibilidades: Tarjeta de crédito y débito (MasterCard, visa, American Express, Discover), Paypal, transferencia en efectivo por Western Union."
      },
      {
        title: "Quiero obtener varios certificados de estudio, ¿Puedo realizar un pago único?",
        description: "El pago del certificado se realiza de manera individual. En el caso que usted se encuentre interesado en realizar un pago único por varios certificados, debe enviar una solicitud a payments@edutin.com para poder generar una referencia de pago única."
      },
      {
        title: "¿Puedo solicitar un reembolso por mi certificado de estudios?",
        description: "Si, siempre puede solicitar reembolso por su certificado de estudios en dos casos. (1) Reembolso por satisfacción. (2) Reembolso por garantía de aceptación. <a class='link-a' target='_blank' href='https://edutin.com/helps/payments/q82'>Leer más</a>"
      },
    ];
  }

  ngOnInit(): void {
  }

}
